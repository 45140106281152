<div class="er-empf-case-details">
  <div class="back-to-case-summary l-d-f">
    <cds-icon (click)="onBack()" class="cursor-pointer" [config]="backErIconConfig" [icon]="backErIcon"> </cds-icon>
    <div class="l-ml-2 cursor-pointer">
      <span class="cds-body2-demibold" (click)="onBack()"> MD_IFF Summary </span>
    </div>
  </div>

  <div class="div-border-bottom">
    <div class="case-details-header">
      <span class="cds-h4-demibold"> eMPF case details </span>
    </div>
  </div>

  <div *ngIf="erCaseDetailsResponse" class="l-pl-7 l-pt-7 l-pr-7 l-pb-7">
    <div>
      <span class="cds-body-hero">Item Checklist</span>
    </div>
    <div class="l-mt-5 l-d-f l-ai-cen">
      <div class="l-d-f l-ai-cen l-ml-5">
        <cds-checkbox class="check-list" [checked]="erCaseDetailsResponse.status.agentIFFCompleted"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">Servicing agent eIFF Completed</span>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-ml-5">
        <cds-checkbox class="check-list" [checked]="erCaseDetailsResponse.status.agentIFFCompleted"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">Split agent eIFF Completed</span>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-ml-5">
        <cds-checkbox class="check-list" [checked]="erCaseDetailsResponse.status.splitAgentCompleted"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">Client eIFF Completed</span>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-ml-5">
        <cds-checkbox class="check-list" [checked]="erCaseDetailsResponse.status.customerIFFCompleted"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">eMPF case mapped with eIFF</span>
        </div>
      </div>
      <div class="l-d-f l-ai-cen l-ml-5">
        <cds-checkbox class="check-list" [checked]="erCaseDetailsResponse.status.empfMatched"></cds-checkbox>
        <div style="margin-left: 1.25rem">
          <span class="cds-h6-demibold">eMPF status Completed</span>
        </div>
      </div>
    </div>
    <div class="mdiff-details-box">
      <div class="left" style="background-color: var(--cds-color-m-green)">
        <span class="cds-h4-demibold" style="color: var(--cds-color-white)">MD_IFF Details</span>
      </div>
      <div class="right">
        <div class="l-d-f l-fd-col">
          <div>
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">MD Reference Number</span>
          </div>
          <div class="l-mt-1 l-d-f l-ai-cen">
            <div>
              <span class="cds-h2-demibold">{{ erCaseDetailsResponse.mdCaseDetail?.mdNo || 'N/A' }}</span>
            </div>
            <app-copy-button *ngIf="erCaseDetailsResponse.mdCaseDetail" class="l-ml-3" [value]="erCaseDetailsResponse.mdCaseDetail.mdNo"></app-copy-button>
          </div>
        </div>
        <div class="l-mt-5 l-d-f l-ai-cen">
          <cds-checkbox [formControl]="intragroupTransferCheck" [disabled]="true" ngDefaultControl></cds-checkbox>
          <div class="l-ml-4">
            <span class="cds-detail2">這是有聯繫公司間或更改業務擁有權時之成員累算權益轉移。</span>
          </div>
        </div>
        <div class="line l-mt-5"></div>
        <div class="item-box">
          <div class="item">
            <div class="l-d-f l-ai-cen">
              <div>
                <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">MD_IFF status</span>
              </div>
              <cds-icon class="note l-ml-1" (click)="onDisplayAdviseIFF()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
            </div>
            <div *ngIf="!erCaseDetailsResponse.mdCaseDetail" class="l-mt-1 l-d-f l-ai-cen">
              <span class="cds-body1-demibold">N/A</span>
            </div>
            <div
              *ngIf="erCaseDetailsResponse.mdCaseDetail && erCaseDetailsResponse.mdCaseDetail?.mdStatus === IffStatusValue.Pending_Split_Agent"
              class="l-mt-1 l-d-f l-ai-cen">
              <div class="lamp" [ngStyle]="{ 'background-color': iffStatusService.getIFFStatusInfo(erCaseDetailsResponse.mdCaseDetail.mdStatus)?.color }"></div>
              <div class="l-ml-3">
                <span
                  [cdsTooltip]="'Pending the following split agent submission：' + PendingSplitAgentSubmission"
                  (mouseenter)="onMDIFFStatusShowToggle($event)"
                  (mouseleave)="onMDIFFStatusShowToggle($event)"
                  class="cds-body1-demibold mdiff-status-text"
                  style="cursor: context-menu"
                  >{{ iffStatusService.getIFFStatusInfo(erCaseDetailsResponse.mdCaseDetail.mdStatus)?.text }}</span
                >
              </div>
            </div>
            <div
              *ngIf="erCaseDetailsResponse.mdCaseDetail && erCaseDetailsResponse.mdCaseDetail?.mdStatus !== IffStatusValue.Pending_Split_Agent"
              class="l-mt-1 l-d-f l-ai-cen">
              <div class="lamp" [ngStyle]="{ 'background-color': iffStatusService.getIFFStatusInfo(erCaseDetailsResponse.mdCaseDetail.mdStatus)?.color }"></div>
              <div class="l-ml-3">
                <span class="cds-body1-demibold">{{ iffStatusService.getIFFStatusInfo(erCaseDetailsResponse.mdCaseDetail.mdStatus)?.text }}</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">First eIFF submission time & date (DD/MM/YYYY)</span>
            </div>
            <div *ngIf="erCaseDetailsResponse.mdCaseDetail?.submissionDate; else noMdSubmissionDate" class="l-mt-1">
              <span class="cds-body1-demibold">{{ erCaseDetailsResponse.mdCaseDetail?.submissionDate }} (HKT)</span>
            </div>
            <ng-template #noMdSubmissionDate>
              <div class="l-mt-1">
                <span class="cds-body1-demibold">N/A</span>
              </div>
            </ng-template>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Employer Name/ BR Number</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">{{
                erCaseDetailsResponse.mdCaseDetail?.employerName || erCaseDetailsResponse.mdCaseDetail?.brNo || 'N/A'
              }}</span>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Delivery channel of the eIFF</span>
            </div>
            <div *ngIf="!erCaseDetailsResponse.mdCaseDetail" class="l-mt-1">
              <span class="cds-body1-demibold">N/A</span>
            </div>
            <div *ngIf="erCaseDetailsResponse.mdCaseDetail" class="l-mt-1">
              <div *ngIf="erCaseDetailsResponse.mdCaseDetail.commChannel === 'SMS'" class="l-mt-1">
                <span class="cds-body1-demibold"
                  >SMS: ({{ erCaseDetailsResponse.mdCaseDetail.phoneCountry }}) {{ erCaseDetailsResponse.mdCaseDetail.phone }}</span
                >
              </div>
              <div *ngIf="erCaseDetailsResponse.mdCaseDetail.commChannel === 'EMAIL'" class="l-mt-1">
                <span class="cds-body1-demibold">EMAIL: ({{ erCaseDetailsResponse.mdCaseDetail.email }})</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Resend time & date</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">{{ erCaseDetailsResponse.mdCaseDetail?.resendDate || 'N/A' }}</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="erCaseDetailsResponse.mdCaseDetail?.resendAble">
          <div class="l-mt-5">
            <cds-button [style]="'secondary'" size="sm" (click)="openResendEIFF()">Resend eIFF</cds-button>
          </div>
        </ng-container>
        <div *ngIf="!erCaseDetailsResponse.mdCaseDetail && erCaseDetailsResponse.empfCaseDetail && erCaseDetailsResponse.empfCaseDetail.empfNo" class="l-mt-5">
          <cds-button [style]="'secondary'" size="sm" (click)="onOpenAddMDRefNoPopup(erCaseDetailsResponse.empfCaseDetail.empfNo)"
            >Match MD Reference No.</cds-button
          >
        </div>
      </div>
    </div>

    <div class="empf-details-box">
      <div class="left" style="background-color: var(--cds-color-jh-blue)">
        <span class="cds-h4-demibold" style="color: var(--cds-color-white)">eMPF Details</span>
      </div>
      <div class="right">
        <div class="l-d-f l-fd-col">
          <div>
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">eMPF Transaction Reference Number</span>
          </div>
          <div class="l-mt-1">
            <span class="cds-h2-demibold">{{ erCaseDetailsResponse.empfCaseDetail?.empfNo || 'N/A' }}</span>
          </div>
        </div>
        <div class="line l-mt-5"></div>
        <div class="item-box">
          <div class="item">
            <div class="l-d-f l-ai-cen">
              <div>
                <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">eMPF status</span>
              </div>
              <cds-icon class="note l-ml-1" (click)="onDisplayAdviseEmpf()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
            </div>
            <div *ngIf="!erCaseDetailsResponse.empfCaseDetail" class="l-mt-1 l-d-f l-ai-cen">
              <span class="cds-body1-demibold">N/A</span>
            </div>
            <div *ngIf="erCaseDetailsResponse.empfCaseDetail" class="l-mt-1 l-d-f l-ai-cen">
              <div
                class="lamp"
                [ngStyle]="{ 'background-color': empfAppStatusService.getEmpfAppStatusInfo(erCaseDetailsResponse.empfCaseDetail.empfStatus)?.color }"></div>
              <div class="l-ml-3">
                <span class="cds-body1-demibold">{{ empfAppStatusService.getEmpfAppStatusInfo(erCaseDetailsResponse.empfCaseDetail.empfStatus)?.text }}</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">eMPF submission date & time (DD/MM/YYYY)</span>
            </div>
            <div *ngIf="erCaseDetailsResponse.empfCaseDetail?.submissionDate; else noEmpfSubmissionDate" class="l-mt-1">
              <span class="cds-body1-demibold">{{ erCaseDetailsResponse.empfCaseDetail?.submissionDate }} (HKT)</span>
            </div>
            <ng-template #noEmpfSubmissionDate>
              <div class="l-mt-1">
                <span class="cds-body1-demibold">N/A</span>
              </div>
            </ng-template>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Employer Name</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">{{ erCaseDetailsResponse.empfCaseDetail?.employerName || 'N/A' }}</span>
            </div>
          </div>
          <div class="item">
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">BR Number</span>
            </div>
            <div class="l-mt-1">
              <span class="cds-body1-demibold">{{ erCaseDetailsResponse.empfCaseDetail?.brNo || 'N/A' }}</span>
            </div>
          </div>
        </div>
        <div class="line l-mt-5"></div>
        <ng-container
          *ngIf="
            erCaseDetailsResponse.empfCaseDetail && erCaseDetailsResponse.empfCaseDetail.apPerson && erCaseDetailsResponse.empfCaseDetail.apPerson.length > 0
          ">
          <div class="l-mt-5">
            <span class="cds-body1-bold italic" style="font-weight: 600; color: var(--cds-color-light-3-dark-navy)">Contact of Authorized Person(s) </span>
          </div>
          <div class="l-mt-5">
            <app-cd-multiple-accordion>
              <app-cd-multiple-accordion-panel *ngFor="let item of erCaseDetailsResponse.empfCaseDetail.apPerson; let i = index">
                <div header class="l-d-f l-ai-cen accordion-header">
                  <div style="flex: 1">
                    <span class="cds-body1-ita authorized-person">{{ numRanking(i + 1) }} Authorized Person</span>
                  </div>
                  <div style="flex: 1">
                    <span class="cds-body2-demibold">{{ item.name }}</span>
                  </div>
                </div>
                <div content class="accordion-content">
                  <div style="flex: 1; color: var(--cds-text-disabled)">
                    <div>
                      <span class="cds-body2">Phone Number</span>
                    </div>
                    <div style="margin-top: 10px">
                      <span class="cds-body2">Email</span>
                    </div>
                  </div>
                  <div style="flex: 1">
                    <div>
                      <span class="cds-detail1-demibold">({{ item.phoneCountry }}) {{ item.phone || 'N/A' }}</span>
                    </div>
                    <div style="margin-top: 10px">
                      <span class="cds-detail1-demibold">{{ item?.email || 'N/A' }}</span>
                    </div>
                  </div>
                </div>
              </app-cd-multiple-accordion-panel>
            </app-cd-multiple-accordion>
          </div>
        </ng-container>
        <div *ngIf="!erCaseDetailsResponse.empfCaseDetail && erCaseDetailsResponse.mdCaseDetail && erCaseDetailsResponse.mdCaseDetail.mdNo" class="l-mt-5">
          <cds-button [style]="'secondary'" size="sm" (click)="onOpenAddEmpfRefNoPopup(erCaseDetailsResponse.mdCaseDetail.mdNo)"
            >Match MPF Reference No.</cds-button
          >
        </div>
        <div *ngIf="isDisplayUpdateMPFReferenceNo" class="l-mt-5">
          <cds-button
            [style]="'secondary'"
            size="sm"
            *ngIf="erCaseDetailsResponse.mdCaseDetail"
            (click)="onOpenAddEmpfRefNoPopup(erCaseDetailsResponse.mdCaseDetail.mdNo)"
            >Update MPF Reference No.</cds-button
          >
        </div>
      </div>
    </div>

    <ng-container *ngIf="rolePriority !== 'SalesJourneyAgent'">
      <div *ngIf="erCaseDetailsResponse.serviceAgent" class="servicing-agent-box">
        <div class="left" style="background-color: var(--cds-color-violet)">
          <div>
            <span class="cds-h4-demibold" style="color: var(--cds-color-white)">Servicing Agent</span>
          </div>
          <div *ngIf="rolePriority === 'SuperAdmin'" class="l-ml-3">
            <cds-icon class="icon-edit" icon="action:edit" style="--cds-icon-color: var(--cds-color-white)" (click)="onGoEditAgentDetails()"></cds-icon>
          </div>
        </div>
        <div class="right">
          <div>
            <div>
              <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Servicing Agent</span>
            </div>
            <div class="l-d-f l-ai-cen l-mt-1">
              <div>
                <span class="cds-body1-demibold">{{ erCaseDetailsResponse.serviceAgent.agentCode }}</span>
              </div>
              <div class="l-ml-4">
                <span class="cds-body1-demibold">{{ erCaseDetailsResponse.serviceAgent.agentName }}</span>
              </div>
            </div>
            <div class="l-mt-5">
              <cds-checkbox
                [checked]="erCaseDetailsResponse.serviceAgent.isRgltdActAck"
                [disabled]="true"
                label="在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求。"></cds-checkbox>
            </div>
          </div>
          <!-- *ngIf="hasSalesJourneySuperAdmin" -->
          <div class="l-d-f l-ai-cen">
            <div class="l-d-f l-fd-col l-jc-fe">
              <div>
                <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Share HKPC</span>
              </div>
              <div style="text-align: end">
                <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{ erCaseDetailsResponse.serviceAgent.shareHKPC * 100 }} %</span>
              </div>
            </div>
            <div class="l-ml-5 l-d-f l-fd-col l-jc-fe">
              <div>
                <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Share Commission</span>
              </div>
              <div style="text-align: end">
                <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)"
                  >{{ erCaseDetailsResponse.serviceAgent.shareCommission * 100 }} %</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="erCaseDetailsResponse.splitAgent && erCaseDetailsResponse.splitAgent.length > 0" class="split-agent-box">
        <div class="left" style="background-color: var(--cds-color-violet)">
          <div>
            <span class="cds-h4-demibold" style="color: var(--cds-color-white)">Split Agent</span>
          </div>
          <div *ngIf="rolePriority === 'SuperAdmin'" class="l-ml-3">
            <cds-icon class="icon-edit" icon="action:edit" style="--cds-icon-color: var(--cds-color-white)" (click)="onGoEditAgentDetails()"></cds-icon>
          </div>
        </div>
        <div class="right">
          <div class="l-mt-5 l-d-f l-jc-sb" *ngFor="let sagent of erCaseDetailsResponse.splitAgent">
            <div>
              <div>
                <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Split Agent</span>
              </div>
              <div class="l-d-f l-ai-cen l-mt-1">
                <div>
                  <span class="cds-body1-demibold">{{ sagent.agentCode }}</span>
                </div>
                <div class="l-ml-4">
                  <span class="cds-body1-demibold">{{ sagent.agentName }}</span>
                </div>
              </div>
              <div class="l-mt-5">
                <cds-checkbox
                  [checked]="sagent.isRgltdActAck"
                  [disabled]="true"
                  label="在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求。"></cds-checkbox>
              </div>
            </div>
            <!-- *ngIf="hasSalesJourneySuperAdmin" -->
            <div class="l-d-f l-ai-cen">
              <div class="l-d-f l-fd-col l-jc-fe">
                <div>
                  <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Share HKPC</span>
                </div>
                <div style="text-align: end">
                  <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{ sagent.shareHKPC * 100 }} %</span>
                </div>
              </div>
              <div class="l-ml-5 l-d-f l-fd-col l-jc-fe">
                <div>
                  <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Share Commission</span>
                </div>
                <div style="text-align: end">
                  <span class="cds-body-hero-demibold" style="color: var(--cds-color-m-green)">{{ sagent.shareCommission * 100 }} %</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- <div class="l-mt-7">
      <div>
        <span class="cds-h4-demibold">Transfer Details</span>
      </div>

      <div *ngFor="let item of erCaseDetailsResponse.transfer; let i = index" class="transfer-details-item">
        <div>
          <span class="cds-h5-demibold">{{ i + 1 }}</span>
        </div>
        <div class="right">
          <div>
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">Transfer Status* (Source: eMPF Portal)</span>
          </div>
          <div class="l-mt-1 l-d-f l-ai-cen">
            <div *ngIf="!transferStatusService.getTransferStatusInfo(item.status)">
              <span class="cds-body1-demibold mdiff-status-text">N/A</span>
            </div>
            <div *ngIf="transferStatusService.getTransferStatusInfo(item.status)">
              <span class="cds-body1-demibold mdiff-status-text">{{ transferStatusService.getTransferStatusInfo(item.status)?.text }}</span>
            </div>
          </div>
          <div class="transfer-table">
            <div class="table-head">
              <div></div>
              <div class="table-head-item">
                <span class="cds-body2">Original Scheme</span>
              </div>
              <div class="table-head-item">
                <span class="cds-body2">New (MIL) Scheme</span>
              </div>
            </div>
            <div class="table-row">
              <div>
                <span class="cds-detail1">Trustee Name</span>
              </div>
              <div>
                <span class="cds-body2-demibold">{{ item.trusteeName.originalScheme }}</span>
              </div>
              <div>
                <span class="cds-body2-demibold">{{ item.trusteeName.MILScheme }}</span>
              </div>
            </div>
            <div class="table-row">
              <div>
                <span class="cds-detail1">Scheme name</span>
              </div>
              <div>
                <span class="cds-body2-demibold">{{ item.schemeName.originalScheme }}</span>
              </div>
              <div>
                <span class="cds-body2-demibold">{{ item.schemeName.MILScheme }}</span>
              </div>
            </div>
            <div class="table-row table-row-end">
              <div>
                <span class="cds-detail1">Scheme member account no.</span>
              </div>
              <div>
                <span class="cds-body2-demibold">{{ item.schemeMemberAccount.originalScheme }}</span>
              </div>
              <div>
                <span class="cds-body2-demibold">{{ item.schemeMemberAccount.MILScheme }}</span>
              </div>
            </div>
          </div>

          <div class="row-expand">
            <div class="row-expand-head">
              <div>
                <span class="cds-body1">#</span>
              </div>
              <div class="l-ml-6">
                <span class="cds-body2">Reject Reason(s)</span>
              </div>
            </div>
            <div class="row-expand-content">
              <div *ngFor="let reason of item.rejectReasonCombination; let i = index" class="item">
                <div class="item-head cursor-pointer" (click)="reason.isExpand = !reason.isExpand">
                  <div class="l-d-f">
                    <div>
                      <span class="cds-body2">{{ i + 1 }}</span>
                    </div>
                    <div class="l-ml-6">
                      <span class="cds-body2-demibold">{{ reason.title }}</span>
                    </div>
                  </div>
                  <div>
                    <cds-icon *ngIf="!reason.isExpand" icon="action:button_down"></cds-icon>
                    <cds-icon *ngIf="reason.isExpand" icon="action:button_up"></cds-icon>
                  </div>
                </div>
                <div class="item-content" [ngClass]="{ active: reason.isExpand }">
                  <div style="margin: 0.875rem 1rem; margin-left: 57px">
                    <div>
                      <span class="cds-detail2-ita">Further Action:</span>
                    </div>
                    <div>
                      <p class="cds-detail1" style="margin-top: 6px; margin-bottom: 0">{{ reason.content }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="l-mt-7">
      <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)"
        >*Remarks: If you have any enquiry, please contact eMPF platform at enquiry@eMPF.com.</span
      >
    </div>

    <div class="l-mt-7">
      <cds-button [style]="'secondary'" size="sm" (click)="toTop()">Back to Top</cds-button>
    </div>
  </div>
</div>
