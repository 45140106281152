/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';

import { CdPopupService, CdPopupSize, MatDialogRef, CDS_POPUP_DATA, CdPopupType } from 'src/app/shared/cd-popup';

import { CalendarService } from 'src/app/views/system/calendar/shared/calendar.service';

import { I18N_KEY, I18N_KEY_FOR_API_RES } from '../calendar.config';

@Component({
  selector: 'app-review-dividend-rate',
  templateUrl: './review-dividend-rate.component.html',
  styleUrls: ['./review-dividend-rate.component.scss'],
})
export class ReviewDividendRateComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ReviewDividendRateComponent>,
    private cdPopup: CdPopupService,
    public calendarService: CalendarService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      data: any;
    }
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    this.calendarService.doApproveDividendRate(this.data.data).subscribe(res => {
      if (res.data) {
        const popupRef: MatDialogRef<any> = this.cdPopup.openCommon();
        popupRef.afterClosed().subscribe(() => {
          this.dialogRef.close({
            isChange: true,
            event: this.data.data,
          });
        });
      } else {
        this.cdPopup.openCommon({
          data: {
            message: I18N_KEY_FOR_API_RES[res.message] || res.message,
          },
        });
      }
    });
  }

  decline() {
    const popupRef: MatDialogRef<any> = this.cdPopup.openCommon({
      size: CdPopupSize.MEDIUM,
      type: CdPopupType.CONTINUE,
      isShowClose: false,
      data: {
        message: I18N_KEY.CALENDAR_DECLINE_MSG,
        continue: I18N_KEY.CALENDAR_DECLINE,
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.continue) {
        this.calendarService.doDeclineDividendRate(this.data.data).subscribe(res => {
          if (res.data) {
            const popupRef: MatDialogRef<any> = this.cdPopup.openCommon();
            popupRef.afterClosed().subscribe(() => {
              this.dialogRef.close({
                isChange: true,
                event: this.data.data,
              });
            });
          } else {
            this.cdPopup.openCommon({
              data: {
                message: I18N_KEY_FOR_API_RES[res.message] || res.message,
              },
            });
          }
        });
      }
    });
  }
}
