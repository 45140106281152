<app-cd-popup>
  <cds-popup-content>
    <div class="content l-pl-6 l-pr-6">
      <div class="cds-h2-light l-mb-0 l-pb-1">
        {{ 'calendar.action.add' | lang }}
      </div>
      <span class="cds-body2-light cds-coral-text dark-1">{{ 'common.fillIn' | lang }}</span>
      <div class="row l-plr-0 l-pt-5" *ngIf="dropdownConfig">
        <div class="col-xs-12 col-md-8 l-plr-0 cds-demibold cds-dropdown-label ng-star-inserted label-color l-pb-3">
          {{ 'calendar.action.chooseName' | lang }}<span class="cds-coral-text dark-1">*</span>
        </div>
        <cds-dropdown
          [config]="dropdownConfig"
          (change)="typeChange($event)"
          class="col-xs-12 col-md-8 l-plr-0"
          [formControl]="eventTypeSelected"></cds-dropdown>
      </div>
      <div *ngIf="eventTypeSelected.value === eventTypeKey.COMMISSION">
        <div *ngIf="hasNextYearCommissionEvent" class="cds-body2-light cds-coral-text dark-1 l-mt-3">{{ 'calendar.addCommissionDuplited' | lang }}</div>
        <div *ngIf="!isLastTwoMonths" class="cds-body2-light cds-coral-text dark-1 l-mt-3">{{ 'calendar.addCommissionLimit' | lang }}</div>
        <div class="l-d-f l-ai-cen l-pt-5" *ngIf="!hasNextYearCommissionEvent && isLastTwoMonths">
          <span class="cds-body1 l-mb-0">{{ 'calendar.event.year' | lang }}</span>
          <span class="cds-h3 l-pl-3 l-mb-0 cds-demibold">{{ calendarService.nextYear }}</span>
        </div>
        <div class="row l-plr-0 l-pt-4 l-pb-4" *ngIf="!hasNextYearCommissionEvent && isLastTwoMonths">
          <div>
            <app-update-commission-event (valueChange)="commissionChange($event)" class="col-xs-12 col-md-12 col-lg-11 l-plr-0"></app-update-commission-event>
          </div>
        </div>
      </div>

      <div *ngIf="eventTypeSelected.value === eventTypeKey.DIVIDEND_RECORD_DATE">
        <div class="row col-xs-12 l-plr-0 l-pb-2 l-pt-7 bottom-xs">
          <span class="l-pr-4">{{ 'dividend-rate.event-year' | lang }}</span>
          <div>
            <span class="cds-h4-demibold">{{ calendarService.nextYear }}</span>
          </div>
        </div>
        <div class="row col-xs-12 l-pb-4 l-plr-0 interest-rate div-bg-white">
          <table>
            <tr>
              <td class="border-right"></td>
              <td class="border-right">
                <span class="cds-body-demibold">{{ 'dividend-rate.month' | lang }}</span>
              </td>
              <td>
                <span class="cds-body-demibold">{{ 'dividend-rate.record-date' | lang }}</span>
              </td>
            </tr>
            <tr *ngFor="let item of calendarService.mothList; let i = index" [ngClass]="[i % 2 !== 0 ? 'div-bg-white' : 'div-bg-light-grey']">
              <td class="border-right">
                <span>{{ item.month }}</span>
              </td>
              <td class="border-right">
                <span>{{ item.monthName }}</span>
              </td>
              <td>
                <div class="l-pb-2">
                  <cds-textfield
                    [disabled]="item.status !== undefined && item.status !== 'Approved'"
                    [min]="calendarService.getMinDate(item.month)"
                    [max]="calendarService.getMaxDate(item.month)"
                    placeholder="DD/MM/YYYY"
                    [(ngModel)]="item.recordDate"
                    (ngModelChange)="dividendRateDisabled()"
                    [cdsDatepicker]="picker">
                  </cds-textfield>
                  <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <ng-container *ngIf="eventTypeSelected.value !== eventTypeKey.DIVIDEND_RECORD_DATE">
      <cds-button [style]="'secondary'" size="sm" (click)="cancle()">{{ 'common.cancel' | lang }}</cds-button>
      <cds-button size="sm" [disabled]="disabled" (click)="onSubmit()">{{ 'common.confirm' | lang }}</cds-button>
    </ng-container>
    <ng-container *ngIf="eventTypeSelected.value === eventTypeKey.DIVIDEND_RECORD_DATE">
      <cds-button [style]="'secondary'" size="sm" (click)="cancle()">{{ 'common.cancel' | lang }}</cds-button>
      <cds-button size="sm" [disabled]="dividendRateDisabled()" (click)="onSubmitDividend()">{{ 'common.confirm' | lang }}</cds-button>
    </ng-container>
  </cds-popup-actions>
</app-cd-popup>
