export enum ResponseResult {
  SUCCESS = 0,
  DECLINED = 1,
  ERROR = 2,
  NOT_FOUND = 3,
  INVALID_PARAMETER = 4,
  TIMEOUT = 5,
  DATABASE_ERROR = 6,
  UNKNOWN = 7,
  ENCRYPTION_ERROR = 8,
  DECRYPTION_ERROR = 9,
  PROCESSING = 11,
  PREPARE_REQUEST_ERRO = 12,
  INVALID_DATE_RANGE = 13,
  INVALID_FORMAT = 14,
  ALREADY_EXISTS = 15,
  REQUEST_INVALID_RESPONSE = 16,
  SYSTEM_BAD_REQUEST = 400,
  SYSTEM_UNAUTHORIZED = 401,
  SYSTEM_ACCESS_DENIED = 403,
  SYSTEM_404_NOT_FOUND = 404,
  SYSTEM_INTERNAL_SERVICE_ERROR = 500,
  AZURE_BLOB_ERROR = 1001,
  AZURE_BLOB_INVALID_PATH = 1002,
  AZURE_SERVICE_BUS_SEND_MESSAGE_ERROR = 1103,
  AGENT_ERROR = 2100,
  AGENT_NO_EXIST = 2101,
  AGENT_NOT_ACTIVE = 2102,
  AGENT_NOT_IN_SAME_DISTRICT = 2103,
  AGENT_MORE_THAN_LIMIT = 2104,
  AGENT_IN_DELEGATED = 2105,
  EMPLOYER_NO_EXIST = 21015,
  EMPLOYEE_NO_EXIST = 21016,
  LOGIN_FAILED = 2000,
  LOGIN_FAILED_USERNAME_PASSWORD_BLANK = 2001,
  LOGIN_FAILED_ACCOUNT_LOCKED = 2002,
  LOGIN_FAILED_INACTIVATE_AGT_PIN = 2003,
  LOGIN_FAILED_INVALIDATE_AGT_PIN = 2004,
  LOGIN_FAILED_UNAUTHORIZED_USER = 2005,
  LOGIN_DUPLICATE = 2006,
  LOGIN_PHONE_NUM_INVALID = 2007,
  LOGIN_FAILED_INACTIVATE_USER = 2008,
  LOGIN_FAILED_WITH_SCB_TEMPORARILY_BAN_EXCEPTION = 2009,
  LOGIN_FAILED_NO_VALID_MPF_LICENSE = 2010,
  LOGIN_FAILED_WITH_UNEXPECTED_EXCEPTION = 2011,
  OTP_ERROR_NO_EFFECTIVE_CONTACT_WAY = 2012,
  OTP_REQUEST_LIMIT = 2013,
  OTP_VERIFY_FAILED = 2014,
  OTP_VERIFY_EXPIRED = 2015,
  OTP_VERIFY_LIMIT = 2016,
  OTP_SERVICE_UNAVAILABLE = 2017,
  OTP_VERIFY_WITH_UNEXPECTED_EXCEPTION = 2018,
  RESPONSE_ERROR = -99,
  LOGIN_FAILED_INVALID_MCF_USER = 2019,
}
