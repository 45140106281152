<div class="container-fluid p-0">
  <div class="card">
    <div class="card-header-tab card-header space-content" style="justify-content: space-between">
      <div class="card-header-title font-size-lg text-capitalize fw-normal">Chat history of {{ data.agent }} and {{ data.user }}</div>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="app-inner-layout__content card">
        <div class="table-responsive">
          <div class="chat-wrapper">
            <ng-container *ngFor="let obj of chatJson">
              <div class="chat-box-wrapper" *ngIf="obj.userType === 'USER'">
                <div>
                  <div class="avatar-icon-wrapper mr-1">
                    <div class="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg"></div>
                    <div _ngcontent-serverapp-c272="" class="cb-bot-icon cb-chatbot-small">
                      <svg _ngcontent-serverApp-c272="" width="21" height="20" class="cb-bot">
                        <use _ngcontent-serverApp-c272="" xlink:href="./assets/images/sprite.svg#cb-bot"></use>
                      </svg>
                    </div>
                  </div>
                </div>
                <div>
                  <div style="text-align: left">
                    {{ obj.sender }}
                  </div>
                  <div class="chat-box" [innerHTML]="obj.content"></div>
                  <small class="opacity-6 m-0">
                    {{ obj.msgDate | date : 'dd/MM/yyyy HH:mm' }}
                  </small>
                </div>
              </div>

              <div class="chat-box-wrapper chat-box-wrapper-right float-right" *ngIf="obj.userType === 'AGENT'">
                <div>
                  <div style="text-align: right">
                    {{ obj.sender }}
                  </div>
                  <div class="chat-box" [innerHTML]="obj.content"></div>
                  <small class="opacity-6 m-0">
                    {{ obj.msgDate | date : 'dd/MM/yyyy HH:mm' }}
                  </small>
                </div>
              </div>
            </ng-container>
            <!-- <div class="app-inner-layout__bottom-pane d-block text-center">
                        <div class="mb-0 position-relative row form-group">
                            <div class="col-sm-12">
                                <input placeholder="Write here and hit enter to send..." type="text"
                                    class="form-control-lg form-control">
                            </div>
                        </div>
                    </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
