/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BasicResponse } from '../../../core/models/response/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuditLogService {
  constructor(private http: HttpClient) {}

  getData(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.notificationServiceExt}${'/audit/history/logs'}`, { params: params });
  }
}
