/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs';

import moment from 'moment';

import { ResponseResult } from 'src/app/core/models/response/response-result';
import { PageConfig, ColumnConfig } from 'src/app/shared/data-table';
import { aoaToSheet, FileType } from 'src/app/utils/xlsx';

import { FinancialJournalService } from '../shared/financial-journal.service';
import { requiredValidator, dateRangeInCompleteValidator, dateRangeInvalidValidator, dateValidator, numberValidator } from 'src/app/core/validators';

import { I18N_KEY, FINANICAL_JOURNAL_COLUMNS, PAGE_SIZE, FILE_NAME, EXCEL_COLUMNS } from './financial-journal.component.config';

@Component({
  selector: 'app-financial-journal',
  templateUrl: './financial-journal.component.html',
  styleUrls: ['./financial-journal.component.scss'],
})
export class FinancialJournalComponent implements OnInit {
  isDownLoading = false;
  previousSearchParams: any;
  isShowSearchDatePicker = true; // Fix DatePicker Bug

  isDataLoading = false;
  pageConfig: PageConfig = {};
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = FINANICAL_JOURNAL_COLUMNS;

  formGroup = new FormGroup(
    {
      runDateFrom: new FormControl('', {
        initialValueIsDefault: true,
        validators: [requiredValidator({ error: I18N_KEY.DATE_From_NULL }), dateValidator({ error: I18N_KEY.INVALID_RUN_DATE })],
      }),
      runDateTo: new FormControl('', {
        initialValueIsDefault: true,
        validators: [requiredValidator({ error: I18N_KEY.DATE_TO_NULL }), dateValidator({ error: I18N_KEY.INVALID_RUN_DATE })],
      }),
      employerAccountCode: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.ACCOUNT_NO_INVALID,
          }),
        ],
        initialValueIsDefault: true,
      }),
      memberAccountCode: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.MEMBER_NO_INVALID,
          }),
        ],
        initialValueIsDefault: true,
      }),
      payrollGrpId: new FormControl('', { initialValueIsDefault: true }),
      transaction: new FormControl('', { initialValueIsDefault: true }),
    },
    [
      dateRangeInCompleteValidator('runDateFrom', 'runDateTo', { error: I18N_KEY.DATE_RANGE_INCOMPLETE }),
      dateRangeInvalidValidator('runDateFrom', 'runDateTo', { error: I18N_KEY.INVALID_DATE_RANGE }),
    ]
  );

  get runDateFrom() {
    return this.formGroup.get('runDateFrom') as FormControl;
  }

  get runDateTo() {
    return this.formGroup.get('runDateTo') as FormControl;
  }

  get employerAccountCode() {
    return this.formGroup.get('employerAccountCode') as FormControl;
  }

  get memberAccountCode() {
    return this.formGroup.get('memberAccountCode') as FormControl;
  }

  get payrollGrpId() {
    return this.formGroup.get('payrollGrpId') as FormControl;
  }

  get transaction() {
    return this.formGroup.get('transaction') as FormControl;
  }

  get searchDisabled() {
    const accountNo = this.employerAccountCode.value.trim();
    const memberNo = this.memberAccountCode.value.trim();
    const payrollGrpId = this.payrollGrpId.value.trim();
    const transaction = this.transaction.value.trim();
    const hasValue = accountNo || memberNo || payrollGrpId || transaction;
    return this.formGroup.invalid || !this.formGroup.dirty || !hasValue;
  }

  get downloadDisabled() {
    return !this.previousSearchParams || this.isDownLoading || !this.getTotalCount;
  }

  get getTotalCount() {
    return this.pageConfig.totalCount || 0;
  }

  constructor(private financialJournalService: FinancialJournalService) {}

  ngOnInit() {}

  search() {
    this.previousSearchParams = null;
    this.pageConfig = {
      pageSize: PAGE_SIZE,
      current: 1,
    };
    this.getData();
  }

  getData() {
    this.isDataLoading = true;
    const params = this.createParams();
    this.financialJournalService
      .getData(params)
      .pipe(
        finalize(() => {
          this.isDataLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.previousSearchParams = params;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: res.data.totalElements,
          };
          const tempData = res.data.content || [];
          this.dataDisplayed = this.createDisplayData(tempData);
        } else {
          // this.openAlertPop(res.message);
        }
      });
  }

  createDisplayData(data: any[]) {
    return data.map((item: any) => {
      return item;
    });
  }

  createParams() {
    const params: any = {
      page: (this.pageConfig.current || 1) - 1,
      size: PAGE_SIZE,
      sort: 'runDate,desc',
    };
    if (this.previousSearchParams) {
      return { ...this.previousSearchParams, ...params };
    }
    const formValue = this.getFormValue();
    if (formValue.runDateFrom) {
      formValue.runDateFrom = moment(formValue.runDateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (formValue.runDateTo) {
      formValue.runDateTo = moment(formValue.runDateTo, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    return { ...params, ...formValue };
  }

  getFormValue() {
    const valueTemp: any = {};
    const value = this.formGroup.value;
    const keys: string[] = Object.keys(value);
    keys.forEach(key => {
      if (value[key]) {
        valueTemp[key] = value[key];
      }
    });
    return valueTemp;
  }

  reset() {
    this.previousSearchParams = null;
    this.formGroup.reset();
    this.isShowSearchDatePicker = false;
    setTimeout(() => {
      this.isShowSearchDatePicker = true; // Fix DatePicker Bug
    });
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.getData();
    }
  }

  download() {
    if (this.downloadDisabled) {
      return;
    }
    this.isDownLoading = true;
    this.financialJournalService
      .getData(this.previousSearchParams)
      .pipe(
        finalize(() => {
          this.isDownLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          const tempData = res.data.content || [];
          const data = this.createDisplayData(tempData);
          const excelData = this.createExcelData(data);
          aoaToSheet(
            {
              fileName: `${FILE_NAME} ${moment().format('YYYYMMDD_HHmmss')}`,
              fileType: FileType.XLSX,
            },
            excelData
          );
        } else {
          // this.openAlertPop(res.message);
        }
      });
  }

  createExcelData(data: any[]) {
    const excelData: any[] = [];
    excelData.push(EXCEL_COLUMNS);
    data.forEach(item => {
      const temp = [];
      temp.push(item.employerAccountCode);
      temp.push(item.payrollGroupId);
      temp.push(item.runDate);
      temp.push(item.memberAccountCode);
      temp.push(item.txnDate);
      temp.push(item.txnCode);
      temp.push(item.transactionName);
      temp.push(item.accountNumber);
      temp.push(item.fundCode);
      temp.push(item.amountCredited);
      temp.push(item.schemeType);
      temp.push(item.reportNo);
      temp.push(item.workArea);
      temp.push(item.process);
      temp.push(item.adminUnit);
      temp.push(item.lastRunDate);
      excelData.push(temp);
    });
    return excelData;
  }
}
