import { environment } from 'src/environments/environment';

const baseUrl = environment.apiPrefix + '/ext/eb-ssms/chatbot-service/api/v1/';
//const baseUrl = 'http://localhost:8080/api/v1/';

export const APIPaths = {
  getCanedResponse: baseUrl + 'canned',
  getParent: baseUrl + 'canned/parent',
  getsubCanedResponse: baseUrl + 'canned',
  getAutoResponse: baseUrl + 'auto',
  feedBack: baseUrl + 'feedback',
  getNewChatRoom: baseUrl + 'room',
  getUsreList: baseUrl + 'rooms',
  getPendingChatList: baseUrl + 'pending-rooms',
  getActiveCloseChatList: baseUrl + 'rooms-with-status',
  getAgentcanned: baseUrl + 'agentcanned',
  bot_room: baseUrl + 'bot-rooms',
  getRoomInfo: baseUrl + 'room',
  upload: environment.apiPrefix + '/ext/eb-ssms/chatbot-service/api/files/upload',
  download: environment.apiPrefix + '/ext/eb-ssms/chatbot-service/api/files/download/',
  websocket: environment.chatbotWebsocket + '/ext/eb-ssms/chatbot-service/ws',
  //websocket:  'ws://localhost:8080/ws',
  //websocket: environment.apiPrefix + '/ws',
  changeRoomStatus: baseUrl + 'room/',
  changeUsertatus: baseUrl + 'user/status/',
  gethistory: baseUrl + 'history/',
  getServicesAgree: baseUrl + 'agreements',
  botUser: baseUrl + 'user/get-bot-user',
  user: baseUrl + 'user/get-user',
  checkAgentCert: environment.apiPrefix + '/ext/eb-ssms/chatbot-service/api/v1/verify/customer-service/customer-agents/',
  checkAvailableCSO: baseUrl + 'check-room-availability',
  updateChatCount: baseUrl + 'history/status/',
};
