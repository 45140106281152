import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA } from '@cds/ng-web-components/popup';
import { AgentProductSummary } from '../../product-type-summary/product-type-summary.model';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { IFF_STATUS, MdStatusValue } from 'src/app/config/iff-status.config';

@Component({
  selector: 'app-product-type-count-details-popup',
  templateUrl: './product-type-count-details-popup.component.html',
  styleUrls: ['./product-type-count-details-popup.component.scss'],
})
export class ProductTypeCountDetailsPopupComponent implements OnInit {
  clearButton: CdsButtonConfig = {
    size: 'sm',
    style: 'primary',
    disabled: false,
  };
  pendingToMapColor?: string;
  PendingCustomerSubmissionColor?: string;
  pendingSplitAgentColor?: string;

  constructor(
    private dialogRef: MatDialogRef<ProductTypeCountDetailsPopupComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      count: {
        pa: AgentProductSummary;
        tvc: AgentProductSummary;
        svc: AgentProductSummary;
        er: AgentProductSummary;
        sep: AgentProductSummary;
      };
      productType: string;
    }
  ) {}

  detailsByProductType: AgentProductSummary = { pendingToMap: 0, pendingcustomer: 0, pendingAgent: 0 };

  ngOnInit(): void {
    const productType = this.data.productType;
    if (productType === 'PA') {
      this.detailsByProductType = this.data.count.pa;
    } else if (productType === 'TVC') {
      this.detailsByProductType = this.data.count.tvc;
    } else if (productType === 'SVC') {
      this.detailsByProductType = this.data.count.svc;
    } else if (productType === 'ER') {
      this.detailsByProductType = this.data.count.er;
    } else if (productType === 'SEP') {
      this.detailsByProductType = this.data.count.sep;
    }

    this.pendingToMapColor = 'orange';
    this.PendingCustomerSubmissionColor = IFF_STATUS.find(item => item.value === MdStatusValue.Pending_Customer_Submission)?.color || '#000000';
    this.pendingSplitAgentColor = IFF_STATUS.find(item => item.value === MdStatusValue.Pending_Split_Agent)?.color || '#000000';
  }

  gotIt() {
    this.dialogRef.close({ agree: true });
  }
}
