<div class="empf-salessupport-ind">
  <div class="col-xs-12 col-md-12 l-plr-0">
    <span class="cds-h2-light">Search record</span>
    <div class="row l-d-f l-plr-0">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color">{{ 'md-iff-summary.eMPF-ref-no' | lang }}</span>
        <app-cd-input
          class="text-field"
          placeholder="{{ 'common.please-input' | lang : { p1: 'md-iff-summary.eMPF-ref-no' | lang } }}"
          [group]="formGroup"
          [controlName]="'empfNo'"></app-cd-input>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color">{{ 'md-iff-summary.client-name' | lang }}</span>
        <app-cd-input
          class="text-field"
          [group]="formGroup"
          [controlName]="'clientName'"
          [regExpLimit]="'^[a-zA-Z ]*$'"
          placeholder="{{ 'common.please-input' | lang : { p1: 'md-iff-summary.client-name' | lang } }}"></app-cd-input>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color">{{ 'md-iff-summary.agent-code' | lang }}</span>
        <app-cd-input
          class="text-field"
          [group]="formGroup"
          [controlName]="'agentCode'"
          placeholder="{{ 'common.please-input' | lang : { p1: 'md-iff-summary.agent-code' | lang } }}"></app-cd-input>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color">District Code</span>
        <app-cd-input class="text-field" [group]="formGroup" [controlName]="'districtCode'" placeholder="Input District Code"></app-cd-input>
      </div>
    </div>
    <div class="row l-d-f l-plr-0">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <div>
          <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color">ABM</span>
          <app-cd-multidropdown [style.width]="'100%'" placeholder="Select ABM" [toppingList]="abmOptionList" [formControl]="abm"></app-cd-multidropdown>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item prodtype-date-icon">
        <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color">
          {{ 'case-summary.hkid' | lang }}
        </span>
        <app-cd-input
          class="text-field"
          [group]="formGroup"
          [controlName]="'hkidOrPassportNo'"
          (valueChange)="hkidOrPassportNoChange($event)"
          [placeholder]="'common.input' | lang : { p1: 'case-summary.hkid' | lang }"></app-cd-input>
      </div>
      <app-date-range-box class="col-lg-6 col-xs-12 l-plr-0 search-item" style="margin-top: var(--cds-spacing-02)">
        <div *ngIf="!resetDatePicker" left>
          <cds-textfield
            #iFFExpiryDateFromBox
            placeholder="DD/MM/YYYY"
            [cdsDatepicker]="pickerFromDate"
            (ngModelChange)="iFFExpiryDateFromChange(iFFExpiryDateFromBox.value)"
            ngDefaultControl
            label="{{ 'md-iff-summary.declaration-date-from' | lang }}"
            [formControl]="declarationDateStart"
            [max]="declarationDateFromMax"></cds-textfield>
          <cds-datepicker #pickerFromDate="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="declarationDateStart.errors && declarationDateStart.dirty" class="l-mt-1 l-pt-3">
            {{ 'common.invalidDate' | lang }}
          </cds-assistive-text>
          <cds-assistive-text *ngIf="formGroup.hasError('dateRangeInvalidValidator')" class="l-mt-1 l-pt-3">
            {{ 'common.invalidDate' | lang }}
          </cds-assistive-text>
        </div>
        <div *ngIf="!resetDatePicker" right>
          <cds-textfield
            #iFFExpiryDateToBox
            placeholder="DD/MM/YYYY"
            [cdsDatepicker]="pickerToDate"
            (ngModelChange)="iFFExpiryDateToChange(iFFExpiryDateToBox.value)"
            ngDefaultControl
            label="{{ 'md-iff-summary.declaration-date-to' | lang }}"
            [formControl]="declarationDateEnd"
            [max]="declarationDateToMax"
            [min]="declarationDateToMin"></cds-textfield>
          <cds-datepicker #pickerToDate="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="declarationDateEnd.errors && declarationDateEnd.dirty" class="l-mt-1 l-pt-3">
            {{ 'common.invalidDate' | lang }}
          </cds-assistive-text>
        </div>
      </app-date-range-box>
    </div>
    <div class="row l-d-f l-plr-0">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <div>
          <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color">{{ 'md-iff-summary.md-iff-status' | lang }}</span>
          <app-cd-multidropdown
            [style.width]="'100%'"
            placeholder="{{ 'common.plsSelect' | lang : { p1: 'md-iff-summary.md-iff-status' } }}"
            [toppingList]="iffStatusoptionList"
            [formControl]="mdIffStatus"></app-cd-multidropdown>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <div>
          <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color">Declined Reason</span>
          <app-cd-multidropdown
            [style.width]="'100%'"
            placeholder="Select declined reason"
            [toppingList]="declinedReasonOptionList"
            [formControl]="declineReason"></app-cd-multidropdown>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item">
        <div>
          <span class="cds-body2 cds-dropdown-label ng-star-inserted label-color">{{ 'md-iff-summary.empf-app-status' | lang }}</span>
          <app-cd-multidropdown
            [style.width]="'100%'"
            placeholder="{{ 'common.plsSelect' | lang : { p1: 'md-iff-summary.empf-app-status' } }}"
            [toppingList]="empfAppStatusoptionList"
            [formControl]="empfStatus"></app-cd-multidropdown>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 search-item bottom-box">
        <cds-button [disabled]="isDisableSearch || isLoading" size="sm" class="l-mr-4" (click)="onClickSearch()">{{
          'md-iff-summary.search' | lang
        }}</cds-button>
        <cds-button [style]="'secondary'" size="sm" (click)="onClickReset()">{{ 'md-iff-summary.reset' | lang }}</cds-button>
      </div>
    </div>
  </div>

  <div class="record-list-row">
    <div>
      <div>
        <span class="cds-h2-light">Record list</span>
      </div>

      <div class="l-mt-2">
        <span class="cds-body1">Total {{ totalElements | number }} records</span>
      </div>
    </div>

    <div class="l-d-f l-ai-cen l-mt-5">
      <div class="font-label l-mr-4">{{ 'md-iff-summary.product-type' | lang }}:</div>
      <div [ngClass]="['button-style', individualTypeSelected === IndividualType.ALL ? 'checked' : '']" (click)="checkProductTypeTag(IndividualType.ALL)">
        All Product Type
      </div>
      <div [ngClass]="['button-style', individualTypeSelected === IndividualType.PA ? 'checked' : '']" (click)="checkProductTypeTag(IndividualType.PA)">PA</div>
      <div [ngClass]="['button-style', individualTypeSelected === IndividualType.TVC ? 'checked' : '']" (click)="checkProductTypeTag(IndividualType.TVC)">
        TVC
      </div>
      <div [ngClass]="['button-style', individualTypeSelected === IndividualType.SVC ? 'checked' : '']" (click)="checkProductTypeTag(IndividualType.SVC)">
        SVC
      </div>
    </div>

    <div class="l-d-f l-jc-sb">
      <div class="l-d-f l-ai-cen">
        <div class="font-label l-mr-4">IFF status:</div>
        <div
          [ngClass]="['button-style', quickSearchIffStatusSelected === QuickSearchIffStatus.All ? 'checked' : '']"
          (click)="checkIFFStatusTag(QuickSearchIffStatus.All)">
          All IFF status
        </div>
        <div
          [ngClass]="['button-style', quickSearchIffStatusSelected === QuickSearchIffStatus.Completed ? 'checked' : '']"
          (click)="checkIFFStatusTag(QuickSearchIffStatus.Completed)">
          Completed
        </div>
        <div
          [ngClass]="['button-style', quickSearchIffStatusSelected === QuickSearchIffStatus.Declined ? 'checked' : '']"
          (click)="checkIFFStatusTag(QuickSearchIffStatus.Declined)">
          Declined
        </div>
      </div>
      <cds-button *ngIf="rolePriority === 'SalesJourneyAgent'" size="sm" (click)="onGenerateNewMDReferenceNo()" [style]="'secondary'"
        >Generate New eIFF Reference No.</cds-button
      >
    </div>
  </div>

  <div class="l-mt-5" style="position: relative">
    <div *ngIf="isLoading && currentPageData.length === 0" class="progress-box l-d-f l-jc-cen">
      <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
    </div>
    <app-no-result-found [style.display]="currentPageData.length === 0 && !isLoading ? 'block' : 'none'"></app-no-result-found>
    <div [style.display]="currentPageData.length > 0 ? 'unset' : 'none'" class="row l-plr-0">
      <div #tableBox class="table-wrapper" [ngClass]="[isLoading ? 'loading' : '']">
        <cds-table class="col-lg-12 row l-plr-0" [dataSource]="currentPageData" [class.full]="false">
          <cds-row *cdsRowDef="let row; index as i; columns: displayedColumns"></cds-row>
          <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
          <ng-container cdsColumnDef="empfNo" sticky>
            <cds-header-cell *cdsHeaderCellDef class="empf-ref-no-width no-right-border">
              <div class="l-d-f l-ai-cen">
                <span>{{ 'md-iff-summary.eMPF-ref-no' | lang }}</span>
                <cds-icon class="note l-ml-1" (click)="onDisplayAdviseRef()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
              </div>
            </cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="empf-ref-no-width no-right-border z-index-2">
              <div *ngIf="element.empfNo" class="l-d-f l-ai-cen">
                <app-cd-fake-link [text]="element.empfNo" (callBack)="onGoCaseDetails(element.empfNo)"></app-cd-fake-link>
                <app-copy-button class="l-ml-3" [value]="element.empfNo"></app-copy-button>
              </div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="mdNo" sticky>
            <cds-header-cell *cdsHeaderCellDef class="md-ref-no-width fix-sticky" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">{{
              'md-iff-summary.md-ref-no' | lang
            }}</cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="md-ref-no-width fix-sticky" [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']">
              <div *ngIf="element.mdNo" class="l-d-f l-ai-cen">
                <div>
                  <span>{{ element.mdNo }}</span>
                </div>
                <app-copy-button class="l-ml-3" [value]="element.mdNo"></app-copy-button>
              </div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="districtCode">
            <cds-header-cell *cdsHeaderCellDef class="product-type-width">District Code</cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="product-type-width">
              {{ element.districtCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="agentCode">
            <cds-header-cell *cdsHeaderCellDef class="client-name-width">{{ 'md-iff-summary.agent-code' | lang }}</cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="client-name-width">
              {{ element.agentCode }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="productType">
            <cds-header-cell *cdsHeaderCellDef class="client-name-width">{{ 'md-iff-summary.product-type' | lang }}</cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="client-name-width">
              {{ element.productType }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="clientName">
            <cds-header-cell *cdsHeaderCellDef class="client-name-width">{{ 'md-iff-summary.client-name' | lang }}</cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="client-name-width">
              {{ element.clientName }}
            </cds-cell>
          </ng-container>

          <ng-container cdsColumnDef="declarationDate">
            <cds-header-cell *cdsHeaderCellDef class="md-declaration-date-width">{{ 'md-iff-summary.md-declara-date' | lang }}</cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="md-declaration-date-width">
              {{ element.declarationDate }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="mdIffStatus">
            <cds-header-cell *cdsHeaderCellDef class="mdiff-status-width">
              <div class="l-d-f l-ai-cen">
                <span>{{ 'md-iff-summary.md-iff-status' | lang }}</span>
                <cds-icon class="note l-ml-1" (click)="onDisplayAdviseIFF()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
              </div>
            </cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="mdiff-status-width">
              <div class="l-d-f l-ai-cen">
                <div class="lamp" [ngStyle]="{ 'background-color': iffStatusService.getIFFStatusInfo(element.mdIffStatus)?.color }"></div>
                <div class="l-ml-3">
                  <span>{{ iffStatusService.getIFFStatusInfo(element.mdIffStatus)?.text }}</span>
                </div>
              </div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="empfStatus">
            <cds-header-cell *cdsHeaderCellDef class="empf-app-status-width">
              <div class="l-d-f l-ai-cen">
                <span>{{ 'md-iff-summary.empf-app-status' | lang }}</span>
                <cds-icon class="note l-ml-1" (click)="onDisplayAdviseEmpf()" [icon]="infoIcon" [config]="iconConfig"></cds-icon>
              </div>
            </cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="empf-app-status-width">
              <div class="l-d-f l-ai-cen">
                <div class="lamp" [ngStyle]="{ 'background-color': empfAppStatusService.getEmpfAppStatusInfo(element.empfStatus)?.color }"></div>
                <div class="l-ml-3">
                  <span>{{ empfAppStatusService.getEmpfAppStatusInfo(element.empfStatus)?.text }}</span>
                </div>
              </div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="isSplitAgent">
            <cds-header-cell *cdsHeaderCellDef class="split-case-width">{{ 'md-iff-summary.split-case' | lang }}</cds-header-cell>
            <cds-cell *cdsCellDef="let element" [ngSwitch]="element.isSplitAgent" class="split-case-width">
              <div *ngSwitchCase="true">
                <cds-icon class="prodtype-splitcaselogo" icon="general:collaboration"></cds-icon>
              </div>
              <div *ngSwitchDefault>
                <cds-icon class="prodtype-splitcaselogo no-split-case" icon="general:collaboration"></cds-icon>
              </div>
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="hkIdOrPassport" sticky>
            <cds-header-cell *cdsHeaderCellDef class="hkid-or-passport-width">{{ 'md-iff-summary.hkid-pas-no' | lang }}</cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="hkid-or-passport-width">
              {{ element.hkIdOrPassport }}
            </cds-cell>
          </ng-container>
          <ng-container cdsColumnDef="declineReason">
            <cds-header-cell *cdsHeaderCellDef class="decline-reason-width">Declined Reason</cds-header-cell>
            <cds-cell *cdsCellDef="let element" class="decline-reason-width">
              <div class="l-mb-1" *ngFor="let reason of getDeclinedReasonLabel(element.declineReason)">
                <span class="cds-detail1">
                  {{ reason }}
                </span>
              </div>
            </cds-cell>
          </ng-container>
        </cds-table>
        <div *ngIf="isLoading" class="progress-box">
          <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
        </div>
      </div>
      <div class="col-sm-12 div-border-bottom prodtype-pageformat">
        <app-pagination
          [pageCount]="{
            current: currentPageNumOrigin,
            count: allPageData.length
          }"
          (pageChange)="pageChange($event)">
        </app-pagination>
      </div>
    </div>
  </div>
</div>
