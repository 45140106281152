/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MatDialogRef, CDS_POPUP_DATA, CdsPopupService } from '@cds/ng-web-components/popup';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { CdsLangService } from '@cds/ng-core/lang';

import { UserAgreementPopupComponent } from '../../user-agreement-popup.component';
import { CommissionRelatedPartiesService } from 'src/app/core/services/commission/commission-related-parties.service';
import { texts } from '../pool-info.model';
import { AccountCodeExplain } from 'src/app/views/commission/summary/related-parties/related-parties.model';
import { CustomerStatus } from 'src/app/core/models/enum';

interface Account {
  customerId: string;
  name: string;
  no: string;
  accountTypeStr: string;
}
@Component({
  selector: 'app-related-parties-add',
  templateUrl: './related-parties-add.component.html',
  styleUrls: ['./related-parties-add.component.scss'],
})
export class RelatedPartiesAddComponent implements OnInit, OnDestroy {
  constructor(
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    private relatedPartiesService: CommissionRelatedPartiesService,
    private dialogRef: MatDialogRef<RelatedPartiesAddComponent>,
    @Inject(CDS_POPUP_DATA)
    public data: {
      title: string;
      message: string;
      cancel: string;
      continue: string;
      type: string;
      commissionPoolId: string;
      summaryInfo: any;
    }
  ) {
    this.commissionPoolId = data.commissionPoolId;
    this.summaryInfo = data.summaryInfo;
  }

  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  commissionPoolId = '';
  summaryInfo: any = {};
  disagreeButtonConfig: CdsButtonConfig = { size: 'sm', style: 'secondary' };
  agreeButtonConfig: CdsButtonConfig = { size: 'sm' };

  options: Account[] = [];
  errorMsg = '';
  warningMsg = '';
  queryPara = '';
  accounts: any[] = [];
  account = {
    commissionPoolId: '',
    customerId: '',
    customerStatus: '',
    name: '',
    no: '',
    type: '',
    accountType: '',
    poolAgentChange: false,
    updateNextCommYearStart: false,
  };
  loading = false;
  isInput = true;

  accountExplain() {
    this.langService.addLangEntries({
      alertMsg: {
        en: AccountCodeExplain,
        zh: AccountCodeExplain,
      },
    });
    this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'md',
      data: {
        message: 'alertMsg',
        continue: texts.alertSuccessContinue,
        type: 'alert',
        actionRight: true,
      },
    });
  }

  searchChange() {
    const searchBox: any = document.getElementById('search');
    const keyup$ = fromEvent(searchBox, 'keyup');
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe(queryPara => {
        this.searchRelatedParties(searchBox, queryPara);
      });
  }

  searchRelatedParties(searchBox: HTMLElement, queryPara: string) {
    this.options.forEach(item => {
      if (queryPara && queryPara.indexOf(item.no) !== -1) {
        queryPara = item.no;
      }
    });
    this.options = [];
    this.errorMsg = '';
    this.warningMsg = '';
    this.account.customerId = '';
    if (queryPara && queryPara.length >= 6) {
      this.loading = true;
      this.queryPara = queryPara;
      this.relatedPartiesService
        .searchRelatedParties({
          queryPara: queryPara,
          commissionPoolId: this.commissionPoolId,
        })
        .subscribe(resp => {
          if (resp && resp.data && resp.data.queryPara == this.queryPara) {
            this.loading = false;
            this.options = [];
            if (resp.data.accounts && resp.data.accounts.length > 0) {
              this.accounts = resp.data.accounts;
              this.accounts.forEach(item => {
                this.options.push(item);
              });
              searchBox.click();
            } else {
              this.errorMsg = texts.relatedNotFound;
            }
          }
        });
    }
  }

  displayFn(value: any) {
    const filterObj = this.options.find(x => x.customerId === value);
    if (filterObj) return filterObj.no + ' [' + filterObj.accountTypeStr + '] ' + filterObj.name;
    return value;
  }

  optionSelected(event: any) {
    for (let i = 0; i < this.accounts.length; i++) {
      if (this.accounts[i].customerId == event.option.value) {
        this.account = this.accounts[i];
        break;
      }
    }
    this.errorMsg = '';
    this.warningMsg = '';
    if (this.account && this.account.customerStatus === CustomerStatus.TERMINATED) {
      this.errorMsg = texts.customerTerminated;
      return;
    }
    if (this.account) {
      this.loading = true;
      this.relatedPartiesService.typesValidateAdd(this.commissionPoolId, this.account.accountType, this.summaryInfo).then(res => {
        this.loading = false;
        this.errorMsg = res.msg ? res.msg : '';
        if (!this.errorMsg && this.account.poolAgentChange) {
          this.warningMsg = texts.poolAgentChangeConfirm;
        }
      });
    }
  }

  confirm() {
    if (this.errorMsg || !this.account.customerId) {
      return;
    }
    this.confirm2();
  }

  confirm2() {
    const param = {
      commissionPoolId: this.commissionPoolId,
      customerId: this.account.customerId,
      updateNextCommYearStart: this.account.updateNextCommYearStart,
    };
    this.loading = true;
    this.relatedPartiesService.addRelatedParties(param).subscribe(resp => {
      this.loading = false;
      if (resp && resp.result == 0) {
        const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
          data: {
            message: texts.alertSuccessMsg,
            continue: texts.alertSuccessContinue,
            type: 'alert',
          },
        });
        popupRef.afterClosed().subscribe(confirm => {
          if (confirm) {
            this.agree();
          }
        });
      } else {
        this.errorMsg = resp.message;
      }
    });
  }

  agree() {
    this.dialogRef.close({ agree: true });
  }
  disagree() {
    this.dialogRef.close({ agree: false });
  }

  ngOnInit() {
    this.searchChange();
  }

  ngOnDestroy() {}
}
