import { Injectable } from '@angular/core';
import { EMPF_APP_STATUS } from 'src/app/config/empf-app-status.config';

@Injectable({
  providedIn: 'root',
})
export class EmpfAppStatusService {
  constructor() {}

  getEmpfAppStatusInfo(val: number) {
    return EMPF_APP_STATUS.find(item => item.value === val);
  }
}
