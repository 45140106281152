/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { CdsLangService } from '@cds/ng-core/lang';
import { deepCopy } from 'src/app/utils/copy';
import { fromISO } from 'src/app/utils/dateUtils';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { UserAgreementPopupComponent } from '../../user-agreement-popup.component';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { CommissionAgentService } from 'src/app/core/services/commission/commission-agent.service';
import { RecordDetailList } from '../record-details/record-details.model';
import { Agent } from './agent.model';
import { texts, Status } from '../pool-info.model';

import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { CommissionAgentChannelMap } from 'src/app/core/models/enum/agent-channel.enum';
import moment from 'moment';

interface AgentRecord {
  id: string;
  commissionPoolId?: string;
  value?: string;
  label?: string;
  millis: number;
  status?: string;
  current?: boolean;
  name?: string;
  effectiveDate?: string;
  createBy?: any;
  active?: boolean;
  lastUpdateBy?: any;
  lastUpdateName?: string;
  lastUpdateTime?: string;
}

@Component({
  selector: 'app-commission-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss'],
})
export class AgentComponent implements OnInit {
  constructor(private router: Router, private agentService: CommissionAgentService, private cdsPopup: CdsPopupService, private langService: CdsLangService) {}

  @Input() commissionPoolId = '';
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  status = Status;
  roleW = PermissionAccess.W;
  roleE = PermissionAccess.E;
  permissionN = PermissionItem.COMM_INFO_AGENT_COMP;
  permissionN2 = PermissionItem.COMM_INFO_AGENT_CHANGE;
  permissionADdjNormal = PermissionItem.COMM_ADJ_NORMAL;
  currentClickOpenIndex = -1;
  scrollLoading = false;
  scrollPage = 0;
  totalPages = 0;
  loading = false;

  agentRecord: AgentRecord = { id: '', millis: 0, createBy: { name: '' } };
  recordList: AgentRecord[] = [];
  agentItems: Agent[] = [];

  createRecord(action: string) {
    const params = {
      commissionPoolId: this.commissionPoolId,
      agentDetailId: '',
      currentEffectiveDate: '',
    };
    this.recordList.forEach(item => {
      if (item.status == this.status.CURRENT) {
        params.agentDetailId = item.id;
        params.currentEffectiveDate = fromISO(item.effectiveDate);
      }
    });
    const url = action == 'edit' ? '/commission/agent-record-edit' : '/commission/agent-record-add';
    this.router.navigate([url], {
      queryParams: params,
    });
  }

  toAdjustmentAmount() {
    this.router.navigate([
      '/commission/adjustment',
      {
        commissionPoolId: this.commissionPoolId,
        tabSelected: 'normalCompensation',
      },
    ]);
  }

  clickShowChildList = (i: number, item: any) => {
    if (this.currentClickOpenIndex === i && item) {
      this.currentClickOpenIndex = -1;
    } else {
      this.currentClickOpenIndex = i;
    }
  };

  deleteRecord() {
    const data = {
      title: 'commission.pool.agent.deleteTitle',
      message: 'commission.pool.agent.deleteMsg',
      cancel: texts.cancel,
      continue: texts.continue,
      type: 'confirm',
      params: {
        createTime: fromISO(this.agentRecord.effectiveDate),
        createBy: this.agentRecord.createBy.name,
      },
    };
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'lg',
      data: data,
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree) {
        this.agentService.delete(this.agentRecord.id).subscribe(resp => {
          if (resp && resp.result == 0) this.scrollPage = 0;
          this.recordList = [];
          this.getRegularList();
        });
      }
    });
  }

  getRegularList() {
    if (!this.scrollLoading) {
      this.scrollLoading = true;
      this.scrollPage++;
      this.agentItems = [];
      this.loading = true;
      this.agentService.listRegular(this.commissionPoolId, this.scrollPage - 1, this.recordList).then(res => {
        this.loading = false;
        this.scrollLoading = false;
        const resp: any = res;
        if (resp) {
          this.recordList = resp.recordList;
          this.agentRecord = resp.agentRecord;
          this.totalPages = resp.totalPages;
          this.getRegularItems();
        }
      });
    }
  }

  getRegularItems() {
    if (!this.agentRecord.id) {
      return;
    }
    if (this.agentRecord.id && this.recordList) {
      this.recordList.forEach(item => {
        if (item.id == this.agentRecord.id) {
          this.agentRecord = deepCopy(item);
        }
      });
    }
    const params = {
      id: this.agentRecord.id,
      commissionPoolId: this.commissionPoolId,
    };
    this.agentItems = [];
    this.loading = true;
    this.agentService.getRegularItems(params).subscribe(resp => {
      this.loading = false;
      if (resp && resp.result == 0 && resp.data.content) {
        const scaleTypes: any[] = deepCopy(RecordDetailList);
        const agentItems: any[] = resp.data.content;
        agentItems.forEach(item => {
          const isBigger = moment(new Date(item.saReportDate)) > moment(new Date(1900, 1, 1));
          const agent = {
            id: item.id,
            agentCode: item.agentCode,
            agentName: item.agentName,
            commissionPoolAgentId: item.commissionPoolAgentId,
            saReportDate: isBigger ? item.saReportDate : '',
            sellingAgent: item.sellingAgent,
            shareCommission: item.shareCommission,
            shareHkpc: item.shareHkpc,
            poolJoinDate: item.poolJoinDate,
            branch: item.branch,
            type: CommissionAgentChannelMap.get(item.type),
            details: [{ toFixedTwo: true, label: '', cytd: 0, ptd: 0, calytd: 0 }],
          };
          const details: any[] = [];
          scaleTypes.forEach(type => {
            const d = {
              toFixedTwo: true,
              label: type.scaleLabel,
              cytd: '0',
              ptd: '0',
              calytd: '0',
            };
            if (d.label.indexOf('HKPC') !== -1) {
              d.toFixedTwo = false;
            }
            if (item.commissionPoolAgentAccumulations) {
              const accumulations: any[] = item.commissionPoolAgentAccumulations;
              accumulations.forEach(a => {
                if (type.scaleType == a.scaleType && a.agentAccumulationType == 'CYTD') {
                  d.cytd = a.amount;
                }
                if (type.scaleType == a.scaleType && a.agentAccumulationType == 'PTD') {
                  d.ptd = a.amount;
                }
                if (type.scaleType == a.scaleType && a.agentAccumulationType == 'CAL_YTD') {
                  d.calytd = a.amount;
                }
              });
            }
            details.push(d);
            agent.details = details;
          });
          this.agentItems.push(agent);
        });
      }
    });
  }

  agentMsg =
    "<div class='l-pl-4 l-pr-4'><span class='cds-h3-light l-pt-2'>Agent Compensation</span><br><br>" +
    'Release the compensation for below transaction scenario under this section:<br><br>' +
    "<div class='l-d-f l-pl-4'><div class='black-tip'></div>&nbsp;&nbsp;Regular Contribution, Lump Sum Contributions [MPF-ER, MPF-SEP, MPF-FRC, MPF-TVC, ORSO-ER, MACAU-ER(PPS), MACAU-ER(CPF)]</div>" +
    "<div class='l-d-f l-pl-4'><div class='black-tip'></div>&nbsp;&nbsp;Plan Transfer (ERMC/ERVC/EEMC/EEVC>0) [MPF-ER, ORSO-ER, MACAU-ER(PPS), MACAU-ER(CPF)]</div><br>" +
    'Excluding the compensation of Individual Transfer Asset for MPF-ER(PAC), MPF-SEP, MPF-SEP(PAC), MPF-TVC, MPF-PAP, MACAU-PAP<br>';

  alertAgent() {
    this.langService.addLangEntries({
      alertAgent: {
        en: this.agentMsg,
        zh: this.agentMsg,
      },
    });
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'lg',
      data: {
        message: 'alertAgent',
        continue: texts.alertSuccessContinue,
        type: 'alert',
        actionRight: true,
      },
    });
    console.log('open' + popupRef);
  }

  backToTop() {
    if (document && document.getElementById(`top`)) {
      const top = document.getElementById(`top`);
      if (top) {
        top.scrollIntoView();
      }
    }
  }

  ngOnInit(): void {
    this.getRegularList();
  }
}
