<div class="add-md-reference-number-popup">
  <div class="cds-popup-padding-top">
    <cds-icon class="close-current-popup" icon="action:cross" (click)="closeCurrentPopup()"></cds-icon>
  </div>
  <div *ngIf="step === 1" class="top-content" style="height: 364px">
    <div>
      <span class="cds-h2-light">Add MD Reference Number</span>
    </div>
    <div class="l-mt-7">
      <span class="cds-body2">MD Reference Number</span>
    </div>
    <div class="l-mt-2">
      <cds-textfield label="" placeholder="Input MD Reference Number" [formControl]="mdControl"></cds-textfield>
    </div>
    <div class="l-mt-5">
      <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">*請確保資料與客戶在eMPF上提供的資料一致</span>
    </div>
  </div>
  <div *ngIf="step === 2" class="top-content" style="height: 450px">
    <div>
      <span class="cds-h2-light">Confirmation</span>
    </div>
    <div class="l-mt-7">
      <span class="cds-body1">MD_IFF information</span>
    </div>
    <div class="short-line l-mt-3"></div>
    <div class="l-mt-4">
      <div>
        <span class="cds-body2 confir-title">MD Reference Number</span>
      </div>
      <div class="l-mt-1">
        <span class="cds-body1-demibold">{{ mdControl.value }}</span>
      </div>
    </div>
    <div class="l-mt-7">
      <span class="cds-body1">eMPF Information</span>
    </div>
    <div class="short-line l-mt-3"></div>
    <div class="l-mt-4">
      <div>
        <span class="cds-body2 confir-title">eMPF Reference Number</span>
      </div>
      <div class="l-mt-1">
        <span class="cds-body1-demibold">{{ data.empfNo }}</span>
      </div>
    </div>
    <div *ngIf="data.prodType === 'EE'" class="l-mt-4 l-d-f">
      <div>
        <div>
          <span class="cds-body2 confir-title">Client Name</span>
        </div>
        <div class="l-mt-1">
          <span class="cds-body1-demibold">{{ eeEmpfDetailSimple?.clientName }}</span>
        </div>
      </div>
      <div style="margin-left: 166px">
        <div>
          <span class="cds-body2 confir-title">Phone Number from eMPF</span>
        </div>
        <div class="l-mt-1">
          <span class="cds-body1-demibold">({{ eeEmpfDetailSimple?.phoneCountry }}) {{ eeEmpfDetailSimple?.phone }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="data.prodType === 'ER'" class="l-mt-4 l-d-f">
      <div>
        <div>
          <span class="cds-body2 confir-title">Employer Name</span>
        </div>
        <div class="l-mt-1">
          <span class="cds-body1-demibold">{{ erEmpfDetailSimple?.clientName }}</span>
        </div>
      </div>
      <div style="margin-left: 166px">
        <div>
          <span class="cds-body2 confir-title">Phone Number from eMPF</span>
        </div>
        <div class="l-mt-1">
          <span class="cds-body1-demibold">({{ erEmpfDetailSimple?.phoneCountry }}) {{ erEmpfDetailSimple?.phone }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="button-box">
    <cds-button size="sm" [style]="'secondary'" (click)="onReset()">Reset</cds-button>
    <cds-button [disabled]="isDisabledAdd" *ngIf="step === 1" class="l-ml-4" size="sm" (click)="onAdd()">Add</cds-button>
    <div class="submit">
      <cds-button *ngIf="step === 2" class="l-ml-4" size="sm" (click)="onSubmit()">Confirm and Submit</cds-button>
    </div>
  </div>
</div>
