export enum TransactionType {
  APE_ANN_PREM = 'APE_ANN_PREM',
  SYS_ADJ_APE_RECON = 'SYS_ADJ_APE_RECON',
  SYS_ADJ_APE_REJOIN = 'SYS_ADJ_APE_REJOIN',
  SYS_ADJ_APE_TERM = 'SYS_ADJ_APE_TERM',
  SYS_TRAILER_FEE = 'SYS_TRAILER_FEE',
  APE_PLAN_TRF = 'APE_PLAN_TRF',
  APE_IND_TRF = 'APE_IND_TRF',
  APE_CONTR_112 = 'APE_CONTR_112',
  APE_COMM_ADJ_112 = 'APE_COMM_ADJ_112',
  APE_COMM_ADJ_112P = 'APE_COMM_ADJ_112P',
  APE_COMM_ADJ_112I = 'APE_COMM_ADJ_112I',
  MAN_ADJ_112 = 'MAN_ADJ_112',
  MAN_ADJ_112P = 'MAN_ADJ_112P',
  MAN_ADJ_112I = 'MAN_ADJ_112I',
  APE_CONTR_133 = 'APE_CONTR_133',
  APE_COMM_ADJ_133 = 'APE_COMM_ADJ_133',
  MAN_ADJ_133 = 'MAN_ADJ_133',
  APE_CONTR_115 = 'APE_CONTR_115',
  MAN_ADJ_115 = 'MAN_ADJ_115',
  APE_COMM_ADJ_115 = 'APE_COMM_ADJ_115',
  MAN_ADJ_141ANN = 'MAN_ADJ_141ANN',
  MAN_ADJ_935ANN = 'MAN_ADJ_935ANN',
  MAN_ADJ_136FEE = 'MAN_ADJ_136FEE',
}
