<div class="product-type-summary">
  <div class="top-title">
    <span class="title">MPF eIFF Summary –All Product Types</span>
  </div>

  <div class="content">
    <div class="l-mb-7">
      <span class="cds-h3-light">Select Product Type</span>
    </div>

    <div class="box" [ngClass]="[isIndividualPendingStatus ? 'selected' : '']" (click)="onclickroute(SalesJourneyProdType.Individual)">
      <div class="l-d-f l-ai-cen l-jc-sb">
        <div>
          <span class="cds-h4-demibold" [ngClass]="[isIndividualPendingStatus ? 'selected-title' : '']">Individual (PA, TVC, FRC)</span>
        </div>
        <div *ngIf="isIndividualPendingStatus" class="l-d-f action-flag-box l-ai-cen">
          <div>
            <cds-icon style="--cds-icon-color: var(--cds-bg-item)" icon="action:flag"></cds-icon>
          </div>
          <div class="l-ml-2">
            <span>Pending Items</span>
          </div>
        </div>
      </div>
      <div class="l-mt-5 l-d-f l-ai-cen l-jc-sb">
        <div>
          <div>
            <span class="cds-body1-light">PA</span>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[paSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">Pending to send :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[paSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">{{ paSummary.pendingToMap }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[paSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">Pending customer submission :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[paSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">{{ paSummary.pendingcustomer }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[paSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">Pending split agent :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[paSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">{{ paSummary.pendingAgent }}</span>
            </div>
          </div>
        </div>
        <div>
          <div>
            <span class="cds-body1-light">TVC</span>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[tvcSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">Pending to send :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[tvcSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">{{ tvcSummary.pendingToMap }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[tvcSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">Pending customer submission :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[tvcSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">{{ tvcSummary.pendingcustomer }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[tvcSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">Pending split agent :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[tvcSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">{{ tvcSummary.pendingAgent }}</span>
            </div>
          </div>
        </div>
        <div>
          <div>
            <span class="cds-body1-light">FRC</span>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[frcSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">Pending to send :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[frcSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">{{ frcSummary.pendingToMap }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[frcSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">Pending customer submission :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[frcSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">{{ frcSummary.pendingcustomer }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[frcSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">Pending split agent :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[frcSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">{{ frcSummary.pendingAgent }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box l-mt-4" [ngClass]="[isErPendingStatus ? 'selected' : '']" (click)="onclickroute(SalesJourneyProdType.Corperate)">
      <div class="l-d-f l-ai-cen l-jc-sb">
        <div>
          <span class="cds-h4-demibold" [ngClass]="[isErPendingStatus ? 'selected-title' : '']">Corporate (ER)</span>
        </div>
        <div *ngIf="isErPendingStatus" class="l-d-f action-flag-box l-ai-cen">
          <div>
            <cds-icon style="--cds-icon-color: var(--cds-bg-item)" icon="action:flag"></cds-icon>
          </div>
          <div class="l-ml-2">
            <span>Pending Items</span>
          </div>
        </div>
      </div>
      <div class="l-mt-5 l-d-f l-ai-cen l-jc-sb">
        <div>
          <div class="l-d-f l-ai-cen l-jc-sb">
            <div>
              <span [ngClass]="[erSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">Pending to send :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[erSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">{{ erSummary.pendingToMap }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[erSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">Pending customer submission :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[erSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">{{ erSummary.pendingcustomer }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[erSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">Pending split agent :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[erSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">{{ erSummary.pendingAgent }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box l-mt-4" [ngClass]="[isSepPendingStatus ? 'selected' : '']" (click)="onclickroute(SalesJourneyProdType.SEP)">
      <div class="l-d-f l-ai-cen l-jc-sb">
        <div>
          <span class="cds-h4-demibold" [ngClass]="[isSepPendingStatus ? 'selected-title' : '']">Self-Employed Person (SEP)</span>
        </div>
        <div *ngIf="isSepPendingStatus" class="l-d-f action-flag-box l-ai-cen">
          <div>
            <cds-icon style="--cds-icon-color: var(--cds-bg-item)" icon="action:flag"></cds-icon>
          </div>
          <div class="l-ml-2">
            <span>Pending Items</span>
          </div>
        </div>
      </div>
      <div class="l-mt-5 l-d-f l-ai-cen l-jc-sb">
        <div>
          <div class="l-d-f l-ai-cen l-jc-sb">
            <div>
              <span [ngClass]="[sepSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">Pending to send :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[sepSummary.pendingToMap ? 'cds-body2-demibold' : 'cds-body2']">{{ sepSummary.pendingToMap }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[sepSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">Pending customer submission :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[sepSummary.pendingcustomer ? 'cds-body2-demibold' : 'cds-body2']">{{ sepSummary.pendingcustomer }}</span>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-jc-sb l-mt-1">
            <div>
              <span [ngClass]="[sepSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">Pending split agent :</span>
            </div>
            <div class="l-ml-2">
              <span [ngClass]="[sepSummary.pendingAgent ? 'cds-body2-demibold' : 'cds-body2']">{{ sepSummary.pendingAgent }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
