import { Component, OnDestroy, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { ActionIcons } from '@cds/ng-themes';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';

import { CaseSummaryDS, EmployerColumns, SalesJourneyProdType } from './case-summary.model';
import { AdviseEmpfrefComponent } from './advise-empfref/advise-empfref.component';
import { AdviseIffstatusComponent } from './advise-iffstatus/advise-iffstatus.component';
import { AdviseEmpfstatusComponent } from './advise-empfstatus/advise-empfstatus.component';
import { DisplayDetailsByProductTypeComponent } from './display-details-by-product-type/display-details-by-product-type.component';
import { DeclarationIffComponent } from './declaration-iff/declaration-iff.component';
import { EMPFSTATUS_TYPE_OPTIONS, IFFSTATUS_TYPE_OPTIONS } from './case-summary.config';
import { Subscription } from 'rxjs';
import { CasesummaryService } from './casesummary.service';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CaseSummaryRequest } from './casesummary-request.model';
import { ProductTypeSummaryService } from '../product-type-summary/producttype-summary.service';
import { AgentProductSummary } from '../product-type-summary/product-type-summary.model';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdsLangService } from '@cds/ng-core/lang';
import { DeclarationIffErComponent } from './declaration-iff-er/declaration-iff-er.component';
import { GgiDeclarationPopupComponent } from './ggi-declaration-popup/ggi-declaration-popup.component';
import { TempCacheService } from 'src/app/shared/temp-cache/temp-cache.service';
import { cdsPopupBackdropOntouchmovePreventDefault, cdsPopupBackdropUnOntouchmovePreventDefault, pageToTop } from 'src/app/utils/utils';
import { CdMultidropdownTopping } from 'src/app/shared/cd-multidropdown/cd-multidropdown.model';
import { AgentChannelService } from 'src/app/core/services/agent-channel.service';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { CaseSummaryResponse } from './casesummary-response.model';
import { ProductTypeResponse } from '../product-type-summary/producttype-response';

export enum IndividualType {
  ALL = 'all',
  PA = 'PA',
  TVC = 'TVC',
  FRC = 'FRC',
}

interface ResEmployer {
  brNo: string;
  customerId?: string;
  splitCase: boolean;
  chnEmployerNm: string;
  domainType: string;
  empfRefNo: string;
  empfStatus: string;
  employerNm: string;
  lnkgIffDclrDt: string;
  lnkgIffStatus: string;
  productType: string;
  salesJourneyStatus: string;
  salesJourneySummaryId: string;
}

@Component({
  selector: 'app-case-summary',
  templateUrl: './case-summary.component.html',
  styleUrls: ['./case-summary.component.scss'],
})
export class CaseSummaryComponent implements OnInit, OnDestroy, AfterViewInit {
  IndividualType = IndividualType;
  SalesJourneyProdType = SalesJourneyProdType;
  individualTypeSelected = IndividualType.ALL;
  caseSummaryTotalCount = 0;
  isShowProgress = true;
  isDisableTopCheckboxNoEr = false;
  isDisableTopCheckboxEr = false;
  allPageData: CaseSummaryDS[][] = [];
  allPageDataOfEmployerColumns: EmployerColumns[][] = [];
  private allPageDataForCheck: string[] = [];
  private allPageDataOfEmployerColumnsCheck: string[] = [];
  currentPageNumOrigin = 1;
  totalRecords = 0;
  resetDatePicker = false;
  isAgreeSendIff = false;
  private caseSummarySubscription?: Subscription;
  private productSummarySubscription?: Subscription;
  private errorSub?: Subscription;
  isLoading = false;
  private today = new Date();
  private earliestDate = new Date('1970/01/01');
  declarationDateFromMax: Date = this.today;
  declarationDateFromMin: Date = this.earliestDate;
  declarationDateToMax: Date = this.today;
  declarationDateToMin: Date = this.earliestDate;
  birthdateValueMax: Date = this.today;
  declarationDateFromError = false;
  declarationDateToError = false;
  dateofBirthError = false;
  isRefreshAllChecked = false;

  constructor(
    private router: Router, // private langService: CdsLangService,
    private cdsPopup: CdsPopupService,
    private alert: CdsAlertService,
    public caseSummaryService: CasesummaryService,
    private productTypeSummaryService: ProductTypeSummaryService,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    private tempCacheService: TempCacheService,
    private agentChannelService: AgentChannelService
  ) {}
  ngOnDestroy(): void {
    this.caseSummarySubscription?.unsubscribe();
    this.errorSub?.unsubscribe();
    this.productSummarySubscription?.unsubscribe();
  }

  private oldIffStatusoptionsOrigin: CdMultidropdownTopping[] = [];
  iffStatusoptionsOrigin: CdMultidropdownTopping[] = [];
  iffStatusoptionList = IFFSTATUS_TYPE_OPTIONS;

  appStatusoptionsOrigin: CdMultidropdownTopping[] = [];
  appStatusoptionList = EMPFSTATUS_TYPE_OPTIONS;
  empfRefValue = '';
  clientNameValue = '';
  declaredatefromValue = '';
  declaredatetoValue = '';
  birthdateValue = '';
  employerNameValue = '';
  brNoValue = '';
  hkidOrPassportNo = '';

  result = 0;
  myReqeuest: CaseSummaryRequest = {
    page: 1,
    size: 50,
  };
  private oldIffStatus: number[] = [];

  paSummary = new AgentProductSummary(0, 0, 0, 0);
  tvcSummary = new AgentProductSummary(0, 0, 0, 0);
  frcSummary = new AgentProductSummary(0, 0, 0, 0);
  erSummary = new AgentProductSummary(0, 0, 0, 0);
  sepSummary = new AgentProductSummary(0, 0, 0, 0);
  productTypeCount: {
    pa: AgentProductSummary;
    tvc: AgentProductSummary;
    frc: AgentProductSummary;
    er: AgentProductSummary;
    sep: AgentProductSummary;
  } = {
    pa: this.paSummary,
    tvc: this.tvcSummary,
    frc: this.frcSummary,
    er: this.erSummary,
    sep: this.sepSummary,
  };
  get iffStatusoptions() {
    return this.iffStatusoptionsOrigin.map(item => item.value);
  }

  get appStatusoptions() {
    return this.appStatusoptionsOrigin.map(item => item.value);
  }

  @HostListener('document:keydown.enter', ['$event'])
  onDocumentEnter(): void {
    this.onClickSearch();
  }

  ngAfterViewInit(): void {
    pageToTop();
    setTimeout(() => {
      const searchParams = localStorage.getItem('case-summary-search-params');
      if (searchParams) {
        this.displayedColumns.unshift('isSelected');
        this.employerColumns.unshift('isSelected');
        this.isAgreeSendIff = true;
        this.myReqeuest = JSON.parse(searchParams);
        this.clientNameValue = this.myReqeuest.clientName || '';
        this.empfRefValue = this.myReqeuest.empfRefNo || '';
        this.birthdateValue = this.myReqeuest.dateOfBirth || '';
        this.hkidOrPassportNo = this.myReqeuest.hkidOrPassportNo || '';
        this.declaredatefromValue = this.myReqeuest.declarationDateFrom || '';
        this.declaredatetoValue = this.myReqeuest.declarationDateTo || '';
        this.iffStatusoptionsOrigin = [{ label: 'Pending to Send', value: 0 }];
        this.appStatusoptionsOrigin =
          this.myReqeuest.empfStatus?.map(value => {
            const option = this.appStatusoptionList.filter(item => item.value === value);
            const topping: CdMultidropdownTopping = {
              label: option[0].label,
              value: option[0].value,
            };
            return topping;
          }) || [];
        localStorage.removeItem('case-summary-search-params');
      }

      const data = localStorage.getItem('case-summary-selected-data');
      if (data) {
        const dataList = JSON.parse(data);
        dataList.forEach((item: EmployerColumns | CaseSummaryDS) => {
          this.allPageDataOfEmployerColumnsCheck.push(item.salesJourneySummaryId);
          this.allPageDataForCheck.push(item.salesJourneySummaryId);
        });
      }
      localStorage.removeItem('case-summary-selected-data');
    }, 0);
  }

  producttypeDataSubjectCallBack = (response: ProductTypeResponse) => {
    if (response.result !== undefined) {
      this.result = response.result;
      if (response.data !== undefined) {
        const respData = response.data;
        // TVC
        this.tvcSummary.pendingAgent = respData.TVC ? respData.TVC.pending2ndAgent : 0;
        this.tvcSummary.pendingcustomer = respData.TVC ? respData.TVC.pendingCusSubmission : 0;
        this.tvcSummary.pendingToMap = respData.TVC ? respData.TVC.pendingToMap : 0;
        const tvcPendingAgent = this.tvcSummary.pendingAgent ? this.tvcSummary.pendingAgent : 0;
        const tvcPendingCust = this.tvcSummary.pendingcustomer ? this.tvcSummary.pendingcustomer : 0;
        const tvcPendingToSend = this.tvcSummary.pendingToMap ? this.tvcSummary.pendingToMap : 0;
        this.tvcSummary.allPendingCount = tvcPendingAgent + tvcPendingCust + tvcPendingToSend;

        // PA
        this.paSummary.pendingAgent = respData.PA ? respData.PA.pending2ndAgent : 0;
        this.paSummary.pendingcustomer = respData.PA ? respData.PA.pendingCusSubmission : 0;
        this.paSummary.pendingToMap = respData.PA ? respData.PA.pendingToMap : 0;
        const paPendingAgent = this.paSummary.pendingAgent ? this.paSummary.pendingAgent : 0;
        const paPendingCust = this.paSummary.pendingcustomer ? this.paSummary.pendingcustomer : 0;
        const paPendingToSend = this.paSummary.pendingToMap ? this.paSummary.pendingToMap : 0;
        this.paSummary.allPendingCount = paPendingAgent + paPendingCust + paPendingToSend;

        // FRC
        this.frcSummary.pendingAgent = respData.SVC ? respData.SVC.pending2ndAgent : 0;
        this.frcSummary.pendingcustomer = respData.SVC ? respData.SVC.pendingCusSubmission : 0;
        this.frcSummary.pendingToMap = respData.SVC ? respData.SVC.pendingToMap : 0;
        const frcPendingAgent = this.frcSummary.pendingAgent ? this.frcSummary.pendingAgent : 0;
        const frcPendingCust = this.frcSummary.pendingcustomer ? this.frcSummary.pendingcustomer : 0;
        const frcPendingToSend = this.frcSummary.pendingToMap ? this.frcSummary.pendingToMap : 0;
        this.frcSummary.allPendingCount = frcPendingAgent + frcPendingCust + frcPendingToSend;

        // ER
        this.erSummary.pendingAgent = respData.ER ? respData.ER.pending2ndAgent : 0;
        this.erSummary.pendingcustomer = respData.ER ? respData.ER.pendingCusSubmission : 0;
        this.erSummary.pendingToMap = respData.ER ? respData.ER.pendingToMap : 0;
        const erPendingAgent = this.erSummary.pendingAgent ? this.erSummary.pendingAgent : 0;
        const erPendingCust = this.erSummary.pendingcustomer ? this.erSummary.pendingcustomer : 0;
        const erPendingToSend = this.erSummary.pendingToMap ? this.erSummary.pendingToMap : 0;
        this.erSummary.allPendingCount = erPendingAgent + erPendingCust + erPendingToSend;

        // SEP
        this.sepSummary.pendingAgent = respData.SEP ? respData.SEP.pending2ndAgent : 0;
        this.sepSummary.pendingcustomer = respData.SEP ? respData.SEP.pendingCusSubmission : 0;
        this.sepSummary.pendingToMap = respData.SEP ? respData.SEP.pendingToMap : 0;
        const sepPendingAgent = this.sepSummary.pendingAgent ? this.sepSummary.pendingAgent : 0;
        const sepPendingCust = this.sepSummary.pendingcustomer ? this.sepSummary.pendingcustomer : 0;
        const sepPendingToSend = this.sepSummary.pendingToMap ? this.sepSummary.pendingToMap : 0;
        this.sepSummary.allPendingCount = sepPendingAgent + sepPendingCust + sepPendingToSend;

        this.productTypeCount = {
          pa: this.paSummary,
          tvc: this.tvcSummary,
          frc: this.frcSummary,
          er: this.erSummary,
          sep: this.sepSummary,
        };
      }
    }
  };

  ngOnInit(): void {
    // this.router.getCurrentNavigation().extras.state.example;
    // console.log();

    this.prodTypeFormControl.setValue(sessionStorage.getItem('productType') || SalesJourneyProdType.Individual);
    this.prodTypeChange(this.prodTypeFormControl.value);
    this.productTypeSummaryService.getProducttypeSummary();
    this.productSummarySubscription = this.productTypeSummaryService.producttypeDataSubject.subscribe(this.producttypeDataSubjectCallBack);

    this.errorSub = this.caseSummaryService.errorcasesummarySubject.subscribe(errorMessage => {
      const alertTitle = 'Got Error!';
      this.alert.error(alertTitle, errorMessage);
    });
    this.caseSummarySubscription = this.caseSummaryService.casesummaryDataSubject.subscribe(this.casesummaryDataSubjectCallBack);
  }

  casesummaryDataSubjectCallBack = (response: CaseSummaryResponse) => {
    if (response.result != 0) {
      this.pageCountSbms = 0;
      this.totalRecords = 0;
    } else if (response.data) {
      const totalPages = response.data.totalPages;
      const currentAllPageData = this.allPageData.length;
      if (totalPages > currentAllPageData) {
        for (let i = 0; i < totalPages - currentAllPageData; i++) {
          this.allPageData.push([]);
        }
      } else if (totalPages < currentAllPageData) {
        for (let i = 0; i < currentAllPageData - totalPages; i++) {
          this.allPageData.pop();
        }
      }
      this.totalRecords = response.data.totalElements;
      this.currentPageNumOrigin = response.data.number;

      if (!response.data.empty) {
        this.allPageData[this.currentPageIndex] = response.data.content.map(item => {
          let isSelected = false;
          if (this.isAgreeSendIff) {
            isSelected = this.allPageDataForCheck.includes(item.salesJourneySummaryId);
          }
          const casesummaryitem: CaseSummaryDS = {
            isSelected,
            isDisable: false,
            customerId: item.customerId,
            salesJourneySummaryId: item.salesJourneySummaryId,
            eMPFRef: item.empfRefNo,
            ProductType: item.productType,
            ClientName: item.clientLastName && item.clientFirstName ? item.clientLastName + ' ' + item.clientFirstName : '',
            ClientNameTC: item.clientLastNameTC && item.clientFirstNameTC ? item.clientLastNameTC + item.clientFirstNameTC : '',
            hkidOrPassportNo: item.hkidOrPassportNo,
            IFFDeclarationDate: item.iffDeclarationDate,
            IFFStatus: item.iffStatus.toString(),
            eMPFAppStatus: item.empfStatus.toString(),
            SplitCase: item.splitCase,
          };
          return casesummaryitem;
        });

        this.allPageData.flat().forEach(element => {
          this.onRowCheckOfNoErList(element.isSelected, element);
        });
      }
      this.refreshAllChecked();
    }
    this.isLoading = false;
  };

  getClientName(item: CaseSummaryDS) {
    if (this.cdsLangService.currentLang === 'en') {
      return item.ClientName || item.ClientNameTC;
    } else {
      return item.ClientNameTC || item.ClientName;
    }
  }

  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  get employerPageData() {
    if (this.allPageDataOfEmployerColumns.length === 0) {
      return [];
    }
    return this.allPageDataOfEmployerColumns[this.currentPageIndex];
  }

  pageCountSbms = 1;
  displayedColumns: string[] = [
    // 'isSelected',
    'eMPFRef',
    'ProductType',
    'ClientName',
    'hkidOrPassportNo',
    'IFFDeclarationDate',
    'IFFStatus',
    'eMPFAppStatus',
    'SplitCase',
  ];

  employerColumns: string[] = [
    // 'isSelected',
    'eMPFRef',
    'EmployerName',
    'BRNumber',
    'IFFDeclarationDate',
    'IFFStatus',
    'eMPFAppStatus',
    'SplitCase',
  ];

  updateIcon = ActionIcons.edit;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;

  backSummaryIcon = ActionIcons.button_left_filled;
  // CaseSummarylist : CaseSummary[] =["M12431743261pc","PA","wan....hc","28/07/2022","28/07/2022","P","P","s"]

  backSummaryIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  prodTypeFormControl: FormControl = new FormControl();
  prodTypeDropdownConfig: CdsDropdownConfig = {
    label: 'Choose product Type',
    options: [
      {
        label: 'Individual(PA,TVC,FRC)',
        value: SalesJourneyProdType.Individual,
      },
      { label: 'Corperate(ER)', value: SalesJourneyProdType.Corperate },
      { label: 'SEP', value: SalesJourneyProdType.SEP },
    ],
    color: 'ml',
  };
  onBackSummayIconClick() {
    this.router.navigate(['/salesjourney/product-type-summary/select-product-type']);
  }
  prodTypeChange(value: string) {
    this.prodTypeFormControl.setValue(value);
    const searchParams = localStorage.getItem('case-summary-search-params');
    if (searchParams) {
      this.myReqeuest = JSON.parse(searchParams);
      this.clientNameValue = this.myReqeuest.clientName || '';
      this.empfRefValue = this.myReqeuest.empfRefNo || '';
      this.birthdateValue = this.myReqeuest.dateOfBirth || '';
      this.hkidOrPassportNo = this.myReqeuest.hkidOrPassportNo || '';
      this.declaredatefromValue = this.myReqeuest.declarationDateFrom || '';
      this.declaredatetoValue = this.myReqeuest.declarationDateTo || '';
      this.iffStatusoptionsOrigin = [{ label: 'Pending to Send', value: 0 }];
      this.appStatusoptionsOrigin =
        this.myReqeuest.empfStatus?.map(value => {
          const option = this.appStatusoptionList.filter(item => item.value === value);
          const topping: CdMultidropdownTopping = {
            label: option[0].label,
            value: option[0].value,
          };
          return topping;
        }) || [];
    } else {
      this.myReqeuest = {
        domainType: value,
        productTypes: [],
        page: 1,
        size: 50,
      };
    }

    if (value === SalesJourneyProdType.Individual) {
      delete this.myReqeuest.productTypes;
    } else if (value === SalesJourneyProdType.SEP) {
      this.myReqeuest.productTypes = ['SEP'];
    } else if (value === SalesJourneyProdType.Corperate) {
      this.myReqeuest.productTypes = ['ER'];
    }
    sessionStorage.setItem('productType', value);
    this.individualTypeSelected = IndividualType.ALL;
    this.onResetDatePicker();

    this.clearSearchParams();
    this.getCaseSummary();
    this.isLoading = true;
  }
  pageChange(num: number): void {
    this.myReqeuest.page = num;
    this.getCaseSummary();
    this.isLoading = true;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchByInputPageNobysubmit($event: any, pageNo: string) {
    $event.preventDefault();
    const intPageNo: number = parseInt(pageNo);
    if (intPageNo > this.pageCountSbms) {
      pageNo = '1';
    }
    this.pageChange(parseInt(pageNo));
  }
  checkRecordDetailTag(value: IndividualType) {
    this.individualTypeSelected = value;
    if (value === IndividualType.ALL) {
      delete this.myReqeuest.productTypes;
    } else {
      this.myReqeuest.productTypes = [value];
    }
    this.allPageDataForCheck.length = 0;
    this.allPageDataOfEmployerColumnsCheck.length = 0;
    this.myReqeuest.page = 1;
    this.isLoading = true;
    this.getCaseSummary();
  }
  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'lg',
    });
  }
  onDisplayAdviseIFF() {
    const popupRef: MatDialogRef<AdviseIffstatusComponent> = this.cdsPopup.open(AdviseIffstatusComponent, {
      size: 'sm',
      data: {
        message: 'The changes you’ve made will be lost, are you sure to continue?',
        cancel: 'Cancel',
        continue: 'Continue',
      },
    });
    popupRef.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
    popupRef.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }
  onDisplayAdviseEmpf() {
    const popupRef: MatDialogRef<AdviseEmpfstatusComponent> = this.cdsPopup.open(AdviseEmpfstatusComponent, {
      size: 'sm',
      data: {
        message: 'The changes you’ve made will be lost, are you sure to continue?',
        cancel: 'Cancel',
        continue: 'Continue',
      },
    });
    popupRef.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
    popupRef.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }
  onClickPAitem() {
    const popupRef: MatDialogRef<DisplayDetailsByProductTypeComponent> = this.cdsPopup.open(DisplayDetailsByProductTypeComponent, {
      size: 'sm',
      data: {
        message: 'The changes you’ve made will be lost, are you sure to continue?',
        cancel: 'Cancel',
        continue: 'Continue',
      },
    });
    popupRef.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
    popupRef.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  onClickGetDetailsByProductType(type: string) {
    const popupRef: MatDialogRef<DisplayDetailsByProductTypeComponent> = this.cdsPopup.open(DisplayDetailsByProductTypeComponent, {
      size: 'sm',
      data: {
        count: this.productTypeCount,
        productType: type,
      },
    });
    popupRef.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
    popupRef.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  closeSendIff() {
    this.isAgreeSendIff = false;
    this.displayedColumns.shift();
    this.employerColumns.shift();
    this.onClickReset();
    this.allPageDataForCheck.length = 0;
    this.allPageDataOfEmployerColumnsCheck.length = 0;
  }

  onRoutesend() {
    if (this.isDisableSendIff) {
      return;
    }

    const ggiPopupRef: MatDialogRef<GgiDeclarationPopupComponent> = this.cdsPopup.open(GgiDeclarationPopupComponent, {
      size: 'md',
    });

    ggiPopupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.allPageData = [];
        this.allPageDataOfEmployerColumns = [];
        this.isAgreeSendIff = true;
        this.displayedColumns.unshift('isSelected');
        this.employerColumns.unshift('isSelected');
        this.myReqeuest.page = 1;
        this.myReqeuest.size = 50;
        this.myReqeuest.domainType = this.prodTypeFormControl.value;
        this.oldIffStatus = this.myReqeuest.iffStatus || [];
        this.myReqeuest.iffStatus = [0];
        this.oldIffStatusoptionsOrigin = this.iffStatusoptionsOrigin;
        this.iffStatusoptionsOrigin = [{ label: 'Pending to Send', value: 0 }];
        this.getCaseSummary();
      }
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });

    ggiPopupRef.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  onRouteDeclareIff() {
    if (this.isDisableGenerateGeneralIff) {
      return;
    }
    if (this.prodTypeFormControl.value === SalesJourneyProdType.Corperate) {
      const ref = this.cdsPopup.open(DeclarationIffErComponent, {
        size: 'md',
      });

      ref.afterOpened().subscribe(() => {
        cdsPopupBackdropOntouchmovePreventDefault();
      });

      ref.afterClosed().subscribe((o: { refresh: boolean }) => {
        if (o?.refresh) {
          this.search();
        }
        cdsPopupBackdropUnOntouchmovePreventDefault();
      });
    } else {
      const ref = this.cdsPopup.open(DeclarationIffComponent, {
        size: 'md',
        data: {
          domain: this.prodTypeFormControl.value,
        },
      });

      ref.afterOpened().subscribe(() => {
        cdsPopupBackdropOntouchmovePreventDefault();
      });

      ref.afterClosed().subscribe((o: { refresh: boolean }) => {
        if (o?.refresh) {
          this.search();
        }
        cdsPopupBackdropUnOntouchmovePreventDefault();
      });
    }
  }

  onClickSearch() {
    if (this.isDisableSearch || this.isAgreeSendIff || this.isLoading) {
      return;
    }
    this.search();
  }

  search() {
    this.myReqeuest.page = 1;
    this.myReqeuest.size = 50;
    this.myReqeuest.clientName = this.clientNameValue.trim();
    this.myReqeuest.empfRefNo = this.empfRefValue.trim();
    this.myReqeuest.dateOfBirth = this.birthdateValue.trim();
    this.myReqeuest.hkidOrPassportNo = this.hkidOrPassportNo.trim();
    this.myReqeuest.declarationDateFrom = this.declaredatefromValue.trim();
    this.myReqeuest.declarationDateTo = this.declaredatetoValue.trim();
    this.myReqeuest.iffStatus = this.iffStatusoptions.length > 0 ? this.iffStatusoptions : undefined;
    this.myReqeuest.empfStatus = this.appStatusoptions.length > 0 ? this.appStatusoptions : undefined;
    this.isLoading = true;
    this.getCaseSummary();
  }

  get getSelected() {
    let num = 0;
    if (this.prodTypeFormControl.value === SalesJourneyProdType.Corperate) {
      this.allPageDataOfEmployerColumns.forEach(list => {
        list.forEach(item => {
          if (item.isSelected) {
            num++;
          }
        });
      });
    } else {
      this.allPageData.forEach(list => {
        list.forEach(item => {
          if (item.isSelected) {
            num++;
          }
        });
      });
    }
    return num;
  }

  clearSearchParams() {
    this.brNoValue = '';
    this.employerNameValue = '';
    this.empfRefValue = '';
    this.clientNameValue = '';
    this.declaredatefromValue = '';
    this.declaredatetoValue = '';
    this.birthdateValue = '';
    this.hkidOrPassportNo = '';
    this.appStatusoptionsOrigin = [];
    this.iffStatusoptionsOrigin = [];
    this.declarationDateFromError = false;
    this.declarationDateToError = false;
    this.dateofBirthError = false;
  }

  onClickReset() {
    if (this.isAgreeSendIff) {
      return;
    }
    this.onResetDatePicker();
    this.clearSearchParams();
    this.myReqeuest = {
      page: 1,
      size: 50,
      productTypes: this.myReqeuest.productTypes,
      domainType: this.prodTypeFormControl.value,
    };
    this.isLoading = true;
    this.getCaseSummary();
    this.productTypeSummaryService.getProducttypeSummary();
  }
  empfRefChange(result: string) {
    this.empfRefValue = result;
  }
  clientNameChange(result: string) {
    this.clientNameValue = result;
  }

  declaredatefChange(result: string) {
    if (result === '') {
      this.declarationDateFromError = false;
      return;
    }
    this.declaredatefromValue = result;
    const temp = this.declaredatefromValue.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date' || selectedDate > this.today) {
      this.declarationDateFromError = true;
      return;
    }
    if (this.declaredatetoValue) {
      const tempTo = this.declaredatetoValue.split('/');
      const tempToDate = new Date([tempTo[1], tempTo[0], tempTo[2]].join('/'));
      if (selectedDate > tempToDate) {
        this.declarationDateFromError = true;
        return;
      }
    }
    this.declarationDateFromError = false;

    this.declarationDateToMin = selectedDate;
  }
  declaredatetoChange(result: string) {
    if (result === '') {
      this.declarationDateToError = false;
      return;
    }
    this.declaredatetoValue = result;
    const temp = this.declaredatetoValue.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date' || selectedDate > this.today) {
      this.declarationDateToError = true;
      return;
    }
    if (this.declaredatefromValue) {
      const tempFrom = this.declaredatefromValue.split('/');
      const tempFromDate = new Date([tempFrom[1], tempFrom[0], tempFrom[2]].join('/'));
      if (selectedDate < tempFromDate) {
        this.declarationDateToError = true;
        return;
      }
    }
    this.declarationDateToError = false;

    this.declarationDateFromMax = new Date([temp[1], temp[0], temp[2]].join('/'));
  }
  birthdateChange(result: string) {
    if (result === '') {
      this.dateofBirthError = false;
      return;
    }
    this.birthdateValue = result;
    const temp = result.split('/');
    const selectedDate = new Date([temp[1], temp[0], temp[2]].join('/'));
    if (selectedDate.toString() === 'Invalid Date' || selectedDate > this.today) {
      this.dateofBirthError = true;
      return;
    }
    this.dateofBirthError = false;
  }

  get isDisableSearch() {
    if (this.prodTypeFormControl.value === SalesJourneyProdType.Corperate) {
      return (
        (this.empfRefValue === '' &&
          this.employerNameValue === '' &&
          this.brNoValue === '' &&
          this.appStatusoptions.length === 0 &&
          this.iffStatusoptions.length === 0 &&
          this.declaredatefromValue === '' &&
          this.declaredatetoValue === '') ||
        this.declarationDateFromError ||
        this.declarationDateToError
      );
    } else {
      return (
        (this.empfRefValue === '' &&
          this.clientNameValue === '' &&
          this.appStatusoptions.length === 0 &&
          this.iffStatusoptions.length === 0 &&
          this.declaredatefromValue === '' &&
          this.declaredatetoValue === '' &&
          this.birthdateValue === '' &&
          this.hkidOrPassportNo === '') ||
        this.declarationDateFromError ||
        this.declarationDateToError ||
        this.dateofBirthError
      );
    }
  }

  get isDisableGenerateGeneralIff() {
    return this.disableFoneFtwoByChannel();
  }

  get isDisableSendIff() {
    if (this.disableFoneFtwoByChannel()) {
      return true;
    }
    if (this.prodTypeFormControl.value !== SalesJourneyProdType.Corperate) {
      return this.currentPageData.length === 0;
    } else {
      return this.employerPageData.length === 0;
    }
  }

  disableFoneFtwoByChannel() {
    if (this.agentChannelService.channel && [AgentChannel.SCB, AgentChannel.BROKER].includes(this.agentChannelService.channel)) {
      return true;
    }
    if (this.agentChannelService.channel === AgentChannel.CORPORATE) {
      if (!this.agentChannelService.agentCode?.startsWith('400') || this.agentChannelService.agentCode === '400003') {
        return true;
      }
    }
    return false;
  }

  getCaseSummary() {
    this.isLoading = true;
    if (this.myReqeuest.domainType === SalesJourneyProdType.Corperate) {
      this.myReqeuest.companyName = this.employerNameValue;
      this.myReqeuest.brNo = this.brNoValue;
      delete this.myReqeuest.clientName;
      delete this.myReqeuest.dateOfBirth;
      this.getEmployerSalesJourneySummarie(this.myReqeuest);
    } else {
      delete this.myReqeuest.companyName;
      delete this.myReqeuest.brNo;
      this.caseSummaryService.getCaseSummary(this.myReqeuest);
    }
  }

  getEmployerSalesJourneySummarie(request: CaseSummaryRequest) {
    this.cdHttpServeService
      .post<{
        totalPages: number;
        totalElements: number;
        number: number;
        content: ResEmployer[];
        empty: boolean;
      }>(`/ext/eb-ssms-sales-journey-service/employerSalesJourneySummaries`, request)
      .subscribe({
        next: response => {
          if (response.result != 0) {
            this.pageCountSbms = 0;
            this.totalRecords = 0;
          } else if (response.data) {
            const totalPages = response.data.totalPages;
            const currentAllPageData = this.allPageDataOfEmployerColumns.length;
            if (totalPages > currentAllPageData) {
              for (let i = 0; i < totalPages - currentAllPageData; i++) {
                this.allPageDataOfEmployerColumns.push([]);
              }
            } else if (totalPages < currentAllPageData) {
              for (let i = 0; i < currentAllPageData - totalPages; i++) {
                this.allPageDataOfEmployerColumns.pop();
              }
            }
            this.totalRecords = response.data.totalElements;
            this.currentPageNumOrigin = response.data.number;

            if (!response.data.empty) {
              this.allPageDataOfEmployerColumns[this.currentPageIndex] = response.data.content.map(item => {
                let isSelected = false;
                if (this.isAgreeSendIff) {
                  isSelected = this.allPageDataOfEmployerColumnsCheck.includes(item.salesJourneySummaryId);
                }
                const casesummaryitem: EmployerColumns = {
                  isSelected,
                  isDisable: false,
                  customerId: item.customerId,
                  salesJourneySummaryId: item.salesJourneySummaryId,
                  eMPFRef: item.empfRefNo,
                  EmployerName: item.employerNm,
                  EmployerNameTC: item.chnEmployerNm,
                  BRNumber: item.brNo,
                  IFFDeclarationDate: item.lnkgIffDclrDt,
                  IFFStatus: item.lnkgIffStatus.toString(),
                  eMPFAppStatus: item.empfStatus.toString(),
                  SplitCase: item.splitCase,
                };
                return casesummaryitem;
              });

              this.allPageDataOfEmployerColumns.flat().forEach(element => {
                this.onRowCheckOfErList(element.isSelected, element);
              });
            }
          }
          this.isLoading = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isLoading = false;
        },
      });
  }

  onRowCheckOfErList(boo: boolean, element: EmployerColumns) {
    element.isSelected = boo;

    if (element.isSelected && element.SplitCase) {
      this.allPageDataOfEmployerColumns.forEach(list => {
        list.forEach(item => {
          if (element.salesJourneySummaryId !== item.salesJourneySummaryId) {
            item.isDisable = true;
          }
        });
      });
      this.isDisableTopCheckboxEr = true;
    } else if (element.isSelected && !element.SplitCase) {
      this.allPageDataOfEmployerColumns.forEach(list => {
        list.forEach(item => {
          if (item.SplitCase) {
            item.isDisable = true;
          }
        });
      });
    }

    if (boo) {
      this.allPageDataOfEmployerColumnsCheck.push(element.salesJourneySummaryId);
    } else {
      this.allPageDataOfEmployerColumnsCheck = this.allPageDataOfEmployerColumnsCheck.filter(itemId => itemId !== element.salesJourneySummaryId);
      if (this.allPageDataOfEmployerColumns.flat().every(item => !item.isSelected)) {
        this.allPageDataOfEmployerColumns.flat().forEach(element => {
          element.isDisable = false;
        });
        this.isDisableTopCheckboxEr = false;
      }
    }
  }

  erChangeListIsAllChecked() {
    return this.employerPageData.every(item => item.isSelected);
  }

  erChangeListIsHalfChecked() {
    const isAllChecked = this.erChangeListIsAllChecked();
    const isAllUnChecked = this.employerPageData.every(item => !item.isSelected);

    return !isAllChecked && !isAllUnChecked;
  }

  onErAllCheckedChange(boo: boolean) {
    this.employerPageData.forEach(element => {
      if (!element.SplitCase) {
        element.isSelected = boo;
        if (boo) {
          this.allPageDataOfEmployerColumnsCheck.push(element.salesJourneySummaryId);
        } else {
          this.allPageDataOfEmployerColumnsCheck = this.allPageDataOfEmployerColumnsCheck.filter(itemId => itemId !== element.salesJourneySummaryId);
        }
      } else {
        element.isDisable = boo;
      }
    });
  }

  onRowCheckOfNoErList(boo: boolean, element: CaseSummaryDS) {
    element.isSelected = boo;

    if (element.isSelected && element.SplitCase) {
      this.allPageData.forEach(list => {
        list.forEach(item => {
          if (element.salesJourneySummaryId !== item.salesJourneySummaryId) {
            item.isDisable = true;
          }
        });
      });
      this.isDisableTopCheckboxNoEr = true;
    } else if (element.isSelected && !element.SplitCase) {
      this.allPageData.forEach(list => {
        list.forEach(item => {
          if (item.SplitCase) {
            item.isDisable = true;
          }
        });
      });
    }

    if (boo) {
      this.allPageDataForCheck.push(element.salesJourneySummaryId);
    } else {
      this.allPageDataForCheck = this.allPageDataForCheck.filter(itemId => itemId !== element.salesJourneySummaryId);
      if (this.allPageData.flat().every(item => !item.isSelected)) {
        this.allPageData.flat().forEach(element => {
          element.isDisable = false;
        });
        this.isDisableTopCheckboxNoEr = false;
      }
    }
  }

  noErChangeListIsAllChecked() {
    return this.currentPageData.every(item => item.isSelected);
  }

  noErChangeListIsHalfChecked() {
    const isAllChecked = this.noErChangeListIsAllChecked();
    const isAllUnChecked = this.currentPageData.every(item => !item.isSelected);

    return !isAllChecked && !isAllUnChecked;
  }

  onNoErAllCheckedChange(boo: boolean) {
    this.currentPageData.forEach(element => {
      if (!element.SplitCase) {
        element.isSelected = boo;
        if (boo) {
          this.allPageDataForCheck.push(element.salesJourneySummaryId);
        } else {
          this.allPageDataForCheck = this.allPageDataForCheck.filter(itemId => itemId !== element.salesJourneySummaryId);
        }
      } else {
        element.isDisable = boo;
      }
    });
  }

  getEmployerName(element: EmployerColumns) {
    if (this.cdsLangService.currentLang === 'en') {
      return element.EmployerName || element.EmployerNameTC;
    } else {
      return element.EmployerNameTC || element.EmployerName;
    }
  }

  navigateToCaseDetail(salesJourneySummariesId: string, empfRefNo: string) {
    return `/salesjourney/${
      this.prodTypeFormControl.value === SalesJourneyProdType.Corperate ? 'case-details-er' : 'case-details'
    }/${salesJourneySummariesId}/${empfRefNo}`;
  }

  onResetDatePicker() {
    this.declarationDateFromMax = this.today;
    this.declarationDateFromMin = this.earliestDate;
    this.declarationDateToMax = this.today;
    this.declarationDateToMin = this.earliestDate;
    this.resetDatePicker = true;
    setTimeout(() => {
      this.resetDatePicker = false;
    }, 0);
  }

  saveErData(element: EmployerColumns) {
    this.tempCacheService.setItem(`case-summary-er-${element.eMPFRef}`, element);
    this.tempCacheService.saveToLocalStorage();
  }

  saveData(element: CaseSummaryDS) {
    this.tempCacheService.setItem(`case-summary-ee-${element.eMPFRef}`, element);
    this.tempCacheService.saveToLocalStorage();
  }

  goSendIffInBatchSummary() {
    if (this.disabledContinue) {
      return;
    }

    localStorage.setItem('case-summary-search-params', JSON.stringify(this.myReqeuest));

    const selectedData: (CaseSummaryDS | EmployerColumns)[] = [];
    if (this.prodTypeFormControl.value !== SalesJourneyProdType.Corperate) {
      this.allPageData.forEach(list => {
        list.forEach(item => {
          if (item.isSelected) {
            selectedData.push(item);
          }
        });
      });
    } else {
      this.allPageDataOfEmployerColumns.forEach(list => {
        list.forEach(item => {
          if (item.isSelected) {
            selectedData.push(item);
          }
        });
      });
    }
    localStorage.setItem('case-summary-selected-data', JSON.stringify(selectedData));
    if (this.prodTypeFormControl.value !== SalesJourneyProdType.Corperate) {
      this.router.navigate(['/salesjourney/send-iff-in-batch-summary']);
    } else {
      this.router.navigate(['/salesjourney/er-send-iff-in-batch-summary']);
    }
  }

  get disabledContinue() {
    return this.getSelected === 0;
  }

  refreshAllChecked() {
    this.isRefreshAllChecked = true;
    setTimeout(() => {
      this.isRefreshAllChecked = false;
    }, 0);
  }
}
