import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsPopupModule } from '@cds/ng-web-components/popup';

import { FinancialJournalComponent } from './financial-journal.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CdsDatepickerModule,
    CdsLanguageModule,
    CdsFormFieldModule,
    CdsTextfieldModule,
    CdsAssistiveTextModule,
    CdsButtonModule,
    CdsPopupModule,
  ],
  declarations: [FinancialJournalComponent],
})
export class FinancialJournalModule {}
