/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsToastService } from '@cds/ng-web-components/toast';
import moment from 'moment';
import { finalize } from 'rxjs';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { AuditLogService } from './audit-log.service';

import { dateRangeInCompleteValidator, dateRangeInvalidValidator, dateValidator, uuidValidator } from 'src/app/core/validators';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { aoaToSheet, FileType } from 'src/app/utils/xlsx';
import {
  AccountTypeOptions,
  AUDIT_HISTORY_COLUMNS,
  EXCEL_COLUMNS,
  FILE_NAME,
  I18N_KEY,
  PAGE_SIZE,
  ServiceOptions,
  SubServiceOptions,
} from './audit-history.config';

@Component({
  styleUrls: ['./audit-history.component.scss'],
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
})
export class AuditHistoryComponent implements OnInit {
  isShowSearchDatePicker = true; // Fix DatePicker Bug
  serviceOptions = ServiceOptions;
  subServiceOptions = SubServiceOptions;
  accountTypesOptions = AccountTypeOptions;
  previousSearchParams: any;
  isDownLoading = false;
  isDataLoading = false;
  pageConfig: PageConfig = {};
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = AUDIT_HISTORY_COLUMNS;
  formGroup = new FormGroup(
    {
      startDate: new FormControl(null, [
        dateValidator({
          error: I18N_KEY.DATE_INVALID,
        }),
      ]),
      endDate: new FormControl(null, [
        dateValidator({
          error: I18N_KEY.DATE_INVALID,
        }),
      ]),
      service: new FormControl(null),
      serviceSection: new FormControl(null),
      accountType: new FormControl(null),
      accountCode: new FormControl(null, [
        uuidValidator({
          error: I18N_KEY.ACCOUNT_CODE_INVALID,
        }),
      ]),
    },
    [
      dateRangeInCompleteValidator('startDate', 'endDate', { error: I18N_KEY.DATE_RANGE_INCOMPLETE }),
      dateRangeInvalidValidator('startDate', 'endDate', { error: I18N_KEY.DATE_RANGE_INVALID }),
    ]
  );

  get startDate() {
    return this.formGroup.get('startDate') as FormControl;
  }

  get endDate() {
    return this.formGroup.get('endDate') as FormControl;
  }

  get service() {
    return this.formGroup.get('service') as FormControl;
  }

  get serviceSection() {
    return this.formGroup.get('serviceSection') as FormControl;
  }

  get accountType() {
    return this.formGroup.get('accountType') as FormControl;
  }

  get accountCode() {
    return this.formGroup.get('accountCode') as FormControl;
  }

  get searchDisabled() {
    let tag = true;
    const controlValues = this.formGroup.value;
    const keys = Object.keys(controlValues);
    keys.forEach((key: string) => {
      if (controlValues[key]) {
        tag = false;
      }
    });
    return tag || this.formGroup.invalid || this.isDataLoading;
  }

  get getTotalCount() {
    return this.pageConfig.totalCount || 0;
  }

  get downloadDisabled() {
    return !this.previousSearchParams || this.isDownLoading || !this.getTotalCount;
  }
  constructor(private toast: CdsToastService, private auditLogService: AuditLogService) {}

  ngOnInit() {
    // this.search();
  }

  search() {
    this.previousSearchParams = null;
    this.pageConfig = {
      pageSize: PAGE_SIZE,
      current: 1,
    };
    this.getData();
  }

  getData() {
    this.isDataLoading = true;
    const params = this.createParams();
    this.auditLogService
      .getData(params)
      .pipe(
        finalize(() => {
          this.isDataLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.previousSearchParams = params;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: res.data.totalElements,
          };
          const tempData = res.data.content || [];
          this.dataDisplayed = this.createDisplayData(tempData);
        } else {
          this.toast.error(res.message);
        }
      });
  }

  createDisplayData(data: any[]) {
    return data.map((item: any) => {
      const before = item.before || {};
      const after = item.after || {};
      const fields = Object.keys({
        ...before,
        ...after,
      });
      const beforeArr: string[] = [];
      const afterArr: string[] = [];
      fields.forEach(key => {
        beforeArr.push(before[key] === undefined ? '' : before[key]);
        afterArr.push(after[key] === undefined ? '' : after[key]);
      });
      if (this.previousSearchParams.service === 'eb-ssms-commission-service') {
        const temp = item.section_key.split(':');
        return {
          ...item,
          sub_section: item.sub_section ? item.sub_section.split(':')[0] : '',
          action: item.action,
          originalValue: beforeArr,
          changedValue: afterArr,
          section_key: `${temp[0]}${temp[0] ? ' ' : ''}#${temp[1]}`,
          fields,
          createBy: item.createBy.split(':')[0],
        };
      }
      return {
        ...item,
        originalValue: beforeArr,
        changedValue: afterArr,
        fields,
        createBy: item.createBy,
      };
    });
  }

  createParams() {
    const params: any = {
      page: (this.pageConfig.current || 1) - 1,
      size: PAGE_SIZE,
    };
    if (this.previousSearchParams) {
      return { ...this.previousSearchParams, ...params };
    }
    const formValue = this.getFormValue();
    if (formValue.startDate) {
      formValue.startDate = moment(formValue.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (formValue.endDate) {
      formValue.endDate = moment(formValue.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (formValue.accountType && formValue.accountCode) {
      formValue.customerid = `${formValue.accountType}_${formValue.accountCode}`;
    }
    return { ...params, ...formValue };
  }

  getFormValue() {
    const valueTemp: any = {};
    const value = this.formGroup.value;
    const keys: string[] = Object.keys(value);
    keys.forEach(key => {
      const inputValue = value[key] ? value[key].trim() : '';
      if (inputValue) {
        valueTemp[key] = inputValue;
      }
    });
    return valueTemp;
  }

  reset() {
    this.previousSearchParams = null;
    this.formGroup.reset();
    this.isShowSearchDatePicker = false;
    this.pageConfig = {};
    setTimeout(() => {
      this.isShowSearchDatePicker = true; // Fix DatePicker Bug
    });
    this.search();
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.getData();
    }
  }

  download() {
    if (this.downloadDisabled) {
      return;
    }
    this.isDownLoading = true;
    const params = {
      ...this.previousSearchParams,
      current: 1,
      size: this.getTotalCount,
    };
    this.auditLogService
      .getData(params)
      .pipe(
        finalize(() => {
          this.isDownLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          const data = this.createDisplayData(res.data.content || []);
          const excelData = this.createExcelData(data);
          aoaToSheet(
            {
              fileName: `${FILE_NAME} ${moment().format('YYYYMMDD_HHmmss')}`,
              fileType: FileType.CSV,
            },
            excelData
          );
        } else {
          this.toast.error(res.message);
        }
      });
  }

  createExcelData(data: any[]) {
    const excelData: any[] = [];
    excelData.push(EXCEL_COLUMNS);
    data.forEach(item => {
      const temp = [];
      temp.push(moment(item.updatedAt).format('DD/MM/YYYY HH:mm:ss'));
      temp.push(item.sub_section);
      temp.push(item.action);
      temp.push(item.section_key);
      temp.push(item.originalValue);
      temp.push(item.changedValue);
      temp.push(item.createBy);
      excelData.push(temp);
    });
    return excelData;
  }
}
