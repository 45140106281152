/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { BasicResponse } from 'src/app/core/models/response/response';
@Injectable({
  providedIn: 'root',
})
export class ReportCommissionService {
  constructor(private http: HttpClient) {}

  getData(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.reportingServiceExt}${'/api/v1/report-files/query/Commission'}`, params);
  }
}
