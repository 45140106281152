import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsRadioConfig } from '@cds/ng-core/radio';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { NotifyService } from 'src/app/core/services/notify.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { WillBeLostComponent } from 'src/app/shared/will-be-lost/will-be-lost.component';
import { keepDecimalDigitFull, pageToTop } from 'src/app/utils/utils';
import { EECaseDetailsResponse } from '../empf-case-details/empf-case-details.model';
import { ERCaseDetailsResponse } from '../er-empf-case-details/er-empf-case-details.model';
import { SalesjourneyService } from '../salesjourney.service';
import { AddNewCommissionPopupComponent } from './add-new-commission-popup/add-new-commission-popup.component';
import { LeastOneWarningComponent } from './least-one-warning/least-one-warning.component';
import { UpdatedSuccessfullyComponent } from 'src/app/shared/updated-successfully/updated-successfully.component';

type RolePriority = 'SuperAdmin' | 'SalesSupport' | 'SalesJourneyAgent';

@Component({
  selector: 'app-edit-agent-details',
  templateUrl: './edit-agent-details.component.html',
  styleUrls: ['./edit-agent-details.component.scss'],
})
export class EditAgentDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  isConfirming = false;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;
  targetType?: 'EE' | 'ER';
  form = new FormGroup({
    agentList: new FormArray([]),
  });
  servicingAgentRadioConfig: CdsRadioConfig = {
    options: [{ label: '', value: true }],
  };
  isErrPercentage100 = false;
  isErrEquallySplit = false;
  isErrSmallerOne = false;

  private errPercentage100Values: string[] = [];
  get errPercentage100() {
    return `The ${this.errPercentage100Values.join(', ')} percentage must add up to 100% in total.`;
  }
  private errPercentage100SubValues: string[] = [];
  get errPercentage100Sub() {
    return `Please check the distribution of ${this.errPercentage100SubValues.join(', ')}`;
  }

  private errEquallySplitValues: string[] = [];
  get errEquallySplit() {
    return `The ${this.errEquallySplitValues.join(', ')} percentage of the servicing agent and split agent must be equally split.`;
  }
  private errEquallySplitSubValues: string[] = [];
  get errEquallySplitSub() {
    return `Please check the distribution of ${this.errEquallySplitSubValues.join(', ')}`;
  }

  mdNo?: string;
  caseDetails?: EECaseDetailsResponse;
  erCaseDetails?: ERCaseDetailsResponse;

  hasSalesSupportRole = false;
  hasSalesJourneyAgentRole = false;
  hasSalesJourneySuperAdmin = false;
  rolePriority?: RolePriority;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public empfAppStatusService: EmpfAppStatusService,
    private cdsPopup: CdsPopupService,
    private fb: FormBuilder,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    public cdsLangService: CdsLangService,
    private location: Location,
    private notifyService: NotifyService,
    private languageChangeService: LanguageChangeService,
    private salesjourneyService: SalesjourneyService,
    private permissionService: PermissionService
  ) {}

  get agentList() {
    return this.form.controls['agentList'] as FormArray;
  }

  private resizeListener = () => {
    const isMobile = window.matchMedia('only screen and (max-width: 1023px)').matches || window.screen.width < 1023;
    if (isMobile) {
      this.notifyService.notifyHideNavigationLang();
    }
  };

  ngAfterViewInit(): void {
    pageToTop();
    this.notifyService.notifyHideNavigationLang();
    window.addEventListener('resize', this.resizeListener);
    this.languageChangeService.setLang('en');
  }

  ngOnDestroy(): void {
    this.notifyService.notifyDisplayNavigationLang();
    window.removeEventListener('resize', this.resizeListener);
  }

  private async initRole() {
    this.hasSalesJourneySuperAdmin = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SUPER_ADMIN);
    this.hasSalesSupportRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SALES_SUPPORT);
    this.hasSalesJourneyAgentRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_AGENT);
    if (this.hasSalesJourneySuperAdmin) {
      this.rolePriority = 'SuperAdmin';
    } else if (this.hasSalesSupportRole) {
      this.rolePriority = 'SalesSupport';
    } else if (this.hasSalesJourneyAgentRole) {
      this.rolePriority = 'SalesJourneyAgent';
    }
  }

  ngOnInit(): void {
    if (this.disableEditAgentByChannel()) {
      this.router.navigate(['/permission-error']);
      return;
    }

    this.mdNo = this.route.snapshot.paramMap.get('mdNo') || undefined;
    this.targetType = (this.route.snapshot.paramMap.get('type') as 'EE' | 'ER') || undefined;

    if (this.mdNo) {
      this.notifyService.screenLoadingSubject.next(true);
      if (this.targetType === 'EE') {
        this.initRole().then(() => {
          let urlRolePart = '';
          switch (this.rolePriority) {
            case 'SalesJourneyAgent':
              urlRolePart = 'agent';
              break;
            case 'SalesSupport':
              urlRolePart = 'support';
              break;
            case 'SuperAdmin':
              urlRolePart = 'admin';
              break;
            default:
              break;
          }
          this.cdHttpServeService
            .get<EECaseDetailsResponse>(`/ext/eb-ssms-sales-journey-service/sj/caseDetailByMd/${urlRolePart}`, { mdNo: this.mdNo })
            .subscribe({
              next: this.postMemberCaseDetailsCallBack,
              error: err => {
                this.alert.error('Error!', err);
                this.notifyService.screenLoadingSubject.next(false);
              },
            });
        });
      } else if (this.targetType === 'ER') {
        this.initRole().then(() => {
          let urlRolePart = '';
          switch (this.rolePriority) {
            case 'SalesJourneyAgent':
              urlRolePart = 'agent';
              break;
            case 'SalesSupport':
              urlRolePart = 'support';
              break;
            case 'SuperAdmin':
              urlRolePart = 'admin';
              break;
            default:
              break;
          }
          this.cdHttpServeService
            .get<ERCaseDetailsResponse>(`/ext/eb-ssms-sales-journey-service/sj/caseDetailByMd/${urlRolePart}`, { mdNo: this.mdNo })
            .subscribe({
              next: this.postEmployerCaseDetailsCallBack,
              error: err => {
                this.alert.error('Error!', err);
                this.notifyService.screenLoadingSubject.next(false);
              },
            });
        });
      }
    }
  }

  postEmployerCaseDetailsCallBack = (res: { result: number; message: string; data: ERCaseDetailsResponse }) => {
    if (res.result !== 0) {
      this.alert.warning('Warning!', `${res.message}`);
    } else {
      this.form = new FormGroup({
        agentList: new FormArray([]),
      });
      this.erCaseDetails = res.data;

      this.erAgentsInfos.forEach((agent, index: number) => {
        this.agentList.push(
          this.fb.group({
            agentCode: this.fb.control(agent.agentCode),
            agentName: this.fb.control(agent.agentName),
            agentNameTC: this.fb.control(agent.agentNameTC),
            servicingAgent: this.fb.control(index === 0),
            shareHKPC: this.fb.control(agent.shareHKPC ? keepDecimalDigitFull(agent.shareHKPC * 100, 3) + '' : ''),
            shareCommission: this.fb.control(agent.shareCommission ? keepDecimalDigitFull(agent.shareCommission * 100, 3) + '' : ''),
          })
        );
      });
    }
    this.notifyService.screenLoadingSubject.next(false);
  };

  get eeAgentsInfos() {
    const agentsInfos = [];
    if (this.caseDetails?.serviceAgent) {
      agentsInfos.push(this.caseDetails.serviceAgent);
    }
    if (this.caseDetails?.splitAgent) {
      agentsInfos.push(...this.caseDetails.splitAgent);
    }
    return agentsInfos;
  }

  get erAgentsInfos() {
    const agentsInfos = [];
    if (this.erCaseDetails?.serviceAgent) {
      agentsInfos.push(this.erCaseDetails.serviceAgent);
    }
    if (this.erCaseDetails?.splitAgent) {
      agentsInfos.push(...this.erCaseDetails.splitAgent);
    }
    return agentsInfos;
  }

  postMemberCaseDetailsCallBack = (res: { result: number; message: string; data: EECaseDetailsResponse }) => {
    if (res.result !== 0) {
      this.alert.warning('Warning!', `${res.message}`);
    } else {
      this.form = new FormGroup({
        agentList: new FormArray([]),
      });
      this.caseDetails = res.data;

      this.eeAgentsInfos.forEach((agent, index: number) => {
        this.agentList.push(
          this.fb.group({
            agentCode: this.fb.control(agent.agentCode),
            agentName: this.fb.control(agent.agentName),
            agentNameTC: this.fb.control(agent.agentNameTC),
            servicingAgent: this.fb.control(index === 0),
            shareHKPC: this.fb.control(agent.shareHKPC ? keepDecimalDigitFull(agent.shareHKPC * 100, 3) + '' : ''),
            shareCommission: this.fb.control(agent.shareCommission ? keepDecimalDigitFull(agent.shareCommission * 100, 3) + '' : ''),
          })
        );
      });
    }
    this.notifyService.screenLoadingSubject.next(false);
  };

  onBack() {
    if (this.isConfirming) {
      return;
    }
    this.location.back();
  }

  servicingAgentChange(index: number, current: AbstractControl) {
    if (this.asFormGroup(current).controls['servicingAgent'].value === false) {
      return;
    }

    let i = 0;
    for (const agent of this.agentList.controls) {
      if (i !== index) {
        this.asFormGroup(agent).controls['servicingAgent'].setValue(false);
      }
      i++;
    }
  }

  reset() {
    const popupRef: MatDialogRef<WillBeLostComponent> = this.cdsPopup.open(WillBeLostComponent, {
      size: 'md',
    });

    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.form = new FormGroup({
          agentList: new FormArray([]),
        });
        if (this.targetType === 'EE') {
          this.eeAgentsInfos.forEach((agent, index: number) => {
            this.agentList.push(
              this.fb.group({
                agentCode: this.fb.control(agent.agentCode),
                agentName: this.fb.control(agent.agentName),
                agentNameTC: this.fb.control(agent.agentNameTC),
                servicingAgent: this.fb.control(index === 0),
                shareHKPC: this.fb.control(agent.shareHKPC ? keepDecimalDigitFull(agent.shareHKPC * 100, 3) + '' : ''),
                shareCommission: this.fb.control(agent.shareCommission ? keepDecimalDigitFull(agent.shareCommission * 100, 3) + '' : ''),
              })
            );
          });
        } else if (this.targetType === 'ER') {
          this.erAgentsInfos.forEach((agent, index: number) => {
            this.agentList.push(
              this.fb.group({
                agentCode: this.fb.control(agent.agentCode),
                agentName: this.fb.control(agent.agentName),
                agentNameTC: this.fb.control(agent.agentNameTC),
                servicingAgent: this.fb.control(index === 0),
                shareHKPC: this.fb.control(agent.shareHKPC ? keepDecimalDigitFull(agent.shareHKPC * 100, 3) + '' : ''),
                shareCommission: this.fb.control(agent.shareCommission ? keepDecimalDigitFull(agent.shareCommission * 100, 3) + '' : ''),
              })
            );
          });
        }
        this.isErrEquallySplit = false;
        this.isErrPercentage100 = false;
      }
    });
  }

  backToEdit() {
    this.isConfirming = false;
  }

  confirm() {
    const agentInfos = [];
    for (const agent of this.agentList.controls) {
      agentInfos.push({
        agentCode: this.asFormGroup(agent).controls['agentCode'].value,
        isServiceAgent: this.asFormGroup(agent).controls['servicingAgent'].value,
        commissionPt: +this.asFormGroup(agent).controls['shareCommission'].value / 100,
        hkPcPercentagePt: +this.asFormGroup(agent).controls['shareHKPC'].value / 100,
      });
    }

    if (this.mdNo) {
      const params = {
        mdNo: this.mdNo,
        exist: agentInfos,
      };
      this.notifyService.screenLoadingSubject.next(true);
      this.cdHttpServeService.post('/ext/eb-ssms-sales-journey-service/md/update/agent', params).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            const ref = this.cdsPopup.open(UpdatedSuccessfullyComponent, {
              size: 'md',
            });
            ref.afterClosed().subscribe(() => {
              this.location.back();
            });
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
    }
  }

  clearErrPercentage100() {
    this.isErrPercentage100 = false;

    if (!this.isErrEquallySplit && !this.isErrSmallerOne) {
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareHKPC'].setErrors(null);
        this.asFormGroup(agent).controls['shareCommission'].setErrors(null);
      }
    }
  }

  clearErrEquallySplit() {
    this.isErrEquallySplit = false;

    if (!this.isErrPercentage100 && !this.isErrSmallerOne) {
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareHKPC'].setErrors(null);
        this.asFormGroup(agent).controls['shareCommission'].setErrors(null);
      }
    }
  }

  clearErrSmallerOne() {
    this.isErrSmallerOne = false;

    if (!this.isErrPercentage100 && !this.isErrEquallySplit) {
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareHKPC'].setErrors(null);
        this.asFormGroup(agent).controls['shareCommission'].setErrors(null);
      }
    }
  }

  save() {
    if (this.isDisableSave) {
      return;
    }

    this.errPercentage100Values = [];
    this.errPercentage100SubValues = [];
    this.errEquallySplitValues = [];
    this.errEquallySplitSubValues = [];
    let sumShareHKPC = 0;
    let sumShareCommission = 0;
    this.isErrEquallySplit = false;
    this.isErrPercentage100 = false;
    this.isErrSmallerOne = false;
    for (const agent of this.agentList.controls) {
      if (this.asFormGroup(agent).controls['shareHKPC'].value === '') {
        this.asFormGroup(agent).controls['shareHKPC'].setValue('0.000');
      }
      if (this.asFormGroup(agent).controls['shareCommission'].value === '') {
        this.asFormGroup(agent).controls['shareCommission'].setValue('0.000');
      }
      const shareHKPCVal = +this.asFormGroup(agent).controls['shareHKPC'].value;
      sumShareHKPC += shareHKPCVal;
      const shareCommissionVal = +this.asFormGroup(agent).controls['shareCommission'].value;
      sumShareCommission += shareCommissionVal;

      if (shareHKPCVal < 1) {
        this.isErrSmallerOne = true;
        this.asFormGroup(agent).controls['shareHKPC'].markAsDirty();
        this.asFormGroup(agent).controls['shareHKPC'].setErrors({ format: true });
      }
      if (shareCommissionVal < 1) {
        this.isErrSmallerOne = true;
        this.asFormGroup(agent).controls['shareCommission'].markAsDirty();
        this.asFormGroup(agent).controls['shareCommission'].setErrors({ format: true });
      }
    }
    if (sumShareHKPC !== 100) {
      this.isErrPercentage100 = true;
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareHKPC'].markAsDirty();
        this.asFormGroup(agent).controls['shareHKPC'].setErrors({ format: true });
      }
      this.errPercentage100Values.push('Share PC');
      this.errPercentage100SubValues.push('hkpc%');
    }
    if (sumShareCommission !== 100) {
      this.isErrPercentage100 = true;
      for (const agent of this.agentList.controls) {
        this.asFormGroup(agent).controls['shareCommission'].markAsDirty();
        this.asFormGroup(agent).controls['shareCommission'].setErrors({ format: true });
      }
      this.errPercentage100Values.push('Share commission');
      this.errPercentage100SubValues.push('commission%');
    }
    if (this.agentList.controls.length >= 2) {
      if (this.targetType === 'EE') {
        if (
          sumShareHKPC === 100 &&
          ((this.agentList.controls[0] as FormGroup).controls['shareHKPC'].value !== '50.000' ||
            (this.agentList.controls[1] as FormGroup).controls['shareHKPC'].value !== '50.000')
        ) {
          this.isErrEquallySplit = true;
          this.errEquallySplitValues.push('Share PC');
          this.errEquallySplitSubValues.push('hkpc%');
          for (const agent of this.agentList.controls) {
            this.asFormGroup(agent).controls['shareHKPC'].markAsDirty();
            this.asFormGroup(agent).controls['shareHKPC'].setErrors({ format: true });
          }
        }
        if (
          sumShareCommission === 100 &&
          ((this.agentList.controls[0] as FormGroup).controls['shareCommission'].value !== '50.000' ||
            (this.agentList.controls[1] as FormGroup).controls['shareCommission'].value !== '50.000')
        ) {
          this.isErrEquallySplit = true;
          this.errEquallySplitValues.push('Share commission');
          this.errEquallySplitSubValues.push('commission%');
          for (const agent of this.agentList.controls) {
            this.asFormGroup(agent).controls['shareCommission'].markAsDirty();
            this.asFormGroup(agent).controls['shareCommission'].setErrors({ format: true });
          }
        }
      }
    }

    if (this.isErrEquallySplit) {
      return;
    }
    if (this.isErrPercentage100) {
      return;
    }
    if (this.isErrSmallerOne) {
      return;
    }

    this.isConfirming = true;
    pageToTop();
  }

  delete(index: number) {
    if (this.agentList.length <= 1) {
      this.cdsPopup.open(LeastOneWarningComponent, {
        size: 'md',
      });
      return;
    }

    const isDeleted = this.asFormGroup(this.agentList.controls[index]).controls['servicingAgent'].value === true;
    this.agentList.removeAt(index);
    if (isDeleted) {
      setTimeout(() => {
        this.asFormGroup(this.agentList.at(0)).controls['servicingAgent'].setValue(true);
      }, 0);
    }
  }

  get isDisableAddNewSplitAgent() {
    if (this.isConfirming) {
      return true;
    }
    if (this.targetType === 'EE') {
      return this.agentList.length >= 2;
    } else {
      return this.agentList.length >= 10;
    }
  }

  addNewSplitAgent() {
    if (this.isDisableAddNewSplitAgent) {
      return;
    }

    const agentCodeList = [];
    for (const agent of this.agentList.controls) {
      agentCodeList.push(this.asFormGroup(agent).controls['agentCode'].value);
    }

    const popupRef: MatDialogRef<AddNewCommissionPopupComponent> = this.cdsPopup.open(AddNewCommissionPopupComponent, {
      size: 'md',
      data: {
        agentCodeList: agentCodeList,
      },
    });

    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.agree) {
        this.agentList.push(
          this.fb.group({
            agentCode: this.fb.control(confirm.agent.agentCode),
            agentName: this.fb.control(confirm.agent.name),
            agentNameTC: this.fb.control(confirm.agent.chineseName),
            servicingAgent: this.fb.control(false),
            shareHKPC: this.fb.control(''),
            shareCommission: this.fb.control(''),
          })
        );
      }
    });
  }

  asFormGroup(agent: AbstractControl) {
    return agent as FormGroup;
  }

  getAgentNameFormControl(agent: AbstractControl) {
    const agentName = this.asFormGroup(agent).controls['agentName'].value;
    const agentNameTC = this.asFormGroup(agent).controls['agentNameTC'].value;

    if (this.cdsLangService.currentLang === 'en') {
      return agentName || agentNameTC;
    } else {
      return agentNameTC || agentName;
    }
  }

  shareHKPCBlur(agent: AbstractControl) {
    if (this.asFormGroup(agent).controls['shareHKPC'].value !== '') {
      let shareHKPC = String(Math.round(+this.asFormGroup(agent).controls['shareHKPC'].value * 1000) / 1000);

      if (shareHKPC.includes('.')) {
        if (shareHKPC.length - 1 - shareHKPC.indexOf('.') < 3) {
          const needN = 3 - (shareHKPC.length - 1 - shareHKPC.indexOf('.'));
          for (let i = 0; i < needN; i++) {
            shareHKPC += '0';
          }
        }
      } else {
        shareHKPC += '.000';
      }

      this.asFormGroup(agent).controls['shareHKPC'].setValue(shareHKPC);
      if (this.asFormGroup(agent).controls['shareCommission'].value === '') {
        this.asFormGroup(agent).controls['shareCommission'].setValue(shareHKPC);
      }
    }
  }

  shareCommissionBlur(agent: AbstractControl) {
    if (this.asFormGroup(agent).controls['shareCommission'].value !== '') {
      let shareCommission = String(Math.round(+this.asFormGroup(agent).controls['shareCommission'].value * 1000) / 1000);

      if (shareCommission.includes('.')) {
        if (shareCommission.length - 1 - shareCommission.indexOf('.') < 3) {
          const needN = 3 - (shareCommission.length - 1 - shareCommission.indexOf('.'));
          for (let i = 0; i < needN; i++) {
            shareCommission += '0';
          }
        }
      } else {
        shareCommission += '.000';
      }

      this.asFormGroup(agent).controls['shareCommission'].setValue(shareCommission);
      if (this.asFormGroup(agent).controls['shareHKPC'].value === '') {
        this.asFormGroup(agent).controls['shareHKPC'].setValue(shareCommission);
      }
    }
  }

  get isDisableSave() {
    return !this.form.valid;
  }

  isShowItemWarning(agent: AbstractControl) {
    return (
      this.targetType === 'ER' &&
      this.isConfirming &&
      this.asFormGroup(agent).controls['shareHKPC'].value !== this.asFormGroup(agent).controls['shareCommission'].value
    );
  }

  disableEditAgentByChannel() {
    return this.salesjourneyService.isScbBrokerCorporateChannel();
  }
}
