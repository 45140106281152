import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionState } from 'src/app/views/chatbot-admin/_common/_interface';
import { CannedService } from 'src/app/views/chatbot-admin/_services/canned.service';
import { DataService } from 'src/app/views/chatbot-admin/_services/data.service';
import { Alerts } from '../../_common/alerts';

@Component({
  selector: 'app-transfer-chat',
  templateUrl: './transfer-chat.component.html',
  styleUrls: ['./transfer-chat.component.scss'],
})
export class TransferChatComponent implements OnInit {
  loading: boolean = false;
  stateModel: any;
  modalRef: any;
  agentID: any;
  agentDDL = [];
  disable: boolean = false;
  DDL: any[] = [];
  icon: string = 'contract';
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public cannedServices: CannedService,
    public dataService: DataService
  ) {
    this.stateModel = this.dataService.getModel() as ActionState;

    this.getAgetnList();
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.agentID) {
      this.loading = true;
      this.cannedServices.transferChat(this.agentID, this.stateModel).subscribe((resp: any) => {
        this.loading = true;
        if (resp) {
          this.activeModal.close('Cross click');
        }
      });
    } else {
      Alerts.showInfoMessage('Please select Agent');
    }
  }

  getAgetnList() {
    this.loading = true;
    this.cannedServices.getAgetnList().subscribe((resp: any) => {
      this.loading = false;
      if (resp) {
        this.agentDDL = resp;
      }
    });
  }
}
