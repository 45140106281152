import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CannedService } from '../../_services/canned.service';
import { Alerts } from '../../_common/alerts';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  form: FormGroup;
  loading: boolean = true;
  agentDDL: any;
  monthDDL = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  constructor(private formBuilder: FormBuilder, public router: Router, public cannedServices: CannedService) {
    this.form = this.formBuilder.group({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      agent: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.getAgetnList();
  }

  onSubmit() {
    let model = this.form.value;
    this.loading = true;
    this.cannedServices.getReport(model).subscribe(resp => {
      this.loading = false;
      if (resp) {
        if (resp.size > 0) {
          const blob = new Blob([resp], { type: resp.type });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          const contentDisposition = resp.headers?.get('content-disposition');
          let fileName = 'report';
          if (contentDisposition) {
            const match = contentDisposition.match(/filename="(.+)"/);
            if (match) {
              fileName = match[1];
            }
          }
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          Alerts.showInfoMessage('Report is not available');
        }
      } else {
        Alerts.showErrorMessage('No File Found');
      }
    });
  }

  getAgetnList() {
    this.loading = true;
    this.cannedServices.getAllAgetnList().subscribe((resp: any) => {
      this.loading = false;
      if (resp) {
        this.agentDDL = resp;
      }
    });
  }
}
