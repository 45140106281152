import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';

import { CdAutocompleteModule } from '../cd-autocomplete/cd-autocomplete.module';
import { CdRealtimeSearchComponent } from './cd-realtime-search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CdsLanguageModule,
    CdsIconModule,
    MatInputModule,
    CdAutocompleteModule,
  ],
  declarations: [CdRealtimeSearchComponent],
  exports: [CdRealtimeSearchComponent],
})
export class CdRealtimeSearchModule {}
