<div class="quick-access container-width">
  <div class="l-pl-2 l-pr-8 l-mr-5 border-right">
    <div class="link-big" (click)="anchorScrollTo('generalInformation')">General Information</div>
    <div class="link-big" (click)="anchorScrollTo('terminationDetails')" *ngIf="showTerminationDetail">Termination Details</div>
    <!-- <div class="link-big" (click)="anchorScrollTo('enrolmentDetails')">Enrolment Details</div> -->
    <!-- <div class="link-big" (click)="anchorScrollTo('enrolmentInformation', true)">Enrolment Information</div>
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('employerGeneralInformation', true)">Employer General Information</div> -->
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('communicationMethod', true)">Communication Method</div>
    <app-permission [role]="PermissionAccess.R" [permission]="PermissionItem.CUSTOMER_EMPLOYER_ACTIVITY_CODE">
      <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('activityCode', true)">Activity Records</div>
    </app-permission>
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('comments', true)">Comments</div>
    <app-permission [role]="PermissionAccess.R" [permission]="PermissionItem.CUSTOMER_EMPLOYER_ADDITIONAL_INFORMATION">
      <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('specialOfferManagement')">Client Additional Information</div>
    </app-permission>
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('vestingScale')">Vesting Rules</div>
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('formulaClass')">Formula Class</div>
  </div>

  <div class="l-pl-2">
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('agentInfo')">Agent info</div>
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('paymentNotice')">Payment Notice</div>
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('appealDetails')">Appeal Details</div>
    <div class="link-big" (click)="anchorScrollTo('employerPayrollGroup')">Employer Payroll Group</div>
    <app-permission [role]="PermissionAccess.R" [permission]="PermissionItem.CUSTOMER_EMPLOYER_SQ">
      <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('specialQuote')">Special Quote</div>
    </app-permission>
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('chargeInfomation')">Charge Information</div>
    <div class="link-big" *ngIf="isInternalStaff" (click)="anchorScrollTo('moreEmployerInfoDetails')">MoreEmployerInfoDetails</div>
    <app-permission [role]="PermissionAccess.R" [permission]="PermissionItem.CUSTOMER_EMPLOYER_SUBSIDIARY">
      <div class="link-big" (click)="anchorScrollTo('parentCompany')">Parent/ Subsidary Company List</div>
    </app-permission>
  </div>
</div>
