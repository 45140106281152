import { CdsButtonConfig } from '@cds/ng-core/button';
import { Component, Input, OnInit } from '@angular/core';
import { CdsCheckboxConfig } from '@cds/ng-core/checkbox';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { Employee, employeeStatusMapObj } from '../employee';
import { EmployeeService } from '../employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { CampaignInformation } from '../ee-modules/CampaignInformation.module';
import { EEProfilePolicyResponse } from '../ee-modules/EEProfilePolicy.module';
import moment from 'moment';
import { pageToTop } from 'src/app/utils/utils';

@Component({
  selector: 'app-ee-profile-detail',
  templateUrl: './ee-profile-detail.component.html',
  styleUrls: ['./ee-profile-detail.component.scss'],
})
export class EeProfileDetailComponent implements OnInit {
  constructor(
    public service: EmployeeService,
    private toast: CdsToastService,
    private route: ActivatedRoute,
    private router: Router,
    private layoutHeaderService: LayoutHeaderService
  ) {}
  @Input() fullWidth = false;
  employeeDTO: Employee = {};

  checkedConfig: CdsCheckboxConfig = {
    checked: true,
    disabled: true,
  };
  noConfig: CdsCheckboxConfig = {
    disabled: true,
  };

  displayedColumns2: string[] = [
    'policyNo',
    'Campaign-description',
    'Customer-communication-send-channel',
    'Customer-communication-date',
    'Campaign-Period',
    'Follow-up-agent-name',
    'Follow-up-agent-code',
  ];

  linkBtnConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  isShowDetailA = true;
  detailData: Employee = {};
  compaignList: CampaignInformation[] = [];
  policeList: EEProfilePolicyResponse[] = [];
  headerButton: AdminHeaderConfig = {
    title: 'ee.profile.detail.title',
    backButton: {
      text: 'Member Enquiry Information Summary',
      url: '/employee/list',
    },
  };

  id = '';

  md5 = '';

  employeeStatusMapObj = employeeStatusMapObj;

  titleSticky = false;

  ngOnInit(): void {
    const id = this.route.snapshot.queryParams['id'];
    this.layoutHeaderService.setHeaderButton(this.headerButton);
    this.id = id;
    this.md5 = this.route.snapshot.queryParams['md5'];
    this.getDetail(this.md5, this.id);
    pageToTop();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  doClickDetail() {
    if (this.isShowDetailA) {
      this.isShowDetailA = false;
    } else {
      this.isShowDetailA = true;
    }
  }

  goToDetailPlus(policyItem: EEProfilePolicyResponse) {
    this.router.navigate([
      '/employee/profileplus',
      {
        legalId: this.detailData.legalId,
        legalIdType: this.detailData.legalIdType,
        group_no: policyItem.groupNo,
        id: this.id,
      },
    ]);
  }
  getDetail(md5: string, id: string) {
    if (!id) {
      id = '';
    }
    if (!md5) {
      md5 = '';
    }
    // if (!md5 || !id) return;
    this.service.getOverviewBasicInfo(md5, id).subscribe(res => {
      if (res.result === 0 && res.data) {
        this.detailData = res.data;
        // if (this.detailData.campaignList) {
        //   this.compaignList = this.detailData.campaignList;
        // }
        // if (this.detailData.policyList) {
        //   this.policeList = this.detailData.policyList;
        // }
      } else {
        this.toast.error('getById API error: ' + res.message);
      }
    });
  }

  updateDate(date?: string) {
    if (!date) {
      return '';
    }
    return moment(date).utcOffset(-8).format('h:mma DD MMM, YYYY');
  }

  stickyStatusChange(isSticky: boolean) {
    this.titleSticky = isSticky;
  }

  nationRender(list: string[]) {
    if (!list) return '';
    return list.filter(item => !!item).join(', ');
  }

  getGender(item: string | undefined) {
    switch (item) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      case 'U':
        return 'Unisex';
      case 'O':
        return 'No Such Information';
      default:
        return item;
    }
  }
}
