<div class="layout-content div-bg-light-grey">
  <main class="cb-main">
    <div class="cb-all-chats cb-scrollbar" *ngIf="showAgent">
      <div style="margin-bottom: 11.5px; display: flex; justify-content: space-between; padding: 1rem 1.25rem 0rem">
        <h3 style="font-weight: 600; font-size: 22px">All Chats - {{ activeChat?.length + pendingChat?.length + closeChat?.length }}</h3>

        <!-- <span style="background-color: #cccc; padding: 7px 7px 10px 7px; width: 130px; border-radius: 10px">
          <b> Status </b>
          <ui-switch [checked]="true" size="small" >
            <i class="fa fa-spinner fa-pulse" *ngIf="loading"></i>
          </ui-switch>
        </span> -->
        <!-- <cds-dropdown [config]="dropdownConfig" [(ngModel)]="status" (change)="updateStatus($event)"></cds-dropdown> -->
        <ng-select
          class="font-small-1"
          [multiple]="false"
          [items]="dropdownConfig.options"
          bindLabel="label"
          [(ngModel)]="status"
          bindValue="value"
          placeholder=""
          (change)="updateStatus($event)"
          [clearable]="false">
        </ng-select>
        <!-- <cds-switch [config]="config" checked [(ngModel)]="agentStatus" (change)="updateStatus($event)"></cds-switch> -->
      </div>
      <div class="agentTab">
        <cds-tab-group [type]="'line-small'" [selectedIndex]="tab" (selectedTabChange)="tabChange($event)" style="width: 100%">
          <cds-tab [selected]="tab == 0" label="Active  ({{ activeChat.length }})">
            <div class="cb-chats-list">
              <ng-container *ngFor="let item of activeChat">
                <li (click)="onClickChat(item); item.count = 0">
                  <div
                    class="cb-chats-card"
                    [ngStyle]="item.status == 'CLOSED_BY_AGENT' ? { border: '1px solid red' } : {}"
                    [ngClass]="{ 'cb-active': item.roomId === roomId }">
                    <!-- <div class="cb-chats-user">
                    <img src="./assets/images/user.jpg" alt="" />
                  </div> -->

                    <div class="cb-chats-meta">
                      <div class="cb-chats-start">
                        <p style="font-weight: 600" [innerHTML]="item.users ? item.users[0]?.name : ''"></p>
                        <p [innerHTML]="item.users ? item.users[0]?.lastMessage : ''"></p>
                        <p>{{ item.users ? (item.users[0]?.msgDate | date : 'yyyy/MM/dd HH:mm') : '' }}</p>
                      </div>
                      <div class="cb-chats-end">
                        <!-- <cds-icon icon="action:button_x_filled"></cds-icon> -->
                        <span style="display: flex; flex-direction: column">
                          <!-- <cds-icon style="z-index: 1000;" icon="action:button_checkmark_outlined"
                            (click)="joinChat(item.roomId)"></cds-icon> -->
                          <p
                            *ngIf="item.count > 0"
                            style="
                              font-weight: 600;
                              background-color: green;
                              border-radius: 50%;
                              height: 20px;
                              width: 20px;
                              text-align: center;
                              margin-top: 5px;
                            ">
                            {{ item.count }}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ng-container>
            </div>
          </cds-tab>
          <cds-tab [selected]="tab == 1" label="Pending ({{ pendingChat.length }})">
            <div class="cb-chats-outer">
              <div class="cb-chats-list">
                <ng-container *ngFor="let item of pendingChat; let i = index">
                  <li (click)="onClickAwaitingChat(item); item.count = 0">
                    <div class="cb-chats-card" [ngClass]="{ 'cb-active': item.roomId === roomId }">
                      <div class="cb-chats-meta">
                        <div class="cb-chats-start">
                          <p style="font-weight: 600" [innerHTML]="item.users ? item.users[0]?.name : ''"></p>
                          <p [innerHTML]="item.users ? item.users[0]?.lastMessage : ''"></p>
                          <p>{{ item.users ? (item.users[0]?.msgDate | date : 'yyyy/MM/dd HH:mm') : '' }}</p>
                        </div>
                        <div class="cb-chats-end" style="z-index: 1000">
                          <span style="display: flex; flex-direction: column">
                            <!-- <cds-icon style="z-index: 1000;" icon="action:button_checkmark_outlined"
                            (click)="joinChat(item.roomId)"></cds-icon> -->
                            <p
                              *ngIf="item.count > 0"
                              style="
                                font-weight: 600;
                                background-color: green;
                                border-radius: 50%;
                                height: 20px;
                                width: 20px;
                                text-align: center;
                                margin-top: 5px;
                              ">
                              {{ item.count }}
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </div>
            </div>
          </cds-tab>
          <cds-tab [selected]="tab == 2" label="Closed ({{ closeChat.length }})">
            <div class="cb-chats-list">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group" style="position: relative">
                    <input type="text" class="form-control" style="padding: 0.7rem" placeholder="Search" (keyup)="searchChat($event)" />
                    <cds-icon *ngIf="loading" icon="arrow:reload" class="rotate"></cds-icon>
                  </div>
                </div>
              </div>
              <ng-container *ngFor="let item of closeChatFilter">
                <li (click)="onClickArchiveChat(item)">
                  <div class="cb-chats-card" [ngClass]="{ 'cb-active': item.roomId === roomId }">
                    <!-- <div class="cb-chats-user">
                    <img src="./assets/images/user.jpg" alt="" />
                  </div> -->

                    <div class="cb-chats-meta">
                      <div class="cb-chats-start">
                        <p style="font-weight: 600" [innerHTML]="item.users ? item.users[0]?.name : ''"></p>
                        <p [innerHTML]="item.users ? item.users[0]?.lastMessage : ''"></p>
                        <p>{{ item.users ? (item.users[0]?.msgDate | date : 'yyyy/MM/dd HH:mm') : '' }}</p>
                      </div>
                      <!-- <div class="cb-chats-end">
                      <cds-icon icon="action:button_x_filled"></cds-icon>
                    </div> -->
                    </div>
                  </div>
                </li>
              </ng-container>
            </div>
          </cds-tab>
          <cds-tab [selected]="tab == 3" label="Bot ({{ botChat.length }})">
            <div class="cb-chats-list">
              <ng-container *ngFor="let item of botChat">
                <li (click)="onClickArchiveChat(item)">
                  <div class="cb-chats-card" [ngClass]="{ 'cb-active': item.roomId === roomId }">
                    <!-- <div class="cb-chats-user">
                    <img src="./assets/images/user.jpg" alt="" />
                  </div> -->

                    <div class="cb-chats-meta">
                      <div class="cb-chats-start">
                        <p style="font-weight: 600" [innerHTML]="item.users ? item.users[0]?.name : ''"></p>
                        <p [innerHTML]="item.users ? item.users[0]?.lastMessage : ''"></p>
                        <p>{{ item.users ? (item.users[0]?.msgDate | date : 'yyyy/MM/dd HH:mm') : '' }}</p>
                      </div>
                      <!-- <div class="cb-chats-end">
                      <cds-icon icon="action:button_x_filled"></cds-icon>
                    </div> -->
                    </div>
                  </div>
                </li>
              </ng-container>
            </div>
          </cds-tab>
        </cds-tab-group>
      </div>
    </div>
    <div class="cb-chat-messages" *ngIf="showChat">
      <!-- <div class="cb-no-chat-found">
          <svg width="105" height="105">
              <use xlink:href="./assets/images/sprite.svg#cb-message"></use>
          </svg>
          <h2>No Chat Found!</h2>
          <p>All CSOs are currently busy. <br> Please try again later.</p>
      </div> -->
      <div class="cb-chat-messages-header" (click)="onShowChats()" style="vertical-align: middle">
        <!-- <svg width="24" height="24" class="cb-back">
          <use xlink:href="./assets/images/sprite.svg#cb-back"></use>
        </svg> -->
        <div style="font-size: 22px">
          {{ agentName }} ({{ roomId }})
          <div style="font-size: 16px">
            {{ roomInfo?.group_no && roomInfo?.group_no != 'null' ? roomInfo?.group_no + ' -' : '' }}
            {{ roomInfo?.cert_no && roomInfo?.cert_no != 'null' ? roomInfo?.cert_no : '' }}
          </div>
        </div>
      </div>

      <div class="cb-message-list" #messageContainer>
        <ng-container *ngFor="let obj of chatJson">
          <div class="cb-message cb-chatbot" *ngIf="obj.userType === 'USER'">
            <p>{{ obj.sender }}</p>
            <div class="cb-message-content" *ngIf="obj.type === 'CHAT'" [innerHTML]="obj?.content"></div>
            <div
              *ngIf="obj.type === 'CHAT' && (obj?.alerten || obj?.alertzh)"
              class="cb-message-content alertMsg"
              [innerHTML]="lang == 'en' ? obj.alerten : obj.alertzh"></div>

            <div class="cb-message cb-you" *ngIf="obj.type === 'FILE'">
              <div class="cb-message-img">
                <img [src]="getObjectFilePath(obj.content)" alt="" />
              </div>
            </div>
            <div class="cb-message-date text-start">{{ obj?.msgDate | date : 'yyyy/MM/dd HH:mm' }}</div>
          </div>
          <div class="cb-message cb-you" *ngIf="obj.userType === 'AGENT' && (obj.type === 'CHAT' || obj.type === 'FILE')">
            <p style="text-align: right">{{ obj.sender }}</p>
            <div class="cb-message-content" *ngIf="obj.type === 'CHAT'" [innerHTML]="obj.content"></div>
            <div
              *ngIf="obj.type === 'CHAT' && (obj.alerten || obj.alertzh)"
              class="cb-message-content alertMsg"
              [innerHTML]="lang == 'en' ? obj.alerten : obj.alertzh"></div>
            <div class="cb-message cb-you" *ngIf="obj.type === 'FILE'">
              <div class="cb-message-img">
                <img [src]="getObjectFilePath(obj.content)" alt="" />
              </div>
            </div>
            <div class="cb-message-date">{{ obj?.msgDate | date : 'yyyy/MM/dd HH:mm' }}</div>
          </div>
          <div class="cb-message cb-you" *ngIf="obj.userType === 'AGENT' && obj.type === 'JOIN'">
            <p style="text-align: right">{{ obj.sender }}</p>
            <div class="cb-message-content" *ngIf="obj.type === 'JOIN'" style="background-color: #f7b924">
              {{
                lang == 'en'
                  ? '
              You have accepted the chat'
                  : ' 您已接受聊天'
              }}
            </div>
            <div class="cb-message-date">{{ obj?.msgDate | date : 'yyyy/MM/dd HH:mm' }}</div>
          </div>
        </ng-container>
        <div class="cb-message cb-you" style="width: 100%; text-align: center" *ngIf="isCloseByAgent">
          <div class="cb-message-content" style="color: red; background: none">
            {{ lang == 'en' ? 'The agent has terminated the chat.' : ' 代理人已終止對話。' }}
          </div>
        </div>
      </div>
      <ul class="cb-btn-group">
        <li style="margin-right: 10px" *ngIf="Awaiting">
          <cds-button [style]="'secondary'" (click)="joinChat(roomId)" size="sm">Accept Chat</cds-button>
        </li>
      </ul>
      <div class="cb-message-footer" *ngIf="!Archive">
        <div class="cb-send-message" style="width: 100%; padding-top: 7px">
          <ul class="cb-messages-meta" style="float: left; margin-top: 7px" *ngIf="agentStatus">
            <li (click)="showModal($event)">
              <label>
                <cds-icon icon="action:list"></cds-icon>
              </label>
            </li>
          </ul>
          <input
            *ngIf="!isCloseByAgent && agentStatus"
            class="inputWidth"
            type="text"
            placeholder="Write to send message"
            [disabled]="isCloseByAgent"
            [innerHTML]="input"
            [(ngModel)]="input" />
          <ul class="cb-messages-meta" style="float: right; margin-top: 8px !important" *ngIf="agentStatus">
            <!-- <li (click)="showModal($event)">
              <label>
                <cds-icon icon="action:list"></cds-icon>
              </label>
            </li> -->
            <li>
              <div class="cb-send" (click)="sendMessage()">
                <svg width="18" height="18">
                  <use xlink:href="./assets/images/sprite.svg#cb-send"></use>
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
  <div class="cb-agentchatbot" *ngIf="isShowChatBot" (click)="$event.stopPropagation()">
    <div class="row">
      <div class="col-12">
        <div class="cb-agentchatbot-header">
          <ul class="agentchatbot">
            <!-- <li class="fileUpload" *ngIf="!isCloseByAgent">
              <label for="attachment">
                File Upload
              </label>
              <input id="attachment" (change)="onFileChange($event);isShowChatBot=false" type="file"
                name="attachment" />
            </li> -->
            <li *ngIf="!Awaiting && !Archive" (click)="onCloseChatModal(); isShowChatBot = false">Close Chat</li>
            <li *ngIf="!Awaiting && !Archive && !isCloseByAgent" (click)="onTrasnferChat(); isShowChatBot = false">Transfer Chat</li>
            <li style="display: block" *ngIf="!isCloseByAgent">
              <p (click)="openCannedMoal()">Canned Message</p>
              <!-- <div>
                <ng-select class="cb-select" (change)="selectMessage($event);" [(ngModel)]="cannedMessage"
                  placeholder="Search Canned Message">
                  <ng-option *ngFor="let item of cannedMessageDDL" [value]="item">
                    <div [innerHTML]="lang=='en' ? item?.msg : item?.chineseMsg"></div>
                  </ng-option>

                </ng-select>
              </div> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
