import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { Subscription } from 'rxjs';
import { AgentProductSummary } from './product-type-summary.model';
import { producttypeRequest } from './producttype-request';
import { ProductTypeSummaryService } from './producttype-summary.service';
import { SalesJourneyProdType } from '../case-summary/case-summary.model';

@Component({
  selector: 'app-product-type-summary',
  templateUrl: './product-type-summary.component.html',
  styleUrls: ['./product-type-summary.component.scss'],
})
export class ProductTypeSummaryComponent implements OnInit, OnDestroy {
  SalesJourneyProdType = SalesJourneyProdType;
  select = '';
  paSummary = new AgentProductSummary(0, 0, 0, 0);
  tvcSummary = new AgentProductSummary(0, 0, 0, 0);
  frcSummary = new AgentProductSummary(0, 0, 0, 0);
  erSummary = new AgentProductSummary(0, 0, 0, 0);
  sepSummary = new AgentProductSummary(0, 0, 0, 0);
  private errorSub?: Subscription;

  constructor(private router: Router, private alert: CdsAlertService, private productTypeSummaryService: ProductTypeSummaryService) {}
  ngOnDestroy(): void {
    this.productSummarySubscription?.unsubscribe();
    this.errorSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.productTypeSummaryService.getProducttypeSummary();
    this.errorSub = this.productTypeSummaryService.errorproducttypeSubject.subscribe(errorMessage => {
      const alertTitle = 'Got Error!';
      this.alert.error(alertTitle, errorMessage);
    });
    this.productSummarySubscription = this.productTypeSummaryService.producttypeDataSubject.subscribe(response => {
      if (response.result !== undefined) {
        if (response.data !== undefined) {
          this.tvcSummary.pendingAgent = response.data.TVC?.pending2ndAgent;
          this.tvcSummary.pendingcustomer = response.data.TVC?.pendingCusSubmission;
          this.tvcSummary.pendingToMap = response.data.TVC?.pendingToMap;
          this.paSummary.pendingAgent = response.data.PA?.pending2ndAgent;
          this.paSummary.pendingcustomer = response.data.PA?.pendingCusSubmission;
          this.paSummary.pendingToMap = response.data.PA?.pendingToMap;
          this.frcSummary.pendingAgent = response.data.SVC?.pending2ndAgent;
          this.frcSummary.pendingcustomer = response.data.SVC?.pendingCusSubmission;
          this.frcSummary.pendingToMap = response.data.SVC?.pendingToMap;
          this.erSummary.pendingAgent = response.data.ER?.pending2ndAgent;
          this.erSummary.pendingcustomer = response.data.ER?.pendingCusSubmission;
          this.erSummary.pendingToMap = response.data.ER?.pendingToMap;
          this.sepSummary.pendingAgent = response.data.SEP?.pending2ndAgent;
          this.sepSummary.pendingcustomer = response.data.SEP?.pendingCusSubmission;
          this.sepSummary.pendingToMap = response.data.SEP?.pendingToMap;
        }
      }
    });
  }
  onclickroute(value: SalesJourneyProdType) {
    this.select = value;
    sessionStorage.setItem('productType', value);
    localStorage.removeItem('case-summary-search-params');
    localStorage.removeItem('case-summary-selected-data');
    this.router.navigate(['/salesjourney/product-type-summary/case-summary']);
  }
  myRqeuest: producttypeRequest = {
    productTypes: 'All',
  };
  private productSummarySubscription?: Subscription;

  get isIndividualPendingStatus() {
    return (
      (this.paSummary.pendingAgent || 0) > 0 ||
      (this.paSummary.pendingcustomer || 0) > 0 ||
      (this.paSummary.pendingToMap || 0) > 0 ||
      (this.tvcSummary.pendingAgent || 0) > 0 ||
      (this.tvcSummary.pendingcustomer || 0) > 0 ||
      (this.tvcSummary.pendingToMap || 0) > 0 ||
      (this.frcSummary.pendingAgent || 0) > 0 ||
      (this.frcSummary.pendingcustomer || 0) > 0 ||
      (this.frcSummary.pendingToMap || 0) > 0
    );
  }

  get isErPendingStatus() {
    return (this.erSummary.pendingAgent || 0) > 0 || (this.erSummary.pendingcustomer || 0) > 0 || (this.erSummary.pendingToMap || 0) > 0;
  }

  get isSepPendingStatus() {
    return (this.sepSummary.pendingAgent || 0) > 0 || (this.sepSummary.pendingcustomer || 0) > 0 || (this.sepSummary.pendingToMap || 0) > 0;
  }
}
