/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { dateRangeInCompleteValidator, dateRangeInvalidValidator, dateValidator } from 'src/app/core/validators';
import { COMMISSION_REPORT_COLUMNS, I18N_KEY, SubCategoryOptions } from './report-commission.config';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';

@Component({
  selector: 'app-report-commission',
  templateUrl: './report-commission.component.html',
  styleUrls: ['./report-commission.component.scss'],
})
export class ReportCommissionComponent implements OnInit {
  isShowSearchDatePicker = true;
  isDataLoading = false;
  pageConfig: PageConfig = {};
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = COMMISSION_REPORT_COLUMNS;
  subCategoryOptions = SubCategoryOptions;

  reportForm = new FormGroup(
    {
      occurDateFrom: new FormControl(null, [dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT })]),
      occurDateTo: new FormControl(null, [dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT })]),
      subCategory: new FormControl([]),
      reportName: new FormControl(null),
    },
    [
      dateRangeInCompleteValidator('occurDateFrom', 'occurDateTo', { error: I18N_KEY.ERROR_DATE_REANGE }),
      dateRangeInvalidValidator('occurDateFrom', 'occurDateTo', { error: I18N_KEY.INVALID_DATE_REANGE }),
    ]
  );

  get occurDateFrom() {
    return this.reportForm.get('occurDateFrom') as FormControl;
  }

  get occurDateTo() {
    return this.reportForm.get('occurDateTo') as FormControl;
  }

  get subCategory() {
    return this.reportForm.get('subCategory') as FormControl;
  }

  get reportName() {
    return this.reportForm.get('reportName') as FormControl;
  }

  get searchDisabled() {
    let tag = true;
    const controlValues = this.reportForm.value;
    const keys = Object.keys(controlValues);
    keys.forEach((key: string) => {
      if (controlValues[key]) {
        tag = false;
      }
    });

    return tag || this.reportForm.invalid || this.isDataLoading;
  }

  get getTotalCount() {
    return this.pageConfig.totalCount || 0;
  }

  constructor() {}

  ngOnInit(): void {}

  search() {}

  reset() {
    this.reportForm.reset();
  }
}
