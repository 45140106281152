/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommissionBatchStatusService } from '../services/commission-batch-status.service';

@Injectable()
export class CommissionBatchStatusInterceptor implements HttpInterceptor {
  constructor(private commissionBatchStatusService: CommissionBatchStatusService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.commissionBatchStatusService.refreshCommBatchStatus();
    return next.handle(request);
  }
}
