/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';

import { CdPopupService, CdPopupSize, MatDialogRef } from 'src/app/shared/cd-popup';
import { ReviewReportNumberComponent } from './review-report-number/review-report-number.component';
import { EventTypeKey } from 'src/app/views/system/calendar/calendar.model';
import { ReviewCommissionEventComponent } from './review-commission-event/review-commission-event.component';
import { CalendarService } from 'src/app/views/system/calendar/calendar.service';
import { ActionEnum } from 'src/app/core/models/enum';

@Component({
  selector: 'app-event-pending-review',
  templateUrl: './event-pending-review.component.html',
  styleUrls: ['./event-pending-review.component.scss'],
})
export class EventPendingReviewComponent implements OnInit {
  eventType = EventTypeKey;
  actionType = ActionEnum;
  constructor(private dialogRef: MatDialogRef<ReviewCommissionEventComponent>, private cdsPopup: CdPopupService, public calendarService: CalendarService) {}

  ngOnInit(): void {}

  reviewEvent(event: any) {
    if (event.eventType === EventTypeKey.COMMISSION) {
      this.reviewCommissionEvent(event);
    }
  }

  reviewCommissionEvent(event: any) {
    const popupRef: MatDialogRef<ReviewCommissionEventComponent> = this.cdsPopup.open(ReviewCommissionEventComponent, {
      size: CdPopupSize.LARGE,
      data: {
        data: event,
        reviewId: event.reviewId,
      },
    });
    popupRef.afterClosed().subscribe(() => {
      this.isClose();
    });
  }

  reviewReportNo() {
    const popupRef: MatDialogRef<ReviewReportNumberComponent> = this.cdsPopup.open(ReviewReportNumberComponent, {
      size: CdPopupSize.LARGE,
      panelClass: ['calendar-popup-bg'],
      data: { isReview: true },
    });
    popupRef.afterClosed().subscribe(() => {
      this.isClose();
    });
  }

  isClose() {
    if (!this.calendarService.reviewEvents.length) {
      this.dialogRef.close();
    }
  }
}
