/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, delay } from 'rxjs';
import { BasicResponse } from 'src/app/core/models/response/response';

// import { environment } from 'src/environments/environment';
const data = [
  {
    id: '1222012',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222013',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222014',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222015',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222016',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222017',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222018',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222019',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '12220020',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222021',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222022',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
  {
    id: '1222023',
    schemeCd: 'GS',
    erAcctCd: '11223344',
    payrollGroupId: 'BIMONTHLY',
    mbAcctCd: '',
    rcvRefNo: '55553322101234',
    statusCd: 'TAGGED',
    paySubmitRefNo: '71070485162886',
    payMthd: 'BILL_PYM',
    rcvdAmt: '6690.77',
    bnkCd: '004',
    branchCd: '006',
    bnkAcctNo: '7808780',
    payerNm: 'CDD COMPANY LIMITED',
    chqDate: '',
    chqBnkInDate: '',
    othTxnRefNo: '',
    clearDate: '20231017',
    rcvdDate: '',
    clkChqFlg: '',
    splitfundingID: '',
    splitfundingFlag: '',
    trBnkCd: '004',
    trBranchCd: '500',
    trBnkAcctNo: '539291002',
    wfRefNo: '2023-10-17-12.00.00.123456T01',
    reportNo: '194',
    currency: 'HKD',
  },
];
@Injectable({
  providedIn: 'root',
})
export class PaymentDetailsService {
  constructor(private http: HttpClient) {}

  getData(params: any): Observable<BasicResponse<any>> {
    const totalCount = data.length;
    const index = params.page * params.size;
    const size = index + params.size > totalCount ? totalCount - index : params.size;
    return of({
      message: 'SUCCESS',
      result: 0,
      data: {
        totalElements: totalCount,
        content: data.slice(index, index + size),
      },
    }).pipe(delay(500));
    // return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.accountingServiceExt}${'/payment-details/list'}`, {
    //   params: params,
    // });
  }
}
