<div class="new-resend-eiff">
  <div class="cds-popup-padding-top">
    <cds-icon class="close-current-popup" icon="action:cross" (click)="closeCurrentPopup()"></cds-icon>
  </div>
  <div class="top-content" [formGroup]="iffForm">
    <div>
      <span class="cds-h2-light" style="font-weight: 500">重新發送eIFF</span>
    </div>
    <div class="l-d-f l-ai-fs l-mt-5 md-reference-number-box">
      <div style="position: relative; top: 7px">
        <cds-icon class="icon-document" icon="form:document"></cds-icon>
      </div>
      <div class="l-d-f l-fd-col l-ml-5">
        <div>
          <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">MD Reference Number</span>
        </div>
        <div class="l-mt-1">
          <span class="cds-h4-demibold">MD120382714</span>
        </div>
        <div *ngIf="data.resendType === ResendType.ER" class="l-mt-4 l-d-f l-ai-cen">
          <cds-checkbox [formControl]="intragroupTransferCheck" [disabled]="true" ngDefaultControl></cds-checkbox>
          <div class="l-ml-4">
            <span class="cds-detail2">這是有聯繫公司間或更改業務擁有權時之成員累算權益轉移。</span>
          </div>
        </div>
      </div>
    </div>
    <div class="l-mt-5">
      <app-matters-needing-attention [color]="'red'" [title]="'注意事項'">
        <ul class="l-mt-0 l-mb-0">
          <li>
            <span class="cds-detail2">盡本人所知所信，本聲明上所提供之資料均屬正確無訛且無缺漏。</span>
          </li>
          <li>
            <div>
              <span class="cds-detail2 red-text">代理人不得使用自己或任何第三方的郵寄地址、電話號碼和/或電郵地址，去代替客戶的聯繫方式。</span>
            </div>
            <div>
              <span class="cds-detail2">一旦發現違規，會立刻向合規部及您的上級呈報並發出警告。</span>
            </div>
          </li>
        </ul>
      </app-matters-needing-attention>
    </div>
    <div class="l-mt-5 info-box">
      <div>
        <span class="cds-h4" style="font-weight: 300">客戶資料</span>
      </div>
      <div class="line l-mt-5"></div>
      <div class="info-list">
        <ng-container *ngIf="data.resendType === ResendType.EE">
          <div class="l-mt-5">
            <span class="cds-body2-demibold">客戶姓名</span><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">* </span
            ><span class="cds-body2-demibold">:</span>
          </div>
          <div class="l-mt-5">
            <span class="cds-body1">陳大文</span>
          </div>
        </ng-container>
        <ng-container *ngIf="data.resendType === ResendType.ER">
          <div class="l-mt-5"><span class="cds-body2-demibold">僱主全名</span><span class="cds-body2-demibold">:</span></div>
          <div class="l-mt-5">
            <span class="cds-body1">ABC Company</span>
          </div>
          <div class="l-mt-5"><span class="cds-body2-demibold">商業登記號碼</span><span class="cds-body2-demibold">:</span></div>
          <div class="l-mt-5">
            <span class="cds-body1">123456</span>
          </div>
          <div class="l-mt-5"><span class="cds-body2-demibold">獲授權人士全名</span><span class="cds-body2-demibold">:</span></div>
          <div class="l-mt-5">
            <span class="cds-body1">CHEUNG SIU MING</span>
          </div>
        </ng-container>
        <div class="l-mt-5">
          <span class="cds-body2-demibold">客戶電郵地址/ 手機號碼</span><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">* </span
          ><span class="cds-body2-demibold">:</span>
        </div>
        <div *ngIf="!isEdit" class="l-mt-5">
          <span *ngIf="contactType.value === 'M'" class="cds-body1">({{ showCountryCode }}) {{ showPhoneNo }}</span>
          <span *ngIf="contactType.value === 'E'" class="cds-body1">{{ showEmail }}</span>
        </div>
        <div *ngIf="isEdit" class="l-mt-5">
          <div>
            <div>
              <cds-radio
                class="cd-radio-margin-bottom-clear"
                [config]="{
                  options: [
                    {
                      label: 'iff.contactTypeEmail' | lang,
                      value: 'E'
                    },
                    {
                      label: 'iff.contactTypePhone' | lang,
                      value: 'M'
                    }
                  ]
                }"
                formControlName="contactType"
                (cdsChange)="onContactTypeChange($event)"
                ngDefaultControl></cds-radio>
              <div class="l-mt-1" *ngIf="!iffForm.get('contactType')!.valid && iffForm.get('contactType')?.touched">
                <cds-assistive-text *ngIf="!iffForm.value.contactType" type="error">{{ 'iff.contactTypeError' | lang }} </cds-assistive-text>
              </div>
            </div>
            <div></div>
            <div *ngIf="iffForm.value.contactType === 'M'" class="l-d-f l-fd-row l-mb-3 l-mt-5">
              <div class="col-md-4 l-plr-0">
                <cds-dropdown
                  formControlName="countryCode"
                  placeholder="+852 (HK)"
                  [config]="countrycodeConfig"
                  (ngModelChange)="clientCountryCodeChange()"
                  ngDefaultControl></cds-dropdown>
              </div>
              <div class="col-md-8">
                <app-cd-input
                  style="position: relative; top: -1px"
                  [type]="'number'"
                  ngDefaultControl
                  placeholder="Please input Client Phone Number"
                  [group]="iffForm"
                  controlName="phoneNo"></app-cd-input>
                <div class="l-mt-1">
                  <cds-assistive-text *ngIf="phoneNo.dirty && phoneNo.hasError('errorPhoneNumber')">
                    <span>
                      {{ 'common.please-input-valid-mobile-number' | lang }}
                    </span>
                  </cds-assistive-text>
                </div>
                <div class="l-mt-1">
                  <cds-assistive-text *ngIf="phoneNo.dirty && phoneNo.hasError('required')">
                    <span>
                      {{ 'common.please-input-phone-number' | lang }}
                    </span>
                  </cds-assistive-text>
                </div>
              </div>
            </div>
            <div *ngIf="iffForm.value.contactType === 'E'" class="l-d-f l-fd-row l-mb-3 l-mt-5">
              <div class="col-md-12 l-plr-0">
                <cds-textfield ngDefaultControl [placeholder]="'common.email-address' | lang" formControlName="email"></cds-textfield>
                <cds-assistive-text class="l-mt-4" *ngIf="email.dirty && email.hasError('email')">{{
                  'common.please-input-valid-customer-email' | lang
                }}</cds-assistive-text>
                <cds-assistive-text class="l-mt-4" *ngIf="email.dirty && email.hasError('required')">{{
                  'common.please-input-customer-email' | lang
                }}</cds-assistive-text>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isEdit" class="l-mt-5" style="grid-column: span 2">
          <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">*請確保資料與客戶在eMPF上提供的資料一致</span>
        </div>
        <ng-container *ngIf="contactType.value === 'M'">
          <div class="l-mt-5"><span class="cds-body2-demibold">選擇語言 </span><span class="cds-body2-demibold">:</span></div>
          <div *ngIf="!isEdit" class="l-mt-5">
            <span *ngIf="preferredLanguage.value === PreferredLanguageType.English" class="cds-body1">英文</span>
            <span *ngIf="preferredLanguage.value === PreferredLanguageType.Chinese" class="cds-body1">中文</span>
          </div>
          <div *ngIf="isEdit" class="l-mt-5">
            <app-cd-radio
              class="cd-radio-margin-bottom-clear"
              #cdRadioPreferredLanguage
              (valueChange)="iffForm.get('preferredLanguage')?.setValue($event)"
              [value]="iffForm.get('preferredLanguage')?.value"
              [config]="preferredLanguageConfig"></app-cd-radio>
            <div *ngIf="iffForm.get('preferredLanguage')?.invalid && iffForm.get('preferredLanguage')?.touched" style="margin-top: var(--cds-spacing-01)">
              <cds-assistive-text type="error">{{ 'common.plsSelect' | lang : { p1: 'sendIffInBatchSummary.preferredLanguage' | lang } }} </cds-assistive-text>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="isShowAllInfo">
      <div class="l-mt-5 info-box">
        <div>
          <span class="cds-h4" style="font-weight: 300">DBS</span>
        </div>
        <div class="line l-mt-5"></div>
        <ng-container *ngIf="dbs">
          <div class="l-d-f l-ai-cen l-mt-5">
            <div style="flex: 1">
              <div>
                <span class="cds-body2-bold">RM Referral Code</span>
                <span class="cds-body2-bold" style="color: var(--cds-color-dark-1-coral)">* </span>
                <span class="cds-body2-bold">:</span>
              </div>
            </div>
            <div style="flex: 2">
              <span *ngIf="step === 2">{{ dbsRmReferralCd.value || '-' }}</span>
              <app-cd-input
                *ngIf="step === 1"
                [type]="'number'"
                [formControl]="dbsRmReferralCd"
                [maxlength]="8"
                ngDefaultControl
                [isHasErrors]="dbsRmReferralCd.dirty && (dbsRmReferralCd.hasError('required') || dbsRmReferralCd.hasError('lengthError'))"
                [placeholder]="'Input RM Referral Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbsRmReferralCd.dirty && dbsRmReferralCd.hasError('required'); else second" type="error"
                  >Please input valid RM Referral Code
                </cds-assistive-text>
                <ng-template #second>
                  <cds-assistive-text *ngIf="dbsRmReferralCd.dirty && dbsRmReferralCd.hasError('lengthError')" type="error"
                    >Please input valid RM Referral Code
                  </cds-assistive-text>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-mt-5">
            <div style="flex: 1">
              <div class="cds-body2-bold">Customer ID:</div>
            </div>
            <div style="flex: 2">
              <span *ngIf="step === 2">{{ dbsCustomerId.value || '-' }}</span>
              <app-cd-input
                *ngIf="step === 1"
                [formControl]="dbsCustomerId"
                [maxlength]="8"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [isHasErrors]="dbsCustomerId.dirty && dbsCustomerId.hasError('lengthError')"
                [placeholder]="'Input Customer ID'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbsCustomerId.dirty && dbsCustomerId.hasError('lengthError')" type="error"
                  >Please input valid Customer ID
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-mt-5">
            <div style="flex: 1">
              <div class="cds-body2-bold">Campaign Code:</div>
            </div>
            <div style="flex: 2">
              <span *ngIf="step === 2">{{ dbsCampaignCd.value || '-' }}</span>
              <app-cd-input
                *ngIf="step === 1"
                [formControl]="dbsCampaignCd"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [isHasErrors]="dbsCampaignCd.dirty && dbsCampaignCd.hasError('lengthError')"
                [placeholder]="'Input Campaign Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbsCampaignCd.dirty && dbsCampaignCd.hasError('lengthError')" type="error"
                  >Please input valid Campaign Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="l-d-f l-ai-cen l-mt-5">
            <div style="flex: 1">
              <div class="cds-body2-bold">Source Code:</div>
            </div>
            <div style="flex: 2">
              <span *ngIf="step === 2">{{ dbsSourceCd.value || '-' }}</span>
              <app-cd-input
                *ngIf="step === 1"
                [formControl]="dbsSourceCd"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [isHasErrors]="dbsSourceCd.dirty && dbsSourceCd.hasError('lengthError')"
                [placeholder]="'Input Source Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbsSourceCd.dirty && dbsSourceCd.hasError('lengthError')" type="error"
                  >Please input valid Source Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="l-mt-5 info-box">
        <div>
          <span class="cds-h4" style="font-weight: 300">有關受規管活動</span>
        </div>
        <div class="line l-mt-5"></div>
        <div class="l-mt-5">
          <span class="cds-body2-demibold">在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求。</span>
        </div>
        <div class="l-mt-5">
          <app-cd-radio class="cd-radio-margin-bottom-clear" [(value)]="regulation" [config]="regulationConfig"></app-cd-radio>
        </div>
      </div>
      <div class="l-mt-5 info-box">
        <div>
          <span class="cds-h4" style="font-weight: 300">第二強積金中介人資料</span>
        </div>
        <div class="line l-mt-5"></div>
        <div class="info-list">
          <div class="l-mt-5"><span class="cds-body2-demibold">代理人編號</span><span class="cds-body2-demibold">:</span></div>
          <div class="l-mt-5">
            <span class="cds-body1">345678</span>
          </div>
          <div class="l-mt-5"><span class="cds-body2-demibold">代理人姓名</span><span class="cds-body2-demibold">:</span></div>
          <div class="l-mt-5">
            <span class="cds-body1">LAU TAI MAN</span>
          </div>
          <ng-container *ngIf="!isEdit">
            <div class="l-mt-5">
              <span class="cds-body2-demibold">客戶電郵地址/ 手機號碼</span
              ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">* </span><span class="cds-body2-demibold">:</span>
            </div>
            <div class="l-mt-5">
              <span class="cds-body1">{{ secondIntermediaryContactInformation === 'M' ? '(+852) 6188 ****' : 'wink******@manulife.com' }}</span>
            </div>
            <div class="l-mt-5"><span class="cds-body2-demibold">選擇語言 </span><span class="cds-body2-demibold">:</span></div>
            <div class="l-mt-5">
              <span class="cds-body1">{{ secondIntermediaryLang === 'en' ? '英文' : '中文' }}</span>
            </div>
          </ng-container>
        </div>
        <div class="regulation-box l-mt-5">
          <div>
            <span class="cds-detail2" style="font-weight: 300">有關受規管活動</span>
          </div>
          <div class="l-mt-4">
            <span class="cds-body2-demibold">在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求。</span>
          </div>
          <div class="l-mt-5">
            <app-cd-radio class="cd-radio-margin-bottom-clear" [(value)]="regulation" [config]="regulationConfig"></app-cd-radio>
          </div>
        </div>
        <div *ngIf="isEdit" class="l-mt-7">
          <div>
            <span class="cds-body2-demibold">請選擇以下已登記的第二強積金中介人聯絡方式完成聲明：</span>
          </div>
          <div class="l-mt-4">
            <app-cd-radio
              class="cd-radio-margin-bottom-clear second-intermediary-contact-information-radio"
              [(value)]="secondIntermediaryContactInformation"
              [config]="secondIntermediaryContactInformationConfig"></app-cd-radio>
          </div>
          <div class="l-mt-5 l-d-f l-ai-cen">
            <div style="margin-right: 102px">
              <span class="cds-body2-demibold">選擇語言：</span>
            </div>
            <div>
              <app-cd-radio class="cd-radio-margin-bottom-clear" [(value)]="secondIntermediaryLang" [config]="secondIntermediaryLangConfig"></app-cd-radio>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="step === 1" class="l-d-f l-pt-5 l-pl-7 l-pb-7 buttons-box">
    <cds-button [style]="'secondary'" (click)="onEdit()">重設</cds-button>
    <cds-button [disabled]="isDisableContinue" class="l-ml-4" (click)="onContinue()">繼續</cds-button>
  </div>
  <div *ngIf="step === 2" class="l-d-f l-pt-5 l-pl-7 l-pb-7 buttons-box">
    <cds-button [style]="'secondary'" (click)="onBackEdit()">返回編輯</cds-button>
    <cds-button class="l-ml-4" (click)="onSubmit()">確認並提交</cds-button>
  </div>
</div>
