import { ApeType } from 'src/app/core/models/enum/ape-type.enum';

export const I18N_KEY = {
  COMMON_SUCCESS: 'common.action.success',
  COMMON_ACTION_CANCLE: 'common.action.cancel',
  COMMON_CANCLE: 'common.cancel',
  COMMON_CONTINUE: 'common.gotItWithExclamation',
  COMMON_EDIT: 'common.backToEdit',
  NBV_RATIO_NULL: 'commission.ape.nbvRatio.placeholder',
};

export const NbvRatioItems: NbvRatioItem[] = [
  {
    apeType: ApeType.MPF_ER_AC,
    label: 'MPF-ER AC \n [Annualize Contribution]',
  },
  {
    apeType: ApeType.MPF_ER_TA,
    label: 'MPF-ER TA \n [Plan Transfer Asset]',
  },
  {
    apeType: ApeType.MPF_ER_IT_MPF_PAP,
    label: 'MPF-ER IT & MPF-PAP \n [Individual Transfer Asset]',
  },
  {
    apeType: ApeType.MPF_TVC,
    label: 'MPF-TVC \n [Contribution & Transfer Asset]',
  },
  {
    apeType: ApeType.MPF_SEP,
    label: 'MPF-SEP \n [Contribution & Transfer Asset]',
  },
  {
    apeType: ApeType.MPF_FRC,
    label: 'MPF-FRC \n [Contribution]',
  },
  {
    apeType: ApeType.OR_MA_PPS_AC,
    label: 'OR MA(PPS) AC \n [Annualize Contribution]',
  },
  {
    apeType: ApeType.OR_MA_PPS_TA,
    label: 'OR MA(PPS) TA \n [Plan Transfer Asset]',
  },
  {
    apeType: ApeType.MA_CPF_AC,
    label: 'MA(CPF) AC \n [Annualize Contribution]',
  },
  {
    apeType: ApeType.MA_CPF_TA,
    label: 'MA(CPF) TA \n [Plan Transfer Asset]',
  },
  {
    apeType: ApeType.MA_CPF_IT,
    label: 'MA(CPF) IT \n [Individual Transfer Asset]',
  },
  {
    apeType: ApeType.MA_PAP,
    label: 'MA-PAP \n [Individual Transfer Asset]',
  },
  {
    apeType: ApeType.MA_IVC,
    label: 'MA-IVC \n [Contribution & Transfer Asset]',
  },
];

export interface NbvRatioItem {
  apeType: string;
  label: string;
}
export interface ResNbvRatioItem {
  apeType: string;
  value: number;
}
