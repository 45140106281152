/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import moment from 'moment';
import { CdsToastService } from '@cds/ng-web-components/toast';

import { environment } from 'src/environments/environment';
import { BasicResponse } from 'src/app/core/models/response/response';
import { ResponseResult } from 'src/app/core/models/response/response-result';

import {
  EventItem,
  AddEventItem,
  QueryEventParams,
  Category,
  EventTypeCheck,
  EventTypeKey,
  ReviewEventParam,
  EventStatus,
  DividendRate,
  DividendRateDoSubmit,
} from './calendar.model';
import { CATEGORY, COLORS } from './calendar.config';
import { deepCopy } from 'src/app/utils/copy';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  nextYear = new Date().getFullYear() + 1;
  reviewEvents: any;
  declineEvents: any;
  approvalEvents: any[] = [];

  _crrentYear = this.nextYear + '';
  mothList: DividendRate[] = [];

  constructor(private http: HttpClient, private toast: CdsToastService) {
    this.initDividend();
  }

  // get approved events
  getEvents(paramsObj?: any) {
    const arr = [];
    switch ((paramsObj as QueryEventParams)?.eventType) {
      case EventTypeKey.A_C_MONTH_END:
        arr.push(this.getReportingEvents(paramsObj));
        break;
      case EventTypeKey.PROC_DATE:
        arr.push(this.getAccountingEvents(paramsObj));
        break;
      case EventTypeKey.COMMISSION:
        arr.push(this.getCommissionEvents(paramsObj));
        break;
      case EventTypeKey.HOLIDAY:
        arr.push(this.getReportingEvents(paramsObj));
        break;
      case EventTypeKey.DIVIDEND_RECORD_DATE:
        arr.push(this.getDividendRateEvents(paramsObj));
        break;
      default:
        arr.push(this.getCommissionEvents(paramsObj));
        arr.push(this.getReportingEvents(paramsObj));
        arr.push(this.getAccountingEvents(paramsObj));
        arr.push(this.getDividendRateEvents(paramsObj));
    }
    return forkJoin(arr);
  }

  getCommissionEvents(params?: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-event`, { params: params }).pipe(
      map(res => {
        if (res.data && res.data.events && res.data.events.length) {
          res.data.events = res.data.events.map((item: any) => {
            item.eventType = EventTypeKey.COMMISSION;
            return item;
          });
        }
        return res;
      })
    );
  }

  initDividend() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const list: DividendRate[] = [];
    months.forEach((x, index) => {
      list.push({
        month: String(index + 1).padStart(2, '0'),
        monthName: x,
        recordDate: '',
      });
    });
    this.mothList = list;
  }

  getMinDate(mon?: string) {
    return moment(this.nextYear + '-' + mon, 'YYYY-MM');
  }

  getMaxDate(mon?: string) {
    return moment(this.nextYear + '-' + mon, 'YYYY-MM')
      .add(1, 'M')
      .add(-1, 'd');
  }

  reviewItems() {
    return this.approvalEvents.filter(x => x.eventType == EventTypeKey.DIVIDEND_RECORD_DATE).length;
  }

  approveReviewDividendRate(params?: any) {
    return this.http
      .get<BasicResponse<any>>(`${environment.apiPrefix}${environment.paymentServiceExt}/dividend-calendar/approveReview`, { params: params })
      .pipe(
        map(res => {
          if (res.result !== ResponseResult.SUCCESS) {
            this.toast.error(res.message);
            return res;
          }
          if (res.data && res.data.length > 0) {
            const _map = res.data.reduce((group: { [x: string]: any[] }, i: { year: any }) => {
              const year = String(i.year) || '';
              if (!group[year]) {
                group[year] = [];
              }
              group[year].push(i);
              return group;
            }, {} as Record<number, any[]>);
            const _res = [];
            for (const [year, value] of Object.entries(_map)) {
              _res.push({
                year: year,
                eventType: EventTypeKey.DIVIDEND_RECORD_DATE,
                items: value,
              });
            }
            res.data = _res;
            return res;
          }
          return res;
        })
      );
  }

  loadDividendRate() {
    this.initDividend();
    return this.http
      .get<BasicResponse<DividendRate[]>>(`${environment.apiPrefix}${environment.paymentServiceExt}/dividend-calendar/editReview`, {
        params: {
          year: this.nextYear,
        },
      })
      .subscribe(res => {
        if (res.data && res.data.length > 0) {
          this.mothList.forEach(x => {
            res.data?.forEach(i => {
              if (x.month == i.month && i.recordDate) {
                x.recordDate = this.formatDate2(i.recordDate);
                x.id = i.id;
              }
            });
          });
        }
      });
  }

  formatDate(date?: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    return '';
  }

  formatDate2(date?: string) {
    if (date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return '';
  }

  doApproveDividendRate(event: any) {
    const params: DividendRateDoSubmit = {
      year: event.year,
      list: event.items,
    };
    return this.http.post<BasicResponse<boolean>>(`${environment.apiPrefix}${environment.paymentServiceExt}/dividend-calendar/doApprove`, params);
  }

  doDeclineDividendRate(event: any) {
    const params: DividendRateDoSubmit = {
      year: event.year,
      list: event.items,
    };
    return this.http.post<BasicResponse<boolean>>(`${environment.apiPrefix}${environment.paymentServiceExt}/dividend-calendar/doDecline`, params);
  }

  saveDividendRate() {
    const params: DividendRateDoSubmit = {
      year: this._crrentYear,
      list: deepCopy(this.mothList),
    };
    params.list?.forEach(x => {
      x.recordDate = this.formatDate(x.recordDate);
      x.year = this._crrentYear;
      x.month = x.month?.padStart(2, '0');
    });
    return this.http.post<BasicResponse<boolean>>(`${environment.apiPrefix}${environment.paymentServiceExt}/dividend-calendar/doSubmit`, params);
  }

  getDividendYearList() {
    return this.http.get<BasicResponse<Array<string>>>(`${environment.apiPrefix}${environment.paymentServiceExt}/dividend-rate/yearList`);
  }

  getDividendRateEvents(params?: any): Observable<BasicResponse<any>> {
    return this.http
      .get<BasicResponse<any>>(`${environment.apiPrefix}${environment.paymentServiceExt}/dividend-calendar/calendarList`, { params: params })
      .pipe(
        map(res => {
          if (res.data) {
            res.data = res.data.map((item: any) => {
              return {
                ...item,
                eventType: EventTypeKey.DIVIDEND_RECORD_DATE,
                eventId: item.id,
                eventEndDate: item.recordDate,
              };
            });
          }
          return res;
        })
      );
  }

  getReportingEvents(params?: any): Observable<BasicResponse<Array<EventItem>>> {
    return this.http.get<BasicResponse<Array<EventItem>>>(`${environment.apiPrefix}${environment.reportingServiceExt}/system-event`, { params: params });
  }

  getAccountingEvents(params?: any): Observable<BasicResponse<Array<EventItem>>> {
    return this.http.get<BasicResponse<Array<EventItem>>>(`${environment.apiPrefix}${environment.accountingServiceExt}/accounting/list`, { params: params });
  }

  setReportNoReviewEvents(cb: () => void) {
    const response = this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.accountingServiceExt}/accounting/reportNo/review-list`, {
      params: { year: this.nextYear },
    });
    response.subscribe(res => {
      if (res.result === ResponseResult.SUCCESS && res.data) {
        this.reviewEvents = res.data;
        cb();
      }
    });
  }

  // Report Events Review API
  reviewReportNoEvents(flowId: any) {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.accountingServiceExt}/accounting/reportNo/review/${flowId}`);
  }

  setApproveEvents(cb: () => void) {
    this.getApproveEvents()
      .pipe(
        map((data: any) => {
          return data
            .filter((result: BasicResponse<any>) => {
              return result.data;
            })
            .reduce((pre: Array<any>, cur: BasicResponse<Array<any>>) => {
              return pre.concat(cur.data as Array<EventItem>);
            }, []);
        })
      )
      .subscribe(data => {
        this.approvalEvents = data.map((item: any) => {
          return this.createApproveEvents(item);
        });
        cb();
      });
  }

  getApproveEvents() {
    const arr = [];
    arr.push(this.getApproveCommissionEvents({ year: this.nextYear, status: EventStatus.PENDING_APPROVE }));
    arr.push(this.getApproveReportNoEvents({ year: this.nextYear }));
    arr.push(this.approveReviewDividendRate());
    return forkJoin(arr);
  }

  getApproveCommissionEvents(params: any): Observable<BasicResponse<any>> {
    return this.http
      .get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-event/review/list`, {
        params: { ...params },
      })
      .pipe(
        map(res => {
          if (res.result !== ResponseResult.SUCCESS) {
            this.toast.error(res.message);
            return res;
          }
          if (res.data) {
            res.data = [
              {
                eventType: EventTypeKey.COMMISSION,
                ...res.data,
              },
            ];
          }
          return res;
        })
      );
  }

  getApproveReportNoEvents(params: any) {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.accountingServiceExt}/accounting/reportNo/approve-list`, { params }).pipe(
      map(res => {
        if (res.result !== ResponseResult.SUCCESS) {
          this.toast.error(res.message);
          return res;
        }
        if (res.data) {
          res.data = [
            {
              eventType: EventTypeKey.PROC_DATE,
              ...res.data,
            },
          ];
        }
        return res;
      })
    );
  }

  createApproveEvents(reviewEventItem: any) {
    const eventTypes = this.createEventTypes(CATEGORY);
    const eventType = eventTypes.find(item => item.key === reviewEventItem.eventType) || eventTypes[0];
    return {
      ...reviewEventItem,
      title: eventType.title,
      color: COLORS[eventType.color],
    };
  }

  // Report Events Approve API
  approveReportNoEvents(flowId: any) {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.accountingServiceExt}/accounting/reportNo/approve/${flowId}`);
  }

  // Commission Events Approve or Decline API
  reviewCommissionEvents(params: ReviewEventParam): Observable<BasicResponse<Array<any>>> {
    return this.http.post<BasicResponse<Array<any>>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-event/review`, params);
  }

  setDeclineEvents(cb: () => void) {
    const request = this.getDeclineCommissionEvents({ year: this.nextYear, status: EventStatus.REJECTED });
    request.subscribe(res => {
      if (res.data) {
        const eventTypes = this.createEventTypes(CATEGORY);
        const eventType = eventTypes.find(i => i.key === res.data.eventType);
        res.data.declineTime = moment(res.data.events[0].actionTime).format('DD-MM-yyyy');
        res.data.title = eventType?.title;
        this.declineEvents = res.data;
        cb();
      }
    });
  }

  getDeclineCommissionEvents(params: any): Observable<BasicResponse<any>> {
    return this.http
      .get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-event/review/list`, {
        params: { ...params },
      })
      .pipe(
        map(res => {
          if (res.result !== ResponseResult.SUCCESS) {
            this.toast.error(res.message);
            return res;
          }
          if (res.data) {
            res.data.eventType = EventTypeKey.COMMISSION;
          }
          return res;
        })
      );
  }

  // add commission events
  addCommissionEvents(params: Array<AddEventItem>): Observable<BasicResponse<Array<EventItem>>> {
    return this.http.post<BasicResponse<Array<EventItem>>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-event`, params);
  }

  getNextYearCommissionEvents() {
    const arr = [];
    arr.push(
      this.getCommissionEvents({
        eventStartDate: `${this.nextYear}-01-01`,
        eventEndDate: `${this.nextYear}-12-31`,
      }).pipe(
        map(res => {
          if (!res.data || !res.data.events) {
            res.data = null;
          }
          return res;
        })
      )
    );
    // arr.push(this.getApproveCommissionEvents({ year: this.nextYear, status: EventStatus.PENDING_APPROVE }));
    arr.push(this.getDeclineCommissionEvents({ year: this.nextYear, status: EventStatus.REJECTED }));
    return forkJoin(arr);
  }

  createEventTypes(category: Array<Category>): Array<EventTypeCheck> {
    const tempArr: Array<EventTypeCheck> = [];
    return category.reduce((pre, cur: Category) => {
      return pre.concat(cur.children);
    }, tempArr);
  }
}
