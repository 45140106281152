<div class="card-min-height p-3">
  <div class="row" style="align-items: end">
    <span style="font-weight: 600; font-size: 20px"> Chat History</span>
    <app-cd-table-title [total]="filterRows.length" [showSubTitle]="true"></app-cd-table-title>

    <div class="col-md-2">
      <div class="form-group pb-2">
        <input type="text" class="form-control" style="padding: 0.7rem" placeholder="Search by CSO" [(ngModel)]="cso" />
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group pb-2">
        <input type="text" class="form-control" style="padding: 0.7rem" placeholder="Search by Agent" [(ngModel)]="agent" />
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group pb-2">
        <input type="text" class="form-control" style="padding: 0.7rem" placeholder="Search by Type" [(ngModel)]="type" />
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group pb-2">
        <input type="text" class="form-control" style="padding: 0.7rem" placeholder="Search by Keyword" [(ngModel)]="keyword" />
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group pb-2">
        <cds-button
          [type]="'submit'"
          [ngClass]="{ 'm-loader m-loader--right m-loader--info': loading }"
          (click)="getChatHistory()"
          [size]="'sm'"
          [style]="'primary'"
          >Search</cds-button
        >
      </div>
    </div>
    <!-- <div class="form-group pb-2">
      <input type="text" class="form-control" style="padding: 0.7rem;" placeholder="Search User"
        (keyup)="searchChat($event)" />
    </div> -->
    <app-data-table [dataSource]="filterRows" [columnsConfig]="headers" [isLoading]="loading">
      <ng-template appDtBodyCellDef="date" let-element>
        {{ element.date | date : 'yyyy/MM/dd HH:mm' }}
      </ng-template>
      <ng-template appDtBodyCellDef="schemeCd" let-element>
        <div class="l-d-f l-ai-cen">
          <a (click)="download(element)" style="margin-right: 15px"> <cds-icon icon="arrow:download"></cds-icon></a>
          <a (click)="view(element)" style="margin-right: 15px"> <cds-icon icon="action:eye_1"></cds-icon></a>
        </div>
      </ng-template>
    </app-data-table>
  </div>
</div>
