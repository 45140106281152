<app-cd-popup>
  <cds-popup-content>
    <div class="content l-pl-2 l-pr-2">
      <div class="cds-h2-light l-mb-0 l-pb-1">
        {{ 'calendar.event.approval' | lang }}
      </div>
      <div class="row l-plr-0 l-mt-4 l-mb-2">
        <div class="col-xs-12 col-md-11 l-d-f alert l-pl-5 l-pr-5 l-pt-4 l-pb-4">
          <cds-icon class="cds-demibold l-pr-3" size="sm" icon="action:megaphone"></cds-icon>
          <div>
            <div class="cds-demibold l-pb-1">
              {{ 'common.alert' | lang }}
            </div>
            <div>
              {{ 'calendar.approve.alert' | lang }}
            </div>
          </div>
        </div>
      </div>
      <div class="l-pt-4">
        <div class="col-xs-12 col-md-8 l-plr-0 cds-demibold cds-dropdown-label ng-star-inserted label-color l-pb-3">
          {{ 'calendar.event.name' | lang }}
        </div>
        <div class="cds-h3-light l-mb-0">{{ 'dividend-rate.dividend' | lang }}</div>
      </div>
      <div class="l-d-f l-ai-cen l-pt-5">
        <span class="cds-body1 l-mb-0">{{ 'calendar.event.year' | lang }}</span>
        <span class="cds-h3 l-pl-3 l-mb-0 cds-demibold">{{ data.data.year }}</span>
      </div>
      <div class="row l-plr-0 l-pt-4">
        <div class="row col-xs-12 l-pb-4 l-plr-0 interest-rate div-bg-white">
          <table>
            <tr>
              <td class="border-right"></td>
              <td class="border-right">
                <span class="cds-body-demibold">{{ 'dividend-rate.month' | lang }}</span>
              </td>
              <td>
                <span class="cds-body-demibold">{{ 'dividend-rate.record-date' | lang }}</span>
              </td>
            </tr>
            <tr *ngFor="let item of data.data.items; let i = index" [ngClass]="[i % 2 !== 0 ? 'div-bg-white' : 'div-bg-light-grey']">
              <td class="border-right">
                <span>{{ item.month }}</span>
              </td>
              <td class="border-right">
                <span>{{ item.recordDate | date : 'MMMM' }}</span>
              </td>
              <td>
                <span>{{ item.recordDate | ssmsDate }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [style]="'secondary'" size="sm" (click)="decline()">{{ 'common.decline' | lang }}</cds-button>
    <cds-button size="sm" (click)="onSubmit()">{{ 'common.approve' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
