<app-cd-page-title
  [showBack]="true"
  [backLandingLabel]="'Commission Info Maintenance Record List' | lang"
  (backLanding)="backLanding()"
  title="{{ 'Commission Info Maintenance' | lang }}">
</app-cd-page-title>
<div class="l-pb-6">
  <div class="row">
    <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12 l-pt-7">
      <div class="l-mb-7">
        <span class="l-mr-4 cds-h5">{{ 'commission.pool.summary.commissionPoolNumber' | lang }}:</span>
        <span class="cds-h4-demibold">{{ summary.id }}</span>
        <span class="cds-h4 cds-demibold l-mb-0" *ngIf="summary.mainParty"
          >({{ 'commission.pool.related.list.th.mainParty' | lang }}:{{ summary.mainParty }})</span
        >
      </div>
      <div class="l-d-f l-ai-cen l-mb-5">
        <div class="l-mr-7 cds-h2 cds-light l-mb-0">{{ 'commission.pool.summary.commissionSummary' | lang }}</div>
        <div *ngIf="!loading">
          <div *ngIf="!editStatus && (role | permission : permission | async)" (click)="edit()" class="l-d-f l-ai-cen l-pt-2 cursor-pointer">
            <cds-icon class="l-pr-2" [height]="'50'" icon="action:edit"></cds-icon>
            <div class="cds-h6-demibold display-max-screen l-mb-0">
              {{ 'commission.pool.common.edit' | lang }}
            </div>
          </div>
          <div *ngIf="editStatus" (click)="cancelEdit()" class="l-d-f l-ai-cen l-pt-2 cursor-pointer">
            <cds-icon class="l-pr-2" [height]="'50'" icon="action:edit"></cds-icon>
            <div class="cds-h6-demibold display-max-screen l-mb-0">
              {{ 'commission.pool.common.cancelEdit' | lang }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <cds-progress *ngIf="loading" mode="indeterminate" class="l-pl-6 l-pt-6"></cds-progress>
    <div *ngIf="!loading" class="col-xs-12 col-md-12 col-sm-12 col-lg-12 l-pb-4">
      <div class="row l-plr-0">
        <div class="col-xs-12 col-md-4 l-pl-0 l-mb-5">
          <div class="l-pt-2 l-pb-2">
            <label class="cds-body2 cds-dark-navy-text light-3">{{ 'commission.pool.summary.firstContributionMonth' | lang }}</label>
          </div>
          <div *ngIf="!isConfirm && !isSpecialArrangement" class="l-pt-2 l-pb-2">
            {{ summary.firstContributionMonth | ssmsDate : 'MM/yyyy' }}
          </div>
          <div *ngIf="isConfirm" class="l-pt-2 l-pb-2">
            {{ summaryForm.firstContributionMonth | ssmsDate : 'MM/yyyy' }}
          </div>
          <div *ngIf="!isConfirm && isSpecialArrangement" class="l-pr-6">
            <input
              class="text-field"
              id="firstContributionMonth"
              [disabled]="disableInput"
              [(ngModel)]="firstContributionMonth"
              placeholder="MM/YYYY"
              (input)="input($event)" />
            <cds-assistive-text *ngIf="fcMonthError" class="l-pt-3">
              {{ fcMonthError | lang }}
            </cds-assistive-text>
            <cds-assistive-text *ngIf="fcMonthNotEdit" class="l-pt-3">
              {{ fcMonthNotEdit | lang }}
            </cds-assistive-text>
          </div>
          <div class="l-d-f l-ai-cen l-mt-2" *ngIf="editStatus && !isConfirm">
            <cds-checkbox [(ngModel)]="isSpecialArrangement" (click)="specialArrangement()"></cds-checkbox>
            <label class="l-ml-3 cds-body cds-dark-navy-text l-mb-0">{{ 'Special arrangement is applied' | lang }}</label>
          </div>
        </div>

        <div class="col-xs-12 col-md-4 l-pl-0 l-mb-5">
          <div class="l-pt-2 l-pb-2">
            <label class="cds-body2 cds-dark-navy-text light-3">{{ 'commission.pool.summary.nextCommYearStart' | lang }}</label>
          </div>
          <div class="l-pt-2 l-pb-2">
            {{ summary.nextCommYearStart | ssmsDate : 'MM/yyyy' }}
          </div>
        </div>

        <div class="col-xs-12 col-md-4 l-pl-0 l-mb-5">
          <div class="l-pt-2 l-pb-2">
            <label class="cds-body2 cds-dark-navy-text light-3">{{ 'commission.pool.summary.reconHitDate' | lang }}</label>
          </div>
          <div class="l-pt-2 l-pb-2">
            {{ summary.reconHitDate | ssmsDate }}
          </div>
        </div>

        <div class="col-xs-12 col-md-4 l-pl-0 l-mb-5 l-pt-5">
          <div class="l-d-f l-ai-cen" *ngIf="!editStatus">
            <cds-checkbox [(ngModel)]="summary.frozen" [disabled]="!editStatus"></cds-checkbox>
            <label class="l-ml-3 cds-body cds-dark-navy-text l-mb-0">{{ 'commission.pool.summary.frozen' | lang }}</label>
          </div>
          <div class="l-d-f l-ai-cen" *ngIf="editStatus">
            <cds-checkbox [(ngModel)]="summaryForm.frozen" (click)="change('frozen')" [disabled]="!editStatus || isConfirm"></cds-checkbox>
            <label class="l-ml-3 cds-body cds-dark-navy-text l-mb-0">{{ 'commission.pool.summary.frozen' | lang }}</label>
          </div>
          <cds-assistive-text *ngIf="editStatus && frozenError" class="l-pt-3">
            {{ frozenError | lang }}
          </cds-assistive-text>
        </div>

        <div class="col-xs-12 col-md-4 l-pl-0 l-mb-5 l-pt-5">
          <div class="l-d-f l-ai-cen" *ngIf="!editStatus">
            <cds-checkbox [(ngModel)]="summary.waiveMonthlyBonus" [disabled]="!editStatus"></cds-checkbox>
            <label class="l-ml-3 cds-body1 cds-dark-navy-text l-mb-0">{{ 'commission.pool.summary.waiveMonthlyBonus' | lang }}</label>
          </div>
          <div class="l-d-f l-ai-cen" *ngIf="editStatus">
            <cds-checkbox [(ngModel)]="summaryForm.waiveMonthlyBonus" (click)="change('wmBonus')" [disabled]="!editStatus || isConfirm"></cds-checkbox>
            <label class="l-ml-3 cds-body1 cds-dark-navy-text l-mb-0">{{ 'commission.pool.summary.waiveMonthlyBonus' | lang }}</label>
          </div>
          <cds-assistive-text *ngIf="editStatus && wmBonusError" class="l-pt-3">
            {{ wmBonusError | lang }}
          </cds-assistive-text>
        </div>

        <div class="col-xs-12 col-md-4 l-pl-0 l-mb-5">
          <div class="l-pt-2 l-pb-2">
            <label class="cds-body2 cds-dark-navy-text light-3">{{ 'common.business.subscriptionFee' | lang }}</label>
          </div>
          <div class="l-pt-2 l-pb-2">
            {{ summary.subscriptionFee }}
          </div>
        </div>

        <div *ngIf="editStatus && !isConfirm" class="col-xs-12 col-md-10 l-pt-6 l-pb-3 l-d-f">
          <cds-button [config]="btnCfg" size="md" (click)="reset()">{{ 'commission.pool.common.reset' | lang }} </cds-button>
          <cds-button class="l-ml-3" size="md" (click)="save()" [disabled]="saveDisabled">{{ 'commission.pool.common.save' | lang }} </cds-button>
        </div>
        <div *ngIf="editStatus && isConfirm" class="col-xs-12 col-md-10 l-pt-6 l-pb-3 l-d-f">
          <cds-button [config]="btnCfg" size="md" (click)="backToEdit()">{{ 'commission.pool.common.backToEdit' | lang }} </cds-button>
          <cds-button class="l-ml-3" size="md" (click)="confirm()" [disabled]="saveDisabled">{{ 'commission.pool.common.confirm' | lang }} </cds-button>
        </div>
      </div>
    </div>

    <div id="tabElement" class="col-xs-12 col-md-12 l-pt-4 div-border-bottom padding-none">
      <div class="row col-xs-12 col-md-12">
        <div class="tab l-mr-8" [class.tab-selected]="item.selected" *ngFor="let item of tab" (click)="tabClick(item)">
          <span class="cds-body-Demibold_16">{{ item.label | lang }}</span>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-md-12 padding-none">
      <app-related-parties-list *ngIf="tab[0].selected" commissionPoolId="{{ commissionPoolId }}"></app-related-parties-list>
      <app-commission-record-details *ngIf="tab[1].selected" commissionPoolId="{{ commissionPoolId }}"></app-commission-record-details>
      <app-commission-agent *ngIf="tab[2].selected" commissionPoolId="{{ commissionPoolId }}"></app-commission-agent>
      <app-individual-transfer [commissionPoolId]="commissionPoolId" *ngIf="tab[3].selected"></app-individual-transfer>
    </div>
  </div>
</div>
