/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import Big from 'big.js';
import { deepCopy } from 'src/app/utils/copy';

import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { UserAgreementPopupComponent } from 'src/app/views/commission/user-agreement-popup.component';

import { texts } from 'src/app/views/commission/summary/pool-info.model';
import { AdjustmentService } from 'src/app/core/services/commission/commission-adjustment.service';
import { CommissionPoolService } from 'src/app/core/services/commission/commission-pool.service';
import { AccountTypesNormalCompensation, CustomerStatus } from 'src/app/core/models/enum';

import { AdjustTypes, AccountAdjMap, AdjustAccumulations, Transactions } from './normal-compensation.model';
import { Validator } from '../adjustment.validator';

@Component({
  selector: 'app-normal-compensation',
  templateUrl: './normal-compensation.component.html',
  styleUrls: ['./normal-compensation.component.scss'],
})
export class NormalCompensationComponent implements OnInit {
  constructor(
    private cdsPopup: CdsPopupService,
    private adjustmentService: AdjustmentService,
    private poolService: CommissionPoolService,
    private validator: Validator
  ) {}

  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  loading = false;
  searchForm0 = {
    type: 1,
    commissionPoolId: '',
    accountType: '',
    accountTypeStr: '',
    accountCode: '',
    accountName: '',
    searchDisabled: true,
    errorMsg: '',
    warningMsg: '',
    policyWarning: '',
  };
  searchForm = deepCopy(this.searchForm0);

  saveDisabled = true;
  editStatus = true;
  isConfirm = false;
  accountTypes = AccountTypesNormalCompensation;
  adjustTypes = AdjustTypes;
  adjustAccumulations = AdjustAccumulations;
  transactions = Transactions;
  customers: any[] = [];
  customers2: any[] = [];
  adjustItems: any = {
    adjustType: '',
    adjustTypeStr: '',
    amount: '0.00',
    amountPremium: '0.00',
    hide: false,
    adjustTypes2: deepCopy(AdjustTypes),
  };
  item = {
    config: { size: 'sm', label: 'Negative Values On' },
    negativeOn: false,
    customerId: '',
    accountCode: '',
    agentCode: '',
    adjustItems: [deepCopy(this.adjustItems)],
  };
  dataSource = [deepCopy(this.item)];

  selectNegative(value: any, i: number) {
    this.saveDisabled = true;
    this.validator.negativeValidation(this.dataSource, value, i, this.searchForm).then(res => {
      this.checkOpen(res);
    });
  }

  inputAgentCode(event: any, i: number, j: number) {
    this.saveDisabled = true;
    event.target.value = event.target.value.replace(/[^\d]+/g, '');
    this.validator.agentCodeValidation(this.dataSource, event.target.value, i, j, this.searchForm).then(res => {
      this.checkOpen(res);
    });
  }

  inputAdjustType(event: any, i: number, j: number) {
    this.saveDisabled = true;
    this.dataSource[i].adjustItems[j].adjustTypes2 = this.adjustTypes.filter(item => item.label && item.label.indexOf(event.target.value) != -1);
    this.validator
      .adjustTypeValidation(this.dataSource, this.dataSource[i].adjustItems[j].adjustTypes2, event.target.value, i, j, this.searchForm)
      .then(res => {
        this.checkOpen(res);
      });
  }

  selectAdjustType(value: any, i: number, j: number) {
    this.saveDisabled = true;
    this.validator.adjustTypeValidation(this.dataSource, this.dataSource[i].adjustItems[j].adjustTypes2, value, i, j, this.searchForm).then(res => {
      this.checkOpen(res);
    });
  }

  inputAmount(event: any, i: number, j: number) {
    this.saveDisabled = true;
    if (event && event.target.value && event.target.value != '-') {
      this.validator.amountValidation(this.dataSource, event.target.value, i, j, this.searchForm).then(res => {
        this.checkOpen(res);
      });
    }
  }

  inputAmountPremium(event: any, i: number, j: number) {
    this.saveDisabled = true;
    if (event && event.target.value && event.target.value != '-') {
      this.validator.amountPremiumValidation(this.dataSource, event.target.value, i, j, this.searchForm).then(res => {
        this.checkOpen(res);
      });
    }
  }

  checkOpen(res: any) {
    this.saveDisabled = res.disable;
    if (res.errorMsg) {
      this.open(
        {
          message: texts.alertFailedMsg,
          continue: texts.alertFailedContinue,
          type: 'alert',
          params: { respMsg: res.errorMsg },
        },
        res.result
      );
    }
  }

  addAdjust(i: number) {
    this.dataSource[i].adjustItems.push(deepCopy(this.adjustItems));
    this.saveDisabled = this.validator.formValidation(this.dataSource, this.searchForm);
  }

  addAgent() {
    this.dataSource.push(deepCopy(this.item));
    this.saveDisabled = this.validator.formValidation(this.dataSource, this.searchForm);
  }

  delete(i: number, j: number) {
    if (this.dataSource[i].adjustItems && this.dataSource[i].adjustItems.length > j) {
      if (i > 0) {
        if (j == 0) {
          this.dataSource.splice(i, 1);
        } else {
          this.dataSource[i].adjustItems.splice(j, 1);
        }
      } else if (i == 0 && j > 0) {
        this.dataSource[i].adjustItems.splice(j, 1);
      }
    }
    this.validator.requestCheck(this.dataSource, this.searchForm).then(res => {
      this.checkOpen(res);
    });
  }

  backToEdit() {
    this.isConfirm = false;
  }

  save() {
    if (this.saveDisabled) {
      return;
    }
    this.isConfirm = true;
    this.searchForm.policyWarning = '';
    let policyCount = new Big(0);
    this.dataSource.forEach(item => {
      const items: any[] = item.adjustItems;
      items.forEach(subItem => {
        this.adjustTypes.forEach(type => {
          if (subItem.adjustType == type.value) {
            subItem.adjustTypeStr = type.label;
          }
        });
        if (subItem.adjustType == 'POLICY') {
          policyCount = policyCount.plus(new Big(subItem.amount));
        }
      });
    });
    if (!policyCount.eq(new Big(0)) && !policyCount.eq(new Big(1)) && !policyCount.eq(new Big(-1))) {
      this.searchForm.policyWarning = 'The total amount in Adjust Type: “20: Policy count” isn’t integer and the sum range aren’t between -1 to 1';
    }
  }

  confirm() {
    this.loading = true;
    this.adjustmentService.addAdjustment(this.validator.mapList(this.dataSource, this.searchForm)).subscribe(resp => {
      this.loading = false;
      let popData, result: number;
      if (resp && resp.result == 0) {
        popData = {
          message: texts.alertSuccessMsg,
          continue: texts.alertSuccessContinue,
          type: 'alert',
        };
        result = resp.result;
      } else {
        popData = {
          message: texts.alertFailedMsg,
          continue: texts.alertFailedContinue,
          type: 'alert',
          params: { respMsg: resp.message },
        };
        result = resp.result;
      }
      this.open(popData, result);
    });
  }

  open(popData: any, result: number) {
    popData.size = 'lg';
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree) {
        if (result == 0) {
          this.reset(false);
        } else {
          this.backToEdit();
        }
      }
    });
  }

  searchChange(event?: any) {
    this.searchForm.errorMsg = '';
    this.searchForm.warningMsg = '';
    if (event && event.target) {
      event.target.value = event.target.value.replace(/[^\d]+/g, '');
      this.searchForm.accountCode = event.target.value;
      if (!this.searchForm.accountCode) {
        this.searchForm.errorMsg = 'Account Type & Employer Account Code/ Member Account Code is (blank)';
      }
    }
    if (this.searchForm.accountType && this.searchForm.accountCode) {
      this.searchForm.searchDisabled = false;
    } else {
      this.searchForm.searchDisabled = true;
    }
  }

  search() {
    if (this.searchForm.searchDisabled) {
      return;
    }
    this.searchForm.commissionPoolId = '';
    const form = {
      accountType: this.searchForm.accountType,
      accountCode: this.searchForm.accountCode,
      currentEffective: true,
      page: 0,
      size: 1,
      status: 'VALID',
    };
    this.dataSource = [deepCopy(this.item)];
    this.isConfirm = false;
    this.saveDisabled = true;
    this.loading = true;
    this.poolService.selectList(form).subscribe(resp => {
      this.loading = false;
      if (resp && resp.data && resp.result == 0 && resp.data.content && resp.data.content.length > 0) {
        const commissionPoolCustomers: any[] = resp.data.content[0].commissionPoolCustomers;
        this.searchForm.commissionPoolId = resp.data.content[0].id;
        if (commissionPoolCustomers) {
          this.searchForm.accountTypeStr = commissionPoolCustomers[0].accountTypeStr;
          this.searchForm.accountName = commissionPoolCustomers[0].accountName;
          this.searchForm.warningMsg = commissionPoolCustomers[0].customerStatus == CustomerStatus.TERMINATED ? 'Terminated Account Code' : '';
        }
        this.loadInfo();
      }
      if (!this.searchForm.commissionPoolId) {
        this.searchForm.errorMsg = 'Cannot find the Employer Account Code/ Member Account Code on this Account Type';
      }
    });
  }

  reset(b: boolean) {
    if (b) {
      this.searchForm = deepCopy(this.searchForm0);
    }
    this.adjustItems.adjustTypes2 = deepCopy(this.adjustTypes);
    this.item.adjustItems = [deepCopy(this.adjustItems)];
    this.dataSource = [deepCopy(this.item)];
    this.backToEdit();
  }

  loadInfo() {
    this.adjustTypes = [];
    const arr = AccountAdjMap.get(this.searchForm.accountType);
    if (arr) {
      arr.forEach(adjustType => {
        AdjustTypes.forEach(item => {
          if (adjustType == item.value) {
            this.adjustTypes.push(item);
          }
        });
      });
    }
    this.reset(false);
  }

  alert() {
    const msg =
      '<div class="l-pl-4 l-pr-4"><span class="cds-h2-light l-pt-2">Only available for searching below account code:</span><br><br>' +
      '<div class="l-d-f l-pt-1"><div class="l-pl-2 l-pr-2">•</div>Employer Account Code on MPF-ER</div>' +
      '<div class="l-d-f l-pt-1"><div class="l-pl-2 l-pr-2">•</div>Member Account Code on MPF-SEP, MPF-SEP(AGENT), MPF-TVC, MPF-PAP,MACAU-PAP</div><br></div>';
    this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'md',
      data: {
        message: msg,
        continue: texts.alertSuccessContinue,
        type: 'alert',
        actionRight: true,
      },
    });
  }

  ngOnInit(): void {}
}
