import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, fromEvent, Observable, of, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-cd-realtime-search',
  templateUrl: './cd-realtime-search.component.html',
  styleUrls: ['./cd-realtime-search.component.scss'],
})
export class CdRealtimeSearchComponent implements OnInit {
  @Input() searchFunction!: (query: string, page: number, size: number) => Observable<{ label: string; value: string }[]>;
  @Output() resultSelected = new EventEmitter<string>();
  @Input() placeholder = '';
  @Input() searchControl!: FormControl;

  results: { label: string; value: string }[] = [];
  page = 1;
  size = 20;
  loading = false;
  haveMore = true;
  private scrollSubscription?: Subscription;

  @ViewChild('auto', { static: true }) matAutocomplete!: MatAutocomplete;

  ngOnInit() {
    if (this.searchFunction) {
      this.searchControl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap(query => {
            this.reset();
            this.loading = true;
            if (query === '') {
              return of([]);
            } else {
              return this.searchFunction(query, this.page, this.size);
            }
          })
        )
        .subscribe(data => {
          this.results = data;
          this.page++;
          this.loading = false;
        });
    }
  }

  onOpen() {
    setTimeout(() => {
      const panel = this.matAutocomplete.panel.nativeElement;
      this.scrollSubscription = fromEvent(panel, 'scroll')
        .pipe(debounceTime(200))
        .subscribe(() => this.onScroll());
    }, 50);
  }

  onClose() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  onScroll() {
    const panel = this.matAutocomplete.panel.nativeElement;
    const scrollTop = panel.scrollTop;
    const scrollHeight = panel.scrollHeight;
    const elementHeight = panel.clientHeight;

    if (scrollHeight - scrollTop === elementHeight && !this.loading && this.searchControl.value !== '' && this.haveMore) {
      this.loading = true;
      this.searchFunction(this.searchControl.value, this.page, this.size).subscribe(data => {
        this.results = [...this.results, ...data];
        this.page++;
        this.loading = false;
        if (data.length === 0) {
          this.haveMore = false;
        }
      });
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedOption = this.results.find(item => item.label === event.option.value);
    this.resultSelected.emit(selectedOption?.value);
  }

  reset() {
    this.results = [];
    this.page = 1;
    this.haveMore = true;
  }

  clearInput() {
    this.searchControl.setValue('');
  }
}
