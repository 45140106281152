// export class producttypeResponse {
//   pendingsend?: number;
//   pendingcustomer?: number;
//   pendingAgent?: number;
//   result?: string;
// }
export class ProductTypeResponse {
  result?: number;
  message?: string;
  data?: CheckResponseData;
}

export interface CheckResponseData {
  TVC?: TVC;
  PA?: PA;
  SVC?: SVC;
  ER?: ER;
  SEP?: SEP;
}

export interface TVC {
  pendingToMap?: number;
  pending2ndAgent?: number;
  pendingCusSubmission?: number;
}

export interface PA {
  pendingToMap?: number;
  pending2ndAgent?: number;
  pendingCusSubmission?: number;
}

export interface SVC {
  pendingToMap?: number;
  pending2ndAgent?: number;
  pendingCusSubmission?: number;
}

export interface ER {
  pendingToMap?: number;
  pending2ndAgent?: number;
  pendingCusSubmission?: number;
}

export interface SEP {
  pendingToMap?: number;
  pending2ndAgent?: number;
  pendingCusSubmission?: number;
}
