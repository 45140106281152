import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { NotifyService } from 'src/app/core/services/notify.service';
import { CdsOption } from '@cds/ng-core/configuration';
import { environment } from 'src/environments/environment';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { ThemeOptions } from '../theme-options';
import { ConfigActions } from '../ThemeOptions/store/config.actions';
import { LoginType } from '../../user/login/login.component';
import { loadGoHomeFunc } from 'src/app/utils/utils';

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./../styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupervisorComponent implements AfterViewInit {
  langList: CdsOption[] = environment.languageList;
  selectedLang = 'en';
  isHideNavigationLang = false;
  private sharData: Subject<string> = new Subject();
  constructor(
    public globals: ThemeOptions,
    public configActions: ConfigActions,
    private languageChangeService: LanguageChangeService,
    private authenticationService: AuthenticationService,
    public router: Router,
    private notifyService: NotifyService
  ) {}

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  logout() {
    let loginType = localStorage.getItem('loginType') as LoginType;
    if (!loginType) {
      loginType = 'sso';
    }
    this.authenticationService.logout(loginType, this.router);
  }

  ngAfterViewInit(): void {
    loadGoHomeFunc(() => {
      this.router.navigate(['/home']);
    });
  }

  ngOnInit(): void {
    const styleTags = document.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
      const styleTag = styleTags[i] as any;
      if (styleTag.textContent.includes('chatbot-admin-style-marker-deletion-prohibited')) {
        if (styleTag.hasAttribute('media')) {
          styleTag.removeAttribute('media');
        }
      }
    }
  }

  changeLang(lang: string) {
    this.selectedLang = lang;
    this.languageChangeService.setLang(lang);
    this.sendData(lang);
  }

  sendData(value: string) {
    this.sharData.next(value);
  }

  ngOnDestroy(): void {
    const styleTags = document.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
      const styleTag = styleTags[i] as any;
      if (styleTag.textContent.includes('chatbot-admin-style-marker-deletion-prohibited')) {
        styleTag.setAttribute('media', 'max-width: 1px');
      }
    }
  }
}
