/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, delay } from 'rxjs';
import { BasicResponse } from 'src/app/core/models/response/response';

// import { environment } from 'src/environments/environment';
const data = [
  {
    employerAccountCode: '80176232',
    payrollGroupId: '00',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '01',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '02',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '03',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '04',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '05',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '06',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '07',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '08',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '09',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '10',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '11',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '12',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
  {
    employerAccountCode: '80176232',
    payrollGroupId: '13',
    runDate: '2024-03-13',
    memberAccountCode: '',
    txnDate: '13MAR2024',
    txnCode: '405',
    transactionName: 'MPF EE CERT TERM',
    amountDebited: '2,199.20',
    accountNumber: '9153-00',
    fundCode: '000',
    amountCredited: '2,199.20',
    schemeType: 'ORSO',
    currency: 'HK',
    reportNo: '51',
    workArea: '',
    process: '',
    adminUnit: '',
    lastRunDate: '2024-03-13',
  },
];
@Injectable({
  providedIn: 'root',
})
export class FinancialJournalService {
  constructor(private http: HttpClient) {}

  getData(params: any): Observable<BasicResponse<any>> {
    const totalCount = data.length;
    const index = params.page * params.size;
    const size = index + params.size > totalCount ? totalCount - index : params.size;
    return of({
      message: 'SUCCESS',
      result: 0,
      data: {
        totalElements: totalCount,
        content: data.slice(index, index + size),
      },
    }).pipe(delay(500));
    // return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.accountingServiceExt}${'/finanical-journal/list'}`, {
    //   params: params,
    // });
  }
}
