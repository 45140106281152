<div class="container-fluid p-0">
  <div class="card">
    <div class="card-header-tab card-header space-content" style="justify-content: space-between">
      <div class="card-header-title font-size-lg text-capitalize fw-normal">Send Canned Message</div>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="form-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Canned Message</label>
              <ng-select class="cb-select" (change)="selectMessage($event)" [(ngModel)]="cannedMessage" placeholder="Search Canned Message">
                <ng-option *ngFor="let item of cannedMessageDDL" [value]="item">
                  <div [innerHTML]="lang == 'en' ? item?.msg : item?.chineseMsg"></div>
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="required">Message details:</label>
              <angular-editor [placeholder]="'Enter text here...'" [config]="config" [(ngModel)]="cannedMessageDetail"></angular-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <cds-button [disabled]="loading" [size]="'sm'" [style]="'secondary'" (click)="activeModal.dismiss('Cross click')">Cancel</cds-button>

      <cds-button
        [ngClass]="{ 'm-loader m-loader--right m-loader--info': loading }"
        (click)="sendMessage()"
        [disabled]="loading"
        [size]="'sm'"
        [style]="'primary'"
        >Send</cds-button
      >
    </div>
  </div>
</div>
