import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { HighRiskFileUploadCenterComponent } from './high-risk-file-upload-center/high-risk-file-upload-center.component';
import { HighRiskFileUploadCenterRoutingModule } from './high-risk-file-upload-center-routing.module';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { CdsButtonModule } from '@cds/ng-web-components/button';

@NgModule({
  declarations: [HighRiskFileUploadCenterComponent, UploadDocumentComponent],
  imports: [
    CommonModule,
    CdsLanguageModule,
    CdsIconModule,
    CdsTableModule,
    CdsFileUploadModule,
    SharedModule,
    HighRiskFileUploadCenterRoutingModule,
    CdsProgressModule,
    CdsPaginationModule,
    CdsButtonModule,
  ],
})
export class HighRiskFileUploadCenterModule {}
