import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatService } from 'src/app/views/chatbot/_service/chat.service';

@Component({
  selector: 'app-view-chat',
  templateUrl: './view-chat.component.html',
  styleUrls: ['./view-chat.component.scss'],
})
export class ViewChatComponent implements OnInit {
  @Input() data: any;
  constructor(public activeModal: NgbActiveModal, public chatService: ChatService) {}

  ngOnInit(): void {
    console.log(this.data);
    this.onClickChat(this.data);
  }

  chatJson: any = [];
  onClickChat(item: any) {
    this.chatService.getUserChatHistory(this.data.id).subscribe(resp => {
      if (resp) {
        this.chatJson = resp || [];
      }
    });
  }
}
