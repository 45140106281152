/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CdsCheckboxConfig } from '@cds/ng-core/checkbox';
import {
  VestingRuleDetail,
  VoluntaryRule,
  CalMnthServiceTypE,
  calMnthServiceTypMapObj,
  CalBaseUntE,
  calBaseUntMapObj,
  SubacctTypE,
  subacctTypMapObj,
  VestTermReasonE,
  vestTermReasonMapObj,
  IncomeTypeE,
  incomeTypeMapObj,
  VestingRuleOrso,
  NoPayLeaveMap,
  NoPayLeaveE,
  OrsoVestingE,
  OrsoVestingMap,
  VestingClassMap,
  VestingClassE,
  MpfTermination,
  BillingClass,
  RequirementTypeE,
  VestingRuleMpfDTO,
  VoluntaryRuleMpfDTO,
  definitionOfServiceTypMapObj,
} from '../../employer';
import { EmployerService } from '../../employer.service';
import { finalize } from 'rxjs';
import { deepCopy } from 'src/app/utils/copy';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vesting-scale',
  templateUrl: './vesting-scale.component.html',
  styleUrls: ['./vesting-scale.component.scss'],
})
export class VestingScaleComponent implements OnInit, OnChanges {
  @Input() billingClass?: BillingClass;
  @Input() id?: string;

  CalMnthServiceTypE = CalMnthServiceTypE;

  vestingRuleDisplayedColumns = ['completedMonthOfServiceFrom', 'completedMonthOfServiceTo', 'vestingRate'];

  relevantIncomeDataSource: VestingRuleDetail[] = [];

  relevantIncomeDisplayedColumns = ['completedMonthOfServiceFrom', 'completedMonthOfServiceTo', 'contributionRate', 'contributionAmount', 'cappedAmount'];

  vestingSchemeDisplayedColumns = ['breakPoint', 'vestRt'];

  bestVestingData: VestingRuleDetail[] = [];

  hasConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: true,
  };

  vestingRuleOrsoList: VestingRuleOrso[] = [];

  vestingRuleMpfList: VestingRuleMpfDTO[] = [];

  voluntaryRuleList: VoluntaryRuleMpfDTO[] = [];

  noPayLeaveTypeDataSource: VestingRuleOrso[] = [];

  noPayLeaveTypeDisplayedColumns = ['type', 'vesting'];

  noPayLeaveRangeDisplayedColumns = ['type', 'min', 'full'];

  isLoading = false;

  vestingLoading = false;

  voluntaryLoading = false;

  showOrso = false;

  constructor(
    public service: EmployerService,
    private toastAlert: ToastAlertService,
    private permissionService: PermissionService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('>>>>>>>>>>>>> vesting', changes);
    if (changes['id'].currentValue) {
      this.showOrso = this.isOrsoSchema();
      if (this.showOrso) {
        this.getOrsoData();
      } else {
        this.getMpfData();
        this.getVoluntaryData();
      }
    }
  }

  isOrsoSchema() {
    return this.id?.split('-')[0] == 'ORSO';
  }
  getOrsoData() {
    this.vestingLoading = true;

    this.service
      .getVestingRules({
        id: this.id,
        // payrollGroupId: this.billingClass?.payrollGroupId,
      })
      .pipe(
        finalize(() => {
          this.vestingLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.vestingRuleOrsoList = res.data || [];
        } else {
          this.toastError(res.message);
        }
      });
  }

  getMpfData() {
    this.vestingLoading = true;

    this.service
      .getVestingRules({
        id: this.id,
        // payrollGroupId: this.billingClass?.payrollGroupId,
      })
      .pipe(
        finalize(() => {
          this.vestingLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          try {
            this.vestingRuleMpfList = res.data;
          } catch (error) {
            console.error(error);
            this.vestingRuleMpfList = [];
          }
        } else {
          this.toastError(res.message);
        }
      });
  }

  getVoluntaryData() {
    this.voluntaryLoading = true;

    this.service
      .getVoluntaryRules({
        id: this.id,
        // payrollGroupId: this.billingClass?.payrollGroupId,
      })
      .pipe(
        finalize(() => {
          this.voluntaryLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          try {
            this.voluntaryRuleList = res.data;
          } catch (error) {
            console.error(error);
            this.voluntaryRuleList = [];
          }
        } else {
          this.toastError(res.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  mpfDataHandler(data: any) {
    let list: any[] = [];
    Object.keys(data).forEach(key => {
      list = [...list, ...data[key]];
    });
    return list.map(item => {
      return {
        ...item,
        structuredTerminationList: this.mpfDataSourceHandler(item.terminationReasonList),
      };
    });
  }

  voluntaryDataHandler(data: VoluntaryRule[]) {
    const list: VoluntaryRule[] = [];
    // Object.keys(data).forEach(key => {
    //   const rules: VestingRuleDetail[] = data[key].map((item: VoluntaryRule) => {
    //     return {
    //       mnthOfServiceFrm: item.mnthOfServiceFrm,
    //       mnthOfServiceTo: item.mnthOfServiceTo,
    //       conRt: item.conRt,
    //       conAmt: item.conAmt,
    //       vcCapdAmt: item.vcCapdAmt,
    //     };
    //   });
    //   const item = deepCopy(data[key][0]);
    //   item.rules = rules;
    //   list = [...list, item];
    // });
    // return list;

    data.forEach(dataItem => {
      const rule: VestingRuleDetail = {
        mnthOfServiceFrm: dataItem.mnthOfServiceFrm,
        mnthOfServiceTo: dataItem.mnthOfServiceTo,
        conRt: dataItem.conRt,
        conAmt: dataItem.conAmt,
        vcCapdAmt: dataItem.vcCapdAmt,
      };
      const sameIndex = list.findIndex(listItem => listItem.erGrdMbClss === dataItem.erGrdMbClss);
      if (sameIndex === -1) {
        const newListItem = {
          ...dataItem,
          rules: [rule],
        };
        list.push(newListItem);
      } else {
        list[sameIndex].rules?.push(rule);
      }
    });

    return list;
  }

  calMnthServiceTypRender(type: CalMnthServiceTypE) {
    return calMnthServiceTypMapObj[type] || '';
  }

  definitionOfServiceTypRender(type: CalMnthServiceTypE) {
    return definitionOfServiceTypMapObj[type] || '';
  }

  calBaseUntRender(type: CalBaseUntE) {
    return calBaseUntMapObj[type] || '';
  }

  subacctTypRender(type: SubacctTypE) {
    return subacctTypMapObj[type] || '';
  }

  incomeTypRender(type: IncomeTypeE) {
    return incomeTypeMapObj[type] || '';
  }

  vestTermReasonsRedner(typeList: VestTermReasonE[]) {
    if (!typeList) return '';
    const name = typeList.map(item => vestTermReasonMapObj[item] || '').join(', ');
    return name;
  }

  noPayLeaveRender(type: NoPayLeaveE) {
    if (type === undefined) return '';
    return NoPayLeaveMap.get(type) || '';
  }

  OrsoVestingRender(type: OrsoVestingE) {
    if (type === undefined) return '';
    return OrsoVestingMap.get(type) || '';
  }

  getNoPayLeaveTypeDataSource(item: VestingRuleOrso) {
    if (!item) return [];
    return [
      {
        type: 'Death Benefit Vesting',
        vesting: item.deathBenefitVesting,
      },
      {
        type: 'Early Retirement Vesting',
        vesting: item.earlyRetirementVesting,
      },
      {
        type: 'Disability Vesting',
        vesting: item.disabilityVesting,
      },
      {
        type: 'Discontinued Vesting',
        vesting: item.discontinuedVesting,
      },
    ];
  }

  getRequiredType(type: RequirementTypeE | undefined) {
    if (type == undefined) {
      return 'NA';
    }
    if (type == RequirementTypeE.NA) {
      return 'NA';
    }
    if (type == RequirementTypeE.REQUEST_1_OF_3) {
      return 'REQUEST 1 OF 3';
    }
    if (type == RequirementTypeE.REQUEST_2_OF_3) {
      return 'REQUEST 2 OF 3';
    }
    if (type == RequirementTypeE.REQUEST_ALL_3) {
      return 'REQUEST ALL 3';
    }
    return 'NA';
  }

  getNoPayLeaveRangeDataSource(item: VestingRuleOrso) {
    if (!item) return [];
    return [
      {
        type: 'Age',
        min: item.minimumVestingAge,
        full: item.fullVestingAge,
      },
      {
        type: 'Service Year/ Month',
        min: item.serviceYearOrMonthMinimumVesting,
        full: item.serviceYearOrMonthFullVesting,
      },
      {
        type: 'Participation Year/ Month',
        min: item.participationYearOrMonthMinimumVesting,
        full: item.participationYearOrMonthFullVesting,
      },
      {
        type: 'Requirement Type',
        min: this.getRequiredType(item.requirementTypeMinimumVesting),
        full: this.getRequiredType(item.requirementTypeFullVesting),
      },
    ];
  }

  getVestingSchemeDataSource(item: any) {
    if (!item) return [];
    const list: any[] = [];
    Object.keys(item).forEach(key => {
      if (key.startsWith('vestingSchemeBreakpoint')) {
        const number = Number(key.slice(23)) - 1;
        if (list[number]) {
          list[number].breakPoint = item[key];
        } else {
          list[number] = {
            breakPoint: item[key] as string,
            vestRt: '',
          };
        }
      } else if (key.startsWith('vestingSchemePercentage')) {
        const number = Number(key.slice(23)) - 1;
        if (list[number]) {
          list[number].vestRt = item[key];
        }
      }
    });
    return list;
  }

  mpfRoleRender(type: VestingClassE) {
    if (!type) return '';
    return VestingClassMap.get(type) || '';
  }

  mpfDataSourceHandler = (list: MpfTermination[]) => {
    if (!list) return [];
    const categorizedData = this.category(list);
    const dataSource = this.deduplicat(categorizedData);
    return dataSource;
  };

  category = (list: MpfTermination[]) => {
    const data: any = {};
    list
      .sort((a, b) => {
        if (a.mnthOfServiceFrm !== undefined && b.mnthOfServiceFrm !== undefined) {
          return a.mnthOfServiceFrm - b.mnthOfServiceFrm;
        }
        return -1;
      })
      .forEach(item => {
        if (item.vestTermReason) {
          if (!data[item.vestTermReason]) {
            data[item.vestTermReason] = [];
          }
          const rowData = deepCopy(item);
          delete rowData.vestTermReason;
          data[item.vestTermReason].push(rowData);
        }
      });
    return data;
  };

  deduplicat = (data: any) => {
    //keysList = [['A', 'B'],  ['C']]
    const keysList: string[][] = [];
    //valueStrList = ['[str]', '[str]']
    const valueStrList: string[] = [];

    Object.keys(data).forEach(key => {
      const valueStr = JSON.stringify(data[key]);

      const sameIndex = valueStrList.indexOf(valueStr) as number;

      if (sameIndex === -1) {
        keysList.push([key]);
        valueStrList.push(valueStr);
      } else {
        keysList[sameIndex].push(key);
      }
    });

    // [
    //   {
    //     reasons: ['A', 'B'],
    //     dataSource: [...]
    //   },
    // ]
    const list: any[] = [];

    keysList.forEach(item => {
      const reasons = deepCopy(item);
      const dataSource = deepCopy(data[item[0]]);
      list.push({
        reasons,
        dataSource,
      });
    });
    return list;
  };
}
