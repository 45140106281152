import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { HighRiskFileUploadCenterComponent } from './high-risk-file-upload-center/high-risk-file-upload-center.component';

const routes: Routes = [
  {
    path: 'high-risk-file-upload-center',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: HighRiskFileUploadCenterComponent,
        // data: {
        //   roles: {
        //     [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
        //   },
        // },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HighRiskFileUploadCenterRoutingModule {}
