import { FormControl } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';

export enum AreaCode {
  HK = '852',
  MA = '853',
  CN = '86',
}

export enum ResendEiffResendType {
  ER,
  EE,
}

export interface AgentOfDetailByMdNoRsp {
  agentIndex: number;
  agentName: string;
  agentNameTC: string;
  agentCode: string;
  isRgltdActAck: boolean; // regulatedAct
  isPrimaryAgent: boolean;
  language: 'CN' | 'EN'; // smsLanguage
  commChannel: 'SMS' | 'EMAIL';
  email: string;
  phone: string;
  shortEmail?: string;
  shortPhone?: string;
  isSplitSent: boolean;

  channelConfig?: CdRadioConfig;
  channelValue?: number;
}

export interface DetailByMdNoRsp {
  language: 'CN' | 'EN';
  commChannel: 'SMS' | 'EMAIL';
  email: string;
  phoneCountry: string;
  phone: string;
  primaryAgent: AgentOfDetailByMdNoRsp;
  secondAgent: AgentOfDetailByMdNoRsp;
  thirdAgent: AgentOfDetailByMdNoRsp;
  brNo: string;
  companyName: string;
  clientName: string; // last first name
  lastName: string;
  firstName: string;
  hkId: string;
  hkIdCheckDigit: string;
  passportNo: string;
  hkidOrPassportNo: string;
  authorizedPersonNameControl: FormControl;
  authorizedPersonNameConfig: CdsDropdownConfig;
  contactInfos: {
    personName: string;
    email: string;
    phoneCountry: string;
    phone: string;
  }[];
  dbs?: {
    isDBS: boolean;
    dbsRmReferralCd: string;
    dbsCustomerId: string;
    dbsCampaignCd: string;
    dbsSourceCd: string;
  };
  apFirstName: string;
  apLastName: string;
  apPhone: string;
  apEmail: string;
  apCountryCallingCd: string;
  apCommChannel: string;
}

export interface ResendEiffResendInfoRsp {
  empfRefNo: string;
  firstName: string;
  lastName: string;
  hkidOrPassportNo: string;
  countryCallingCd: string;
  phoneNumber: string;
  emailAddress: string;
  commChannel: string;
  specialNeeds: number;
  regulatedAct: boolean;
  companyName: string;
  brNo: string;
  customerId: string;
  authorizedPersonNameControl: FormControl;
  authorizedPersonNameConfig: CdsDropdownConfig;
  agentInfos: {
    agentid: string;
    agentCd: string;
    regulatedAct: boolean;
    agentNotificationChannel: string;
    emailAddress: string;
    mobile: string;
    agentNameTc: string;
    agentName: string;
    channelConfig?: CdRadioConfig;
    channelValue?: number;
    iffAgentStatusEnum: number;
    smsLanguage?: 0 | 1;
  }[];
  dbs?: {
    dbsRmReferralCd: string;
    dbsCustomerId: string;
    dbsCampaignCd: string;
    dbsSourceCd: string;
  };
  smsLanguage?: 0 | 1;
}

export interface ResendEiffResendSubmissionParams {
  salesJourneySummaryId: string;
  empfRefNo?: string;
  communicationChannel: string;
  authPersonId: string;
  email?: string;
  countryCallingCode?: string;
  mobile?: string;
  customerId?: string;
  splitAgentInfos: {
    agentid: string;
    agentNotificationChannel: string;
  }[];
  dbs?: {
    dbsRmReferralCd: string;
    dbsCustomerId: string;
    dbsCampaignCd: string;
    dbsSourceCd: string;
  };
  smsLanguage?: 0 | 1;
}
