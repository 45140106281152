<div class="l-pb-6 l-pt-6 l-pl-6 l-pr-6 background-grey product-type-count-details-popup">
  <div>
    <p class="cds-h5-light l-pb-2 papending-fontbold">Individuals IFF Pending Status</p>

    <div>
      <span class="papending-fontbold">{{ data.productType }}</span>
    </div>
    <div class="papending-align 1-pt-2">
      <div style="display: flex">
        <div class="papending-lightalign">
          <div class="light" [ngStyle]="{ 'background-color': pendingToMapColor }"></div>
        </div>
        <div>
          <span>Pending to map :</span>
        </div>
      </div>
      <span>{{ detailsByProductType.pendingToMap }}</span>
    </div>
    <div class="papending-align 1-pt-2">
      <div style="display: flex">
        <div class="papending-lightalign">
          <div class="light" [ngStyle]="{ 'background-color': PendingCustomerSubmissionColor }"></div>
        </div>
        <div>
          <span>Pending customer submission :</span>
        </div>
      </div>
      <span>{{ detailsByProductType.pendingcustomer }}</span>
    </div>
    <div class="papending-align 1-pt-2">
      <div style="display: flex">
        <div class="papending-lightalign">
          <div class="light" [ngStyle]="{ 'background-color': pendingSplitAgentColor }"></div>
        </div>
        <div>
          <span>Pending split agent(s) acknowledgement :</span>
        </div>
      </div>
      <span>{{ detailsByProductType.pendingAgent }}</span>
    </div>

    <div class="row l-pt-6 col-xs-12 col-md-12 l-d-f l-jc-sb">
      <div></div>
      <cds-button label="Got it" (click)="gotIt()" [config]="clearButton"></cds-button>
    </div>
  </div>
</div>
