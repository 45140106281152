import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { BillingClass, EmployerProfile, EmployerProfileDetail, employerStatusMapObj } from '../employer';
import { EmployerProfileCompanyListService } from '../employer-profile-company-list/employer-profile-company-list.service';
import { EmployerProfileInvestedComponent } from '../employer-profile-invested/employer-profile-invested.component';

@Component({
  selector: 'app-employer-profile-title',
  templateUrl: './employer-profile-title.component.html',
  styleUrls: ['./employer-profile-title.component.scss'],
})
export class EmployerProfileTitleComponent implements OnInit {
  @Input() detailShow = false;
  @Input() positionShow = true;
  @Input() lineShow = true;
  @Input() showQuickAccessBtn = false;
  @Input() showErInfoBtn = false;
  @Input() employerProfileDetail?: EmployerProfileDetail;
  @Input() payrollGroup?: BillingClass;

  @Output() erProfileInfoDetailShow = new EventEmitter();
  @Output() stickyStatusChange = new EventEmitter();

  status = 'Active';

  data?: EmployerProfile;

  btnConfig: CdsButtonConfig = {
    size: 'sm',
  };

  showQuickAccess = false;

  id = '';
  schema = '';

  toolTipString = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet nisl ultricies, ultrices augue id, finibus diam. ';

  sticky = false;

  employerStatusMapObj = employerStatusMapObj;

  constructor(
    private cdsPopup: CdsPopupService,
    public companyService: EmployerProfileCompanyListService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.queryParams['id'];
    this.schema = this.route.snapshot.queryParams['schema'];

    const scrollDiv = document.getElementsByClassName('cds-navigation-content')[0] as HTMLElement;
    scrollDiv.onscroll = this.scrollFunction;
  }

  scrollFunction = () => {
    const scrollDiv = document.getElementsByClassName('cds-navigation-content')[0] as HTMLElement;
    const navigation = document.getElementById('ttt') as HTMLElement;
    const style = getComputedStyle(navigation);
    const height = parseInt(style.paddingTop) + +parseInt(style.height) + parseInt(style.paddingBottom);
    if (scrollDiv.scrollTop > height) {
      this.sticky = true;
      this.forceRender();
    } else {
      this.sticky = false;
      this.sticky = false;
      this.forceRender();
    }
  };

  showInfo() {
    this.cdsPopup.open(EmployerProfileInvestedComponent, {
      size: 'lg',
      data: {},
    });
  }

  goBack() {
    this.router.navigate(['/employer/profile-detail'], {
      queryParams: {
        id: this.id,
        schema: this.schema,
      },
    });
  }

  quickAccess(status?: boolean) {
    if (status !== undefined) {
      this.showQuickAccess = status;
    } else {
      this.showQuickAccess = !this.showQuickAccess;
    }
  }

  updateDate(date: string) {
    if (!date) {
      return '';
    }
    return moment(date).format('h:mma DD MMM, YYYY');
  }

  forceRender() {
    this.cdr.detectChanges();
    this.stickyStatusChange.emit(this.sticky);
    const hostElement = this.el.nativeElement;
    this.renderer.setStyle(hostElement, 'box-shadow', this.sticky ? '0px 8px 6px -6px rgba(0, 0, 0, 0.1)' : '');
    this.renderer.setStyle(hostElement, 'border-top', this.sticky ? '1px solid #ededed' : '');
  }
}
