import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BasicResponse } from '../../models/response/response';
import { AddMdIFFReferenceNumberOption } from 'src/app/views/salesjourney/md-iff-summary/md-iff-summary.model';

@Injectable({
  providedIn: 'root',
})
export class AddMdService {
  constructor(private http: HttpClient) {}

  mdNoLike(query: string, page: number, size: number) {
    return this.http.post<BasicResponse<AddMdIFFReferenceNumberOption[]>>(`${environment.apiPrefix}/ext/eb-ssms-sales-journey-service/md/mdNoLike`, {
      mdNo: query,
      pageIndex: page,
      pageSize: size,
    });
  }
}
