/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicResponse } from '../../models/response/response';
import { environment } from 'src/environments/environment';
import { HighRiskFileType } from 'src/app/views/high-risk-file-upload-center/high-risk-file-upload-center.model';

@Injectable({
  providedIn: 'root',
})
export class HighRiskFileUploadCenterService {
  constructor(private http: HttpClient) {}

  uploadFile(file: File, fileType: HighRiskFileType) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}/ext/eb-ssms/reporting-service/aml-high-risk-list/upload`, formData, {
      params: { fileType },
    });
  }
}
