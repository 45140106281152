/* eslint-disable @typescript-eslint/no-explicit-any */
import { deepCopy } from 'src/app/utils/copy';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { ColumnSort } from '../../employer/employer';
import { CdsCheckboxConfig } from '@cds/ng-core/checkbox';
import { Router } from '@angular/router';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { Sort } from 'src/app/components/table/table-expand';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { dateValidator } from 'src/app/shared/validators/validators';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { CurrencyE, ErSearchRequest } from '../../employer/employer';
import { EmployerService } from '../../employer/employer.service';
import { DateTime } from 'luxon';
import { EmployeeService } from '../employee.service';
import {
  PolicyTransfer,
  PolicyTransferRequest,
  fundTransactionFormTypeOptions,
  policyTransferStastusCodeMapObj,
  policyTransferStastusCodeOptions,
  transferActivityTypeOptions,
} from '../employee';
import { ColumnConfig, PageConfig, SortMode } from 'src/app/shared/data-table';

@Component({
  selector: 'app-policy-transfer',
  templateUrl: './policy-transfer.component.html',
  styleUrls: ['./policy-transfer.component.scss'],
})
export class PolicyTransferComponent implements OnInit, OnChanges {
  @Input() id = '';

  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;
  public Sort = Sort;
  form: FormGroup = new FormGroup({});
  formReady = false;
  showTable = true;

  showDatePicker = true;

  employerNameValidTip = 'Please input English, Chinese, numbers, or special characters';

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  resetButtonDisabled = true;

  get transferReferenceNo() {
    return this.form?.get('transferReferenceNo');
  }

  get groupReferenceNo() {
    return this.form?.get('groupReferenceNo');
  }

  get statusCode() {
    return this.form?.get('statusCode');
  }

  get activityType() {
    return this.form?.get('activityType');
  }

  get fundTransactionFormType() {
    return this.form?.get('fundTransactionFormType');
  }

  get dateOfTransfer() {
    return this.form?.get('dateOfTransfer');
  }

  get originalRequestedSubmissionDate() {
    return this.form?.get('originalRequestedSubmissionDate');
  }

  tempSeardhParams: ErSearchRequest = {
    page: 1,
    size: 10,
  };

  policyTransferStastusCodeMapObj = policyTransferStastusCodeMapObj;

  constructor(private service: EmployeeService, private router: Router, private toastAlert: ToastAlertService, public employerService: EmployerService) {}

  statusCodeConfig: CdsDropdownConfig = {
    label: 'Status Code',
    placeholder: 'Select Status Code',
    options: policyTransferStastusCodeOptions,
  };

  activityTypeConfig: CdsDropdownConfig = {
    label: 'Activity Type',
    placeholder: 'Select Activity Type',
    options: transferActivityTypeOptions,
  };

  fundTransactionFormTypeConfig: CdsDropdownConfig = {
    label: 'Fund Transaction Form Type',
    placeholder: 'Select Fund Transaction Form Type',
    options: fundTransactionFormTypeOptions,
  };

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  isStaff = false;

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  totalElements = 0;

  dataSource: PolicyTransfer[] = [];

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  columnSort: ColumnSort = {
    key: 'refNo',
    value: SortMode.DOWN,
  };

  columnsConfig: ColumnConfig[] = [
    {
      key: 'transferReferenceNo',
      title: 'Transfer Reference NO.',
      sort: SortMode.DEFAULT,
    },
    {
      key: 'groupReferenceNo',
      title: 'Group Reference No.',
    },
    {
      key: 'statusCode',
      title: 'Status Code',
    },
    {
      key: 'activityType',
      title: 'Activity Type',
    },
    {
      key: 'fundTransactionFormType',
      title: 'Fund Transaction Form Type',
    },
    {
      key: 'dateOfTransfer',
      title: 'Date of Transfer',
    },
    {
      key: 'originalRequestedSubmissionDate',
      title: 'Original Requested Submission Date',
    },
  ];

  isLoading = false;

  noCheckConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: false,
  };

  checkedConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: true,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'].currentValue !== undefined && changes['id'].currentValue !== changes['id'].previousValue) {
      document.documentElement.scrollTop = 0;
      this.isLoading = true;
      this.initForm();
      this.loadTable();
    }
  }

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;
    this.isLoading = true;
    this.initForm();
    this.loadTable();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup({
      transferReferenceNo: new FormControl(''),
      groupReferenceNo: new FormControl(''),
      statusCode: new FormControl(''),
      activityType: new FormControl(''),
      fundTransactionFormType: new FormControl(''),
      dateOfTransfer: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
      originalRequestedSubmissionDate: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
    });
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  search() {
    if (this.searchButtonDisabled) return;
    this.resetButtonDisabled = false;
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.loadTable();
  }

  goToDetail(transferReferenceNo?: string) {
    this.router.navigate(['/employee/policy-transfer-detail'], {
      queryParams: {
        transferReferenceNo,
        id: this.id,
      },
    });
  }

  reset() {
    if (this.resetButtonDisabled) return;
    this.resetButtonDisabled = true;
    this.initForm();
    // reloadTable to reset sort
    this.showTable = false;
    this.showDatePicker = false;
    setTimeout(() => {
      this.showTable = true;
      this.showDatePicker = true;
      this.tempSeardhParams = { page: 1, size: 10 };
      this.loadTable();
    });
  }

  loadTable() {
    this.isLoading = true;

    const params: ErSearchRequest = this.getRequestParams();

    this.service
      .getPolicyTransferList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.totalElements = data.data.totalElements;

          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data.totalElements,
          };
        } else {
          this.toastError(data.message);
        }
      });
  }

  getTempSearchParams(sort = '') {
    const params: PolicyTransferRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this.pageConfig.current as number) - 1,
      size: 10,
    };

    if (sort) {
      params.sort = sort;
    }
    return params;
  }

  getRequestParams() {
    const params: PolicyTransferRequest = {
      ...this.tempSeardhParams,
      page: (this.pageConfig.current as number) - 1,
      size: 10,
      id: this.id,
    };
    params.sort = `${this.columnSort.key},${this.columnSort.value}`;
    params.dateOfTransfer = params.dateOfTransfer ? this.dateTrans(params.dateOfTransfer) : '';
    params.originalRequestedSubmissionDate = params.originalRequestedSubmissionDate ? this.dateTrans(params.originalRequestedSubmissionDate) : '';
    return params;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  amountCUrrency(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currencyControl = control.get('aggregateInvestedAmountCurrency');
      const amountFromControl = control.get('aggregateInvestedAmountFrom');
      const amountToControl = control.get('aggregateInvestedAmountTo');
      const currency = currencyControl?.value;
      const amountFrom = amountFromControl?.value;
      const amountTo = amountToControl?.value;

      const fromErrors = amountFromControl?.errors ? deepCopy(amountFromControl?.errors) : {};
      const toErrors = amountToControl?.errors ? deepCopy(amountToControl?.errors) : {};

      if (currency !== CurrencyE.HKD && amountFrom === '' && amountTo === '') {
        fromErrors['required'] = true;
        toErrors['required'] = true;

        amountFromControl?.markAsTouched();
        amountToControl?.markAsTouched();

        amountFromControl?.setErrors(fromErrors);
        amountToControl?.setErrors(toErrors);

        return null;
      }

      delete fromErrors['required'];
      delete toErrors['required'];
      amountFromControl?.setErrors(Object.keys(fromErrors).length === 0 ? null : fromErrors);
      amountToControl?.setErrors(Object.keys(toErrors).length === 0 ? null : toErrors);

      return null;
    };
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.columnSort =
      event.column && event.column.sort !== SortMode.DEFAULT
        ? {
            key: event.column?.key === 'transferReferenceNo' ? 'refNo' : event.column?.key,
            value: event.column?.sort,
          }
        : {
            key: 'refNo',
            value: SortMode.UP,
          };
    this.loadTable();
  }

  dateTrans(date: string) {
    if (date) {
      return DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat('yyyy-MM-dd');
    }

    return date;
  }

  originalDateRender(date: string) {
    if (!date || !DateTime.fromISO(date).isValid) return '';
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy, hh:mm:ss');
  }
}
