<div class="card-min-height p-3">
  <div class="row" style="align-items: end">
    <div class="col-lg-6 col-md-6 col-sm-3 col-7 pb-1 ps-1">
      <span style="font-weight: 600; font-size: 20px">Chatbot / Live Chat</span>
      <app-cd-table-title [total]="rows.length" [showSubTitle]="true"></app-cd-table-title>
    </div>
    <div class="tabClsss">
      <cds-tab-group [type]="'line-small'" [selectedIndex]="tab" (selectedTabChange)="tabChange($event)">
        <cds-tab [selected]="tab == 0" label="Active">
          <app-data-table [dataSource]="rows" [columnsConfig]="activheaders" [isLoading]="loading">
            <ng-template appDtBodyCellDef="userc" let-element>
              <span class="">
                {{ element.userc }}
              </span>
            </ng-template>
            <ng-template appDtBodyCellDef="agent" let-element>
              <span class="">
                {{ element.agent }}
              </span>
            </ng-template>
          </app-data-table>
        </cds-tab>
        <cds-tab [selected]="tab == 1" label="Pending ">
          <app-data-table [dataSource]="rows" [columnsConfig]="pendingheaders" [isLoading]="loading">
            <ng-template appDtBodyCellDef="userc" let-element>
              <span class="">
                {{ element.userc }}
              </span>
            </ng-template>
            <ng-template appDtBodyCellDef="agent" let-element>
              <span class="">
                {{ element.agent }}
              </span>
            </ng-template>
          </app-data-table>
        </cds-tab>
        <cds-tab [selected]="tab == 2" label="Closed">
          <app-data-table [dataSource]="rows" [columnsConfig]="closeheaders" [isLoading]="loading">
            <ng-template appDtBodyCellDef="userc" let-element>
              <span class="">
                {{ element.userc }}
              </span>
            </ng-template>
            <ng-template appDtBodyCellDef="agent" let-element>
              <span class="">
                {{ element.agent }}
              </span>
            </ng-template>
          </app-data-table>
        </cds-tab>
        <cds-tab [selected]="tab == 3" label="Bot">
          <app-data-table [dataSource]="rows" [columnsConfig]="botheaders" [isLoading]="loading">
            <ng-template appDtBodyCellDef="userc" let-element>
              <span class="">
                {{ element.userc }}
              </span>
            </ng-template>
            <ng-template appDtBodyCellDef="agent" let-element>
              <span class="">
                {{ element.agent }}
              </span>
            </ng-template>
            <ng-template appDtBodyCellDef="lastTopic" let-element>
              {{ element.users[0].lastMessage }}
            </ng-template>
            <ng-template appDtBodyCellDef="msgDate" let-element>
              {{ element.users[0].msgDate | date : 'yyyy/MM/dd HH:mm' }}
            </ng-template>
          </app-data-table>
        </cds-tab>
      </cds-tab-group>
    </div>
  </div>
</div>
