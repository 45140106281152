/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse } from 'src/app/core/models/response/response';
import { AccountTypes, AccountType } from 'src/app/core/models/enum/account-type.enum';
import { str2Obj } from 'src/app/utils/utils';
import Big from 'big.js';

@Injectable({
  providedIn: 'root',
})
export class CommissionRelatedPartiesService {
  accountTypes = new Map();
  constructor(private http: HttpClient) {
    AccountTypes.forEach(item => {
      this.accountTypes.set(item.value, item.label);
    });
  }

  addRelatedParties(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-related-parties/add`, params);
  }

  editRelatedParties(params: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-related-parties/update`, params);
  }

  list(params: any): Observable<any> {
    return this.http.get(
      `${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-related-parties/list`,
      //`http://localhost:8085/commission-pool-related-parties/list`
      {
        params: params,
        observe: 'response',
        responseType: 'text',
      }
    );
  }

  searchRelatedParties(params: any): Observable<BasicResponse<any>> {
    return this.http
      .get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-pool-related-parties/searchRelatedParties`, {
        params: params,
      })
      .pipe(
        map(res => {
          let accounts: any[] = [];
          if (res && res.data && res.data.length > 0) {
            accounts = res.data;
            const filterArrs = [
              AccountType.MPF_EER,
              AccountType.MPF_EEC,
              AccountType.CPF_EE,
              AccountType.ORSO_EE,
              AccountType.MACAU_PPS_EE,
              AccountType.MACAU_CPF_EE,
            ];
            accounts = accounts.filter(item => filterArrs.indexOf(item.accountType) == -1);
            accounts.forEach(item => {
              item.accountTypeStr = this.accountTypes.get(item.accountType);
            });
          }
          const data = {
            accounts: accounts,
            queryPara: params.queryPara,
          };
          res.data = data;
          return res;
        })
      );
  }

  typesValidateAdd(commissionPoolId: string, addType: string, summaryInfo: any): Promise<any> {
    const params = { commissionPoolId: commissionPoolId };
    return new Promise(resolve => {
      const canAddAccountTypes = [AccountType.MPF_ER, AccountType.ORSO_ER];
      // const disableAccountTypes = [AccountType.MPF_SEP,AccountType.MPF_SEP_AGENT,AccountType.MACAU_PPS_ER,AccountType.MACAU_CPF_ER];
      if (summaryInfo.waiveMonthlyBonus && canAddAccountTypes.indexOf(addType as any) == -1) {
        resolve({
          validate: false,
          msg: 'Claw Back / Waive Bonus is Y, Only MPF-ER & ORSO-ER can add',
        });
      } else {
        const errorMsg = 'Only MPF-ER, MPF-SEP, MPF-SEP AGENT, ORSO-ER, MACAU-ER(PPS), MACAU-ER(CPF) are allowed to be grouped in one Pool';
        // const unGroupedAccounts = [AccountType.MPF_TVC, AccountType.MPF_PAP, AccountType.MPF_FRC];
        const groupedAccounts = [
          AccountType.MPF_ER,
          AccountType.MPF_SEP,
          AccountType.MPF_SEP_AGENT,
          AccountType.ORSO_ER,
          AccountType.MACAU_PPS_ER,
          AccountType.MACAU_CPF_ER,
        ];
        if (groupedAccounts.indexOf(addType as any) == -1) {
          resolve({
            validate: false,
            msg: errorMsg,
          });
        }
        this.getRelatedList(params).then(res => {
          let list: any[] = res.list;
          list = list.filter(item => item.active);
          let hasUnGroupedAccount = false;
          let hasMacauPPSAccount = false;
          list.forEach(item => {
            if (groupedAccounts.indexOf(item.accountType) == -1) {
              hasUnGroupedAccount = true;
            }
            if (item.accountType === AccountType.MACAU_PPS_ER) {
              hasMacauPPSAccount = true;
            }
          });
          if (hasMacauPPSAccount && addType !== AccountType.MACAU_PPS_ER) {
            resolve({
              validate: false,
              msg: 'MACAU-ER(PPS) is only allowed to be grouped with same account type',
            });
          }
          if (hasUnGroupedAccount) {
            resolve({
              validate: false,
              msg: errorMsg,
            });
          }
          resolve({
            validate: true,
            isUpdateNCYS: list.length == 0 ? true : false,
          });
        });
      }
    });
  }

  typesValidate(commissionPoolId: string, typeStr: string): Promise<any> {
    const params = { commissionPoolId: commissionPoolId };
    return new Promise(resolve => {
      this.getRelatedList(params).then(res => {
        let list: any[] = res.list;
        list = list.filter(item => item.active);
        list.forEach(item => {
          if (typeStr == 'fcMonthNotEdit') {
            const notAllowEditScenario = [AccountType.MPF_FRC, AccountType.MPF_PAP, AccountType.MACAU_PAP];
            if (notAllowEditScenario.indexOf(item.accountType) !== -1) {
              resolve({
                validate: false,
                msg: 'Not allow to edit “First Contribution month” if the account type is MPF-FRC, MPF-PAP, MACAU-PAP',
              });
            }
          }
          if (typeStr == 'frozen') {
            const notAllowEditScenario = [AccountType.MPF_PAP, AccountType.MACAU_PAP];
            if (notAllowEditScenario.indexOf(item.accountType) !== -1) {
              resolve({
                validate: false,
                msg: 'Account Type: MPF-PAP/Macau-PAP aren’t allowed to tick the Frozen',
              });
            }
          }
          if (typeStr == 'wmBonus') {
            const canAddAccountTypes = [AccountType.MPF_ER, AccountType.ORSO_ER];
            if (canAddAccountTypes.indexOf(item.accountType) == -1) {
              resolve({
                validate: false,
                // msg: 'Claw Back / Waive Bonus is Y, but not MPF-ER / ORSO-ER/ MPF-TVC case in this commission pool.',
                msg: 'Claw Back / Waive Bonus is Y, but not MPF-ER/ORSO-ER case in this commission pool.',
              });
            }
          }
        });
        resolve({ validate: true });
      });
    });
  }

  async getRelatedList(params: any): Promise<any> {
    return new Promise(resolve => {
      this.list(params).subscribe(res => {
        const resp = str2Obj(res.body, ['contAdjPend']);
        let dataSource: any[] = [];
        let lastUpdateTime = '';
        let lastUpdateBy = '';
        if (resp && resp.result == 0 && resp.data && resp.data.content) {
          const content: any[] = resp.data.content;
          const convert = this.convertList(content, null);
          dataSource = convert.list;
          lastUpdateTime = convert.lastUpdateTime;
          lastUpdateBy = convert.lastUpdateBy;
        }
        resolve({
          list: dataSource,
          number: resp?.data?.number,
          totalPages: resp?.data?.totalPages,
          lastUpdateTime: lastUpdateTime,
          lastUpdateBy: lastUpdateBy,
        });
      });
    });
  }

  convertList(content: any[], params: any): any {
    if (!content) {
      return {
        list: [],
        lastUpdateTime: '',
        lastUpdateBy: '',
      };
    }
    const dataSource: any[] = [];
    let lastUpdateTime = '';
    let lastUpdateBy = '';

    content.forEach(item => {
      const i: any = {
        mainParty: '',
        id: item.id,
        accountCode: '',
        customerId: '',
        customerType: '',
        accountType: '',
        accountName: '',
        customerStatus: '',
        servicingAgents: '',
        rejoin: false,
        contAdjPend: '0.0',
        offset: '0.00',
        earlyBenefitStartDate: '',
        earlyBirdBonusSignal: '',
        lastDate: '',
        active: false,
      };
      i.id = item.id;
      i.mainParty = item.mainParty;
      i.accountCode = item.accountCode;
      i.customerId = item.customerId;
      i.customerType = item.customerType;
      i.accountType = item.accountType;
      i.accountTypeStr = this.accountTypes.get(item.accountType);
      i.accountName = item.accountName;
      i.customerStatus = item.customerStatus;
      i.commencementDate = item.commencementDate;
      i.rejoin = item.rejoinIntraGroup;
      i.isRejoinDisable = item.rejoinIntraGroup;
      i.servicingAgents = item.servicingAgents;
      i.earlyBenefitStartDate = item.earlyBenefitStartDate;
      i.earlyBirdBonusSignal = item.earlyBirdBonusSignal;
      i.contAdjPend = item.contAdjPend ? new Big(item.contAdjPend).toFixed(2) : parseFloat('0').toFixed(2);
      i.contAdjPendEdit = i.accountType && i.accountType.indexOf(AccountType.MPF_ER) !== -1 ? true : false;
      i.offset = item.offset ? parseFloat(item.offset).toFixed(2) : '0.00';
      i.active = item.active;
      i.lastDate = !item.active ? item.lastUpdateTime : '';
      i.firstContributionDate = item.firstContributionDate;
      i.firstPcReleaseDate = item.firstPcReleaseDate;
      i.sysAdjustmentDate = item.sysAdjustmentDate;
      i.sysAdjustmentFlag = item.sysAdjustmentFlag;
      if (item.createBy && item.createTime) {
        const t2 = new Date(item.createTime).getTime();
        if (!lastUpdateTime) {
          lastUpdateTime = item.createTime;
          lastUpdateBy = item.createBy.name;
        } else {
          const t = new Date(lastUpdateTime).getTime();
          if (t2 > t) {
            lastUpdateTime = item.createTime;
            lastUpdateBy = item.createBy.name;
          }
        }
      }

      if (item.lastUpdateTime && item.lastUpdateBy) {
        const t2 = new Date(item.lastUpdateTime).getTime();
        if (!lastUpdateTime) {
          lastUpdateTime = item.lastUpdateTime;
          lastUpdateBy = item.lastUpdateBy.name;
        } else {
          const t = new Date(lastUpdateTime).getTime();
          if (t2 > t) {
            lastUpdateTime = item.lastUpdateTime;
            lastUpdateBy = item.lastUpdateBy.name;
          }
        }
      }
      let b = true;
      if (params) {
        if (params.currentEffective && i.lastDate) {
          b = false;
        }
        if (params.accountCode && i.accountCode && i.accountCode.toLowerCase().indexOf(params.accountCode.toLowerCase()) == -1) {
          b = false;
        }
        if (params.accountName && i.accountName && i.accountName.toLowerCase().indexOf(params.accountName.toLowerCase()) == -1) {
          b = false;
        }
        if (params.limit > 0 && dataSource.length >= params.limit) {
          b = false;
        }
        if (b) {
          dataSource.push(i);
        }
      } else {
        dataSource.push(i);
      }

      //             if ('MPF_EER,MPF_EEC,CPF_EE,ORSO_EE'.indexOf(i.accountType) == -1) {
      //
      //             }
    });
    return {
      list: dataSource,
      lastUpdateTime: lastUpdateTime,
      lastUpdateBy: lastUpdateBy,
    };
  }
}
