<div *ngIf="isLoading" class="col-xs-12 l-plr-0">
  <div class="progress">
    <cds-progress mode="indeterminate" type="bar"></cds-progress>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-plr-0 l-mt-8 l-mb-4">
    <span class="cds-body-hero">Termination Information</span>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-d-f l-jc-sb record-history">
    <div class="record-history-padding l-fg-1">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-select
          #mySelect
          appSelectCloseDirective="mySelect"
          [(ngModel)]="terminationReferenceNo"
          class="record-history-select"
          (selectionChange)="onSelectChange($event)">
          <mat-select-trigger class="l-d-f">
            <div class="cds-h5-bold">{{ terminationReferenceNo }}</div>
          </mat-select-trigger>
          <mat-option class="record-history-option" *ngFor="let item of options; let i = index" [value]="item.refNo">
            <div class="l-pt-2 l-pb-2">
              <div class="cds-body1 l-mb-0">{{ item.refNo }}</div>
              <div class="cds-detail2 cds-dark-navy-text">{{ terminationStatusRender | fn : item.statusCd }}</div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div style="position: relative; top: -42px" class="cds-body2 cds-dark-navy-text light-3 l-pl-3">{{ 'termination.no.des' | lang }}&nbsp;&nbsp;</div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 submission-container l-mb-7">
    <div class="row">
      <div class="row l-plr-0">
        <div class="title-icon">
          <cds-icon color="#00A758" width="40px" height="40px" icon="form:document"></cds-icon>
        </div>
        <div class="col-xs">
          <div class="row l-plr-0">
            <div class="col-xs-12">
              <div class="l-mb-1">
                <span class="cds-body2">Termination Reference No.</span>
              </div>
              <div>
                <span class="cds-h4-demibold weight">
                  {{ detailData.refNo }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row padding-l">
      <app-row-item label="Group Reference No." [data]="detailData.grpRefNo" class="col-xs-6 col-sm-4 col-md-4 col-lg-4"> </app-row-item>
      <app-row-item label="Workflow Reference No." class="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <ng-template appRowItemContentDef>
          <span class="click cds-body1 line-break" (click)="jumpToWorkflow(detailData)">{{ detailData.wfRefNo }}</span>
        </ng-template>
      </app-row-item>
      <app-row-item label="Notice of Termination Notified Date" [data]="detailData.notNotifyDate | ssmsDate" class="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      </app-row-item>
      <app-row-item
        label="Original Requested Submission Date and Time"
        [data]="detailData.origRequestSubmitDtm | ssmsDate : 'h:mma dd/MM/yyyy'"
        class="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      </app-row-item>
      <app-row-item label="Termination Reason" [data]="terminationReasonRender | fn : detailData.termReason" class="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      </app-row-item>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <div class="l-mb-1 title-with-checkbox">
      <span class="cds-body1-demibold green-title">LSP/SP</span>
      <cds-checkbox [disabled]="true" [checked]="detailData.lspSpFlg === 'Y'"></cds-checkbox>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 p-line"></div>
</div>

<div class="row">
  <app-row-item label="LSP/SP Type Code" [data]="detailData.lspspTyp" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="EE- LSP/SP Amount Entitled" [data]="detailData.eeLspSpEntitleAmt" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="EE - Amount Payable" [data]="detailData.eePayableAmt" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="ER - LSP/SP Amount Paid" [data]="detailData.erLspSpPaidAmt" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="ER- Request Offset Amount Payable" [data]="detailData.erAcctOffsetAmt" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="Other Means - LSP/SP Offset Amount" [data]="detailData.othLspSpOffsetAmt" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="Other Means - LSP/SP Offset Description" [data]="detailData.othLspSpOffsetDesc" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
  </app-row-item>
  <app-row-item label="ORSO Scheme Offset Amount" [data]="detailData.orsoSchemeOffsetAmt" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
  <app-row-item label="ORSO Scheme Name" [data]="detailData.orsoSchemeNm" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
</div>

<div class="row">
  <div class="col-xs-12">
    <div class="l-mb-1">
      <span class="cds-body1-demibold green-title">Payment Information</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 p-line"></div>
</div>

<div class="row">
  <div class="col-xs-12 to-container">
    <div class="title-area col-xs-12">
      <cds-icon color="#282B3E" width="24px" height="24px" icon="people:login_1"></cds-icon>
      <div class="cds-body1-demibold font-weight-6 l-mb-0 l-ml-2">To EE</div>
    </div>

    <div class="row l-plr-0">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row l-plr-0">
      <app-row-item label="Payment Amount" [data]="detailData.eePayAmt" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Payment Method" [data]="detailData.eePayMthd" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Cheque No." [data]="detailData.eeChqNo" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Cheque Issue Date" [data]="detailData.eeChqIssueDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Payment Cleared Date" [data]="detailData.eePayClearDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item
        *ngIf="terminationStatus === TerminationStatusE.REJECT"
        label="Remark of Rejection Reason"
        [data]="detailData.eePayRejectReason"
        class="col-xs-12">
      </app-row-item>
    </div>

    <div class="row l-pt-5 l-plr-0">
      <div class="col-xs-12 row middle-xs basic-pad">
        <div class="l-mr-2">
          <cds-icon color="#282B3E" width="20px" height="20px" icon="finance:bank"></cds-icon>
        </div>
        <div>
          <span class="cds-h5-demibold">Bank Information</span>
        </div>
      </div>
      <div class="col-xs-12 row bg-white l-pt-2 l-pb-2 padding-lr">
        <app-row-item label="Payee Name Offset" [data]="detailData.eePayeeNm" class="col-xs-12"> </app-row-item>
        <app-row-item label="Bank Code" [data]="detailData.eeBnkCd" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="Branch Code of Bank" [data]="detailData.eeBranchCd" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="Bank Account No." [data]="detailData.erBnkAcctNo" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="Bank Name" [data]="detailData.eeBnkNm" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 to-container">
    <div class="title-area col-xs-12">
      <cds-icon color="#282B3E" width="24px" height="24px" icon="environment:office_building"></cds-icon>
      <div class="cds-body1-demibold font-weight-6 l-mb-0 l-ml-2">To ER</div>
    </div>

    <div class="row l-plr-0">
      <div class="col-xs-12 p-line"></div>
    </div>

    <div class="row l-plr-0">
      <app-row-item label="Payment Amount" [data]="detailData.erPayAmt" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Payment Method" [data]="detailData.erPayMthd" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Cheque No." [data]="detailData.erChqNo" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Cheque Issue Date" [data]="detailData.erChqIssueDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Payment Cleared Date" [data]="detailData.erPayClearDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="Remark of Rejection Reason" [data]="detailData.erPayRejectReason" class="col-xs-12"> </app-row-item>
    </div>

    <div class="row l-pt-5 l-plr-0">
      <div class="col-xs-12 row middle-xs basic-pad">
        <div class="l-mr-2">
          <cds-icon color="#282B3E" width="20px" height="20px" icon="finance:bank"></cds-icon>
        </div>
        <div>
          <span class="cds-h5-demibold">Bank Information</span>
        </div>
      </div>
      <div class="col-xs-12 row bg-white l-pt-2 l-pb-2 padding-lr">
        <app-row-item label="Payee Name Offset" [data]="detailData.erPayeeNm" class="col-xs-12"> </app-row-item>
        <app-row-item label="Bank Code" [data]="detailData.erBnkCd" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="Branch Code of Bank" [data]="detailData.erBranchCd" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="Bank Account No." [data]="detailData.erBnkAcctNo" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
        <app-row-item label="Bank Name" [data]="detailData.erBnkNm" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      </div>
    </div>
  </div>
</div>
