<div class="row">
  <div class="col-xs-12 l-pt-5">
    <span class="cds-h2-light">{{ 'list.search-record' | lang }}</span>
  </div>
</div>
<div class="row" *ngIf="form" [formGroup]="form">
  <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 row l-pt-4 l-plr-0">
    <cds-textfield
      class="col-xs"
      formControlName="chequePrintDateStart"
      placeholder="DD/MM/YYYY"
      label="Cheque Issue Date From"
      [max]="getTime | fn : chequePrintDateEnd"
      [cdsDatepicker]="picker">
    </cds-textfield>
    <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>

    <span class="cds-color-navy-light4 l-d-f center-xs bottom-xs l-pb-4">-</span>
    <cds-textfield
      class="col-xs"
      formControlName="chequePrintDateEnd"
      placeholder="DD/MM/YYYY"
      label="To"
      [min]="getTime | fn : chequePrintDateStart"
      [cdsDatepicker]="picker2">
    </cds-textfield>
    <cds-datepicker #picker2="cdsDatepicker"></cds-datepicker>
  </div> -->

  <div class="row col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
    <div class="col-xs-6 date-picker">
      <cds-textfield
        formControlName="chequeIssueDateFrom"
        placeholder="DD/MM/YYYY"
        label="Cheque Issue Date From"
        [max]="maxDate(searchDTO.chequeIssueDateTo)"
        [(ngModel)]="searchDTO.chequeIssueDateFrom"
        (ngModelChange)="validating()"
        [cdsDatepicker]="startPicker">
      </cds-textfield>
      <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

      <cds-assistive-text class="l-pt-3" *ngIf="form.get('chequeIssueDateFrom')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
    </div>
    <div class="connector">-</div>
    <div class="col-xs-6 date-picker">
      <cds-textfield
        formControlName="chequeIssueDateTo"
        placeholder="DD/MM/YYYY"
        label="To"
        [min]="minDate(searchDTO.chequeIssueDateFrom)"
        [(ngModel)]="searchDTO.chequeIssueDateTo"
        (ngModelChange)="validating()"
        [cdsDatepicker]="endPicker">
      </cds-textfield>
      <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>
      <cds-assistive-text class="l-pt-3" *ngIf="form.get('chequeIssueDateTo')?.invalid">{{ 'create.dateError' | lang }} </cds-assistive-text>
    </div>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="outChequeNo"
      [(ngModel)]="searchDTO.outChequeNo"
      (input)="inputChange(outChequeNo.value, 'outChequeNo')"
      (compositionend)="inputChange(outChequeNo.value, 'outChequeNo')"
      #outChequeNo
      label="{{ 'list.cheque-number' | lang }}"
      placeholder="{{ 'list.input-cheque-number' | lang }}"></cds-textfield>
  </div>
  <cds-button
    class="l-pr-2 l-pl-2 l-pt-2"
    [disabled]="!form.valid || !_valid"
    [config]="searchButtonConfig"
    label="{{ 'list.search' | lang }}"
    (click)="searchFn()"></cds-button>
  <cds-button class="l-pr-2 l-pl-2 l-pt-2" [disabled]="!_valid" [config]="resetButtonConfig" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
</div>

<div class="row l-pt-7 table-title">
  <div class="record-area">
    <div class="l-mr-6 l-mb-2">
      <div class="col-xs-12 l-pb-2">
        <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
      </div>
      <div class="col-xs-12">
        <span class="cds-body1">{{ 'page.total' | lang }} {{ _edited ? checkedDatasource.length : _totalCount }} {{ 'page.records' | lang }}</span>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="!_edited">
  <div class="col-xs-12 row between-xs bottom-xs l-pb-2">
    <div class="col-xs row l-plr-0 middle-xs">
      <div class="cds-color-navy-light3">
        <span>{{ 'cheque.list.number-of-records-shown' | lang }}</span>
      </div>
      <div class="row col-xs-6 col-md">
        <div
          class="page-size l-mr-2 l-ml-2 pointer"
          *ngFor="let size of pageSelectSize; let i = index"
          [ngClass]="_pageSelected === i ? 'size-selected' : ''"
          (click)="sizeSelect(i)">
          <span class="cds-body2">{{ size }}</span>
        </div>
      </div>
    </div>

    <div class="l-pr-4">
      <cds-button [disabled]="exportSelectedDisabled" (click)="exportSelected()" [config]="exportSelectedButtonConfig">Export Selected</cds-button>
    </div>
    <div>
      <div class="col-xs-12 end-xs">
        <div><span class="cds-body-1">Total amount of transaction:</span></div>
        <div class="color-green l-pt-2">
          <span class="cds-body-hero-demibold">HKD {{ transactionAmount | number : '1.2-2' }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 row">
    <div class="cds-color-navy-light3 l-pt-4">
      <span>{{ 'cheque.list.status' | lang }}</span>
    </div>
    <div class="row col-xs">
      <div class="row col-xs-12 l-plr-0">
        <div
          class="page-size l-ma-2 pointer"
          *ngFor="let item of _paymentStatusList; let i = index"
          [ngClass]="item.selected ? 'size-selected' : ''"
          (click)="paymentStatusSelecte(i)">
          <span class="cds-body2">{{ item.label }}</span>
        </div>
      </div>
      <div (click)="resetStatus()" class="row col-xs-12 middle-xs pointer l-pt-2 l-pb-2">
        <cds-icon class="l-pr-2" color="#00a758" icon="arrow:update_recycle"></cds-icon>
        <span class="cds-h6-demibold l-mb-0">Reset Status Filters</span>
      </div>
    </div>
  </div>
</div>

<div class="row l-pt-4" *ngIf="!_edited">
  <app-loading *ngIf="_progress" [overlayInPopup]="true"></app-loading>
  <div class="col-xs-12">
    <div #tableBox class="table-x">
      <cds-table [dataSource]="dataSource" cdsSort (cdsSortChange)="onSortChange($event)" [class.full]="true">
        <ng-container cdsColumnDef="selected" sticky>
          <cds-header-cell *cdsHeaderCellDef>
            <cds-checkbox [checked]="_selectedAll" [indeterminate]="_checkedDatasourceMap.size > 0" (change)="triggerAll()"></cds-checkbox>
          </cds-header-cell>
          <cds-cell class="none-width" *cdsCellDef="let element; index as i">
            <cds-checkbox (change)="trigger(i)" [checked]="element.checked"></cds-checkbox>
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="outChequeNo" sticky>
          <cds-header-cell *cdsHeaderCellDef cds-sort-header> Cheque No. </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            <ng-container>
              <div>{{ element.outChequeNo }}</div>
            </ng-container>
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="paymentStatus" sticky>
          <cds-header-cell *cdsHeaderCellDef> Payment Status </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
            <div class="status">
              <div class="status-icon" [ngStyle]="{ background: getColor(element.recordStatus) }"></div>
              <div class="l-pr-2"></div>
              <div>{{ getPaymentStatus | fn : element.recordStatus }}</div>
            </div>
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="amt" sticky>
          <cds-header-cell *cdsHeaderCellDef cds-sort-header>Amount</cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
            <div>{{ element.curr }} {{ element.amt | number : '1.2-2' | mynumber }}</div>
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="payeeName" sticky>
          <cds-header-cell cds-sort-header [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''" *cdsHeaderCellDef> Payee Name </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', checkIsHaveScroll ? 'shadow-right' : '']" *cdsCellDef="let element">
            <div>{{ element.payeeName }}</div>
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="feedReceiveDate">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Payment Instruction File Date </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
            {{ element.feedReceiveDate | ssmsDate }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="isReissue">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Reissuance </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
            {{ element.isReissue }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="reissueReason">
          <cds-header-cell *cdsHeaderCellDef> Reissurance Reason </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', 'break']" *cdsCellDef="let element">
            {{ formatReason(element.reissueReason) }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="payRefNo">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Payment Ref No. </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
            {{ element.payRefNo }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="appRefNo">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Application Reference No. </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
            <span class="green-line pointer">{{ element.appRefNo }}</span>
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="exptPayIssue">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Expected Payment Issue Date on Statement </cds-header-cell>
          <cds-cell [ngClass]="element.checked ? 'bg-green-light-4' : ''" *cdsCellDef="let element">
            {{ formatDate2(element.exptPayIssue) }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="chequeIssueDate">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Cheque Issue Date </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
            {{ element.chequeIssueDate | ssmsDate }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="lastUpdateDate">
          <cds-header-cell cds-sort-header *cdsHeaderCellDef> Status Update Date </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '']" *cdsCellDef="let element">
            {{ element.lastUpdateDate | ssmsDate }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="remarks">
          <cds-header-cell *cdsHeaderCellDef> Remarks </cds-header-cell>
          <cds-cell [ngClass]="[element.checked ? 'bg-green-light-4' : '', 'break']" *cdsCellDef="let element">
            {{ element.remarks }}
          </cds-cell>
        </ng-container>

        <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
        <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
      </cds-table>
    </div>
  </div>

  <div *ngIf="!_progress && (!dataSource || dataSource.length === 0)" class="col-xs-12 l-pt-7 l-pb-7">
    <app-no-result-found></app-no-result-found>
  </div>

  <div *ngIf="dataSource.length" class="col-xs-12 l-pt-4 l-pb-4 l-d-f l-jc-sb">
    <app-pagination [pageCount]="pageCount" [isLoading]="_progress" [useOf]="true" (pageChange)="loadTable()"> </app-pagination>
  </div>
</div>

<div class="row l-pt-4" *ngIf="_edited">
  <app-loading *ngIf="_progress" [overlayInPopup]="true"></app-loading>
  <div class="col-xs-12">
    <div #tableBox class="table-x">
      <cds-table [dataSource]="checkedDatasource" [class.full]="true">
        <ng-container cdsColumnDef="outChequeNo" sticky>
          <cds-header-cell *cdsHeaderCellDef> Cheque No. </cds-header-cell>
          <cds-cell *cdsCellDef="let element; let index = index">
            <div class="cheque-width">
              <ng-container *ngIf="!_confirmed && element.canInputChequeNo; else noInput">
                <cds-form-field>
                  <cds-textfield type="number" [(ngModel)]="element.outChequeNo" (input)="chqInput(element, $event)" placeholder=""></cds-textfield>
                  <cds-assistive-text *ngIf="element.cheqNoError">{{ element.cheqNoError }}</cds-assistive-text>
                </cds-form-field>
                <ng-container *ngIf="!element.cheqNoError">
                  <div *ngIf="index === 0" class="prefill-area l-pt-2 cds-color-green pointer" (click)="prefill(element.outChequeNo)">
                    <cds-icon color="#00A758" icon="action:gear" width="14px" height="14px" class="l-mr-1"></cds-icon>
                    <span>Prefill Remaining No.</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="element.cheqNoError">
                  <div *ngIf="index === 0" class="prefill-area l-pt-2 cds-color-grey">
                    <cds-icon color="#8e90a2" icon="action:gear" width="14px" height="14px" class="l-mr-1"></cds-icon>
                    <span>Prefill Remaining No.</span>
                  </div>
                </ng-container>
              </ng-container>
              <ng-template #noInput>
                <div>{{ element.outChequeNo }}</div>
              </ng-template>
            </div>
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="paymentStatus" sticky>
          <cds-header-cell *cdsHeaderCellDef> Payment Status </cds-header-cell>
          <cds-cell class="min-150" *cdsCellDef="let element; let index = index">
            <ng-container *ngIf="!_confirmed">
              <ng-container *ngIf="element.downConfig">
                <div [ngClass]="['l-pl-2', index === 0 ? 'edit-dropdown-first' : 'edit-dropdown']">
                  <cds-dropdown [(ngModel)]="element.recordStatus" [config]="element.downConfig"></cds-dropdown>
                </div>
              </ng-container>
              <ng-container *ngIf="!element.downConfig">
                <div class="status">
                  <div class="status-icon" [ngStyle]="{ background: getColor(element.recordStatus) }"></div>
                  <div class="l-pr-2"></div>
                  <div>{{ element.recordStatus }}</div>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="_confirmed">
              <div>{{ element.recordStatus }}</div>
            </ng-container>
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="amt" sticky>
          <cds-header-cell *cdsHeaderCellDef>Amount</cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            <div>HKD {{ element.amt }}</div>
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="payeeName" sticky>
          <cds-header-cell [ngClass]="checkIsHaveScroll ? 'shadow-right' : ''" *cdsHeaderCellDef> Payee Name </cds-header-cell>
          <cds-cell [ngClass]="[checkIsHaveScroll ? 'shadow-right' : '']" *cdsCellDef="let element">
            <div>{{ element.payeeName }}</div>
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="feedReceiveDate">
          <cds-header-cell *cdsHeaderCellDef> Payment Instruction File Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            {{ element.feedReceiveDate | ssmsDate }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="isReissue">
          <cds-header-cell *cdsHeaderCellDef> Reissuance </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            {{ element.isReissue }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="reissueReason">
          <cds-header-cell *cdsHeaderCellDef> Reissurance Reason </cds-header-cell>
          <cds-cell class="break" *cdsCellDef="let element">
            {{ formatReason(element.reissueReason) }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="payRefNo">
          <cds-header-cell *cdsHeaderCellDef> Payment Ref No. </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            {{ element.payRefNo }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="appRefNo">
          <cds-header-cell *cdsHeaderCellDef> Application Reference No. </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            {{ element.appRefNo }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="exptPayIssue">
          <cds-header-cell *cdsHeaderCellDef> Expected Payment Issue Date on Statement </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            {{ formatDate2(element.exptPayIssue) }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="chequeIssueDate">
          <cds-header-cell *cdsHeaderCellDef> Cheque Issue Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            {{ element.chequeIssueDate | ssmsDate }}
          </cds-cell>
        </ng-container>

        <ng-container cdsColumnDef="lastUpdateDate">
          <cds-header-cell *cdsHeaderCellDef> Status Update Date </cds-header-cell>
          <cds-cell *cdsCellDef="let element">
            {{ element.lastUpdateDate | ssmsDate }}
          </cds-cell>
        </ng-container>
        <ng-container cdsColumnDef="remarks">
          <cds-header-cell *cdsHeaderCellDef> Remarks </cds-header-cell>
          <cds-cell class="break" *cdsCellDef="let element">
            <ng-container *ngIf="!_confirmed">
              <cds-textfield [(ngModel)]="element.remarks" placeholder=""></cds-textfield>
            </ng-container>
            <ng-container *ngIf="_confirmed">
              {{ element.remarks }}
            </ng-container>
          </cds-cell>
        </ng-container>

        <cds-header-row *cdsHeaderRowDef="checkedDisplayedColumns"></cds-header-row>
        <cds-row *cdsRowDef="let row; columns: checkedDisplayedColumns"></cds-row>
      </cds-table>
    </div>
  </div>

  <div *ngIf="!_confirmed" class="row">
    <div class="col-xs-12 row">
      <div class="search-padding l-pb-2 l-pt-5">
        <cds-button
          [fullWidth]="true"
          [config]="resetEditConfig"
          label="{{ 'list.reset' | lang }}"
          [disabled]="_resetEditDisable"
          (click)="editReset()"></cds-button>
      </div>
      <div class="search-padding l-pb-2 l-pt-5">
        <cds-button [fullWidth]="true" [config]="saveEditConfig" [disabled]="_saveDisable" label="{{ 'list.save' | lang }}" (click)="toConfirm()"></cds-button>
      </div>
    </div>
  </div>

  <div *ngIf="_confirmed" class="row">
    <div class="col-xs-12 row">
      <div class="search-padding l-pb-2 l-pt-5">
        <cds-button [fullWidth]="true" [config]="resetEditConfig" label="{{ 'list.back-to-edit' | lang }}" (click)="backListClick()"></cds-button>
      </div>
      <div class="search-padding l-pb-2 l-pt-5">
        <cds-button
          [fullWidth]="true"
          [config]="saveEditConfig"
          label="{{ 'list.confirm' | lang }}"
          [disabled]="_saveDisable"
          (click)="confirmClick()"></cds-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="_toolBar && _checkedDatasourceMap.size > 0" class="operation-bar" [style.right]="scrollBarWidth" [style.left]="isMobile ? 0 : '80px'">
  <div class="cds-h4 l-mb-0" *ngIf="!isMobile">Please select record(s) to</div>
  <div class="button-area">
    <div class="cds-h5 l-mb-0 font-weight-6 l-mr-6">
      <span>{{ _checkedDatasourceMap.size }}</span> item(s) selected
    </div>
    <ng-container *ngIf="!_edited">
      <cds-button (click)="editTrigger()" [disabled]="editSelectedDisabled" [config]="editSelectedButtonConfig">Edit Selected</cds-button>
      <div class="l-pr-5"></div>
      <cds-button
        [disabled]="exportMailMerge"
        [config]="exportAllButtonConfig"
        label="{{ 'cheque.list.export-for-mail-merge' | lang }}"
        (click)="exportPdf()"></cds-button>
    </ng-container>
    <ng-container *ngIf="_edited">
      <cds-button (click)="editTrigger(true)" [disabled]="editSelectedDisabled" [config]="editSelectedButtonConfig">Cancel Edit</cds-button>
    </ng-container>
  </div>
</div>

<app-cd-download-progress [show]="_downloading"></app-cd-download-progress>
