<div class="card-min-height p-3">
  <div class="row" style="align-items: end">
    <div class="col-lg-6 col-md-6 col-sm-3 col-7 pb-1 ps-1">
      <span style="font-weight: 600; font-size: 20px">Supervisor Dashbord</span>
      <app-cd-table-title [total]="rows.length" [showSubTitle]="true"></app-cd-table-title>
    </div>
    <app-data-table [dataSource]="rows" [columnsConfig]="headers">
      <ng-template appDtBodyCellDef="timeEngaged" let-element>
        {{ element.activeChats == 0 && element.completedChats == 0 ? 0 : element.timeEngaged }}
      </ng-template>
      <ng-template appDtBodyCellDef="alertKeyword" let-element>
        <span class="" [innerHTML]="element.alertKeyword"> </span>
      </ng-template>
      <ng-template appDtBodyCellDef="lastMessageTime" let-element>
        {{ element.lastMessageTime ? (element.lastMessageTime | date : 'dd/MM/yyyy HH:mm') : '-' }}
      </ng-template>
    </app-data-table>
  </div>
</div>
