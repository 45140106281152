<div class="l-pa-7">
  <div class="l-mt-3 submit-success">
    <cds-icon class="button-checkmark" icon="action:check"></cds-icon>
  </div>
  <div class="l-mt-5">
    <span class="cds-h4-demibold">
      {{ 'generate.md.has-been-sent-customer' | lang }}
    </span>
  </div>
  <div class="l-mt-3">
    <cds-assistive-text type="error">{{ 'sendIffInBatchSummary.noChanges' | lang }}</cds-assistive-text>
  </div>
  <div class="l-mt-5">
    <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">{{ 'generate.md.md-reference-number' | lang }}</span>
  </div>
  <div class="l-mt-1">
    <span class="cds-body1-demibold">{{ data.mdNo }}</span>
  </div>
  <div style="margin-top: 56px">
    <cds-button [config]="buttonConfig" (click)="gotIt()" label="{{ 'iff.gotIt' | lang }}"></cds-button>
  </div>
</div>
