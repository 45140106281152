/* eslint-disable @typescript-eslint/no-explicit-any */

export enum AccountType {
  MPF_ER = 'MPF_ER',
  MPF_EER = 'MPF_EER',
  MPF_EEC = 'MPF_EEC',
  MPF_SEP = 'MPF_SEP',
  MPF_SEP_AGENT = 'MPF_SEP_AGENT',
  MPF_TVC = 'MPF_TVC',
  MPF_PAP = 'MPF_PAP',
  MPF_FRC = 'MPF_FRC',
  ORSO_ER = 'ORSO_ER',
  ORSO_EE = 'ORSO_EE',
  // MACAU_PPS = 'MACAU_PPS',
  MACAU_PPS_ER = 'MACAU_PPS_ER',
  MACAU_PPS_EE = 'MACAU_PPS_EE',
  CPF_EE = 'CPF_EE',
  // MACAU_CPF = 'MACAU_CPF',
  MACAU_CPF_ER = 'MACAU_CPF_ER',
  MACAU_CPF_EE = 'MACAU_CPF_EE',
  MACAU_PAP = 'MACAU_PAP',
}

export const AccountTypes: any[] = [
  { label: 'MPF-ER', value: AccountType.MPF_ER },
  { label: 'MPF-EE(R)', value: AccountType.MPF_EER },
  { label: 'MPF-EE(C)', value: AccountType.MPF_EEC },
  { label: 'MPF-SEP', value: AccountType.MPF_SEP },
  { label: 'MPF-SEP(AGENT)', value: AccountType.MPF_SEP_AGENT },
  { label: 'MPF-TVC', value: AccountType.MPF_TVC },
  { label: 'MPF-PAP', value: AccountType.MPF_PAP },
  { label: 'MPF-FRC', value: AccountType.MPF_FRC },
  { label: 'ORSO-ER', value: AccountType.ORSO_ER },
  { label: 'ORSO-EE', value: AccountType.ORSO_EE },
  { label: 'MACAU-ER(PPS)', value: AccountType.MACAU_PPS_ER },
  { label: 'MACAU-EE(PPS)', value: AccountType.MACAU_PPS_EE },
  { label: 'MACAU-ER(CPF)', value: AccountType.MACAU_CPF_ER },
  { label: 'MACAU-EE(CPF)', value: AccountType.MACAU_CPF_EE },
  { label: 'MACAU-PAP', value: AccountType.MACAU_PAP },
  { label: 'Macau-EE', value: AccountType.CPF_EE },
];

export const AccountTypesCommissionpool = [
  { label: 'MPF-ER', value: AccountType.MPF_ER },
  { label: 'ORSO-ER', value: AccountType.ORSO_ER },
  { label: 'MACAU-ER(PPS)', value: AccountType.MACAU_PPS_ER },
  { label: 'MPF-SEP', value: AccountType.MPF_SEP },
  { label: 'MPF-SEP(AGENT)', value: AccountType.MPF_SEP_AGENT },
];

export const AccountTypesNormalCompensation = [
  { label: 'MPF-ER', value: AccountType.MPF_ER },
  { label: 'MPF-SEP', value: AccountType.MPF_SEP },
  { label: 'MPF-SEP (AGENT)', value: AccountType.MPF_SEP_AGENT },
  { label: 'MPF-FRC', value: AccountType.MPF_FRC },
  { label: 'MPF-TVC', value: AccountType.MPF_TVC },
  { label: 'ORSO-ER', value: AccountType.ORSO_ER },
  { label: 'MACAU-ER(PPS)', value: AccountType.MACAU_PPS_ER },
  { label: 'MACAU-ER(CPF)', value: AccountType.MACAU_CPF_ER },
];

export const AccountTypesIndividual = [
  { label: 'MPF-ER', value: AccountType.MPF_ER },
  { label: 'MPF-SEP', value: AccountType.MPF_SEP },
  { label: 'MPF-SEP (AGENT)', value: AccountType.MPF_SEP_AGENT },
  { label: 'MPF-TVC', value: AccountType.MPF_TVC },
  { label: 'MPF-PAP', value: AccountType.MPF_PAP },
  { label: 'MACAU-PAP', value: AccountType.MACAU_PAP },
];

export const AccountTypesJournal: any[] = [
  { label: 'MPF-ER', value: AccountType.MPF_ER },
  { label: 'MPF-SEP', value: AccountType.MPF_SEP },
  { label: 'MPF-SEP(AGENT)', value: AccountType.MPF_SEP_AGENT },
  { label: 'MPF-FRC', value: AccountType.MPF_FRC },
  { label: 'MPF-TVC', value: AccountType.MPF_TVC },
  { label: 'MPF-PAP', value: AccountType.MPF_PAP },
  { label: 'ORSO-ER', value: AccountType.ORSO_ER },
  { label: 'MACAU-ER(PPS)', value: AccountType.MACAU_PPS_ER },
  { label: 'MACAU-ER(CPF)', value: AccountType.MACAU_CPF_ER },
  { label: 'MACAU-PAP', value: AccountType.MACAU_PAP },
];

export const AccountTypesHoldOnReport: any[] = [
  { label: 'All', value: 'all' },
  { label: 'MPF-ER', value: AccountType.MPF_ER },
  { label: 'MPF-SEP', value: AccountType.MPF_SEP },
  { label: 'MPF-SEP(AGENT)', value: AccountType.MPF_SEP_AGENT },
  { label: 'MPF-TVC', value: AccountType.MPF_TVC },
  { label: 'MPF-PAP', value: AccountType.MPF_PAP },
  { label: 'MPF-FRC', value: AccountType.MPF_FRC },
  { label: 'ORSO-ER', value: AccountType.ORSO_ER },
  { label: 'MACAU-ER(PPS)', value: AccountType.MACAU_PPS_ER },
  { label: 'MACAU-ER(CPF)', value: AccountType.MACAU_CPF_ER },
  { label: 'MACAU-PAP', value: AccountType.MACAU_PAP },
];

export const MPFAccountTypes = [
  {
    label: 'MPF-ER',
    value: AccountType.MPF_ER,
  },
  {
    label: 'MPF-SEP',
    value: AccountType.MPF_SEP,
  },
  {
    label: 'MPF-SEP AGENT',
    value: AccountType.MPF_SEP_AGENT,
  },
  {
    label: 'MPF-TVC',
    value: AccountType.MPF_TVC,
  },
  {
    label: 'MPF-PAP',
    value: AccountType.MPF_PAP,
  },
  {
    label: 'MPF-FRC',
    value: AccountType.MPF_FRC,
  },
];

export const MacauAccountTypes = [
  {
    label: 'ORSO-ER',
    value: AccountType.ORSO_ER,
  },
  {
    label: 'MACAU-ER(PPS)',
    value: AccountType.MACAU_PPS_ER,
  },
  {
    label: 'MACAU-ER(CPF)',
    value: AccountType.MACAU_CPF_ER,
  },
  {
    label: 'MACAU-PAP',
    value: AccountType.MACAU_PAP,
  },
];
