export enum HttpStatus {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  SESSION_TIMEOUT = 401,
  UNAUTHORIZED = 403,
  NOT_FOUND = 404,
  SYSTEM_EDITABLE_LOCKED = 423,
  INTERNAL_SERVICE_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  BAD_GATEWAY_TIMEOUT = 504,
}
