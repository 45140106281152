/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

export const clientPhoneNumberVali = (countryCode: FormControl) => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    if (typeof control.value === 'string') {
      if (['852', '853'].includes(countryCode.value)) {
        if (control.value.length === 8 && ['4', '5', '6', '7', '8', '9'].includes(control.value.substring(0, 1))) {
          return null;
        }
      }

      if (['86'].includes(countryCode.value)) {
        if (control.value.length === 11) {
          const e = /^1[345678]\d{9}$/.test(control.value);
          if (e) {
            return null;
          }
        }
      }

      if (!['852', '853', '86'].includes(countryCode.value)) {
        return null;
      }
    }

    return { errorPhoneNumber: true };
  };
};

export const atLeastOneQueryConditionValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    let paramsNum = 0;
    for (const key in control.value) {
      if (Object.prototype.hasOwnProperty.call(control.value, key)) {
        const val = (control.value as any)[key];
        if (val !== undefined && val !== null && val !== '' && !(Object.prototype.toString.call(val) === '[object Array]' && val.length === 0)) {
          paramsNum++;
        }
      }
    }

    return paramsNum === 0 ? { atLeastOneQueryCondition: true } : null;
  };
};
