import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

export enum Domain {
  individual = 'individual',
  employer = 'employer',
  sep = 'sep',
}

@Component({
  selector: 'app-select-product-type-for-md',
  templateUrl: './select-product-type-for-md.component.html',
  styleUrls: ['./select-product-type-for-md.component.scss'],
})
export class SelectProductTypeForMdComponent implements OnInit, OnDestroy {
  Domain = Domain;
  domain = Domain.individual;
  @ViewChild('displayContent') displayContent!: ElementRef<HTMLDivElement>;
  currentStep = 1;
  isScrollBottom = false;

  onScoll = () => {
    const scrollHeight = this.displayContent.nativeElement.scrollHeight;
    const clientHeight = this.displayContent.nativeElement.clientHeight;

    if (!this.isScrollBottom && this.displayContent.nativeElement.scrollTop > scrollHeight - clientHeight - 10) {
      this.isScrollBottom = true;
    }
  };

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.displayContent?.nativeElement.removeEventListener('scroll', this.onScoll);
  }

  toEdit() {
    if (!this.isScrollBottom) {
      return;
    }
    if (this.domain === Domain.individual) {
      this.router.navigate(['/salesjourney/generate-md/individual', { domain: Domain.individual }]);
    } else if (this.domain === Domain.sep) {
      this.router.navigate(['/salesjourney/generate-md/individual', { domain: Domain.sep }]);
    } else {
      this.router.navigate(['/salesjourney/generate-md/employer']);
    }
  }

  showDeclaration(domain: Domain) {
    this.domain = domain;
    this.currentStep = 2;
    setTimeout(() => {
      this.displayContent?.nativeElement.addEventListener('scroll', this.onScoll);
    }, 0);
  }
}
