import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupervisorRoutingModule } from './supervisor-routing.module';
import { SupervisorComponent } from './supervisor.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChatHistoryComponent } from '../main/chat-history/chat-history.component';
import { ReportsComponent } from '../main/reports/reports.component';
import { SupervisorDashboardComponent } from './supervisor-dashboard/supervisor-dashboard.component';
import { CdsNavigationModule } from '@cds/ng-web-components/navigation';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { ChatListComponent } from './chat-list/chat-list.component';

@NgModule({
  declarations: [SupervisorComponent, ChatHistoryComponent, ReportsComponent, SupervisorDashboardComponent, ChatListComponent],
  imports: [CommonModule, SharedModule, SupervisorRoutingModule, CdsNavigationModule, CdsLanguageModule],
})
export class SupervisorModule {}
