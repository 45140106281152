/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicResponse, IPageResponse } from 'src/app/core/models/response/response';
import { aoaToSheet, FileType } from 'src/app/utils/xlsx';
import { environment } from 'src/environments/environment';
import { EXCEL_COLUMNS } from './batch-upload-adjustment.model';

@Injectable({
  providedIn: 'root',
})
export class BatchUploadAdjustmentService {
  constructor(private http: HttpClient) {}

  getList(params: any): Observable<BasicResponse<IPageResponse<any>>> {
    return this.http.get<BasicResponse<IPageResponse<any>>>(`${environment.apiPrefix}${environment.commissionServiceExt}${'/commission-adjust-batch/list'}`, {
      params: params,
    });
  }

  detail(id: string): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-adjust-batch/list-items/${id}`);
  }

  delete(id: string): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-adjust-batch/delete/${id}`, null);
  }

  validate(id: string): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}/commission-adjust-batch/validate/${id}`, null);
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<BasicResponse<any>>(`${environment.apiPrefix}${environment.commissionServiceExt}${'/commission-adjust-batch/upload'}`, formData);
  }

  downLoadExcel(data: Array<any>, fileName: string, isDetail?: boolean) {
    const excelData: any[] = [];
    let arr = [...EXCEL_COLUMNS];
    if (!isDetail) {
      arr = arr.slice(0, -1);
    }
    excelData.push(arr);
    data.forEach(item => {
      const temp = [];
      temp.push(item.adjustType);
      temp.push(item.accountType);
      temp.push(item.accountCode);
      temp.push(item.memberCode);
      temp.push(item.agentCode);
      temp.push(item.negativeFlag);
      temp.push(item.hkpcAmount);
      temp.push(item.commAmount);
      temp.push(item.pcCommPremium);
      temp.push(item.sfOtherAmount);
      temp.push(item.sfOtherPremium);
      temp.push(item.caseCount);
      if (isDetail) {
        temp.push(item.resultMsg);
      }
      excelData.push(temp);
    });
    aoaToSheet(
      {
        fileName: fileName.split('.')[0],
        fileType: FileType.XLSX,
      },
      excelData
    );
  }
}
