import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/services/notify.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdPopupService, CdPopupType, MatDialogRef } from 'src/app/shared/cd-popup';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { PreferredLanguageType } from '../../case-summary/case-summary.model';
import { empfCountryOption } from '../../case-summary/declaration-iff-er/empf-declaration-form.config';
import { ResetWarningComponent } from '../../iff-paper-form/reset-warning/reset-warning.component';
import { HasBeenSentCustomerPopupComponent } from '../has-been-sent-customer-popup/has-been-sent-customer-popup.component';
import { PendingSplitAgentConfirPopupComponent } from '../pending-split-agent-confir-popup/pending-split-agent-confir-popup.component';

enum Show {
  employer = 'employer',
  intermediary = 'intermediary',
}

interface AgentInfo {
  agentId: string;
  agentCode: string;
  agentName: string;
  agentNameTC: string;
  mobileNumber: string;
  emailAddress: string;
  branchCode: string;
  agentCategory: string;
  licenseNumber: string;
  licenseCode: string;
  licenseStatus: string;
  status: string;
  shortEmail?: string;
  shortPhone?: string;
}

interface AddEmployerParams {
  email?: string;
  phoneCountry?: string;
  phone?: string;
  isRegulatedActivities?: boolean;
  commChannel?: 'SMS' | 'EMAIL';
  secondAgent?: {
    agentCode?: string;
    isRegulatedActivities?: boolean;
    commChannel?: 'SMS' | 'EMAIL';
    language?: 'CN' | 'EN';
  };
  thirdAgent?: {
    agentCode?: string;
    isRegulatedActivities?: boolean;
    commChannel?: 'SMS' | 'EMAIL';
    language?: 'CN' | 'EN';
  };
  isDbs?: boolean;
  dbsCustomerId?: string;
  dbsRmReferralCd?: string;
  dbsCampaignCd?: string;
  dbsSourceCd?: string;
  language?: 'CN' | 'EN';
  productTypes?: string[];
  employerName?: string;
  businessRegisterNo?: string;
}

@Component({
  selector: 'app-employer-for-md',
  templateUrl: './employer-for-md.component.html',
  styleUrls: ['./employer-for-md.component.scss'],
})
export class EmployerForMdComponent implements OnInit, OnDestroy {
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage?: CdRadioComponent;
  @ViewChild('radioRelevantRegulatedActivities') radioRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioInTransferValue') radioInTransferValue!: CdRadioComponent;
  @ViewChild('dbsReferredLanguage') dbsReferredLanguage!: CdRadioComponent;
  @ViewChild('radioSecondAgentRelevantRegulatedActivities') radioSecondAgentRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioSecondAgentClientContact') radioSecondAgentClientContact?: CdRadioComponent;
  @ViewChild('radioSecondAgentLanguage') radioSecondAgentLanguage?: CdRadioComponent;
  @ViewChild('radioThirdAgentRelevantRegulatedActivities') radioThirdAgentRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioThirdAgentClientContact') radioThirdAgentClientContact?: CdRadioComponent;
  @ViewChild('radioThirdAgentLanguage') radioThirdAgentLanguage?: CdRadioComponent;
  PreferredLanguageType = PreferredLanguageType;
  Show = Show;
  show = Show.employer;
  isConfirming = false;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  private readonly CN_PHONE = /^1[345678]\d{9}$/;
  backIcon = ActionIcons.button_left_filled;
  employerForm!: FormGroup;
  authpersonContact = '';
  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };
  lanChangeSubscription?: Subscription;
  language = 'EN';
  authpersonContactConfig: CdRadioConfig = {
    options: [
      {
        label: 'Email Address',
        value: 'E',
        disable: false,
      },
      {
        label: 'Phone Number',
        value: 'M',
        disable: false,
      },
    ],
  };
  resetDatePicker = false;
  secondAgent: {
    agentCode: string;
    secondAgentInfo?: AgentInfo;
  } = {
    agentCode: '',
  };
  thirdAgent: {
    agentCode: string;
    thirdAgentInfo?: AgentInfo;
  } = {
    agentCode: '',
  };
  isTryConfirm = false;
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  employerFullNameOrBrNumber = '';
  employerFullNameOrBrNumberConfig: CdRadioConfig = {
    options: [
      {
        label: '僱主全名',
        value: 'N',
        disable: false,
      },
      {
        label: '商業登記號碼',
        value: 'B',
        disable: false,
      },
    ],
  };

  relevantRegulatedActivitiesValue = '';
  relevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'n',
        disable: false,
      },
    ],
  };

  inTransferValue = '';
  inTransferConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'n',
        disable: false,
      },
    ],
  };

  isDBschannel = '';
  dbsContactConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };

  secondAgentRelevantRegulatedActivities = '';
  secondAgentRelevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };
  secondAgentClientContact = '';
  secondAgentClientContactConfig?: CdRadioConfig;
  secondAgentLanguage = '';
  secondAgentLanguageConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.chinese'),
        value: 'ZH',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.english'),
        value: 'EN',
        disable: false,
      },
    ],
  };

  thirdAgentRelevantRegulatedActivities = '';
  thirdAgentRelevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };
  thirdAgentClientContact = '';
  thirdAgentClientContactConfig?: CdRadioConfig;
  thirdAgentLanguage = '';
  thirdAgentLanguageConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.chinese'),
        value: 'ZH',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.english'),
        value: 'EN',
        disable: false,
      },
    ],
  };

  get preferredLanguage() {
    return this.employerForm.get('preferredLanguage') as FormControl;
  }

  get authpersonEmailAddress() {
    return this.employerForm.get('authpersonEmailAddress') as FormControl;
  }

  get authpersonPhoneNumber() {
    return this.employerForm.get('authpersonPhoneNumber') as FormControl;
  }

  get authpersonCountryCode() {
    return this.employerForm.get('authpersonCountryCode') as FormControl;
  }

  get employerFullName() {
    return this.employerForm.get('employerFullName') as FormControl;
  }

  get BRNumber() {
    return this.employerForm.get('BRNumber') as FormControl;
  }

  get idType() {
    return this.employerForm.get('idType') as FormControl;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private notifyService: NotifyService,
    private languageChangeService: LanguageChangeService,
    private cdsPopup: CdPopupService
  ) {}

  ngOnInit(): void {
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(this.lanSubjectChange);

    this.employerForm = new FormGroup({
      idType: new FormControl('0'),
      authpersonEmailAddress: new FormControl(null, [this.authpersonEmailAddressVali]),
      authpersonCountryCode: new FormControl('852'),
      authpersonPhoneNumber: new FormControl('', [this.authpersonPhoneNumberVali]),
      preferredLanguage: new FormControl(null, [this.preferredLanguageVali]),
      employerFullName: new FormControl('', [this.employerFullNameVali]),
      BRNumber: new FormControl('', [this.brNumberVali]),
      rmReferralCode: new FormControl('', [this.rmReferralCodeVali]),
      inputCustomerId: new FormControl('', [this.inputCustomerIdVali]),
      sourceCode: new FormControl('', [this.sourceCodeVali]),
      campaignCode: new FormControl('', [this.campaignCodeVali]),
    });
  }

  employerFullNameVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.employerFullNameOrBrNumber === 'N') {
      let input = control.value || '';
      input = input.trim();
      if (input.length === 0) {
        return { required: true };
      }
    }
    return null;
  };

  brNumberVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.employerFullNameOrBrNumber === 'B') {
      let input = control.value || '';
      input = input.trim();
      if (input.length === 0) {
        return { required: true };
      }
    }
    return null;
  };

  rmReferralCodeVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.isDBschannel === 'Y') {
      let input = control.value || '';
      input = input.trim();
      const isValid = input.length >= 6 && input.length <= 8;
      return isValid ? null : { lengthError: true };
    }
    return null;
  };
  inputCustomerIdVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  sourceCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  campaignCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  authpersonPhoneNumberVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.authpersonContact === 'M') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        if (['852', '853'].includes(this.authpersonCountryCode.value)) {
          if (option.value.length === 8 && ['4', '5', '6', '7', '8', '9'].includes(option.value.substring(0, 1))) {
            return null;
          }
        }

        if (['86'].includes(this.authpersonCountryCode.value)) {
          if (option.value.length === 11) {
            const e = this.CN_PHONE.test(option.value);
            if (e) {
              return null;
            }
          }
        }

        if (!['852', '853', '86'].includes(this.authpersonCountryCode.value)) {
          return null;
        }
      }

      return { errorPhoneNumber: true };
    }

    return null;
  };

  authpersonEmailAddressVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.authpersonContact === 'E') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        if (!option.value.includes('@')) {
          return { email: true };
        }
      }
    }
    return null;
  };

  authpersonContactChange() {
    this.preferredLanguage.setValue(undefined);
    this.preferredLanguageConfig = {
      options: [
        {
          label: 'common.chinese',
          value: PreferredLanguageType.Chinese,
          disable: false,
        },
        {
          label: 'common.english',
          value: PreferredLanguageType.English,
          disable: false,
        },
      ],
    };
    this.cdRadioPreferredLanguage?.refreshConfig();
    if (this.authpersonContact === 'E') {
      this.authpersonPhoneNumber.updateValueAndValidity();
    }
  }

  preferredLanguageVali = (control: AbstractControl): ValidationErrors | null => {
    if ((this.authpersonContact === 'M' && control.value) || this.authpersonContact === 'E' || !this.authpersonContact) {
      return null;
    }
    return { formatError: true };
  };

  authpersonCountryCodeChange() {
    this.authpersonPhoneNumber.updateValueAndValidity();
  }

  lanSubjectChange = (lan: string) => {
    this.language = lan === 'en' ? 'EN' : 'TC';

    this.authpersonContactConfig = {
      options: [
        {
          label: this.cdsLangService.translate('generate.md.emailAddress'),
          value: 'E',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('generate.md.phoneNumber'),
          value: 'M',
          disable: false,
        },
      ],
    };

    this.employerFullNameOrBrNumberConfig = {
      options: [
        {
          label: this.cdsLangService.translate('generate.md.employer-full-name'),
          value: 'N',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('generate.md.br-bumber'),
          value: 'B',
          disable: false,
        },
      ],
    };

    this.refreshRelevantRegulatedActivitiesConfig();
    this.refreshinTransferConfig();
    this.refreshDbsContactConfig();
    this.refreshSecondAgentClientContactConfig();
    this.refreshThirdAgentClientContactConfig();
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
    this.refreshThirdAgentRelevantRegulatedActivitiesConfig();
  };

  private refreshSecondAgentClientContactConfig() {
    if (!this.secondAgent.secondAgentInfo) {
      return;
    }
    this.secondAgent.secondAgentInfo.shortEmail = '';
    if (this.secondAgent.secondAgentInfo.emailAddress) {
      const ea = this.secondAgent.secondAgentInfo.emailAddress || '';
      const endIndex = ea.indexOf('@');
      this.secondAgent.secondAgentInfo.shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
    }
    this.secondAgent.secondAgentInfo.shortPhone = '';
    if (this.secondAgent.secondAgentInfo.mobileNumber) {
      const mn = this.secondAgent.secondAgentInfo.mobileNumber || '';
      let phoneArr = new Array((mn + '').length - 6);
      phoneArr = phoneArr.fill('*');
      this.secondAgent.secondAgentInfo.shortPhone = mn.substring(0, 6) + phoneArr.join('');
    }

    this.secondAgentClientContactConfig = {
      options: [
        {
          label: `${this.cdsLangService.translate('generate.md.registered-email-address')}(${this.secondAgent.secondAgentInfo.shortEmail})`,
          value: 'E',
          disable: false,
        },
        {
          label: `${this.cdsLangService.translate('generate.md.registered-phone-number')} ${this.secondAgent.secondAgentInfo.shortPhone}`,
          value: 'M',
          disable: false,
        },
      ],
    };
    this.radioSecondAgentClientContact?.refreshConfig();
  }

  private refreshThirdAgentClientContactConfig() {
    if (!this.thirdAgent.thirdAgentInfo) {
      return;
    }
    this.thirdAgent.thirdAgentInfo.shortEmail = '';
    if (this.thirdAgent.thirdAgentInfo.emailAddress) {
      const ea = this.thirdAgent.thirdAgentInfo.emailAddress || '';
      const endIndex = ea.indexOf('@');
      this.thirdAgent.thirdAgentInfo.shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
    }
    this.thirdAgent.thirdAgentInfo.shortPhone = '';
    if (this.thirdAgent.thirdAgentInfo.mobileNumber) {
      const mn = this.thirdAgent.thirdAgentInfo.mobileNumber || '';
      let phoneArr = new Array((mn + '').length - 6);
      phoneArr = phoneArr.fill('*');
      this.thirdAgent.thirdAgentInfo.shortPhone = mn.substring(0, 6) + phoneArr.join('');
    }

    this.thirdAgentClientContactConfig = {
      options: [
        {
          label: `${this.cdsLangService.translate('generate.md.registered-email-address')}(${this.thirdAgent.thirdAgentInfo.shortEmail})`,
          value: 'E',
          disable: false,
        },
        {
          label: `${this.cdsLangService.translate('generate.md.registered-phone-number')} ${this.thirdAgent.thirdAgentInfo.shortPhone}`,
          value: 'M',
          disable: false,
        },
      ],
    };
    this.radioThirdAgentClientContact?.refreshConfig();
  }

  reset() {
    const popupRef: MatDialogRef<ResetWarningComponent> = this.cdsPopup.open(ResetWarningComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(this.resetWarningComponentPopupCloseCall);
  }

  resetWarningComponentPopupCloseCall = (confirm: { agree: boolean }) => {
    if (confirm.agree) {
      this.employerForm.reset();
      this.authpersonCountryCode.setValue('852');
      this.idType.setValue('0');
      this.isTryConfirm = false;
      this.relevantRegulatedActivitiesValue = '';
      this.inTransferValue = '';
      this.secondAgent.agentCode = '';
      this.thirdAgent.agentCode = '';
      this.authpersonContact = '';
      this.employerFullNameOrBrNumber = '';
      this.isDBschannel = '';
      this.resetDatePicker = true;
      setTimeout(() => {
        this.resetDatePicker = false;
      }, 0);
      this.preferredLanguage.reset();
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
      this.cdRadioPreferredLanguage?.refreshConfig();
    }
  };

  get isDisableNext() {
    return (
      this.employerForm.invalid ||
      this.employerFullNameOrBrNumber === '' ||
      this.authpersonContact === '' ||
      this.isDBschannel === '' ||
      this.relevantRegulatedActivitiesValue === '' ||
      this.inTransferValue === ''
    );
  }

  get getSecondAgentName() {
    if (!this.secondAgent.secondAgentInfo) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return this.secondAgent.secondAgentInfo.agentName || this.secondAgent.secondAgentInfo.agentNameTC;
    } else {
      return this.secondAgent.secondAgentInfo.agentNameTC || this.secondAgent.secondAgentInfo.agentName;
    }
  }

  get getThirdAgentName() {
    if (!this.thirdAgent.thirdAgentInfo) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return this.thirdAgent.thirdAgentInfo.agentName || this.thirdAgent.thirdAgentInfo.agentNameTC;
    } else {
      return this.thirdAgent.thirdAgentInfo.agentNameTC || this.thirdAgent.thirdAgentInfo.agentName;
    }
  }

  next() {
    if (this.isDisableNext) {
      return;
    }

    if (this.employerForm.valid) {
      this.isConfirming = true;
      this.setRelevantRegulatedActivitiesDisable();
      this.setInTransferConfigDisable();

      const agentCodeList = [];
      if (this.secondAgent.agentCode) {
        agentCodeList.push(this.secondAgent.agentCode);
      }
      if (this.thirdAgent.agentCode) {
        agentCodeList.push(this.thirdAgent.agentCode);
      }
      if (agentCodeList.length > 0) {
        this.show = Show.intermediary;
        this.getAgentInfoByAgentCode(agentCodeList).then(agentInfoList => {
          this.secondAgent.secondAgentInfo = agentInfoList[0];
          this.thirdAgent.thirdAgentInfo = agentInfoList[1];
          this.refreshSecondAgentClientContactConfig();
          this.refreshThirdAgentClientContactConfig();
        });
      }
    }
  }

  private getAgentInfoByAgentCode(agentCodeList: string[]) {
    return new Promise<AgentInfo[]>((resolve, reject) => {
      this.cdHttpServeService.post<AgentInfo[]>(`/ext/eb-ssms/agent-service/agent/infos/agentCd`, agentCodeList).subscribe({
        next: res => {
          if (res.result === 0) {
            resolve(res.data);
          } else {
            this.alert.error('Error!', res.message);
          }
          reject();
        },
        error: err => {
          this.alert.error('Error!', err);
          reject();
        },
      });
    });
  }

  private setRelevantRegulatedActivitiesDisable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: true,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: true,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setRelevantRegulatedActivitiesEnable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: false,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setInTransferConfigDisable() {
    this.inTransferConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: true,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: true,
        },
      ],
    };
    this.radioInTransferValue?.refreshConfig();
  }

  private setInTransferConfigEnable() {
    this.inTransferConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: false,
        },
      ],
    };
    this.radioInTransferValue?.refreshConfig();
  }

  backToEdit() {
    const popupRef = this.cdsPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: 'generate.md.leave-page',
        continue: 'common.confirm-continue',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.continue) {
        this.isConfirming = false;
        this.dbsContactConfig.options.forEach((e: { disable: boolean }) => {
          e.disable = false;
        });
        this.dbsReferredLanguage?.refreshConfig();
        this.setRelevantRegulatedActivitiesEnable();
        this.setInTransferConfigEnable();
        this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
        this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
        this.secondAgentRelevantRegulatedActivities = '';
        this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
        this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
        this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
        this.thirdAgentRelevantRegulatedActivities = '';
        this.refreshThirdAgentRelevantRegulatedActivitiesConfig();
        this.secondAgentClientContact = '';
        this.secondAgentLanguage = '';
        this.thirdAgentClientContact = '';
        this.thirdAgentLanguage = '';
      }
    });
  }

  submit() {
    const params: AddEmployerParams = {};

    if (this.employerFullNameOrBrNumber === 'N') {
      params.employerName = this.employerFullName.value;
    } else {
      params.businessRegisterNo = this.BRNumber.value;
    }

    if (this.authpersonContact === 'E') {
      params.commChannel = 'EMAIL';
      params.email = this.authpersonEmailAddress.value;
    } else {
      params.commChannel = 'SMS';
      params.phoneCountry = this.authpersonCountryCode.value;
      params.phone = this.authpersonPhoneNumber.value;
      params.language = this.preferredLanguage.value === PreferredLanguageType.Chinese ? 'CN' : 'EN';
    }

    params.isRegulatedActivities = this.relevantRegulatedActivitiesValue === 'y';
    params.isDbs = false;
    params.productTypes = ['ER'];

    if (this.secondAgent.secondAgentInfo) {
      params.secondAgent = {};
      params.secondAgent.agentCode = this.secondAgent.secondAgentInfo.agentCode;
      params.secondAgent.isRegulatedActivities = this.secondAgentRelevantRegulatedActivities === 'Y';
      if (params.secondAgent.isRegulatedActivities) {
        params.secondAgent.commChannel = this.secondAgentClientContact === 'E' ? 'EMAIL' : 'SMS';
        if (this.secondAgentClientContact === 'M') {
          params.secondAgent.language = this.secondAgentLanguage === 'EN' ? 'EN' : 'CN';
        }
      }
    }

    if (this.thirdAgent.thirdAgentInfo) {
      params.thirdAgent = {};
      params.thirdAgent.agentCode = this.thirdAgent.thirdAgentInfo.agentCode;
      params.thirdAgent.isRegulatedActivities = this.thirdAgentRelevantRegulatedActivities === 'Y';
      if (params.thirdAgent.isRegulatedActivities) {
        params.thirdAgent.commChannel = this.thirdAgentClientContact === 'E' ? 'EMAIL' : 'SMS';
        if (this.thirdAgentClientContact === 'M') {
          params.thirdAgent.language = this.thirdAgentLanguage === 'EN' ? 'EN' : 'CN';
        }
      }
    }

    this.cdHttpServeService
      .post<{
        mainId: string;
        ids: string[];
      }>(`/ext/eb-ssms-sales-journey-service/md/add/employer`, params)
      .subscribe({
        next: res => {
          if (res.result === 0) {
            if (this.relevantRegulatedActivitiesValue === 'n') {
              this.cdsPopup
                .open(HasBeenSentCustomerPopupComponent, {
                  size: 'md',
                  data: {
                    mdNo: res.data.mainId,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['/salesjourney/md-iff-summary']);
                });
            } else {
              this.cdsPopup
                .open(PendingSplitAgentConfirPopupComponent, {
                  size: 'md',
                  data: {
                    mdNo: res.data.mainId,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['/salesjourney/md-iff-summary']);
                });
            }
          } else {
            this.alert.error('Error!', res.message);
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
  }

  ngOnDestroy(): void {
    this.lanChangeSubscription?.unsubscribe();
  }

  onBack() {
    this.router.navigate(['/salesjourney/md-iff-summary']);
  }

  employerFullNameOrBrNumberChange() {
    this.employerFullName.updateValueAndValidity();
    this.BRNumber.updateValueAndValidity();
  }

  dbsContractChange(val: string) {
    this.isDBschannel = val;
    if (val === 'N') {
      this.employerForm.get('rmReferralCode')?.reset();
      this.employerForm.get('inputCustomerId')?.reset();
      this.employerForm.get('campaignCode')?.reset();
      this.employerForm.get('sourceCode')?.reset();
    }
  }

  private refreshDbsContactConfig() {
    this.dbsContactConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.dbsContactConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.dbsReferredLanguage?.refreshConfig();
  }

  private refreshRelevantRegulatedActivitiesConfig() {
    this.relevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.relevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private refreshinTransferConfig() {
    this.inTransferConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.inTransferConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioInTransferValue?.refreshConfig();
  }

  secondAgentCodeChange(code: string) {
    if (code.length === 0) {
      this.thirdAgent.agentCode = '';
    }
  }

  secondAgentClientContactChange() {
    this.secondAgentLanguage = '';
  }

  thirdAgentClientContactChange() {
    this.thirdAgentLanguage = '';
  }

  intermediaryReset() {
    this.secondAgentRelevantRegulatedActivities = '';
    this.secondAgentLanguage = '';
    this.secondAgentClientContact = '';
    this.thirdAgentRelevantRegulatedActivities = '';
    this.thirdAgentLanguage = '';
    this.thirdAgentClientContact = '';
  }

  get isDisableIntermediaryNext() {
    if (this.secondAgent.secondAgentInfo) {
      if (this.secondAgentRelevantRegulatedActivities === '') {
        return true;
      }
      if (this.secondAgentRelevantRegulatedActivities === 'Y') {
        if (this.secondAgentClientContact === '') {
          return true;
        }
        if (this.secondAgentClientContact === 'M' && this.secondAgentLanguage === '') {
          return true;
        }
      }
    }

    if (this.thirdAgent.thirdAgentInfo) {
      if (this.thirdAgentRelevantRegulatedActivities === '') {
        return true;
      }
      if (this.thirdAgentRelevantRegulatedActivities === 'Y') {
        if (this.thirdAgentClientContact === '') {
          return true;
        }
        if (this.thirdAgentClientContact === 'M' && this.thirdAgentLanguage === '') {
          return true;
        }
      }
    }
    return false;
  }

  get getSecondAgentLanguage() {
    if (this.secondAgentLanguage === 'ZH') {
      return this.secondAgentLanguageConfig.options[0].label;
    } else if (this.secondAgentLanguage === 'EN') {
      return this.secondAgentLanguageConfig.options[1].label;
    }
    return undefined;
  }

  get getThirdAgentLanguage() {
    if (this.thirdAgentLanguage === 'ZH') {
      return this.thirdAgentLanguageConfig.options[0].label;
    } else if (this.thirdAgentLanguage === 'EN') {
      return this.thirdAgentLanguageConfig.options[1].label;
    }
    return undefined;
  }

  intermediaryNext() {
    if (this.isDisableIntermediaryNext) {
      return;
    }
    this.isConfirming = true;
    this.show = Show.employer;
    this.setRelevantRegulatedActivitiesDisable();
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
    this.refreshThirdAgentRelevantRegulatedActivitiesConfig();
  }

  private refreshSecondAgentRelevantRegulatedActivitiesConfig() {
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioSecondAgentRelevantRegulatedActivities?.refreshConfig();
  }

  private refreshThirdAgentRelevantRegulatedActivitiesConfig() {
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.thirdAgentRelevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioThirdAgentRelevantRegulatedActivities?.refreshConfig();
  }
}
