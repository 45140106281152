/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { APIPaths } from '../_common/constant';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const SockJS: any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const Stomp: any;
declare const StompJs: any;
// var destination = '/queue/someQueueName';

@Injectable({
  providedIn: 'root',
})
export class MessageService implements OnDestroy {
  public stompClient: any;
  public msg: any = [];
  private receivedMessagesSubject = new Subject<string>();
  subscription: any;
  roomId: any;
  private manualClose = false;
  username: any;
  constructor() {}

  // async initializeWebSocketConnection(roomId: any, username: string) {
  //   const serverUrl = APIPaths.websocket;
  //   const socket = new SockJS(serverUrl);
  //   this.stompClient = Stomp.over(socket);
  //   this.stompClient.connect({}, (frame: any) => {
  //     this.stompClient.subscribe(`/topic/public/${roomId}`, (message: any) => {
  //       // Tell your username to the server
  //       const receivedMessage = JSON.parse(message.body);
  //       var d = new Date();
  //       receivedMessage.time =  d.getHours() +':'+ d.getMinutes() +':'+ d.getSeconds()
  //       this.receivedMessagesSubject.next(receivedMessage);
  //     });
  //   });
  // }

  // sendMessage(message: any, roomId: any) {
  //   this.stompClient.send(`/app/chat.sendMessage/${roomId}`, {}, JSON.stringify(message));
  // }

  // joinMessage(message: any, roomId: any) {
  //   this.stompClient.send(`/app/chat.addUser/${roomId}`, {}, JSON.stringify(message));
  // }

  // receiveMessages(): Observable<string> {
  //   return this.receivedMessagesSubject.asObservable();
  // }

  // async initializeWebSocketConnection(roomId: any, username: string) {
  //   this.roomId = roomId
  //   this.username = username
  //   return new Promise((resolve, reject) => {

  //     if (this.stompClient && this.stompClient.connected) {
  //       this.stompClient.deactivate();  // Gracefully close the previous connection
  //     }
  //     this.stompClient = new StompJs.Client();
  //     this.stompClient.webSocketFactory = () => new WebSocket(environment.wsPrefix);
  //     this.stompClient.activate();

  //     this.stompClient.onConnect = (frame: any) => {
  //       console.log('Connected: ' + frame);
  //       if (this.subscription) {
  //         this.subscription.unsubscribe(); // Unsubscribe from previous room
  //       }
  //       this.subscription = this.stompClient.subscribe(`/topic/public/${roomId}`, (message: any) => {
  //         const receivedMessage = JSON.parse(message.body);
  //         this.receivedMessagesSubject.next(receivedMessage);
  //       });
  //       resolve(true);
  //     };

  //     this.stompClient.onWebSocketError = (error: any) => {
  //       console.error('Error with websocket', error);
  //       reject(false);
  //     };

  //     this.stompClient.onWebSocketClose = () => {
  //       console.log("WebSocket connection closed. Attempting to reconnect...");
  //       setTimeout(() => {
  //         this.initializeWebSocketConnection(roomId, username);
  //       }, 5000);  // Reconnect after a delay (e.g., 5 seconds)
  //     };

  //     this.stompClient.onStompError = (frame: any) => {
  //       console.error('Broker reported error: ' + frame.headers['message']);
  //       console.error('Additional details: ' + frame.body);
  //       reject(false);
  //     };
  //   })
  // }

  async initializeWebSocketConnection(roomId: any, username: string) {
    return new Promise((resolve, reject) => {
      this.manualClose = false;
      if (this.roomId === roomId) {
        console.log('Already connected to the room:', roomId);
        return;
      }

      // Close existing WebSocket connection if it's open
      if (this.stompClient && this.stompClient.connected) {
        this.closeWebSocketConnection(); // Gracefully close the previous connection
      }
      this.roomId = roomId;
      this.username = username;
      try {
        this.stompClient = new StompJs.Client();
        this.stompClient.webSocketFactory = () => new WebSocket(APIPaths.websocket);
        this.stompClient.activate();
        this.stompClient.onConnect = (frame: any) => {
          console.log('Connected: ' + frame);

          // Unsubscribe from any existing subscription
          if (this.subscription) {
            this.subscription.unsubscribe();
          }

          // Subscribe to the new room
          if (this.stompClient.connected) {
            this.subscription = this.stompClient.subscribe(`/topic/public/${roomId}`, (message: any) => {
              const receivedMessage = JSON.parse(message.body);
              this.receivedMessagesSubject.next(receivedMessage);
            });
          } else {
            console.error('Attempted to subscribe without a valid connection.');
          }

          resolve(true);
        };
        this.stompClient.onWebSocketError = (error: any) => {
          console.error('Error with websocket', error);
          reject('WebSocket error occurred');
        };

        this.stompClient.onWebSocketClose = () => {
          console.log('WebSocket connection closed.');
          if (!this.manualClose) {
            console.log('Attempting to reconnect...');
            setTimeout(() => {
              this.initializeWebSocketConnection(roomId, username);
            }, 5000); // Reconnect after a delay
          }
        };

        this.stompClient.onStompError = (frame: any) => {
          console.error('Broker reported error: ' + frame.headers['message']);
          console.error('Additional details: ' + frame.body);
          reject('STOMP error occurred');
        };
      } catch (error) {
        console.error('Failed to initialize WebSocket connection:', error);
        // this.isConnecting = false; // Reset flag on failure
      }
    });
  }

  closeWebSocketConnection() {
    if (this.stompClient && this.stompClient.connected) {
      // Set the flag to indicate a manual closure
      this.manualClose = true;

      // Unsubscribe from the current subscription
      if (this.subscription) {
        this.subscription.unsubscribe();
        console.log('Unsubscribed from room subscription.');
      }

      // Deactivate the STOMP client asynchronously
      this.stompClient
        .deactivate()
        .then(() => {
          // this.stompClient = null
          console.log('WebSocket connection successfully closed.');
        })
        .catch((error: any) => {
          console.error('Error closing WebSocket connection:', error);
        });
    } else {
      console.warn('WebSocket connection is already closed or not established.');
    }
  }

  sendMessage(message: any, roomId: any) {
    //this.stompClient.send(`/app/chat.sendMessage/${roomId}`, {}, JSON.stringify(message));
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.publish({
        destination: `/app/chat.sendMessage/${roomId}`,
        body: JSON.stringify(message),
      });
    } else {
      console.warn('WebSocket is not connected. Cannot send message.');
    }
  }

  joinMessage(message: any, roomId: any) {
    this.stompClient.publish({
      destination: `/app/chat.addUser/${roomId}`,
      body: JSON.stringify(message),
    });
    // this.stompClient.send(`/app/chat.addUser/${roomId}`, {}, JSON.stringify(message));
  }

  leaveRoom(message: any, roomId: any) {
    this.stompClient.publish({
      destination: `/app/chat.closeChat/${roomId}`,
      body: JSON.stringify(message),
    });
  }

  receiveMessages(): Observable<string> {
    return this.receivedMessagesSubject.asObservable();
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.deactivate(); // Gracefully close the WebSocket connection
    }
  }
}
