import { CdDropdownTopping } from 'src/app/shared/cd-dropdown/cd-dropdown.component';

export enum HighRiskFileType {
  High_Risk_Nationality = '1',
  High_Risk_Country = '2',
  High_Risk_Business_Nature = '3',
  High_Risk_Occupation = '4',
  High_Risk_Nationality_ORSO = '5',
  High_Risk_Country_ORSO = '6',
  High_Risk_Business_Nature_Keyword = '7',
  High_Risk_Occupation_Keyword = '8',
}

export const highRiskFileTypeOptions: CdDropdownTopping[] = [
  {
    label: 'High_Risk_Nationality',
    value: '1',
  },
  {
    label: 'High_Risk_Country',
    value: '2',
  },
  {
    label: 'High_Risk_Business_Nature',
    value: '3',
  },
  {
    label: 'High_Risk_Occupation',
    value: '4',
  },
  {
    label: 'High_Risk_Nationality_ORSO',
    value: '5',
  },
  {
    label: 'High_Risk_Country_ORSO',
    value: '6',
  },
  {
    label: 'High_Risk_Business_Nature_Keyword',
    value: '7',
  },
  {
    label: 'High_Risk_Occupation_Keyword',
    value: '8',
  },
];
