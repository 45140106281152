/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { cdsPopupBackdropOntouchmovePreventDefault, cdsPopupBackdropUnOntouchmovePreventDefault } from 'src/app/utils/utils';
import { SalesJourneyProdType } from '../case-summary/case-summary.model';
import { IffCompletionStepsPopupComponent } from '../iff-completion-steps-popup/iff-completion-steps-popup.component';
import { AgentProductSummary } from '../product-type-summary/product-type-summary.model';
import { CheckResponseData } from '../product-type-summary/producttype-response';
import { ProductTypeCountDetailsPopupComponent } from './product-type-count-details-popup/product-type-count-details-popup.component';
import { IndividualType } from './md-iff-summary.model';

type RolePriority = 'SalesSupport' | 'SalesJourneyAgent' | 'SuperAdmin';

export enum QuickSearchIffStatus {
  'All',
  'Completed',
  'Declined',
  'NONE',
}

enum TabIndex {
  'mdiff',
  'empf',
}

@Component({
  selector: 'app-md-iff-summary',
  templateUrl: './md-iff-summary.component.html',
  styleUrls: ['./md-iff-summary.component.scss'],
})
export class MdIffSummaryComponent implements OnInit, OnDestroy {
  SalesJourneyProdType = SalesJourneyProdType;
  TabIndex = TabIndex;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  IndividualType = IndividualType;
  IffStatus = QuickSearchIffStatus;
  individualTypeSelected = IndividualType.ALL;
  iffStatusSelected = QuickSearchIffStatus.All;
  prodTypeFormControl: FormControl = new FormControl(SalesJourneyProdType.Individual);
  productTypeOptions = [
    {
      label: 'Individual(PA,TVC,SVC)',
      value: SalesJourneyProdType.Individual,
    },
    { label: 'Corperate(ER)', value: SalesJourneyProdType.Corperate },
    { label: 'SEP', value: SalesJourneyProdType.SEP },
  ];
  hasSalesSupportRole = false;
  hasSalesJourneyAgentRole = false;
  hasSalesJourneySuperAdminRole = false;
  rolePriority?: RolePriority;
  tabIndex: TabIndex = TabIndex.mdiff;
  backSummaryIcon = ActionIcons.button_left_filled;

  backSummaryIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };

  paSummary = new AgentProductSummary(0, 0, 0, 0);
  tvcSummary = new AgentProductSummary(0, 0, 0, 0);
  svcSummary = new AgentProductSummary(0, 0, 0, 0);
  erSummary = new AgentProductSummary(0, 0, 0, 0);
  sepSummary = new AgentProductSummary(0, 0, 0, 0);
  productTypeCount: {
    pa: AgentProductSummary;
    tvc: AgentProductSummary;
    svc: AgentProductSummary;
    er: AgentProductSummary;
    sep: AgentProductSummary;
  } = {
    pa: this.paSummary,
    tvc: this.tvcSummary,
    svc: this.svcSummary,
    er: this.erSummary,
    sep: this.sepSummary,
  };

  constructor(
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    private permissionService: PermissionService,
    public empfAppStatusService: EmpfAppStatusService,
    private router: Router,
    private route: ActivatedRoute,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService
  ) {}

  ngOnInit(): void {
    this.prodTypeFormControl.setValue(sessionStorage.getItem('productType') || SalesJourneyProdType.Individual);
    const tempTabIndex = sessionStorage.getItem('md-iff-summary-tabIndex');
    if (tempTabIndex) {
      this.tabIndex = Number(tempTabIndex);
    }
    if (this.route.snapshot.paramMap.get('tabIndex') != null) {
      this.tabIndex = Number(this.route.snapshot.paramMap.get('tabIndex'));
    }
    const prodType = (this.route.snapshot.paramMap.get('prodType') as SalesJourneyProdType) || undefined;
    if (prodType) {
      this.prodTypeFormControl.setValue(prodType);
    }

    this.initRole();
  }

  onClickGetDetailsByProductType(type: string) {
    const popupRef: MatDialogRef<ProductTypeCountDetailsPopupComponent> = this.cdsPopup.open(ProductTypeCountDetailsPopupComponent, {
      size: 'sm',
      data: {
        count: this.productTypeCount,
        productType: type,
      },
    });
    popupRef.afterClosed().subscribe(() => {
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });
    popupRef.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('md-iff-summary-tabIndex');
  }

  onBackSummayIconClick() {
    this.router.navigate(['/salesjourney/md-iff-summary/select-product-type']);
  }

  private async initRole() {
    this.hasSalesSupportRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SALES_SUPPORT);
    this.hasSalesJourneyAgentRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_AGENT);
    this.hasSalesJourneySuperAdminRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SUPER_ADMIN);
    if (this.hasSalesJourneySuperAdminRole) {
      this.rolePriority = 'SuperAdmin';
    } else if (this.hasSalesSupportRole) {
      this.rolePriority = 'SalesSupport';
    } else if (this.hasSalesJourneyAgentRole) {
      this.rolePriority = 'SalesJourneyAgent';
      this.getProductTypeSummaries();
    }
  }

  private getProductTypeSummaries() {
    this.cdHttpServeService.get<CheckResponseData>(`/ext/eb-ssms-sales-journey-service/productTypeSummaries`, undefined).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          const respData = res.data;
          // TVC
          this.tvcSummary.pendingAgent = respData.TVC ? respData.TVC.pending2ndAgent : 0;
          this.tvcSummary.pendingcustomer = respData.TVC ? respData.TVC.pendingCusSubmission : 0;
          this.tvcSummary.pendingToMap = respData.TVC ? respData.TVC.pendingToMap : 0;
          const tvcPendingAgent = this.tvcSummary.pendingAgent ? this.tvcSummary.pendingAgent : 0;
          const tvcPendingCust = this.tvcSummary.pendingcustomer ? this.tvcSummary.pendingcustomer : 0;
          const tvcPendingToSend = this.tvcSummary.pendingToMap ? this.tvcSummary.pendingToMap : 0;
          this.tvcSummary.allPendingCount = tvcPendingAgent + tvcPendingCust + tvcPendingToSend;

          // PA
          this.paSummary.pendingAgent = respData.PA ? respData.PA.pending2ndAgent : 0;
          this.paSummary.pendingcustomer = respData.PA ? respData.PA.pendingCusSubmission : 0;
          this.paSummary.pendingToMap = respData.PA ? respData.PA.pendingToMap : 0;
          const paPendingAgent = this.paSummary.pendingAgent ? this.paSummary.pendingAgent : 0;
          const paPendingCust = this.paSummary.pendingcustomer ? this.paSummary.pendingcustomer : 0;
          const paPendingToSend = this.paSummary.pendingToMap ? this.paSummary.pendingToMap : 0;
          this.paSummary.allPendingCount = paPendingAgent + paPendingCust + paPendingToSend;

          // FRC
          this.svcSummary.pendingAgent = respData.SVC ? respData.SVC.pending2ndAgent : 0;
          this.svcSummary.pendingcustomer = respData.SVC ? respData.SVC.pendingCusSubmission : 0;
          this.svcSummary.pendingToMap = respData.SVC ? respData.SVC.pendingToMap : 0;
          const frcPendingAgent = this.svcSummary.pendingAgent ? this.svcSummary.pendingAgent : 0;
          const frcPendingCust = this.svcSummary.pendingcustomer ? this.svcSummary.pendingcustomer : 0;
          const frcPendingToSend = this.svcSummary.pendingToMap ? this.svcSummary.pendingToMap : 0;
          this.svcSummary.allPendingCount = frcPendingAgent + frcPendingCust + frcPendingToSend;

          // ER
          this.erSummary.pendingAgent = respData.ER ? respData.ER.pending2ndAgent : 0;
          this.erSummary.pendingcustomer = respData.ER ? respData.ER.pendingCusSubmission : 0;
          this.erSummary.pendingToMap = respData.ER ? respData.ER.pendingToMap : 0;
          const erPendingAgent = this.erSummary.pendingAgent ? this.erSummary.pendingAgent : 0;
          const erPendingCust = this.erSummary.pendingcustomer ? this.erSummary.pendingcustomer : 0;
          const erPendingToSend = this.erSummary.pendingToMap ? this.erSummary.pendingToMap : 0;
          this.erSummary.allPendingCount = erPendingAgent + erPendingCust + erPendingToSend;

          // SEP
          this.sepSummary.pendingAgent = respData.SEP ? respData.SEP.pending2ndAgent : 0;
          this.sepSummary.pendingcustomer = respData.SEP ? respData.SEP.pendingCusSubmission : 0;
          this.sepSummary.pendingToMap = respData.SEP ? respData.SEP.pendingToMap : 0;
          const sepPendingAgent = this.sepSummary.pendingAgent ? this.sepSummary.pendingAgent : 0;
          const sepPendingCust = this.sepSummary.pendingcustomer ? this.sepSummary.pendingcustomer : 0;
          const sepPendingToSend = this.sepSummary.pendingToMap ? this.sepSummary.pendingToMap : 0;
          this.sepSummary.allPendingCount = sepPendingAgent + sepPendingCust + sepPendingToSend;

          this.productTypeCount = {
            pa: this.paSummary,
            tvc: this.tvcSummary,
            svc: this.svcSummary,
            er: this.erSummary,
            sep: this.sepSummary,
          };
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  quickGuide() {
    this.cdsPopup.open(IffCompletionStepsPopupComponent, {
      size: 'sm',
    });
  }

  prodTypeChange(value: string) {
    this.prodTypeFormControl.setValue(value);
    sessionStorage.setItem('productType', value);
  }

  tabIndexChange(index: number) {
    sessionStorage.setItem('md-iff-summary-tabIndex', String(index));
  }
}
