<div class="row l-plr-0 l-pt-1">
  <div class="col-xs-12 col-md-12 l-d-f">
    <div class="cds-h2-light l-ma-0 l-pr-7">
      {{ 'calendar.event.list' | lang }}
    </div>
    <app-permission [role]="permissionAccess.W" [permission]="permissionItem.SYS_CALENDAR" class="l-d-f l-ai-cen l-jc-cen">
      <div class="l-d-f l-ai-cen l-jc-cen l-pl-9 l-pr-9 card" (click)="addNewEvent()" style="cursor: pointer">
        <div class="icon-add">
          <cds-icon size="sm" color="white" icon="action:button_plus"></cds-icon>
        </div>
        <span class="font-bold l-pl-3">{{ 'calendar.action.add' | lang }}</span>
      </div>
    </app-permission>
  </div>
  <div class="col-xs-12 col-md-12 l-d-f l-fd-col l-pt-5">
    <div class="cds-body2">{{ 'calendar.action.choose' | lang }}</div>
    <div class="l-d-f l-fd-row">
      <div *ngFor="let item of eventTypes" class="card-type" [class.card-type-selected]="item === eventTypeSelect" (click)="eventTypeChange(item)">
        <div>{{ item.title | lang }}</div>
      </div>
    </div>
  </div>
  <form [formGroup]="searchForm" class="col-xs-12 col-md-12 l-d-f l-fw-w">
    <div class="l-pt-5 input-width" *ngIf="isShowSearchDatePicker">
      <div>
        <label class="cds-textfield-label cds-detail1 font-label">
          {{ 'calendar.event.from' | lang }}
        </label>
      </div>
      <div>
        <cds-textfield placeholder="DD/MM/YYYY" [cdsDatepicker]="picker1" ngDefaultControl formControlName="fromDate"> </cds-textfield>
        <cds-datepicker #picker1="cdsDatepicker"></cds-datepicker>
      </div>
      <cds-assistive-text *ngIf="fromDate?.errors?.['error']" class="l-pt-3">
        {{ fromDate.errors?.['error'] | lang }}
      </cds-assistive-text>
      <cds-assistive-text *ngIf="searchForm.errors?.['error']" class="l-pt-3">
        {{ searchForm.errors?.['error'] | lang }}
      </cds-assistive-text>
    </div>
    <div class="l-d-f l-ai-fs l-jc-cen l-pt-8 l-pl-4 l-pr-4">-</div>
    <div class="l-pt-5 l-pr-4 input-width" *ngIf="isShowSearchDatePicker">
      <div>
        <label class="cds-textfield-label cds-detail1 font-label">{{ 'calendar.event.to' | lang }}</label>
      </div>
      <div>
        <cds-textfield placeholder="DD/MM/YYYY" [cdsDatepicker]="picker2" ngDefaultControl formControlName="toDate"> </cds-textfield>
        <cds-datepicker #picker2="cdsDatepicker"></cds-datepicker>
      </div>
      <cds-assistive-text *ngIf="toDate?.errors?.['error']" class="l-pt-3">
        {{ toDate.errors?.['error'] | lang }}
      </cds-assistive-text>
    </div>
    <div class="l-pt-5 l-pr-4 input-width" *ngIf="eventTypeSelect.key === eventTypeKey.PROC_DATE">
      <div>
        <label class="cds-textfield-label cds-detail1 font-label">
          {{ 'calendar.event.reportNo' | lang }}
        </label>
      </div>
      <div>
        <cds-textfield placeholder="{{ 'calendar.event.reportNo' | lang }}" ngDefaultControl formControlName="reportNo"> </cds-textfield>
      </div>
      <cds-assistive-text class="l-pt-3" *ngIf="reportNo?.errors">
        {{reportNo.errors?.['error'] | lang}}
      </cds-assistive-text>
    </div>
    <div class="l-pt-5 l-pr-4">
      <div class="l-d-f l-pl-0 l-pt-2">
        <cds-button size="sm" [disabled]="isSearchDisabled" (click)="search()">
          {{ 'common.search' | lang }}
        </cds-button>
        <cds-button class="l-ml-3" [disabled]="!isResetDisable" size="sm" [style]="'secondary'" (click)="reset()">
          {{ 'common.reset' | lang }}
        </cds-button>
        <div class="cursor-pointer l-d-f l-as-cen l-ml-3" (click)="download()">
          <cds-icon icon="arrow:download" [config]="{ color: '#00A758' }"></cds-icon>
          <span class="font-bold l-pl-2 font-color">{{ 'calendar.download' | lang }}</span>
        </div>
      </div>
    </div>
  </form>

  <div
    class="col-xs-12 col-md-12 l-pt-4 l-pb-1"
    *ngIf="eventTypeSelect.key === eventTypeKey.COMMISSION && approveDeatil?.createUser && approveDeatil?.approveUser">
    <p>
      <span>
        {{ 'calendar.request.time' | lang }}
        {{ approveDeatil.createTime | ssmsDate }},{{ 'calendar.request.by' | lang }} {{ approveDeatil.createUser }}</span
      ><span class="l-ml-4 l-mr-4">|</span>
      <span>
        {{ 'calendar.approve.time' | lang }}
        {{ approveDeatil.approveTime | ssmsDate }},{{ 'calendar.approve.by' | lang }} {{ approveDeatil.approveUser }}</span
      >
    </p>
  </div>

  <app-data-table
    class="col-xs-12 col-md-10 col-lg-6 l-pt-5 l-plr-0"
    [frontPagination]="true"
    [isLoading]="loading"
    [overlay]="true"
    [fixed]="true"
    [pageConfig]="pageConfig"
    [dataSource]="dataDisplayed"
    (reloadTable)="pageChange()"
    [columnsConfig]="columnsConfig">
    <ng-template appDtBodyCellDef="eventDateStr" let-element>
      <div>
        {{ element['eventDateStr'] }}
      </div>
    </ng-template>
    <ng-template appDtBodyCellDef="eventStartDate" let-element>
      <div>
        {{ element['eventStartDate'] ? element['eventStartDate'] : '/' }}
      </div>
    </ng-template>
  </app-data-table>
</div>
<app-cd-download-progress [show]="isDownLoading"></app-cd-download-progress>
