<div class="high-risk-file-upload-center">
  <div class="title-box">
    <span class="cds-h4-demibold">High Risk File Upload Center</span>
  </div>

  <div class="content">
    <div class="search-box l-mt-3">
      <app-cd-dropdown
        style="flex: 1"
        [placeholder]="'Choose File type'"
        [selected]="fileTypeControl.value"
        (selectedChange)="fileTypeChange($event)"
        [toppingList]="highRiskFileTypeOptions"></app-cd-dropdown>
      <div>
        <cds-button [size]="'sm'" (click)="uploadFile()">Upload</cds-button>
      </div>
    </div>

    <div class="l-ml-7 l-mt-7 file-list-text-box">
      <span class="cds-body-hero-light">File list</span>
    </div>

    <div *ngIf="isShowProgress && dataSource.length === 0" class="progress-box l-d-f l-jc-cen">
      <cds-progress mode="indeterminate" [diameter]="60" [type]="'ring'" [text]="'Loading'" [color]="'ml'"></cds-progress>
    </div>
    <app-no-result-found [style.display]="dataSource.length === 0 && !isShowProgress ? 'block' : 'none'"></app-no-result-found>
    <div [style.display]="dataSource.length > 0 ? 'unset' : 'none'" class="row l-plr-0">
      <div [ngStyle]="{ 'overflow-x': isShowProgress ? 'auto' : 'unset' }">
        <div class="result-table" [ngStyle]="{ width: resultTableIsFitContent ? 'fit-content' : 'unset' }">
          <div class="table-box" [ngClass]="[isShowProgress ? 'loading' : '']">
            <cds-table #filesTable [dataSource]="dataSource">
              <ng-container cdsColumnDef="fileName">
                <cds-header-cell *cdsHeaderCellDef class="file-name-column-width">File Name</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="file-name-column-width">
                  <div class="l-d-f l-ai-cen l-jc-sb">
                    <div>
                      <span>{{ element.fileName }}</span>
                    </div>
                    <div class="latest-icon" *ngIf="element.isLatest === 'Y'">
                      <span>LATEST</span>
                    </div>
                  </div>
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="uploadDate">
                <cds-header-cell *cdsHeaderCellDef class="report-date-column-width">Upload Date</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="report-date-column-width">
                  {{ element.createdAt | date : 'dd/MM/yyyy' }}
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="uploadBy">
                <cds-header-cell *cdsHeaderCellDef class="report-date-column-width">Upload By</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="report-date-column-width">
                  {{ element.createdBy }}
                </cds-cell>
              </ng-container>

              <ng-container cdsColumnDef="download" stickyEnd>
                <cds-header-cell *cdsHeaderCellDef class="action-column-width">Download</cds-header-cell>
                <cds-cell *cdsCellDef="let element" class="action-column-width">
                  <div [ngStyle]="{ 'pointer-events': isDownloading ? 'none' : 'unset' }" (click)="downloadFile(element)" class="l-d-f l-ai-cen download">
                    <div>
                      <cds-icon icon="arrow:download" class="icon-arrow-download"></cds-icon>
                    </div>
                    <div class="l-ml-2">
                      <span class="cds-h6-demibold" style="color: var(--cds-color-m-green)">Download</span>
                    </div>
                  </div>
                </cds-cell>
              </ng-container>

              <cds-header-row *cdsHeaderRowDef="displayedColumns"></cds-header-row>
              <cds-row *cdsRowDef="let row; columns: displayedColumns"></cds-row>
            </cds-table>
          </div>
          <div *ngIf="isShowProgress" class="progress-box">
            <cds-progress mode="indeterminate" [diameter]="60" [type]="cdsProgressType" [text]="cdsProgressTxt" [color]="cdsProgressColor"></cds-progress>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row result-table-footer" [style]="{ 'max-width': resultTableFooterMaxWidth + 'px' }">
      <div class="col-sm-6 col-xs-12 footer-pagination">
        <cds-pagination [color]="'ml'" [pageCount]="pageCount" [page]="currentPage" (pageChange)="pageChange($event)"></cds-pagination>
      </div>
      <div class="col-sm-6 col-xs-12 page-search">
        <span>Page </span>
        <input class="input" type="number" [value]="currentPage" #pageNoSearchBox (keydown.enter)="searchByInputPageNo($event, pageNoSearchBox.value)" />
        <span> of {{ pageCount }} </span>
      </div>
    </div> -->
  </div>

  <div class="download-progress" *ngIf="isDownloading">
    <div class="l-d-f l-ai-cen font-weight-6">
      <cds-icon class="l-mr-4" size="md" icon="form:save_file"></cds-icon>
      <div class="l-fg-1 l-pb-2">
        <div class="l-mb-3">File Downloading...</div>
        <cds-progress type="bar" [value]="percentComplete.value"></cds-progress>
      </div>
    </div>
    <cds-icon (click)="closeDownload()" size="md" style="cursor: pointer" icon="action:button_x"></cds-icon>
  </div>
</div>
