import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { AdminLayoutComponent } from '../layout/admin-layout/admin-layout.component';
import { AgentConfirmationComponent } from './agent-confirmation/agent-confirmation.component';
import { SplitAgentNotificationInfoResolveService } from './agent-confirmation/split-agent-notification-info-resolve/split-agent-notification-info-resolve.service';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { CaseSummaryComponent } from './case-summary/case-summary.component';
import { ErSendIffInBatchSummaryComponent } from './er-send-iff-in-batch-summary/er-send-iff-in-batch-summary.component';
import { ProductTypeSummaryComponent } from './product-type-summary/product-type-summary.component';
import { SendIffInBatchSummaryComponent } from './send-iff-in-batch-summary/send-iff-in-batch-summary.component';
import { CaseDetailsErComponent } from './case-details-er/case-details-er.component';
import { SalesJourneyGuard } from './salesjourney.guard';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { EditAgentDetailsComponent } from './edit-agent-details/edit-agent-details.component';
import { PaperSummaryComponent } from './paper-summary/paper-summary.component';
import { WsmCaseSummaryComponent } from './wsm-case-summary/wsm-case-summary.component';
import { IffDeclinedDashboardComponent } from './iff-declined-dashboard/iff-declined-dashboard.component';
import { SelectProductTypeComponent } from './iff-paper-form/select-product-type/select-product-type.component';
import { IndividualsComponent } from './iff-paper-form/individuals/individuals.component';
import { EmployerComponent } from './iff-paper-form/employer/employer.component';
import { SalesSupportSummaryComponent } from './sales-support-summary/sales-support-summary/sales-support-summary.component';
import { MdIffSummaryComponent } from './md-iff-summary/md-iff-summary.component';
import { EmpfCaseDetailsComponent } from './empf-case-details/empf-case-details.component';
import { ErEmpfCaseDetailsComponent } from './er-empf-case-details/er-empf-case-details.component';
import { IndividualComponent } from './generate-md/individual/individual.component';
import { EmployerForMdComponent } from './generate-md/employer-for-md/employer-for-md.component';
import { SelectProductTypeForMdComponent } from './generate-md/select-product-type-for-md/select-product-type-for-md.component';
import { MdIffSelectProductTypeComponent } from './md-iff-summary/md-iff-select-product-type/md-iff-select-product-type.component';

const routes: Routes = [
  {
    path: 'salesjourney',
    component: AdminLayoutComponent,
    canActivate: [SalesJourneyGuard],
    children: [
      {
        path: 'product-type-summary/select-product-type',
        component: ProductTypeSummaryComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'product-type-summary/case-summary',
        component: CaseSummaryComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'split-agent-notification',
        component: AgentConfirmationComponent,
        resolve: {
          splitAgentNotificationInfo: SplitAgentNotificationInfoResolveService,
        },
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'case-details/:salesJourneySummariesId/:empfRefNo',
        component: CaseDetailsComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SUPER_USER]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'send-iff-in-batch-summary',
        component: SendIffInBatchSummaryComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'er-send-iff-in-batch-summary',
        component: ErSendIffInBatchSummaryComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'case-details-er/:salesJourneySummariesId/:empfRefNo',
        component: CaseDetailsErComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SUPER_USER]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'super-admin',
        component: SuperAdminComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SUPER_USER]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'edit-agent-details/:mdNo',
        component: EditAgentDetailsComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'app-paper-summary',
        component: PaperSummaryComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_BRANCH_LEVEL]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'wsm-case-summary',
        component: WsmCaseSummaryComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SUPER_USER]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'iff-declined-dashboard',
        component: IffDeclinedDashboardComponent,
        data: {
          roles: {
            [PermissionItem.CUSTOMER_MEMBER]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'iff-paper-form/select-product-type',
        component: SelectProductTypeComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'iff-paper-form/individuals',
        component: IndividualsComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'iff-paper-form/employer',
        component: EmployerComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'sales-support-summary',
        component: SalesSupportSummaryComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'md-iff-summary/select-product-type',
        component: MdIffSelectProductTypeComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'support-md-iff-summary',
        component: MdIffSummaryComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'md-iff-summary',
        component: MdIffSummaryComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'empf-case-details/:no',
        component: EmpfCaseDetailsComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'er-empf-case-details/:no',
        component: ErEmpfCaseDetailsComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SALES_SUPPORT]: PermissionAccess.R,
            [PermissionItem.SALES_JOURNEY_SUPER_ADMIN]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'generate-md/select-product-type',
        component: SelectProductTypeForMdComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'generate-md/individual',
        component: IndividualComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
      {
        path: 'generate-md/employer',
        component: EmployerForMdComponent,
        data: {
          roles: {
            [PermissionItem.SALES_JOURNEY_AGENT]: PermissionAccess.R,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [SalesJourneyGuard],
})
export class SalesjourneyRoutingModule {}
