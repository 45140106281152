<app-cd-page-title title="{{ 'commission.apeJournal.title' | lang }}"> </app-cd-page-title>
<div class="row">
  <div class="col-xs-12 l-mb-8">
    <div class="cds-h2-light l-pt-7 l-mb-7">
      {{ 'commission.pool.common.searchRecord' | lang }}
    </div>
    <form [formGroup]="formGroup" (ngSubmit)="search()">
      <div class="l-pb-2 div-border-bottom l-mb-5">
        <span class="cds-h4 cds-demibold cds-green-text">{{ 'common.filterSearch' | lang }} </span>
      </div>
      <p class="cds-body2-light cds-coral-text dark-1 l-mb-5 l-mt-5">
        {{ 'common.fillIn' | lang }}
      </p>
      <div>
        <div class="row l-plr-0 l-pb-4">
          <div class="cds-h5 col-xs-12 col-md-12 l-plr-0">
            {{ 'common.dateRange' | lang }}
          </div>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <label class="cds-body2 font-color-light3 l-d-ib l-mb-3" style="padding-bottom: 3px">{{ 'commission.apeJournal.dateType' | lang }}</label>
            <cds-dropdown [config]="dateTypeConfig" [formControl]="dateType"></cds-dropdown>
          </cds-form-field>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <div *ngIf="isShowSearchDatePicker">
              <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">
                {{ 'commission.common.runDateFrom' | lang }}
                <span class="cds-coral-text dark-1">*</span>
              </label>
              <cds-textfield placeholder="DD/MM/YYYY" [cdsDatepicker]="fromDateFromPicker" [formControl]="runDateFrom"> </cds-textfield>
              <cds-datepicker #fromDateFromPicker="cdsDatepicker"></cds-datepicker>
            </div>
            <cds-assistive-text *ngIf="runDateFrom.errors && runDateFrom.dirty">{{ runDateFrom.errors['error'] | lang }}</cds-assistive-text>
            <cds-assistive-text *ngIf="formGroup.errors && formGroup.dirty && !runDateFrom.errors">{{ formGroup.errors['error'] | lang }}</cds-assistive-text>
          </cds-form-field>

          <div class="l-pr-4 l-pt-7 desktop-md-hide">-</div>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <div *ngIf="isShowSearchDatePicker">
              <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">
                {{ 'commission.common.runDateTo' | lang }}
                <span class="cds-coral-text dark-1">*</span>
              </label>
              <cds-textfield placeholder="DD/MM/YYYY" [cdsDatepicker]="fromDateToPicker" [formControl]="runDateTo"> </cds-textfield>
              <cds-datepicker #fromDateToPicker="cdsDatepicker"></cds-datepicker>
            </div>
            <cds-assistive-text *ngIf="runDateTo.errors && runDateTo.dirty">{{ runDateTo.errors['error'] | lang }}</cds-assistive-text>
          </cds-form-field>
        </div>

        <div class="row l-plr-0 l-pb-6">
          <div class="cds-h5 col-xs-12 col-md-12 l-plr-0">
            {{ 'commission.journal.criteriaType' | lang }}
          </div>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <app-cd-autocomplete
              placeholder="commission.common.accountType.placeholder"
              label="commission.common.accountType"
              [control]="accountType"
              [options]="accountTypeOptions"></app-cd-autocomplete>
          </cds-form-field>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <app-cd-autocomplete
              placeholder="commission.common.transactionType.placeholder"
              label="commission.common.transactionType"
              [control]="transactionType"
              [options]="transactionTypesOptions"></app-cd-autocomplete>
          </cds-form-field>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <app-cd-autocomplete
              placeholder="commission.common.channel.placeholder"
              label="commission.common.channel"
              [control]="agentChannel"
              [options]="channelOptions"></app-cd-autocomplete>
          </cds-form-field>
        </div>

        <div class="row l-plr-0 l-pb-5">
          <div class="cds-h5 col-xs-12 col-md-12 l-plr-0">
            {{ 'commission.common.specificNo' | lang }}
          </div>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'commission.common.accountNo' | lang }}</label>
            <cds-textfield [placeholder]="'commission.common.accountNo.placeholder' | lang" [formControl]="employerAccountCode"></cds-textfield>
            <cds-assistive-text *ngIf="employerAccountCode.errors && employerAccountCode.dirty">{{
              employerAccountCode.errors['error'] | lang
            }}</cds-assistive-text>
          </cds-form-field>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'commission.common.memberNo' | lang }}</label>
            <cds-textfield [placeholder]="'commission.common.placeholder.memberNo' | lang" [formControl]="memberAccountCode"> </cds-textfield>
            <cds-assistive-text *ngIf="memberAccountCode.errors && memberAccountCode.dirty">{{ memberAccountCode.errors['error'] | lang }}</cds-assistive-text>
          </cds-form-field>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-4 l-pb-4">
            <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'commission.common.agentCode' | lang }}</label>
            <cds-textfield [placeholder]="'commission.common.agentCode.placeholder' | lang" [formControl]="agentCode"> </cds-textfield>
            <cds-assistive-text *ngIf="agentCode.errors && agentCode.dirty">{{ agentCode.errors['error'] | lang }}</cds-assistive-text>
          </cds-form-field>
        </div>
      </div>

      <div class="row l-plr-0 l-pb-3">
        <div class="col-xs-12 col-md-6 col-lg-3 l-plr-0">
          <div class="search-area-btn">
            <cds-button type="submit" [disabled]="searchDisabled" [style]="'primary'" size="md">{{ 'common.search' | lang }} </cds-button>
            <cds-button class="l-ml-3" (click)="reset()" [style]="'secondary'" size="md">{{ 'common.reset' | lang }} </cds-button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-xs-12" *ngIf="(previousSearchParams || loading) && !isOverMax">
    <app-cd-table-title class="l-pt-3 l-pb-3" [title]="'common.recordList' | lang" [total]="totalElements">
      <ng-template #tableAction>
        <cds-button (click)="download()" [disabled]="downloadDisabled" [style]="'secondary'" size="sm">
          <span>{{ 'common.download' | lang }}</span>
        </cds-button>
      </ng-template>
    </app-cd-table-title>
    <app-data-table
      [frontPagination]="false"
      [fixed]="false"
      [isLoading]="loading"
      (reloadTable)="pageChange($event)"
      [pageConfig]="pageConfig"
      [dataSource]="dataDisplayed"
      [columnsConfig]="columnsConfig">
      <ng-template appDtBodyCellDef="runDate" let-element>
        <span class="line-no-wrap">{{ element['runDate'] }}</span>
      </ng-template>
      <ng-template appDtHeaderCellDef="pacFlag" let-element>
        <span style="width: 120px; white-space: pre-line">{{ element['title'] | lang }}</span>
      </ng-template>
      <ng-template appDtBodyCellDef="txnEffectiveDate" let-element>
        <span class="line-no-wrap">{{ element['txnEffectiveDate'] }}</span>
      </ng-template>
    </app-data-table>
  </div>
</div>
<app-cd-download-progress [show]="isDownLoading || isDownLoadingPdf"></app-cd-download-progress>
