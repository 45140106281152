<app-employer-profile-title
  [employerProfileDetail]="employerProfileDetail"
  [showErInfoBtn]="true"
  [detailShow]="true"
  [payrollGroup]="billingClass"
  (stickyStatusChange)="showPlaceholderHeight($event)"></app-employer-profile-title>

<div *ngIf="isHeaderSticky" class="placeholder-height"></div>

<div class="row">
  <div class="col-xs-6 l-pt-6 l-plr-0">
    <cds-dropdown [(ngModel)]="billingClass" [config]="dropdownConfig" (cdsChange)="selectChange($event)"></cds-dropdown>
  </div>
</div>
<div class="row l-pt-5">
  <div class="col-xs-12 row middle-xs basic-pad">
    <div class="l-mr-2">
      <cds-icon color="#282B3E" width="20px" height="20px" icon="form:document"></cds-icon>
    </div>
    <div>
      <span class="cds-h5-demibold">{{ 'payroll.basic-information' | lang }}</span>
    </div>
  </div>
  <div class="col-xs-12 row bg-white l-pt-2 l-pb-2">
    <div class="col-xs row l-pl-5 l-pr-5">
      <div class="col-xs-12 l-pt-2 l-pb-2 l-pl-3">
        <span class="cds-body1-demibold">{{ 'payment.address' | lang }}</span>
      </div>
      <div class="row col-xs-12 l-plr-0 l-pt-2 l-pb-2">
        <div class="col-xs-6 l-mb-4" *ngFor="let item of billingClass?.addressList">
          <div class="row l-plr-0">
            <div class="l-mr-2 l-mt-1">
              <cds-icon color="#282B3E" icon="contact:location"></cds-icon>
            </div>
            <div class="col-xs l-plr-0">
              <div class="col-xs row l-plr-0 cds-color-navy-light3 middle-xs">
                <div class="l-mr-2">
                  <span class="cds-body2 navy">{{ addressTypeRender | fn : item.addressType }}</span>
                </div>
                <app-tooltip-icon *ngIf="item.addressType === ErAddressTypeE.REGISTERED" tooltipString="BR Address"></app-tooltip-icon>
              </div>
              <div class="l-mb-0">
                <div class="cds-body1 l-mb-0" *ngIf="item.zhAddrFlg === 'Y' && item.formatTyp === 'STD'; else ascAddress">
                  <span *ngIf="item.country">{{ item.country }}</span>
                  <span *ngIf="item.city">{{ item.city }}</span>
                  <span *ngIf="item.district">{{ item.district }}</span>
                  <span *ngIf="item.street">{{ item.street }}</span>
                  <span *ngIf="item.building">{{ item.building }}</span>
                  <span *ngIf="item.room">{{ item.block }}座</span>
                  <span *ngIf="item.floor">{{ item.floor }}樓</span>
                  <span *ngIf="item.room">{{ item.room }}室</span>
                </div>
                <ng-template #ascAddress>
                  <div class="cds-body1 l-mb-0">
                    <span>{{ addressRender | fn : item }}</span>
                  </div>
                </ng-template>
              </div>

              <div class="l-mb-2">
                <div class="cds-detail1 cds-color-navy-light3 l-mb-0">Remarks</div>
                <div class="cds-body1 l-mb-0 l-mb-2">{{ item.remarks }}</div>
                <div class="status-area">
                  <app-icon-status [value]="item.statusCode" [statusMapObj]="inValidStatusMapObj"></app-icon-status>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 p-line"></div>

      <app-row-item
        label="er.profile.01.lives"
        toolTipString="Total no. of Lives under the Billing Class"
        [data]="billingClass?.lives | number"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>

      <app-row-item
        label="payment.contribution-frequency"
        [data]="contributionFrequencyRender | fn : billingClass?.contributionFrequency"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3"></app-row-item>

      <app-row-item
        label="er.profile.effective-date"
        [data]="(billingClass?.effDate | ssmsDate) + (billingClass?.effDate && billingClass?.termDate ? '-' : '') + (billingClass?.termDate | ssmsDate)"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item>
      <!-- <app-row-item label="er.profile.effective-end-date" data="{{ billingClass?.termDate | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item> -->
      <!-- <app-row-item label="er.profile.01.bill-hit" data="{{ billingClass?.billHit | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="er.profile.01.stmt-hit" data="{{ billingClass?.stmtHit | ssmsDate }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="er.profile.01.line-bussiness" data="{{ billingClass?.lineOfBusiness }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item> -->
      <!-- <app-row-item
        label="er.profile.01.cont-sig"
        toolTipString="For MPF, always 'SPECIFIED/' For ORSO, can be 'Billed' or 'Specified'"
        data="{{ billingClass?.contSig }}"
        class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item> -->
      <!-- <app-row-item label="er.profile.01.mcn" toolTipString="For ORSO only" data="{{ billingClass?.mcn }}" class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
      </app-row-item> -->
    </div>
  </div>
</div>

<div class="row p-tab l-pt-8 l-plr-0" *ngIf="permissionChecked">
  <div class="col-xs-12">
    <cds-tab-group [selectedIndex]="tabIndex" (selectedTabIndexChange)="selectedTabIndexChange($event)">
      <cds-tab label="Scheme Info">
        <app-scheme-info *ngIf="ifLoadTab | fn : activedTabList : 'schemeInfo'" [billingClass]="billingClass" [employerId]="_id"> </app-scheme-info>
      </cds-tab>
      <cds-tab label="Billing Info">
        <app-payment-details
          *ngIf="ifLoadTab | fn : activedTabList : 'billingInfo'"
          [payrollGroupId]="billingClass?.payrollGroupId"
          [employerId]="_id"
          [isStaff]="isStaff"></app-payment-details>
      </cds-tab>
      <cds-tab label="Account Info">
        <app-account-info *ngIf="ifLoadTab | fn : activedTabList : 'accountInfo'" [billingClass]="billingClass" [employerId]="_id"> </app-account-info>
      </cds-tab>
      <cds-tab label="Payment Record" *ngIf="isStaff">
        <app-payment-record *ngIf="ifLoadTab | fn : activedTabList : 'paymentRecord'" [payrollGroupId]="billingClass?.payrollGroupId" [employerId]="_id">
        </app-payment-record>
      </cds-tab>
      <cds-tab label="Account Balance" *ngIf="isStaff">
        <app-suspense-record
          *ngIf="ifLoadTab | fn : activedTabList : 'accountBalance'"
          [payrollGroupId]="billingClass?.payrollGroupId"
          [id]="_id"></app-suspense-record>
      </cds-tab>
      <cds-tab label="Vesting & VC Rules" *ngIf="isStaff">
        <app-vesting-scale *ngIf="ifLoadTab | fn : activedTabList : 'vestingRule'" [id]="_id" [billingClass]="billingClass"></app-vesting-scale>
      </cds-tab>
      <cds-tab label="eMPF Workflow" *ngIf="isStaff">
        <app-empf-workflow
          *ngIf="ifLoadTab | fn : activedTabList : 'empfWorkflow'"
          [id]="_id"
          [payrollGroupId]="billingClass?.payrollGroupId"
          [isEr]="true"></app-empf-workflow>
      </cds-tab>
      <cds-tab label="Charge Infomation" *ngIf="isStaff && _typeShow">
        <app-charge-info
          *ngIf="ifLoadTab | fn : activedTabList : 'chargeInfo'"
          [payrollGroupId]="billingClass?.payrollGroupId"
          [employerId]="_id"></app-charge-info>
      </cds-tab>
      <cds-tab label="Formula Class" *ngIf="isStaff && _typeShow">
        <app-formula-class
          *ngIf="ifLoadTab | fn : activedTabList : 'formulaClass'"
          [payrollGroupId]="billingClass?.payrollGroupId"
          [employerId]="_id"></app-formula-class>
      </cds-tab>
    </cds-tab-group>
  </div>
</div>

<app-back-top></app-back-top>
