import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsNavigationModule } from '@cds/ng-web-components/navigation';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';

import { CalendarModule as CM, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { SharedModule } from 'src/app/shared/shared.module';

import { DayCardComponent } from './day-card.component';
import { CalendarComponent } from './calendar/calendar.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EventEditListComponent } from './event-edit-list/event-edit-list.component';
import { EventCheckComponent } from './event-check/event-check.component';
import { EventAddComponent } from './event-add/event-add.component';
import { AddCommissionEventComponent } from './event-add/add-commission-event/add-commission-event.component';
import { ReviewCommissionEventComponent } from './event-pending-review/review-commission-event/review-commission-event.component';
import { EventDeclinedComponent } from './event-declined/event-declined.component';
import { EditDeclinedCommissionComponent } from './event-declined/edit-declined-commission/edit-declined-commission.component';
import { EventPendingReviewComponent } from './event-pending-review/event-pending-review.component';
import { ReviewReportNumberComponent } from './event-pending-review/review-report-number/review-report-number.component';

@NgModule({
  declarations: [
    DayCardComponent,
    CalendarComponent,
    EventDetailsComponent,
    EventEditListComponent,
    EventCheckComponent,
    EventAddComponent,
    AddCommissionEventComponent,
    ReviewCommissionEventComponent,
    EventDeclinedComponent,
    EditDeclinedCommissionComponent,
    EventPendingReviewComponent,
    ReviewReportNumberComponent,
  ],
  imports: [
    CM.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    // CalendarRoutingModule,
    CdsButtonModule,
    CdsTextfieldModule,
    CdsLanguageModule,
    CdsIconModule,
    CdsNavigationModule,
    CdsPaginationModule,
    CdsTableModule,
    CdsCheckboxModule,
    CdsSearchModule,
    CdsPopupModule,
    CdsFormFieldModule,
    CdsAssistiveTextModule,
    CdsDropdownModule,
    CdsProgressModule,
    CdsSortModule,
    CdsDatepickerModule,

    MatTreeModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    MatMenuModule,
    MatDatepickerModule,
    MatAutocompleteModule,

    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CalendarModule {
  constructor() {}
}
