<div class="cds-h4-demibold out-common-title no-padding margin-top-common row bold l-pb-6">
  <div class="col-xs-12">{{ 'ee.profile.detail.plus.title' | lang }}</div>
</div>
<app-cd-accordion *ngFor="let item of dataSource; index as i">
  <app-cd-accordion-panel
    [title]="(item?.memberAccountType || '') + ':' + (item?.id || '')"
    [longTitle]="false"
    [right]="service.getStatus | fn : item?.status : employeeStatusMapObj"
    [statusColor]="service.getStatusColor | fn : item?.status : employeeStatusMapObj">
    <div class="col-xs-12 row l-plr-0">
      <app-row-item label="ee.origin-member-account-code" [data]="item?.originalMemberAccountCode" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.status-change-date" [data]="item?.statusChangeDate | ssmsDate" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.member-account-type" [data]="item?.memberAccountType" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="ee.default-pa" [checked]="item?.defaultPA === 'Y'" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    </div>
    <div class="col-xs-12 l-mt-5">
      <div class="button-area">
        <div class="div-icon">+</div>
        <span class="l-ml-2" (click)="goToDetail(item?.id)">View More</span>
      </div>
    </div>
  </app-cd-accordion-panel>
</app-cd-accordion>
