<div class="col-xs-12 col-md-12 l-pt-6 l-d-f l-jc-sb" id="top">
  <div>
    <span class="cds-h2-light l-pt-2 l-d-f l-mb-6">
      {{ 'commission.pool.agent.title' | lang }}
      <cds-icon class="l-pl-1 l-pr-4" size="sm" (click)="alertAgent()" style="cursor: pointer" icon="action:info_1"></cds-icon>
    </span>
  </div>
  <div class="l-pt-1" *ngIf="roleW | permission : permissionN | async">
    <cds-button [config]="btnCfg" size="md" #trigger="matMenuTrigger" appMenuClose="trigger" [matMenuTriggerFor]="menu2">
      <div class="l-d-f">
        Change Request&nbsp;&nbsp;
        <cds-icon size="sm" icon="action:button_down"></cds-icon>
      </div>
    </cds-button>
    <mat-menu #menu2="matMenu" xPosition="before">
      <div class="record-history-option" *ngIf="roleW | permission : permissionN2 | async" (click)="createRecord('add')" mat-menu-item>
        Commission Agent Change
      </div>
      <div class="record-history-option" (click)="createRecord('edit')" mat-menu-item>Update Selling Agent or SA Report Rec’d Date</div>
    </mat-menu>
  </div>
</div>

<cds-progress *ngIf="loading" mode="indeterminate" class="l-pl-6"></cds-progress>

<div *ngIf="!loading">
  <div class="col-xs-12 col-md-12">
    <div class="col-xs-12 col-md-12 l-d-f l-jc-sb record-history">
      <div class="record-history-padding l-fg-1">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-select
            #mySelect
            appSelectCloseDirective="mySelect"
            [(ngModel)]="agentRecord.id"
            (ngModelChange)="getRegularItems()"
            class="record-history-select"
            msInfiniteScroll
            (infiniteScroll)="getRegularList()"
            matNativeControl
            [complete]="scrollPage === totalPages || totalPages === 0">
            <mat-select-trigger class="l-d-f">
              {{ agentRecord.effectiveDate | ssmsDate }}, {{ 'commission.pool.common.createBy' | lang }}&nbsp; {{ agentRecord.createBy.name }}&nbsp;&nbsp;
            </mat-select-trigger>
            <mat-option
              class="record-history-option"
              [disabled]="!item.active"
              [class.disabled]="!item.active"
              *ngFor="let item of recordList; let i = index"
              [value]="item.id">
              <div class="l-d-f l-jc-sb">
                <mat-icon class="l-d-f">
                  {{ item.effectiveDate | ssmsDate }}, {{ 'commission.pool.common.createBy' | lang }}&nbsp; {{ item.createBy.name }}&nbsp;&nbsp; &nbsp;&nbsp;
                </mat-icon>
                <span class="font-grey" *ngIf="item.status === 'CURRENT' && item.active"> Current effective Agent </span>
                <span class="font-grey" *ngIf="item.status === 'DELETED'">
                  Deleted on {{ item.lastUpdateTime | ssmsDate }}, by
                  {{ item.lastUpdateName }}
                </span>
              </div>
            </mat-option>
            <cds-progress *ngIf="scrollLoading" mode="indeterminate" class="l-pl-2"></cds-progress>
          </mat-select>
        </mat-form-field>
        <div style="position: relative; top: -42px" class="cds-body2 cds-dark-navy-text light-3 l-pl-3 font-grey">Agent Effective Date (DD/MM/YYYY)</div>
      </div>
      <div *ngIf="agentRecord.status === status.FUTURE && (roleE | permission : permissionN | async)" class="record-menu" [matMenuTriggerFor]="menu">
        <cds-icon size="sm" icon="action:more_options"></cds-icon>
      </div>
      <mat-menu #menu="matMenu">
        <div class="menu-item" (click)="deleteRecord()">
          <cds-icon size="sm" icon="form:delete"></cds-icon>
          <div class="l-pl-2">
            {{ 'commission.pool.agent.deleteRecord' | lang }}
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="col-xs-12 col-md-12 l-pt-4">
    <div class="row card l-pt-3 l-mt-6 l-pb-3 div-border-top div-border-bottom padding-none" *ngFor="let item of agentItems; let i = index">
      <div class="row col-xs-12 col-md-12 l-pt-3 l-pb-3 l-d-f">
        <div class="col-xs-12 col-md-6 l-d-f">
          <cds-icon size="md" icon="people:login_1"></cds-icon>
          <div class="l-pl-4">
            <span style="font-weight: bold">{{ item.agentCode }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span style="font-weight: bold">{{ item.agentName }}</span>
            <br />
            <span *ngIf="agentRecord.status !== status.FUTURE" class="cds-detail2"
              >{{ 'commission.pool.agent.poolJoinDate' | lang }}&nbsp;&nbsp;{{ item.poolJoinDate | ssmsDate }}</span
            >
            <br />
            <div class="l-pt-2 l-d-f cursor-pointer" (click)="toAdjustmentAmount()" *ngIf="roleW | permission : permissionADdjNormal | async">
              <div class="icon-redirect">
                <cds-icon size="sm" color="white" icon="action:button_right"></cds-icon>
              </div>
              <span class="cds-body2 cds-dark-navy-text light-3 font-weight-6 l-ml-3">Make commission adjustment</span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 l-pl-4 l-pr-8 l-d-f">
          <div class="text-right" style="width: 50%">
            <div class="cds-body2 cds-dark-navy-text light-3 l-mb-1">{{ 'commission.pool.agent.shareHKPC' | lang }}</div>
            <span class="cds-body-hero-demibold" style="color: #00a758"> {{ item.shareHkpc || 0 | mynumber : 3 }} % </span>
          </div>
          <div class="text-right" style="width: 50%">
            <div class="cds-body2 cds-dark-navy-text light-3 l-mb-1">{{ 'commission.pool.agent.shareCommission' | lang }}</div>
            <span class="cds-body-hero-demibold" style="color: #00a758">{{ item.shareCommission || 0 | mynumber : 3 }} %</span>
          </div>
        </div>
      </div>

      <div class="row col-xs-12 col-md-12 l-pl-4 l-pt-4 l-pb-4 div-border-top">
        <div class="col-xs-12 col-md-3 l-pt-2 l-pb-2">
          <div class="cds-body2 cds-dark-navy-text light-3 l-mb-1">{{ 'commission.pool.agent.branch' | lang }}</div>
          {{ item.branch }}
        </div>
        <div class="col-xs-12 col-md-3 l-pt-2 l-pb-2">
          <div class="cds-body2 cds-dark-navy-text light-3 l-mb-1">{{ 'commission.pool.agent.sellingAgent' | lang }}</div>
          <cds-checkbox [(ngModel)]="item.sellingAgent" [disabled]="true"></cds-checkbox>
        </div>
        <div class="col-xs-12 col-md-3 l-pt-2 l-pb-2">
          <div class="cds-body2 cds-dark-navy-text light-3 l-mb-1">{{ 'commission.pool.agent.type' | lang }}</div>
          {{ item.type }}
        </div>
        <div class="col-xs-12 col-md-3 l-pt-2 l-pb-2">
          <div class="cds-body2 cds-dark-navy-text light-3 l-mb-1">{{ 'commission.pool.agent.saReportDate' | lang }}</div>
          {{ item.saReportDate | ssmsDate }}
        </div>
      </div>

      <ng-container>
        <div class="col-xs-12 col-md-12 l-pl-4 l-pb-4 div-border-top">
          <div class="col-xs-12 col-md-12 l-pt-6 l-pb-3 l-d-f">
            <span class="cds-h4-demibold">{{ 'commission.pool.agent.agentAdjustment' | lang }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
        <div class="col-xs-12 col-md-12">
          <div class="row l-plr-0">
            <div class="col-xs-12 col-md-4 l-pb-4 l-pl-4 l-pr-8">
              <span class="cds-h4-light">CYTD<br /><br /></span>
              <table>
                <ng-container *ngFor="let detail of item.details; let j = index">
                  <tr class="l-pt-2 l-pb-2">
                    <td class="l-pt-1 l-pb-1 cds-body2 cds-dark-navy-text light-3" width="100%">{{ detail.label }}&nbsp;CYTD</td>
                    <td *ngIf="!detail.toFixedTwo" class="l-pt-1 l-pb-1">{{ detail.cytd || 0 | mynumber }}</td>
                    <td *ngIf="detail.toFixedTwo" class="l-pt-1 l-pb-1">{{ detail.cytd || 0 | mynumber : 2 }}</td>
                  </tr>
                </ng-container>
              </table>
            </div>
            <div class="col-xs-12 col-md-4 l-pb-4 l-pl-border l-pr-8">
              <span class="cds-h4-light">PTD<br /><br /></span>
              <table>
                <ng-container *ngFor="let detail of item.details; let j = index">
                  <tr class="l-pt-2 l-pb-2">
                    <td class="l-pt-1 l-pb-1 cds-body2 cds-dark-navy-text light-3" width="100%">{{ detail.label }}&nbsp;PTD</td>
                    <td *ngIf="!detail.toFixedTwo" class="l-pt-1 l-pb-1">{{ detail.ptd || 0 | mynumber }}</td>
                    <td *ngIf="detail.toFixedTwo" class="l-pt-1 l-pb-1">{{ detail.ptd || 0 | mynumber : 2 }}</td>
                  </tr>
                </ng-container>
              </table>
            </div>
            <div class="col-xs-12 col-md-4 l-pb-4 l-pl-border l-pr-8">
              <span class="cds-h4-light">CAL YTD<br /><br /></span>
              <table>
                <ng-container *ngFor="let detail of item.details; let j = index">
                  <tr>
                    <td class="l-pt-1 l-pb-1 cds-body2 cds-dark-navy-text light-3" width="100%">{{ detail.label }}&nbsp;CAL YTD</td>
                    <td *ngIf="!detail.toFixedTwo" class="l-pt-1 l-pb-1">{{ detail.calytd || 0 | mynumber }}</td>
                    <td *ngIf="detail.toFixedTwo" class="l-pt-1 l-pb-1">{{ detail.calytd || 0 | mynumber : 2 }}</td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="l-pt-6">
      <cds-button [config]="btnCfg" size="md" (click)="backToTop()">{{ 'Back to Top' | lang }} </cds-button>
    </div>
  </div>
</div>
