import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngentRoutingModule } from './angent-routing.module';
import { AgentComponent } from './agent/agent.component';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { LanguageSwitchModule } from 'src/app/shared/language-switch/language-switch.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CdsNavigationModule } from '@cds/ng-web-components/navigation';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CloseChatComponent } from './close-chat/close-chat.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from 'src/app/core/interceptors/auth.http-interceptor';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { TransferChatComponent } from './transfer-chat/transfer-chat.component';
import { CsoCannedMsgComponent } from './cso-canned-msg/cso-canned-msg.component';

@NgModule({
  declarations: [AgentComponent, CloseChatComponent, TransferChatComponent, CsoCannedMsgComponent],
  imports: [
    CommonModule,
    AngentRoutingModule,
    CdsIconModule,
    LanguageSwitchModule,
    CdsLanguageModule,
    FormsModule,
    CdsNavigationModule,
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    CdsButtonModule,
    UiSwitchModule.forRoot({
      size: 'small',
      checkedLabel: 'online',
      uncheckedLabel: 'offline',
    }),
  ],
})
export class AngentModule {}
