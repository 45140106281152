export enum MdStatusValue {
  Pending_MD_eIFF_Record = 0,
  Pending_eMPF_Reference_No = 1,
  Pending_Split_Agent = 10,
  Pending_Customer_Submission = 12,
  Completed = 30,
  Declined = 99,
  Declined_Agent_SEP_Staff_CA = 98,
  Expired = 40,
  Void = 97,
}

export const MD_STATUS = [
  {
    text: 'Pending MD_eIFF Record',
    info: undefined,
    color: 'var(--cds-color-light-1-violet)',
    value: MdStatusValue.Pending_MD_eIFF_Record,
  },
  {
    text: 'Pending eMPF Reference No.',
    info: undefined,
    color: 'var(--cds-color-gold)',
    value: MdStatusValue.Pending_eMPF_Reference_No,
  },
  {
    text: 'Pending Split Agent(s)',
    info: 'The IFF is pending for split agent acknowledgement',
    color: 'var(--cds-color-turquoise)',
    value: MdStatusValue.Pending_Split_Agent,
  },
  {
    text: 'Pending Customer Submission',
    info: 'The IFF is sent and pending for customer submission',
    color: 'var(--cds-color-jh-blue)',
    value: MdStatusValue.Pending_Customer_Submission,
  },
  {
    text: 'Completed',
    info: 'The IFF form is received from customer',
    color: 'var(--cds-color-dark-1-green)',
    value: MdStatusValue.Completed,
  },
  {
    text: 'Declined',
    info: undefined,
    color: 'var(--cds-color-dark-1-coral)',
    value: MdStatusValue.Declined,
  },
  {
    text: 'Declined (Agent SEP/ Staff CA)',
    info: undefined,
    color: 'var(--cds-color-coral)',
    value: MdStatusValue.Declined_Agent_SEP_Staff_CA,
  },
  {
    text: 'Expired',
    info: undefined,
    color: 'var(--cds-color-light-4-dark-navy)',
    value: MdStatusValue.Expired,
  },
  {
    text: 'Void',
    info: undefined,
    color: 'var(--cds-color-dark-navy)',
    value: MdStatusValue.Void,
  },
];
