/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsToastService } from '@cds/ng-web-components/toast';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';
import { RoleService } from 'src/app/core/services/role.service';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
})
export class RoleListComponent implements OnInit {
  permissionAccess = PermissionAccess.W;
  permissionItem = PermissionItem.SYS_ROLE_MANAGE;

  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;
  loading = false;
  overlay = false;
  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = [
    { key: 'roleName', title: 'role.list.table.role' },
    { key: 'userCounts', title: 'role.list.table.noUser' },
  ];

  constructor(private router: Router, private roleService: RoleService, private toast: CdsToastService) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.roleService
      .findAll({
        page: (this.pageConfig.current as number) - 1,
        size: this.pageConfig.pageSize as number,
      })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.overlay = true;
        })
      )
      .subscribe({
        next: res => {
          const data = res.data;
          if (res.result === ResponseResult.SUCCESS && data) {
            this.pageConfig = {
              ...this.pageConfig,
              totalCount: data.totalElements,
            };
            this.dataDisplayed = data.content || [];
          } else {
            this.toast.error(res.message);
          }
        },
        error: err => {
          console.error('Call role-list API error: ', err);
        },
      });
  }

  create() {
    this.router.navigate(['/system/role-create']);
  }

  edit(role: any) {
    this.router.navigate(['/system/role-info'], {
      queryParams: { roleId: role.roleId },
    });
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.getData();
  }
}
