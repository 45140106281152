import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fund-benifit',
  templateUrl: './fund-benifit.component.html',
  styleUrls: ['./fund-benifit.component.scss'],
})
export class FundBenifitComponent implements OnInit {
  _index = 0;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const _index = this.route.snapshot.queryParams['index'];
    if (_index) {
      setTimeout(() => {
        this.selectedTabIndexChange(_index);
      }, 1000);
    }
  }

  selectedTabIndexChange(index: number) {
    console.log('>>>>>>>>>', index);
    this._index = index;
  }
}
