import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-info-details',
  templateUrl: './more-info-details.component.html',
  styleUrls: ['./more-info-details.component.scss'],
})
export class MoreInfoDetailsComponent implements OnInit {
  @Input() id?: string;

  payrollGroupId?: string;

  tabIndex = 0;

  constructor() {}

  ngOnInit(): void {}

  selectedTabIndexChange(value: number) {
    this.tabIndex = value;
  }
}
