import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CdsColorTheme } from '@cds/ng-core/configuration';
import { CdsProgressType } from '@cds/ng-core/progress';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { PermissionService } from 'src/app/core/services/permission.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { DownloadFileService } from 'src/app/shared/download-file/download-file.service';
import { HighRiskFileType, highRiskFileTypeOptions } from '../high-risk-file-upload-center.model';
import { UploadDocumentComponent } from '../upload-document/upload-document.component';

interface AmlHighRiskItem {
  id: number;
  fileType: number;
  fileName: string;
  filePath: string;
  createdAt: string;
  createdBy: string;
  isLatest: string;
}

@Component({
  selector: 'app-high-risk-file-upload-center',
  templateUrl: './high-risk-file-upload-center.component.html',
  styleUrls: ['./high-risk-file-upload-center.component.scss'],
})
export class HighRiskFileUploadCenterComponent implements OnInit, AfterViewInit {
  highRiskFileTypeOptions = highRiskFileTypeOptions;
  fileTypeControl: FormControl = new FormControl();
  isShowProgress = false;
  resultTableIsFitContent = false;
  resultTableFooterMaxWidth = 1280;
  dataSource: AmlHighRiskItem[] = [];
  isDownloading = false;
  displayedColumns: string[] = ['fileName', 'uploadDate', 'uploadBy', 'download'];
  cdsProgressType: CdsProgressType = 'ring';
  cdsProgressTxt = 'Loading';
  cdsProgressColor: CdsColorTheme = 'ml';
  pageCount = 0;
  currentPage = 1;
  percentComplete = { value: 0 };
  closeDownloadEvent: { event: (() => void) | null } = {
    event: null,
  };

  constructor(
    private cdsPopup: CdsPopupService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private downloadFileService: DownloadFileService
  ) {}

  ngOnInit(): void {
    this.getAmlHighRiskList(HighRiskFileType.High_Risk_Nationality);
    this.fileTypeControl.setValue(HighRiskFileType.High_Risk_Nationality);
  }

  ngAfterViewInit(): void {
    this.resultTableIsFitContent = document.documentElement.clientWidth >= this.resultTableFooterMaxWidth + 130;
    window.onresize = () => {
      this.resultTableIsFitContent = document.documentElement.clientWidth >= this.resultTableFooterMaxWidth + 130;
    };
  }

  fileTypeChange(fileTypeValue: string) {
    this.fileTypeControl.setValue(fileTypeValue);
    this.getAmlHighRiskList(fileTypeValue);
  }

  private getAmlHighRiskList(fileTypeValue: string) {
    this.isShowProgress = true;
    this.cdHttpServeService.get<AmlHighRiskItem[]>(`/ext/eb-ssms/reporting-service/aml-high-risk-list/${fileTypeValue}`, undefined).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          this.dataSource = res.data;
        }
        this.isShowProgress = false;
      },
      error: err => {
        this.alert.error('Error!', err);
        this.isShowProgress = false;
      },
    });
  }

  downloadFile(file: AmlHighRiskItem) {
    this.isDownloading = true;
    this.percentComplete = { value: 0 };
    this.downloadFileService.downloadFile(file.filePath, file.fileName, this.percentComplete, this.closeDownloadEvent).then(() => {
      this.isDownloading = false;
    });
  }

  pageChange(num: number) {
    if (this.currentPage === num) {
      return;
    }
    this.currentPage = num;
    this.getReportFiles();
  }

  getReportFiles() {}

  searchByInputPageNo($event: { preventDefault: () => void }, pageNo: string) {
    $event.preventDefault();
    this.currentPage = +pageNo;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    } else if (this.currentPage > this.pageCount) {
      this.currentPage = this.pageCount;
    }
    this.getReportFiles();
  }

  uploadFile() {
    this.cdsPopup
      .open(UploadDocumentComponent, {
        size: 'lg',
        data: {
          fileType: this.fileTypeControl.value,
        },
      })
      .afterClosed()
      .subscribe(({ agree }) => {
        if (agree) {
          this.getAmlHighRiskList(this.fileTypeControl.value);
        }
      });
  }

  closeDownload() {
    if (this.closeDownloadEvent.event) {
      this.closeDownloadEvent.event();
    }
    this.isDownloading = false;
  }
}
