import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA, CdsPopupService } from '@cds/ng-web-components/popup';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { SentEiffPopupComponent } from './sent-eiff-popup/sent-eiff-popup.component';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PreferredLanguageType } from '../case-summary/case-summary.model';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { empfCountryOption } from '../case-summary/declaration-iff-er/empf-declaration-form.config';
import { clientPhoneNumberVali } from 'src/app/core/validators/common.validator';
import { ResendEiffResendType } from '../resend-eiff/resend-eiff.model';

@Component({
  selector: 'app-new-resend-eiff',
  templateUrl: './new-resend-eiff.component.html',
  styleUrls: ['./new-resend-eiff.component.scss'],
})
export class NewResendEiffComponent implements OnInit {
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage?: CdRadioComponent;
  PreferredLanguageType = PreferredLanguageType;
  ResendType = ResendEiffResendType;
  regulationConfig: CdRadioConfig = {
    options: [
      {
        label: '是',
        value: 'Y',
        disable: true,
      },
      {
        label: '否',
        value: 'N',
        disable: true,
      },
    ],
  };
  secondIntermediaryContactInformationConfig: CdRadioConfig = {
    options: [
      {
        label: '已登記的電郵地址(wink******@manulife.com)',
        value: 'E',
        disable: false,
      },
      {
        label: '已登記的手機號碼 (+852) 6188 ****',
        value: 'M',
        disable: false,
      },
    ],
  };
  secondIntermediaryLangConfig: CdRadioConfig = {
    options: [
      {
        label: '中文',
        value: 'zh',
        disable: false,
      },
      {
        label: '英文',
        value: 'en',
        disable: false,
      },
    ],
  };
  secondIntermediaryLang = 'en';
  secondIntermediaryContactInformation = 'M';
  regulation = 'Y';
  isShowAllInfo = false;
  isEdit = false;
  iffForm!: FormGroup;
  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  step = 1;
  dbs?: FormGroup;
  intragroupTransferCheck = new FormControl(true);

  get showCountryCode() {
    return '+' + this.countryCode?.value;
  }

  get showPhoneNo() {
    return this.phoneNo?.value;
  }

  get showEmail() {
    return this.email?.value;
  }

  get contactType() {
    return this.iffForm.get('contactType') as FormControl;
  }

  get email() {
    return this.iffForm.get('email') as FormControl;
  }

  get phoneNo() {
    return this.iffForm.get('phoneNo') as FormControl;
  }

  get countryCode() {
    return this.iffForm.get('countryCode') as FormControl;
  }

  get preferredLanguage() {
    return this.iffForm.get('preferredLanguage') as FormControl;
  }

  get dbsRmReferralCd() {
    return this.dbs?.get('dbsRmReferralCd') as FormControl;
  }
  get dbsCustomerId() {
    return this.dbs?.get('dbsCustomerId') as FormControl;
  }
  get dbsSourceCd() {
    return this.dbs?.get('dbsSourceCd') as FormControl;
  }
  get dbsCampaignCd() {
    return this.dbs?.get('dbsCampaignCd') as FormControl;
  }

  constructor(
    private dialogRef: MatDialogRef<NewResendEiffComponent>,
    private cdsPopup: CdsPopupService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      salesJourneySummariesId: string;
      resendType: ResendEiffResendType;
      iffStatus: number;
      empfStatus: number;
    }
  ) {}

  ngOnInit(): void {
    this.iffForm = new FormGroup({
      contactType: new FormControl('M', [Validators.required]),
      preferredLanguage: new FormControl(PreferredLanguageType.English, [
        (control: AbstractControl): ValidationErrors | null => {
          if (
            (this.iffForm?.get('contactType')?.value === 'M' && control.value) ||
            this.iffForm?.get('contactType')?.value === 'E' ||
            !this.iffForm?.get('contactType')?.value
          ) {
            return null;
          }
          return { formatError: true };
        },
      ]),
      countryCode: new FormControl('82'),
      phoneNo: new FormControl('87878787'),
      email: new FormControl('abc@gmail.com'),
    });

    this.dbs = new FormGroup({
      dbsRmReferralCd: new FormControl('12312312', [
        Validators.required,
        (control: AbstractControl): ValidationErrors | null => {
          let input = control.value || '';
          input = input.trim();
          const isValid = input.length >= 6 && input.length <= 8;
          return isValid ? null : { lengthError: true };
        },
      ]),
      dbsCustomerId: new FormControl('12312312', [
        (control: AbstractControl): ValidationErrors | null => {
          let input = control.value || '';
          input = input.trim();
          const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
          return isValid ? null : { lengthError: true };
        },
      ]),
      dbsSourceCd: new FormControl('1231231231', [
        (control: AbstractControl): ValidationErrors | null => {
          let input = control.value || '';
          input = input.trim();
          const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
          return isValid ? null : { lengthError: true };
        },
      ]),
      dbsCampaignCd: new FormControl('1231231231', [
        (control: AbstractControl): ValidationErrors | null => {
          let input = control.value || '';
          input = input.trim();
          const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
          return isValid ? null : { lengthError: true };
        },
      ]),
    });
  }

  clientCountryCodeChange() {
    this.phoneNo.updateValueAndValidity();
  }

  onContactTypeChange(val: string) {
    this.preferredLanguage.setValue(undefined);
    if (val === 'E') {
      this.iffForm.removeControl('countryCode');
      this.iffForm.removeControl('phoneNo');
      const emailFormControl = new FormControl(null, [Validators.required, Validators.email]);
      this.iffForm.addControl('email', emailFormControl);
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
    } else if (val === 'M') {
      this.iffForm.removeControl('email');
      const countryCodeFormControl = new FormControl(null, [Validators.required]);
      const phoneNoFormControl = new FormControl(null, [Validators.required, clientPhoneNumberVali(countryCodeFormControl)]);
      this.iffForm.addControl('countryCode', countryCodeFormControl);
      this.iffForm.addControl('phoneNo', phoneNoFormControl);
      this.iffForm.patchValue({ countryCode: '852' });
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: false,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: false,
          },
        ],
      };
    }
    this.cdRadioPreferredLanguage?.refreshConfig();
  }

  closeCurrentPopup() {
    this.dialogRef.close();
  }

  onContinue() {
    if (this.isDisableContinue) {
      return;
    }

    this.isEdit = false;
    this.isShowAllInfo = true;
    this.step = 2;
  }

  onSubmit() {
    this.cdsPopup.open(SentEiffPopupComponent, {
      size: 'sm',
    });
    this.closeCurrentPopup();
  }

  onEdit() {
    this.isEdit = true;
    this.isShowAllInfo = true;
    this.step = 1;
    this.contactType.reset('');
    this.onContactTypeChange('');
  }

  onBackEdit() {
    this.isEdit = true;
    this.isShowAllInfo = true;
    this.step = 1;

    this.preferredLanguageConfig = {
      options: [
        {
          label: 'common.chinese',
          value: PreferredLanguageType.Chinese,
          disable: false,
        },
        {
          label: 'common.english',
          value: PreferredLanguageType.English,
          disable: false,
        },
      ],
    };
  }

  get isDisableContinue() {
    return (this.iffForm.invalid || !!(this.dbs && this.dbs.invalid)) && this.isEdit;
  }
}
