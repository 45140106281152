/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsCheckboxListConfig } from '@cds/ng-core/checkbox';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { Subscription } from 'rxjs';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdPopupService, CdPopupType } from 'src/app/shared/cd-popup';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { PreferredLanguageType } from '../../case-summary/case-summary.model';
import { empfCountryOption } from '../../case-summary/declaration-iff-er/empf-declaration-form.config';
import { ResetWarningComponent } from '../../iff-paper-form/reset-warning/reset-warning.component';
import { ProductType } from '../../md-iff-summary/md-iff-summary.model';
import { HasBeenSentCustomerPopupComponent } from '../has-been-sent-customer-popup/has-been-sent-customer-popup.component';
import { PendingSplitAgentConfirPopupComponent } from '../pending-split-agent-confir-popup/pending-split-agent-confir-popup.component';
import { Domain } from '../select-product-type-for-md/select-product-type-for-md.component';

enum Show {
  individuals = 'individuals',
  intermediary = 'intermediary',
}

interface AgentInfo {
  agentId: string;
  agentCode: string;
  agentName: string;
  agentNameTC: string;
  mobileNumber: string;
  emailAddress: string;
  branchCode: string;
  agentCategory: string;
  licenseNumber: string;
  licenseCode: string;
  licenseStatus: string;
  status: string;
  shortEmail?: string;
  shortPhone?: string;
}

interface AddMemberParams {
  email?: string;
  phoneCountry?: string;
  phone?: string;
  isRegulatedActivities?: boolean;
  commChannel?: 'SMS' | 'EMAIL';
  secondAgent?: {
    agentCode?: string;
    isRegulatedActivities?: boolean;
    commChannel?: 'SMS' | 'EMAIL';
    language?: 'CN' | 'EN';
  };
  thirdAgent?: {
    agentCode?: string;
    isRegulatedActivities?: boolean;
    commChannel?: 'SMS' | 'EMAIL';
    language?: 'CN' | 'EN';
  };
  isDbs?: boolean;
  dbsCustomerId?: string;
  dbsRmReferralCd?: string;
  dbsCampaignCd?: string;
  dbsSourceCd?: string;
  language?: 'CN' | 'EN';
  productTypes?: string[];
  fastName?: string;
  lastName?: string;
  hkId?: string;
  passportNo?: string;
}

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss'],
})
export class IndividualComponent implements OnInit, OnDestroy {
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage!: CdRadioComponent;
  @ViewChild('dbsReferredLanguage') dbsReferredLanguage!: CdRadioComponent;
  @ViewChild('radioRelevantRegulatedActivities') radioRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioSecondAgentRelevantRegulatedActivities') radioSecondAgentRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioSecondAgentClientContact') radioSecondAgentClientContact?: CdRadioComponent;
  @ViewChild('radioSecondAgentLanguage') radioSecondAgentLanguage?: CdRadioComponent;
  PreferredLanguageType = PreferredLanguageType;
  domain?: Domain;
  Domain = Domain;
  Show = Show;
  show = Show.individuals;
  isConfirming = false;
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;
  individualsForm!: FormGroup;
  isTryConfirm = false;
  language = 'EN';
  lanChangeSubscription?: Subscription;
  clientContactConfig: CdRadioConfig = {
    options: [
      {
        label: 'Email Address',
        value: 'E',
        disable: false,
      },
      {
        label: 'Phone Number',
        value: 'M',
        disable: false,
      },
    ],
  };
  isDBschannel = '';
  dbsContactConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };
  clientContact = '';
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  private readonly CN_PHONE = /^1[345678]\d{9}$/;

  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  idTypeConfigOptions = [
    { label: this.cdsLangService.translate('generate.md.hkid'), value: '0' },
    { label: this.cdsLangService.translate('generate.md.passport-no'), value: '1' },
  ];

  relevantRegulatedActivitiesValue = '';
  relevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'n',
        disable: false,
      },
    ],
  };
  resetDatePicker = false;
  secondAgent: {
    agentCode: string;
    secondAgentInfo?: AgentInfo;
  } = {
    agentCode: '',
  };
  secondAgentRelevantRegulatedActivities = '';
  secondAgentRelevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };

  secondAgentClientContact = '';
  secondAgentClientContactConfig?: CdRadioConfig;

  secondAgentLanguage = '';
  secondAgentLanguageConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.chinese'),
        value: 'ZH',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.english'),
        value: 'EN',
        disable: false,
      },
    ],
  };
  productTypeListConfig: CdsCheckboxListConfig = {
    options: [
      {
        label: ProductType.PA,
        value: ProductType.PA,
      },
      {
        label: ProductType.TVC,
        value: ProductType.TVC,
      },
      {
        label: ProductType.SVC,
        value: ProductType.SVC,
      },
    ],
    orientation: 'vertical',
  };

  get preferredLanguage() {
    return this.individualsForm.get('preferredLanguage') as FormControl;
  }

  get customerSurname() {
    return this.individualsForm.get('customerSurname') as FormControl;
  }

  get customerGivenName() {
    return this.individualsForm.get('customerGivenName') as FormControl;
  }

  get isCustomerError() {
    if (!this.isTryConfirm) {
      return false;
    }
    if (!this.customerSurname && !this.customerGivenName) {
      return true;
    } else if ((this.customerSurname && !this.customerGivenName) || (!this.customerSurname && this.customerGivenName)) {
      return true;
    }
    return false;
  }

  get hkidOrPassportNo() {
    return this.individualsForm.get('hkidOrPassportNo') as FormControl;
  }

  get idType() {
    return this.individualsForm.get('idType') as FormControl;
  }

  get rmReferralCode() {
    return this.individualsForm.get('rmReferralCode') as FormControl;
  }
  get inputCustomerId() {
    return this.individualsForm.get('inputCustomerId') as FormControl;
  }
  get sourceCode() {
    return this.individualsForm.get('sourceCode') as FormControl;
  }
  get campaignCode() {
    return this.individualsForm.get('campaignCode') as FormControl;
  }

  get getSecondAgentName() {
    if (!this.secondAgent.secondAgentInfo) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return this.secondAgent.secondAgentInfo.agentName || this.secondAgent.secondAgentInfo.agentNameTC;
    } else {
      return this.secondAgent.secondAgentInfo.agentNameTC || this.secondAgent.secondAgentInfo.agentName;
    }
  }

  get clientPhoneNumber() {
    return this.individualsForm.get('clientPhoneNumber') as FormControl;
  }

  get clientCountryCode() {
    return this.individualsForm.get('clientCountryCode') as FormControl;
  }

  get clientEmailAddress() {
    return this.individualsForm.get('clientEmailAddress') as FormControl;
  }

  get productTypes() {
    return this.individualsForm.get('productTypes') as FormControl;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private languageChangeService: LanguageChangeService,
    private cdsPopup: CdPopupService
  ) {}

  hKIDPassportNoChange() {
    this.hkidOrPassportNo.updateValueAndValidity();
  }

  hkidOrPassportNoChange(event: string) {
    const value = this.hkidOrPassportNo.value?.replace(/[\u4e00-\u9fa5]/gi, '');
    if (event !== value) {
      this.hkidOrPassportNo.setValue(value);
    }

    if (/^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(value)) {
      if (this.idType.value === '0') {
        this.cdHttpServeService.post(`/ext/eb-ssms-sales-journey-service/hkidCheckDigit`, { hkId: value.trim() }).subscribe({
          next: res => {
            if (!(res.result === 0 && res.data)) {
              this.hkidOrPassportNo.setErrors({ formatError: true });
            }
          },
          error: err => {
            this.hkidOrPassportNo.setErrors({ formatError: true });
            this.alert.error('Error!', err);
          },
        });
      }
    }
  }

  clientCountryCodeChange() {
    this.clientPhoneNumber.updateValueAndValidity();
  }

  clientContactChange() {
    this.preferredLanguage.setValue(undefined);
    this.preferredLanguageConfig = {
      options: [
        {
          label: 'common.chinese',
          value: PreferredLanguageType.Chinese,
          disable: false,
        },
        {
          label: 'common.english',
          value: PreferredLanguageType.English,
          disable: false,
        },
      ],
    };
    this.cdRadioPreferredLanguage?.refreshConfig();
    if (this.clientContact === 'E') {
      this.clientPhoneNumber.updateValueAndValidity();
    }
  }

  dbsContractChange(val: string) {
    this.isDBschannel = val;
    if (val === 'N') {
      this.individualsForm.get('rmReferralCode')?.reset();
      this.individualsForm.get('inputCustomerId')?.reset();
      this.individualsForm.get('campaignCode')?.reset();
      this.individualsForm.get('sourceCode')?.reset();
    }
  }

  lanSubjectChange = (lan: string) => {
    this.language = lan === 'en' ? 'EN' : 'TC';

    this.clientContactConfig = {
      options: [
        {
          label: this.cdsLangService.translate('generate.md.emailAddress'),
          value: 'E',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('generate.md.phoneNumber'),
          value: 'M',
          disable: false,
        },
      ],
    };

    this.idTypeConfigOptions = [
      { label: this.cdsLangService.translate('generate.md.hkid'), value: '0' },
      { label: this.cdsLangService.translate('generate.md.passport-no'), value: '1' },
    ];

    this.refreshSecondAgentClientContactConfig();
    this.refreshRelevantRegulatedActivitiesConfig();
    this.refreshDbsContactConfig();
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
    this.refreshSecondAgentLanguageConfig();
  };

  private refreshSecondAgentLanguageConfig() {
    this.secondAgentLanguageConfig.options[0].label = this.cdsLangService.translate('common.chinese');
    this.secondAgentLanguageConfig.options[1].label = this.cdsLangService.translate('common.english');
    this.radioSecondAgentLanguage?.refreshConfig();
  }

  private refreshSecondAgentRelevantRegulatedActivitiesConfig() {
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioSecondAgentRelevantRegulatedActivities?.refreshConfig();
  }

  ngOnInit(): void {
    this.domain = (this.route.snapshot.paramMap.get('domain') as Domain) || undefined;
    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(this.lanSubjectChange);

    this.individualsForm = new FormGroup({
      customerSurname: new FormControl('', [Validators.required]),
      customerGivenName: new FormControl('', [Validators.required]),
      idType: new FormControl('0'),
      hkidOrPassportNo: new FormControl('', [Validators.required, this.indFormHkidOrPassportNoVali]),
      clientEmailAddress: new FormControl(null, [this.clientEmailVali]),
      clientCountryCode: new FormControl('852'),
      clientPhoneNumber: new FormControl('', [this.idFormClientPhoneNumberVali]),
      preferredLanguage: new FormControl(null, [this.idFormPreferredLanguageVali]),
      rmReferralCode: new FormControl('', [this.rmReferralCodeVali]),
      inputCustomerId: new FormControl('', [this.inputCustomerIdVali]),
      sourceCode: new FormControl('', [this.sourceCodeVali]),
      campaignCode: new FormControl('', [this.campaignCodeVali]),
      productTypes: new FormControl({ value: [], disabled: false }, [Validators.required]),
    });

    if (this.domain === Domain.sep) {
      this.productTypes.setValue([ProductType.SEP]);
    }
  }

  ngOnDestroy(): void {
    this.lanChangeSubscription?.unsubscribe();
  }

  rmReferralCodeVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.isDBschannel === 'Y') {
      let input = control.value || '';
      input = input.trim();
      const isValid = input.length >= 6 && input.length <= 8;
      return isValid ? null : { lengthError: true };
    }
    return null;
  };
  inputCustomerIdVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  sourceCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  campaignCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  idFormPreferredLanguageVali = (control: AbstractControl): ValidationErrors | null => {
    if ((this.clientContact === 'M' && control.value) || this.clientContact === 'E' || !this.clientContact) {
      return null;
    }
    return { formatError: true };
  };

  clientEmailVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.clientContact === 'E') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        if (!option.value.includes('@')) {
          return { email: true };
        }
      }
    }
    return null;
  };

  idFormClientPhoneNumberVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.clientContact === 'M') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        if (['852', '853'].includes(this.clientCountryCode.value)) {
          if (option.value.length === 8 && ['4', '5', '6', '7', '8', '9'].includes(option.value.substring(0, 1))) {
            return null;
          }
        }

        if (['86'].includes(this.clientCountryCode.value)) {
          if (option.value.length === 11) {
            const e = this.CN_PHONE.test(option.value);
            if (e) {
              return null;
            }
          }
        }

        if (!['852', '853', '86'].includes(this.clientCountryCode.value)) {
          return null;
        }
      }

      return { errorPhoneNumber: true };
    }

    return null;
  };

  indFormHkidOrPassportNoVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.individualsForm?.get('idType')?.value === '1') {
      return null;
    }
    if (control.value === '' || control.value == undefined) {
      return null;
    }
    let input: string = control.value;
    input = input.trim();
    const isValid: RegExpExecArray | null | boolean = /^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(input);
    return isValid ? null : { formatError: true };
  };

  onBack() {
    this.router.navigate(['/salesjourney/md-iff-summary']);
  }

  reset() {
    const popupRef: MatDialogRef<ResetWarningComponent> = this.cdsPopup.open(ResetWarningComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(this.resetWarningComponentPopupCloseCall);
  }

  resetWarningComponentPopupCloseCall = (confirm: { agree: boolean }) => {
    if (confirm.agree) {
      this.individualsForm.reset({
        productTypes: { value: [], disabled: false },
      });
      this.clientCountryCode.setValue('852');
      this.idType.setValue('0');
      this.isTryConfirm = false;
      this.relevantRegulatedActivitiesValue = '';
      this.resetDatePicker = true;
      this.clientContact = '';
      this.isDBschannel = '';
      this.secondAgent = {
        agentCode: '',
      } as AgentInfo;
      setTimeout(() => {
        this.resetDatePicker = false;
      }, 0);
      this.preferredLanguage.reset();
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
      this.cdRadioPreferredLanguage?.refreshConfig();
    }
  };

  backToEdit() {
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: 'generate.md.leave-page',
        continue: 'common.confirm-continue',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.continue) {
        this.isConfirming = false;
        this.dbsContactConfig.options.forEach((e: { disable: boolean }) => {
          e.disable = false;
        });
        this.dbsReferredLanguage?.refreshConfig();
        this.setRelevantRegulatedActivitiesEnable();
        this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
        this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
        this.secondAgentRelevantRegulatedActivities = '';
        this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
        this.secondAgentClientContact = '';
        this.secondAgentLanguage = '';
        this.secondAgent.secondAgentInfo = undefined;
        this.productTypes.enable();
      }
    });
  }

  private refreshDbsContactConfig() {
    this.dbsContactConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.dbsContactConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.dbsReferredLanguage?.refreshConfig();
  }

  next() {
    if (this.isDisableNext) {
      return;
    }
    this.customerSurname.markAsDirty();
    this.customerGivenName.markAsDirty();
    this.hkidOrPassportNo.markAsDirty();
    this.isTryConfirm = true;
    this.clientEmailAddress.markAsDirty();
    this.clientPhoneNumber.markAsDirty();
    this.preferredLanguage.markAsTouched();

    // const boo = this.isAllowPast();

    if (this.individualsForm.valid) {
      this.isConfirming = true;
      this.productTypes.disable();
      this.setRelevantRegulatedActivitiesDisable();
      if (this.secondAgent.agentCode) {
        this.show = Show.intermediary;
        this.getAgentInfoByAgentCode(this.secondAgent.agentCode).then(agentInfo => {
          this.secondAgent.secondAgentInfo = agentInfo;
          this.refreshSecondAgentClientContactConfig();
        });
      }
    }
  }

  private refreshSecondAgentClientContactConfig() {
    if (!this.secondAgent.secondAgentInfo) {
      return;
    }
    this.secondAgent.secondAgentInfo.shortEmail = '';
    if (this.secondAgent.secondAgentInfo.emailAddress) {
      const ea = this.secondAgent.secondAgentInfo.emailAddress || '';
      const endIndex = ea.indexOf('@');
      this.secondAgent.secondAgentInfo.shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
    }
    this.secondAgent.secondAgentInfo.shortPhone = '';
    if (this.secondAgent.secondAgentInfo.mobileNumber) {
      const mn = this.secondAgent.secondAgentInfo.mobileNumber || '';
      let phoneArr = new Array((mn + '').length - 6);
      phoneArr = phoneArr.fill('*');
      this.secondAgent.secondAgentInfo.shortPhone = mn.substring(0, 6) + phoneArr.join('');
    }

    this.secondAgentClientContactConfig = {
      options: [
        {
          label: `${this.cdsLangService.translate('generate.md.registered-email-address')}(${this.secondAgent.secondAgentInfo.shortEmail})`,
          value: 'E',
          disable: false,
        },
        {
          label: `${this.cdsLangService.translate('generate.md.registered-phone-number')} ${this.secondAgent.secondAgentInfo.shortPhone}`,
          value: 'M',
          disable: false,
        },
      ],
    };
    this.radioSecondAgentClientContact?.refreshConfig();
  }

  private getAgentInfoByAgentCode(agentCode: string) {
    return new Promise<AgentInfo>((resolve, reject) => {
      this.cdHttpServeService.post<AgentInfo[]>(`/ext/eb-ssms/agent-service/agent/infos/agentCd`, [agentCode]).subscribe({
        next: res => {
          if (res.result === 0) {
            if (res.data && res.data.length) {
              resolve(res.data[0]);
            }
          } else {
            this.alert.error('Error!', res.message);
          }
          reject();
        },
        error: err => {
          this.alert.error('Error!', err);
          reject();
        },
      });
    });
  }

  private refreshRelevantRegulatedActivitiesConfig() {
    this.relevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.relevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setRelevantRegulatedActivitiesDisable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: true,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: true,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setRelevantRegulatedActivitiesEnable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: false,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  isAllowPast() {
    if (this.relevantRegulatedActivitiesValue === '' || this.isDBschannel === '' || !this.customerSurname || !this.customerGivenName) {
      return false;
    }
    if (this.isDBschannel === 'Y' && (!this.rmReferralCode.valid || !this.campaignCode.valid || !this.inputCustomerId.valid || !this.sourceCode.valid)) {
      return false;
    }

    if (this.idType.value === '0' && !this.hkidOrPassportNo.valid) {
      return false;
    }

    if (this.clientContact === '') {
      return false;
    }
    if (this.clientContact === 'E' && !this.clientEmailAddress.valid) {
      return false;
    } else if (this.clientContact === 'M' && !this.clientPhoneNumber.valid) {
      return false;
    }

    return true;
  }

  get isDisableNext() {
    return this.individualsForm.invalid || this.clientContact === '' || this.isDBschannel === '' || this.relevantRegulatedActivitiesValue === '';
  }

  get isDisableIntermediaryNext() {
    if (this.secondAgentRelevantRegulatedActivities === '') {
      return true;
    }
    if (this.secondAgentRelevantRegulatedActivities === 'Y') {
      if (this.secondAgentClientContact === '') {
        return true;
      }
      if (this.secondAgentClientContact === 'M' && this.secondAgentLanguage === '') {
        return true;
      }
    }
    return false;
  }

  secondAgentClientContactChange() {
    this.secondAgentLanguage = '';
  }

  intermediaryNext() {
    if (this.isDisableIntermediaryNext) {
      return;
    }
    this.isConfirming = true;
    this.show = Show.individuals;
    this.setRelevantRegulatedActivitiesDisable();
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
  }

  intermediaryReset() {
    this.secondAgentRelevantRegulatedActivities = '';
    this.secondAgentLanguage = '';
    this.secondAgentClientContact = '';
  }

  get getSecondAgentLanguage() {
    if (this.secondAgentLanguage === 'ZH') {
      return this.secondAgentLanguageConfig.options[0].label;
    } else if (this.secondAgentLanguage === 'EN') {
      return this.secondAgentLanguageConfig.options[1].label;
    }
    return undefined;
  }

  submit() {
    const params: AddMemberParams = {
      lastName: this.customerSurname.value,
      fastName: this.customerGivenName.value,
    };
    if (this.idType.value === '0') {
      params.hkId = this.hkidOrPassportNo.value;
    } else {
      params.passportNo = this.hkidOrPassportNo.value;
    }
    if (this.clientContact === 'E') {
      params.commChannel = 'EMAIL';
      params.email = this.clientEmailAddress.value;
    } else {
      params.commChannel = 'SMS';
      params.phoneCountry = this.clientCountryCode.value;
      params.phone = this.clientPhoneNumber.value;
      params.language = this.preferredLanguage.value === PreferredLanguageType.Chinese ? 'CN' : 'EN';
    }
    params.isRegulatedActivities = this.relevantRegulatedActivitiesValue === 'y';
    params.isDbs = false;
    params.productTypes = this.productTypes.value;

    if (this.secondAgent.secondAgentInfo) {
      params.secondAgent = {};
      params.secondAgent.agentCode = this.secondAgent.secondAgentInfo.agentCode;
      params.secondAgent.isRegulatedActivities = this.secondAgentRelevantRegulatedActivities === 'Y';
      if (params.secondAgent.isRegulatedActivities) {
        params.secondAgent.commChannel = this.secondAgentClientContact === 'E' ? 'EMAIL' : 'SMS';
        if (this.secondAgentClientContact === 'M') {
          params.secondAgent.language = this.secondAgentLanguage === 'EN' ? 'EN' : 'CN';
        }
      }
    }

    this.cdHttpServeService
      .post<{
        mainId: string;
        ids: string[];
      }>(`/ext/eb-ssms-sales-journey-service/md/add/member`, params)
      .subscribe({
        next: res => {
          if (res.result === 0) {
            if (this.relevantRegulatedActivitiesValue === 'n') {
              this.cdsPopup
                .open(HasBeenSentCustomerPopupComponent, {
                  size: 'md',
                  data: {
                    mdNo: res.data.mainId,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['/salesjourney/md-iff-summary']);
                });
            } else {
              this.cdsPopup
                .open(PendingSplitAgentConfirPopupComponent, {
                  size: 'md',
                  data: {
                    mdNo: res.data.mainId,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['/salesjourney/md-iff-summary']);
                });
            }
          } else {
            this.alert.error('Error!', res.message);
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
  }
}
