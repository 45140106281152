<div class="upload-document-popup-box">
  <div [formGroup]="formGroup" class="content">
    <span class="upload-document-text">Upload document</span>
    <div class="l-mt-6">
      <span class="file-requirements-text">File requirements</span>
    </div>
    <div style="margin-top: 20px">
      <div class="l-d-f l-fd-col">
        <div>
          <span class="requirements-info">• A maximum of 1 file with file size limit of 8MB can be uploaded</span>
        </div>
        <div class="l-mt-1" *ngIf="[HighRiskFileType.High_Risk_Country_ORSO].includes(data.fileType)">
          <span class="requirements-info">• File name: AML_High_Risk_Country_ORSO_YYYYMMDD</span>
        </div>
        <div class="l-mt-1" *ngIf="[HighRiskFileType.High_Risk_Country].includes(data.fileType)">
          <span class="requirements-info">• File name: AML_High_Risk_Country_YYYYMMDD</span>
        </div>
        <div class="l-mt-1" *ngIf="[HighRiskFileType.High_Risk_Business_Nature].includes(data.fileType)">
          <span class="requirements-info">• File name: AML_High_Risk_Business_Nature_YYYYMMDD</span>
        </div>
        <div class="l-mt-1" *ngIf="[HighRiskFileType.High_Risk_Nationality_ORSO].includes(data.fileType)">
          <span class="requirements-info">• File name: AML_High_Risk_Nationality_ORSO_YYYYMMDD</span>
        </div>
        <div class="l-mt-1" *ngIf="[HighRiskFileType.High_Risk_Nationality].includes(data.fileType)">
          <span class="requirements-info">• File name: AML_High_Risk_Nationality_YYYYMMDD</span>
        </div>
        <div class="l-mt-1" *ngIf="[HighRiskFileType.High_Risk_Occupation].includes(data.fileType)">
          <span class="requirements-info">• File name: AML_High_Risk_Occupation_YYYYMMDD</span>
        </div>
        <div class="l-mt-1" *ngIf="[HighRiskFileType.High_Risk_Occupation_Keyword].includes(data.fileType)">
          <span class="requirements-info">• File name: High_Risk_Occupation_Keyword_YYYYMMDD</span>
        </div>
        <div class="l-mt-1" *ngIf="[HighRiskFileType.High_Risk_Business_Nature_Keyword].includes(data.fileType)">
          <span class="requirements-info">• File name: High_Risk_Business_Nature_Keyword_YYYYMMDD</span>
        </div>
      </div>
    </div>
    <div formArrayName="fileUploadInfoList" class="l-d-f l-fw-w file-upload-info-list">
      <div *ngFor="let uploadinfo of fileUploadInfoList.controls; let i = index" class="l-mr-5 l-mt-5">
        <cds-file-upload
          acceptedFiles=".xlsx"
          (change)="fileChange($event, uploadinfo)"
          [formControl]="getFileControl(uploadinfo)"
          [errorMessage]="getErrorMessage(uploadinfo)"></cds-file-upload>
        <div class="error-message-area l-mt-2" *ngIf="getErrorMessage(uploadinfo).length > 0">
          <cds-icon class="l-mr-2 warning" icon="action:warning" style="--cds-icon-color: #c14a36"></cds-icon>
          <div class="error-message-text">{{ getErrorMessage(uploadinfo) }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="botton-list">
    <cds-button (click)="cancel()" [style]="'secondary'" size="sm">{{ 'common.cancel' | lang }}</cds-button>
    <cds-button (click)="upload()" [disabled]="formGroup.invalid" size="sm">{{ 'common.upload' | lang }}</cds-button>
  </div>
  <cds-icon class="close-icon" icon="action:cross" (click)="cancel()"></cds-icon>
</div>
