/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { filter, finalize } from 'rxjs';

import { ResponseResult } from 'src/app/core/models/response/response-result';

import { CommissionJournalService } from 'src/app/core/services/commission/commission-journal.service';

import { FileType, aoaToSheet } from 'src/app/utils/xlsx';

import { ColumnConfig } from 'src/app/shared/data-table';
import {
  AGENT_INFO_COLUMNS,
  FILE_NAME,
  I18N_KEY,
  JOURNAL_COLUMNS_NEW,
  PAGE_SIZE,
  TXN_CONFIG,
  ACCOUNT_TYPE_CONFIG,
  ACTIVITY_CODE_CONFIG,
  DOWN_LOAD_MAX,
  EXCEL_COLUMNS,
  FILE_HEADER,
} from './journal.config';

import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import {
  letterAndNumberValidator,
  requiredValidator,
  dateRangeInCompleteValidator,
  dateRangeInvalidValidator,
  dateValidator,
  agentCodeValidator,
  numberValidator,
  journalValidator,
} from 'src/app/core/validators';
import { TxnCode, TxnCodeMap } from 'src/app/core/models/enum/txn-code.enum';
import { CommissionCode, CommissionPCCode } from 'src/app/core/models/enum/commission-code.enum';
import { CdPopupService, CdPopupSize, MatDialogRef } from 'src/app/shared/cd-popup';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss'],
})
export class JournalComponent implements OnInit {
  accountTypeConfig = ACCOUNT_TYPE_CONFIG;
  subActivityCdConfig = ACTIVITY_CODE_CONFIG;
  txnConfig = TXN_CONFIG;

  isQuickSearch = false;
  isDownLoading = false;
  isOverMax = false;
  previousSearchParams: any;
  isShowSearchDatePicker = true; // Fix DatePicker Bug

  quickSearchGroup = new FormGroup({
    referenceNo: new FormControl('', [
      letterAndNumberValidator({
        error: I18N_KEY.REFERENCE_NO_INVALID,
      }),
    ]),
  });
  formGroup = new FormGroup(
    {
      runDateFrom: new FormControl('', {
        initialValueIsDefault: true,
        validators: [requiredValidator({ error: I18N_KEY.DATE_From_NULL }), dateValidator({ error: I18N_KEY.INVALID_RUN_DATE })],
      }),
      runDateTo: new FormControl('', {
        initialValueIsDefault: true,
        validators: [requiredValidator({ error: I18N_KEY.DATE_TO_NULL }), dateValidator({ error: I18N_KEY.INVALID_RUN_DATE })],
      }),
      accountType: new FormControl('', {
        initialValueIsDefault: true,
      }),
      subActivityCd: new FormControl('', {
        initialValueIsDefault: true,
      }),
      txnCode: new FormControl('', {
        initialValueIsDefault: true,
      }),
      employerAccountCode: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.ACCOUNT_NO_INVALID,
          }),
        ],
        initialValueIsDefault: true,
      }),
      memberAccountCode: new FormControl('', {
        validators: [
          numberValidator({
            error: I18N_KEY.MEMBER_NO_INVALID,
          }),
        ],
        initialValueIsDefault: true,
      }),
      originalErMerCode: new FormControl('', {
        initialValueIsDefault: true,
      }),
      agentCode: new FormControl('', {
        validators: [
          agentCodeValidator({
            error: I18N_KEY.AGENT_CODE_INVALID,
          }),
        ],
        initialValueIsDefault: true,
      }),
      noCommission: new FormControl(false, {
        initialValueIsDefault: true,
      }),
    },
    [
      dateRangeInCompleteValidator('runDateFrom', 'runDateTo', {
        dateRangeInComplete: I18N_KEY.DATE_RANGE_INCOMPLETE,
      }),
      dateRangeInvalidValidator('runDateFrom', 'runDateTo', {
        dateRangeInvalid: I18N_KEY.INVALID_DATE_RANGE,
      }),
      journalValidator({
        originalErMerCodeError: I18N_KEY.ORIGINAL_ER_MER_INVALID,
      }),
    ]
  );

  data: any[] = [];
  columns: Array<ColumnConfig> = JOURNAL_COLUMNS_NEW;
  columnsAgent: Array<ColumnConfig> = AGENT_INFO_COLUMNS;
  pageCount: IPaginationCount = { current: 1, count: 0 };
  totalElements = 0;
  isDataLoading = false;

  private _pageSize = PAGE_SIZE;

  get referenceNo() {
    return this.quickSearchGroup.get('referenceNo') as FormControl;
  }

  get runDateFrom() {
    return this.formGroup.get('runDateFrom') as FormControl;
  }

  get runDateTo() {
    return this.formGroup.get('runDateTo') as FormControl;
  }

  get accountType() {
    return this.formGroup.get('accountType') as FormControl;
  }

  get subActivityCd() {
    return this.formGroup.get('subActivityCd') as FormControl;
  }

  get txnCode() {
    return this.formGroup.get('txnCode') as FormControl;
  }

  get employerAccountCode() {
    return this.formGroup.get('employerAccountCode') as FormControl;
  }

  get memberAccountCode() {
    return this.formGroup.get('memberAccountCode') as FormControl;
  }

  get originalErMerCode() {
    return this.formGroup.get('originalErMerCode') as FormControl;
  }

  get agentCode() {
    return this.formGroup.get('agentCode') as FormControl;
  }

  get noCommission() {
    return this.formGroup.get('noCommission') as FormControl;
  }

  get quickSearchDisabled() {
    return !this.referenceNo.value || this.referenceNo.invalid;
  }

  get searchDisabled() {
    const fromDate = this.runDateFrom.value;
    const toDate = this.runDateTo.value;
    const accountType = this.accountType.value;
    const subActivityCd = this.subActivityCd.value;
    const txn = this.txnCode.value;
    const accountNo = this.employerAccountCode.value?.trim();
    const memberNo = this.memberAccountCode.value?.trim();
    const originalErMerCode = this.originalErMerCode.value?.trim();
    const agentCode = this.agentCode.value?.trim();
    const hasValue = accountType || subActivityCd || txn || accountNo || memberNo || originalErMerCode || agentCode;
    return !fromDate || !toDate || !this.formGroup.dirty || this.formGroup.invalid || !hasValue;
  }

  get resetDisabled() {
    let tag = true;
    const controls = this.formGroup.controls;
    const keys = Object.keys(controls);
    keys.forEach((key: string) => {
      const defaultValue = (controls[key] as FormControl).defaultValue;
      const controlValue = (controls[key] as FormControl).value;
      if (defaultValue != controlValue) {
        tag = false;
      }
    });
    return tag;
  }

  get downloadDisabled() {
    return !this.previousSearchParams || this.isDownLoading || !this.totalElements;
  }

  constructor(private commissionJournalService: CommissionJournalService, private cdsPopup: CdPopupService) {}

  ngOnInit() {}

  search(isQuickSearch: boolean) {
    this.previousSearchParams = null;
    this.pageCount = {
      count: 0,
      current: 1,
    };
    this.isQuickSearch = isQuickSearch;
    this.getdata();
  }

  createParams() {
    const params: any = {
      page: this.pageCount.current - 1,
      size: this._pageSize,
      sort: 'runDate,asc', //asc
    };
    if (this.previousSearchParams) {
      return { ...this.previousSearchParams, ...params };
    }
    let value = { ...this.formGroup.value };
    if (this.isQuickSearch) {
      value = {
        ...this.quickSearchGroup.value,
        noCommission: this.noCommission.value,
      };
    }
    const keys: string[] = Object.keys(value);
    keys.forEach(key => {
      if (value[key]) {
        params[key] = value[key];
      }
    });
    if (params.runDateFrom) {
      params.runDateFrom = moment(params.runDateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (params.runDateTo) {
      params.runDateTo = moment(params.runDateTo, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    return params;
  }

  reset() {
    if (!this.resetDisabled) {
      this.previousSearchParams = null;
      this.formGroup.reset();
      this.data = [];
      this.isShowSearchDatePicker = false;
      setTimeout(() => {
        this.isShowSearchDatePicker = true; // Fix DatePicker Bug
      });
    }
  }

  download() {
    if (this.isOverMax) {
      this.openAlertPop(I18N_KEY.DOWN_LOAD_MAX_FAIL);
      return;
    }

    if (this.downloadDisabled) {
      return;
    }

    this.isDownLoading = true;
    this.commissionJournalService
      .download(this.previousSearchParams)
      .pipe(
        finalize(() => {
          this.isDownLoading = false;
        }),
        filter(res => res.result === ResponseResult.SUCCESS)
      )
      .subscribe(res => {
        const data = this.createData(res.data || []);
        const excelData = this.createExcelData(data);
        aoaToSheet(
          {
            fileName: `${FILE_NAME} ${moment().format('YYYYMMDD_HHmmss')}`,
            fileType: FileType.XLSX,
          },
          excelData
        );
      });
  }

  createExcelData(data: any[]) {
    const excelData: any[] = [];
    excelData.push([`${FILE_HEADER}${moment().format('DD-MM-YYYY HH:MM:SS')}`]);
    excelData.push(EXCEL_COLUMNS);
    data.forEach(item => {
      const list = item.list.length ? item.list : [{}];
      list.forEach((agent: any, index: number) => {
        const temp = [];
        temp.push(item.runDate);
        temp.push(item.employerAccountCode);
        temp.push(item.memberAccountCode);
        temp.push(item.accountType);
        temp.push(item.payRollGroupId);
        temp.push(item.transactionReferenceNo);
        temp.push(item.groupReferenceNo);
        temp.push(item.payReferenceNo);
        temp.push(item.subActivityCd);
        temp.push(item.originalErMerCode);
        temp.push(item.txnCode);
        temp.push(item.commFlag);
        if (index === 0) {
          temp.push(item.contStart);
          temp.push(item.contEnd);
          temp.push(item.txnEffectiveDate);
          temp.push(item.contributionFrequency);
          temp.push(item.pacFlag);
          temp.push(item.processor?.name);
          temp.push(item.totalPremiumReceived.toFixed(2));
          temp.push(item.commissionableAmount.toFixed(2));
        } else {
          temp.push('');
          temp.push('');
          temp.push('');
          temp.push('');
          temp.push('');
          temp.push('');
          temp.push('');
          temp.push('');
        }
        temp.push(agent.agentCode);
        temp.push(agent.share === undefined ? '' : `${agent.share.toFixed(3)}%`);
        temp.push(agent.scaleRate === '' || agent.scaleRate === undefined ? agent.scaleRate : `${agent.scaleRate.toFixed(4)}%`);
        temp.push(agent.commissionCode);
        temp.push(agent.amount === undefined ? '' : `${agent.amount.toFixed(2)}`);
        temp.push(agent.amountPremium === undefined ? '' : `${agent.amountPremium.toFixed(2)}`);
        excelData.push(temp);
      });
      excelData.push(['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*']);
    });
    return excelData;
  }

  pageChange(currentPage: number) {
    this.pageCount.current = currentPage;
    this.getdata();
  }

  getdata() {
    this.isDataLoading = true;
    const params = this.createParams();
    this.commissionJournalService
      .getData(params)
      .pipe(
        finalize(() => {
          this.isDataLoading = false;
        }),
        filter(res => res.result === ResponseResult.SUCCESS)
      )
      .subscribe((res: any) => {
        this.previousSearchParams = params;
        const data = res.data;
        this.totalElements = data.totalElements || 0;
        this.isOverMax = this.totalElements > DOWN_LOAD_MAX;
        if (this.isOverMax) {
          this.openAlertPop(I18N_KEY.DOWN_LOAD_MAX_LIMMIT);
          return;
        }
        this.pageCount = {
          count: Math.ceil(this.totalElements / this._pageSize),
          current: this.pageCount.current,
        };
        this.data = this.createData(data.content || []);
      });
  }

  createData(data: any[]) {
    return data.map(item => {
      item.pacFlag = item.pacFlag ? item.pacFlag : '';

      const accountType = ACCOUNT_TYPE_CONFIG.options.find(accountType => accountType.value === item.accountType);
      item.accountType = accountType ? accountType.label : '';

      item.txnCodeKey = item.txnCode;
      item.txnCode = TxnCodeMap.get(item.txnCode);

      const subActivityCd = ACTIVITY_CODE_CONFIG.options.find(subActivityCd => subActivityCd.value === item.subActivityCd);
      item.subActivityCd = subActivityCd ? subActivityCd.label : '';

      item.txnEffectiveDate = item.txnEffectiveDate ? moment(new Date(item.txnEffectiveDate)).format('DD/MM/YYYY') : '';
      item.runDate = item.runDate ? moment(new Date(item.runDate)).format('DD/MM/YYYY') : '';
      item.contStart = item.contStart ? moment(new Date(item.contStart)).format('DD/MM/YYYY') : '';
      item.contEnd = item.contEnd ? moment(new Date(item.contEnd)).format('DD/MM/YYYY') : '';
      if (!item.totalPremiumReceived) {
        item.totalPremiumReceived = 0;
      }
      if (!item.commissionableAmount) {
        item.commissionableAmount = 0;
      }
      const agentList = item.list || [];
      const agentCode: any = {};

      item.list = agentList
        .sort((a: any, b: any) => b.scaleRate - a.scaleRate)
        .map((agent: any) => {
          if (item.txnCodeKey === TxnCode.COMMISSION_ADJUSTMENT || !agent.scaleRate) {
            agent.scaleRate = '';
          }
          agent.commissionCode = agent.commissionCode ? CommissionCode[agent.commissionCode] : '';
          agent.amountDp = CommissionPCCode[agent.commissionCode] ? 0 : 2;
          return agent;
        })
        .sort((a: any, b: any) => a.commissionCode - b.commissionCode)
        .map((agent: any) => {
          const isHasCode = agentCode[agent.agentCode];

          if (!isHasCode) {
            agent.agentCodeCount = 1;
            agentCode[agent.agentCode] = agent;
          } else {
            agentCode[agent.agentCode].agentCodeCount++;
          }

          return agent;
        })
        .sort((a: any, b: any) => a.agentCode - b.agentCode);

      return item;
    });
  }

  openAlertPop(key: string) {
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      data: {
        message: key,
      },
      size: CdPopupSize.MEDIUM,
    });
    popupRef.afterClosed().subscribe(() => {});
  }

  alertFutherInfo() {
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      data: {
        message: I18N_KEY.ORIGINAL_ER_MER_FURTHERINFO,
      },
    });
    popupRef.afterClosed().subscribe(() => {});
  }
}
