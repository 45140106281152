import { Injectable } from '@angular/core';
import { TRANSFER_STATUS } from 'src/app/config/transfer-status.config';

@Injectable({
  providedIn: 'root',
})
export class TransferStatusService {
  constructor() {}

  getTransferStatusInfo(val: number) {
    return TRANSFER_STATUS.find(item => item.value === val);
  }
}
