<cds-navigation class="utility-header" bannerLogo="logo:Manulife_txt_rgb_HongKong">
  <!-- sidebar Start -->
  <cds-menu>
    <cds-menu-item #menuItem icon="transport:subway" routerLink="/chatbot/cso" label="CSO"> </cds-menu-item>
    <cds-menu-item #menuItem icon="arrow:history" routerLink="/chatbot/history" label="History"> </cds-menu-item>
  </cds-menu>
  <!-- sidebar End -->

  <!-- headbar Start -->
  <cds-navigation-utilities>
    <cds-navigation-lang-utility
      [ngStyle]="{ display: isHideNavigationLang ? 'none' : 'unset' }"
      [(selected)]="selectedLang"
      [options]="langList"
      (selectedChange)="changeLang($event)">
    </cds-navigation-lang-utility>
    <cds-navigation-button-utility label="{{ 'Logout' | lang }}" (cdsClick)="logout()"> </cds-navigation-button-utility>
  </cds-navigation-utilities>
  <!-- headbar End -->

  <!-- content container -->
  <cds-content id="contenContainer" style="height: calc(100% - 96px); display: flex; flex-direction: column">
    <!-- <app-admin-header></app-admin-header> -->
    <div class="layout-content div-bg-light-grey" style="height: 100%">
      <div class="app-main__outer" style="height: 100%">
        <div class="app-main__inner p-0" style="height: 100%">
          <div [@architectUIAnimation]="o.isActivated ? o.activatedRoute : ''" style="height: 100%">
            <router-outlet #o="outlet"></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </cds-content>
  <!-- footer -->
  <!-- <app-cds-navigation-footer-local
    [config]="footerConfig"
    class="l-ma-0"></app-cds-navigation-footer-local> -->
  <div class="footer l-pl-7 l-pr-7">
    <div>
      <div class="logo-wrapper">
        <cds-icon class="logo" icon="logo:Manulife_rgb_w" [config]="{ color: 'var(--cds-bg-info)' }"></cds-icon>
      </div>
    </div>
    <div class="text-wrapper">
      <span class="text cds-detail1">{{ 'common.footerText' | lang }}</span>
    </div>
  </div>
</cds-navigation>

<!-- <ngx-loading-bar [color]="'#3f6ad8'"></ngx-loading-bar> -->
