<div class="sw-update-ask">
  <div class="l-d-f l-ai-cen sound-warpper">
    <div class="sound-low-box">
      <cds-icon icon="action:sound_low"></cds-icon>
    </div>
    <div class="tip-box">
      <div>
        <span class="cds-body1-demibold cds-coral-text">{{
          '“ATTENTION: EDIT/UPDATE is disabled while the Commission Batch process is running.”' | lang
        }}</span>
      </div>
    </div>
  </div>
</div>
