import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comm-batch-running',
  templateUrl: './comm-batch-running.component.html',
  styleUrls: ['./comm-batch-running.component.scss'],
})
export class CommBatchRunningComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
