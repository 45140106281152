import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { SubActivityOpts, AccountTypesJournal, TxnCodeOpts } from 'src/app/core/models/enum';
import { ColumnConfig } from 'src/app/shared/data-table';

export const JOURNAL_COLUMNS_NEW: Array<ColumnConfig> = [
  { key: 'runDate', title: 'commission.common.runDate' },
  { key: 'employerAccountCode', title: 'commission.common.accountNo' },
  { key: 'memberAccountCode', title: 'commission.common.memberNo' },
  { key: 'accountType', title: 'commission.common.accountType' },
  { key: 'payRollGroupId', title: 'commission.common.payroolGroupId' },
  { key: 'subActivityCd', title: 'commission.common.subActivity' },
  { key: 'contStart', title: 'commission.common.contStart' },
  { key: 'contEnd', title: 'commission.common.contEnd' },
];

export const EXCEL_COLUMNS: Array<string> = [
  'Run date',
  'Employer Account Code',
  'Member Account code',
  'Account Type',
  'Payroll Group Id',
  'Transaction Reference No.',
  'Group Reference No.',
  'Payment Submission Reference No.',
  'Sub Activity',
  'Original ER/Member Code',
  'Txn',
  'Comm Flag',
  'Cont.Start',
  'Cont.End',
  'Txn Effective Date',
  'Contribution Frequency',
  'MPF PAC or ORSO PS Flag',
  'Processor',
  'Total Premium Received',
  'Commissionable Amount',
  'Agent Code',
  'Share',
  'Scale Rate',
  'Comm Code',
  'Amount',
  'Premium',
];

export const AGENT_INFO_COLUMNS: Array<ColumnConfig> = [
  { key: 'agentCode', title: 'commission.common.agentCode' },
  { key: 'share', title: 'commission.journal.share' },
  { key: 'scaleRate', title: 'commission.journal.scaleRate' },
  { key: 'commissionCode', title: 'commission.journal.commCode' },
  { key: 'amount', title: 'commission.journal.amount' },
  { key: 'amountPremium', title: 'commission.journal.preminum' },
];

export const PAGE_SIZE = 5;
export const FILE_NAME = 'Txn_report';
export const FILE_HEADER = 'Report Date:';
export const DOWN_LOAD_MAX = 200000;

export const I18N_KEY = {
  DATE_From_NULL: 'commission.journal.dateFromNull',
  DATE_TO_NULL: 'commission.journal.dateToNull',
  INVALID_RUN_DATE: 'commission.common.runDate.invalid',
  DATE_RANGE_INCOMPLETE: 'commission.common.dateRangeError',
  INVALID_DATE_RANGE: 'commission.common.dateRangeInvalid',
  ACCOUNT_NO_INVALID: 'commission.common.accountNo.invalid',
  MEMBER_NO_INVALID: 'commission.common.memberNo.invalid',
  ORIGINAL_ER_MER_INVALID: 'common.journal.originalErMerCode.error',
  ORIGINAL_ER_MER_FURTHERINFO: 'common.journal.originalErMerCode.futherInfo',
  AGENT_CODE_INVALID: 'commission.common.agentCode.invalid',
  DOWN_LOAD_MAX_LIMMIT: 'commission.journal.download.limmit',
  DOWN_LOAD_MAX_FAIL: 'commission.journal.download.fail',
  COMMON_GOT: 'common.gotItWithExclamation',
  REFERENCE_NO_INVALID: 'commission.common.referenceNo.invalid',
};

export const ACCOUNT_TYPE_CONFIG: CdsDropdownConfig = {
  label: 'commission.common.accountType',
  placeholder: 'commission.common.accountType.placeholder',
  options: AccountTypesJournal,
};

export const TXN_CONFIG: CdsDropdownConfig = {
  label: 'commission.common.txn',
  placeholder: 'commission.journal.placeholder.txn',
  options: TxnCodeOpts,
};

export const ACTIVITY_CODE_CONFIG: CdsDropdownConfig = {
  label: 'commission.common.subActivity',
  placeholder: 'commission.common.placeholder.subActivity',
  options: SubActivityOpts,
};
