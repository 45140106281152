<app-cd-popup>
  <!-- <cds-popup-title>Add New Event</cds-popup-title> -->
  <cds-popup-content>
    <div class="content l-pl-6 l-pr-6">
      <div class="cds-h2-light l-mb-0 l-pb-1">
        {{ 'calendar.action.details' | lang }}
      </div>
      <span class="cds-body2-light cds-coral-text dark-1">{{ 'common.fillIn' | lang }}</span>

      <div class="l-pt-4 l-pb-4">
        <div class="col-xs-12 col-md-8 l-plr-0 cds-body2 l-mb-0">
          {{ 'calendar.event.name' | lang }}
        </div>
        <div class="cds-demibold l-mb-0 l-pb-2 l-pt-2">
          {{ data.event.title | lang }}
        </div>
      </div>

      <div class="row l-plr-0" [formGroup]="formGroup">
        <div class="col-xs-12 col-md-12 l-plr-0 cds-body2 l-mb-0">
          {{ 'calendar.event.date' | lang }}
          <span class="cds-coral-text dark-1">*</span>
        </div>
        <div class="col-xs-12 col-md-6 l-plr-0 l-pb-2 l-pt-2" *ngIf="hasPermission && isShowDatePicker">
          <cds-textfield placeholder="Input Date" [cdsDatepicker]="picker" formControlName="eventDate"> </cds-textfield>
          <cds-datepicker #picker="cdsDatepicker"></cds-datepicker>
          <div style="min-height: 32px">
            <cds-assistive-text class="l-pt-3" *ngIf="eventDate?.errors && eventDate?.dirty">
              {{ eventDate.errors?.['error'] | lang}}
            </cds-assistive-text>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 cds-demibold l-mb-0 l-pb-2 l-pt-2" *ngIf="!hasPermission">
          {{ eventDate.value }}
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [style]="'secondary'" [disabled]="resetDisabled" size="sm" (click)="reset()">{{ 'common.reset' | lang }}</cds-button>
    <cds-button size="sm" [disabled]="saveDisabled" (click)="onSubmit()">{{ 'common.save' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
