<div class="md-iff-summary">
  <div class="head-box">
    <div>
      <span class="head">MD_IFF Summary</span>
    </div>
    <div class="l-d-f l-ai-cen cursor-pointer" (click)="quickGuide()">
      <div>
        <cds-icon class="icon-question" icon="contact:question"></cds-icon>
      </div>
      <div class="l-ml-2">
        <span class="cds-body1-demibold" style="color: var(--cds-color-dark-1-green)">Quick Guide</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="rolePriority === 'SalesJourneyAgent'">
    <div class="l-pt-7 l-ml-7 l-d-f l-ai-cen">
      <cds-icon (click)="onBackSummayIconClick()" class="cursor-pointer" [config]="backSummaryIconConfig" [icon]="backSummaryIcon"> </cds-icon>
      <div class="back-to-product-list">
        <span (click)="onBackSummayIconClick()" class="cds-body1-demibold">Back to Product List</span>
      </div>
    </div>
  </ng-container>

  <div class="prodtype-drop-employer-name">
    <div class="padding-none">
      <div class="choose-product-type">
        <div class="l-mb-4">
          <span class="cds-body2">Choose product Type</span>
        </div>
        <app-cd-dropdown
          [placeholder]="'Choose product type'"
          [selected]="prodTypeFormControl.value"
          (selectedChange)="prodTypeChange($event)"
          [toppingList]="productTypeOptions"></app-cd-dropdown>
      </div>
    </div>

    <ng-container *ngIf="rolePriority === 'SalesJourneyAgent'">
      <div class="spherical-wapper" *ngIf="prodTypeFormControl.value === SalesJourneyProdType.Individual">
        <div>
          <div
            class="l-d-f l-ai-cen l-jc-sb spherical-box"
            [ngClass]="[productTypeCount.pa.allPendingCount && productTypeCount.pa.allPendingCount > 0 ? 'gold' : '']"
            (click)="onClickGetDetailsByProductType('PA')">
            <div class="l-d-f l-ai-cen left">
              <div class="spherical l-d-f l-ai-cen">
                <div>
                  <span class="cds-h6-demibold">{{ productTypeCount.pa.allPendingCount }}</span>
                </div>
              </div>
              <div class="l-ml-3">
                <span class="cds-h6-demibold">PA Pending items</span>
              </div>
            </div>
            <cds-icon class="l-ml-3" icon="arrow:chevron_r"></cds-icon>
          </div>
        </div>
        <div>
          <div
            class="l-d-f l-ai-cen l-jc-sb spherical-box"
            [ngClass]="[productTypeCount.tvc.allPendingCount && productTypeCount.tvc.allPendingCount > 0 ? 'gold' : '']"
            (click)="onClickGetDetailsByProductType('TVC')">
            <div class="l-d-f l-ai-cen left">
              <div class="spherical l-d-f l-ai-cen">
                <div>
                  <span class="cds-h6-demibold">{{ productTypeCount.tvc.allPendingCount }}</span>
                </div>
              </div>
              <div class="l-ml-3">
                <span class="cds-h6-demibold">TVC Pending items</span>
              </div>
            </div>
            <cds-icon class="l-ml-3" icon="arrow:chevron_r"></cds-icon>
          </div>
        </div>
        <div>
          <div
            class="l-d-f l-ai-cen l-jc-sb spherical-box"
            [ngClass]="[productTypeCount.svc.allPendingCount && productTypeCount.svc.allPendingCount > 0 ? 'gold' : '']"
            (click)="onClickGetDetailsByProductType('SVC')">
            <div class="l-d-f l-ai-cen left">
              <div class="spherical l-d-f l-ai-cen">
                <div>
                  <span class="cds-h6-demibold">{{ productTypeCount.svc.allPendingCount }}</span>
                </div>
              </div>
              <div class="l-ml-3">
                <span class="cds-h6-demibold">SVC Pending items</span>
              </div>
            </div>
            <cds-icon class="l-ml-3" icon="arrow:chevron_r"></cds-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="tabs-box">
    <app-cd-fake-tabs
      #cdFakeTabs
      [options]="['MD_IFF Summary', 'eMPF Summary Page']"
      [(selectedIndex)]="tabIndex"
      (selectedIndexChange)="tabIndexChange($event)"></app-cd-fake-tabs>
  </div>

  <div *ngIf="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SalesJourneyAgent'">
    <app-empf-agent-ind [rolePriority]="rolePriority"></app-empf-agent-ind>
  </div>

  <div *ngIf="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SalesJourneyAgent'">
    <app-empf-agent-sep [rolePriority]="rolePriority"></app-empf-agent-sep>
  </div>

  <div *ngIf="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SalesJourneyAgent'">
    <app-empf-agent-er [rolePriority]="rolePriority"></app-empf-agent-er>
  </div>

  <div *ngIf="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SuperAdmin'">
    <app-empf-superadmin-ind [rolePriority]="rolePriority"></app-empf-superadmin-ind>
  </div>

  <div *ngIf="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SuperAdmin'">
    <app-empf-superadmin-sep [rolePriority]="rolePriority"></app-empf-superadmin-sep>
  </div>

  <div *ngIf="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SuperAdmin'">
    <app-empf-superadmin-er [rolePriority]="rolePriority"></app-empf-superadmin-er>
  </div>

  <div *ngIf="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SalesSupport'">
    <app-empf-salessupport-ind [rolePriority]="rolePriority"></app-empf-salessupport-ind>
  </div>

  <div *ngIf="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SalesSupport'">
    <app-empf-salessupport-sep [rolePriority]="rolePriority"></app-empf-salessupport-sep>
  </div>

  <div *ngIf="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SalesSupport'">
    <app-empf-salessupport-er [rolePriority]="rolePriority"></app-empf-salessupport-er>
  </div>

  <div *ngIf="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SalesJourneyAgent'">
    <app-mdiff-agent-ind [rolePriority]="rolePriority"></app-mdiff-agent-ind>
  </div>

  <div *ngIf="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SalesJourneyAgent'">
    <app-mdiff-agent-sep [rolePriority]="rolePriority"></app-mdiff-agent-sep>
  </div>

  <div *ngIf="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SalesJourneyAgent'">
    <app-mdiff-agent-er [rolePriority]="rolePriority"></app-mdiff-agent-er>
  </div>

  <div *ngIf="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SuperAdmin'">
    <app-mdiff-superadmin-ind [rolePriority]="rolePriority"></app-mdiff-superadmin-ind>
  </div>

  <div *ngIf="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SuperAdmin'">
    <app-mdiff-superadmin-sep [rolePriority]="rolePriority"></app-mdiff-superadmin-sep>
  </div>

  <div *ngIf="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SuperAdmin'">
    <app-mdiff-superadmin-er [rolePriority]="rolePriority"></app-mdiff-superadmin-er>
  </div>

  <div *ngIf="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SalesSupport'">
    <app-mdiff-salessupport-ind [rolePriority]="rolePriority"></app-mdiff-salessupport-ind>
  </div>

  <div *ngIf="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SalesSupport'">
    <app-mdiff-salessupport-sep [rolePriority]="rolePriority"></app-mdiff-salessupport-sep>
  </div>

  <div *ngIf="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SalesSupport'">
    <app-mdiff-salessupport-er [rolePriority]="rolePriority"></app-mdiff-salessupport-er>
  </div>
</div>
