<div class="row">
  <div class="col-xs-12 l-mt-7 cds-color-green anchor-parent">
    <div class="anchor-element" id="moreEmployerInfoDetails"></div>
    <span class="cds-h4-demibold">{{ 'er.more-employer-info-details' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<div class="row p-tab l-pt-2 l-plr-0 l-pb-7">
  <div class="col-xs-12">
    <cds-tab-group [selectedIndex]="tabIndex" (selectedTabIndexChange)="selectedTabIndexChange($event)">
      <cds-tab label="eMPF Workflow">
        <app-empf-workflow *ngIf="tabIndex === 0" [id]="id" [isEr]="true"></app-empf-workflow>
      </cds-tab>
    </cds-tab-group>
  </div>
</div>
