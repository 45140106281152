import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerRoutingModule } from './employer-routing.module';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { HttpClientModule } from '@angular/common/http';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { EmployerProfileComponent } from './employer-profile/employer-profile.component';
import { EmployerProfileDetailComponent } from './employer-profile-detail/employer-profile-detail.component';
import { EmployerProfileTitleComponent } from './employer-profile-title/employer-profile-title.component';
import { EmployerProfileInformationComponent } from './employer-profile-information/employer-profile-information.component';
import { EmployerProfileBillingClassComponent } from './employer-profile-billing-class/employer-profile-billing-class.component';
import { EmployerProfileInvestedComponent } from './employer-profile-invested/employer-profile-invested.component';
import { PayrollGroupInfoComponent } from './payroll-group-info/payroll-group-info.component';
import { SchemeInfoComponent } from './payroll-group-info/scheme-info/scheme-info.component';
import { ContactPersionComponent } from './payroll-group-info/contact-persion/contact-persion.component';
import { InvestedFundsInfoComponent } from './payroll-group-info/invested-funds-info/invested-funds-info.component';
import { VestingScaleComponent } from './payroll-group-info/vesting-scale/vesting-scale.component';
import { PaymentDetailsComponent } from './payroll-group-info/payment-details/payment-details.component';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { CdsTextareaModule } from '@cds/ng-web-components/textarea';
import { CommentsHistoryComponent } from 'src/app/views/employer/commonts-history/comments-history.component';
import { CommontsPopupComponent } from './commonts-popup/commonts-popup.component';
import { CommontsSuccessComponent } from './commonts-success/commonts-success.component';
import { ExpandTableModule } from 'src/app/components/table/expand-table.module';
import { CdAccordionModule } from 'src/app/components/accordion/cd-accordion.module';
import { RowItemModule } from 'src/app/components/row-item/row-item.module';
import { EmployerListComponent } from './employer-list/employer-list.component';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { AgentInfoComponent } from './payroll-group-info/agent-info/agent-info.component';
import { AccountInfoComponent } from './payroll-group-info/account-info/account-info.component';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { MemberLevelComponent } from './payroll-group-info/member-level/member-level.component';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { EmployerProfileCompanyListComponent } from './employer-profile-company-list/employer-profile-company-list.component';
import { MatRadioModule } from '@angular/material/radio';
import { CdsRadioModule } from '@cds/ng-web-components/radio';
import { EditCompanyListComponent } from './employer-profile-company-list/edit-company-list/edit-company-list.component';
import { InlineAlertComponent } from './employer-profile-company-list/inline-alert/inline-alert.component';
import { EmployerProfilePaymentNoticeComponent } from './employer-profile-payment-notice/employer-profile-payment-notice.component';
import { LoadingModule } from 'src/app/shared/loading/loading.modules';
import { EmployerProfileSpecialQuoteComponent } from './employer-profile-special-quote/employer-profile-special-quote.component';
import { OutstandingAmountOfPaymentPopupComponent } from './payroll-group-info/outstanding-amount-of-payment-popup/outstanding-amount-of-payment-popup.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddSpecialQuoteComponent } from './employer-profile-special-quote/add-special-quote/add-special-quote.component';
import { EmployerDetailQuickAccessComponent } from './employer-detail-quick-access/employer-detail-quick-access.component';
import { CdsPillModule } from '@cds/ng-web-components/pill';
import { AmountTableComponent } from './payroll-group-info/amount-table/amount-table.component';
import { BillReferenceComponent } from './payroll-group-info/bill-reference/bill-reference.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdsAccordionModule } from '@cds/ng-web-components/accordion';
import { SuspenseRecordComponent } from './payroll-group-info/suspense-record/suspense-record.component';
import { PaymentRecordComponent } from './payroll-group-info/payment-record/payment-record.component';
import { PaymentRecordDetailsComponent } from './payroll-group-info/payment-record-details/payment-record-details.component';
import { SpecialManagementPopupComponent } from './special-management-popup/special-management-popup.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { ActivityRecordsComponent } from './activity-records/activity-records.component';
import { AddActivityComponent } from './add-activity/add-activity.component';
import { PayrollGroupActivityRecordComponent } from './payroll-group-activity-record/payroll-group-activity-record.component';
import { EditCompletedActivityComponent } from './edit-completed-activity/edit-completed-activity.component';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { SqLogMasterListComponent } from './sq-log-master-list/sq-log-master-list.component';
import { AgentSepComponent } from './agent-sep/agent-sep.component';
import { UploadAgentSepListFileComponent } from './upload-agent-sep-list-file/upload-agent-sep-list-file.component';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';
import { AgentSepEditComponent } from './agent-sep-edit/agent-sep-edit.component';
import { AgentSepAddComponent } from './agent-sep-add/agent-sep-add.component';
import { FundBenifitComponent } from './fund-benifit/fund-benifit.component';
import { UnitPriceComponent } from './unit-price/unit-price.component';
import { UploadUnitPriceListFileComponent } from './upload-unit-price-list-file/upload-unit-price-list-file.component';
import { CdsInlineAlertModule } from '@cds/ng-web-components/inline-alert';
import { InterestRateComponent } from './interest-rate/interest-rate/interest-rate.component';
import { InterestRateAddPopupComponent } from './interest-rate/interest-rate-add-popup/interest-rate-add-popup.component';
import { InterestRateHistoryPopupComponent } from './interest-rate/interest-rate-history-popup/interest-rate-history-popup.component';
import { EmpfWorkflowModule } from '../empf-workflow/empf-workflow.module';
import { FormulaClassComponent } from './payroll-group-info/formula-class/formula-class.component';
import { ChargeInfoComponent } from './payroll-group-info/charge-info/charge-info.component';
import { MoreInfoDetailsComponent } from './more-info-details/more-info-details.component';
import { DividendRateComponent } from './dividend-rate/dividend-rate/dividend-rate.component';
import { DividendRateAddPopupComponent } from './dividend-rate/dividend-rate-add-popup/dividend-rate-add-popup.component';

@NgModule({
  declarations: [
    EmployerProfileComponent,
    EmployerProfileDetailComponent,
    EmployerProfileTitleComponent,
    EmployerProfileInformationComponent,
    EmployerProfileBillingClassComponent,
    EmployerProfileInvestedComponent,
    PayrollGroupInfoComponent,
    SchemeInfoComponent,
    ContactPersionComponent,
    InvestedFundsInfoComponent,
    VestingScaleComponent,
    PaymentDetailsComponent,
    CommentsHistoryComponent,
    CommontsPopupComponent,
    CommontsSuccessComponent,
    EmployerListComponent,
    AgentInfoComponent,
    AccountInfoComponent,
    MemberLevelComponent,
    EmployerProfileCompanyListComponent,
    EditCompanyListComponent,
    InlineAlertComponent,
    EmployerProfilePaymentNoticeComponent,
    OutstandingAmountOfPaymentPopupComponent,
    EmployerProfileSpecialQuoteComponent,
    AddSpecialQuoteComponent,
    EmployerDetailQuickAccessComponent,
    AmountTableComponent,
    BillReferenceComponent,
    SuspenseRecordComponent,
    PaymentRecordComponent,
    PaymentRecordDetailsComponent,
    SpecialManagementPopupComponent,
    ActivityRecordsComponent,
    AddActivityComponent,
    PayrollGroupActivityRecordComponent,
    EditCompletedActivityComponent,
    SqLogMasterListComponent,
    AgentSepComponent,
    UploadAgentSepListFileComponent,
    AgentSepEditComponent,
    AgentSepAddComponent,
    FundBenifitComponent,
    UnitPriceComponent,
    UploadUnitPriceListFileComponent,
    InterestRateComponent,
    InterestRateAddPopupComponent,
    InterestRateHistoryPopupComponent,
    FormulaClassComponent,
    ChargeInfoComponent,
    MoreInfoDetailsComponent,
    DividendRateComponent,
    DividendRateAddPopupComponent,
  ],

  imports: [
    CommonModule,
    EmployerRoutingModule,
    CdsTextfieldModule,
    CdsLanguageModule,
    CdsIconModule,
    CdsButtonModule,
    CdsPaginationModule,
    FormsModule,
    CdsPopupModule,
    HttpClientModule,
    CdsProgressModule,
    CdsTableModule,
    CdsSortModule,
    CdsCheckboxModule,
    CdsTabsModule,
    CdsDropdownModule,
    CdsTooltipModule,
    ReactiveFormsModule,
    CdsTextareaModule,
    ExpandTableModule,
    CdAccordionModule,
    RowItemModule,
    CdsDatepickerModule,
    CdsSearchModule,
    MatRadioModule,
    CdsRadioModule,
    LoadingModule,
    SharedModule,
    CdsPillModule,
    BrowserAnimationsModule,
    CdsAccordionModule,
    MatAutocompleteModule,
    MatSelectModule,
    CdsAssistiveTextModule,
    CdsFormFieldModule,
    CdsFileUploadModule,
    CdsInlineAlertModule,
    EmpfWorkflowModule,
  ],

  exports: [ContactPersionComponent],
})
export class EmployerModule {}
