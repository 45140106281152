/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_POPUP_DATA, CdsPopupService } from '@cds/ng-web-components/popup';
import { WillBeLostComponent } from 'src/app/shared/will-be-lost/will-be-lost.component';
import { HighRiskFileType } from '../high-risk-file-upload-center.model';
import { NotifyService } from 'src/app/core/services/notify.service';
import { HighRiskFileUploadCenterService } from 'src/app/core/services/high-risk-file-upload-center/high-risk-file-upload-center.service';
import { finalize } from 'rxjs';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { CdPopupService } from 'src/app/shared/cd-popup';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent implements OnInit {
  formGroup!: FormGroup;
  HighRiskFileType = HighRiskFileType;
  constructor(
    public dialogRef: MatDialogRef<UploadDocumentComponent>,
    private cdsPopup: CdsPopupService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      fileType: HighRiskFileType;
    },
    private notifyService: NotifyService,
    private highRiskFileUploadCenterService: HighRiskFileUploadCenterService,
    private cdPopupService: CdPopupService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        fileUploadInfoList: new FormArray([]),
      },
      [
        (control: AbstractControl): ValidationErrors | null => {
          const fileUploadInfoList = control.get('fileUploadInfoList') as FormArray;
          const atLeastOneFile = fileUploadInfoList.controls.some(item => item.get('file')?.value);
          const noError = fileUploadInfoList.controls.every(item => !item.get('errorMessage')?.value);
          if (atLeastOneFile && noError) {
            return null;
          } else {
            return { invalid: true };
          }
        },
      ]
    );
    this.addItem();
  }

  get fileUploadInfoList() {
    return this.formGroup.get('fileUploadInfoList') as FormArray;
  }

  tranToFormGroup(uploadinfo: AbstractControl) {
    return uploadinfo as FormGroup;
  }

  getFileControl(uploadinfo: AbstractControl) {
    return this.tranToFormGroup(uploadinfo).get('fileControl') as FormControl;
  }

  getErrorMessage(uploadinfo: AbstractControl) {
    return this.tranToFormGroup(uploadinfo).get('errorMessage')?.value;
  }

  addItem() {
    const fileControl = new FormControl();
    const errorMessage = new FormControl('');
    const file = new FormControl();
    this.fileUploadInfoList.push(
      new FormGroup({
        fileControl: fileControl,
        errorMessage: errorMessage,
        file: file,
      })
    );
    fileControl.valueChanges.subscribe((value: string) => {
      if (value === null) {
        errorMessage.setValue('');
        file.reset();
      }
    });
  }

  removeItem(index: number) {
    this.fileUploadInfoList.removeAt(index);
  }

  cancel() {
    if (this.formGroup.invalid) {
      this.dialogRef.close({ agree: false });
      return;
    }
    const popupRef: MatDialogRef<WillBeLostComponent> = this.cdsPopup.open(WillBeLostComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(({ agree }) => {
      if (agree) {
        this.dialogRef.close({ agree: false });
      }
    });
  }

  fileChange(event: any, uploadinfo: AbstractControl) {
    const info = this.tranToFormGroup(uploadinfo);
    const file = info.get('file');
    const errorMessage = info.get('errorMessage');
    if (file && errorMessage) {
      file.setValue(event?.target?.files[0]);
      if (file.value.size > 8 * 1024 * 1024) {
        errorMessage.setValue('Your file is exceeding the file size limit of 8 MB.');
      } else if (!this.fileNameVerify(file)) {
        errorMessage.setValue('Invalid file name');
      } else {
        errorMessage.setValue('');
        if (this.fileUploadInfoList.length < 1) {
          this.addItem();
        }
      }
    }
  }

  private fileNameVerify(file: AbstractControl) {
    if (file.value.name) {
      if ([HighRiskFileType.High_Risk_Country_ORSO].includes(this.data.fileType)) {
        return /^AML_High_Risk_Country_ORSO_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      } else if ([HighRiskFileType.High_Risk_Country].includes(this.data.fileType)) {
        return /^AML_High_Risk_Country_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      } else if ([HighRiskFileType.High_Risk_Business_Nature].includes(this.data.fileType)) {
        return /^AML_High_Risk_Business_Nature_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      } else if ([HighRiskFileType.High_Risk_Nationality_ORSO].includes(this.data.fileType)) {
        return /^AML_High_Risk_Nationality_ORSO_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      } else if ([HighRiskFileType.High_Risk_Nationality].includes(this.data.fileType)) {
        return /^AML_High_Risk_Nationality_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      } else if ([HighRiskFileType.High_Risk_Occupation].includes(this.data.fileType)) {
        return /^AML_High_Risk_Occupation_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      } else if ([HighRiskFileType.High_Risk_Occupation_Keyword].includes(this.data.fileType)) {
        return /^High_Risk_Occupation_Keyword_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      } else if ([HighRiskFileType.High_Risk_Business_Nature_Keyword].includes(this.data.fileType)) {
        return /^High_Risk_Business_Nature_Keyword_\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(file.value.name.split('.')[0]);
      }
    }
    return true;
  }

  upload() {
    if (this.formGroup.valid) {
      this.notifyService.screenLoadingSubject.next(true);
      const file = this.fileUploadInfoList.controls[0].value.file;
      this.highRiskFileUploadCenterService
        .uploadFile(file, this.data.fileType)
        .pipe(
          finalize(() => {
            this.notifyService.screenLoadingSubject.next(false);
          })
        )
        .subscribe(res => {
          if (res.result === ResponseResult.SUCCESS) {
            this.dialogRef.close({ agree: true });
            this.cdPopupService.openCommon({ data: { message: 'common.action.uploadSuccess' } });
          } else {
            this.tranToFormGroup(this.fileUploadInfoList.controls[0]).get('errorMessage')?.setValue(res.data);
          }
        });
    }
  }
}
