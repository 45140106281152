import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsStepperModule } from '@cds/ng-web-components/stepper';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { CdsCheckboxListModule, CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { SharedModule } from '../../shared/shared.module';
import { AdviseEmpfrefComponent } from './case-summary/advise-empfref/advise-empfref.component';
import { AdviseEmpfstatusComponent } from './case-summary/advise-empfstatus/advise-empfstatus.component';
import { AdviseIffstatusComponent } from './case-summary/advise-iffstatus/advise-iffstatus.component';
import { CaseSummaryComponent } from './case-summary/case-summary.component';
import { ProductTypeSummaryComponent } from './product-type-summary/product-type-summary.component';
import { SalesjourneyRoutingModule } from './salesjourney-routing.module';
import { DeclarationIffComponent } from './case-summary/declaration-iff/declaration-iff.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ComponentsModule } from 'src/app/components/components.module';
import { EmpfDeclarationFormComponent } from './empf-declaration-form/empf-declaration-form.component';
import { CdsRadioModule } from '@cds/ng-web-components/radio';
import { EmpfDeclarationConfirmationComponent } from './empf-declaration-confirmation/empf-declaration-confirmation.component';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { ExitIffWarnPopupComponent } from './case-summary/declaration-iff/exit-iff-warn-popup/exit-iff-warn-popup.component';
import { EMPFRefNoUsedComponent } from './case-summary/declaration-iff/e-mpf-ref-no-used/e-mpf-ref-no-used.component';
import { CdsLinkModule } from '@cds/ng-web-components/link';
import { IffSubmissionSuccPopupComponent } from './case-summary/declaration-iff/iff-submission-succ-popup/iff-submission-succ-popup.component';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { DisplayDetailsByProductTypeComponent } from './case-summary/display-details-by-product-type/display-details-by-product-type.component';
import { AgentConfirmationComponent } from './agent-confirmation/agent-confirmation.component';
import { SplitAgentNotificationInfoErrorComponent } from './agent-confirmation/split-agent-notification-info-error/split-agent-notification-info-error.component';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { DeclarationIffErComponent } from './case-summary/declaration-iff-er/declaration-iff-er.component';
import { SplitAgentNotificationInfoSuccessComponent } from './agent-confirmation/split-agent-notification-info-success/split-agent-notification-info-success.component';
import { GgiDeclarationPopupComponent } from './case-summary/ggi-declaration-popup/ggi-declaration-popup.component';
import { SendIffInBatchSummaryComponent } from './send-iff-in-batch-summary/send-iff-in-batch-summary.component';
import { SubmitSuccessComponent } from './send-iff-in-batch-summary/submit-success/submit-success.component';
import { ErSendIffInBatchSummaryComponent } from './er-send-iff-in-batch-summary/er-send-iff-in-batch-summary.component';
import { ErSubmitSuccessComponent } from './er-send-iff-in-batch-summary/er-submit-success/er-submit-success.component';
import { CaseDetailsErComponent } from './case-details-er/case-details-er.component';
import { DifferentAgentStatusComponent } from './different-agent-status/different-agent-status.component';
import { AdviseTransferStatusComponent } from './case-summary/advise-transfer-status/advise-transfer-status.component';
import { ResendEiffComponent } from './resend-eiff/resend-eiff.component';
import { ResendEiffSubmitSuccessComponent } from './resend-eiff/resend-eiff-submit-success/resend-eiff-submit-success.component';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { EditAgentDetailsComponent } from './edit-agent-details/edit-agent-details.component';
import { LeastOneWarningComponent } from './edit-agent-details/least-one-warning/least-one-warning.component';
import { AddNewCommissionPopupComponent } from './edit-agent-details/add-new-commission-popup/add-new-commission-popup.component';
import { PaperSummaryComponent } from './paper-summary/paper-summary.component';
import { WsmCaseSummaryComponent } from './wsm-case-summary/wsm-case-summary.component';
import { AddNewCaseComponent } from './wsm-case-summary/add-new-case/add-new-case.component';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { IffDeclinedDashboardComponent } from './iff-declined-dashboard/iff-declined-dashboard.component';
import { CdsPieChartModule } from '@cds/ng-charts/pie';
import { RegulatedActivitiesComponent } from './regulated-activities/regulated-activities.component';
import { ResolvePopupComponent } from './iff-declined-dashboard/resolve-popup/resolve-popup.component';
import { SelectProductTypeComponent } from './iff-paper-form/select-product-type/select-product-type.component';
import { IndividualsComponent } from './iff-paper-form/individuals/individuals.component';
import { ResetWarningComponent } from './iff-paper-form/reset-warning/reset-warning.component';
import { EmployerComponent } from './iff-paper-form/employer/employer.component';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsFileUploadModule } from '@cds/ng-experimental/file-upload';
import { SalesSupportSummaryComponent } from './sales-support-summary/sales-support-summary/sales-support-summary.component';
import { VoidEiffPopupComponent } from './sales-support-summary/void-eiff-popup/void-eiff-popup.component';
import { MdIffSummaryComponent } from './md-iff-summary/md-iff-summary.component';
import { IffCompletionStepsPopupComponent } from './iff-completion-steps-popup/iff-completion-steps-popup.component';
import { IffStatusFlowPopupComponent } from './iff-status-flow-popup/iff-status-flow-popup.component';
import { EmpfAppStatusPopupComponent } from './empf-app-status-popup/empf-app-status-popup.component';
import { EmpfAgentIndComponent } from './md-iff-summary/empf-agent-ind/empf-agent-ind.component';
import { EmpfAgentErComponent } from './md-iff-summary/empf-agent-er/empf-agent-er.component';
import { EmpfSalessupportErComponent } from './md-iff-summary/empf-salessupport-er/empf-salessupport-er.component';
import { EmpfSalessupportIndComponent } from './md-iff-summary/empf-salessupport-ind/empf-salessupport-ind.component';
import { MdiffSalessupportIndComponent } from './md-iff-summary/mdiff-salessupport-ind/mdiff-salessupport-ind.component';
import { MdiffSalessupportErComponent } from './md-iff-summary/mdiff-salessupport-er/mdiff-salessupport-er.component';
import { MdiffAgentErComponent } from './md-iff-summary/mdiff-agent-er/mdiff-agent-er.component';
import { MdiffAgentIndComponent } from './md-iff-summary/mdiff-agent-ind/mdiff-agent-ind.component';
import { AddMpfReferenceNumberPopupComponent } from './add-mpf-reference-number-popup/add-mpf-reference-number-popup.component';
import { EmpfCaseDetailsComponent } from './empf-case-details/empf-case-details.component';
import { NewResendEiffComponent } from './new-resend-eiff/new-resend-eiff.component';
import { SentEiffPopupComponent } from './new-resend-eiff/sent-eiff-popup/sent-eiff-popup.component';
import { AddMdReferenceNumberPopupComponent } from './add-md-reference-number-popup/add-md-reference-number-popup.component';
import { ErEmpfCaseDetailsComponent } from './er-empf-case-details/er-empf-case-details.component';
import { CdsAccordionModule } from '@cds/ng-web-components/accordion';
import { EmpfAgentSepComponent } from './md-iff-summary/empf-agent-sep/empf-agent-sep.component';
import { MdiffAgentSepComponent } from './md-iff-summary/mdiff-agent-sep/mdiff-agent-sep.component';
import { EmpfSalessupportSepComponent } from './md-iff-summary/empf-salessupport-sep/empf-salessupport-sep.component';
import { MdiffSalessupportSepComponent } from './md-iff-summary/mdiff-salessupport-sep/mdiff-salessupport-sep.component';
import { IndividualComponent } from './generate-md/individual/individual.component';
import { HasBeenSentCustomerPopupComponent } from './generate-md/has-been-sent-customer-popup/has-been-sent-customer-popup.component';
import { PendingSplitAgentConfirPopupComponent } from './generate-md/pending-split-agent-confir-popup/pending-split-agent-confir-popup.component';
import { EmployerForMdComponent } from './generate-md/employer-for-md/employer-for-md.component';
import { SelectProductTypeForMdComponent } from './generate-md/select-product-type-for-md/select-product-type-for-md.component';
import { MdIffSelectProductTypeComponent } from './md-iff-summary/md-iff-select-product-type/md-iff-select-product-type.component';
import { ProductTypeCountDetailsPopupComponent } from './md-iff-summary/product-type-count-details-popup/product-type-count-details-popup.component';

@NgModule({
  declarations: [
    ProductTypeSummaryComponent,
    CaseSummaryComponent,
    AdviseEmpfrefComponent,
    AdviseIffstatusComponent,
    AdviseEmpfstatusComponent,
    DisplayDetailsByProductTypeComponent,
    DeclarationIffComponent,
    EmpfDeclarationFormComponent,
    EmpfDeclarationConfirmationComponent,
    ExitIffWarnPopupComponent,
    EMPFRefNoUsedComponent,
    IffSubmissionSuccPopupComponent,
    AgentConfirmationComponent,
    SplitAgentNotificationInfoErrorComponent,
    CaseDetailsComponent,
    DeclarationIffErComponent,
    SplitAgentNotificationInfoSuccessComponent,
    GgiDeclarationPopupComponent,
    SendIffInBatchSummaryComponent,
    SubmitSuccessComponent,
    ErSendIffInBatchSummaryComponent,
    ErSubmitSuccessComponent,
    CaseDetailsErComponent,
    DifferentAgentStatusComponent,
    AdviseTransferStatusComponent,
    ResendEiffComponent,
    ResendEiffSubmitSuccessComponent,
    SuperAdminComponent,
    EditAgentDetailsComponent,
    LeastOneWarningComponent,
    AddNewCommissionPopupComponent,
    PaperSummaryComponent,
    WsmCaseSummaryComponent,
    AddNewCaseComponent,
    IffDeclinedDashboardComponent,
    RegulatedActivitiesComponent,
    ResolvePopupComponent,
    SelectProductTypeComponent,
    IndividualsComponent,
    ResetWarningComponent,
    EmployerComponent,
    SalesSupportSummaryComponent,
    VoidEiffPopupComponent,
    MdIffSummaryComponent,
    IffCompletionStepsPopupComponent,
    IffStatusFlowPopupComponent,
    EmpfAppStatusPopupComponent,
    EmpfAgentIndComponent,
    EmpfAgentErComponent,
    EmpfSalessupportErComponent,
    EmpfSalessupportIndComponent,
    MdiffSalessupportIndComponent,
    MdiffSalessupportErComponent,
    MdiffAgentErComponent,
    MdiffAgentIndComponent,
    AddMpfReferenceNumberPopupComponent,
    EmpfCaseDetailsComponent,
    NewResendEiffComponent,
    SentEiffPopupComponent,
    AddMdReferenceNumberPopupComponent,
    ErEmpfCaseDetailsComponent,
    EmpfAgentSepComponent,
    MdiffAgentSepComponent,
    EmpfSalessupportSepComponent,
    MdiffSalessupportSepComponent,
    IndividualComponent,
    IndividualComponent,
    HasBeenSentCustomerPopupComponent,
    PendingSplitAgentConfirPopupComponent,
    EmployerForMdComponent,
    SelectProductTypeForMdComponent,
    MdIffSelectProductTypeComponent,
    ProductTypeCountDetailsPopupComponent,
  ],
  imports: [
    CommonModule,
    SalesjourneyRoutingModule,
    FormsModule,
    // ComponentsModule,

    ReactiveFormsModule,
    CdsRadioModule,
    CdsButtonModule,
    CdsDatepickerModule,
    CdsDropdownModule,
    CdsIconModule,
    CdsLinkModule,
    CdsLanguageModule,
    CdsPaginationModule,
    CdsStepperModule,
    CdsTableModule,
    CdsTextfieldModule,
    CdsCheckboxModule,
    CdsAssistiveTextModule,
    SharedModule,
    MatSelectModule,
    CdsProgressModule,
    CdsTooltipModule,
    CdsSortModule,
    CdsPieChartModule,
    CdsSearchModule,
    CdsFileUploadModule,
    CdsAccordionModule,
    CdsCheckboxListModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SalesjourneyModule {
  constructor() {}
}
