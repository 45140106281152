<app-cd-popup>
  <cds-popup-title
    ><div class="cds-h2-light">{{ 'calendar.approveEvent.list' | lang }}</div></cds-popup-title
  >
  <cds-popup-content>
    <div class="content l-pb-5">
      <ng-container *ngFor="let event of calendarService.approvalEvents">
        <div class="l-d-f l-jc-sb div-border l-pl-7 l-pr-7 l-pt-5 l-pb-5 l-mt-4">
          <div class="l-d-f">
            <div class="l-mr-7">
              <div calss="cds-detail2 l-mb-01">{{ 'calendar.event.name' | lang }}</div>
              <div class="l-d-f l-ai-cen l-mb-2">
                <div class="square-box" [style.backgroundColor]="event.color?.primary"></div>
                <div class="cds-h6-demibold l-mb-0">{{ event.title }}</div>
              </div>
              <div class="cds-detail2" *ngIf="event.createTime">{{ 'common.record.time.update' | lang }}: {{ event.createTime | date : 'dd/MM/yyyy' }}</div>
            </div>
          </div>
          <div class="l-d-f l-ai-cen cursor-pointer" (click)="approveEvent(event)">
            <div class="common-button cds-detail2 cds-demibold" [style.backgroundColor]="event.color?.primary">
              {{ 'calendar.event.added' | lang }}
            </div>
            <cds-icon icon="arrow:chevron_r"></cds-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </cds-popup-content>
</app-cd-popup>
