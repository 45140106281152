import { Component, OnInit } from '@angular/core';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { MatDialogRef } from '@angular/material/dialog';
import { InterestRateService } from '../../interest-rate.service';
import { DividendRate, DividendRateDoSubmit } from 'src/app/views/system/calendar/shared/calendar.model';
import { DividendRateDeatilRequest } from '../../employer';
import { deepCopy } from 'src/app/utils/copy';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { CdsOption } from '@cds/ng-core/configuration';

@Component({
  selector: 'app-dividend-rate-add-popup',
  templateUrl: './dividend-rate-add-popup.component.html',
  styleUrls: ['./dividend-rate-add-popup.component.scss'],
})
export class DividendRateAddPopupComponent implements OnInit {
  constructor(private service: InterestRateService, private dialogRef: MatDialogRef<DividendRateAddPopupComponent>, private toastAlert: ToastAlertService) {}

  form = new FormGroup({
    year: new FormControl(),
    month: new FormControl(),
    normalRate: new FormControl('', [this.amtValidator()]),
  });

  monthConfig: CdsDropdownConfig = {
    placeholder: 'Month',
    options: [
      { label: '01', value: '01' },
      { label: '02', value: '02' },
      { label: '03', value: '03' },
      { label: '04', value: '04' },
      { label: '05', value: '05' },
      { label: '06', value: '06' },
      { label: '07', value: '07' },
      { label: '08', value: '08' },
      { label: '09', value: '09' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
    ],
  };
  yearConfig: CdsDropdownConfig = {
    placeholder: 'Year',
    options: [],
  };

  resetConfig: CdsButtonConfig = {
    style: 'secondary',
    size: 'sm',
  };
  saveConfig: CdsButtonConfig = {
    style: 'primary',
    size: 'sm',
  };
  _loadding = false;

  formatter = new Intl.NumberFormat('en', {
    useGrouping: false,
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });

  _error: Set<string> = new Set();
  _waring: Set<string> = new Set();
  _valid = false;

  _addList: DividendRate[] = [{}];

  ngOnInit(): void {
    this.yearsInit();
  }

  yearsInit() {
    this.service.getDividendYearList().subscribe(res => {
      if (res.data) {
        const _list: CdsOption[] = [];
        res.data.forEach(x => {
          _list.push({
            label: x,
            value: x,
          });
        });
        this.yearConfig.options = _list;
        this.yearConfig = { ...this.yearConfig };
      }
    });
  }

  addRow() {
    this._addList.push({});
    this.validating();
  }

  removeRow(index: number) {
    this._addList.splice(index, 1);
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  amtValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim();
      if (!value) {
        return {};
      }
      const reg = /^[0-9]{1,2}\.*[0-9]{0,4}$/; // eslint-disable-line
      if (!reg.test(value)) {
        return { msg: 'Invalid percent for Normal Rate' };
      }
      return {};
    };
  }

  initForm() {}

  reset() {
    this._addList = [{}];
    this.validating();
  }

  validating() {
    if (this._addList.filter(x => x.recordDate == undefined || x.dividendRate == undefined || x.status !== 'Approved').length == 0) {
      this._valid = true;
    } else {
      this._valid = false;
    }
  }

  save() {
    if (!this._valid) {
      return;
    }
    this._loadding = true;

    const params: DividendRateDoSubmit = {
      list: deepCopy(this._addList),
    };

    this.service
      .diviendRateDoConfirm(params)
      .pipe(
        finalize(() => {
          this._loadding = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.dialogRef.close({ data: true });
        } else {
          this.toastError(data.message);
        }
      });
  }

  findDetail(item: DividendRate, index: number) {
    if (!item.year || !item.month) {
      return;
    }
    const param: DividendRateDeatilRequest = {
      ...item,
    };
    this.service.getDetailRate(param).subscribe(res => {
      if (res.data) {
        this._addList[index].id = res.data.id;
        this._addList[index].dividendRate = res.data.dividendRate;
        this._addList[index].recordDate = res.data.recordDate;
      }
      this.validating();
    });
  }

  inputChange(val: string, type: string, item: DividendRate, index: number) {
    switch (type) {
      case 'year':
        item.year = val;
        this.findDetail(item, index);
        break;
      case 'month':
        item.month = val;
        this.findDetail(item, index);
        break;
      case 'dividendRate':
        item.dividendRate = this.formatAmt(val);
        break;
      default:
    }
  }

  formatAmt(val: string | undefined) {
    if (!val) {
      return;
    }
    return this.formatter.format(Number.parseFloat(val));
  }
}
