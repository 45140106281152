import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionState } from 'src/app/views/chatbot-admin/_common/_interface';
import { CannedService } from 'src/app/views/chatbot-admin/_services/canned.service';
import { DataService } from 'src/app/views/chatbot-admin/_services/data.service';
import { MessageService } from '../../_service/message.service';
import { Alerts } from '../../_common/alerts';

@Component({
  selector: 'app-close-chat',
  templateUrl: './close-chat.component.html',
  styleUrls: ['./close-chat.component.scss'],
})
export class CloseChatComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  stateModel: any;
  modalRef: any;
  typeDDL = [];
  data = [];
  subtypeDDL = [];
  enquiryDDL = [];
  statusDDL = [
    { id: true, text: 'OPEN' },
    { id: false, text: 'CLOSED' },
  ];
  disable: boolean = false;
  DDL: any[] = [];
  icon: string = 'contract';
  validationMessages = {
    policyNumber: [{ type: 'required', message: 'Policy Number is required' }],
    certificateNumber: [{ type: 'required', message: 'Certificate Number is required' }],
    type: [{ type: 'required', message: 'Type is required' }],
    subType: [{ type: 'required', message: 'Sub Type is required' }],
    enquiryType: [{ type: 'required', message: 'Enquiry Type is required' }],
    contactName: [
      { type: 'required', message: 'Value is required' },
      { type: 'pattern', message: 'Enter a number Value only' },
      { type: 'maxlength', message: 'Value must not be greater than 10 characters ' },
      { type: 'whitespace', message: 'Only whitespace is not allowed' },
      { type: 'compareValue', message: 'Value can not be greater' },
    ],
    description: [{ type: 'required', message: 'Description is required' }],
    status: [{ type: 'required', message: 'status is required' }],
  };
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public cannedServices: CannedService,
    public dataService: DataService,
    private messageService: MessageService
  ) {
    this.form = this.formBuilder.group({
      id: new FormControl(),
      policyNumber: new FormControl(''),
      certificateNumber: new FormControl(''),
      type: new FormControl(null, Validators.required),
      subType: new FormControl(null),
      enquiryType: new FormControl(null),
      contactName: new FormControl('', Validators.compose([])),
      status: new FormControl(true),
      description: new FormControl(''),
    });

    this.stateModel = this.dataService.getModel() as ActionState;
    this.form.get('policyNumber')?.setValue(this.stateModel?.policyNumber ? this.stateModel?.policyNumber : '');
    this.form.get('certificateNumber')?.setValue(this.stateModel?.certificateNumber ? this.stateModel?.certificateNumber : '');

    this.getStaticDate();
    // if (this.stateModel?.data) {
    //
    //   this.getSingle(this.stateModel.data);
    // }
  }

  ngOnInit(): void {
    this.ngOnChanges();
  }

  ngOnChanges(): void {
    this.form.get('type')?.valueChanges.subscribe(val => {
      this.subtypeDDL = this.data.filter((x: any) => x.parentId == val);
    });
    this.form.get('subType')?.valueChanges.subscribe(val => {
      this.enquiryDDL = this.data.filter((x: any) => x.parentId == val);
    });
  }

  onSubmit() {
    let modal = this.form.value;
    this.cannedServices.closeChat(modal, this.stateModel, 0).subscribe((resp: any) => {
      if (resp) {
        this.messageService.closeWebSocketConnection();
        Alerts.showSuccessMessage('Chat Closed Successfully');
        this.activeModal.close('Cross click');
      }
    });
  }

  getSingle(id: any) {
    this.cannedServices.getSingleCannde(id).subscribe((resp: any) => {
      if (resp) {
        this.form.patchValue(resp);
      }
    });
  }

  getStaticDate() {
    this.cannedServices.getStaticData().subscribe((resp: any) => {
      if (resp) {
        this.data = resp;
        this.typeDDL = resp.filter((x: any) => x.type == 1);
        // this.statusDDL = resp.filter((x: any) => x.type == 4);
      }
    });
  }
}
