<div class="md-iff-summary">
  <div class="head-box">
    <div>
      <span class="head">MD_IFF Summary</span>
    </div>
    <div class="l-d-f l-ai-cen cursor-pointer" (click)="quickGuide()">
      <div>
        <cds-icon class="icon-question" icon="contact:question"></cds-icon>
      </div>
      <div class="l-ml-2">
        <span class="cds-body1-demibold" style="color: var(--cds-color-dark-1-green)">Quick Guide</span>
      </div>
    </div>
  </div>

  <div class="prodtype-drop-employer-name">
    <div class="padding-none">
      <div class="choose-product-type">
        <div class="l-mb-4">
          <span class="cds-body2">Choose product Type</span>
        </div>
        <app-cd-dropdown
          [placeholder]="'Choose product type'"
          [selected]="prodTypeFormControl.value"
          (selectedChange)="prodTypeChange($event)"
          [toppingList]="productTypeOptions"></app-cd-dropdown>
      </div>
    </div>
  </div>

  <div class="tabs-box">
    <app-cd-fake-tabs #cdFakeTabs [options]="['eMPF Summary Page', 'MD_IFF Summary']" (selectedIndexChange)="fakeTabIndexChange($event)"></app-cd-fake-tabs>
  </div>

  <div
    [style.display]="
      tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SalesJourneyAgent' ? 'block' : 'none'
    ">
    <app-empf-agent-ind></app-empf-agent-ind>
  </div>

  <div [style.display]="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SalesJourneyAgent' ? 'block' : 'none'">
    <app-empf-agent-sep></app-empf-agent-sep>
  </div>

  <div [style.display]="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SalesSupport' ? 'block' : 'none'">
    <app-empf-salessupport-ind></app-empf-salessupport-ind>
  </div>

  <div [style.display]="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SalesSupport' ? 'block' : 'none'">
    <app-empf-salessupport-sep></app-empf-salessupport-sep>
  </div>

  <div
    [style.display]="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SalesJourneyAgent' ? 'block' : 'none'">
    <app-empf-agent-er></app-empf-agent-er>
  </div>

  <div [style.display]="tabIndex === 0 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SalesSupport' ? 'block' : 'none'">
    <app-empf-salessupport-er></app-empf-salessupport-er>
  </div>

  <div
    [style.display]="
      tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SalesJourneyAgent' ? 'block' : 'none'
    ">
    <app-mdiff-agent-ind></app-mdiff-agent-ind>
  </div>

  <div [style.display]="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SalesJourneyAgent' ? 'block' : 'none'">
    <app-mdiff-agent-sep></app-mdiff-agent-sep>
  </div>

  <div [style.display]="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Individual && rolePriority === 'SalesSupport' ? 'block' : 'none'">
    <app-mdiff-salessupport-ind></app-mdiff-salessupport-ind>
  </div>

  <div [style.display]="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.SEP && rolePriority === 'SalesSupport' ? 'block' : 'none'">
    <app-mdiff-salessupport-sep></app-mdiff-salessupport-sep>
  </div>

  <div
    [style.display]="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SalesJourneyAgent' ? 'block' : 'none'">
    <app-mdiff-agent-er></app-mdiff-agent-er>
  </div>

  <div [style.display]="tabIndex === 1 && prodTypeFormControl.value === SalesJourneyProdType.Corperate && rolePriority === 'SalesSupport' ? 'block' : 'none'">
    <app-mdiff-salessupport-er></app-mdiff-salessupport-er>
  </div>
</div>
