/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { dateRangeInCompleteValidator, dateRangeInvalidValidator, dateValidator } from 'src/app/core/validators';
import { COMMISSION_REPORT_COLUMNS, I18N_KEY, PAGE_SIZE, SubCategoryOptions } from './report-commission.config';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { ReportCommissionService } from './report-commission.service';
import { CdsToastService } from '@cds/ng-web-components/toast';
import moment from 'moment';
import { finalize } from 'rxjs';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { DownloadFileService } from 'src/app/shared/download-file/download-file.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';

@Component({
  selector: 'app-report-commission',
  templateUrl: './report-commission.component.html',
  styleUrls: ['./report-commission.component.scss'],
})
export class ReportCommissionComponent implements OnInit {
  isShowSearchDatePicker = true;
  isDownLoading = false;
  isDataLoading = false;
  pageConfig: PageConfig = {};
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = COMMISSION_REPORT_COLUMNS;
  subCategoryOptions = SubCategoryOptions;

  previousSearchParams: any;

  reportForm = new FormGroup(
    {
      reportDateFrom: new FormControl(null, [dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT })]),
      reportDateTo: new FormControl(null, [dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT })]),
      subCategory: new FormControl([], {
        initialValueIsDefault: true,
      }),
      reportName: new FormControl(null),
    },
    [
      dateRangeInCompleteValidator('reportDateFrom', 'reportDateTo', { error: I18N_KEY.ERROR_DATE_REANGE }),
      dateRangeInvalidValidator('reportDateFrom', 'reportDateTo', { error: I18N_KEY.INVALID_DATE_REANGE }),
    ]
  );

  get reportDateFrom() {
    return this.reportForm.get('reportDateFrom') as FormControl;
  }

  get reportDateTo() {
    return this.reportForm.get('reportDateTo') as FormControl;
  }

  get subCategory() {
    return this.reportForm.get('subCategory') as FormControl;
  }

  get reportName() {
    return this.reportForm.get('reportName') as FormControl;
  }

  get searchDisabled() {
    let tag = true;
    const controlValues = this.reportForm.value;
    const keys = Object.keys(controlValues);
    keys.forEach((key: string) => {
      if (controlValues[key]) {
        tag = false;
      }
    });

    return tag || this.reportForm.invalid || this.isDataLoading;
  }

  get getTotalCount() {
    return this.pageConfig.totalCount || 0;
  }

  constructor(
    private cdHttpServeService: CdHttpServeService,
    private downloadFileService: DownloadFileService,
    private toast: CdsToastService,
    private reportCommissionService: ReportCommissionService
  ) {}

  ngOnInit(): void {
    this.search();
  }

  search() {
    this.previousSearchParams = null;
    this.pageConfig = {
      pageSize: PAGE_SIZE,
      current: 1,
    };
    this.getData();
  }

  getData() {
    this.isDataLoading = true;
    const params = this.createParams();
    this.reportCommissionService
      .getData(params)
      .pipe(
        finalize(() => {
          this.isDataLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.previousSearchParams = params;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: res.data ? res.data.totalElements : 0,
          };
          const tempData = res.data?.content || [];
          this.dataDisplayed = tempData;
          // this.dataDisplayed = this.createDisplayData(tempData);
        } else {
          this.toast.error(res.message);
        }
      });
  }

  createParams() {
    const params: any = {
      page: (this.pageConfig.current || 1) - 1,
      size: PAGE_SIZE,
    };
    if (this.previousSearchParams) {
      return { ...this.previousSearchParams, ...params };
    }
    const formValue = this.getFormValue();
    if (formValue.reportDateFrom) {
      formValue.reportDateFrom = moment(formValue.reportDateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (formValue.reportDateTo) {
      formValue.reportDateTo = moment(formValue.reportDateTo, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    return { ...params, ...formValue };
  }

  getFormValue() {
    const valueTemp: any = {};
    const value = this.reportForm.value;
    const keys: string[] = Object.keys(value);
    keys.forEach(key => {
      if (key === 'subCategory' && value[key].length) {
        valueTemp[key] = value[key].map((i: any) => i.value);
      }
      if (key !== 'subCategory' && value[key]) {
        valueTemp[key] = value[key];
      }
    });
    return valueTemp;
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.getData();
    }
  }

  reset() {
    this.reportForm.reset();
  }

  download(file: any) {
    this.isDownLoading = true;
    this.cdHttpServeService.get<any>(`/ext/eb-ssms/reporting-service/api/v1/report-file/download/${file.id}`, {}).subscribe({
      next: res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.downloadFileService.downLoadNew(atob(res.data), file.fileName);
        } else {
          this.toast.error(res.message);
        }
        this.isDownLoading = false;
      },
      error: err => {
        this.toast.error(err);
      },
    });
  }
}
