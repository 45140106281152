export const DECLINE_REASON = [
  {
    text: 'Case CANNOT match with eMPF data',
    value: 1,
  },
  {
    text: 'Client HKID/ Passport No. mismatched with eMPF data',
    value: 2,
  },
  {
    text: 'Employer Name mismatched with eMPF data',
    value: 3,
  },
  {
    text: 'BR No. mismatched with eMPF data',
    value: 4,
  },
  {
    text: 'Authorized person name mismatched with eMPF data',
    value: 5,
  },
  {
    text: 'Agent information mismatched with eMPF data',
    value: 6,
  },
  {
    text: 'Contact info mismatched with eMPF data, an acknowledgement e-alert had been sent to the member.',
    value: 7,
  },
  {
    text: 'Declined due to reject application in eMPF portal',
    value: 8,
  },
  {
    text: 'Pending to Send',
    value: 91,
  },
  {
    text: 'Pending Split Agent(s)',
    value: 92,
  },
  {
    text: 'Pending Customer Submission',
    value: 93,
  },
];
