import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { BillingClass, ContributionFrequencyE, contributionFrequencyMapObj } from '../employer';
import { EmployerService } from '../employer.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-employer-profile-billing-class',
  templateUrl: './employer-profile-billing-class.component.html',
  styleUrls: ['./employer-profile-billing-class.component.scss'],
})
export class EmployerProfileBillingClassComponent implements OnInit, OnChanges {
  isStaff = false;

  schema = '';

  _loadding = false;

  constructor(private router: Router, private service: EmployerService, private permissionService: PermissionService, private route: ActivatedRoute) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id']) {
      if (this.id) {
        this.getBillingClassList(this.id);
      }
    }
  }

  @Input() id: string | undefined;

  data?: BillingClass[] = [];

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(havePermission => {
      if (havePermission) {
        this.isStaff = true;
      }
    });

    const urlParams = new URLSearchParams(window.location.search);
    this.schema = urlParams.get('schema') || '';
  }

  toDetail = (billingClass: BillingClass) => {
    this.router.navigate(['/employer/payroll-group-info'], {
      queryParams: {
        id: this.id,
        // entityCode: billingClass.entityCode,
        payrollGroupId: billingClass.payrollGroupId,
        schema: this.schema,
      },
    });
  };

  getBillingClassList(id: string) {
    this._loadding = true;
    this.service
      .getBillingClassList(id)
      .pipe(
        finalize(() => {
          this._loadding = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.data = data.data;
        }
      });
  }

  contributionFrequencyRender(type: ContributionFrequencyE) {
    return contributionFrequencyMapObj[type] || '';
  }

  formatNum(id?: string) {
    if (!id) {
      return '';
    }
    if (Number(id) < 10) {
      return id.padStart(2, '0');
    }
    return id;
  }
}
