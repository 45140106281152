/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { trigger, style, state, transition, animate, AnimationTriggerMetadata } from '@angular/animations';
import { map } from 'rxjs';

import { CalendarEvent } from 'angular-calendar';
import { startOfDay, endOfDay, isSameDay, isSameMonth } from 'date-fns';

import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { BasicResponse } from 'src/app/core/models/response/response';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';

import { EventItem, Category, EventType, CalendarEventNew, EventTypeKey, EventTypeCheck } from '../shared/calendar.model';
import { CalendarService } from '../shared/calendar.service';
import { MONTH_OPTS } from 'src/app/config/month.config';
import { CdPopupService, MatDialogRef, CdPopupSize } from 'src/app/shared/cd-popup';
import { COLORS, CATEGORY } from '../shared/calendar.config';

import { EventAddComponent } from '../event-add/event-add.component';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ReportNoEventComponent } from '../shared/report-no-event/report-no-event.component';
import { ApproveEventComponent } from '../approve-event/approve-event.component';
import { DeclinedCommissionEventComponent } from '../declined-commission-event/declined-commission-event.component';
import { Router } from '@angular/router';
import { EventDetailsComponent } from '../event-details/event-details.component';

export const collapseAnimation: AnimationTriggerMetadata = trigger('collapse', [
  state(
    'void',
    style({
      height: 0,
      overflow: 'hidden',
      'padding-top': 0,
      'padding-bottom': 0,
    })
  ),
  state(
    '*',
    style({
      height: '*',
      overflow: 'hidden',
      'padding-top': '*',
      'padding-bottom': '*',
    })
  ),
  transition('* => void', animate('300ms ease-out')),
  transition('void => *', animate('300ms ease-in')),
]);

@Component({
  selector: 'app-calendar-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [collapseAnimation],
})
export class CalendarComponent implements OnInit {
  hasReviewPermission = false;
  hasApprovePermission = false;
  hasEditPermission = false;
  checkdEvents: Array<Category> = CATEGORY;

  eventTypes: Array<EventType>;

  monthCfg: CdsDropdownConfig;
  mh = new Date().getMonth();
  yearCfg!: CdsDropdownConfig;
  yr = new Date().getFullYear();

  viewDate: Date;

  events: CalendarEventNew[] = [];
  eventDisplay: CalendarEventNew[] = [];

  activeDayIsOpen = false;

  constructor(
    private cdPopup: CdPopupService,
    public calendarService: CalendarService,
    private permissionService: PermissionService,
    private ref: ChangeDetectorRef,
    private router: Router
  ) {
    this.eventTypes = this.calendarService.createEventTypes(CATEGORY);
    const date = new Date();
    this.viewDate = date;
    this.mh = date.getMonth();
    this.yr = date.getFullYear();
    this.monthCfg = {
      options: MONTH_OPTS,
    };
  }

  ngOnInit(): void {
    this.setPermission();
    this.getEvents();
  }

  setPermission() {
    const arr = [
      this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SYS_CALENDAR_REVIEWER),
      this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SYS_CALENDAR_APPROVE),
      this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SYS_CALENDAR),
    ];
    Promise.all(arr).then(accessArr => {
      this.hasReviewPermission = accessArr[0];
      this.hasApprovePermission = accessArr[1];
      this.hasEditPermission = accessArr[2];
      this.setReportNoReviewEvents();
      this.setApproveEvents();
      this.setDeclineEvents();
      this.listDividendRateRview();
    });
  }

  listDividendRateRview() {
    this.calendarService.reviewItems();
  }

  setReportNoReviewEvents() {
    if (this.hasReviewPermission) {
      this.calendarService.setReportNoReviewEvents(() => {
        this.ref.detectChanges();
      });
    }
  }

  setDeclineEvents() {
    if (this.hasEditPermission) {
      this.calendarService.setDeclineEvents(() => {
        this.ref.detectChanges();
      });
    }
  }

  setApproveEvents() {
    if (this.hasApprovePermission) {
      this.calendarService.setApproveEvents(() => {
        this.ref.detectChanges();
      });
    }
  }

  getEvents() {
    this.calendarService
      .getEvents()
      .pipe(
        map((data: Array<BasicResponse<Array<EventItem>>>) => {
          return data
            .map((result: BasicResponse<Array<EventItem> | any>) => {
              const res = { ...result };
              res.data = res.data.events || res.data || [];
              return res;
            })
            .filter((result: BasicResponse<Array<EventItem>>) => {
              return result.data && result.data.length;
            })
            .reduce((pre: Array<EventItem>, cur: BasicResponse<Array<EventItem>>) => {
              return pre.concat(cur.data as Array<EventItem>);
            }, []);
        })
      )
      .subscribe((data: Array<EventItem>) => {
        this.yearCfg = {
          placeholder: '',
          options: this.createYearOpts(data),
        };
        const eventDisplay = this.createCalendarEvent(data);
        this.eventDisplay = this.sortEvents(eventDisplay);
        this.filter();
      });
  }

  createYearOpts(data: Array<EventItem>) {
    const currentYear = new Date().getFullYear();
    const nextYear = new Date().getFullYear() + 1;
    const year = [currentYear, nextYear];
    data.forEach(item => {
      const eventEndYear = new Date(item.eventEndDate).getFullYear();
      const eventStartYear = new Date(item.eventStartDate ? item.eventStartDate : item.eventEndDate).getFullYear();
      if (!year.includes(eventStartYear) && eventStartYear > 1990) {
        year.push(eventStartYear);
      }
      if (!year.includes(eventEndYear) && eventEndYear > 1990) {
        year.push(eventEndYear);
      }
    });
    const yearOpts = year
      .sort((a, b) => b - a)
      .map(item => {
        return {
          label: item.toString(),
          value: item,
        };
      });
    return yearOpts;
  }

  createCalendarEvent(data: any): Array<CalendarEventNew> {
    const temp: Array<CalendarEventNew> = [];
    if (data && data.length > 0) {
      data.forEach((item: EventItem) => {
        const event = this.createEventItem(item);
        if (event) {
          temp.push(event);
        }
      });
    }
    return temp;
  }

  createEventItem(data: EventItem): CalendarEventNew | undefined {
    const temp = this.eventTypes.find((item: EventType) => item.key === data.eventType);
    if (temp === undefined) {
      return undefined;
    }
    let start;
    if (data.eventType === EventTypeKey.COMMISSION) {
      start = startOfDay(new Date(data.eventEndDate));
    } else {
      start = startOfDay(new Date(data.eventStartDate as string));
    }
    const editable = temp.editable;
    return {
      eventId: data.eventId,
      sequence: temp.sequence || 0,
      id: data.eventId,
      eventType: temp.key,
      start,
      end: endOfDay(new Date(data.eventEndDate)),
      title: temp.title,
      editable,
      color: COLORS[temp.color],
      allDay: true,
      eventStr: data.eventEndDate,
    };
  }

  eventCheckChange(list: EventTypeCheck) {
    this.checkdEvents = this.checkdEvents.map(category => {
      category.children = category.children.map(child => {
        return child.key === list.key ? list : child;
      });
      return category;
    });
    this.filter();
  }

  sortEvents(data: Array<CalendarEventNew>) {
    return data.sort((a, b) => a.sequence - b.sequence);
  }

  mounthChange(mh: number) {
    this.viewDate = new Date(this.viewDate.getFullYear(), mh, 1);
    this.closeOpenMonthViewDay();
  }

  yearChange(yr: number) {
    this.viewDate = new Date(yr, this.viewDate.getMonth(), 1);
    this.closeOpenMonthViewDay();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (!isSameMonth(date, this.viewDate)) {
      return;
    }
    if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
      this.activeDayIsOpen = false;
      this.viewDate = date;
      return;
    }
    this.viewDate = date;
    this.activeDayIsOpen = true;
  }

  filter() {
    const temp: Array<string> = [];
    let events: Array<any> = [];
    this.checkdEvents.forEach((value: any) => {
      value.children.forEach((list: any) => {
        list.checked && temp.push(list.key);
      });
    });
    if (this.eventDisplay && this.eventDisplay.length) {
      events = this.eventDisplay.filter((item: any) => {
        return temp.includes(item.eventType);
      });
    }
    this.events = events;
    this.ref.detectChanges();
  }

  reviewEvent(): void {
    const popupRef: MatDialogRef<ReportNoEventComponent> = this.cdPopup.open(ReportNoEventComponent, {
      size: CdPopupSize['LARGE'],
      panelClass: ['calendar-popup-bg'],
      data: {
        event: this.calendarService.reviewEvents,
        isReview: true,
      },
    });
    popupRef.afterClosed().subscribe(data => {
      if (data && data.isChange) {
        this.ref.detectChanges();
        this.setApproveEvents();
      }
    });
  }

  addNewEvent(): void {
    const popupRef: MatDialogRef<EventAddComponent> = this.cdPopup.open(EventAddComponent, { size: CdPopupSize['LARGE'] });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.setApproveEvents();
      }
    });
  }

  approvalEvent() {
    const lenth = this.calendarService.approvalEvents.length;
    const popupRef: MatDialogRef<ApproveEventComponent> = this.cdPopup.open(ApproveEventComponent, {
      size: CdPopupSize.LARGE,
      panelClass: ['calendar-popup-bg'],
    });
    popupRef.afterClosed().subscribe(() => {
      if (lenth !== this.calendarService.approvalEvents.length) {
        this.ref.detectChanges();
        this.getEvents();
        this.setDeclineEvents();
      }
    });
  }

  declineEvent() {
    if (!this.calendarService.declineEvents) {
      return;
    }
    const popupRef: MatDialogRef<DeclinedCommissionEventComponent> = this.cdPopup.open(DeclinedCommissionEventComponent, {
      size: CdPopupSize.LARGE,
      data: { event: this.calendarService.declineEvents },
    });
    popupRef.afterClosed().subscribe(data => {
      if (data) {
        this.setApproveEvents();
      }
    });
  }

  editEventPop(event: CalendarEventNew): void {
    if (event.eventType === EventTypeKey.DIVIDEND_RECORD_DATE) {
      this.router.navigate(['/employer/fund-benifit'], { queryParams: { index: 1 } });
      return;
    }
    const popupRef: MatDialogRef<EventDetailsComponent> = this.cdPopup.open(EventDetailsComponent, {
      size: CdPopupSize.LARGE,
      data: { event: event },
    });
    popupRef.afterClosed().subscribe(() => {});
  }

  closeOpenMonthViewDay() {
    this.mh = this.viewDate.getMonth();
    this.yr = this.viewDate.getFullYear();
    this.activeDayIsOpen = false;
  }
}
