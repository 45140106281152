<div class="resend-eiff">
  <cds-icon class="close-icon" icon="action:cross" (click)="close()"></cds-icon>
  <div class="resend-eiff-popup">
    <div class="popup-content">
      <span class="cds-h2-demibold" *ngIf="currentStep === 1">{{ 'resend-eiff.resendIff' | lang }}</span>
      <span class="cds-h2-demibold" *ngIf="currentStep === 2">{{ 'resend-eiff.resendIffConfirmation' | lang }}</span>
      <div class="l-d-f l-ai-fs l-mt-5 md-reference-number-box">
        <div style="position: relative; top: 7px">
          <cds-icon class="icon-document" icon="form:document"></cds-icon>
        </div>
        <div class="l-d-f l-fd-col l-ml-5">
          <div>
            <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">MD Reference Number</span>
          </div>
          <div class="l-mt-1">
            <span class="cds-h4-demibold">{{ data.mdNo }}</span>
          </div>
          <div *ngIf="data.resendType === ResendType.ER" class="l-mt-4 l-d-f l-ai-cen">
            <cds-checkbox [checked]="true" [disabled]="true" ngDefaultControl></cds-checkbox>
            <div class="l-ml-4">
              <span class="cds-detail2">這是有聯繫公司間或更改業務擁有權時之成員累算權益轉移。</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="l-mt-5">
          <app-matters-needing-attention [color]="'red'" [title]="'resend-eiff.importantNotes' | lang">
            <ul class="l-mt-0 l-mb-0">
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote1' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote2' | lang }}</span>
              </li>
              <li>
                <span class="cds-detail1">{{ 'iff.splitAgentNote3HeadPart' | lang }}</span>
                <span class="cds-detail1 red-text">{{ 'iff.splitAgentNote3RedPart' | lang }}</span>
                <span class="cds-detail1">{{ 'iff.splitAgentNoteTailPart' | lang }}</span>
              </li>
            </ul>
          </app-matters-needing-attention>
        </div>

        <div class="l-mt-5 info-box">
          <div>
            <span class="cds-h4" style="font-weight: 300">客戶資料</span>
          </div>
          <div class="line l-mt-5"></div>
          <div class="info-list">
            <ng-container *ngIf="data.resendType === ResendType.ER && resendInfo?.companyName">
              <div class="l-mt-5">
                <span class="cds-body2-demibold">{{ 'case-details-er.employer-name' | lang }} :</span>
              </div>
              <div class="l-mt-5">
                <span class="cds-body2-demibold">{{ resendInfo?.companyName }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="data.resendType === ResendType.ER && resendInfo?.brNo">
              <div class="l-mt-5">
                <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.eRNumber' | lang }} :</span>
              </div>
              <div class="l-mt-5">
                <span class="cds-body2-demibold">{{ resendInfo?.brNo }}</span>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                data.resendType === ResendType.ER &&
                data.empfStatus !== 0 &&
                (isReadOnlyAuthorizedPersonName || (!isReadOnlyAuthorizedPersonName && resendInfo?.authorizedPersonNameControl))
              ">
              <div class="l-mt-5">
                <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.authorizedPersonName' | lang }} :</span>
              </div>
              <div class="l-mt-5" *ngIf="resendInfo">
                <span *ngIf="isReadOnlyAuthorizedPersonName" class="cds-body2-demibold">{{ resendInfo.clientName }}</span>
                <cds-dropdown
                  *ngIf="!isReadOnlyAuthorizedPersonName && resendInfo.authorizedPersonNameControl"
                  [disabled]="currentStep === 2"
                  (ngModelChange)="authorizedPersonNameControlChange()"
                  style="width: 88%"
                  [formControl]="resendInfo.authorizedPersonNameControl"
                  [config]="resendInfo.authorizedPersonNameConfig"
                  ngDefaultControl></cds-dropdown>
              </div>
            </ng-container>

            <ng-container *ngIf="currentStep === 1">
              <ng-container *ngIf="data.resendType === ResendType.ER">
                <div class="l-mt-5">
                  <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.apEmailPhone' | lang }} :</span>
                </div>
                <div class="l-mt-5">
                  <app-cd-radio
                    #cdRadio
                    class="email-and-phone-radio"
                    [config]="emailAndPhoneConfig"
                    [(value)]="emailAndPhoneValue"
                    (valueChange)="emailAndPhoneValueChange($event)"></app-cd-radio>
                </div>
              </ng-container>

              <ng-container *ngIf="data.resendType === ResendType.ER">
                <div class="l-mt-5"></div>
                <div class="l-mt-5">
                  <div style="position: relative" *ngIf="emailAndPhoneValue === 1">
                    <app-cd-textfield
                      ngDefaultControl
                      class="email-address"
                      [placeholder]="'common.email-address' | lang"
                      [disable]="isReadOnlyEmailAndPhone || isReadOnlyEmailForNull"
                      [formControl]="emailAddressFormControl"></app-cd-textfield>
                    <cds-assistive-text class="email-address-fail" *ngIf="emailAddressFormControlValidatorFail">{{
                      'common.please-input-valid-customer-email' | lang
                    }}</cds-assistive-text>
                    <cds-assistive-text class="email-address-fail" *ngIf="isShowPleaseInputCustomerEmail">{{
                      'common.please-input-customer-email' | lang
                    }}</cds-assistive-text>
                  </div>
                  <div style="position: relative" *ngIf="emailAndPhoneValue === 2" class="l-d-f">
                    <cds-dropdown
                      [disabled]="isReadOnlyEmailAndPhone || isReadOnlyPhoneForNull"
                      [formControl]="countrycodeFormControl"
                      [config]="countrycodeConfig"
                      ngDefaultControl></cds-dropdown>
                    <div class="phone-number">
                      <app-cd-textfield
                        ngDefaultControl
                        [disable]="isReadOnlyEmailAndPhone || isReadOnlyPhoneForNull"
                        [placeholder]="'common.phone-number' | lang"
                        [formControl]="phoneNumberFormControl"
                        [type]="'number'"></app-cd-textfield>
                      <cds-assistive-text class="phone-number-fail" *ngIf="phoneNumberFormControlValidatorFail">{{
                        'common.please-input-valid-mobile-number' | lang
                      }}</cds-assistive-text>
                      <cds-assistive-text class="phone-number-fail" *ngIf="isShowPleaseInputPhoneNumber">{{
                        'common.please-input-customer-mobile-number' | lang
                      }}</cds-assistive-text>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="currentStep === 2 && data.resendType === ResendType.ER">
              <div class="l-mt-5">
                <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.apEmailPhone' | lang }} :</span>
              </div>
              <div class="l-mt-5">
                <div *ngIf="emailAndPhoneValue === 1">
                  {{ emailAddressFormControl.value }}
                </div>
                <div *ngIf="emailAndPhoneValue === 2">
                  {{ '(' + countrycodeFormControl.value + ')' + phoneNumberFormControl.value }}
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="data.resendType === ResendType.EE">
              <div class="l-mt-5">
                <span class="cds-body2-demibold">客戶姓名</span><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span
                ><span class="cds-body2-demibold"> :</span>
              </div>
              <div class="l-mt-5">
                <span class="cds-body2-demibold">{{ resendInfo?.clientName }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="currentStep === 2 && data.resendType === ResendType.EE">
              <div class="l-mt-5">
                <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.emailAndPhone' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold"> :</span>
              </div>
              <div class="l-mt-5">
                <div *ngIf="emailAndPhoneValue === 1">
                  {{ emailAddressFormControl.value }}
                </div>
                <div *ngIf="emailAndPhoneValue === 2">
                  {{ '(' + countrycodeFormControl.value + ')' + phoneNumberFormControl.value }}
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="currentStep === 1 && data.resendType === ResendType.EE">
              <div class="l-mt-5">
                <span class="cds-body2-demibold">{{ 'sendIffInBatchSummary.emailAndPhone' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold"> :</span>
              </div>
              <div class="l-mt-5">
                <app-cd-radio
                  #cdRadio
                  class="email-and-phone-radio"
                  [config]="emailAndPhoneConfig"
                  [(value)]="emailAndPhoneValue"
                  (valueChange)="emailAndPhoneValueChange($event)"></app-cd-radio>
              </div>

              <div></div>
              <div>
                <div style="position: relative" *ngIf="emailAndPhoneValue === 1">
                  <app-cd-textfield
                    ngDefaultControl
                    class="email-address"
                    [disable]="isReadOnlyEmailAndPhone || isReadOnlyEmailForNull"
                    [placeholder]="'common.email-address' | lang"
                    [formControl]="emailAddressFormControl"></app-cd-textfield>
                  <cds-assistive-text class="email-address-fail" *ngIf="emailAddressFormControlValidatorFail">{{
                    'common.please-input-valid-customer-email' | lang
                  }}</cds-assistive-text>
                  <cds-assistive-text class="email-address-fail" *ngIf="isShowPleaseInputCustomerEmail">{{
                    'common.please-input-customer-email' | lang
                  }}</cds-assistive-text>
                </div>
                <div style="position: relative" *ngIf="emailAndPhoneValue === 2" class="l-d-f">
                  <cds-dropdown [formControl]="countrycodeFormControl" [config]="countrycodeConfig" ngDefaultControl></cds-dropdown>
                  <div class="phone-number">
                    <app-cd-textfield
                      ngDefaultControl
                      [disable]="isReadOnlyEmailAndPhone || isReadOnlyPhoneForNull"
                      [placeholder]="'common.phone-number' | lang"
                      [formControl]="phoneNumberFormControl"
                      [type]="'number'"></app-cd-textfield>
                    <cds-assistive-text class="phone-number-fail" *ngIf="phoneNumberFormControlValidatorFail">{{
                      'common.please-input-valid-mobile-number' | lang
                    }}</cds-assistive-text>
                    <cds-assistive-text class="phone-number-fail" *ngIf="isShowPleaseInputPhoneNumber">{{
                      'common.please-input-customer-mobile-number' | lang
                    }}</cds-assistive-text>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="l-mt-5">
              <div *ngIf="currentStep === 1">
                <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">{{ 'resend-eiff.pleaseEnsure' | lang }}</span>
              </div>
            </div>
            <div class="l-mt-5">
              <ng-container *ngIf="currentStep === 2">
                <div style="flex: 1"></div>
                <div style="flex: 2">
                  <cds-assistive-text type="success">{{ 'resend-eiff.ensureTheContact' | lang }}</cds-assistive-text>
                </div>
              </ng-container>
            </div>

            <ng-container *ngIf="currentStep === 1">
              <div class="l-mt-5">
                <div>
                  <span class="cds-body2-demibold"> {{ 'sendIffInBatchSummary.preferredLanguage' | lang }}: </span>
                </div>
              </div>
              <div class="l-mt-5">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #cdRadioPreferredLanguage
                  [(value)]="preferredLanguage"
                  [config]="preferredLanguageConfig"></app-cd-radio>
              </div>
            </ng-container>
            <ng-container *ngIf="currentStep === 2 && preferredLanguage">
              <div class="l-mt-5">
                <div>
                  <span class="cds-body2-demibold"> {{ 'sendIffInBatchSummary.receivingLanguage' | lang }}: </span>
                </div>
              </div>
              <div class="l-mt-5">
                <div *ngIf="preferredLanguage === PreferredLanguageType.Chinese">
                  <span class="cds-body2-demibold">{{ 'common.chinese' | lang }}</span>
                </div>
                <div *ngIf="preferredLanguage === PreferredLanguageType.English">
                  <span class="cds-body2-demibold">{{ 'common.english' | lang }}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- <div class="item" style="margin-top: 1.25rem">
          <div style="flex: 1">
            <span class="cds-body2-demibold">{{ 'resend-eiff.eMPF Reference No.' | lang }} :</span>
          </div>
          <div style="flex: 2">
            <span class="cds-body2-demibold">{{ data.mdNo }}</span>
          </div>
        </div>
        <div class="item" style="margin-top: 1.125rem">
          <div style="flex: 1"></div>
          <div class="l-d-f l-ai-cen" style="flex: 2">
            <cds-checkbox [disabled]="true" [checked]="resendInfo?.primaryAgent?.isRgltdActAck || false"></cds-checkbox>
            <div class="l-ml-4">
              <span class="cds-detail2">{{ 'sendIffInBatchSummary.havePerformedMPF' | lang }}</span>
            </div>
          </div>
        </div> -->
        <!-- <div *ngIf="data.resendType === ResendType.EE" class="item l-mt-5">
          <div style="flex: 1">
            <span class="cds-body2-demibold">{{ 'iff.hKIDPassportNo' | lang }} :</span>
          </div>
          <div style="flex: 2">
            <span *ngIf="resendInfo" class="cds-body2-demibold">{{ resendInfo.hkidOrPassportNo }}</span>
          </div>
        </div> -->

        <ng-container *ngIf="isChannelDBS && dbs">
          <div class="item l-mt-6">
            <div style="flex: 1">
              <div>
                <span class="cds-body2-bold">RM Referral Code:</span>
              </div>
            </div>
            <div style="flex: 2">
              <span *ngIf="currentStep === 2">{{ dbs.dbsRmReferralCd.value || '-' }}</span>
              <app-cd-input
                *ngIf="currentStep === 1"
                [type]="'number'"
                [formControl]="dbs.dbsRmReferralCd"
                [maxlength]="8"
                ngDefaultControl
                [placeholder]="'Input RM Referral Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.dbsRmReferralCd.dirty && dbs.dbsRmReferralCd.hasError('required'); else second" type="error"
                  >Please input valid RM Referral Code
                </cds-assistive-text>
                <ng-template #second>
                  <cds-assistive-text *ngIf="dbs.dbsRmReferralCd.dirty && dbs.dbsRmReferralCd.hasError('lengthError')" type="error"
                    >Please input valid RM Referral Code
                  </cds-assistive-text>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="item l-mt-6">
            <div style="flex: 1">
              <div class="cds-body2-bold">Customer ID:</div>
            </div>
            <div style="flex: 2">
              <span *ngIf="currentStep === 2">{{ dbs.dbsCustomerId.value || '-' }}</span>
              <app-cd-input
                *ngIf="currentStep === 1"
                [formControl]="dbs.dbsCustomerId"
                [maxlength]="8"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Customer ID'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.dbsCustomerId.dirty && dbs.dbsCustomerId.hasError('lengthError')" type="error"
                  >Please input valid Customer ID
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="item l-mt-6">
            <div style="flex: 1">
              <div class="cds-body2-bold">Campaign Code:</div>
            </div>
            <div style="flex: 2">
              <span *ngIf="currentStep === 2">{{ dbs.dbsCampaignCd.value || '-' }}</span>
              <app-cd-input
                *ngIf="currentStep === 1"
                [formControl]="dbs.dbsCampaignCd"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Campaign Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.dbsCampaignCd.dirty && dbs.dbsCampaignCd.hasError('lengthError')" type="error"
                  >Please input valid Campaign Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="item l-mt-6">
            <div style="flex: 1">
              <div class="cds-body2-bold">Source Code:</div>
            </div>
            <div style="flex: 2">
              <span *ngIf="currentStep === 2">{{ dbs.dbsSourceCd.value || '-' }}</span>
              <app-cd-input
                *ngIf="currentStep === 1"
                [formControl]="dbs.dbsSourceCd"
                [maxlength]="10"
                [regExpLimit]="'^[A-Za-z0-9-]*$'"
                ngDefaultControl
                [placeholder]="'Input Source Code'"></app-cd-input>
              <div class="l-mt-1">
                <cds-assistive-text *ngIf="dbs.dbsSourceCd.dirty && dbs.dbsSourceCd.hasError('lengthError')" type="error"
                  >Please input valid Source Code
                </cds-assistive-text>
              </div>
            </div>
          </div>
          <div class="item l-mt-4">
            <div class="l-pl-3">
              <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">*請確保資料與客戶在eMPF上提供的資料一致 </span>
            </div>
          </div>
        </ng-container>

        <div class="l-mt-5 info-box">
          <div>
            <span class="cds-h4" style="font-weight: 300">有關受規管活動</span>
          </div>
          <div class="line l-mt-5"></div>
          <div class="l-mt-5">
            <span class="cds-body2-demibold">在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求。</span>
          </div>
          <div class="l-mt-5">
            <app-cd-radio
              class="cd-radio-margin-bottom-clear"
              [value]="resendInfo?.primaryAgent?.isRgltdActAck || false"
              [config]="regulationConfig"></app-cd-radio>
          </div>
        </div>

        <div *ngFor="let item of agentInfos; let i = index" class="l-mt-5 info-box">
          <div>
            <span class="cds-h4" style="font-weight: 300">{{
              'resend-eiff.informationOnMPFIntermediaries'
                | lang
                  : {
                      p1: ['resend-eiff.second', 'resend-eiff.third'][i] | lang
                    }
            }}</span>
          </div>
          <div class="line l-mt-5"></div>
          <div class="info-list">
            <div class="l-mt-5"><span class="cds-body2-demibold">代理人編號</span><span class="cds-body2-demibold">:</span></div>
            <div class="l-mt-5">
              <span class="cds-body1">{{ item.agentCode }}</span>
            </div>
            <div class="l-mt-5"><span class="cds-body2-demibold">代理人姓名</span><span class="cds-body2-demibold">:</span></div>
            <div class="l-mt-5">
              <span class="cds-body1">{{ getAgentName(item) }}</span>
            </div>
            <ng-container *ngIf="currentStep === 2">
              <div class="l-mt-5">
                <span class="cds-body2-demibold">客戶電郵地址/ 手機號碼</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">* </span><span class="cds-body2-demibold">:</span>
              </div>
              <div class="l-mt-5">
                <span class="cds-body1">{{ item.commChannel === 'SMS' ? item.shortPhone : item.shortEmail }}</span>
              </div>
              <div class="l-mt-5"><span class="cds-body2-demibold">選擇語言 </span><span class="cds-body2-demibold">:</span></div>
              <div class="l-mt-5">
                <ng-container *ngIf="i === 0 && secondAgentPreferredLanguage">
                  <div>
                    <div *ngIf="secondAgentPreferredLanguage === PreferredLanguageType.Chinese">
                      <span class="cds-body1">{{ 'common.chinese' | lang }}</span>
                    </div>
                    <div *ngIf="secondAgentPreferredLanguage === PreferredLanguageType.English">
                      <span class="cds-body1">{{ 'common.english' | lang }}</span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="i === 1 && thirdAgentPreferredLanguage">
                  <div>
                    <div *ngIf="thirdAgentPreferredLanguage === PreferredLanguageType.Chinese">
                      <span class="cds-body1">{{ 'common.chinese' | lang }}</span>
                    </div>
                    <div *ngIf="thirdAgentPreferredLanguage === PreferredLanguageType.English">
                      <span class="cds-body1">{{ 'common.english' | lang }}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div class="regulation-box l-mt-5">
            <div>
              <span class="cds-detail2" style="font-weight: 300">有關受規管活動</span>
            </div>
            <div class="l-mt-4">
              <span class="cds-body2-demibold">在客戶進行受規管活動時，必須已履行強積金中介人履行職務要求。</span>
            </div>
            <div class="l-mt-5">
              <app-cd-radio class="cd-radio-margin-bottom-clear" [(value)]="item.isRgltdActAck" [config]="regulationConfig"></app-cd-radio>
            </div>
          </div>
          <div *ngIf="currentStep === 1" class="l-mt-7">
            <div>
              <span class="cds-body2-demibold">請選擇以下已登記的第{{ i === 0 ? '二' : '三' }}強積金中介人聯絡方式完成聲明：</span>
            </div>
            <div *ngIf="item.channelConfig" class="l-mt-4">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear second-intermediary-contact-information-radio"
                [(value)]="item.commChannel"
                [config]="item.channelConfig"></app-cd-radio>
            </div>
            <div class="l-mt-5 l-d-f l-ai-cen">
              <div style="margin-right: 102px">
                <span class="cds-body2-demibold">選擇語言：</span>
              </div>
              <div>
                <div class="item">
                  <ng-container *ngIf="i === 0">
                    <ng-container>
                      <div>
                        <app-cd-radio
                          class="cd-radio-margin-bottom-clear"
                          #cdRadioSecondAgentPreferredLanguage
                          [(value)]="secondAgentPreferredLanguage"
                          [config]="secondAgentPreferredLanguageConfig"></app-cd-radio>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="i === 1">
                    <ng-container>
                      <div>
                        <app-cd-radio
                          class="cd-radio-margin-bottom-clear"
                          #cdRadioThirdAgentPreferredLanguage
                          [(value)]="thirdAgentPreferredLanguage"
                          [config]="thirdAgentPreferredLanguageConfig"></app-cd-radio>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentStep === 1" class="botton-list">
      <cds-button [style]="'secondary'" (click)="back()">{{ 'common.back' | lang }}</cds-button>
      <cds-button [disabled]="isDisabledContinue" (click)="continue()">{{ 'common.continue' | lang }}</cds-button>
    </div>
    <div *ngIf="currentStep === 2" class="botton-list">
      <cds-button [style]="'secondary'" (click)="back()">{{ 'common.back' | lang }}</cds-button>
      <cds-button (click)="resendSubmission()">{{ 'common.confirmAndSubmit' | lang }}</cds-button>
    </div>
  </div>
</div>
