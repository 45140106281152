<div class="select-product-type">
  <div class="top">
    <span class="text">Generate eIFF Reference Number</span>
  </div>

  <div *ngIf="currentStep === 1" class="content">
    <div>
      <span class="cds-h3-light"> Select Product Type </span>
    </div>

    <div class="select-list l-mt-7">
      <div class="select-box" (click)="showDeclaration(Domain.individual)">
        <cds-icon style="width: 80px; height: 80px; --cds-icon-color: var(--cds-color-dark-navy)" icon="people:login_1"></cds-icon>
        <div class="l-mt-5">
          <span class="cds-h2-light">{{ 'generate.md.individuals' | lang }}</span>
        </div>
      </div>
      <div class="select-box l-ml-6" (click)="showDeclaration(Domain.employer)">
        <cds-icon style="width: 80px; height: 80px; --cds-icon-color: var(--cds-color-dark-navy)" icon="environment:office_building"></cds-icon>
        <div class="l-mt-5">
          <span class="cds-h2-light">{{ 'generate.md.employer' | lang }}</span>
        </div>
      </div>
      <div class="select-box l-ml-6" (click)="showDeclaration(Domain.sep)">
        <cds-icon style="width: 80px; height: 80px; --cds-icon-color: var(--cds-color-dark-navy)" icon="contact:qualify"></cds-icon>
        <div class="l-mt-5" style="text-align: center">
          <span class="cds-h2-light">{{ 'generate.md.sep' | lang }}</span>
        </div>
      </div>
    </div>

    <div class="l-mt-7">
      <span class="cds-body2" style="color: var(--cds-color-light-3-dark-navy)">*Remarks: Individual includes product PA, TVC & SVC.</span>
    </div>
  </div>

  <div *ngIf="currentStep === 2" class="l-mt-7 declaration-box">
    <div class="l-pl-7">
      <span class="cds-h4-demibold">{{ 'Declaration.header' | lang }}</span>
    </div>
    <div #displayContent class="declaration-content">
      <div class="l-mt-5 l-pl-7">
        <span class="cds-body1">
          {{ 'Declaration.headersub1' | lang }}
        </span>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.headersub3' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodydis' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodycommon' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodysuba' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodysubb' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubc' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubd' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodyadmin' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodycommon' | lang }}
          </span>
        </div>

        <div class="  ">
          <span class="cds-body1">
            {{ 'Declaration.bodysube' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubf' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodyother' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodycommon2' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubg' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubh' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodysubi' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodyimportant' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodynotice' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodynoticesub1' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodynoticesuba' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodynoticesubb' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodynoticesubc' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodynoticesubd' | lang }}
          </span>
        </div>
      </div>
    </div>
    <div class="l-pl-7 l-mb-2 l-pt-5 declaration-agreement-btn">
      <cds-button label="{{ 'Declaration.noticeread' | lang }}" (click)="toEdit()" [disabled]="!isScrollBottom" [size]="'md'" [style]="'primary'"></cds-button>
    </div>
  </div>
</div>
