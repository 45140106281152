/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs';
import { APIPaths } from '../_common/constant';
import { Alerts } from '../_common/alerts';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(public httpClient: HttpClient) {}

  getCanedResponse() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getCanedResponse).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getParent() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getParent).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getParentById(id: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getParent + `/${id}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getsubCanedResponse() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getCanedResponse).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getAutoResponse(val: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getCanedResponse + '/' + val).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getNewChatRoom(userId: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getNewChatRoom, { params: { userId: userId } }).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  submitFeedBack(model: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.post(APIPaths.feedBack, model).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getFeedBack() {
    const onSuccess = (data: any) => {
      if (data.success) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.feedBack).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getUsreList() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getUsreList).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getActiveChatList() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getActiveCloseChatList + `?status=ACTIVE`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getBotUser() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      }
    };
    return this.httpClient.get(APIPaths.botUser).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getPendingChatList() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getPendingChatList).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getCloseChatList(keyword?: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getActiveCloseChatList + `?status=CLOSED&keyword=${keyword}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getBotChatList() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.bot_room).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getUserChatHistory(id: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.gethistory + id).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  updateChatCount(id: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      }
    };
    return this.httpClient.get(APIPaths.updateChatCount + id).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  changeChatStatus(id: any, model: any) {
    const onSuccess = (data: any) => {
      if (data && !data?.error) {
        return data;
      } else {
        Alerts.showErrorMessage(data?.error);
      }
    };
    return this.httpClient.put(APIPaths.changeRoomStatus + id, model).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  changeUserStatus(id: any, model: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.put(APIPaths.changeUsertatus + id, model).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getServicesAgree() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getServicesAgree).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  checkGroupNo(memberAccCode: any, errAccountCode: any, userId: any, roomId: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.checkAgentCert + `${errAccountCode}/${memberAccCode}/user/${userId}/room/${roomId}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  checkAvailableCSO() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data?.error);
      }
    };
    return this.httpClient.get(APIPaths.checkAvailableCSO).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getIdleTime() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getServicesAgree).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getAgentCanedResponse() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getAgentcanned).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getRoomInfo(roomId: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getRoomInfo + `/${roomId}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getAgentInfo(id: any) {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.user + `/${id}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getAlert() {
    let onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getAutoResponse).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getActiveChatListForSupervisor() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getChatListForSupervisor + `?status=ACTIVE`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getPendingChatListForSupervisor() {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getPendingChatList).pipe(
      map(value => value),
      map(onSuccess)
    );
  }

  getCloseChatListForSupervisor(keyword?: any) {
    const onSuccess = (data: any) => {
      if (data) {
        return data;
      } else {
        Alerts.showErrorMessage(data.meta.message);
      }
    };
    return this.httpClient.get(APIPaths.getChatListForSupervisor + `?status=CLOSED&keyword=${keyword}`).pipe(
      map(value => value),
      map(onSuccess)
    );
  }
}
