/* eslint-disable @typescript-eslint/no-explicit-any */

export enum TxnCode {
  COMMISSION_ADJUSTMENT = 'COMMISSION_ADJUSTMENT',
  DISCONTINUOUS = 'DISCONTINUOUS',
  MONTHLY_CLAW_BACK = 'MONTHLY_CLAW_BACK',
  ORSO_INVESTMENT_ONLY = 'ORSO_INVESTMENT_ONLY',
  TRANSFER_CASE_LUMP_SUM_CONTRIBUTION = 'TRANSFER_CASE_LUMP_SUM_CONTRIBUTION',
  ANNUALIZE_PREMIUM = 'ANNUALIZE_PREMIUM',
  REGULAR_CONTRIBUTION = 'REGULAR_CONTRIBUTION',
  MEMBER_TRANSFER_OUT = 'MEMBER_TRANSFER_OUT',
  EMPLOYEE_TRANSFER_OUT = 'EMPLOYEE_TRANSFER_OUT',
  WITHDRAWAL_ON_ORSO_INVESTMENT_ONLY = 'WITHDRAWAL_ON_ORSO_INVESTMENT_ONLY',
  NEW_BUSINESS = 'NEW_BUSINESS',
  WITHDRAWAL = 'WITHDRAWAL',
}

export const TxnCodeMap = new Map([
  [TxnCode.COMMISSION_ADJUSTMENT, '027'],
  [TxnCode.DISCONTINUOUS, '050'],
  [TxnCode.MONTHLY_CLAW_BACK, '101'],
  [TxnCode.ORSO_INVESTMENT_ONLY, '105'],
  [TxnCode.TRANSFER_CASE_LUMP_SUM_CONTRIBUTION, '241'],
  [TxnCode.ANNUALIZE_PREMIUM, '300'],
  [TxnCode.REGULAR_CONTRIBUTION, '395'],
  [TxnCode.MEMBER_TRANSFER_OUT, '405'],
  [TxnCode.WITHDRAWAL_ON_ORSO_INVESTMENT_ONLY, '410'],
  [TxnCode.EMPLOYEE_TRANSFER_OUT, '402'],
  [TxnCode.NEW_BUSINESS, '010'],
  [TxnCode.WITHDRAWAL, '401'],
]);
export const TxnCodeOpts = [
  { label: '027: Commission Adjustment', value: TxnCode.COMMISSION_ADJUSTMENT },
  { label: '050: Discontinuous', value: TxnCode.DISCONTINUOUS },
  { label: '101: Monthly Claw Back', value: TxnCode.MONTHLY_CLAW_BACK },
  { label: '105: ORSO Investment Only', value: TxnCode.ORSO_INVESTMENT_ONLY },
  {
    label: '241: Transfer In/ Lump Sum',
    value: TxnCode.TRANSFER_CASE_LUMP_SUM_CONTRIBUTION,
  },
  {
    label: '300: Annualize Premium',
    value: TxnCode.ANNUALIZE_PREMIUM,
  },
  { label: '395: Regular Contribution', value: TxnCode.REGULAR_CONTRIBUTION },
  { label: '405: Member Transfer Out', value: TxnCode.MEMBER_TRANSFER_OUT },
  {
    label: '410: Withdrawal on ORSO',
    value: TxnCode.WITHDRAWAL_ON_ORSO_INVESTMENT_ONLY,
  },
  {
    label: '402: Employee Transfer Out',
    value: TxnCode.EMPLOYEE_TRANSFER_OUT,
  },
  {
    label: '010: New Business',
    value: TxnCode.NEW_BUSINESS,
  },
  {
    label: '401: Withdrawal',
    value: TxnCode.WITHDRAWAL,
  },
];

export const TxnCodeJournalOpts = [
  { label: '027: Commission Adjustment', value: TxnCode.COMMISSION_ADJUSTMENT },
  { label: '050: Discontinuous', value: TxnCode.DISCONTINUOUS },
  { label: '101: Monthly Claw Back', value: TxnCode.MONTHLY_CLAW_BACK },
  { label: '105: ORSO Investment Only', value: TxnCode.ORSO_INVESTMENT_ONLY },
  {
    label: '241: Transfer In/ Lump Sum',
    value: TxnCode.TRANSFER_CASE_LUMP_SUM_CONTRIBUTION,
  },
  {
    label: '300: Annualize Premium',
    value: TxnCode.ANNUALIZE_PREMIUM,
  },
  { label: '395: Regular Contribution', value: TxnCode.REGULAR_CONTRIBUTION },
  { label: '405: Member Transfer Out', value: TxnCode.MEMBER_TRANSFER_OUT },
  {
    label: '410: Withdrawal on ORSO ',
    value: TxnCode.WITHDRAWAL_ON_ORSO_INVESTMENT_ONLY,
  },
];
