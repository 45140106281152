/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnConfig } from 'src/app/shared/data-table';

export const PAGE_SIZE = 10;

export const I18N_KEY = {
  COMMON_GOT: 'common.gotItWithExclamation',
  INVALID_DATE_FORMAT: 'commission.errorLog.occurDateInvalid',
  INVALID_DATE_REANGE: 'commission.common.dateRangeInvalid',
  ERROR_DATE_REANGE: 'commission.common.dateRangeError',
};

export const COMMISSION_REPORT_COLUMNS: Array<ColumnConfig> = [
  { key: 'reportDate', title: 'reportCenter.reportDate' },
  { key: 'reportSubCategory', title: 'reportCenter.subCategory' },
  { key: 'fileName', title: 'reportCenter.fileName' },
  { key: 'action', title: 'reportCenter.action' },
];

export const SubCategoryOptions: any[] = [
  // { label: 'MPF', value: 'MPF' },
  { label: 'ORSO/Macau', value: 'ORSO_Macau' },
];
