/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnConfig } from 'src/app/shared/data-table';

export const PAGE_SIZE = 10;
export const FILE_NAME = 'Audit History Report';

export const I18N_KEY = {
  DATE_INVALID: 'setting.auditHistory.date.invalid',
  DATE_RANGE_INVALID: 'setting.auditHistory.dateRange.invalid',
  DATE_RANGE_INCOMPLETE: 'commission.common.dateRangeError',
  ACCOUNT_CODE_INVALID: 'setting.auditHistory.accountCode.invalid',
  COMMON_GOT: 'common.gotItWithExclamation',
};

export const SubServiceOptions: any[] = [
  { label: 'COMMISSION_SCALE_MANAGEMENT', value: 'COMMISSION_SCALE_MANAGEMENT' },
  { label: 'COMMISSION_SCHEME_MASTER_RECORD', value: 'COMMISSION_SCHEME_MASTER_RECORD' },
  { label: 'COMM_INFO_AGENT_CHANGE', value: 'COMM_INFO_AGENT_CHANGE' },
  { label: 'COMM_INFO_MAIN_PARTIES_CONT_ADJ', value: 'COMM_INFO_MAIN_PARTIES_CONT_ADJ' },
  { label: 'COMM_INFO_MAIN_PARTIES_OTHERS', value: 'COMM_INFO_MAIN_PARTIES_OTHERS' },
  { label: 'COMM_ADJ_APPEAL', value: 'COMM_ADJ_APPEAL' },
  { label: 'SYS_COMMISSION_DEFAULT_SCALE', value: 'SYS_COMMISSION_DEFAULT_SCALE' },
];

export const AccountTypeOptions: any[] = [
  { label: 'ORSO-ER', value: 'ORSO-ER' },
  { label: 'ORSO-EE', value: 'ORSO-EE' },
  { label: 'Macau-ER (PPS)', value: 'Macau-ER (PPS)' },
  { label: 'Macau-EE (PPS)', value: 'Macau-EE (PPS)' },
  { label: 'Macau-ER (CPF)', value: 'Macau-ER (CPF)' },
  { label: 'Macau-EE (CPF)', value: 'Macau-EE (CPF)' },
  { label: 'Macau-PAP', value: 'Macau-PAP' },
  { label: 'MPF-ER', value: 'MPF-ER' },
  { label: 'MPF-EE', value: 'MPF-EE' },
  { label: 'MPF-SEP', value: 'MPF-SEP' },
  { label: 'MPF-SEP (AGENT)', value: 'MPF-SEP (AGENT)' },
  { label: 'MPF-TVC', value: 'MPF-TVC' },
  { label: 'MPF-FRC', value: 'MPF-FRC' },
  { label: 'MPF-PAP: ', value: 'MPF-PAP: ' },
];

export const ServiceOptions: any[] = [
  { label: 'Commission Service', value: 'eb-ssms-commission-service' },
  { label: 'Customer Service', value: 'eb-ssms-customer-service' },
];

export const AUDIT_HISTORY_COLUMNS: Array<ColumnConfig> = [
  { key: 'updatedAt', title: 'setting.auditHistory.updatedTime' },
  { key: 'sub_section', title: 'setting.auditHistory.subService' },
  { key: 'action', title: 'setting.auditHistory.action' },
  { key: 'section_key', title: 'setting.auditHistory.sectionKey' },
  { key: 'fields', title: 'setting.auditHistory.fields' },
  { key: 'originalValue', title: 'setting.auditHistory.originalValue' },
  { key: 'changedValue', title: 'setting.auditHistory.changedValue' },
  { key: 'createBy', title: 'setting.auditHistory.createdBy' },
];
export const EXCEL_COLUMNS: Array<string> = [
  'Update Time',
  'Service Section',
  'Action',
  'Customer_ID&Section Key',
  'Fields',
  'Original Value',
  'Changed Value',
  'Created By',
  'Created By',
];
