/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CdsDropdownConfig } from '@cds/ng-core/dropdown';

import { EventTypeKey } from 'src/app/views/system/calendar/calendar.model';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { CalendarService } from 'src/app/views/system/calendar/calendar.service';

import { CATEGORY, I18N_KEY_FOR_API_RES, I18N_KEY } from '../calendar.config';
import { CdPopupService, CdPopupType, MatDialogRef } from 'src/app/shared/cd-popup';

@Component({
  selector: 'app-event-add',
  templateUrl: './event-add.component.html',
  styleUrls: ['./event-add.component.scss'],
})
export class EventAddComponent implements OnInit {
  dropdownConfig!: CdsDropdownConfig;
  eventTypeSelected = new FormControl('');
  eventTypeKey = EventTypeKey;

  saveParams: any; // call api params
  disabled = true; // for confirms button
  isLastTwoMonths = false;

  hasNextYearCommissionEvent = false;

  constructor(
    private dialogRef: MatDialogRef<EventAddComponent>,
    private cdsPopup: CdPopupService,
    private ref: ChangeDetectorRef,
    public calendarService: CalendarService
  ) {}

  ngOnInit() {
    this.eventTypeSelected.valueChanges.subscribe(() => {
      this.disabled = true;
    });
    this.initDropOpts();
    this.setLastTwoMonths();
    this.getNextYearCommission();
  }

  setLastTwoMonths() {
    const currentMonth = new Date().getMonth() + 1;
    this.isLastTwoMonths = currentMonth === 11 || currentMonth === 12;
  }

  getNextYearCommission() {
    this.calendarService
      .reviewCommissionEventsList({
        year: this.calendarService.nextYear,
      })
      .subscribe(res => {
        if (res.data) {
          this.hasNextYearCommissionEvent = true;
        }
      });
  }

  initDropOpts() {
    this.dropdownConfig = {
      placeholder: I18N_KEY.EVENT_CHOOSE_NAME,
      options: this.calendarService
        .createEventTypes(CATEGORY)
        .filter(item => {
          // return item.addable && ((!this.hasNextYearCommissionEvent && this.isLastTwoMonths) || item.key !== EventTypeKey.COMMISSION);
          return item.addable;
        })
        .map(item => {
          return {
            label: item.title,
            value: item.key,
          };
        }),
    };
  }

  commissionChange($event: any) {
    this.disabled = $event.invalid;
    this.saveParams = [];
    $event.value.forEach((item: string) => {
      this.saveParams.push({
        eventEndDate: `${this.calendarService.nextYear}-${item}`,
      });
    });
    this.ref.detectChanges();
  }

  typhoonChange($event: any) {
    this.disabled = $event.invalid;
    this.saveParams = {
      eventDate: $event.value,
    };
  }

  onSubmit() {
    if (this.disabled) {
      return;
    }
    this.calendarService.addCommissionEvents(this.saveParams).subscribe(res => {
      const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
        data: {
          message: I18N_KEY_FOR_API_RES[res.message] || res.message,
        },
      });
      popupRef.afterClosed().subscribe(() => {
        if (res.result == ResponseResult.SUCCESS) {
          this.dialogRef.close({
            eventType: this.eventTypeSelected?.value,
          });
        }
      });
    });
  }

  cancle() {
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: I18N_KEY.COMMON_ACTION_CANCLE,
      },
    });
    popupRef.afterClosed().subscribe(res => {
      if (res.continue) this.dialogRef.close();
    });
  }
}
