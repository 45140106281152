import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionState } from 'src/app/views/chatbot-admin/_common/_interface';
import { CannedService } from 'src/app/views/chatbot-admin/_services/canned.service';
import { DataService } from 'src/app/views/chatbot-admin/_services/data.service';
import { MessageService } from '../../_service/message.service';
import { Alerts } from '../../_common/alerts';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { finalize } from 'rxjs';
import { ChatService } from '../../_service/chat.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-cso-canned-msg',
  templateUrl: './cso-canned-msg.component.html',
  styleUrls: ['./cso-canned-msg.component.scss'],
})
export class CsoCannedMsgComponent implements OnInit {
  loading: boolean = false;
  stateModel: any;
  modalRef: any;
  typeDDL = [];
  data = [];
  subtypeDDL = [];
  enquiryDDL = [];
  statusDDL = [
    { id: true, text: 'OPEN' },
    { id: false, text: 'CLOSED' },
  ];
  disable: boolean = false;
  DDL: any[] = [];
  icon: string = 'contract';
  lang = '';
  config: AngularEditorConfig = {
    editable: true,
    showToolbar: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '3rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },

      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public chatService: ChatService,
    public dataService: DataService,
    private messageService: MessageService,
    public languageService: LanguageChangeService
  ) {
    this.languageService.initLanNotice.subscribe(res => {
      this.lang = res;
      // Alerts.showErrorMessage('You are idle for 5 minutes, Please click on the screen to continue');
    });

    this.stateModel = this.dataService.getModel() as ActionState;
  }

  cannedMessage = null;
  cannedMessageDetail = null;
  ngOnInit(): void {
    this.getMessage();
  }

  cannedMessageDDL: any = [];

  getMessage() {
    return this.chatService
      .getAgentCanedResponse()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.cannedMessageDDL = data;
        } else {
          Alerts.showErrorMessage(data.message);
        }
      });
  }

  selectMessage(event: any) {
    this.cannedMessage = null;
    this.cannedMessageDetail = this.lang == 'en' ? event.ans : event.chineseAns;
    // let model = {
    //   type: 'CHAT',
    //   content: this.lang == 'en' ? event.ans : event.chineseAns,
    //   userId: this.agentInfo?.id,
    //   userType: 'AGENT',
    //   sender: this.agentInfo?.displayName,
    // };
    // this.messageService.sendMessage(model, this.roomId);
  }

  sendMessage() {
    debugger;
    let model = {
      type: 'CHAT',
      content: this.cannedMessageDetail,
      userId: this.stateModel?.agent?.id,
      userType: 'AGENT',
      sender: this.stateModel?.agent?.displayName,
    };
    this.messageService.sendMessage(model, this.stateModel?.roomId);
    this.activeModal.close('Cross click');
  }
}
