<div *ngIf="calendarService.approvalEvents.length && hasApprovePermission" class="col-xs-12 col-md-12 l-d-f l-mb-4 box-warning box-approval">
  <div class="l-d-f l-as-cen">
    <cds-icon icon="action:warning"></cds-icon>
    <span class="l-pl-2">Events pending approval </span>
    <span class="underline-green cursor-pointer l-pl-1" (click)="approvalEvent()">here</span>.
  </div>
</div>
<div *ngIf="calendarService.declineEvents && hasEditPermission" class="col-xs-12 col-md-12 l-d-f l-mb-4 box-warning">
  <div class="l-d-f l-as-cen">
    <cds-icon icon="action:warning"></cds-icon>
    <span class="l-pl-2">Update on declined cases, please review the events </span>
    <span class="underline-green cursor-pointer l-pl-1" (click)="declineEvent()">here</span>.
  </div>
</div>
<div class="col-xs-12 col-md-12 l-d-f l-plr-0">
  <div class="col-xs-2 col-md-2 l-d-f l-fd-col padding-none cds-white">
    <app-event-check [data]="checkdEvents" (eventChange)="eventCheckChange($event)"></app-event-check>
  </div>
  <div class="col-xs-10 col-md-10 l-d-f l-pl-3 l-pr-0">
    <div class="col-xs-12 col-md-12 l-plr-0">
      <div class="row text-center cds-white l-pl-5 l-pr-5 l-pt-3 l-pb-3 l-mb-4">
        <div class="col-md-6 l-d-f l-ai-cen padding-none">
          <cds-dropdown [(ngModel)]="mh" (ngModelChange)="mounthChange($event)" [config]="monthCfg" class="l-pr-5"></cds-dropdown>
          <cds-dropdown *ngIf="yearCfg" [(ngModel)]="yr" (ngModelChange)="yearChange($event)" [config]="yearCfg"></cds-dropdown>
        </div>
        <div class="col-md-6 l-d-f l-jc-fe l-ai-cen">
          <div *ngIf="hasEditPermission" class="l-d-f l-pl-4 l-pr-4 padding-none icon-box" (click)="addNewEvent()" style="cursor: pointer">
            <div class="icon-add">
              <cds-icon size="sm" color="white" icon="action:button_plus"></cds-icon>
            </div>
            <span class="font-bold l-pl-3">{{ 'calendar.action.add' | lang }}</span>
          </div>

          <ng-container *ngIf="this.calendarService.reviewItems() === 0">
            <div
              *ngIf="hasReviewPermission && calendarService.reviewEvents"
              class="l-d-f l-pl-4 l-pr-4 padding-none icon-box"
              (click)="reviewEvent()"
              style="cursor: pointer">
              <div class="icon-add">
                <cds-icon size="sm" color="white" icon="action:button_checkmark"></cds-icon>
              </div>
              <span class="font-bold l-pl-3">{{ 'calendar.action.review' | lang }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="this.calendarService.reviewItems() > 0">
            <div *ngIf="hasReviewPermission" class="l-d-f l-pl-4 l-pr-4 padding-none icon-box" (click)="approvalEvent()" style="cursor: pointer">
              <div class="icon-add">
                <cds-icon size="sm" color="white" icon="action:button_checkmark"></cds-icon>
              </div>
              <span class="font-bold l-pl-3">{{ 'calendar.action.review' | lang }}({{ this.calendarService.reviewItems() }})</span>
            </div>
          </ng-container>
        </div>
      </div>

      <div [ngSwitch]="'month'">
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events"
          [activeDayIsOpen]="activeDayIsOpen"
          [openDayEventsTemplate]="customOpenDayEventsTemplate"
          (dayClicked)="dayClicked($event.day)">
        </mwl-calendar-month-view>
      </div>

      <ng-template #customOpenDayEventsTemplate let-events="events" let-isOpen="isOpen">
        <div class="cal-open-day-events" *ngIf="isOpen" [@collapse]>
          <div *ngFor="let event of events" [style.borderColor]="event.color?.primary" class="calendar-custom-event l-mt-0 l-mb-0 l-mr-2 l-ml-2">
            <span class="cal-event l-mr-2" [style.backgroundColor]="event.color?.primary"></span>
            <mwl-calendar-event-title class="l-mr-2" [event]="event" view="month"> </mwl-calendar-event-title>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
