/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdsOption } from '@cds/ng-core/configuration';
import { IPageableRequest } from 'src/app/core/models/request';
import { BusinessNatureE, ContactPersion } from '../employer/employer';

export interface AbstractExpand {
  expandData?: object;
}
export interface Employee extends AbstractExpand {
  id?: string;
  accountType?: string;
  dateJoiningTheSchm?: string;
  dateOfBirth?: string;
  dateOfEmployment?: string;
  empleeType?: string;
  employerId?: string;
  firstName?: string;
  lastName?: string;
  firstZhName?: string;
  lastZhName?: string;
  gender?: string;
  lastDateOfEmployment?: string;
  originalEmploymentDate?: string;
  status?: EmployeeStatusE;
  termDate?: string;
  trusteeEmplrCd?: string;
  trusteeMbrId?: string;
  empfID?: string;
  empfId?: string;
  dob?: string;
  age?: string;
  legalIdType?: string;
  legalId?: string;
  loyaltyDate?: string;
  nationality?: string[];
  optionLastUpdateDate?: string;
  memberAuthorization?: string;
  bankruptcyReceiveDate?: string;
  bankruptcyDate?: string;
  dischargeDate?: string;
  bankruptcyNumber?: string;
  oroContact?: string;
  md5?: string;
  customerId?: string;
}

export interface EeSearchRequest extends IPageableRequest {
  [key: string]: any;
  enrolmentCompletionDateFrom?: string;
  enrolmentCompletionDateTo?: string;
  phoneNumberExt?: string;
  policyStatus?: string | CdsOption[];
  memberAccountType?: string | CdsOption[];
  servicingChannel?: string | CdsOption[];
}

export interface CampaignInformationRequest extends IPageableRequest {
  id?: string;
  md5?: string;
  pageChannel?: string;
}

export interface CampaignInformation {
  policyNo?: string;
  description?: string;
  customerCommunicationSendChannel?: string;
  customerCommunicationSendDate?: string;
  campaignPeriod?: string;
  followUpAgent?: string;
}

export enum MemberAccountTypeE {
  MPF_EE_R = 'MPF-EE(R)',
  MPF_EE_C = 'MPF-EE(C)',
  MPF_SEP = 'MPF-SEP',
  MPF_AGENT_SEP = 'MPF-AGENT SEP',
  MPF_TVC = 'MPF-TVC',
  MPF_PAP = 'MPF-PAP',
  MPF_FRC = 'MPF-FRC',
  ORSO_EE = 'ORSO-EE',
  Macau_EE = 'Macau-EE',
}

export const memberAccountTypeOptions: CdsOption[] = [
  { label: 'MPF-EE(R)', value: MemberAccountTypeE.MPF_EE_R },
  { label: 'MPF-EE(C)', value: MemberAccountTypeE.MPF_EE_C },
  { label: 'MPF-SEP', value: MemberAccountTypeE.MPF_SEP },
  { label: 'MPF-AGENT SEP', value: MemberAccountTypeE.MPF_AGENT_SEP },
  { label: 'MPF-TVC', value: MemberAccountTypeE.MPF_TVC },
  { label: 'MPF-PAP', value: MemberAccountTypeE.MPF_PAP },
  { label: 'MPF-FRC', value: MemberAccountTypeE.MPF_FRC },
  { label: 'ORSO-EE', value: MemberAccountTypeE.ORSO_EE },
  { label: 'Macau-EE', value: MemberAccountTypeE.Macau_EE },
];

export enum AccountTypeE {
  CA = 'CA',
  PA = 'PA',
  SEP = 'SEP',
  TVC = 'TVC',
  FRC = 'FRC',
  MACAU_EE = 'Macau-EE',
  ORSO_EE = 'ORSO-EE',
}

export const MemberAccountTypeMap = new Map([
  [MemberAccountTypeE.MPF_EE_R, AccountTypeE.CA],
  [MemberAccountTypeE.MPF_EE_C, AccountTypeE.CA],
  [MemberAccountTypeE.MPF_SEP, AccountTypeE.SEP],
  [MemberAccountTypeE.MPF_AGENT_SEP, AccountTypeE.SEP],
  [MemberAccountTypeE.MPF_TVC, AccountTypeE.TVC],
  [MemberAccountTypeE.MPF_PAP, AccountTypeE.PA],
  [MemberAccountTypeE.MPF_FRC, AccountTypeE.FRC],
  [MemberAccountTypeE.Macau_EE, AccountTypeE.CA],
  [MemberAccountTypeE.ORSO_EE, AccountTypeE.CA],
]);

export enum PolicyStatusE {
  RECEIVE = 'RECEIVE',
  FOLLOW_UP_IN_PRGRESS = 'FOLLOW_UP_IN_PRGRESS',
  ACTIVE = 'ACTIVE',
  TERMG = 'TERMG',
  TERMD = 'TERMD',
  TR_REJECT = 'TR_REJECT',
  CANCEL = 'CANCEL',
  RETAIN = 'RETAIN',
  TEMP = 'TEMP',
  PROVISIONAL = 'PROVISIONAL',
  FOD = 'FOD',
}

export const policyStatusOptions: CdsOption[] = [
  { label: 'Received and pending enrolment', value: PolicyStatusE.RECEIVE },
  { label: 'In progress, Unclean and need follow up action', value: PolicyStatusE.FOLLOW_UP_IN_PRGRESS },
  { label: 'Active', value: PolicyStatusE.ACTIVE },
  { label: 'Terminating', value: PolicyStatusE.TERMG },
  { label: 'Terminated', value: PolicyStatusE.TERMD },
  { label: 'Rejected by Trustee', value: PolicyStatusE.TR_REJECT },
  { label: 'Cancelled by client', value: PolicyStatusE.CANCEL },
  { label: 'Retain ESS Member', value: PolicyStatusE.RETAIN },
  { label: 'Temporary after AEOI', value: PolicyStatusE.TEMP },
  { label: 'Provisional with invested monies before AEOI', value: PolicyStatusE.PROVISIONAL },
  { label: 'FOD Terminated Fund on deposit', value: PolicyStatusE.FOD },
];

export enum LineOfBusinessE {
  MPF = 'MPF',
  ORSO = 'ORSO',
  MacauCPF = 'Macau CPF',
  MacauPPS = 'Macau PPS',
}

export const lineOfBusinessOptions: CdsOption[] = [
  { label: 'MPF', value: LineOfBusinessE.MPF },
  { label: 'ORSO', value: LineOfBusinessE.ORSO },
  { label: 'Macau CPF', value: LineOfBusinessE.MacauCPF },
  { label: 'Macau PPS', value: LineOfBusinessE.MacauPPS },
];

export enum ServicingChannelE {
  Agency = 'Agency',
  Broker = 'Broker',
  SCB = 'SCB',
  DBS = 'DBS',
  Corporate = 'Corporate',
  Direct = 'Direct',
}

export const servicingChannelOptions: CdsOption[] = [
  { label: 'Agency', value: ServicingChannelE.Agency },
  { label: 'Broker', value: ServicingChannelE.Broker },
  { label: 'SCB', value: ServicingChannelE.SCB },
  { label: 'DBS', value: ServicingChannelE.DBS },
  { label: 'Corporate', value: ServicingChannelE.Corporate },
  { label: 'Direct', value: ServicingChannelE.Direct },
];

export interface ContryCode {
  crsCountryCode: string;
  countryCallingCode: string;
}

export interface RelatedPolicy {
  md5: string;
  id?: string;
  memberAccountCode?: string;
  status?: string;
  originalMemberAccountCode?: string;
  statusChangeDate?: string;
  memberAccountType?: MemberAccountTypeE;
  defaultPA?: string;
  firstName?: string;
  lastName?: string;
  firstZhName?: string;
  lastZhName?: string;
  latestUpdate?: string;
  schemeCode?: SchemeCodeE;
  employeeType?: EmployeeTypeE;
  businessNature?: BusinessNatureE;
  occupation?: string;
  jobPosition?: string;
  schemeJoinedDate?: string;
  commissionPoolNumber?: string;
  originalSchemeJoinedDate?: string;
  previousCaMemberCode?: string;
  abOffer?: string;
  schemeMergeSignal?: string;
  enrolementSubmissionChannel?: string;
  enrolementCompletionDate?: string;
  applicationReceivedDate?: string;
  declarationCompletionDate?: string;
  empfId?: string;
  dob?: string;
  age?: number;
  gender?: GenderE;
  employerName?: string;
  ecaLastTransferInDate?: string;
  pmLastTransferInDate?: string;
}

export enum GenderE {
  M = 'M',
  F = 'F',
  U = 'U',
  O = 'O',
}

export const GenderMap: Record<GenderE, string> = {
  [GenderE.M]: 'Male',
  [GenderE.F]: 'Female',
  [GenderE.U]: 'Unisex',
  [GenderE.O]: 'No Such Information',
};

export enum EmployeeTypeE {
  NEW = 'NEW',
  EXIST = 'EXIST',
  INTRA_GROUP = 'INTRA_GROUP',
  EXEMPT = 'EXEMPT',
  EXPATRIATE = 'EXPATRIATE',
}

export const EmployeeTypeMap: Record<EmployeeTypeE, string> = {
  [EmployeeTypeE.NEW]: 'New Employee',
  [EmployeeTypeE.EXIST]: 'Existing Employee',
  [EmployeeTypeE.INTRA_GROUP]: 'Intra-group Transfer',
  [EmployeeTypeE.EXEMPT]: 'Exempted Person',
  [EmployeeTypeE.EXPATRIATE]: 'Expatriate Employee',
};

export enum SchemeCodeE {
  RC = 'RC',
  GS = 'GS',
}

export const SchemeCodeMap = new Map([
  [SchemeCodeE.RC, 'Manulife RetireChoice (MPF) Scheme'],
  [SchemeCodeE.GS, 'Manulife Global Select (MPF) Scheme'],
]);

export enum EmployeeStatusE {
  RECEIVE = 'RECEIVE',
  FOLLOW_UP_IN_PRGRESS = 'FOLLOW_UP_IN_PRGRESS',
  UNCLEAN = 'UNCLEAN',
  ACTIVE = 'ACTIVE',
  TERMG = 'TERMG',
  TERMD = 'TERMD',
  TR_REJECT = 'TR_REJECT',
  CANCEL = 'CANCEL',
  RETAIN = 'RETAIN',
  TEMP = 'TEMP',
  PROVISIONAL = 'PROVISIONAL',
}

export const employeeStatusMapObj = {
  [EmployeeStatusE.RECEIVE]: {
    text: 'RECEIVE',
    color: '#28D7CB',
  },
  [EmployeeStatusE.FOLLOW_UP_IN_PRGRESS]: {
    text: 'Follow Up In Progress',
    color: '#F49600',
  },
  [EmployeeStatusE.UNCLEAN]: {
    text: 'Unclean & Need Follow Up',
    color: '#0000C1',
  },
  [EmployeeStatusE.ACTIVE]: {
    text: 'Active',
    color: '#00A758',
  },
  [EmployeeStatusE.TERMG]: {
    text: 'Terminating',
    color: '#6F5693',
  },
  [EmployeeStatusE.TERMD]: {
    text: 'Terminated',
    color: '#8E90A2',
  },
  [EmployeeStatusE.TR_REJECT]: {
    text: 'Rejected by Trustee',
    color: '#D03A39',
  },
  [EmployeeStatusE.CANCEL]: {
    text: 'Cancelled by client',
    color: '#EC6453',
  },
  [EmployeeStatusE.RETAIN]: {
    text: 'Retain ESS Member',
    color: '#5CD790',
  },
  [EmployeeStatusE.TEMP]: {
    text: 'Temporary after AEOI',
    color: '#FCC457',
  },
  [EmployeeStatusE.PROVISIONAL]: {
    text: 'Provisional',
    color: '#9D8DBC',
  },
};

export interface FundBalanceResponse {
  asOfDate?: string;
  fundBalanceTotal?: string;
  netContributionsAndNetTransferIn?: string;
  accountFainLoss?: string;
  totalContributionOrAssetTransferIn?: string;
  totalWithdrawalAssetTransferOut?: string;
  vestingEmployerVoluntaryAccumulation?: string;
  dateOfTransfer?: string;
  fundTransactionFormType?: string;
  currency?: string;
  fundBalanceList?: FundBalanceItem[];
}

export interface FundBalanceItem {
  fundCodeOrFundName?: string;
  mandatoryContributionForERMC?: string;
  mandatoryContributionForEEMC?: string;
  voluntaryContributionERVC?: string;
  voluntaryContributionEEVC?: string;
  formerEmploymentMandatoryContributionERMC?: string;
  formerEmploymentVoluntaryContributionERMC?: string;
  currency?: string;
}
export interface ContactInfo {
  phoneList: ContactPhone[];
  emailList: ContactEmail[];
  addressList: ContactAddress[];
  communicationType?: string;
  languageType?: string;
  memberAuthorization?: string;
  memberAuthorizationLastUpdate?: string;
  lastUpdate?: string;
  all?: string;
  directMailing?: string;
  email?: string;
  sms?: string;
  telemarketing?: string;
  enotification?: string;
  estatementOptInFlag?: string;
}

export interface ContactPhone {
  typeCode?: string;
  countryCode?: string;
  phoneNo?: string;
  phoneExt?: string;
  lastUpdate?: string;
  status?: string;
}

export interface ContactEmail {
  emailAddress?: string;
  lastUpdate?: string;
  status?: string;
}

export interface ContactAddress {
  room?: string;
  floor?: string;
  block?: string;
  building?: string;
  street?: string;
  district?: string;
  city?: string;
  country?: string;
  addressType?: string;
  lastUpdate?: string;
  status?: string;
}

export interface Contact {
  [key: string]: any;
  lastUpdate: string;
  status: string;
}

export interface EmploymentInfo {
  companyInfo?: CompanyInfo;
  employmentInfo?: EmploymentInfo;
  showEmploymentInfo?: boolean;
  erId?: string;
  payrollGroupId?: string;
}

export interface CompanyInfo {
  companyEnglishName?: string;
  companyChineseName?: string;
  companyType?: string;
  statusCode?: string;
  certificateOfIncorporationNumber?: string;
  dateOfIncorporation?: string;
  placeOfIncorporation?: string;
  commencementDate?: string;
  registrationType?: string;
  registrationNumber?: string;
  branchNumber?: string;
}

export interface EmploymentInfo {
  employeeType?: string;
  originalEmploymentDate?: string;
  dateOfEmployment?: string;
  lastDateOfEmployment?: string;
  expartriateVisaIssueDate?: string;
  departmentCode?: string;
  staffNumber?: string;
  employerGrade?: number;
}

export interface DefaultContributionRequest {
  startDate?: string;
  id?: string;
  erAcctCd?: string;
  type: 'month' | 'day';
  month?: string;
}

export interface DefaultContribution {
  startDate?: string;
  endDate?: string;
  defaultContributionReportedDateToMPFA?: DcMsgTypeE;
  messageTypeofDefaultContributionReporting?: string;
  submissionTypeofDefaultContributionReporting?: string;
  defaultContributionReason?: string;
  rectifiedType?: string;
  untraceableDefaulterFlag?: string;
  memberContributionDueDate?: string;
  onTimeTb?: PaidAmount[];
  lateTb?: PaidAmount[];
  surchargeER?: number;
  surchargeEE?: number;
  surchargePaidDate?: string;
  surchargeDueDate?: string;
  remarksOfDefaultContribution?: string;
  mpfaRemarks?: string;
}

export interface TransferReference {
  refNo?: string;
  statusCd?: string;
}

export interface PaidAmount {
  type?: string;
  mcPaidDate?: string;
  vcPaidDate?: string;
  ermc?: number;
  eemc?: number;
  ervc?: number;
  eevc?: number;
}
export interface Surcharge {
  surchargeER?: number;
  surchargeEE?: number;
  surchargePaidDate?: string;
  surchargeDueDate?: string;
}

export enum DcMsgTypeE {
  OSDC = 'OSDC',
  RTDC = 'RTDC',
}

export const dcMsgTypeMapObj = {
  [DcMsgTypeE.OSDC]: 'Outstanding Data',
  [DcMsgTypeE.RTDC]: 'Rectified Data',
};

export enum DcReasonTypeE {
  NOPAY = 'NOPAY',
  PAYNA = 'PAYNA',
  MISIF = 'MISIF',
  PRPAY = 'PRPAY',
  MISCL = 'MISCL',
}

export const dcReasonTypeMapObj = {
  [DcReasonTypeE.NOPAY]: 'No full payment received (For ER & SEP types)',
  [DcReasonTypeE.PAYNA]: 'Full payment cannot be ascertained (For ER type only)',
  [DcReasonTypeE.MISIF]: 'Missing payment and contribution information of the specific EE',
  [DcReasonTypeE.PRPAY]: 'Dc reason for contribution periods start date before 2014-07-01',
  [DcReasonTypeE.MISCL]: 'Dc reason for contribution periods start date before 2014-07-01',
};

export enum RectifyTypeE {
  FPAID = 'FPAID',
  PPAID = 'PPAID',
  RENOT = 'RENOT',
  READM = 'READM',
  CHCPD = 'CHCPD',
}

export const rectifyTypeMapObj = {
  [RectifyTypeE.FPAID]: 'Full Payment / Rectification',
  [RectifyTypeE.PPAID]: 'Partial Payment / Rectification',
  [RectifyTypeE.RENOT]: 'Resolved Problem of NOT (Notice of Termination)',
  [RectifyTypeE.READM]: 'READM - Resolved Other Admin. Problems (By Trustee)',
  [RectifyTypeE.CHCPD]:
    'Changed Contribution Period Details (Reported DC record with outstanding would be cleared. Another DC record should be reported with the changed Contribution Period details if there is late or outstanding payment)',
};

export interface PolicyTransferRequest extends IPageableRequest {
  transferReferenceNo?: string;
  groupReferenceNo?: string;
  statusCode?: string;
  activityType?: string;
  fundTransactionFormType?: string;
  dateOfTransfer?: string;
  originalRequestedSubmissionDate?: string;
  id?: string;
}

export interface PolicyTransfer {
  transferReferenceNo?: string;
  groupReferenceNo?: string;
  statusCode?: string;
  activityType?: string;
  fundTransactionFormType?: string;
  dateOfTransfer?: string;
  originalRequestedSubmissionDate?: string;
}

export interface PolicyTransferDetails {
  firstName?: string;
  lastName?: string;
  firstZhName?: string;
  lastZhName?: string;
  status?: EmployeeStatusE;
  memberAccountCode?: string;
  memberAccountType?: string;
  latestUpdate?: string;
  groupReferenceNo?: string;
  uniqueTransferCaseNo?: string;
  workflowReferenceNo?: string;
  transferReferenceNo?: string;
  statusCode?: PolicyTransferStatusCodeE;
  activityType?: TransferActivityTypeE;
  fundTransactionFormType?: FundTransactionFormTypeE;
  pmTransferOption?: PmtransferOptionTypeE;
  ppTransferOption?: PptransferOptionTypeE;
  submissionType?: SubmissionTypeE;
  dateOfTransfer?: string;
  originalRequestedSubmissionDate?: string;
  subAccountType?: SubAccountTypeE;
  intraGroupTransactionFlag?: YesOrNoFlagE;
  completeDocumentReceiptDate?: string;
  transferLastFollowUpDate?: string;
  transferOutMemberAccountCode?: string;
  transferOutSchemeName?: string;
  transferOutSchemeRegistrationNumber?: string;
  transferOutOriginalScheme?: string;
  transferInMemberAccountCode?: string;
  transferInSchemeName?: string;
  transferInSchemeRegistrationNumber?: string;
  transferInOriginalScheme?: string;
  rejectionReason?: string[];
  remarkOfRejectionReason?: string;
  replyType?: string;
  transferReferenceList?: TransferReference[];
}

export enum TransferActivityTypeE {
  TRF_IN = 'TRF_IN',
  TRF_OUT = 'TRF_OUT',
}

export const transferActivityTypeMapObj = {
  [TransferActivityTypeE.TRF_IN]: 'Transfer_IN',
  [TransferActivityTypeE.TRF_OUT]: 'Transfer_OUT',
};

export const transferActivityTypeOptions: CdsOption[] = [
  { value: TransferActivityTypeE.TRF_IN, label: 'Transfer_IN' },
  { value: TransferActivityTypeE.TRF_OUT, label: 'Transfer_OUT' },
];

export enum FundTransactionFormTypeE {
  PP = 'PP',
  PM = 'PM',
  PC = 'PC',
  PT = 'PT',
  PE = 'PE',
  OT = 'OT',
}

export const fundTransactionFormTypeMapObj = {
  [FundTransactionFormTypeE.PP]: 'Employee Choice Arrangement ("ECA")',
  [FundTransactionFormTypeE.PM]: "Scheme Member's Request for Fund Transfer",
  [FundTransactionFormTypeE.PC]: "Scheme Member's Request for Account Consolidation",
  [FundTransactionFormTypeE.PT]: "Scheme member's request for Transfer Tax Deductible Voluntary Contribution",
  [FundTransactionFormTypeE.PE]: "Employer's request for fund transfer form",
  [FundTransactionFormTypeE.OT]: 'Other than the above',
};

export const fundTransactionFormTypeOptions: CdsOption[] = [
  { value: FundTransactionFormTypeE.PP, label: 'Employee Choice Arrangement ("ECA")' },
  { value: FundTransactionFormTypeE.PM, label: "Scheme Member's Request for Fund Transfer" },
  { value: FundTransactionFormTypeE.PC, label: "Scheme Member's Request for Account Consolidation" },
  { value: FundTransactionFormTypeE.PT, label: "Scheme member's request for Transfer Tax Deductible Voluntary Contribution" },
  { value: FundTransactionFormTypeE.PE, label: "Employer's request for fund transfer form" },
  { value: FundTransactionFormTypeE.OT, label: 'Other than the above' },
];

export enum PmtransferOptionTypeE {
  EE_ALL = 'EE_ALL',
  EE_MC = 'EE_MC',
  RETAIN = 'RETAIN',
}

export const pmtransferOptionTypeMapObj = {
  [PmtransferOptionTypeE.EE_ALL]:
    'Transfer both Former Employment Employee Mandatory Contribution and Former Employment Employee Voluntary Contribution to the new scheme',
  [PmtransferOptionTypeE.EE_MC]:
    'Transfer Former Employment Employee Mandatory Contribution to the new scheme and cash out Former Employment Employee Voluntary Contribution',
  [PmtransferOptionTypeE.RETAIN]: 'Retain all benefits in a personal account in the current scheme',
};

export enum PptransferOptionTypeE {
  ALL = 'ALL',
  PARTIAL = 'PARTIAL',
}

export const pptransferOptionTypeMapObj = {
  [PptransferOptionTypeE.ALL]: 'Transfer Option A - Transfer ALL',
  [PptransferOptionTypeE.PARTIAL]: 'Transfer Option B - Partial Transfer',
};

export enum SubmissionTypeE {
  NEWD = 'NEWD',
  REPY = 'REPY',
  RECO = 'RECO',
}

export const submissionTypeMapObj = {
  [SubmissionTypeE.NEWD]: 'Report the transfer case is processed by the original trustee, and accrued benefitsare transferred to new scheme',
  [SubmissionTypeE.REPY]: 'Report the transfer case is rejected by the original trustee, and/or nil amount fortransfer to new scheme',
  [SubmissionTypeE.RECO]: 'Similar to "NEWD" case, pending on old trustee to reply. (Sent by trustees)',
};

export enum SubAccountTypeE {
  ERMC = 'ERMC',
  EEMC = 'EEMC',
  ERVC = 'ERVC',
  ERVC2 = 'ERVC2',
  ERVC3 = 'ERVC3',
  ERVC4 = 'ERVC4',
  ERVC5 = 'ERVC5',
  ERVC6 = 'ERVC6',
  ERVC7 = 'ERVC7',
  EEVC = 'EEVC',
  EEVC2 = 'EEVC2',
  FRMC = 'FRMC',
  FRVC = 'FRVC',
  TVC = 'TVC',
  SEPMC = 'SEPMC',
  SEPVC = 'SEPVC',
  PREMPFER = 'PREMPFER',
  PREMPFER2 = 'PREMPFER2',
  POSTMPFER = 'POSTMPFER',
  POSTMPFER2 = 'POSTMPFER2',
  PREMPFEE = 'PREMPFEE',
  PREMPFEE2 = 'PREMPFEE2',
  POSTMPFEE = 'POSTMPFEE',
  POSTMPFEE2 = 'POSTMPFEE2',
}

export const subAccountTypeMapObj = {
  [SubAccountTypeE.ERMC]: 'Employer mandatory contributions',
  [SubAccountTypeE.EEMC]: 'Employee mandatory contributions',
  [SubAccountTypeE.ERVC]: 'Employer voluntary contributions 1',
  [SubAccountTypeE.ERVC2]: 'Employer voluntary contributions 2',
  [SubAccountTypeE.ERVC3]: 'Employer voluntary contributions 3',
  [SubAccountTypeE.ERVC4]: 'Employer voluntary contributions 4',
  [SubAccountTypeE.ERVC5]: 'Employer voluntary contributions 5',
  [SubAccountTypeE.ERVC6]: 'Employer voluntary contributions 6',
  [SubAccountTypeE.ERVC7]: 'Employer voluntary contributions 7',
  [SubAccountTypeE.EEVC]: 'Employee voluntary contributions 1',
  [SubAccountTypeE.EEVC2]: 'Employee voluntary contributions 2',
  [SubAccountTypeE.FRMC]: 'Mandatory contributions from former employment(s)',
  [SubAccountTypeE.FRVC]: 'Voluntary contributions from former employment(s)',
  [SubAccountTypeE.TVC]: 'Tax Deductible Voluntary Contributions',
  [SubAccountTypeE.SEPMC]: 'Self-employed Persons mandatory contributions',
  [SubAccountTypeE.SEPVC]: 'Self-employed Persons voluntary contributions',
  [SubAccountTypeE.PREMPFER]: 'Pre MPF Service ER Benefits',
  [SubAccountTypeE.PREMPFER2]: 'Pre MPF Service ER Benefits 2',
  [SubAccountTypeE.POSTMPFER]: 'Post MPF Service ER Benefits',
  [SubAccountTypeE.POSTMPFER2]: 'Post MPF Service ER Benefits 2',
  [SubAccountTypeE.PREMPFEE]: 'Pre MPF Service EE Benefits',
  [SubAccountTypeE.PREMPFEE2]: 'Pre MPF Service EE Benefits 2',
  [SubAccountTypeE.POSTMPFEE]: 'Post MPF Service EE Benefits',
  [SubAccountTypeE.POSTMPFEE2]: 'Post MPF Service EE Benefits 2',
};

export enum YesOrNoFlagE {
  Y = 'Y',
  N = 'N',
}

export const yesOrNoFlagMapObj = {
  [YesOrNoFlagE.Y]: 'Yes',
  [YesOrNoFlagE.N]: 'No',
};

export const yesOrNoFlagOptions: CdsOption[] = [
  {
    label: 'Yes',
    value: YesOrNoFlagE.Y,
  },
  {
    label: 'No',
    value: YesOrNoFlagE.N,
  },
];

export enum YesOrNoFlagNumberE {
  Y = 1,
  N = 0,
}

export const yesOrNoFlagNumberMapObj = {
  [YesOrNoFlagNumberE.Y]: 'Yes',
  [YesOrNoFlagNumberE.N]: 'No',
};

export const yesOrNoFlagNumberOptions: CdsOption[] = [
  {
    label: 'Yes',
    value: YesOrNoFlagNumberE.Y,
  },
  {
    label: 'No',
    value: YesOrNoFlagNumberE.N,
  },
];

export interface TransferReference {
  transferReferenceNo?: string;
  statusCode?: string;
}

export enum PolicyTransferStatusCodeE {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  UNCLEAN = 'UNCLEAN',
  PENDING = 'PENDING',
  PEND_PAYMT_SETTLE = 'PEND_PAYMT_SETTLE',
  NIGHT_BATCH_PEND = 'NIGHT_BATCH_PEND',
  ORDER_IN_PROGRESS = 'ORDER_IN_PROGRESS',
}

export const policyTransferStastusCodeMapObj = {
  [PolicyTransferStatusCodeE.CANCELLED]: {
    text: 'Cancelled',
    color: '#EC6453',
  },
  [PolicyTransferStatusCodeE.COMPLETED]: {
    text: 'Completed',
    color: '#00A758',
  },
  [PolicyTransferStatusCodeE.RECEIVED]: {
    text: 'Received',
    color: '#28D7CB',
  },
  [PolicyTransferStatusCodeE.REJECTED]: {
    text: 'Rejected',
    color: '#8E90A2',
  },
  [PolicyTransferStatusCodeE.SUBMITTED]: {
    text: 'Submitted',
    color: '#6F5693',
  },
  [PolicyTransferStatusCodeE.ACCEPTED]: {
    text: 'Accepted',
    color: '#0000C1',
  },
  [PolicyTransferStatusCodeE.UNCLEAN]: {
    text: 'Unclean',
    color: '#D03A39',
  },
  [PolicyTransferStatusCodeE.PENDING]: {
    text: 'Pending',
    color: '#F49600',
  },
  [PolicyTransferStatusCodeE.PEND_PAYMT_SETTLE]: {
    text: 'Pend_payment_settle',
    color: '#FCC457',
  },
  [PolicyTransferStatusCodeE.NIGHT_BATCH_PEND]: {
    text: 'Night_batch_pend',
    color: '#9D8DBC',
  },
  [PolicyTransferStatusCodeE.ORDER_IN_PROGRESS]: {
    text: 'Order_in_progress',
    color: '#5CD790',
  },
};

export const policyTransferStastusCodeOptions: CdsOption[] = [
  { value: PolicyTransferStatusCodeE.CANCELLED, label: 'Cancelled' },
  {
    value: PolicyTransferStatusCodeE.COMPLETED,
    label: 'Completed',
  },
  { value: PolicyTransferStatusCodeE.RECEIVED, label: 'Received' },
  { value: PolicyTransferStatusCodeE.REJECTED, label: 'Rejected' },
  { value: PolicyTransferStatusCodeE.SUBMITTED, label: 'Submitted' },
  { value: PolicyTransferStatusCodeE.ACCEPTED, label: 'Accepted' },
  { value: PolicyTransferStatusCodeE.UNCLEAN, label: 'Unclean' },
  { value: PolicyTransferStatusCodeE.PENDING, label: 'Pending' },
  { value: PolicyTransferStatusCodeE.PEND_PAYMT_SETTLE, label: 'Pend_payment_settle' },
  { value: PolicyTransferStatusCodeE.NIGHT_BATCH_PEND, label: 'Night_batch_pend' },
  { value: PolicyTransferStatusCodeE.ORDER_IN_PROGRESS, label: 'Order_in_progress' },
];

export const reasonMapObj = {
  ['01']: 'Missing / Incorrect HKID or passport number',
  ['02']: 'Missing / Incorrect name of scheme member',
  ['03']: 'Incorrect membership number under Original Scheme',
  ['04']: 'Incorrect Original Scheme Registration Number',
  ['05']: 'Missing / Mismatch member signature',
  ['06']: 'No such member record in Original Scheme',
  ['07']: 'Member account terminated',
  ['09']: 'Preserved to a personal account',
  ['10']: 'Transfer request has been cancelled by the member',
  ['11']: 'Member has multiple accounts in Original Scheme',
  ['12']: 'Missing / Invalid option in arrangement of voluntary contributions',
  ['13']: 'Missing Notice of Termination',
  ['14']: 'No selection of which Sub-account(s) to be transferred out',
  ['15']: 'Member has ceased employment',
  ['16']: 'Unmatched data and image file',
  ['17']: 'Member still under employment',
  ['18']: 'Missing page of transfer form',
  ['19']: 'No Member Signature Specimen',
  ['99']: 'Other-please specify',
  ['20']: 'EEMC_In excess of number election allowed',
  ['21']: 'EEMC_Account contained nil balance',
  ['22']: 'EEVC_VC not allow to transfer out because not fulfill the transfer conditions in the governingg rules',
  ['23']: 'EEVC_Account contained nil balance',
  ['24']: 'FRMC_Account contained nil balance',
  ['25']: 'FR VC_ VC not allowed to transfer out because not fulfill the transfer conditions in governing rules',
  ['26']: 'FRVC_Account contained nil balance',
  ['27']: 'All selected sub accounts contained nil balance',
  ['28']: "Member's account is a contribution account / self-employed account but P(C) form is received",
  ['29']: "Member's account is a personal account / self-employed account but P(P) form is received Original",
};

export enum ReplyTypeE {
  SUCC = 'SUCC',
  REJE = 'REJE',
}

export const replyTypeMapObj = {
  [ReplyTypeE.SUCC]: 'Report the transfer case is processed by the original trustee, and accrued benefits are transferred to new scheme',
  [ReplyTypeE.REJE]: 'Report the transfer case is rejected by the original trustee, and/or nil amount for transfer to new scheme',
};

export interface PolicyWithdrawalRequest extends IPageableRequest {
  withdrawalReferenceNo?: string;
  groupReferenceNo?: string;
  paymentOutReferenceNo?: string;
  originalSubmissionDate?: string;
  withdrawalStatus?: string;
  paymentOutStatus?: PaymentOutStatusE;
  claimTypeReason?: string;
  completedReceiptDateOfClaim?: string;
  id?: string;
}

export interface PolicyWithdrawal {
  transferReferenceNo?: string;
  groupReferenceNo?: string;
  paymentOutReferenceNo?: string;
  originalSubmissionDate?: string;
  withdrawalStatus?: string;
  paymentOutStatus?: PaymentOutStatusE;
  claimTypeReason?: string;
  completedReceiptDateOfClaim?: string;
}

export interface PolicyWithdrawalDetails {
  firstName?: string;
  lastName?: string;
  firstZhName?: string;
  lastZhName?: string;
  status?: EmployeeStatusE;
  memberAccountCode?: string;
  memberAccountType?: string;
  latestUpdate?: string;
  groupReferenceNo?: string;
  workflowReferenceNo?: string;
  withdrawalReferenceNo?: string;
  statusCode?: string;
  fundTransactionFormType?: FundTransactionFormTypeE;
  withdrawalReferenceList?: WithdrawalReference[];
  originalSubmissionDate?: string;
  submissionChannel?: SubmissionChannelE;
  claimTypeReason?: ClaimTypeReasonE;
  completedReceiptDateOfClaim?: string;
  partialWithdrawalFlag?: YesOrNoFlagE;
  accountTerminationFlag?: YesOrNoFlagE;
  standingInstructionFlag?: YesOrNoFlagE;
  effectiveDate?: string;
  frequency?: FrequencyE;
  amount?: number;
  permanentDepartureClaimFlag?: YesOrNoFlagE;
  mpfaConsentStatusCode?: string;
  claimCode?: ClaimCodeE;
  permanentDepartureClaimReportedDate?: string;
  mpfaPDNo?: string;
  pdpReportedDate?: string;
  departureDate?: string;
  departureReason?: DepartureReasonE;
  destinationCountry?: string;
  mpfaRequestDate?: string;
  mpfaResponseDate?: string;
  bankruptcy?: string;
  oroTIBConsentStatusCode?: OroTIBConsentStatusCodeE;
  payBasisType?: PayBasisTypeE;
  payBasisValue?: number;
  adjustedWithdrawalAmountByOroOrOtherParty?: number;
  oroRequestDate?: string;
  oroResponseDate?: string;
  returnIssueCodeForOro?: ReturnIssueCodeForOroE;
  contactPersonList?: ContactPersion[];
  withdrawalPayeeList?: WithdrawalPayee[];
}

export interface WithdrawalReference {
  withdrawalReferenceNo?: string;
  statusCode?: string;
}

export enum PolicyWithdrawalStatusCodeE {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  COMPLETE = 'COMPLETE',
  INVEST_ORDER_PEND = 'INVEST_ORDER_PEND',
  NIGHT_BATCH_PEND = 'NIGHT_BATCH_PEND',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  PEND_PAYMT_SETTLE = 'PEND_PAYMT_SETTLE',
}

export const policyWithdrawalStatusCodeMapObj = {
  [PolicyWithdrawalStatusCodeE.PENDING_APPROVAL]: 'Pending MPFA/ORO Approval',
  [PolicyWithdrawalStatusCodeE.COMPLETE]: 'Transaction of old-new trustees had been completed after unit allocation, whole transfer had been completed',
  [PolicyWithdrawalStatusCodeE.INVEST_ORDER_PEND]: 'Awaiting for order completion',
  [PolicyWithdrawalStatusCodeE.NIGHT_BATCH_PEND]: 'Pending for nightly batch cycle to generate fund order',
  [PolicyWithdrawalStatusCodeE.CANCELLED]: 'Member cancel request before cutoff',
  [PolicyWithdrawalStatusCodeE.REJECTED]: 'The transfer request is rejected by eMPF or Old Trustee',
  [PolicyWithdrawalStatusCodeE.PEND_PAYMT_SETTLE]: 'Awaiting the payment to be presented',
};

export const policyWithdrawalStatusCodeOptions: CdsOption[] = [
  {
    value: PolicyWithdrawalStatusCodeE.PENDING_APPROVAL,
    label: 'Pending MPFA/ORO Approval',
  },
  {
    value: PolicyWithdrawalStatusCodeE.COMPLETE,
    label: 'Transaction of old-new trustees had been completed after unit allocation, whole transfer had been completed',
  },
  {
    value: PolicyWithdrawalStatusCodeE.INVEST_ORDER_PEND,
    label: 'Awaiting for order completion',
  },
  {
    value: PolicyWithdrawalStatusCodeE.NIGHT_BATCH_PEND,
    label: 'Pending for nightly batch cycle to generate fund order',
  },
  {
    value: PolicyWithdrawalStatusCodeE.CANCELLED,
    label: 'Member cancel request before cutoff',
  },
  {
    value: PolicyWithdrawalStatusCodeE.REJECTED,
    label: 'The transfer request is rejected by eMPF or Old Trustee',
  },
  {
    value: PolicyWithdrawalStatusCodeE.PEND_PAYMT_SETTLE,
    label: 'Awaiting the payment to be presented',
  },
];

export enum SubmissionChannelE {
  WEB = 'WEB',
  FAX = 'FAX',
  EMAIL = 'EMAIL',
  POST = 'POST',
  OTHERS = 'OTHERS',
}

export const submissionChannelMapObj = {
  [SubmissionChannelE.WEB]: 'ER/EE Web Portal',
  [SubmissionChannelE.FAX]: 'Fax',
  [SubmissionChannelE.EMAIL]: 'Email',
  [SubmissionChannelE.POST]: 'Post',
  [SubmissionChannelE.OTHERS]: 'Others',
};

export enum ClaimTypeReasonE {
  NR = 'NR',
  ER = 'ER',
  PD = 'PD',
  TI = 'TI',
  IL = 'IL',
  SB = 'SB',
  DN = 'DN',
  VW = 'VW',
}

export const claimTypeReasonMapObj = {
  [ClaimTypeReasonE.NR]: 'Normal Retirement',
  [ClaimTypeReasonE.ER]: 'Early Retirement',
  [ClaimTypeReasonE.PD]: 'Permanent Departure',
  [ClaimTypeReasonE.TI]: 'Total Incapacity',
  [ClaimTypeReasonE.IL]: 'Terminal Illness',
  [ClaimTypeReasonE.SB]: 'Small Balance',
  [ClaimTypeReasonE.DN]: 'Death',
  [ClaimTypeReasonE.VW]: 'VC / SVC Withdrawal',
};

export const claimTypeReasonOptions: CdsOption[] = [
  {
    value: ClaimTypeReasonE.NR,
    label: 'Normal Retirement',
  },
  {
    value: ClaimTypeReasonE.ER,
    label: 'Early Retirement',
  },
  {
    value: ClaimTypeReasonE.PD,
    label: 'Permanent Departure',
  },
  {
    value: ClaimTypeReasonE.TI,
    label: 'Total Incapacity',
  },
  {
    value: ClaimTypeReasonE.IL,
    label: 'Terminal Illness',
  },
  {
    value: ClaimTypeReasonE.SB,
    label: 'Small Balance',
  },
  {
    value: ClaimTypeReasonE.DN,
    label: 'Death',
  },
  {
    value: ClaimTypeReasonE.VW,
    label: 'VC / SVC Withdrawal',
  },
];

export enum FrequencyE {
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export const frequencyMapObj = {
  [FrequencyE.QUARTERLY]: 'Quarterly',
  [FrequencyE.MONTHLY]: 'Monthly',
  [FrequencyE.YEARLY]: 'Yearly',
};

export enum ClaimCodeE {
  PROC = 'PROC',
  NODD = 'NODD',
  FODD_DIFD = 'FODD_DIFD',
  FODD_PEDD = 'FODD_PEDD',
  FODD_PLDD = 'FODD_PLDD',
  CRCA = 'CRCA',
}

export const claimCodeMapObj = {
  [ClaimCodeE.PROC]: 'Pending (PDC accepted by MPFA and awaiting response)',
  [ClaimCodeE.NODD]: 'Approved for process (Accepted and ready for redemption)',
  [ClaimCodeE.FODD_DIFD]: 'Approved in process (Accepted and ready for redemption)',
  [ClaimCodeE.FODD_PEDD]: 'Rejected (Payment reported with earlier departure date)',
  [ClaimCodeE.FODD_PLDD]: 'Rejected (Payment reported with later departure date)',
  [ClaimCodeE.CRCA]: 'PDC is cancelled (PDC record is cancelled)',
};

export enum DepartureReasonE {
  EMIGRATION = 'EMIGRATION',
  RETIREMENT = 'RETIREMENT',
  FAMILY_REUNION = 'FAMILY_REUNION',
  OVERSEA_EMP = 'OVERSEA_EMP',
  MARRIAGE = 'MARRIAGE',
  OTHER = 'OTHER',
  NOT_PROVIDED = 'NOT_PROVIDED',
}

export const departureReasonMapObj = {
  [DepartureReasonE.EMIGRATION]: 'Emigration',
  [DepartureReasonE.RETIREMENT]: 'Retirement',
  [DepartureReasonE.FAMILY_REUNION]: 'Family reunion',
  [DepartureReasonE.OVERSEA_EMP]: 'Long-term overseas employment',
  [DepartureReasonE.MARRIAGE]: 'Marriage',
  [DepartureReasonE.OTHER]: 'Others',
  [DepartureReasonE.NOT_PROVIDED]: 'Not Provided',
};

export enum OroTIBConsentStatusCodeE {
  NEW = 'NEW',
  PENDING = 'PENDING',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

export enum PayBasisTypeE {
  UNIT = 'UNIT',
  AMOUNT = 'AMOUNT',
  PTC = 'PTC',
}

export const paybasisMapObj = {
  [PayBasisTypeE.UNIT]: 'Amount in unit',
  [PayBasisTypeE.AMOUNT]: 'Amount in amount',
  [PayBasisTypeE.PTC]: 'Amount in percentage',
};

export enum ReturnIssueCodeForOroE {
  PAY_EE = 'PAY_EE',
  NOPAY_EE = 'NOPAY_EE',
  PARTIAL_EE = 'PARTIAL_EE',
}

export const returnIssueCodeForOroMapObj = {
  [ReturnIssueCodeForOroE.PAY_EE]: 'Full paid to employee',
  [ReturnIssueCodeForOroE.NOPAY_EE]: 'Full paid to ORO (Official Receiver’s Office) /TIB (Trustee in Bankruptcy)',
  [ReturnIssueCodeForOroE.PARTIAL_EE]: 'Partial payment to employee and partial payment to ORO/TIB',
};

export interface WithdrawalPayee {
  paymentOutReferenceNo?: string;
  paymentOutStatus?: PaymentOutStatusE;
  paymentMethod?: string;
  paymentAmount?: number;
  currencyOfPayment?: string;
  paymentClearedDate?: string;
  chequeNumber?: string;
  chequeIssueDate?: string;
  paymentRejectReason?: string;
  reissuanceFlag?: string;
}

export enum PaymentOutStatusE {
  PEND_PAYMENT = 'PEND_PAYMENT',
  HOLD = 'HOLD',
  UNPRESENTED = 'UNPRESENTED',
  PAYMENT_SETTLE = 'PAYMENT_SETTLE',
  REJECT = 'REJECT',
  EXPIRED = 'EXPIRED',
  RE_ISSUE = 'RE_ISSUE',
}

export const paymentOutStatusOptions: CdsOption[] = [
  {
    value: PaymentOutStatusE.PEND_PAYMENT,
    label: 'PEND_PAYMENT',
  },
  {
    value: PaymentOutStatusE.HOLD,
    label: 'HOLD',
  },
  {
    value: PaymentOutStatusE.UNPRESENTED,
    label: 'UNPRESENTED',
  },
  {
    value: PaymentOutStatusE.PAYMENT_SETTLE,
    label: 'PAYMENT_SETTLE',
  },
  {
    value: PaymentOutStatusE.PEND_PAYMENT,
    label: 'PEND_PAYMENT',
  },
  {
    value: PaymentOutStatusE.REJECT,
    label: 'REJECT',
  },
  {
    value: PaymentOutStatusE.EXPIRED,
    label: 'EXPIRED',
  },
  {
    value: PaymentOutStatusE.RE_ISSUE,
    label: 'RE_ISSUE',
  },
];

export interface PolicyTermination {
  id?: string;
  schemeCd?: string;
  erAcctCd?: string;
  mbAcctCd?: string;
  grpRefNo?: string;
  refNo?: string;
  seqNo?: string;
  statusCd?: TerminationStatusE;
  termReason?: TerminationReasonE;
  notNotifyDate?: string;
  lspSpFlg?: YesOrNoFlagE;
  lspspTyp?: string;
  origRequestSubmitDtm?: string;
  eeLspSpEntitleAmt?: string;
  eePayableAmt?: string;
  erLspSpPaidAmt?: string;
  erAcctOffsetAmt?: string;
  othLspSpOffsetAmt?: string;
  othLspSpOffsetDesc?: string;
  eePayAmt?: string;
  eePayeeNm?: string;
  eePayMthd?: PaymentMethodE;
  eeChqNo?: string;
  eeChqIssueDate?: string;
  eeBnkCd?: string;
  eeBnkNm?: string;
  eeBranchCd?: string;
  eeBnkAcctNo?: string;
  eePayClearDate?: string;
  eePayRejectReason?: string;
  erPayAmt?: string;
  erPayeeNm?: string;
  erPayMthd?: string;
  erChqNo?: string;
  erChqIssueDate?: string;
  erBnkCd?: string;
  erBnkNm?: string;
  erBranchCd?: string;
  erBnkAcctNo?: string;
  erPayClearDate?: string;
  erPayRejectReason?: string;
  orsoSchemeOffsetAmt?: string;
  orsoSchemeNm?: string;
  ceeLspspEmployStartDate?: string;
  ceeLdoe?: string;
  ceeLdoeRecordDate?: string;
  rejectDate?: string;
  mbTrfOptNoticeDate?: string;
  termLastFuDate?: string;
  nextFuDayCnt?: string;
  wfRefNo?: string;
  recCheckSum1?: string;
  recCheckSum2?: string;
  df2?: string;
  df3?: string;
  df4?: string;
  df5?: string;
  df6?: string;
  df7?: string;
  df8?: string;
  df9?: string;
  df10?: string;
  df1?: string;
}

export enum TerminationStatusE {
  RECEIVE = 'RECEIVE',
  PENDING = 'PENDING',
  CANCEL = 'CANCEL',
  EE_CONFIRM = 'Pending EE confirm',
  EE_CONFIRMED = 'EE_CONFIRMED',
  EE_NOT_AGREE = 'EE_NOT_AGREE',
  ER_CONFIRM = 'Pending ER confirm',
  ER_CONFIRMED = 'ER_CONFIRMED',
  ER_NOT_AGREE = 'ER_NOT_AGREE',
  INVEST_ORDER_PEND = 'INVEST_ORDER_PEND',
  NIGHT_BATCH_PEND = 'NIGHT_BATCH_PEND',
  COMPLETE = 'COMPLETE',
  REJECT = 'REJECT',
  TR_REJECT = 'TR_REJECT',
  REVIEW_IN_PROGRESS = 'REVIEW_IN_PROGRESS',
  FOLLOW_UP_IN_PRGRESS = 'FOLLOW_UP_IN_PRGRESS',
  PEND_PAYMT_SETTLE = 'PEND_PAYMT_SETTLE',
}

export const terminationStatusMapObj = {
  [TerminationStatusE.RECEIVE]: 'RECEIVE',
  [TerminationStatusE.PENDING]: 'PENDING',
  [TerminationStatusE.CANCEL]: 'CANCEL',
  [TerminationStatusE.EE_CONFIRM]: 'Pending EE confirm',
  [TerminationStatusE.EE_CONFIRMED]: 'EE_CONFIRMED',
  [TerminationStatusE.EE_NOT_AGREE]: 'EE_NOT_AGREE',
  [TerminationStatusE.ER_CONFIRM]: 'Pending ER confirm',
  [TerminationStatusE.ER_CONFIRMED]: 'ER_CONFIRMED',
  [TerminationStatusE.ER_NOT_AGREE]: 'ER_NOT_AGREE',
  [TerminationStatusE.INVEST_ORDER_PEND]: 'INVEST_ORDER_PEND',
  [TerminationStatusE.NIGHT_BATCH_PEND]: 'NIGHT_BATCH_PEND',
  [TerminationStatusE.COMPLETE]: 'COMPLETE',
  [TerminationStatusE.REJECT]: 'REJECT',
  [TerminationStatusE.TR_REJECT]: 'TR_REJECT',
  [TerminationStatusE.REVIEW_IN_PROGRESS]: 'REVIEW_IN_PROGRESS',
  [TerminationStatusE.FOLLOW_UP_IN_PRGRESS]: 'In progress, Unclean and need follow up action',
  [TerminationStatusE.PEND_PAYMT_SETTLE]: 'Awaiting the payment to be presented',
};

export enum TerminationReasonE {
  RESIGN = 'RESIGN',
  RETIRE = 'RETIRE',
  EARLY_RETIRE = 'EARLY_RETIRE',
  DEATH = 'DEATH',
  ILL_HEALTH = 'ILL_HEALTH',
  TOTAL_INCAP = 'TOTAL_INCAP',
  REDUNDANCY = 'REDUNDANCY',
  LAID_OFF = 'LAID_OFF',
  DISMIS = 'DISMIS',
  SUM_DISMISS = 'SUM_DISMISS',
  AUTO_TERM_60D = 'AUTO_TERM_60D',
  WRONG_ENROL = 'WRONG_ENROL',
  INTRA_GROUP_TRF = 'INTRA_GROUP_TRF',
  CONTRACT_END = 'CONTRACT_END',
  SCHEME_TRF = 'SCHEME_TRF',
  COMP_WIND_UP = 'COMP_WIND_UP',
  NO_EMPLOYEE = 'NO_EMPLOYEE',
}

export const terminationReasonMapObj = {
  [TerminationReasonE.RESIGN]: 'Resignation',
  [TerminationReasonE.RETIRE]: 'Retirement',
  [TerminationReasonE.EARLY_RETIRE]: 'Early Retirement',
  [TerminationReasonE.DEATH]: 'Death',
  [TerminationReasonE.ILL_HEALTH]: 'On ground of ill health',
  [TerminationReasonE.TOTAL_INCAP]: 'Total Incapacity',
  [TerminationReasonE.REDUNDANCY]: 'Redundancy',
  [TerminationReasonE.LAID_OFF]: 'Laid Off',
  [TerminationReasonE.DISMIS]: 'Dismissal',
  [TerminationReasonE.SUM_DISMISS]: 'Summary Dismissal',
  [TerminationReasonE.AUTO_TERM_60D]: 'Auto Termination within 60 days',
  [TerminationReasonE.WRONG_ENROL]: 'Wrong Enrolment',
  [TerminationReasonE.INTRA_GROUP_TRF]: 'Intra-group transfer',
  [TerminationReasonE.CONTRACT_END]: 'Contract Ended',
  [TerminationReasonE.SCHEME_TRF]: 'Scheme Transfer',
  [TerminationReasonE.COMP_WIND_UP]: 'Company Wind Up as reason of ER Termination',
  [TerminationReasonE.NO_EMPLOYEE]: 'No Eligible Employee working for this company',
};

export enum PaymentMethodE {
  CQT = 'CQT',
  BKT = 'BKT',
  FPS = 'FPS',
  TEL = 'TEL',
}

export const paymentMethodMapObj = {
  [PaymentMethodE.CQT]: 'Cheque Payment',
  [PaymentMethodE.BKT]: 'Bank Transfer',
  [PaymentMethodE.FPS]: 'Fast Payment Service',
  [PaymentMethodE.TEL]: 'Telegraphic Transfer',
};

export enum WorkflowTypeE {
  EMPLOYER_ENROLEMENT = 'Employer Enrolment',
  MEMBER_ENROLEMENT = 'Member Enrolment',
  MEMBER_INVESTMENT_MANDATE = 'Member Investment Mandate',
  COUNTRIBUTION = 'Contribution',
  FUND_SWITCHING = 'Fund switching',
  TERMINATION = 'Termination',
  TRANSFER = 'Transfer',
  WITHDRAWAL = 'Withdrawal',
}

export const workflowTypeOptions: CdsOption[] = [
  {
    label: 'Employer Enrolment',
    value: WorkflowTypeE.EMPLOYER_ENROLEMENT,
  },
  {
    label: 'Member Enrolment',
    value: WorkflowTypeE.MEMBER_ENROLEMENT,
  },
  {
    label: 'Member Investment Mandate',
    value: WorkflowTypeE.MEMBER_INVESTMENT_MANDATE,
  },
  {
    label: 'Contribution',
    value: WorkflowTypeE.COUNTRIBUTION,
  },
  {
    label: 'Fund Switching',
    value: WorkflowTypeE.FUND_SWITCHING,
  },
  {
    label: 'Termination',
    value: WorkflowTypeE.TERMINATION,
  },
  {
    label: 'Transfer',
    value: WorkflowTypeE.TRANSFER,
  },
  {
    label: 'Withdrawal',
    value: WorkflowTypeE.WITHDRAWAL,
  },
];

export enum WorkflowStatusCodeE {
  PENDING = 'PENDING',
  CANCEL = 'CANCEL',
  COMPLETE = 'COMPLETE',
  FOLLOW_UP = 'FOLLOW_UP',
  REJECT = 'REJECT',
  OPEN = 'OPEN',
}

export const workflowStatusCodeOptions: CdsOption[] = [
  {
    label: 'Pending',
    value: WorkflowStatusCodeE.PENDING,
  },
  {
    label: 'Cancel',
    value: WorkflowStatusCodeE.CANCEL,
  },
  {
    label: 'Complete',
    value: WorkflowStatusCodeE.COMPLETE,
  },
  {
    label: 'Follow-up',
    value: WorkflowStatusCodeE.FOLLOW_UP,
  },
  {
    label: 'Reject',
    value: WorkflowStatusCodeE.REJECT,
  },
  {
    label: 'Open',
    value: WorkflowStatusCodeE.OPEN,
  },
];

export const workflowStatusCodeMapObj = {
  [WorkflowStatusCodeE.PENDING]: {
    text: 'Pending',
    color: '#F49600',
  },
  [WorkflowStatusCodeE.CANCEL]: {
    text: 'Cancel',
    color: '#EC6453',
  },
  [WorkflowStatusCodeE.COMPLETE]: {
    text: 'Complete',
    color: '#00A758',
  },
  [WorkflowStatusCodeE.FOLLOW_UP]: {
    text: 'Follow-up',
    color: '#28D7CB',
  },
  [WorkflowStatusCodeE.REJECT]: {
    text: 'Reject',
    color: '#8E90A2',
  },
  [WorkflowStatusCodeE.OPEN]: {
    text: 'Open',
    color: '#6F5693',
  },
};

export interface EmpfWorkflow {
  md5?: string;
  workflowReferenceNo?: string;
  memberAccountCode?: string;
  memberName?: string;
  memberEmpfId?: string;
  statusCode?: WorkflowStatusCodeE;
  type?: WorkflowTypeE;
  createDate?: string;
  followUpRejectDate?: string;
  followUpRejectReason?: string;
}

export interface EmpfWorkflowRequest extends IPageableRequest {
  id?: string;
  payrollGroupId?: string;
  workflowReferenceNo?: string;
  paymentSubmissionReferenceNo?: string;
  memberAccountCode?: string;
  statusCode?: WorkflowStatusCodeE;
  type?: WorkflowTypeE;
  createDate?: string;
}

export interface PolicyDetails {
  id?: string;
  schemeJoinedDate?: string;
  terminationCompletionDate?: string;
  previousCAMemberCode?: string;
  defaultPA?: string;
  abOffer?: string;
  schemeMergeSignal?: string;
  commissionPoolNumber?: string;
  originalSchemeJoinedDate?: string;
  enrolmentReferenceNo?: string;
  workflowReferenceNo?: string;

  enrolmentSubmissionChannel?: string;
  enrolmentCompletionDate?: string;
  applicationReceivedDate?: string;
  declarationCompletionDate?: string;

  sourceOfFundWealth?: string;
  otherSourceOfFundWealth?: string;
  originOfInitialSourceOfWealth?: string;
  originOfOnGoingSourceOfFundsWealth?: string;
}

export const RegistrationTypeMap = new Map([
  ['BR', 'Business Registration'],
  ['EDU', 'Education Institution'],
  ['IRD', 'Inland Revenue Department (Charitable Organisations)'],
  ['TU', 'Registry of Trade Unions'],
  ['SO', 'Societies Office of Hong Kong Police'],
  ['OTHERS', 'Others'],
  ['PASSPORT', 'Passport'],
  ['HKID', 'HKID'],
]);

export const TransactionStatusCds: CdsOption[] = [
  {
    value: 'IN_PROGRESS',
    label: 'In Progress',
  },
  {
    value: 'COMPLETE',
    label: 'Transaction of old - new trustees had been completed after unit allocation, whole transfer had been completed',
  },
  {
    value: 'INVEST_ORDER_PEND',
    label: 'Awaiting for order completion',
  },
  {
    value: 'NIGHT_BATCH_PEND',
    label: 'Pending for nightly batch cycle to generate fund order',
  },
  {
    value: 'CANCEL',
    label: 'Member cancel request before cutoff',
  },
  {
    value: 'REJECT',
    label: 'The transfer request is rejected by eMPF or Old Trustee',
  },
  {
    value: 'ONHOLD',
    label: 'Fund price is not ready, or transfer in instruction that waiting transfer out instruction is completed',
  },
];

export const TransactionActivityTypes: CdsOption[] = [
  {
    value: 'AUTO_PRESERVE_IN',
    label: 'Auto Preservation In',
  },
  {
    value: 'AUTO_PRESERVE_OUT',
    label: 'Auto Preservation Out',
  },
  {
    value: 'CONTR',
    label: 'Contribution',
  },
  {
    value: 'FEE_CHRG',
    label: 'Fee and Charge',
  },
  {
    value: 'FORF_ALLOC_IN',
    label: 'Forfeiture Allocation In',
  },
  {
    value: 'FORF_ALLOC_OUT',
    label: 'Forfeiture Allocation Out',
  },
  {
    value: 'FORFEITURE_OFFSET',
    label: 'Forfeiture Offset',
  },
  {
    value: 'TRF_IN',
    label: 'Transfer_IN',
  },
  {
    value: 'TERM',
    label: 'Termination',
  },
  {
    value: 'LSPSP_OFFSET',
    label: 'Refund Accrued Benefit (LSPSP)',
  },
  {
    value: 'OTH_INC',
    label: 'Other Income',
  },
  {
    value: 'TRF_OUT',
    label: 'Transfer_OUT',
  },
  {
    value: 'REBATE',
    label: 'Rebate',
  },
  {
    value: 'REFUND',
    label: 'Refund Overpaid Contribution',
  },
  {
    value: 'REINVEST_RESIDUAL',
    label: 'Reinvestment of residual balance',
  },
  {
    value: 'RESERVE_IN',
    label: 'Reserve In',
  },
  {
    value: 'RESERVE_OUT',
    label: 'Reserve Out',
  },
  {
    value: 'SWITCH_IN',
    label: 'Fund Switching In',
  },
  {
    value: 'SWITCH_OUT',
    label: 'Fund Switching Out',
  },
  {
    value: 'UCB',
    label: 'Unclaimed Benefit',
  },
  {
    value: 'UNIT_CORRECTION_IN',
    label: 'Unit correction IN',
  },
  {
    value: 'UNIT_CORRECTION_OUT',
    label: 'Unit correction Out',
  },
  {
    value: 'UNVEST_BAL_IN',
    label: 'Unvested Balance In',
  },
  {
    value: 'UNVEST_BAL_OUT',
    label: 'Unvested Balance Out',
  },
  {
    value: 'WDR',
    label: 'Withdrawal',
  },
];

export const TransactionSubActivityTypes: CdsOption[] = [
  {
    value: 'AP_IN',
    label: 'Auto Preservation In',
  },
  {
    value: 'AP_IN_REDEEM',
    label: 'Auto Preservation In with change of fund class',
  },
  {
    value: 'AP_OUT',
    label: 'Auto Preservation Out',
  },
  {
    value: 'AP_OUT_REDEEM',
    label: 'Auto Preservation Out with change of fund class',
  },
  {
    value: 'CONTR_LUMPSUM',
    label: 'Lumpsum Contribution',
  },
  {
    value: 'CONTR_REGULAR',
    label: 'Regular Contribution',
  },
  {
    value: 'CONTR_SUBSEQUENT',
    label: 'Subsequent Contribution',
  },
  {
    value: 'CONTR_SURCHG',
    label: 'Surcharge Contribution',
  },
  {
    value: 'FEE_CHRG',
    label: 'Fee and Charge',
  },
  {
    value: 'FORF_ALLOC_IN',
    label: 'Forfeiture Allocation In',
  },
  {
    value: 'FORF_ALLOC_OUT',
    label: 'Forfeiture Allocation Out',
  },
  {
    value: 'FORFEITURE_OFFSET',
    label: 'Forfeiture Offset',
  },
  {
    value: 'GIC',
    label: 'Government Injection Contribution',
  },
  {
    value: 'IN_RESTRUCTURE',
    label: 'Transfer In  Scheme Restructure',
  },
  {
    value: 'LSP',
    label: 'LSP Offset',
  },
  {
    value: 'LSPSP_OFFSET',
    label: 'Refund Accrued Benefit (LSPSP)',
  },
  {
    value: 'NOT',
    label: 'Notice of Termination',
  },
  {
    value: 'OTH_INC_CRYS',
    label: 'Guarantee Crystallization',
  },
  {
    value: 'OTH_INC_DISTR',
    label: 'Dividend Income Distributions',
  },
  {
    value: 'OTH_INC_GUAR_TOPUP',
    label: 'Injection of guarantee topup',
  },
  {
    value: 'OUT_RESTRUCTURE',
    label: 'Transfer Out  Scheme Restructure',
  },
  {
    value: 'REBATE',
    label: 'Rebate',
  },
  {
    value: 'REFUND_FORFEITURE',
    label: 'Refund Forfeiture',
  },
  {
    value: 'REFUND_OVERPAID',
    label: 'Refund Overpaid',
  },
  {
    value: 'REFUND_SURCHG',
    label: 'Refund Overpaid Surcharge',
  },
  {
    value: 'REFUND_SUSPENSE',
    label: 'Refund Suspense',
  },
  {
    value: 'REINVEST_RESIDUAL',
    label: 'Reinvest Residual balance',
  },
  {
    value: 'RESERVE_IN',
    label: 'Reserve In',
  },
  {
    value: 'RESERVE_OFFSET',
    label: 'Reserve Offset',
  },
  {
    value: 'SP',
    label: 'SP Offset',
  },
  {
    value: 'SW_IN_DIS',
    label: 'DIS Derisking In',
  },
  {
    value: 'SW_IN_FUND_SWITCH',
    label: 'Fund Switching In',
  },
  {
    value: 'SW_IN_REBALANCE',
    label: 'Rebalance IN',
  },
  {
    value: 'SW_OUT_DIS',
    label: 'DIS Derisking Out',
  },
  {
    value: 'SW_OUT_FUND_SWITCH',
    label: 'Fund Switching Out',
  },
  {
    value: 'SW_OUT_REBALANCE',
    label: 'Rebalance OUT',
  },
  {
    value: 'TRF_IN_INTRAGROUP',
    label: 'Intragroup transfer',
  },
  {
    value: 'TRF_IN_MMB',
    label: 'Transfer In  MMB',
  },
  {
    value: 'TRF_IN_MMB_INTRA_GRP',
    label: 'Transfer In MMB Intra Group',
  },
  {
    value: 'TRF_IN_MMB_PE',
    label: 'Transfer In MMB Scheme',
  },
  {
    value: 'TRF_IN_PC',
    label: 'Transfer In PC Form',
  },
  {
    value: 'TRF_IN_PE',
    label: 'Transfer In PE Form',
  },
  {
    value: 'TRF_IN_PM',
    label: 'Transfer In PM Form',
  },
  {
    value: 'TRF_IN_PP',
    label: 'Transfer In PP Form',
  },
  {
    value: 'TRF_IN_PT',
    label: 'Transfer In PT Form',
  },
  {
    value: 'TRF_OUT_INTRAGROUP',
    label: 'Intragroup transfer',
  },
  {
    value: 'TRF_OUT_PC',
    label: 'Transfer Out PCForm',
  },
  {
    value: 'TRF_OUT_PE',
    label: 'Transfer Out PE Form',
  },
  {
    value: 'TRF_OUT_PM',
    label: 'Transfer Out PM Form',
  },
  {
    value: 'TRF_OUT_PP',
    label: 'Transfer Out PP Form',
  },
  {
    value: 'TRF_OUT_PT',
    label: 'Transfer Out PT Form',
  },
  {
    value: 'UCB_REINVEST',
    label: 'Unpresented Cheque Reinvest',
  },
  {
    value: 'UNIT_ADJ_IN',
    label: 'Unit correction IN',
  },
  {
    value: 'UNIT_ADJ_OUT',
    label: 'Unit correction Out',
  },
  {
    value: 'UNIT_TRF',
    label: 'Unit Transfer',
  },
  {
    value: 'UNVEST_BAL_IN',
    label: 'Unvested Balance In',
  },
  {
    value: 'UNVEST_BAL_OUT',
    label: 'Unvested Balance Out',
  },
  {
    value: 'WDR_AUTO_TERM',
    label: 'Auto Termination within 60 days (do not show this to ER as this is logic derived and cannot be selected by ER, see UFLSP008.)  Auto Preservation',
  },
  {
    value: 'WDR_DEATH',
    label: 'Death',
  },
  {
    value: 'WDR_EARLY_RETIRE',
    label: 'Early Retirement  Lump Sum',
  },
  {
    value: 'WDR_FULL',
    label: 'Full Withdrawal',
  },
  {
    value: 'WDR_ORO',
    label: 'ORO/TIB Withdrawal',
  },
  {
    value: 'WDR_PARTIAL',
    label: 'Partial Withdrawal',
  },
  {
    value: 'WDR_PERMNT_DEPART',
    label: 'Permanent Departure',
  },
  {
    value: 'WDR_RETIRE',
    label: 'Retirement  Lump Sum',
  },
  {
    value: 'WDR_SI_EARLY_RETIRE',
    label: 'Early Retirement  Standing Instruction',
  },
  {
    value: 'WDR_SI_RETIRE',
    label: 'Retirement  Standing Instruction',
  },
  {
    value: 'WDR_SMALL_BAL',
    label: 'Small Balance Claim',
  },
  {
    value: 'WDR_TERM_ILL',
    label: 'On ground of ill health',
  },
  {
    value: 'WDR_TOTAL_INCAP',
    label: 'Total Incapacity',
  },
  {
    value: 'SW_OUT_FUND_SWITCH',
    label: 'Fund Switching Out',
  },
  {
    value: 'SW_OUT_FUND_TO_FUND',
    label: 'Fund To Fund Switching Out',
  },
];

export interface InvestedFundInfo {
  fundCode?: string;
  fundName?: string;
  unitPrice?: number;
  noOfUnit?: number;
  fundBalance?: number;
  fundCurrency?: string;
  eeNewBUFlag?: string;
  fseeoreemc?: number;
  pseeoreevc?: number;
  fserorermc?: number;
  pserorervc?: number;
  show?: boolean;
}

export interface InvestedFundInformationResponse {
  asOfDate?: string;
  investedFundInfo?: InvestedFundInfo[];
}

export interface TransactionRecordRequest extends IPageableRequest {
  referenceNo?: string;
  groupReferenceNo?: string;
  statusCode?: string;
  activityType?: string;
  subActivityType?: string;
  effectiveDate?: string;
  createdDate?: string;
  id?: string;
}

export interface TransactionRecordResponse {
  referenceNo?: string;
  groupReferenceNo?: string;
  statusCode?: string;
  activityType?: string;
  subActivityType?: string;
  createDate?: string;
  effectiveDate?: string;
}

export interface FundBaseInfo {
  fundType?: string;
  fundUnit?: string;
  fundAmount?: string;
}

export interface FundInformationRecords {
  fundCode?: string;
  fundEnName?: string;
  fundZhName?: string;
  unitPrice?: string;
  fundBaseInfo?: FundBaseInfo[];
}

export interface TransactionsRefInfo {
  refNo?: string;
  createDate?: string;
  effectiveDate?: string;
  originalRequestedSubmissionDateAndTime?: string;
  activityType?: string;
  subActivityType?: string;
  subAccountType?: string;
  fundInformationRecords?: FundInformationRecords[];
}

export interface PaymentTransactionResponse {
  firstName?: string;
  lastName?: string;
  firstZhName?: string;
  lastZhName?: string;
  mbAccountCode?: string;
  list?: TransactionsRefInfo[];
}
