/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CDS_SIZE } from '@cds/ng-core/configuration';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { filter, finalize } from 'rxjs';
import { ResponseResult } from 'src/app/core/models/response/response-result';

import { CommissionHoldOnReportService, HoldOnReportItem } from 'src/app/core/services/commission/commission-hold-on-report.service';
import { numberValidator } from 'src/app/core/validators';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { aoaToSheet, FileType } from 'src/app/utils/xlsx';
import { UserAgreementPopupComponent } from '../user-agreement-popup.component';

import {
  ACCOUNT_NO_CONFIG,
  ACCOUNT_TYPE_CONFIG,
  ACTIVITY_CODE_CONFIG,
  DOWN_LOAD_MAX,
  EXCEL_COLUMNS,
  // FROZEN_CONFIG,
  HOLD_ON_REPOERTS_COLUMNS,
  I18N_KEY,
  MEMBER_NO_CONFIG,
  PAGE_SIZE,
  REASON_CONFIG,
} from './hold-on-report.config';

@Component({
  selector: 'app-hold-on-report',
  templateUrl: './hold-on-report.component.html',
  styleUrls: ['./hold-on-report.component.scss'],
})
export class HoldOnReportComponent implements OnInit {
  accountNoConfig = ACCOUNT_NO_CONFIG;
  memberNoConfig = MEMBER_NO_CONFIG;
  accountTypeConfig = ACCOUNT_TYPE_CONFIG;
  activityCodeConfig = ACTIVITY_CODE_CONFIG;
  reasonConfig = REASON_CONFIG;
  // frozenConfig = FROZEN_CONFIG;
  isDownLoading = false;
  isOverMax = true;

  formGroup = new FormGroup({
    employerAccountNo: new FormControl('', {
      validators: [numberValidator({ error: I18N_KEY.INVALID_ACCONTNO })],
      initialValueIsDefault: true,
    }),
    memberAccountNo: new FormControl('', {
      validators: [numberValidator({ error: I18N_KEY.INVALID_MEMBERNO })],
      initialValueIsDefault: true,
    }),
    accountType: new FormControl('', { initialValueIsDefault: true }),
    subActivityCd: new FormControl('', { initialValueIsDefault: true }),
    holdOnReason: new FormControl('', { initialValueIsDefault: true }),
    frozenPoolExtraction: new FormControl(false, {
      initialValueIsDefault: true,
    }),
  });

  previousSearchParams: any;
  pageConfig: PageConfig = {};
  loading = false;
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = HOLD_ON_REPOERTS_COLUMNS;

  get accountNo() {
    return this.formGroup.get('employerAccountNo') as FormControl;
  }

  get memberNo() {
    return this.formGroup.get('memberAccountNo') as FormControl;
  }

  get accountType() {
    return this.formGroup.get('accountType') as FormControl;
  }

  get subActivityCd() {
    return this.formGroup.get('subActivityCd') as FormControl;
  }

  get holdOnReason() {
    return this.formGroup.get('holdOnReason') as FormControl;
  }

  get frozenPoolExtraction() {
    return this.formGroup.get('frozenPoolExtraction') as FormControl;
  }

  get isSearchDisable() {
    let tag = true;
    const controls = this.formGroup.controls;
    const keys = Object.keys(controls);
    keys.forEach((key: string) => {
      const defaultValue = (controls[key] as FormControl).defaultValue;
      const controlValue = (controls[key] as FormControl).value;
      const value = key === 'frozenPoolExtraction' ? controlValue : controlValue.trim();
      if (defaultValue !== value) {
        tag = false;
      }
    });
    return tag || this.formGroup.invalid || this.loading;
  }

  get getTotalCount() {
    return this.pageConfig.totalCount || 0;
  }

  get downloadDisabled() {
    return !this.previousSearchParams || this.isDownLoading || !this.getTotalCount;
  }

  constructor(private cdsPopup: CdsPopupService, private commissionHoldOnReportService: CommissionHoldOnReportService) {}

  ngOnInit() {
    this.search();
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.getdata();
    }
  }

  search() {
    this.previousSearchParams = null;
    this.pageConfig = {
      pageSize: PAGE_SIZE,
      current: 1,
    };
    this.getdata();
  }

  reset() {
    this.formGroup.reset();
    this.search();
  }

  getdata() {
    this.loading = true;
    const params = this.createParams();
    this.commissionHoldOnReportService
      .getData(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(res => {
        if (res.result === ResponseResult.SUCCESS) {
          this.previousSearchParams = params;
          const data = res.data;
          const totalElements = data.totalElements || 0;
          this.isOverMax = totalElements > DOWN_LOAD_MAX;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.totalElements,
          };
          this.dataDisplayed = this.createDisplayData(data.content || []);
        } else {
          this.openAlertPop(res.message);
        }
      });
  }

  createDisplayData(data: any[]) {
    return data.map((item: HoldOnReportItem) => {
      const accountType = ACCOUNT_TYPE_CONFIG.options.find(opt => opt.value === item.accountType);
      item.accountType = accountType ? accountType.label : item.accountType;
      const holdOnReason = REASON_CONFIG.options.find(opt => opt.value === item.holdOnReason);
      item.holdOnReason = holdOnReason ? holdOnReason.label : item.holdOnReason;
      item.freezeDate = item.freezeDate ? moment(new Date(item.freezeDate)).format('DD/MM/YYYY') : '';
      item.efctvDate = moment(item.efctvDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
      return item;
    });
  }

  createParams() {
    const params: any = {
      page: (this.pageConfig.current || 1) - 1,
      size: PAGE_SIZE,
    };
    if (this.previousSearchParams) {
      return { ...this.previousSearchParams, ...params };
    }
    const formValue = this.getFormValue();
    return { ...params, ...formValue };
  }

  getFormValue() {
    const value: any = {};
    const tempValue = this.formGroup.value;
    const keys: string[] = Object.keys(tempValue);
    keys.forEach(key => {
      const controlValue = key === 'frozenPoolExtraction' ? tempValue[key] : tempValue[key].trim();
      if (controlValue !== '' && controlValue !== 'all') {
        value[key] = controlValue;
      }
    });
    return value;
  }

  openAlertPop(key: string) {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        continue: I18N_KEY.COMMON_GOT,
        type: 'alert',
        message: key,
      },
      size: CDS_SIZE['MEDIUM'],
    });
    popupRef.afterClosed().subscribe(() => {});
  }

  download() {
    if (this.isOverMax) {
      this.openAlertPop(I18N_KEY.DOWN_LOAD_MAX_FAIL);
      return;
    }

    if (this.downloadDisabled) {
      return;
    }

    this.isDownLoading = true;
    this.commissionHoldOnReportService
      .getData({
        ...this.previousSearchParams,
        size: this.getTotalCount,
      })
      .pipe(
        finalize(() => {
          this.isDownLoading = false;
        }),
        filter(res => res.result === ResponseResult.SUCCESS)
      )
      .subscribe(res => {
        const data = this.createDisplayData(res.data.content || []);
        const excelData = this.createExcelData(data);
        aoaToSheet(
          {
            fileName: `Hold on Compensation Report ${moment().format('YYYYMMDD_HHmmss')}`,
            fileType: FileType.XLSX,
          },
          excelData
        );
      });
  }

  createExcelData(data: any[]) {
    const excelData: any[] = [];
    excelData.push(EXCEL_COLUMNS);
    data.forEach(item => {
      const temp = [];
      temp.push(item.referenceNo);
      temp.push(item.accountType);
      temp.push(item.subActivityCd);
      temp.push(item.efctvDate);
      temp.push(item.employerAccountNo);
      temp.push(item.memberAccountNo);
      temp.push(item.totalPremium);
      temp.push(item.commissionPoolId);
      temp.push(item.freezeDate);
      temp.push(item.processId);
      temp.push(item.holdOnReason);
      excelData.push(temp);
    });
    return excelData;
  }
}
