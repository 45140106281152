<div class="row auto-rollover-container">
  <div>
    <div class="col-xs-12 l-pt-7">
      <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
    </div>
    <div class="row no-padding search-container" [formGroup]="form" *ngIf="formReady">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield
          formControlName="empfMemberAccountNumber"
          label="eMPF Member Account Number"
          placeholder="Input eMPF Member Account Number"></cds-textfield>

        <div
          *ngIf="empfMemberAccountNumberControl?.invalid && (empfMemberAccountNumberControl?.dirty || empfMemberAccountNumberControl?.touched)"
          class="l-pt-2">
          <cds-assistive-text
            *ngIf="empfMemberAccountNumberControl?.errors?.['idInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ empfMemberAccountNumberControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>

      <div *ngIf="showDatePicker" class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="createStartDate"
            placeholder="DD/MM/YYYY"
            label="Create Date From"
            [max]="getMaxFromDate | fn : createEndDateValue || nowTimeInstr"
            [cdsDatepicker]="startPicker">
          </cds-textfield>
          <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="createStartDateControl?.invalid && (createStartDateControl?.dirty || createStartDateControl?.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="createStartDateControl?.errors?.['dateInvalid'] || createStartDateControl?.errors?.['startLaterThanEnd']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ 'create.dateError' | lang }}</cds-assistive-text
            >
          </div>
        </div>
        <div class="connector">-</div>
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="createEndDate"
            placeholder="DD/MM/YYYY"
            label="To"
            [min]="getMinToDate | fn : createStartDateValue"
            [max]="maxTime"
            [cdsDatepicker]="endPicker">
          </cds-textfield>
          <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="createEndDateControl?.invalid && (createEndDateControl?.dirty || createEndDateControl?.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="createEndDateControl?.errors?.['dateInvalid'] || createEndDateControl?.errors?.['startLaterThanEnd']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ 'create.dateError' | lang }}</cds-assistive-text
            >
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="hkid" label="Hong Kong ID No." placeholder="Input Hong Kong ID No."></cds-textfield>

        <div *ngIf="hkidControl?.invalid && (hkidControl?.dirty || hkidControl?.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="hkidControl?.errors?.['idInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ hkidControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="passport" label="Passport No." placeholder="Input Passport No."></cds-textfield>

        <div *ngIf="passportControl?.invalid && (passportControl?.dirty || passportControl?.touched)" class="l-pt-2">
          <cds-assistive-text
            *ngIf="passportControl?.errors?.['idInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ passportControl?.errors?.['idInvalid'] | lang }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-dropdown formControlName="status" [config]="statusConfig"></cds-dropdown>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 l-pb-4 bottom-xs search-button-container">
        <cds-button
          class="l-mr-4 botton"
          [config]="searchButtonConfig"
          [disabled]="searchButtonDisabled"
          label="{{ 'list.search' | lang }}"
          (click)="search()"></cds-button>
        <cds-button
          class="botton"
          [config]="resetButtonConfig"
          [disabled]="resetButtonDisabled"
          label="{{ 'list.reset' | lang }}"
          (click)="reset()"></cds-button>
      </div>
    </div>
  </div>

  <!-- page data -->
  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7 l-mb-4">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ numberOfElements | number }} records</div>
    </div>
  </div>

  <div class="col-xs-12 col-md-12">
    <div class="min-height">
      <app-data-table
        [frontPagination]="false"
        [isLoading]="isLoading"
        [fixed]="false"
        [overlay]="true"
        [pageConfig]="pageConfig"
        [dataSource]="dataSource"
        [columnsConfig]="columnsConfig"
        (reloadTable)="reloadTable($event)">
        <!-- header templates -->
        <ng-template appDtHeaderCellDef="select">
          <div>
            <cds-checkbox
              [checked]="selectAll"
              [indeterminate]="isIndeterminate"
              [disabled]="selectStatus === undefined"
              (change)="handleChange($event)"></cds-checkbox>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="erAccountNo" let-element>
          <div class="min-width">{{ element.title }}</div>
        </ng-template>
        <ng-template appDtHeaderCellDef="empfMemberAccountNumber" let-element>
          <div class="min-width">{{ element.title }}</div>
        </ng-template>
        <ng-template appDtHeaderCellDef="firstName" let-element>
          <div class="min-width">
            <div class="cds-body1-demibold first-title">English Name</div>
            <div class="cds-detail2 second-title">First Name</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="lastName" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Last Name</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="chineseFirstName" let-element>
          <div class="min-width">
            <div class="cds-body1-demibold first-title">Chinese Name</div>
            <div class="cds-detail2 second-title">First Name</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="chineseLastName" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Last Name</div>
          </div>
        </ng-template>
        <!-- <ng-template appDtHeaderCellDef="addressLine1Room" let-element>
          <div class="min-width">
            <div class="cds-body1-demibold first-title">Adress</div>
            <div class="cds-detail2 second-title">Room</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="addressLine2Floor" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Floor</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="addressLine3Block" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Block</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="addressLine4Building" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Building</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="addressLine5Street" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Street</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="addressLine6District" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">District</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="addressLine7City" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">City</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="tinCountry1FullName" let-element>
          <div class="min-width-lg">
            <div class="cds-body1-demibold first-title">Tax 1</div>
            <div class="cds-detail2 second-title">Country Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="tinNo1" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="noTinReason1" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Reason without tax identification number</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="furtherTinReason1" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Further reason tax identification number</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="tinCountry2FullName" let-element>
          <div class="min-width-lg">
            <div class="cds-body1-demibold first-title">Tax 2</div>
            <div class="cds-detail2 second-title">Country Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="tinNo2" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="noTinReason2" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Reason without tax identification number</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="furtherTinReason2" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Further reason tax identification number</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="tinCountry3FullName" let-element>
          <div class="min-width-lg">
            <div class="cds-body1-demibold first-title">Tax 3</div>
            <div class="cds-detail2 second-title">Country Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="tinNo3" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="noTinReason3" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Reason without tax identification number</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="furtherTinReason3" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Further reason tax identification number</div>
          </div>
        </ng-template>

        <ng-template appDtHeaderCellDef="tinCountry4FullName" let-element>
          <div class="min-width-lg">
            <div class="cds-body1-demibold first-title">Tax 4</div>
            <div class="cds-detail2 second-title">Country Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="tinNo4" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="noTinReason4" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Reason without tax identification number</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="furtherTinReason4" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Further reason tax identification number</div>
          </div>
        </ng-template>

        <ng-template appDtHeaderCellDef="tinCountry5FullName" let-element>
          <div class="min-width-lg">
            <div class="cds-body1-demibold first-title">Tax 5</div>
            <div class="cds-detail2 second-title">Country Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="tinNo5" let-element>
          <div class="min-width">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Identification No.</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="noTinReason5" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Reason without tax identification number</div>
          </div>
        </ng-template>
        <ng-template appDtHeaderCellDef="furtherTinReason5" let-element>
          <div class="min-width-lg">
            <div class="none-title"></div>
            <div class="cds-detail2 second-title">Further reason tax identification number</div>
          </div>
        </ng-template> -->

        <!-- content templates -->
        <ng-template appDtBodyCellDef="createdAt" let-element>
          {{ element['createdAt'] | date : 'dd/MM/yyyy' }}
        </ng-template>

        <ng-template appDtBodyCellDef="erAccountNo" let-element>
          <div class="code-column">
            <div>{{ element.erAccountNo }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="empfMemberAccountNumber" let-element>
          <div class="code-column">
            <div>{{ element.empfMemberAccountNumber }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="select" let-element let-num="index">
          <cds-checkbox (change)="trigger(num, $event)" [checked]="element.checked" [disabled]="isDisabled | fn : selectStatus : element.status"></cds-checkbox>
        </ng-template>
        <ng-template appDtBodyCellDef="hkidNo" let-element
          >{{ element.hkidNo }}<span *ngIf="element.hkidCheckDigit">({{ element.hkidCheckDigit }})</span></ng-template
        >
        <!-- <ng-template appDtBodyCellDef="transactionId" let-element>
          <cds-checkbox [checked]="element['transactionId']" [disabled]="true"></cds-checkbox>
        </ng-template> -->
        <ng-template appDtBodyCellDef="bankruptcy" let-element>
          <cds-checkbox [checked]="element['bankruptcy'] === 'Y'" [disabled]="true"></cds-checkbox>
        </ng-template>
        <ng-template appDtBodyCellDef="transactionDate" let-element>
          {{ element['transactionDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <ng-template appDtBodyCellDef="dealingDate" let-element>
          {{ element['dealingDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <ng-template appDtBodyCellDef="dateOfBirth" let-element>
          {{ element['dateOfBirth'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <!--
        <ng-template appDtBodyCellDef="bankruptcyReceivedDate" let-element>
          {{ element['bankruptcyReceivedDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <ng-template appDtBodyCellDef="bankruptcyDate" let-element>
          {{ element['bankruptcyDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <ng-template appDtBodyCellDef="dischargeDate" let-element>
          {{ element['dischargeDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <ng-template appDtBodyCellDef="effectiveDate" let-element>
          {{ element['effectiveDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <ng-template appDtBodyCellDef="expiredDate" let-element>
          {{ element['expiredDate'] | date : 'dd/MM/yyyy' }}
        </ng-template> -->
        <ng-template appDtBodyCellDef="crsReceivedDate" let-element>
          {{ element['crsReceivedDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>
        <ng-template appDtBodyCellDef="accountOpenDate" let-element>
          {{ element['accountOpenDate'] | date : 'dd/MM/yyyy' }}
        </ng-template>

        <ng-template appDtBodyCellDef="primaryMobileNumber" let-element>
          <div class="l-mb-1">
            <span *ngIf="element.primaryMobileNumberCountryCode">(+{{ element.primaryMobileNumberCountryCode }})</span>{{ element.primaryMobileNumber }}
          </div>
          <div *ngIf="element.primaryMobileNumber" class="invalid-check">
            <cds-checkbox [checked]="element['primaryMobileNumberValid'] === 'Y'" [disabled]="true"></cds-checkbox>
            <div>Invalid</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="primaryContactNumber" let-element>
          <div class="l-mb-1">
            <span *ngIf="element.primaryContactNumberCountryCode">(+{{ element.primaryContactNumberCountryCode }})</span>{{ element.primaryContactNumber }}
          </div>
          <div *ngIf="element.primaryContactNumber" class="invalid-check">
            <cds-checkbox [checked]="element['primaryContactNumberValid'] === 'Y'" [disabled]="true"></cds-checkbox>
            <div>Invalid</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="secondaryMobileNumber" let-element>
          <div class="l-mb-1">
            <span *ngIf="element.secondaryMobileNumberCountryCode">(+{{ element.secondaryMobileNumberCountryCode }})</span>{{ element.secondaryMobileNumber }}
          </div>
          <div *ngIf="element.secondaryMobileNumber" class="invalid-check">
            <cds-checkbox [checked]="element['secondaryMobileNumberValid'] === 'Y'" [disabled]="true"></cds-checkbox>
            <div>Invalid</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="secondaryContactNumber" let-element>
          <div class="l-mb-1">
            <span *ngIf="element.secondaryContactNumberCountryCode">(+{{ element.secondaryContactNumberCountryCode }})</span
            >{{ element.secondaryContactNumber }}
          </div>
          <div *ngIf="element.secondaryContactNumber" class="invalid-check">
            <cds-checkbox [checked]="element['secondaryContactNumberValid'] === 'Y'" [disabled]="true"></cds-checkbox>
            <div>Invalid</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="emailAddress" let-element>
          <div class="l-mb-1">
            {{ element.emailAddress }}
          </div>
          <div *ngIf="element.emailAddress" class="invalid-check">
            <cds-checkbox [checked]="element['emailAddressValid'] === 'Y'" [disabled]="true"></cds-checkbox>
            <div>Invalid</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="faxNumber" let-element>
          <div>
            <span *ngIf="element.faxNumberCountryCode">(+{{ element.faxNumberCountryCode }})</span>{{ element.faxNumber }}
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="addressType" let-element>
          <div>
            {{ addressTypeRender | fn : element.addressType }}
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="noTinReason1" let-element>
          <div class="tip-container">
            <div>{{ element.noTinReason1 }}</div>
            <app-tooltip-icon *ngIf="element.noTinReason1" [tooltipString]="element.noTinReason1Tip"> </app-tooltip-icon>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="noTinReason2" let-element>
          <div class="tip-container">
            <div>{{ element.noTinReason2 }}</div>
            <app-tooltip-icon *ngIf="element.noTinReason2" [tooltipString]="element.noTinReason2Tip"> </app-tooltip-icon>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="noTinReason3" let-element>
          <div class="tip-container">
            <div>{{ element.noTinReason3 }}</div>
            <app-tooltip-icon *ngIf="element.noTinReason3" [tooltipString]="element.noTinReason3Tip"> </app-tooltip-icon>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="noTinReason4" let-element>
          <div class="tip-container">
            <div>{{ element.noTinReason4 }}</div>
            <app-tooltip-icon *ngIf="element.noTinReason4" [tooltipString]="element.noTinReason4Tip"> </app-tooltip-icon>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="noTinReason5" let-element>
          <div class="tip-container">
            <div>{{ element.noTinReason5 }}</div>
            <app-tooltip-icon *ngIf="element.noTinReason5" [tooltipString]="element.noTinReason5Tip"> </app-tooltip-icon>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="companyName" let-element>
          <div class="company-name-column">
            <div>{{ element.companyName }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="chineseCompanyName" let-element>
          <div class="company-name-column">
            <div>{{ element.chineseCompanyName }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="firstName" let-element>
          <div class="name-column">
            <div>{{ element.firstName }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="lastName" let-element>
          <div class="name-column">
            <div>{{ element.lastName }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="chineseFirstName" let-element>
          <div class="name-column">
            <div>{{ element.chineseFirstName }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="chineseLastName" let-element>
          <div class="name-column">
            <div>{{ element.chineseLastName }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="passportNumber" let-element>
          <div class="name-column">
            <div>{{ element.passportNumber }}</div>
          </div>
        </ng-template>

        <ng-template appDtBodyCellDef="memberType" let-element>
          <div class="type-column">
            <div>{{ element.memberType }}</div>
          </div>
        </ng-template>
      </app-data-table>
    </div>
  </div>
</div>

<div class="operation-bar" *ngIf="selectStatus !== undefined && selectData.length > 0" [style.right]="scrollBarWidth" [style.left]="isMobile ? 0 : '80px'">
  <div class="cds-h4 l-mb-0" *ngIf="!isMobile">
    Please select record(s) to
    <span *ngIf="selectStatus === AutoRolloverStatsE.READY">hold</span><span *ngIf="selectStatus === AutoRolloverStatsE.HOLD">unhold</span>
  </div>
  <div class="button-area">
    <div class="cds-h5 l-mb-0 font-weight-6 l-mr-6">
      <span>{{ selectData.length }}</span> item(s) selected
    </div>
    <cds-button *ngIf="selectStatus === AutoRolloverStatsE.READY" (click)="changeStatus(true)" [disabled]="isLoading">Hold</cds-button>
    <cds-button *ngIf="selectStatus === AutoRolloverStatsE.HOLD" (click)="changeStatus(false)" [disabled]="isLoading">Unhold</cds-button>
  </div>
</div>
