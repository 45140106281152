import { PaymentReportType } from './../../benefit-payment/benefit-payment';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import {
  configPrimary,
  configSecondary,
  formatDate,
  formatDate3,
  formatDate4,
  maxDate,
  minDate,
  pageInit,
  PaymentHistorySearch,
  PaymentMethodE,
  PaymentReportHistory,
  PaymentReportLog,
  PaymentReportSearch,
  PaymentReportSubType,
  PaymentStatusE,
} from '../../benefit-payment/benefit-payment';
import { PaymentServiceSHKP } from '../../benefit-payment/paymentSHKP.service';
import { deepCopy } from 'src/app/utils/copy';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { jsonToSheet } from 'src/app/utils/xlsx';
import moment from 'moment';

@Component({
  selector: 'app-payment-report-shkp',
  templateUrl: './payment-report-shkp.component.html',
  styleUrls: ['./payment-report-shkp.component.scss'],
})
export class PaymentReportShkpComponent implements OnInit {
  _tabIndex = PaymentReportSubType.CHQ;
  _type = PaymentReportType.STATUS_REPORT;
  PaymentReportType = PaymentReportType;
  transferTypeConfig: CdsDropdownConfig = {
    label: 'Select Report Type',
    placeholder: '',
    options: [
      { label: 'Payment Record Report', value: PaymentReportType.RECORD_REPORT },
      { label: 'Payment Status Report', value: PaymentReportType.STATUS_REPORT },
    ],
  };

  _editPaymentCHQMap = new Map([
    [
      PaymentStatusE.NEW,
      {
        options: [
          { label: 'Printed', value: PaymentStatusE.PRINTED },
          { label: 'Unpresented', value: PaymentStatusE.UN_PRESENTED },
          { label: 'Presented', value: PaymentStatusE.PRESENTED },
          { label: 'Hold Payment', value: PaymentStatusE.HOLD_PAYMENT },
          { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
          { label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE },
          { label: 'Reissue Presented', value: PaymentStatusE.REISSUE_PRESENTED },
          { label: 'Returned from EE', value: PaymentStatusE.RETURNED_FROM_EE },
          { label: 'Returned from ER', value: PaymentStatusE.RETURNED_FROM_ER },
          { label: 'Expired', value: PaymentStatusE.EXPIRED },
        ],
      },
    ],
    [
      PaymentStatusE.PRINTED,
      {
        options: [
          { label: 'Unpresented', value: PaymentStatusE.UN_PRESENTED },
          { label: 'Presented', value: PaymentStatusE.PRESENTED },
          { label: 'Hold Payment', value: PaymentStatusE.HOLD_PAYMENT },
          { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
          { label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE },
          { label: 'Reissue Presented', value: PaymentStatusE.REISSUE_PRESENTED },
          { label: 'Returned from EE', value: PaymentStatusE.RETURNED_FROM_EE },
          { label: 'Returned from ER', value: PaymentStatusE.RETURNED_FROM_ER },
          { label: 'Expired', value: PaymentStatusE.EXPIRED },
        ],
      },
    ],
    [
      PaymentStatusE.UN_PRESENTED,
      {
        options: [
          { label: 'Presented', value: PaymentStatusE.PRESENTED },
          { label: 'Hold Payment', value: PaymentStatusE.HOLD_PAYMENT },
          { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
          { label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE },
          { label: 'Reissue Presented', value: PaymentStatusE.REISSUE_PRESENTED },
          { label: 'Returned from EE', value: PaymentStatusE.RETURNED_FROM_EE },
          { label: 'Returned from ER', value: PaymentStatusE.RETURNED_FROM_ER },
          { label: 'Expired', value: PaymentStatusE.EXPIRED },
        ],
      },
    ],
    [
      PaymentStatusE.PRESENTED,
      {
        options: [
          { label: 'Hold Payment', value: PaymentStatusE.HOLD_PAYMENT },
          { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
          { label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE },
          { label: 'Reissue Presented', value: PaymentStatusE.REISSUE_PRESENTED },
          { label: 'Returned from EE', value: PaymentStatusE.RETURNED_FROM_EE },
          { label: 'Returned from ER', value: PaymentStatusE.RETURNED_FROM_ER },
          { label: 'Expired', value: PaymentStatusE.EXPIRED },
        ],
      },
    ],
    [
      PaymentStatusE.HOLD_PAYMENT,
      {
        options: [
          { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
          { label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE },
        ],
      },
    ],
    [
      PaymentStatusE.STOP_PAYMENT,
      {
        options: [{ label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE }],
      },
    ],
    [
      PaymentStatusE.REISSUE,
      {
        options: [
          { label: 'Pending', value: PaymentStatusE.PENDING },
          { label: 'Ready to Reissue', value: PaymentStatusE.READY_TO_REISSUE },
          { label: 'Printed', value: PaymentStatusE.PRINTED },
          { label: 'Unpresented', value: PaymentStatusE.UN_PRESENTED },
          { label: 'Presented', value: PaymentStatusE.PRESENTED },
          { label: 'Hold Payment', value: PaymentStatusE.HOLD_PAYMENT },
          { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
          { label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE },
          { label: 'Reissue Presented', value: PaymentStatusE.REISSUE_PRESENTED },
          { label: 'Returned from EE', value: PaymentStatusE.RETURNED_FROM_EE },
          { label: 'Returned from ER', value: PaymentStatusE.RETURNED_FROM_ER },
          { label: 'Expired', value: PaymentStatusE.EXPIRED },
        ],
      },
    ],
    [
      PaymentStatusE.PENDING,
      {
        options: [
          { label: 'Ready to Reissue', value: PaymentStatusE.READY_TO_REISSUE },
          { label: 'Printed', value: PaymentStatusE.PRINTED },
          { label: 'Unpresented', value: PaymentStatusE.UN_PRESENTED },
          { label: 'Presented', value: PaymentStatusE.PRESENTED },
          { label: 'Hold Payment', value: PaymentStatusE.HOLD_PAYMENT },
          { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
          { label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE },
          { label: 'Reissue Presented', value: PaymentStatusE.REISSUE_PRESENTED },
          { label: 'Returned from EE', value: PaymentStatusE.RETURNED_FROM_EE },
          { label: 'Returned from ER', value: PaymentStatusE.RETURNED_FROM_ER },
          { label: 'Expired', value: PaymentStatusE.EXPIRED },
        ],
      },
    ],
  ]);

  _editPaymentBANKMap = new Map([
    [
      PaymentStatusE.NEW,
      {
        options: [
          { label: 'Passed to Bank', value: PaymentStatusE.PASSED_TO_BANK },
          { label: 'Completed', value: PaymentStatusE.COMPLETED },
          { label: 'Rejected', value: PaymentStatusE.REJECTED },
        ],
      },
    ],
    [
      PaymentStatusE.PASSED_TO_BANK,
      {
        options: [
          { label: 'Completed', value: PaymentStatusE.COMPLETED },
          { label: 'Rejected', value: PaymentStatusE.REJECTED },
        ],
      },
    ],
    [
      PaymentStatusE.REISSUE,
      {
        options: [
          { label: 'Pending', value: PaymentStatusE.PENDING },
          { label: 'Ready to reissue', value: PaymentStatusE.READY_TO_REISSUE },
          { label: 'Reissue Reject', value: PaymentStatusE.REISSUE_REJECT },
          { label: 'Reissue Complete', value: PaymentStatusE.REISSUE_COMPLETE },
        ],
      },
    ],
    [
      PaymentStatusE.PENDING,
      {
        options: [
          { label: 'Ready to reissue', value: PaymentStatusE.READY_TO_REISSUE },
          { label: 'Reissue Reject', value: PaymentStatusE.REISSUE_REJECT },
          { label: 'Reissue Complete', value: PaymentStatusE.REISSUE_COMPLETE },
        ],
      },
    ],
    [
      PaymentStatusE.READY_TO_REISSUE,
      {
        options: [
          { label: 'Reissue Reject', value: PaymentStatusE.REISSUE_REJECT },
          { label: 'Reissue Complete', value: PaymentStatusE.REISSUE_COMPLETE },
        ],
      },
    ],
  ]);

  _valid = false;

  _resetValid = false;

  _progress = false;

  form = new FormGroup({
    payRefNo: new FormControl('', []),
  });

  maxDate = maxDate;
  minDate = minDate;
  formatDate = formatDate;
  formatDate3 = formatDate3;
  formatDate4 = formatDate4;

  form1 = new FormGroup({
    dateFrom: new FormControl('', []),
    dateEnd: new FormControl('', []),
    statusFrom: new FormControl('', []),
    statusTo: new FormControl('', []),
  });

  pageConfig: PageConfig = {
    current: 1,
    pageSize: 10,
    totalCount: 0,
  };

  searchDTO: PaymentReportSearch = {
    payMethod: [PaymentMethodE.CHQ],
  };

  dataSource: PaymentReportHistory[] = [];

  searchButtonConfig = configPrimary();

  resetButtonConfig = configSecondary();

  _paymentStatusListCHQ: any[] = [
    { label: 'Hold Payment', value: PaymentStatusE.HOLD_PAYMENT },
    { label: 'New', value: PaymentStatusE.NEW },
    { label: 'Pending', value: PaymentStatusE.PENDING },
    { label: 'Printed', value: PaymentStatusE.PRINTED },
    { label: 'Ready to Reissue', value: PaymentStatusE.READY_TO_REISSUE },
    { label: 'Reissue', value: PaymentStatusE.REISSUE },
    { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
    { label: 'Unpresented', value: PaymentStatusE.UN_PRESENTED },
  ];

  _paymentStatusListBANK: any[] = [
    { label: 'New', value: PaymentStatusE.NEW },
    { label: 'Passed to Bank', value: PaymentStatusE.PASSED_TO_BANK },
    { label: 'Pending', value: PaymentStatusE.PENDING },
    { label: 'Ready to reissue', value: PaymentStatusE.READY_TO_REISSUE },
    { label: 'Reissue', value: PaymentStatusE.REISSUE },
  ];

  _allStatus = [
    { label: 'Expired', value: PaymentStatusE.EXPIRED },
    { label: 'Hold Payment', value: PaymentStatusE.HOLD_PAYMENT },
    { label: 'New', value: PaymentStatusE.NEW },
    { label: 'Pending', value: PaymentStatusE.PENDING },
    { label: 'Presented', value: PaymentStatusE.PRESENTED },
    { label: 'Printed', value: PaymentStatusE.PRINTED },
    { label: 'Ready to Reissue', value: PaymentStatusE.READY_TO_REISSUE },
    { label: 'Reissue', value: PaymentStatusE.REISSUE },
    { label: 'Reissue Completed', value: PaymentStatusE.REISSUE_COMPLETE },
    { label: 'Reissue Presented', value: PaymentStatusE.REISSUE_PRESENTED },
    { label: 'Reissue Reject', value: PaymentStatusE.REISSUE_REJECT },
    { label: 'Returned from EE', value: PaymentStatusE.RETURNED_FROM_EE },
    { label: 'Returned from ER', value: PaymentStatusE.RETURNED_FROM_ER },
    { label: 'Stop Payment', value: PaymentStatusE.STOP_PAYMENT },
    { label: 'Unpresented', value: PaymentStatusE.UN_PRESENTED },

    { label: 'Completed', value: PaymentStatusE.COMPLETED },
    { label: 'Passed to Bank', value: PaymentStatusE.PASSED_TO_BANK },
    { label: 'Rejected', value: PaymentStatusE.REJECTED },
  ];

  sheetHeaderRecord = {
    payRefNo: 'Payment Reference No.',
    payMethod: 'Payment Method',
    chqNo: 'Cheque Number',
    payeeName: 'Payee Name',
    amt: 'Amount',
    curr: 'Currency',
    genDate: 'Payment Instruction File Date',
    recordStatus: 'Payment Status',
    createdAt: 'Status Update Date',
    empfId: 'EMPF ID',
    mpfAcctNo: 'MPF Account No.',
    erempfId: 'EMPF ID of ER',
    erName: 'Name of ER',
    payrollGroupID: 'Payroll Group ID',
    remarks: 'Remarks',
  };

  sheetHeaderStatus = {
    payRefNo: 'Payment Reference No.',
    payMethod: 'Payment Method',
    chqNo: 'Cheque Number',
    payeeName: 'Payee Name',
    amt: 'Amount',
    curr: 'Currency',
    genDate: 'Payment Instruction File Date',
    fromPaymentStatus: 'From Payment Status',
    fromStatusUpdateDate: 'Status Update Date',
    toPaymentStatus: 'To Payment Status',
    toStatusUpdateDate: 'Status Update Date',
    turnaroundDays: 'Turnaround Days',
    empfId: 'EMPF ID',
    mpfAcctNo: 'MPF Account No.',
    erempfId: 'EMPF ID of ER',
    erName: 'Name of ER',
    payrollGroupID: 'Payroll Group ID',
    remarks: 'Remarks',
  };

  statusFromConfig: CdsDropdownConfig = {
    label: 'From Payment Status',
    placeholder: 'Select From Payment Status',
    options: [...this._paymentStatusListCHQ],
  };

  statusToConfig: CdsDropdownConfig = {
    label: 'To Payment Status',
    placeholder: 'Select To Payment Status',
    options: [],
  };

  _showError = false;
  _downloading = false;

  columnsConfig: ColumnConfig[] = [
    {
      key: 'createdAt',
      title: 'Generate Date',
    },
    {
      key: 'reportName',
      title: 'Report Name',
    },
    {
      key: 'createdByName',
      title: 'Generated By',
    },
  ];

  constructor(private service: PaymentServiceSHKP, private toastAlert: ToastAlertService) {}

  ngOnInit(): void {}

  getMinDate() {
    return moment(new Date()).subtract(36, 'months').format('YYYY-MM-DD');
  }

  inputChange(val: string, label: string) {
    switch (label) {
      case 'payRefNo':
        this.searchDTO.payRefNo = val;
        break;
    }
    this.validating();
  }

  pageInit() {
    this.pageConfig = {
      current: 1,
      pageSize: 10,
      totalCount: 0,
    };
  }

  searchFn() {
    this.validFn().then(r => {
      if (r) {
        pageInit();
        this.getLogList();
      }
    });
  }

  async validFn() {
    return this._valid && this.form.valid;
  }

  validating() {
    if (!!this.searchDTO.payRefNo || (this.searchDTO.dateFrom && this.searchDTO.dateEnd && this.searchDTO.statusFrom && this.searchDTO.statusTo)) {
      this._valid = true;
    } else {
      this._valid = false;
    }

    if (!!this.searchDTO.payRefNo || !!this.searchDTO.dateFrom || !!this.searchDTO.dateEnd || !!this.searchDTO.statusFrom || !!this.searchDTO.statusTo) {
      this._resetValid = true;
    } else {
      this._resetValid = false;
    }
  }

  reset() {
    this.searchDTO = {
      payMethod: [PaymentMethodE.CHQ],
    };
    this.searchDTO.payMethod = this._tabIndex == PaymentReportSubType.CHQ ? [PaymentMethodE.CHQ] : [PaymentMethodE.BTL, PaymentMethodE.BTO];
    this.statusFromConfig = {
      label: 'From Payment Status',
      placeholder: 'Select From Payment Status',
      options: [...(this._tabIndex == PaymentReportSubType.CHQ ? this._paymentStatusListCHQ : this._paymentStatusListBANK)],
    };
    this.statusToConfig = {
      label: 'To Payment Status',
      placeholder: 'Select To Payment Status',
      options: [],
    };
    this._showError = false;
    pageInit();
    this.validating();
    this.loadTable();
  }

  typeChange(val: PaymentReportType) {
    this._type = val;
    this.reset();
  }

  statusChange(status: PaymentStatusE, name: string) {
    switch (name) {
      case 'statusFrom':
        this.searchDTO.statusFrom = status;
        if (this._tabIndex == PaymentReportSubType.CHQ) {
          this.statusToConfig.options = this._editPaymentCHQMap.get(status)?.options || [];
        }
        if (this._tabIndex == PaymentReportSubType.BANK) {
          this.statusToConfig.options = this._editPaymentBANKMap.get(status)?.options || [];
        }
        this.statusToConfig = { ...this.statusToConfig };
        break;
      case 'statusTo':
        this.searchDTO.statusTo = status;
        break;
    }
    this.validating();
  }

  getRequestParams() {
    const params: PaymentReportHistory = deepCopy(this.searchDTO);
    return params;
  }

  reloadTable(event: any) {
    this.pageConfig = {
      ...this.pageConfig,
      current: event.current,
    };
    this.loadTable();
  }

  loadTable() {
    const listParms: PaymentHistorySearch = {
      size: this.pageConfig.pageSize as number,
      page: (this.pageConfig.current as number) - 1,
      reportType: this._type,
      subType: this._tabIndex,
    };
    this.getList(listParms);
  }

  getList(params: PaymentHistorySearch) {
    this._progress = true;
    this.service
      .getHistory(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.dataSource = data.data.empty ? [] : data.data.content;
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data?.totalElements,
          };
        } else {
          this.toastError(data.message);
        }
      });
  }

  getLogList() {
    const params: PaymentReportSearch = {
      ...this.searchDTO,
      dateFrom: this.formatDate(this.searchDTO.dateFrom),
      dateEnd: this.formatDate(this.searchDTO.dateEnd),
    };
    // const _statsList = [];
    // if (params.statusFrom) {
    //   _statsList.push(params.statusFrom);
    // }
    // if (params.statusTo) {
    //   _statsList.push(params.statusTo);
    // }
    // params.statusFrom = undefined;
    // params.statusTo = undefined;
    // params.statusList = _statsList;
    if (this._type == PaymentReportType.RECORD_REPORT) {
      params.payMethod = undefined;
    }
    this._progress = true;
    this.service
      .getPaymentLog(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this._showError = data.data.length == 0;
          if (data.data.length > 0) {
            const _name = this.getName(params);
            this.exportCsvStatus(_name, data.data);
            this.saveHistory(_name);
          }
        } else {
          this.toastError(data.message);
        }
      });
  }

  exportCsvStatus(name: string, data: PaymentReportLog[]) {
    this._downloading = true;
    jsonToSheet(
      {
        fileName: name,
        fileType: 'csv',
        header: this._type == PaymentReportType.RECORD_REPORT ? this.sheetHeaderRecord : this.sheetHeaderStatus,
      },
      this._type == PaymentReportType.RECORD_REPORT ? this.createDataRecord(data) : this.createDataStaus(data)
    );
    setTimeout(() => {
      this._downloading = false;
    }, 1000);
  }

  isoFormat(o?: string) {
    if (!o) {
      return '';
    }
    return moment(o).format('DD-MM-YYYY');
  }

  formatType(type?: PaymentMethodE) {
    let _type = '';
    switch (type) {
      case PaymentMethodE.CHQ:
        _type = 'Cheuqe';
        break;
      case PaymentMethodE.BTO:
        _type = 'Oversea Bank Transfer';
        break;
      case PaymentMethodE.BTL:
        _type = 'Local Bank Transfer';
        break;
    }
    return _type;
  }

  formatStatus(status?: PaymentStatusE) {
    return this._allStatus.find(x => x.value == status)?.label;
  }

  createDataRecord(data: PaymentReportLog[]) {
    return data.map(item => {
      return {
        payRefNo: item.payRefNo,
        payMethod: this.formatType(item.payMethod),
        chqNo: item.outChequeNo || 'NA',
        payeeName: item.payeeName,
        amt: item.amt,
        curr: item.curr,
        genDate: this.formatDate4(item.genDate),
        recordStatus: this.formatStatus(item.recordStatus),
        createdAt: this.isoFormat(item.createdAt),
        empfId: item.empfid,
        mpfAcctNo: item.mpfAcctNo,
        erempfId: item.erempfId,
        erName: item.erName,
        payrollGroupID: item.payrollGroupID,
        remarks: item.remarks,
      };
    });
  }

  createDataStaus(data: PaymentReportLog[]) {
    return data.map(item => {
      return {
        payRefNo: item.payRefNo,
        payMethod: this.formatType(item.payMethod),
        chqNo: item.outChequeNo || 'NA',
        payeeName: item.payeeName,
        amt: item.amt,
        curr: item.curr,
        genDate: this.formatDate4(item.genDate),
        fromPaymentStatus: this.formatStatus(item.fromPaymentStatus),
        statusUpdateDateFrom: this.isoFormat(item.fromStatusUpdateDate),
        toPaymentStatus: this.formatStatus(item.toPaymentStatus),
        statusUpdateDateTo: this.isoFormat(item.toStatusUpdateDate),
        turnaroundDays: item.turnaroundDays,
        empfId: item.empfid,
        mpfAcctNo: item.mpfAcctNo,
        erempfId: item.erempfId,
        erName: item.erName,
        payrollGroupID: item.payrollGroupID,
        remarks: item.remarks,
      };
    });
  }

  getName(params: PaymentReportSearch) {
    let _name = 'SHKP_Payment Status Report_';
    if (this._type == PaymentReportType.RECORD_REPORT) {
      _name = 'SHKP_Payment Record Report_' + params.payRefNo + '_';
    }
    if (this._type == PaymentReportType.STATUS_REPORT) {
      if (params.dateFrom) {
        _name = _name + this.formatDate3(params.dateFrom) + '_';
      }
      if (params.dateEnd) {
        _name = _name + this.formatDate3(params.dateEnd) + '_';
      }
      if (params.statusFrom) {
        _name = _name + params.statusFrom + '_';
      }
      if (params.statusTo) {
        _name = _name + params.statusTo + '_';
      }
    }
    _name = _name + new Date().getTime();
    return _name;
  }

  saveHistory(name: string) {
    const _param = {
      reportName: name,
      reportType: this._type,
      subType: this._tabIndex,
    };
    this.service
      .saveHistory(_param)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.loadTable();
        } else {
          this.toastError(data.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  selectedTabIndexChange(index: number) {
    this._tabIndex = index === 0 ? PaymentReportSubType.CHQ : PaymentReportSubType.BANK;
    this.reset();
  }
}
