/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { finalize } from 'rxjs';

import { CdsPopupService } from '@cds/ng-web-components/popup';

import { CommissionPoolScaleService } from 'src/app/core/services/commission/commission-pool-scale.service';
import { UserAgreementPopupComponent } from '../../../user-agreement-popup.component';

import { RecordDetailList } from '../record-details.model';
import { I18N_KEY } from './add-scale-records.config';
import { dateValidator, scaleCodeValidator, ScaleCodeAsyncValidator } from './add-scale-records.validators';
import { str2Obj } from 'src/app/utils/utils';

@Component({
  providers: [ScaleCodeAsyncValidator],
  selector: 'app-add-scale-records',
  templateUrl: './add-scale-records.component.html',
  styleUrls: ['./add-scale-records.component.scss'],
})
export class AddScaleRecordsComponent implements OnInit {
  commissionPoolId: string;
  isEdit = true;
  loading = false;
  isSaveing = false;
  isShowDatepiker = true; // fix datePicker bug
  dataSource: any[] = [];

  formGroup: FormGroup = new FormGroup({
    effectiveDate: new FormControl('', {
      initialValueIsDefault: true,
      validators: [dateValidator()],
    }),
    alarmChecked: new FormControl(
      {
        value: false,
        disabled: false,
      },
      {
        initialValueIsDefault: true,
      }
    ),
    scaleCode: new FormArray([]),
  });

  get effectiveDate() {
    return this.formGroup.get('effectiveDate') as FormControl;
  }

  get alarmChecked() {
    return this.formGroup.get('alarmChecked') as FormControl;
  }

  get scaleCode() {
    return this.formGroup.get('scaleCode') as FormArray;
  }

  get isSaveDisable() {
    return this.effectiveDate.invalid || this.scaleCode.pending || this.scaleCode.invalid || this.isResetDisable;
  }

  get isResetDisable() {
    const effectiveDateChange = this.effectiveDate.value === this.effectiveDate.defaultValue;
    const alarmChange = this.alarmChecked.value === this.alarmChecked.defaultValue;
    let scaleCodeChange = false;
    for (let i = 0; i < this.scaleCode.controls.length; i++) {
      const controls = this.scaleCode.controls[i];
      if (this.dataSource[i].value !== controls.value) {
        scaleCodeChange = true;
        break;
      }
    }
    return effectiveDateChange && alarmChange && !scaleCodeChange;
  }

  constructor(
    private scaleCodeAsyncValidator: ScaleCodeAsyncValidator,
    private cdsPopup: CdsPopupService,
    private poolScaleService: CommissionPoolScaleService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.commissionPoolId = this.route.snapshot.paramMap.get('commissionPoolId') || '';
    this.dataSource = RecordDetailList.map(item => {
      this.scaleCode.push(
        new FormControl('', {
          initialValueIsDefault: true,
          validators: [scaleCodeValidator('')],
          asyncValidators: [this.scaleCodeAsyncValidator.validate.bind(this.scaleCodeAsyncValidator)],
        })
      );
      return { scale: item.scale, scaleType: item.scaleType, value: '' };
    });
  }

  ngOnInit() {
    this.getScaleRecordList();
    const scaleDetailId = this.route.snapshot.paramMap.get('scaleDetailId');
    if (scaleDetailId) {
      this.getCommissionPoolInfo(scaleDetailId);
      return;
    }
  }

  getCommissionPoolInfo(scaleDetailId: string) {
    this.loading = true;
    this.poolScaleService
      .getScaleRecordInfo(scaleDetailId)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(resp => {
        const res = str2Obj(resp.body, ['amount']);
        if (res && res.data && res.data.commissionPoolScaleItems) {
          this.dataSource.forEach((item, index) => {
            let initValue = '';
            res.data.commissionPoolScaleItems.forEach((scale: any) => {
              if (item.scaleType == scale.scaleType && scale.commissionScaleDto && scale.commissionScaleDto.info) {
                initValue = scale.commissionScaleDto.info.scaleCode;
                item.value = initValue;
                this.scaleCode.setControl(
                  index,
                  new FormControl(initValue, {
                    initialValueIsDefault: true,
                    validators: [scaleCodeValidator(initValue)],
                    asyncValidators: [this.scaleCodeAsyncValidator.validate.bind(this.scaleCodeAsyncValidator)],
                  })
                );
              }
            });
          });
        }
      });
  }

  getScaleRecordList() {
    this.poolScaleService
      .getScaleRecordList({
        page: 0,
        size: Number.MAX_VALUE,
        commissionPoolId: this.commissionPoolId,
        status: 'VALID',
        sort: 'effectiveDate',
        order: 'asc',
      })
      .subscribe(res => {
        if (res && res.data && res.data.content && res.data.content.length) {
          const date = res.data.content.map((item: any) => {
            return moment(item.effectiveDate).format('YYYY-MM-DD');
          });
          this.effectiveDate.setValidators(dateValidator(date));
        }
      });
  }

  scaleCodeInput(ev: any, index: number) {
    let value = ev.target.value.toUpperCase();
    const pos = ev.target.selectionEnd;
    if (value === this.scaleCode.controls[index].value) {
      return;
    }
    value = value?.trim();
    this.scaleCode.controls[index].setValue(value.substring(0));
    ev.target.setSelectionRange(pos, pos);
  }

  clear(index: number) {
    this.scaleCode.controls[index].setValue('');
  }

  scaleCodeItemChange(index: number) {
    const control = this.scaleCode.controls[index] as FormControl;
    return control.value !== control.defaultValue;
  }

  reset() {
    if (this.isResetDisable) {
      return;
    }
    this.isShowDatepiker = false;
    setTimeout(() => {
      this.isShowDatepiker = true; // fix datePicker bug
    });
    this.formGroup.reset();
  }

  backLanding() {
    this.router.navigate(['/commission/pool-info/'], {
      queryParams: {
        commissionPoolId: this.commissionPoolId,
        tabType: 'recordDetails',
      },
    });
  }

  save() {
    if (this.isSaveDisable) {
      return;
    }
    this.alarmChecked.reset({
      value: this.alarmChecked.value,
      disabled: true,
    });
    this.isEdit = false;
  }

  backToEdit() {
    this.isEdit = true;
    this.alarmChecked.reset({
      value: this.alarmChecked.value,
      disabled: false,
    });
  }

  confirm() {
    if (this.isSaveing) {
      return;
    }
    this.isSaveing = true;
    this.poolScaleService
      .createScaleDetails(this.createParam())
      .pipe(
        finalize(() => {
          this.isSaveing = false;
        })
      )
      .subscribe(resp => {
        let popData;
        const resultNum = resp.result;
        if (resultNum == 0) {
          popData = {
            message: I18N_KEY.COMMON_SUCCESS,
            continue: I18N_KEY.COMMON_GOTIT,
            type: 'alert',
          };
        } else {
          popData = {
            message: I18N_KEY.COMMON_FAILED,
            continue: I18N_KEY.COMMON_GOTIT,
            type: 'alert',
            params: { respMsg: resp.message },
          };
        }
        const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
        popupRef.afterClosed().subscribe(confirm => {
          if (confirm && confirm.agree) {
            if (resultNum == 0) {
              this.backLanding();
            } else {
              this.backToEdit();
            }
          }
        });
      });
  }

  createParam() {
    const params: any = {};
    params.commissionPoolId = this.commissionPoolId;
    params.alertAlarm = this.alarmChecked.value;
    params.effectiveDate = moment(this.effectiveDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    params.commissionPoolScales = [];
    this.dataSource.forEach((item, index) => {
      params.commissionPoolScales.push({
        scaleType: item.scaleType,
        scaleCode: this.scaleCode.controls[index].value?.trim(),
      });
    });
    return params;
  }
}
