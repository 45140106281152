import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsRadioModule } from '@cds/ng-web-components/radio';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';

import { CdAccordionModule } from '../components/accordion/cd-accordion.module';
import { CdInputModule } from '../components/cd-input/cd-input.module';
import { RowItemModule } from '../components/row-item/row-item.module';
import { ExpandTableModule } from '../components/table/expand-table.module';
import { AlertPopupModule } from './alert-popup/alert-popup.module';
import { AutocompleteModule } from './autocomplete/autocomplete.module';
import { CdAutocompleteModule } from './cd-autocomplete/cd-autocomplete.module';
import { CdBackTextModule } from './cd-back-text/cd-back-text.module';
import { CdDownloadProgressModule } from './cd-download-progress/cd-download-progress.module';
import { CdDropdownModule } from './cd-dropdown/cd-dropdown.module';
import { CdPageTitleModule } from './cd-page-title/cd-page-title.module';
import { CdRadioModule } from './cd-radio/cd-radio.module';
import { CdSearchModule } from './cd-search/cd-search.module';
import { CdStepperModule } from './cd-stepper/cd-stepper.module';
import { CdTableTitleModule } from './cd-table-title/cd-table-title.module';
import { CdTextareaModule } from './cd-textarea/cd-textarea.module';
import { CdWarningPopupModule } from './cd-warning-popup/cd-warning-popup.module';
import { ConfirmPopupModule } from './confirm-popup/confirm-popup.module';
import { ContinuePopupModule } from './continue-popup/continue-popup.module';
import { DataTableModule } from './data-table';
import { DynamicAutocompleteModule } from './dynamic-autocomplete/input-autocomplete.module';
import { LanguageSwitchModule } from './language-switch/language-switch.module';
import { LoadingModule } from './loading/loading.modules';
import { MattersNeedingAttentionModule } from './matters-needing-attention/matters-needing-attention.module';
import { NoResultFoundModule } from './no-result-found/no-result-found.module';
import { AppPaginationModule } from './pagination/pagination.module';
import { PermissionModule } from './permission/permission.module';
import { SwUpdateAskModule } from './sw-update-ask/sw-update-ask.module';

import { BottomDetectionDirective } from './directive/bottom-detection/bottom-detection.directive';
import { DisableFormControlDirective } from './directive/disable-formcontrol/disable-formcontrol.directive';
import { OutsideCloseDirective } from './directive/outside-close/outside-close.directive';
import { TextEllipsisDirective } from './directive/text-ellipsis/text-ellipsis.directive';
import { MatSelectCloseDirective } from './directive/mat-select-close.directive';
import { MatMenuCloseDirective } from './directive/mat-menu-close.directive';

import { SsmsDatePipe } from './pipe/date.pipe';
import { SsmsDateTimePipe } from './pipe/dateTime.pipe';
import { FnPipe } from './pipe/fn.pipe';
import { Transfer2LabelPipe } from './pipe/label.pipe';
import { MyNumberPipe } from './pipe/number.pipe';
import { PermissionPipe } from './pipe/permission.pipe';
import { HideTextPipe } from './hide-text/hide-text.pipe';

import { ValidatorsModule } from './directive/validators/validators.module';
import { CdMultipleAccordionModule } from './cd-multiple-accordion/cd-multiple-accordion.module';
import { CdTextfieldComponent } from './cd-textfield/cd-textfield.component';
import { CdFakeTabsComponent } from './cd-fake-tabs/cd-fake-tabs.component';
import { StatusLightComponent } from '../views/salesjourney/case-summary/status-light/status-light.component';
import { CdsTooltipModule } from '@cds/ng-web-components/tooltip';
import { CdFakeLinkComponent } from './cd-fake-link/cd-fake-link.component';
import { CdTimePickerComponent } from './cd-time-picker/cd-time-picker.component';
import { WillBeLostComponent } from './will-be-lost/will-be-lost.component';
import { CdInlineAlertComponent } from './cd-inline-alert/cd-inline-alert.component';
import { UpdatedSuccessfullyComponent } from './updated-successfully/updated-successfully.component';
import { CdMultidropdownComponent } from './cd-multidropdown/cd-multidropdown.component';
import { TooltipIconModule } from './tooltip-icon/tooltip-icon.module';
import { DateRangeBoxComponent } from './date-range-box/date-range-box.component';
import { IconStatusComponent } from './icon-status/icon-status.component';
import { IconStatusModule } from './icon-status/icon-status.module';
import { BackTopModule } from './back-top/back-top.module';
import { SimplePopupModule } from './simple-popup/simple-popup.module';
import { WidgetHostDirective } from './directive/widgetHost/widget-host.directive';
import { CdIntragroupTransferCheckboxComponent } from './cd-intragroup-transfer-checkbox/cd-intragroup-transfer-checkbox.component';
import { CdCarouselModule } from './cd-carousel/cd-carousel.module';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsDialogModule } from '@cds/ng-experimental/dialog';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdPopupModule } from './cd-popup/cd-popup.module';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsDatepickerModule } from '@cds/ng-web-components/datepicker';
import { CdsSwitchModule } from '@cds/ng-web-components/switch';
import { CdsFontSwitcherModule } from '@cds/ng-themes';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdSwitchComponent } from './cd-switch/cd-switch.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CommBatchRunningModule } from './comm-batch-running/comm-batch-running.module';
import { CopyButtonComponent } from './copy-button/copy-button.component';
// import { CdsNavigationFooterLocalComponent } from '../views/layout/cds-navigation-footer/cds-navigation-footer.component';
import { ChatHistoryComponent } from '../views/chatbot-admin/main/chat-history/chat-history.component';
import { WillBeLoseInputComponent } from './will-be-lose-input/will-be-lose-input.component';
import { CdRealtimeSearchModule } from './cd-realtime-search/cd-realtime-search.module';

const pipeAndDirectives = [
  OutsideCloseDirective,
  BottomDetectionDirective,
  TextEllipsisDirective,
  MatSelectCloseDirective,
  MatMenuCloseDirective,
  DisableFormControlDirective,
  HideTextPipe,
  SsmsDatePipe,
  SsmsDateTimePipe,
  PermissionPipe,
  FnPipe,
  Transfer2LabelPipe,
  MyNumberPipe,
  WidgetHostDirective,
];

const components = [
  CdMultidropdownComponent,
  CdTextfieldComponent,
  CdFakeTabsComponent,
  StatusLightComponent,
  CdFakeLinkComponent,
  CdTimePickerComponent,
  WillBeLostComponent,
  CdInlineAlertComponent,
  UpdatedSuccessfullyComponent,
  DateRangeBoxComponent,
  IconStatusComponent,
  CdIntragroupTransferCheckboxComponent,
  CdSwitchComponent,
  CopyButtonComponent,
  // CdsNavigationFooterLocalComponent,
  ChatHistoryComponent,
  WillBeLoseInputComponent,
];

@NgModule({
  declarations: [...pipeAndDirectives, ...components],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AlertPopupModule,
    ConfirmPopupModule,
    ContinuePopupModule,
    CdsCheckboxModule,
    CommonModule,
    CdsLanguageModule,
    CdsButtonModule,
    NoResultFoundModule,
    AppPaginationModule,
    LoadingModule,
    CdsPaginationModule,
    CdsIconModule,
    PermissionModule,
    DataTableModule,
    AutocompleteModule,
    CdAutocompleteModule,
    CdBackTextModule,
    CdDownloadProgressModule,
    CdDropdownModule,
    CdRadioModule,
    CdSearchModule,
    CdStepperModule,
    CdTextareaModule,
    CdWarningPopupModule,
    LanguageSwitchModule,
    MattersNeedingAttentionModule,
    SwUpdateAskModule,
    CdPageTitleModule,
    CdTableTitleModule,
    CdsRadioModule,
    MatAutocompleteModule,
    ExpandTableModule,
    RowItemModule,
    CdAccordionModule,
    CdInputModule,
    DynamicAutocompleteModule,
    CdMultipleAccordionModule,
    CdsTextfieldModule,
    CdsTooltipModule,
    TooltipIconModule,
    BackTopModule,
    SimplePopupModule,
    CdCarouselModule,
    CdsPopupModule,
    CdsAssistiveTextModule,
    CdsDialogModule,
    CdsButtonModule,
    CdsProgressModule,
    CdPopupModule,
    CdsDatepickerModule,
    CdsFontSwitcherModule,
    CdsSwitchModule,
    UiSwitchModule,
    MatDialogModule,
    CdsDropdownModule,
    FontAwesomeModule,
    NgxDatatableModule,
    NgSelectModule,
    ReactiveFormsModule,
    AngularEditorModule,
    CommBatchRunningModule,
    FormsModule,
    CdRealtimeSearchModule,
  ],
  exports: [
    ...pipeAndDirectives,
    ...components,
    AlertPopupModule,
    ConfirmPopupModule,
    ContinuePopupModule,
    NoResultFoundModule,
    AppPaginationModule,
    LoadingModule,
    PermissionModule,
    DataTableModule,
    CdAutocompleteModule,
    CdBackTextModule,
    CdDownloadProgressModule,
    CdDropdownModule,
    CdRadioModule,
    CdSearchModule,
    CdStepperModule,
    CdTextareaModule,
    CdWarningPopupModule,
    LanguageSwitchModule,
    MattersNeedingAttentionModule,
    SwUpdateAskModule,
    AutocompleteModule,
    CdPageTitleModule,
    CdTableTitleModule,
    ExpandTableModule,
    RowItemModule,
    CdAccordionModule,
    ValidatorsModule,
    CdInputModule,
    CdMultipleAccordionModule,
    CdsTextfieldModule,
    TooltipIconModule,
    IconStatusModule,
    BackTopModule,
    DynamicAutocompleteModule,
    CdCarouselModule,
    CdsPopupModule,
    CdsDialogModule,
    CdsFormFieldModule,
    CdsAssistiveTextModule,
    CdsButtonModule,
    CdsProgressModule,
    CdPopupModule,
    CdsLanguageModule,
    CdsIconModule,
    CdsDropdownModule,
    CdsFormFieldModule,
    ReactiveFormsModule,
    CdsDatepickerModule,
    CdsTooltipModule,
    CdsTabsModule,
    CdsFontSwitcherModule,
    CdsSwitchModule,
    MatDialogModule,
    CdsCheckboxModule,
    FontAwesomeModule,
    NgxDatatableModule,
    NgSelectModule,
    ReactiveFormsModule,
    AngularEditorModule,
    CommBatchRunningModule,
    FormsModule,
    CdRealtimeSearchModule,
  ],
})
export class SharedModule {}
