import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MD_STATUS } from 'src/app/config/iff-status.config';

@Component({
  selector: 'app-iff-status-flow-popup',
  templateUrl: './iff-status-flow-popup.component.html',
  styleUrls: ['./iff-status-flow-popup.component.scss'],
})
export class IffStatusFlowPopupComponent implements OnInit {
  IFF_STATUS = MD_STATUS;

  constructor(private dialogRef: MatDialogRef<IffStatusFlowPopupComponent>) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }
}
