import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { CdsIconConfig } from '@cds/ng-core/icon';

@Component({
  selector: 'app-cd-accordion-panel',
  templateUrl: './cd-accordion-panel.component.html',
  styleUrls: ['./cd-accordion-panel.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'detail-expaned',
        style({
          height: '*',
          paddingTop: 'var(--cds-spacing-03)',
          paddingBottom: 'var(--cds-spacing-04)',
          opacity: '1',
        })
      ),
      state(
        'detail-collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: '0',
        })
      ),
      transition('detail-expaned <=> detail-collapsed', [animate('0.3s ease-in-out')]),
    ]),
  ],
})
export class CdAccordionPanelComponent implements OnInit {
  constructor() {}

  @Input() title?: string;
  @Input() longTitle = true;
  @Input() miniTitle?: string;
  @Input() titleTip?: string;
  @Input() subTitle?: string;
  @Input() subTitleTip?: string;
  @Input() right?: string;
  @Input() upIcon = 'action:button_down_outlined';
  @Input() downIcon = 'action:button_down_filled';
  @Input() color = '#EC6453';
  @Input() mouseColor = '#D03A39';
  @Input() size = '20px';
  @Input() statusColor?: string;
  @Input() titleClass = 'cds-h5-demibold';
  @Input() miniTitleClass = 'cds-body2-demibold';
  @Input() subTitleClass = '';
  @Input() subTitleOutClass = 'col-xs-12';
  @Input() rightDesTitle?: string;
  @Input() rightDesValue?: string | null;
  @Input() borderStyle = false;

  _config: CdsIconConfig = {
    color: this.color,
  };
  _iconClass = 'collapsed';
  _detaiClass = 'detail-collapsed';
  _expand? = false;
  borderClass = '';
  borderNone = '';

  ngOnInit(): void {
    if (this.borderStyle) {
      this.borderClass = 'border-class';
      this.borderNone = 'border-none';
    }
  }

  mouseenterFun() {
    this.size = '24px';
    this._config = {
      color: this.mouseColor,
    };
  }
  mouseleaveFun() {
    this.size = '20px';
    this._config = {
      color: this.color,
    };
  }

  @Input()
  set expand(val: boolean | undefined) {
    this._expand = val;
    this._detaiClass = this.expand ? 'detail-expaned' : 'detail-collapsed';
    setTimeout(() => {
      this._iconClass = this.expand ? 'expanded' : 'collapsed';
    }, 5);
  }

  get expand() {
    return this._expand;
  }

  trigger() {
    this.expand = !this.expand;
  }
}
