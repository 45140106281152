<div *ngIf="show === Show.employer" class="employer">
  <div class="head-box">
    <div class="back-box">
      <cds-icon (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
      <div class="back-title">
        <span (click)="onBack()" class="cds-body2-demibold">Exit and back to MD_IFF Summary Page</span>
      </div>
    </div>
    <span class="head">Generate eIFF Reference Number</span>
  </div>

  <form [formGroup]="employerForm">
    <div class="content-box">
      <div class="l-d-f l-ai-cen">
        <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="people:login_1"></cds-icon>
        <div class="l-ml-4">
          <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">{{ 'generate.md.employer' | lang }}</span>
        </div>
      </div>
      <div class="l-mt-7">
        <span class="cds-h2-demibold">強積金中介人聲明</span>
      </div>
      <div class="l-mt-4">
        <span class="cds-detail2">請填寫以下資料以完成聲明</span>
      </div>

      <div *ngIf="isConfirming" class="l-mt-6">
        <app-matters-needing-attention [color]="'red'" [title]="'generate.md.important-notes' | lang">
          <ul class="l-mt-0 l-mb-0">
            <li>
              <span class="cds-detail1">{{ 'generate.md.I-declare-that' | lang }}</span>
            </li>
            <li>
              <span class="cds-detail1 red-text">{{ 'generate.md.agents-should-no1' | lang }}</span>
              <span class="cds-detail1">{{ 'generate.md.agents-should-no2' | lang }}</span>
            </li>
          </ul>
        </app-matters-needing-attention>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">{{ 'generate.md.clientDetails' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body2-demibold">{{ 'generate.md.employer-full-name-br-number' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming">
                <span *ngIf="employerFullNameOrBrNumber === 'N'" class="cds-body1">{{ employerFullName.value }}</span>
                <span *ngIf="employerFullNameOrBrNumber === 'B'" class="cds-body1">{{ BRNumber.value }}</span>
              </div>
              <div *ngIf="!isConfirming">
                <div>
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    [(value)]="employerFullNameOrBrNumber"
                    [config]="employerFullNameOrBrNumberConfig"
                    (valueChange)="employerFullNameOrBrNumberChange()"></app-cd-radio>
                </div>
                <div class="l-mt-5 l-mb-3">
                  <div [style.display]="employerFullNameOrBrNumber === 'N' ? 'unset' : 'none'">
                    <cds-textfield
                      ngDefaultControl
                      [placeholder]="'generate.md.please-input' | lang : { p1: 'generate.md.employer-full-name' }"
                      formControlName="employerFullName"></cds-textfield>
                    <cds-assistive-text class="l-mt-4" *ngIf="employerFullName?.dirty && employerFullName?.hasError('required')">{{
                      'generate.md.please-input' | lang : { p1: 'generate.md.employer-full-name' }
                    }}</cds-assistive-text>
                  </div>
                  <div [style.display]="employerFullNameOrBrNumber === 'B' ? 'unset' : 'none'">
                    <cds-textfield
                      ngDefaultControl
                      [placeholder]="'generate.md.please-input' | lang : { p1: 'generate.md.br-bumber' }"
                      formControlName="BRNumber"></cds-textfield>
                    <cds-assistive-text class="l-mt-4" *ngIf="BRNumber?.dirty && BRNumber?.hasError('required')">{{
                      'generate.md.please-input' | lang : { p1: 'generate.md.br-bumber' }
                    }}</cds-assistive-text>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body2-demibold">{{ 'generate.md.auth-person-email-addr-phone-number' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming">
                <span *ngIf="authpersonContact === 'E'" class="cds-body1">{{ authpersonEmailAddress.value }}</span>
                <span *ngIf="authpersonContact === 'M'" class="cds-body1">(+{{ authpersonCountryCode.value }}) {{ authpersonPhoneNumber.value }}</span>
              </div>
              <div *ngIf="!isConfirming">
                <div>
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    [(value)]="authpersonContact"
                    [config]="authpersonContactConfig"
                    (valueChange)="authpersonContactChange()"></app-cd-radio>
                  <div>
                    <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !authpersonContact">{{
                      'generate.md.pleaseSelCustomerContactType' | lang
                    }}</cds-assistive-text>
                  </div>
                </div>
                <div>
                  <div *ngIf="authpersonContact === 'E'" class="l-mt-5 l-mb-3">
                    <cds-textfield
                      ngDefaultControl
                      [placeholder]="'generate.md.please-input' | lang : { p1: 'generate.md.emailAddress' }"
                      formControlName="authpersonEmailAddress"></cds-textfield>
                    <cds-assistive-text class="l-mt-4" *ngIf="authpersonEmailAddress?.dirty && authpersonEmailAddress?.hasError('email')">{{
                      'generate.md.pleaseInputValidEmail' | lang
                    }}</cds-assistive-text>
                    <cds-assistive-text class="l-mt-4" *ngIf="authpersonEmailAddress?.dirty && authpersonEmailAddress?.hasError('required')">{{
                      'generate.md.pleaseInputValidEmail' | lang
                    }}</cds-assistive-text>
                  </div>
                  <div *ngIf="authpersonContact === 'M'" class="l-d-f l-mt-5">
                    <cds-dropdown
                      formControlName="authpersonCountryCode"
                      [config]="countrycodeConfig"
                      (ngModelChange)="authpersonCountryCodeChange()"
                      ngDefaultControl></cds-dropdown>
                    <div>
                      <div class="l-ml-4" style="position: relative; top: -1px; width: 230px">
                        <app-cd-input
                          [type]="'number'"
                          ngDefaultControl
                          [placeholder]="'generate.md.please-input' | lang : { p1: 'generate.md.phoneNumber' }"
                          [group]="employerForm"
                          controlName="authpersonPhoneNumber"></app-cd-input>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text *ngIf="authpersonPhoneNumber?.dirty && authpersonPhoneNumber.hasError('errorPhoneNumber')">
                          <span>
                            {{ 'generate.md.pleaseInputValidMobileNumber' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text *ngIf="authpersonPhoneNumber?.dirty && authpersonPhoneNumber?.hasError('required')">
                          <span>
                            {{ 'generate.md.pleaseInputValidMobileNumber' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-box" *ngIf="authpersonContact === 'M'">
              <div style="width: 270px">
                <span class="cds-body2-demibold">{{ 'generate.md.selectLanguage' | lang }}:</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ (preferredLanguage.value === PreferredLanguageType.English ? 'common.english' : 'common.chinese') | lang }}</span>
              </div>
              <div *ngIf="!isConfirming" style="flex: 1">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #cdRadioPreferredLanguage
                  (valueChange)="employerForm.get('preferredLanguage')?.setValue($event)"
                  [value]="employerForm.get('preferredLanguage')?.value"
                  [config]="preferredLanguageConfig"></app-cd-radio>
                <div
                  *ngIf="employerForm.get('preferredLanguage')?.invalid && employerForm.get('preferredLanguage')?.touched"
                  style="margin-top: var(--cds-spacing-01)">
                  <cds-assistive-text type="error">{{ 'generate.md.please-select-language' | lang }} </cds-assistive-text>
                </div>
              </div>
            </div>
            <div *ngIf="!isConfirming" class="item-box">
              <div>
                <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">{{ 'generate.md.pleaseEnsure' | lang }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <span class="cds-body2-demibold" style="margin-bottom: 0">{{ 'generate.md.MPFIntermediary' | lang }}</span>
            </div>
            <div class="item-box">
              <app-cd-radio
                #radioRelevantRegulatedActivities
                class="cd-radio-margin-bottom-clear"
                [(value)]="relevantRegulatedActivitiesValue"
                [config]="relevantRegulatedActivitiesConfig"></app-cd-radio>
            </div>
            <div class="l-mt-1">
              <cds-assistive-text *ngIf="isTryConfirm && !relevantRegulatedActivitiesValue">{{
                'generate.md.pleaseSelectRelevantRegulatedActivities' | lang
              }}</cds-assistive-text>
            </div>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">有關權益轉移</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <span class="cds-body2-demibold" style="margin-bottom: 0">這是有聯繫公司間或更改業務擁有權時之成員累算權益轉移。</span>
            </div>
            <div class="item-box">
              <app-cd-radio #radioInTransferValue class="cd-radio-margin-bottom-clear" [(value)]="inTransferValue" [config]="inTransferConfig"></app-cd-radio>
            </div>
            <div class="l-mt-1">
              <cds-assistive-text *ngIf="isTryConfirm && !relevantRegulatedActivitiesValue">{{
                'generate.md.pleaseSelectRelevantRegulatedActivities' | lang
              }}</cds-assistive-text>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isConfirming" class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">{{ 'generate.md.2ndMPFIntermediary' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body1-demibold">DBS</span>
                <span class="cds-body1-demibold" style="color: var(--cds-color-dark-1-coral)">*</span>
                <span class="cds-body1-demibold">:</span>
              </div>
              <div>
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #dbsReferredLanguage
                  [(value)]="isDBschannel"
                  [config]="dbsContactConfig"
                  (valueChange)="dbsContractChange($event)">
                </app-cd-radio>
                <div>
                  <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !isDBschannel">Please select DBS</cds-assistive-text>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold" style="margin-bottom: 0">{{ 'generate.md.2nd-MPF-intermediary-code' | lang }}：</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ secondAgent.agentCode }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets" style="flex: 1">
                <div class="l-d-f">
                  <div style="max-width: 560px; min-width: 560px">
                    <app-cd-input
                      ngDefaultControl
                      [(value)]="secondAgent.agentCode"
                      (valueChange)="secondAgentCodeChange($event)"
                      [type]="'number'"
                      maxlength="6"
                      [placeholder]="'generate.md.please-input-optional' | lang : { p1: 'generate.md.2nd-MPF-intermediary-code' | lang }"></app-cd-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold" style="margin-bottom: 0">{{ 'generate.md.3rd-MPF-intermediary-code' | lang }}：</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ thirdAgent.agentCode }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets" style="flex: 1">
                <div class="l-d-f">
                  <div style="max-width: 560px; min-width: 560px">
                    <app-cd-input
                      ngDefaultControl
                      [(value)]="thirdAgent.agentCode"
                      [type]="'number'"
                      maxlength="6"
                      [disable]="secondAgent.agentCode.length === 0"
                      [placeholder]="'generate.md.please-input-optional' | lang : { p1: 'generate.md.3rd-MPF-intermediary-code' | lang }"></app-cd-input>
                  </div>
                </div>
              </div>
            </div>
            <cds-assistive-text class="l-mt-5" type="success">{{ 'generate.md.maximum-3-commission-agents' | lang }}</cds-assistive-text>
          </div>

          <div class="information-box" *ngIf="isDBschannel === 'Y'">
            <div class="cds-body-hero-light">dbs</div>
            <div class="item-list-box" style="border: 0px">
              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">RM Referral Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ employerForm.get('rmReferralCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [type]="'number'"
                    [group]="employerForm"
                    controlName="rmReferralCode"
                    [maxlength]="8"
                    ngDefaultControl
                    [placeholder]="'Input RM Referral Code'"></app-cd-input>

                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="employerForm.get('rmReferralCode')?.dirty && employerForm.get('rmReferralCode')?.hasError('required'); else second"
                      type="error"
                      >Please input valid RM Referral Code
                    </cds-assistive-text>
                    <ng-template #second>
                      <cds-assistive-text
                        *ngIf="employerForm.get('rmReferralCode')?.dirty && employerForm.get('rmReferralCode')?.hasError('lengthError')"
                        type="error"
                        >Please input valid RM Referral Code
                      </cds-assistive-text>
                    </ng-template>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Customer ID:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ employerForm.get('inputCustomerId')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="employerForm"
                    controlName="inputCustomerId"
                    [maxlength]="8"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Customer ID (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="employerForm.get('inputCustomerId')?.dirty && employerForm.get('inputCustomerId')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Customer ID
                    </cds-assistive-text>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Campaign Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ employerForm.get('campaignCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="employerForm"
                    controlName="campaignCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Campaign Code (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="employerForm.get('campaignCode')?.dirty && employerForm.get('campaignCode')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Campaign Code
                    </cds-assistive-text>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Source Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ employerForm.get('sourceCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="employerForm"
                    controlName="sourceCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Source Code (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text *ngIf="employerForm.get('sourceCode')?.dirty && employerForm.get('sourceCode')?.hasError('lengthError')" type="error"
                      >Please input valid Source Code
                    </cds-assistive-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isConfirming && secondAgent.secondAgentInfo" class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">{{ 'generate.md.2nd-MPF-intermediary-details' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.agent-code' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ secondAgent.secondAgentInfo.agentCode }}</span>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.agent-name' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ getSecondAgentName }}</span>
              </div>
            </div>
            <div *ngIf="secondAgentRelevantRegulatedActivities === 'Y'" class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientEmailorPhone' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{
                  secondAgentClientContact === 'E' ? secondAgent.secondAgentInfo.shortEmail : secondAgent.secondAgentInfo.shortPhone
                }}</span>
              </div>
            </div>
            <div *ngIf="secondAgentRelevantRegulatedActivities === 'Y' && getSecondAgentLanguage" class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.selectLanguage' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ getSecondAgentLanguage }}</span>
              </div>
            </div>
            <div class="second-agent-relevant-regulated-activities-box l-mt-5">
              <div>
                <span class="cds-detail2">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
              </div>
              <div class="l-mt-4">{{ 'generate.md.mpf-intermediary' | lang }}</div>
              <div class="l-mt-5">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #radioSecondAgentRelevantRegulatedActivities
                  [(value)]="secondAgentRelevantRegulatedActivities"
                  [config]="secondAgentRelevantRegulatedActivitiesConfig"></app-cd-radio>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isConfirming && thirdAgent.thirdAgentInfo" class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">{{ 'generate.md.3rd-MPF-intermediary-details' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.agent-code' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ thirdAgent.thirdAgentInfo.agentCode }}</span>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.agent-name' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ getThirdAgentName }}</span>
              </div>
            </div>
            <div *ngIf="thirdAgentRelevantRegulatedActivities === 'Y'" class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientEmailorPhone' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{
                  thirdAgentClientContact === 'E' ? thirdAgent.thirdAgentInfo.shortEmail : thirdAgent.thirdAgentInfo.shortPhone
                }}</span>
              </div>
            </div>
            <div *ngIf="thirdAgentRelevantRegulatedActivities === 'Y' && getThirdAgentLanguage" class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.selectLanguage' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ getThirdAgentLanguage }}</span>
              </div>
            </div>
            <div class="second-agent-relevant-regulated-activities-box l-mt-5">
              <div>
                <span class="cds-detail2">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
              </div>
              <div class="l-mt-4">{{ 'generate.md.mpf-intermediary' | lang }}</div>
              <div class="l-mt-5">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #radioThirdAgentRelevantRegulatedActivities
                  [(value)]="thirdAgentRelevantRegulatedActivities"
                  [config]="thirdAgentRelevantRegulatedActivitiesConfig"></app-cd-radio>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button (click)="reset()" [style]="'secondary'">{{ 'common.reset1' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button [disabled]="isDisableNext" (click)="next()">{{ 'common.next' | lang }}</cds-button>
        </div>
      </div>

      <div *ngIf="isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button (click)="backToEdit()" [style]="'secondary'">{{ 'common.backToEdit' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button (click)="submit()">{{ 'common.confirmAndSubmit' | lang }}</cds-button>
          <div class="l-mt-3">
            <cds-assistive-text>{{ 'generate.md.no-changes-submitted' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="show === Show.intermediary" class="employer">
  <div class="head-box">
    <div class="back-box">
      <cds-icon (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
      <div class="back-title">
        <span (click)="onBack()" class="cds-body2-demibold">Exit and back to MD_IFF Summary Page</span>
      </div>
    </div>
    <span class="head">Generate eIFF Reference Number</span>
  </div>

  <div class="content-box">
    <div class="l-d-f l-ai-cen">
      <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="people:login_1"></cds-icon>
      <div class="l-ml-4">
        <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">{{ 'generate.md.employer' | lang }}</span>
      </div>
    </div>
    <div class="l-mt-7">
      <span class="cds-h2-demibold">強積金中介人聲明</span>
    </div>
    <div class="l-mt-4">
      <span class="cds-detail2">{{ 'generate.md.addMPFIntermediary' | lang }}</span>
    </div>
    <div *ngIf="secondAgent.secondAgentInfo" class="information-list-box">
      <div class="information-box">
        <div>
          <span class="cds-h4">{{ 'generate.md.2nd-MPF-intermediary-details' | lang }}</span>
        </div>
        <div class="item-list-box">
          <div class="item-box">
            <div style="width: 270px; line-height: 33px">
              <span class="cds-body2-demibold">{{ 'generate.md.agent-code' | lang }}</span
              ><span class="cds-body2-demibold">:</span>
            </div>
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ secondAgent.secondAgentInfo.agentCode }}</span>
            </div>
          </div>
          <div class="item-box">
            <div style="width: 270px; line-height: 33px">
              <span class="cds-body2-demibold">{{ 'generate.md.agent-name' | lang }}</span
              ><span class="cds-body2-demibold">:</span>
            </div>
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ getSecondAgentName }}</span>
            </div>
          </div>
          <div class="second-agent-relevant-regulated-activities-box l-mt-5">
            <div>
              <span class="cds-detail2">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
            </div>
            <div class="l-mt-4">{{ 'generate.md.mpf-intermediary' | lang }}</div>
            <div class="l-mt-5">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                #radioSecondAgentRelevantRegulatedActivities
                [(value)]="secondAgentRelevantRegulatedActivities"
                [config]="secondAgentRelevantRegulatedActivitiesConfig"></app-cd-radio>
            </div>
            <ng-container *ngIf="secondAgentRelevantRegulatedActivities === 'Y'">
              <div class="l-mt-7">
                <app-matters-needing-attention [title]="'generate.md.important-notes' | lang">
                  <div class="cds-detail2 l-d-f l-fd-col">
                    <span class="cds-detail2 l-mt-1">
                      {{ 'generate.md.mpf-declaration-will' | lang }}
                    </span>
                  </div>
                </app-matters-needing-attention>
              </div>
              <div class="l-mt-7">
                <span class="cds-body2-demibold" style="color: #000000">{{ 'generate.md.choose-the-following-registered' | lang }}</span>
              </div>
              <div *ngIf="secondAgentClientContactConfig" class="l-mt-4">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #radioSecondAgentClientContact
                  [(value)]="secondAgentClientContact"
                  (valueChange)="secondAgentClientContactChange()"
                  [config]="secondAgentClientContactConfig"></app-cd-radio>
              </div>
              <div *ngIf="secondAgentClientContact === 'M'" class="l-mt-5 l-d-f">
                <div>
                  <span>{{ 'generate.md.selectLanguage' | lang }}</span>
                </div>
                <div style="margin-left: 100px">
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    #radioSecondAgentLanguage
                    [(value)]="secondAgentLanguage"
                    [config]="secondAgentLanguageConfig"></app-cd-radio>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="thirdAgent.thirdAgentInfo" class="information-list-box">
      <div class="information-box">
        <div>
          <span class="cds-h4">{{ 'generate.md.3rd-MPF-intermediary-details' | lang }}</span>
        </div>
        <div class="item-list-box">
          <div class="item-box">
            <div style="width: 270px; line-height: 33px">
              <span class="cds-body2-demibold">{{ 'generate.md.agent-code' | lang }}</span
              ><span class="cds-body2-demibold">:</span>
            </div>
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ thirdAgent.thirdAgentInfo.agentCode }}</span>
            </div>
          </div>
          <div class="item-box">
            <div style="width: 270px; line-height: 33px">
              <span class="cds-body2-demibold">{{ 'generate.md.agent-name' | lang }}</span
              ><span class="cds-body2-demibold">:</span>
            </div>
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ getThirdAgentName }}</span>
            </div>
          </div>
          <div class="second-agent-relevant-regulated-activities-box l-mt-5">
            <div>
              <span class="cds-detail2">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
            </div>
            <div class="l-mt-4">{{ 'generate.md.mpf-intermediary' | lang }}</div>
            <div class="l-mt-5">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                #radioThirdAgentRelevantRegulatedActivities
                [(value)]="thirdAgentRelevantRegulatedActivities"
                [config]="thirdAgentRelevantRegulatedActivitiesConfig"></app-cd-radio>
            </div>
            <ng-container *ngIf="thirdAgentRelevantRegulatedActivities === 'Y'">
              <div class="l-mt-7">
                <app-matters-needing-attention [title]="'generate.md.important-notes' | lang">
                  <div class="cds-detail2 l-d-f l-fd-col">
                    <span class="cds-detail2 l-mt-1">
                      {{ 'generate.md.mpf-declaration-will' | lang }}
                    </span>
                  </div>
                </app-matters-needing-attention>
              </div>
              <div class="l-mt-7">
                <span class="cds-body2-demibold" style="color: #000000">{{ 'generate.md.choose-the-following-registered' | lang }}</span>
              </div>
              <div *ngIf="thirdAgentClientContactConfig" class="l-mt-4">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #radioThirdAgentClientContact
                  [(value)]="thirdAgentClientContact"
                  (valueChange)="thirdAgentClientContactChange()"
                  [config]="thirdAgentClientContactConfig"></app-cd-radio>
              </div>
              <div *ngIf="thirdAgentClientContact === 'M'" class="l-mt-5 l-d-f">
                <div>
                  <span>{{ 'generate.md.selectLanguage' | lang }}</span>
                </div>
                <div style="margin-left: 100px">
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    #radioThirdAgentLanguage
                    [(value)]="thirdAgentLanguage"
                    [config]="thirdAgentLanguageConfig"></app-cd-radio>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="l-mt-6 l-d-f">
      <div>
        <cds-button (click)="intermediaryReset()" [style]="'secondary'">{{ 'common.reset1' | lang }}</cds-button>
      </div>
      <div class="l-ml-4">
        <cds-button [disabled]="isDisableIntermediaryNext" (click)="intermediaryNext()">{{ 'common.next' | lang }}</cds-button>
      </div>
    </div>
  </div>
</div>
