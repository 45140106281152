<div class="row acc-logo" [ngClass]="borderClass">
  <div
    (click)="trigger()"
    (mouseenter)="mouseenterFun()"
    (mouseleave)="mouseleaveFun()"
    class="col-xs-12 pointer"
    [ngClass]="[expand ? 'line-bord-show' : 'line-bord-2']">
    <div class="row title-bar">
      <div class="l-mr-3 icon-container">
        <cds-icon *ngIf="expand" [width]="size" [height]="size" [ngClass]="_iconClass" [config]="_config" [icon]="upIcon"></cds-icon>
        <cds-icon *ngIf="!expand" [ngClass]="_iconClass" [width]="size" [height]="size" [config]="_config" [icon]="downIcon"></cds-icon>
      </div>
      <div class="row l-plr-0" [class.col-xs]="longTitle">
        <div class="col-xs-12 l-plr-0">
          <div>
            <span class="cds-h5-demibold" [ngClass]="titleClass">{{ title }}</span>
            <app-tooltip-icon *ngIf="titleTip" [tooltipString]="titleTip"></app-tooltip-icon>
            <span class="l-mr-2"></span>
            <span class="cds-body2-demibold" [ngClass]="miniTitleClass">{{ miniTitle }}</span>
          </div>
        </div>
        <div [ngClass]="subTitleOutClass">
          <span [ngClass]="subTitleClass">
            {{ subTitle }}
          </span>
          <app-tooltip-icon *ngIf="subTitleTip" [tooltipString]="subTitleTip"></app-tooltip-icon>
        </div>
      </div>
      <div *ngIf="right" class="row col-xs-offset-3 middle-xs l-plr-0 right">
        <div *ngIf="statusColor" class="p-status l-mr-2" [ngStyle]="{ background: statusColor }"></div>
        <div>
          <span class="cds-body2 italic">{{ right }}</span>
        </div>
      </div>

      <div class="row col-xs-offset-3 middle-xs l-plr-0 right">
        <div class="right-des-title">{{ rightDesTitle }}</div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div class="right-des-area">
          <span class="cds-body2 right-des-value">{{ rightDesValue }}</span>
        </div>
      </div>
    </div>
  </div>

  <div [@openClose]="_detaiClass" class="col-xs-12 row line-bord-detail" [ngClass]="borderNone">
    <ng-content></ng-content>
  </div>
</div>
