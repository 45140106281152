/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse } from '../models/response/response';
import { ResponseResult } from '../models/response/response-result';

@Injectable({
  providedIn: 'root',
})
export class CommissionBatchStatusService {
  timeout: any;
  isDone = true;
  on = false;

  constructor(private http: HttpClient, private router: Router) {}

  refreshCommBatchStatus() {
    if (!this.on) {
      return;
    }
    if (this.timeout) {
      return;
    }
    this.timeout = true;
    const timer = setTimeout(() => {
      this.getBatchStatus().subscribe(res => {
        const isCommissionPage = this.router.url.includes('commission') || this.router.url.includes('default-scale-list');
        this.isDone = res.result !== ResponseResult.SUCCESS || !isCommissionPage || res.data === CommissionBatchStatus.DONE;
        this.timeout = false;
        clearTimeout(timer);
      });
    }, 1000);
  }

  getBatchStatus(): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${environment.apiPrefix}${environment.identityServiceExt}/batch/query/status?serviceName=commission-service`);
  }
}

export enum CommissionBatchStatus {
  DONE = 'DONE',
  RUNNING = 'RUNNING',
}
