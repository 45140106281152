import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DefaultInterceptor } from './core/interceptors/default.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';

import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { CdsLangService, CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsSortModule } from '@cds/ng-data-table/sort';
import { CdsTableModule } from '@cds/ng-data-table/table';
import { CdsAssistiveTextModule } from '@cds/ng-web-components/assistive-text';
import { CdsButtonModule } from '@cds/ng-web-components/button';
import { CdsCheckboxModule } from '@cds/ng-web-components/checkbox';
import { CdsDatepickerModule, CDS_DATE_FORMATS, DateAdapter } from '@cds/ng-web-components/datepicker';
import { CdsDropdownModule } from '@cds/ng-web-components/dropdown';
import { CdsFormFieldModule } from '@cds/ng-web-components/form-field';
import { CdsIconModule } from '@cds/ng-web-components/icon';
import { CdsLinkModule } from '@cds/ng-web-components/link';
import { CdsNavigationModule } from '@cds/ng-web-components/navigation';
import { CdsPaginationModule } from '@cds/ng-web-components/pagination';
import { CdsPopupModule } from '@cds/ng-web-components/popup';
import { CdsProgressModule } from '@cds/ng-web-components/progress';
import { CdsRadioModule } from '@cds/ng-web-components/radio';
import { CdsSearchModule } from '@cds/ng-web-components/search';
import { CdsStepperModule } from '@cds/ng-web-components/stepper';
import { CdsTabsModule } from '@cds/ng-web-components/tabs';
import { CdsTextfieldModule } from '@cds/ng-web-components/textfield';
import { RoutesRoutingModule } from './routes-routing.module';
import { SharedModule } from './shared/shared.module';
import { AgentModule } from './views/agent/agent.module';
import { CommissionModule } from './views/commission/commission.module';
import { ErrorModule } from './views/error/error.module';
import { UserModule } from './views/user/user.module';

import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';

import { WelcomeModule } from './views/welcome/welcome.module';
import { LocalStorage } from './utils/LocalStorage';
import { AdminHeaderComponent } from './views/layout/admin-layout/admin-header/admin-header.component';
import { AdminLayoutComponent } from './views/layout/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './views/layout/auth-layout/auth-layout.component';
import { CdsNavigationFooterLocalComponent } from './views/layout/cds-navigation-footer/cds-navigation-footer.component';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ShkModule } from 'src/app/views/shk/shk.module';
import { MY_FORMATS } from './config/datePicker';
import { AgentListModule } from './views/agent-list/agent-list.module';
import { AgentPerformanceModule } from './views/agent-performance/agent-performance.module';
import { AgentPerformanceEnrolmentModule } from './views/agent-performance-enrolment/agent-performance-enrolment.module';
import { EmployeeModule } from './views/employee/employee.module';
import { EmployerModule } from './views/employer/employer.module';
import { NotificationModule } from './views/mobile-subscription/notification.module';
import { SalesjourneyModule } from './views/salesjourney/salesjourney.module';
import { SystemModule } from './views/system/system.module';
import { TerminationModule } from './views/termination/termination.module';
import { EnrolmentModule } from './views/enrolment/enrolment.module';
import { TimeOutComponent } from './views/user/login/time-out.component';
import { ReportCenterModule } from './views/report-center/report-center.module';
import { TrusteeDetailsPopupModule } from './components/trustee-details-popup/trustee-details-popup.module';
import { AngentModule } from './views/chatbot/angent/angent.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ChatbotAdminModule } from './views/chatbot-admin/chatbot-admin.module';
import { CampaignModule } from './views/campaign/campaign.module';
import { PaymentModule } from './views/payment/payment.module';
import { AuthHttpInterceptor } from './core/interceptors/auth.http-interceptor';
import { BenefitPaymentModule } from './views/benefit-payment/benefit-payment.module';
import { AnnuncementModule } from './views/annuncement/annuncement.module';
import { UploadCenterModule } from './views/upload-center/upload-center.module';
import { DataPermissionInterceptor } from './core/interceptors/dataPermission.interceptor';
import { SupervisorModule } from './views/chatbot-admin/chatbot-supervisor/supervisor.module';
import { CommissionBatchStatusInterceptor } from './core/interceptors/commission-batch-status.interceptor';
import { AgentDashboardComponent } from './views/chatbot/angent/agent-dashboard.component';
import { HighRiskFileUploadCenterModule } from './views/high-risk-file-upload-center/high-risk-file-upload-center.module';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    TimeOutComponent,
    CdsNavigationFooterLocalComponent,
    AdminHeaderComponent,
    AgentDashboardComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RoutesRoutingModule,
    MatMenuModule,
    WelcomeModule,
    MatSelectModule,
    CdsLinkModule,
    CdsNavigationModule,
    CdsLanguageModule,
    CdsIconModule,
    CdsButtonModule,
    CdsTextfieldModule,
    CdsPaginationModule,
    CdsTableModule,
    CdsStepperModule,
    CdsCheckboxModule,
    CdsSearchModule,
    CdsPopupModule,
    CdsFormFieldModule,
    CdsAssistiveTextModule,
    CdsDropdownModule,
    CdsProgressModule,
    CdsTabsModule,
    CdsRadioModule,
    CdsDatepickerModule,
    CdsSortModule,
    UserModule,
    ErrorModule,
    NotificationModule,
    AgentModule,
    CommissionModule,
    PaymentModule,
    SharedModule,
    EmployeeModule,
    EmployerModule,
    SalesjourneyModule,
    ReportCenterModule,
    TerminationModule,
    EnrolmentModule,
    AgentListModule,
    ShkModule,
    SystemModule,
    AgentPerformanceModule,
    AgentPerformanceEnrolmentModule,
    MatSnackBarModule,
    MatDialogModule,
    TrusteeDetailsPopupModule,
    AngentModule,
    UiSwitchModule,
    CampaignModule,
    BenefitPaymentModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.profile !== 'dev',
      // enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ChatbotAdminModule,
    AnnuncementModule,
    UploadCenterModule,
    SupervisorModule,
    HighRiskFileUploadCenterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommissionBatchStatusInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: DataPermissionInterceptor, multi: true },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: INIT_LANG_DATA,
      deps: [CdsLangService],
    },
    LocalStorage,

    { provide: CDS_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId, // PPE testing environment
      authority: 'https://login.microsoftonline.com/5d3e2773-e07f-4432-a630-1a0f68a28a05',
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      // storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  //protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/login-failed',
  };
}

export function INIT_LANG_DATA(langService: CdsLangService) {
  return () => {
    return Promise.all([
      langService.init([
        './assets/i18n/common.json',
        './assets/i18n/common-business.json',
        './assets/i18n/common-month.json',
        './assets/i18n/home.json',
        './assets/i18n/login.json',
        './assets/i18n/agent.json',
        './assets/i18n/summary.json',
        './assets/i18n/report-center.json',
        './assets/i18n/commission/commission-scale.json',
        './assets/i18n/commission/scheme-master.json',
        './assets/i18n/commission/commission-journal.json',
        './assets/i18n/commission/commission-hold-on-report.json',
        './assets/i18n/commission/commission-individual-transfer.json',
        './assets/i18n/commission/commission-make-appeal.json',
        './assets/i18n/commission/commission-error-log.json',
        './assets/i18n/commission/commission-default-scale.json',
        './assets/i18n/commission/commission-report-extraction.json',
        './assets/i18n/commission/commission-withdrawal-claw-back.json',
        './assets/i18n/commission/commission-ape.json',
        './assets/i18n/commission-pool/payout.json',
        './assets/i18n/commission-pool/common.json',
        './assets/i18n/commission-pool/create.json',
        './assets/i18n/commission-pool/summary.json',
        './assets/i18n/commission-pool/related-parties/related-list.json',
        './assets/i18n/commission-pool/related-parties/related-add.json',
        './assets/i18n/commission-pool/scale/record-details.json',
        './assets/i18n/commission-pool/scale/record-create.json',
        './assets/i18n/commission-pool/scale/options.json',
        './assets/i18n/commission-pool/agent/agent.json',
        './assets/i18n/commission-pool/agent/agent-add-alert.json',
        './assets/i18n/commission-pool/agent/agent-record-add.json',
        './assets/i18n/setting/audit-history.json',
        './assets/i18n/setting/role.json',
        './assets/i18n/setting/calendar.json',
        './assets/i18n/employ.json',
        './assets/i18n/product-summary.json',
        './assets/i18n/employee.json',
        './assets/i18n/termination.json',
        './assets/i18n/sales-journey.json',
        './assets/i18n/shk.json',
        './assets/i18n/agent-profile.json',
        './assets/i18n/sales-journey/iff-declined-dashboard.json',
        './assets/i18n/payment-journal.json',
        './assets/i18n/annuncement/annuncement-center.json',
        './assets/i18n/generate-md/generate-md.json',
        './assets/i18n/md-iff-summary.json',
        './assets/i18n/sales-journey/case-details.json',
      ]),
    ]);
  };
}
