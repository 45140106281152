import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import moment from 'moment';
import { DECLINE_REASON } from 'src/app/config/decline-reason.config';
import { EMPF_APP_STATUS } from 'src/app/config/empf-app-status.config';
import { eMPF_IFF_STATUS } from 'src/app/config/iff-status.config';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { dateRangeInvalidValidator, isSameOrBeforeToday } from 'src/app/core/validators';
import { atLeastOneQueryConditionValidator } from 'src/app/core/validators/common.validator';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { dateValidator } from 'src/app/shared/validators/validators';
import { copyText, getFilterParams, pageToTop } from 'src/app/utils/utils';
import { AdviseEmpfrefComponent } from '../../case-summary/advise-empfref/advise-empfref.component';
import { SalesJourneyProdType } from '../../case-summary/case-summary.model';
import { EmpfAppStatusPopupComponent } from '../../empf-app-status-popup/empf-app-status-popup.component';
import { IffStatusFlowPopupComponent } from '../../iff-status-flow-popup/iff-status-flow-popup.component';
import { QuickSearchIffStatus } from '../md-iff-summary.component';
import { EmpfSupportIndRecordItem, RefNoType } from '../md-iff-summary.model';

interface ReqeuestParams {
  domain: 'SEP';
  page: number;
  size: number;
  empfNo?: string;
  clientName?: string;
  declarationDateStart?: string;
  declarationDateEnd?: string;
  mdIffStatus?: number[];
  productTypes?: string[];
  empfStatus?: number[];
  hkidOrPassportNo?: string;
  agentCode?: string;
  declineReason?: number[];
  abm?: string;
}

@Component({
  selector: 'app-empf-salessupport-sep',
  templateUrl: './empf-salessupport-sep.component.html',
  styleUrls: ['./empf-salessupport-sep.component.scss'],
})
export class EmpfSalessupportSepComponent implements OnInit {
  @Input() rolePriority?: string;
  QuickSearchIffStatus = QuickSearchIffStatus;
  quickSearchIffStatusSelected = QuickSearchIffStatus.All;
  formGroup!: FormGroup;
  copyText = copyText;
  reqeuestParams: ReqeuestParams = {
    domain: 'SEP',
    page: 1,
    size: 30,
    productTypes: ['SEP'],
  };
  mdIffStatusCompleted?: CdsOption;
  mdIffStatusDeclined?: CdsOption;

  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  readonly displayedColumns = [
    'empfNo',
    'mdNo',
    'districtCode',
    'agentCode',
    'productType',
    'clientName',
    'declarationDate',
    'mdIffStatus',
    'empfStatus',
    'isSplitAgent',
    'hkIdOrPassport',
    'declineReason',
  ];

  allPageData: EmpfSupportIndRecordItem[][] = [];
  currentPageNumOrigin = 1;
  abmOptionList: CdsOption[] = [];
  totalElements = 0;
  totalPages = 0;
  resetDatePicker = false;
  private today = new Date();
  declarationDateFromMax = this.today;
  declarationDateToMin = new Date('2000/01/01');
  declarationDateToMax: Date = this.today;
  iffStatusoptionList: CdsOption[] = [];
  empfAppStatusoptionList: CdsOption[] = [];
  declinedReasonOptionList: CdsOption[] = [];
  isLoading = false;
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;
  get currentPageIndex() {
    return this.currentPageNumOrigin - 1;
  }

  get currentPageData() {
    if (this.allPageData.length === 0) {
      return [];
    }
    return this.allPageData[this.currentPageIndex];
  }

  get checkIsHaveScroll() {
    if (this.tableBox) {
      return this.tableBox.nativeElement.scrollWidth > this.tableBox.nativeElement.clientWidth;
    }
    return false;
  }

  get empfNo() {
    return this.formGroup.get('empfNo') as FormControl;
  }

  get clientName() {
    return this.formGroup.get('clientName') as FormControl;
  }

  get agentCode() {
    return this.formGroup.get('agentCode') as FormControl;
  }

  get districtCode() {
    return this.formGroup.get('districtCode') as FormControl;
  }

  get abm() {
    return this.formGroup.get('abm') as FormControl;
  }

  get declarationDateStart() {
    return this.formGroup.get('declarationDateStart') as FormControl;
  }

  get declarationDateEnd() {
    return this.formGroup.get('declarationDateEnd') as FormControl;
  }

  get mdIffStatus() {
    return this.formGroup.get('mdIffStatus') as FormControl;
  }

  get empfStatus() {
    return this.formGroup.get('empfStatus') as FormControl;
  }

  get hkidOrPassportNo() {
    return this.formGroup.get('hkidOrPassportNo') as FormControl;
  }

  get declineReason() {
    return this.formGroup.get('declineReason') as FormControl;
  }

  constructor(
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    public empfAppStatusService: EmpfAppStatusService,
    private router: Router,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        empfNo: new FormControl(),
        clientName: new FormControl(),
        agentCode: new FormControl(),
        districtCode: new FormControl(),
        abm: new FormControl(),
        declarationDateStart: new FormControl(null, {
          validators: [dateValidator('common.invalidDate'), isSameOrBeforeToday({ dateInvalid: 'common.invalidDate' })],
        }),
        declarationDateEnd: new FormControl(null, {
          validators: [dateValidator('common.invalidDate'), isSameOrBeforeToday({ dateInvalid: 'common.invalidDate' })],
        }),
        hkidOrPassportNo: new FormControl(),
        mdIffStatus: new FormControl([]),
        declineReason: new FormControl(),
        empfStatus: new FormControl([]),
      },
      [dateRangeInvalidValidator('declarationDateStart', 'declarationDateEnd'), atLeastOneQueryConditionValidator()]
    );

    this.initIffStatusOptions();
    this.initEmpfAppStatusOptions();
    this.initDeclinedReasonOptions();
    this.getABMList();
    this.search();
  }

  getABMList() {
    this.cdHttpServeService.get<{ abm: string }[]>('/ext/eb-ssms-sales-journey-service/agent-branch/abm/list', {}).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          res.data.forEach(e => {
            this.abmOptionList.push({ label: e.abm, value: e.abm });
          });
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  private initIffStatusOptions() {
    this.iffStatusoptionList = eMPF_IFF_STATUS.map<CdsOption>(element => ({
      label: element.text,
      value: element.value,
    }));

    this.mdIffStatusCompleted = this.iffStatusoptionList.find(item => item.label === 'Completed');
    this.mdIffStatusDeclined = this.iffStatusoptionList.find(item => item.label === 'Declined');
  }

  private initEmpfAppStatusOptions() {
    this.empfAppStatusoptionList = EMPF_APP_STATUS.map<CdsOption>(element => ({
      label: element.text,
      value: element.value,
    }));
  }

  private initDeclinedReasonOptions() {
    this.declinedReasonOptionList = DECLINE_REASON.map<CdsOption>(element => ({
      label: element.text,
      value: element.value,
    }));
  }

  getDeclinedReasonLabel(declineReasons: number[]): string[] {
    const labelList: string[] = [];
    declineReasons.forEach(val => {
      const item = this.declinedReasonOptionList.find(item => item.value === val);
      if (item) {
        labelList.push(item.label);
      }
    });
    return labelList;
  }

  onResetDatePicker() {
    this.declarationDateFromMax = this.today;
    this.declarationDateToMin = new Date('2000/01/01');
    this.resetDatePicker = true;
    setTimeout(() => {
      this.resetDatePicker = false;
    }, 0);
  }

  iFFExpiryDateFromChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.declarationDateToMin = dateObject.toDate();
    }
  }

  iFFExpiryDateToChange(val: string) {
    const dateObject = moment(val, 'DD/MM/YYYY');
    if (dateObject.isValid()) {
      this.declarationDateFromMax = dateObject.toDate();
    }
  }

  onDisplayAdviseEmpf() {
    this.cdsPopup.open(EmpfAppStatusPopupComponent, {
      size: 'sm',
    });
  }

  get isDisableSearch() {
    return this.formGroup.invalid;
  }

  @HostListener('document:keydown.enter', ['$event'])
  onDocumentEnter(): void {
    this.onClickSearch();
  }
  onClickSearch() {
    if (this.isDisableSearch || this.isLoading) {
      return;
    }

    if (this.mdIffStatus.value) {
      if (this.mdIffStatus.value.length === 0) {
        this.quickSearchIffStatusSelected = QuickSearchIffStatus.All;
      } else if (this.mdIffStatus.value.length === 1 && this.mdIffStatus.value[0].value === this.mdIffStatusCompleted?.value) {
        this.quickSearchIffStatusSelected = QuickSearchIffStatus.Completed;
      } else if (this.mdIffStatus.value.length === 1 && this.mdIffStatus.value[0].value === this.mdIffStatusDeclined?.value) {
        this.quickSearchIffStatusSelected = QuickSearchIffStatus.Declined;
      } else {
        this.quickSearchIffStatusSelected = QuickSearchIffStatus.NONE;
      }
    }

    this.reqeuestParams.page = 1;
    this.search();
  }

  onClickReset() {
    this.formGroup.reset({
      abm: [],
      declineReason: [],
      mdIffStatus: [],
      empfStatus: [],
    });
    this.onResetDatePicker();
    this.quickSearchIffStatusSelected = QuickSearchIffStatus.All;

    this.reqeuestParams = {
      domain: 'SEP',
      page: 1,
      size: 30,
      productTypes: ['SEP'],
    };
    this.search();
  }

  private search() {
    this.reqeuestParams.declarationDateEnd = undefined;
    this.reqeuestParams.declarationDateStart = undefined;
    if (
      !this.declarationDateStart.hasError('dateInvalid') &&
      !this.declarationDateEnd.hasError('dateInvalid') &&
      !this.formGroup.hasError('dateRangeInvalidValidator')
    ) {
      this.reqeuestParams.declarationDateEnd = this.declarationDateEnd.value;
      this.reqeuestParams.declarationDateStart = this.declarationDateStart.value;
    }
    this.reqeuestParams.abm = this.abm.value?.map((item: { value: number }) => item.value);
    this.reqeuestParams.agentCode = this.agentCode.value;
    this.reqeuestParams.clientName = this.clientName.value;
    this.reqeuestParams.declineReason = this.declineReason.value?.map((item: { value: number }) => item.value);
    this.reqeuestParams.empfNo = this.empfNo.value;
    this.reqeuestParams.empfStatus = this.empfStatus.value?.map((item: { value: number }) => item.value);
    this.reqeuestParams.hkidOrPassportNo = this.hkidOrPassportNo.value;
    this.reqeuestParams.mdIffStatus = this.mdIffStatus.value?.map((item: { value: number }) => item.value);
    const params = getFilterParams(this.reqeuestParams);

    this.isLoading = true;
    this.cdHttpServeService
      .post<{
        content: EmpfSupportIndRecordItem[];
        totalElements: number;
        number: number;
        totalPages: number;
      }>('/ext/eb-ssms-sales-journey-service/empfIffSummaries/salesSupport', params)
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            res.data.content = res.data.content || [];
            this.currentPageNumOrigin = res.data.number;
            this.allPageData[this.currentPageIndex] = res.data.content;
            this.totalElements = res.data.totalElements;
            this.totalPages = res.data.totalPages;

            const currentAllPageData = this.allPageData.length;
            if (this.totalPages > currentAllPageData) {
              for (let i = 0; i < this.totalPages - currentAllPageData; i++) {
                this.allPageData.push([]);
              }
            } else if (this.totalPages < currentAllPageData) {
              for (let i = 0; i < currentAllPageData - this.totalPages; i++) {
                this.allPageData.pop();
              }
            }
          }
          this.isLoading = false;
        },
        error: err => {
          this.alert.error('Error!', err);
          this.isLoading = false;
        },
      });
  }

  onGenerateNewMDReferenceNo() {
    this.router.navigate([
      '/salesjourney/generate-md/select-product-type',
      {
        refNoType: RefNoType.EMPF,
        tabIndex: 1,
        prodType: SalesJourneyProdType.SEP,
      },
    ]);
  }

  checkIFFStatusTag(value: QuickSearchIffStatus) {
    this.quickSearchIffStatusSelected = value;
    switch (this.quickSearchIffStatusSelected) {
      case QuickSearchIffStatus.Completed:
        if (this.mdIffStatusCompleted != null) {
          this.mdIffStatus.setValue([this.mdIffStatusCompleted]);
        }
        break;
      case QuickSearchIffStatus.Declined:
        if (this.mdIffStatusDeclined != null) {
          this.mdIffStatus.setValue([this.mdIffStatusDeclined]);
        }
        break;

      default:
        this.mdIffStatus.setValue([]);
        break;
    }

    this.reqeuestParams.page = 1;
    this.search();
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(IffStatusFlowPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseRef() {
    this.cdsPopup.open(AdviseEmpfrefComponent, {
      size: 'sm',
    });
  }

  pageChange(num: number): void {
    pageToTop();
    this.reqeuestParams.page = num;
    this.search();
  }

  onGoCaseDetails(empfNo: string) {
    this.router.navigate([
      '/salesjourney/empf-case-details',
      empfNo,
      {
        refNoType: RefNoType.EMPF,
        tabIndex: 1,
        prodType: SalesJourneyProdType.SEP,
      },
    ]);
  }

  hkidOrPassportNoChange(event: string) {
    const value = this.hkidOrPassportNo.value.replace(/[\u4e00-\u9fa5]/gi, '');
    if (event !== value) {
      this.hkidOrPassportNo.setValue(value);
    }
  }
}
