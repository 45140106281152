<div class="container-fluid p-0">
  <div class="card">
    <div class="card-header-tab card-header space-content" style="justify-content: space-between">
      <div class="card-header-title font-size-lg text-capitalize fw-normal">Close Chat</div>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
      <div class="modal-body">
        <div class="form-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Policy No:</label>
                <input type="text" class="form-control" formControlName="policyNumber" />
                <div *ngIf="form.controls['policyNumber'].touched && !form.controls['policyNumber'].valid">
                  <div *ngFor="let validation of validationMessages.policyNumber" class="has-danger text-start">
                    <span class="form-control-feedback" *ngIf="form.controls['policyNumber'].hasError(validation.type)">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Cert. No:</label>
                <input type="text" class="form-control" formControlName="certificateNumber" />
                <div *ngIf="form.controls['certificateNumber'].touched && !form.controls['certificateNumber'].valid">
                  <div *ngFor="let validation of validationMessages.certificateNumber" class="has-danger text-start">
                    <span class="form-control-feedback" *ngIf="form.controls['certificateNumber'].hasError(validation.type)">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="required">Type:</label>
                <ng-select
                  class="font-small-1"
                  [multiple]="false"
                  [items]="typeDDL"
                  bindLabel="text"
                  formControlName="type"
                  bindValue="id"
                  placeholder="Choose One Option"
                  [clearable]="true">
                </ng-select>
                <div *ngIf="form.controls['type'].touched && !form.controls['type'].valid">
                  <div *ngFor="let validation of validationMessages.type" class="has-danger text-start">
                    <span class="form-control-feedback" *ngIf="form.controls['type'].hasError(validation.type)">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="">Sub-Type:</label>
                <ng-select
                  class="font-small-1"
                  [multiple]="false"
                  [items]="subtypeDDL"
                  bindLabel="text"
                  formControlName="subType"
                  bindValue="id"
                  placeholder="Choose One Option"
                  [clearable]="true">
                </ng-select>
                <div *ngIf="form.controls['subType'].touched && !form.controls['subType'].valid">
                  <div *ngFor="let validation of validationMessages.subType" class="has-danger text-start">
                    <span class="form-control-feedback" *ngIf="form.controls['subType'].hasError(validation.type)">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="">Enquiry Type:</label>
                <ng-select
                  class="font-small-1"
                  [multiple]="false"
                  [items]="enquiryDDL"
                  bindLabel="text"
                  formControlName="enquiryType"
                  bindValue="id"
                  placeholder="Choose One Option"
                  [clearable]="true">
                </ng-select>
                <div *ngIf="form.controls['enquiryType'].touched && !form.controls['enquiryType'].valid">
                  <div *ngFor="let validation of validationMessages.enquiryType" class="has-danger text-start">
                    <span class="form-control-feedback" *ngIf="form.controls['enquiryType'].hasError(validation.type)">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="">Contact Name:</label>
                <input type="text" class="form-control" formControlName="contactName" />
                <div *ngIf="form.controls['contactName'].touched && !form.controls['contactName'].valid">
                  <div *ngFor="let validation of validationMessages.contactName" class="has-danger text-start">
                    <span class="form-control-feedback" *ngIf="form.controls['contactName'].hasError(validation.type)">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Status:</label>
                <ng-select
                  class="font-small-1"
                  [multiple]="false"
                  [items]="statusDDL"
                  bindLabel="text"
                  formControlName="status"
                  bindValue="id"
                  placeholder="Choose One Option"
                  [clearable]="true">
                </ng-select>
                <div *ngIf="form.controls['status'].touched && !form.controls['status'].valid">
                  <div *ngFor="let validation of validationMessages.status" class="has-danger text-start">
                    <span class="form-control-feedback" *ngIf="form.controls['status'].hasError(validation.type)">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Description:</label>
                <textarea [rows]="3" class="form-control" formControlName="description"></textarea>
                <div *ngIf="form.controls['description'].touched && !form.controls['description'].valid">
                  <div *ngFor="let validation of validationMessages.description" class="has-danger text-start">
                    <span class="form-control-feedback" *ngIf="form.controls['description'].hasError(validation.type)">
                      {{ validation.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <cds-button [disabled]="loading" [size]="'sm'" [style]="'secondary'" (click)="activeModal.dismiss('Cross click')">Cancel</cds-button>
        <cds-button
          [type]="'submit'"
          [ngClass]="{ 'm-loader m-loader--right m-loader--info': loading }"
          [disabled]="loading || !form.valid"
          [size]="'sm'"
          [style]="'primary'"
          >Save</cds-button
        >
      </div>
    </form>
  </div>
</div>
