import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChatService } from 'src/app/views/chatbot/_service/chat.service';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
})
export class ChatListComponent implements OnInit {
  tab = 0;
  activeChat: any = [];
  pendingChat: any = [];
  closeChat: any = [];
  closeChatFilter: any = [];
  botChat: any = [];
  loading: boolean = false;
  count = 0;
  rows = [];
  activheaders = [
    { key: 'agent', title: 'CSO' },
    { key: 'userc', title: 'AGENT' },
    { key: 'liveChatDuration', title: 'Live chat duration(Minutes)' },
    { key: 'status', title: 'Status' },
  ];

  pendingheaders = [
    { key: 'agent', title: 'CSO' },
    { key: 'userc', title: 'AGENT' },
    { key: 'waitingDuration', title: 'Waiting Duration(Minutes)' },
    { key: 'status', title: 'Status' },
  ];
  closeheaders = [
    { key: 'agent', title: 'CSO' },
    { key: 'userc', title: 'AGENT' },
    { key: 'liveChatDuration', title: 'Live chat duration(Minutes)' },
    { key: 'status', title: 'Status' },
  ];
  botheaders = [
    { key: 'userc', title: 'AGENT' },
    { key: 'lastTopic', title: 'Last topic enquired' },
    { key: 'waitingDuration', title: 'Live chat duration(Minutes)' },
  ];
  constructor(public chatService: ChatService, public cdk: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getActiveChatList();
  }
  tabChange(item: any) {
    this.tab = item.index;
    if (item.index == 0) {
      this.getActiveChatList();
    }
    if (item.index == 1) {
      this.getPendingChatList();
    }
    if (item.index == 2) {
      this.getCloseChatList();
    }
    if (item.index == 3) {
      // this. = true
      this.getBotChatList();
    }
  }

  getActiveChatList() {
    this.chatService.getActiveChatList().subscribe(resp => {
      if (resp) {
        this.rows = resp;
        this.count = this.rows.length;
      }
    });
  }

  getPendingChatList() {
    this.chatService.getPendingChatList().subscribe(resp => {
      if (resp) {
        this.rows = resp;
        this.count = this.rows.length;
        this.cdk.detectChanges();
      }
    });
  }

  getCloseChatList() {
    this.chatService.getCloseChatList().subscribe(resp => {
      if (resp) {
        this.rows = resp;
        this.count = this.rows.length;
        this.cdk.detectChanges();
      }
    });
  }
  getBotChatList() {
    this.chatService.getBotChatList().subscribe(resp => {
      if (resp) {
        this.rows = resp;
        this.count = this.rows.length;
        this.cdk.detectChanges();
      }
    });
  }

  findUser(user: any, type: any) {
    if (type == 1) {
      let username = user.find((item: any) => item.type == 'CUSTOMER_SERVICING_AGENT')?.name;
      return username ? username : '-';
    } else if (type == 0) {
      let username = user.find((item: any) => item.type != 'CUSTOMER_SERVICING_AGENT')?.name;
      return username ? username : '-';
    }
  }
}
