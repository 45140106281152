import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import moment from 'moment';
import { finalize } from 'rxjs';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { AdminHeaderConfig } from '../../layout/admin-layout/admin-header/admin-header-config';
import {
  AccountTypeE,
  ContactInfo,
  employeeStatusMapObj,
  EmployeeTypeE,
  EmployeeTypeMap,
  GenderE,
  GenderMap,
  RelatedPolicy,
  SchemeCodeE,
  SchemeCodeMap,
} from '../employee';
import { EmployeeService } from '../employee.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { pageToTop } from 'src/app/utils/utils';
import { BusinessNatureE, businessNatureMapObj } from '../../employer/employer';

@Component({
  selector: 'app-related-policy-detail',
  templateUrl: './related-policy-detail.component.html',
  styleUrls: ['./related-policy-detail.component.scss'],
})
export class RelatedPolicyDetailComponent implements OnInit {
  headerButton: AdminHeaderConfig = {
    title: 'ee.profile.detail.plus.title',
    backButton: {
      text: 'Member Profile Overview',
      url: '#',
      method: this.goBack.bind(this),
    },
  };

  headerButtonForAgent: AdminHeaderConfig = {
    title: 'ee.profile.detail.plus.title',
    backButton: {
      text: 'Member Enquiry Information Summary',
      url: '#',
      method: this.goBackForAgent.bind(this),
    },
  };

  id = '';

  md5 = '';

  empfId = '';

  isStaff = false;

  permissionChecked = false;

  typeChecked = false;

  withMa = false;

  detailData: RelatedPolicy = {
    md5: '',
  };

  employeeStatusMapObj = employeeStatusMapObj;

  dropdownConfig: CdsDropdownConfig = {
    label: 'Choose a policy',
    options: [],
  };

  isLoading = false;

  data: RelatedPolicy = {
    md5: '',
  };

  accountType?: AccountTypeE;

  AccountTypeE = AccountTypeE;

  tabIndex = 0;

  activedTabList: string[] = [];

  tabList: string[] = [];

  tabReload = true;

  constructor(
    private layoutHeaderService: LayoutHeaderService,
    public service: EmployeeService,
    private route: ActivatedRoute,
    private router: Router,
    private toastAlert: ToastAlertService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.queryParams['id'];
    this.empfId = this.route.snapshot.queryParams['empfId'];
    this.permissionFilter();
    this.getRelatedPolicies();
    this.getDetail();

    pageToTop();

    // todo
    // this.changeTab();
  }

  getTablist() {
    if (this.isStaff) {
      return [
        'policyDetails',
        ...this.tabExist([AccountTypeE.CA, AccountTypeE.SEP], 'employementInfo'),
        ...this.tabExist([AccountTypeE.PA, AccountTypeE.CA, AccountTypeE.TVC, AccountTypeE.FRC], 'agentInfo'),
        'fundInfo',
        ...this.tabExist([AccountTypeE.CA, AccountTypeE.SEP], 'contributionRecords'),
        ...this.tabExist([AccountTypeE.CA, AccountTypeE.SEP], 'defaultContribution'),
        'transfer',
        'withDrawal',
        'termination',
        'empfWorkflow',
      ];
    } else {
      return ['fundInfo'];
    }
  }

  getGender(item: string | undefined) {
    switch (item) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      case 'U':
        return 'Unisex';
      case 'O':
        return 'No Such Information';
      default:
        return item;
    }
  }

  onSelectChange(val: string) {
    this.tabReload = false;

    setTimeout(() => {
      this.tabReload = true;
      this.tabIndex = 0;
    });

    this.activedTabList = [...this.activedTabList];

    this.id = val;
    this.getDetail();
  }

  getRelatedPolicies() {
    this.isLoading = true;
    if (!this.id) {
      this.id = '';
    }
    this.service
      .getRelatedPolicies(this.id, '')
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dropdownConfig.options = data.data.map(item => {
            return {
              label: `${item.memberAccountType}:${item.id}`,
              value: item.id,
            };
          });
          this.dropdownConfig = { ...this.dropdownConfig };
        } else {
          this.toastError(data.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  goBack() {
    this.router.navigate(['/employee/profile'], {
      queryParams: { id: this.id },
    });
  }

  goBackForAgent() {
    this.router.navigate(['/employee/list']);
  }

  getDetail() {
    if (!this.id) return;
    this.isLoading = true;
    this.service
      .getPolicyBasicInfo(this.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0 && res.data) {
          this.detailData = res.data;
          this.accountType = this.service.accountTypeTrans(res.data?.memberAccountType);
          this.typeChecked = true;

          this.tabList = this.getTablist();
          this.selectedTabIndexChange(this.tabIndex);
        } else {
          this.toastError(res.message);
        }
      });
  }

  updateDate(date?: string) {
    if (!date) {
      return '';
    }
    return moment(date).utcOffset(-8).format('h:mma DD MMM, YYYY');
  }

  permissionFilter() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYEE).then(res => {
      this.isStaff = res;
      this.permissionChecked = true;

      this.layoutHeaderService.setHeaderButton(this.isStaff ? this.headerButton : this.headerButtonForAgent);
    });
  }

  gotoPool(commissionPoolId: string | undefined) {
    this.router.navigate(['/commission/pool-info'], {
      queryParams: { commissionPoolId },
    });
  }

  schemeCodeRender(type?: SchemeCodeE) {
    if (type === undefined) return '';
    return SchemeCodeMap.get(type) || type || '';
  }

  genderRender(type?: GenderE) {
    if (!type) return '';
    return GenderMap[type] || '';
  }

  employeeTypeRender(type?: EmployeeTypeE) {
    if (!type) return '';
    return EmployeeTypeMap[type] || '';
  }

  businessNatureRender(type?: BusinessNatureE) {
    if (!type) return '';
    return businessNatureMapObj[type] || '';
  }

  occupationJobPosition(data?: RelatedPolicy) {
    if (!data) return '';
    return [data?.occupation, data?.jobPosition].filter(item => !!item).join(',');
  }

  contactDataChange(data: ContactInfo) {
    if (data && data.memberAuthorization === 'Y') {
      this.withMa = true;
    } else {
      this.withMa = false;
    }
  }

  // TODO
  changeTab() {
    const tab = this.route.snapshot.queryParams['tab'] || '';
    const index = this.tabList.indexOf(tab);
    if (index > -1) {
      this.tabIndex = index;
      const urlTree = this.router.parseUrl(this.router.url);
      urlTree.queryParams['tab'] = '';

      this.router.navigateByUrl(urlTree);
    }
  }

  show(allowedList: AccountTypeE[], type: AccountTypeE) {
    return allowedList.includes(type);
  }

  tabExist(allowedList: AccountTypeE[], tab: string) {
    const ifAllow = allowedList.includes(this.accountType as AccountTypeE);
    if (ifAllow) {
      return [tab];
    }
    return [];
  }

  selectedTabIndexChange(value: number) {
    this.tabIndex = value;
    const tab = this.tabList[value];
    this.activedTabList.push(tab);
    this.activedTabList = [...this.activedTabList];
    console.log(this.tabIndex);
  }

  ifLoadTab(activedTabList: string[], tab: string) {
    if (activedTabList.includes(tab)) return true;
    return false;
  }

  selectChange() {
    const tab = this.tabList[this.tabIndex];
    this.activedTabList = [tab];
  }
}
