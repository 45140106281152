<cds-popup class="relative">
  <app-loading *ngIf="_loadding" [overlayInPopup]="true"></app-loading>
  <cds-popup-title>{{ 'dividend-rate.addLable' | lang }}</cds-popup-title>
  <cds-popup-content>
    <div class="row l-plr-0 l-pt-4">
      <div class="l-pb-2">
        <span class="cds-body2-ita">{{ 'interest-rate.interest-rate' | lang }}</span>
      </div>
    </div>
    <div class="row col-xs-12 l-pb-4 l-plr-0 interest-rate">
      <table>
        <tr>
          <td class="border-right"></td>
          <td><span class="cds-body2-demibold">Month/ Year</span></td>
          <td><span class="cds-body2-demibold">Dividend/ Unit</span></td>
          <td><span class="cds-body2-demibold">Record Date</span></td>
          <td style="width: 60px"></td>
        </tr>
        <tr *ngFor="let item of _addList; let i = index" class="div-bg-light-grey">
          <td class="border-right">
            <span>{{ i + 1 }}</span>
          </td>
          <td>
            <div class="row l-plr-0">
              <div class="l-pr-2 select-width">
                <cds-dropdown [(ngModel)]="item.month" (change)="inputChange($event, 'month', item, i)" [config]="monthConfig"></cds-dropdown>
              </div>
              <div class="l-pr-2 select-width">
                <cds-dropdown [(ngModel)]="item.year" (change)="inputChange($event, 'year', item, i)" [config]="yearConfig"></cds-dropdown>
              </div>
            </div>
          </td>
          <td>
            <cds-textfield
              [disabled]="item.status !== 'Approved'"
              placeholder="0.00 %"
              [(ngModel)]="item.dividendRate"
              type="number"
              (ngModelChange)="inputChange(normalRate.value, 'dividendRate', item, i)"
              #normalRate></cds-textfield>
          </td>
          <td>
            <span *ngIf="item.recordDate">{{ item.recordDate | ssmsDate }}</span>
          </td>
          <td>
            <cds-icon class="pointer" (click)="removeRow(i)" icon="form:delete"></cds-icon>
          </td>
        </tr>
      </table>
      <div (click)="addRow()" class="row col-xs-12 pointer middle-xs l-pt-4">
        <div class="l-pr-2">
          <cds-icon style="--cds-icon-color: var(--cds-bg-cta-primary)" icon="action:button_plus_filled"></cds-icon>
        </div>
        <div>
          <span class="cds-body2-demibold">Add New</span>
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions class="l-pt-4 l-pb-2">
    <cds-button [fullWidth]="true" class="button-width" label="{{ 'common.reset' | lang }}" [config]="resetConfig" (click)="reset()"></cds-button>
    <cds-button
      [fullWidth]="true"
      [disabled]="!_valid"
      class="button-width"
      label="{{ 'common.continue' | lang }}"
      [config]="saveConfig"
      (click)="save()"></cds-button>
  </cds-popup-actions>
</cds-popup>
