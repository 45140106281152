<div class="row">
  <div class="col-xs-12 l-mt-7 l-mb-2 -plr-0">
    <cds-tab-group type="box" [(selectedIndex)]="_index" (selectedTabIndexChange)="selectedTabIndexChange($event)">
      <cds-tab label="Interest Rate">
        <app-interest-rate *ngIf="_index === 0"></app-interest-rate>
      </cds-tab>
      <cds-tab label="Dividend Rate">
        <app-dividend-rate *ngIf="_index === 1"></app-dividend-rate>
      </cds-tab>
      <cds-tab label="Unit Price">
        <app-unit-price *ngIf="_index === 2"></app-unit-price>
      </cds-tab>
    </cds-tab-group>
  </div>
</div>
