/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize, forkJoin, Subscription } from 'rxjs';

import { CdsButtonConfig } from '@cds/ng-core/button';
import { BTN_CONFIG_SECONDARY } from 'src/app/config/btn.config';

import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { UserAgreementPopupComponent } from '../user-agreement-popup.component';

import { deepCopy } from 'src/app/utils/copy';
import { toISODate, fromISOMonthYear, diffMonths, getMonthFirstByMonthYear, getMonthLastByMonthYear, getMonthLastByNow } from 'src/app/utils/dateUtils';
import { CommissionPoolService } from 'src/app/core/services/commission/commission-pool.service';
import { CommissionPoolScaleService } from 'src/app/core/services/commission/commission-pool-scale.service';
import { CommissionRelatedPartiesService } from 'src/app/core/services/commission/commission-related-parties.service';
import { CommissionAgentService } from 'src/app/core/services/commission/commission-agent.service';

import { CommissionCommonService } from 'src/app/core/services/commission/commission-common.service';
import { AgentChannel } from 'src/app/core/models/enum/agent-channel.enum';
import { PermissionItem, PermissionAccess } from 'src/app/core/models/enum/permission.enum';
import { COMMISSION_POOL_TAB, RELEATE_PAYTY_RELEASE_TAG, texts } from './pool-info.model';
import { AccumulationType } from 'src/app/core/models/enum/accumulation-type.enum';
import { str2Obj } from 'src/app/utils/utils';
import Big from 'big.js';
import { AccountType, CommissionPoolStatus } from 'src/app/core/models/enum';
import moment from 'moment';

@Component({
  selector: 'app-commission-pool-info',
  templateUrl: './pool-info.component.html',
  styleUrls: ['./pool-info.component.scss'],
})
export class CommissionPoolInfoComponent implements OnInit, OnDestroy {
  btnCfg: CdsButtonConfig = BTN_CONFIG_SECONDARY;

  tab = COMMISSION_POOL_TAB;

  role = PermissionAccess.W;
  permission = PermissionItem.COMM_INFO_MAIN_COMM_SUMMARY;

  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;

  commissionPoolId = '';

  constructor(
    private cdsPopup: CdsPopupService,
    private router: Router,
    private route: ActivatedRoute,
    private poolService: CommissionPoolService,
    private poolScaleService: CommissionPoolScaleService,
    private relatedPartiesService: CommissionRelatedPartiesService,
    private commonService: CommissionCommonService,
    private agentService: CommissionAgentService
  ) {
    this.subscription = commonService.relatedParties$.subscribe(() => {
      this.getPoolInfo();
    });
    this.subscription2 = commonService.scale$.subscribe(() => {
      this.getPoolInfo();
    });
    this.subscription3 = commonService.applySummary$.subscribe(() => {
      this.commonService.announceSummary(this.summary);
    });
  }

  ngOnInit(): void {
    const tabType = this.route.snapshot.queryParams['tabType'];
    this.commissionPoolId = this.route.snapshot.queryParams['commissionPoolId'];
    if (tabType) {
      this.tabClick({ value: tabType });
    }
    if (!this.commissionPoolId) {
      this.backLanding();
    }
    this.getPoolInfo();
  }

  getPoolInfo() {
    this.editStatus = false;
    this.poolService.getById(this.commissionPoolId).subscribe(resp => {
      if (resp && resp.data && resp.result == 0) {
        this.summary = resp.data;
        this.commonService.announceSummary(this.summary);
        const firstContributionMonth = moment(this.summary.firstContributionMonth, 'YYYY-MM-DD').add(12, 'months').add(30, 'days');
        const monthsDays = moment().diff(firstContributionMonth, 'days');
        if (monthsDays > 0) {
          this.isNextYearCommStartDisable = false;
        }
      }
      this.loading = false;
    });
  }

  editStatus = false;
  saveDisabled = true;
  loading = true;
  disableInput = false;
  isConfirm = false;
  fcMonthDisabledEditScenario = [AccountType.MPF_FRC, AccountType.MPF_PAP, AccountType.MACAU_PAP];
  relatedParties: any[] = [];
  mainParty: any;
  fcMonthChange = false;
  fcMonthError = '';
  isReleased = false;
  fcMonthAcctTyError = false;
  // fcMonthNotEdit = '';
  fcMonthWarning = '';
  frozenError = '';
  wmBonusError = '';
  isNextYearCommStartDisable = true;
  nextCommYearStartError = '';

  summary = {
    id: '',
    commencementDate: '',
    firstContributionMonth: '',
    nextCommYearStart: '',
    caseCount: '',
    frozen: true,
    waiveMonthlyBonus: true,
    oneStPc: '',
    reconHitDate: '',
    mainParty: '',
    subscriptionFee: '',
  };
  summaryForm: any = {};

  edit() {
    this.summaryForm = deepCopy(this.summary);
    this.summaryForm.firstContributionMonth = fromISOMonthYear(this.summaryForm.firstContributionMonth);
    this.summaryForm.nextCommYearStart = fromISOMonthYear(this.summaryForm.nextCommYearStart);
    this.fcMonthError = '';
    this.isReleased = false;
    this.fcMonthAcctTyError = false;
    // this.fcMonthNotEdit = '';
    this.nextCommYearStartError = '';
    this.frozenError = '';
    this.wmBonusError = '';
    this.disableInput = false;
    this.editStatus = true;
    this.isConfirm = false;
    this.saveDisabled = true;
    this.initFormStatus();
    // this.poolScaleService.getScaleRecordCurrentInfo(this.commissionPoolId).subscribe(res => {
    //   this.loading = false;
    //   if (res) {
    //     const resp = str2Obj(res.body, ['amount']);
    //     if (resp && resp.result == 0 && resp.data && resp.data.commissionPoolScaleAccumulations) {
    //       const accumulations: any[] = resp.data.commissionPoolScaleAccumulations;
    //       accumulations.forEach(accumulation => {
    //         if (accumulation) {
    //           const type = accumulation.scaleAccumulationType;
    //           const amount = new Big(accumulation.amount);
    //           const zero = new Big(0);
    //           if (type == AccumulationType.PREMIUM_PTD && !amount.eq(zero)) {
    //             this.disableInput = true;
    //             // this.fcMonthNotEdit = texts.notEditReleased;
    //           }
    //         }
    //       });
    //     }
    //   }
    //   if (!this.disableInput) {
    //     this.loading = true;
    //     this.relatedPartiesService.typesValidate(this.commissionPoolId, 'fcMonthNotEdit').then(res => {
    //       this.loading = false;
    //       this.editStatus = true;
    //       if (!res.validate) {
    //         this.disableInput = true;
    //         // this.fcMonthNotEdit = res.msg;
    //       }
    //       if(res.validate && res.relatedParties) {
    //         res.relatedParties.forEach((item:any) =>{
    //           if(item.mainParty && !item.firstContributionDate && item.sysAdjustmentDate !== '9999-12-31') {
    //             this.fcMonthWarning = texts.fcMonthWaring;
    //           }
    //         });
    //       }
    //     });
    //   }
    // });
  }

  initFormStatus() {
    this.loading = true;
    const arr = [];
    arr.push(this.poolScaleService.getScaleRecordCurrentInfo(this.commissionPoolId));
    arr.push(this.relatedPartiesService.list({ commissionPoolId: this.commissionPoolId }));
    forkJoin(arr)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res: Array<any>) => {
        const scaleRecordInfo = str2Obj(res[0].body, ['amount']);
        this.scaleValidator(scaleRecordInfo);
        const relatedPartiesInfo = str2Obj(res[1].body, ['contAdjPend']);
        this.relatedPartiesValidator(relatedPartiesInfo);
      });
  }

  scaleValidator(scaleRecordRes: any) {
    if (scaleRecordRes && scaleRecordRes.result == 0 && scaleRecordRes.data && scaleRecordRes.data.commissionPoolScaleAccumulations) {
      const accumulations: any[] = scaleRecordRes.data.commissionPoolScaleAccumulations;
      accumulations.forEach(accumulation => {
        if (accumulation) {
          const type = accumulation.scaleAccumulationType;
          const amount = new Big(accumulation.amount);
          const zero = new Big(0);
          if (type == AccumulationType.PREMIUM_PTD && !amount.eq(zero)) {
            this.isReleased = true;
          }
        }
      });
    }
  }

  relatedPartiesValidator(relatedPartiesRes: any) {
    if (relatedPartiesRes && relatedPartiesRes.result == 0 && relatedPartiesRes.data && relatedPartiesRes.data.content) {
      const content: any[] = relatedPartiesRes.data.content;
      const convert = this.relatedPartiesService.convertList(content, null);
      this.relatedParties = convert.list.filter((item: any) => item.active);
      this.mainParty = this.relatedParties.find(item => item.mainParty);
      const hasApeDependAccountRelatedParty = this.relatedParties.find(i => !i.mainParty && i.apeDependAccount);
      this.relatedParties.forEach((item: any) => {
        if (this.fcMonthDisabledEditScenario.indexOf(item.accountType) !== -1) {
          this.fcMonthAcctTyError = true;
          // this.disableInput = true;
        }

        if (
          !item.mainParty &&
          ((!this.mainParty.firstContributionDate && this.mainParty.sysAdjustmentDate !== RELEATE_PAYTY_RELEASE_TAG) ||
            (hasApeDependAccountRelatedParty && item.sysAdjustmentDate !== RELEATE_PAYTY_RELEASE_TAG))
        ) {
          this.fcMonthWarning = texts.fcMonthWaring;
        }
        if (item.mainParty && item.commencementDate) {
          const now = moment().format('MM/YYYY');
          const commencementDate = moment(item.commencementDate, 'YYYY-MM-DD').format('MM/YYYY');
          const monthsDiff = moment(now, 'MM/YYYY').diff(moment(commencementDate, 'MM/YYYY'), 'months');
          if (monthsDiff > 6) {
            this.disableInput = true;
          }
        }
      });
    }
  }

  monthYearReg = /(^(1)[0-2]{1}\/(20)(\d){2})|(^(0)[1-9]{1}\/(20)(\d){2})$/;
  // input(event: any) {
  //   this.fcMonthError = '';
  //   const obj = <HTMLInputElement>document.getElementById('firstContributionMonth');
  //   if (obj) {
  //     const value = event.target.value.match(
  //       /(^(1)[0-2]{1}\/(20)(\d){1,2})|(^(0)[1-9]{1}\/(20)(\d){1,2})|(^(1)[0-2]{1}\/(2)?(0)?)|(^(0)[1-9]{1}\/(2)?(0)?)|(^(0)[1-9]{1})|(^(1)[0-2]{1})|(^[0-1]{1})/g
  //     );
  //     obj.value = value ? value : '';
  //     this.summaryForm.firstContributionMonth = String(obj.value);
  //     const pos = event.target.selectionEnd;
  //     event.target.setSelectionRange(pos, pos);
  //     if (this.monthYearReg.test(value)) {
  //       this.change('fcMonth');
  //     } else {
  //       this.saveDisabled = true;
  //     }
  //   }
  // }

  monthYearInput(event: any, key: string) {
    const obj = <HTMLInputElement>document.getElementById(key);
    if (obj) {
      const value = event.target.value.match(
        /(^(1)[0-2]{1}\/(20)(\d){1,2})|(^(0)[1-9]{1}\/(20)(\d){1,2})|(^(1)[0-2]{1}\/(2)?(0)?)|(^(0)[1-9]{1}\/(2)?(0)?)|(^(0)[1-9]{1})|(^(1)[0-2]{1})|(^[0-1]{1})/g
      );
      obj.value = value ? value : '';
      if (key === 'firstContributionMonth') {
        this.summaryForm.firstContributionMonth = String(obj.value);
      } else if (key === 'nextCommYearStart') {
        this.summaryForm.nextCommYearStart = String(obj.value);
      }
      const pos = event.target.selectionEnd;
      event.target.setSelectionRange(pos, pos);
      if (obj.value) {
        this.change(key);
      }
    }
  }

  change(field?: string) {
    const originalValue = fromISOMonthYear(this.summary.firstContributionMonth);
    const originalNextCommYearStart = fromISOMonthYear(this.summary.nextCommYearStart);
    switch (field) {
      case 'firstContributionMonth':
        this.fcMonthError = '';
        if (
          this.summaryForm.firstContributionMonth &&
          this.monthYearReg.test(this.summaryForm.firstContributionMonth) &&
          originalValue !== this.summaryForm.firstContributionMonth
        ) {
          // const now = moment().format('MM/YYYY');
          const commencementDate = moment(this.summaryForm.commencementDate, 'YYYY-MM-DD').format('MM/YYYY');
          // const originalFcMonth = moment(this.summary.firstContributionMonth, 'YYYY-MM-DD').format('MM/YYYY');
          const laterSixMonth = moment(this.summaryForm.firstContributionMonth, 'MM/YYYY').diff(moment(commencementDate, 'MM/YYYY'), 'months');
          // const laterSixMonth = diffMonths(this.summaryForm.commencementDate, getMonthLastByMonthYear(this.summaryForm.firstContributionMonth));
          const backSystemMonth = diffMonths(getMonthLastByNow(), getMonthLastByMonthYear(this.summaryForm.firstContributionMonth));
          const backFirstContributionMonth = diffMonths(this.summary.firstContributionMonth, getMonthLastByMonthYear(this.summaryForm.firstContributionMonth));
          if (this.fcMonthAcctTyError) {
            this.fcMonthError = texts.fcMonthAccountTypeError;
          } else if (this.isReleased) {
            this.fcMonthError = texts.notEditReleased;
          } else if (backFirstContributionMonth && (backFirstContributionMonth.months < 0 || backFirstContributionMonth.days < 0)) {
            this.fcMonthError = texts.notEditBackDay;
          } else if (laterSixMonth > 6) {
            this.fcMonthError = texts.fcMonthLaterSix;
          } else if (laterSixMonth < 0) {
            this.fcMonthError = texts.notEditBackDay;
          } else if (backSystemMonth && backSystemMonth.months < 0) {
            this.fcMonthError = texts.notEditBackDay;
          }
        }
        break;
      case 'nextCommYearStart':
        this.nextCommYearStartError = '';
        if (
          this.summaryForm.nextCommYearStart &&
          this.monthYearReg.test(this.summaryForm.nextCommYearStart) &&
          originalNextCommYearStart !== this.summaryForm.nextCommYearStart
        ) {
          const now = moment().format('MM/YYYY');
          const monthsDiff = moment(now, 'MM/YYYY').diff(moment(this.summaryForm.nextCommYearStart, 'MM/YYYY'), 'months');
          if (monthsDiff >= 0) {
            this.nextCommYearStartError = texts.nextCommYearBackDay;
          } else if (monthsDiff < -18) {
            this.nextCommYearStartError = texts.nextCommYearLater;
          }
        }
        break;
      case 'frozen':
        this.frozenError = '';
        if (this.summaryForm.frozen) {
          this.loading = true;
          this.relatedPartiesService.typesValidate(this.commissionPoolId, 'frozen').then(res => {
            this.loading = false;
            if (!res.validate) {
              this.frozenError = res.msg;
            }
            this.changeDisable();
          });
        }
        break;
      case 'wmBonus':
        this.wmBonusError = '';
        if (this.summaryForm.waiveMonthlyBonus) {
          this.loading = true;
          this.relatedPartiesService.typesValidate(this.commissionPoolId, 'wmBonus').then(res => {
            if (!res.validate) {
              this.loading = false;
              this.wmBonusError = res.msg;
              this.changeDisable();
            } else {
              this.agentBroker();
            }
          });
        }
        break;
    }
    this.changeDisable();
  }

  agentBroker() {
    this.agentService.listRegular(this.commissionPoolId).then(res => {
      const resp: any = res;
      const wmBonusError = 'Claw Back / Waive Bonus is Y, but commission agent isn’t Broker/IA Broker channel';
      if (resp && resp.recordList) {
        const recordList: any[] = resp.recordList;
        if (recordList.length) {
          let isAllPrevious = true;
          recordList.forEach(item => {
            if (item.status == CommissionPoolStatus.CURRENT || item.status == CommissionPoolStatus.FUTURE) {
              isAllPrevious = false;
              const param = {
                id: item.id,
                commissionPoolId: this.commissionPoolId,
              };
              this.agentService.getRegularItems(param).subscribe(res2 => {
                this.loading = false;
                if (res2 && res2.result == 0 && res2.data.content) {
                  const agentItems: any[] = res2.data.content;
                  agentItems.forEach(agent => {
                    if (agent.type != AgentChannel.BROKER && agent.type != AgentChannel.IA_BROKER) {
                      this.wmBonusError = wmBonusError;
                    }
                  });
                }
                this.changeDisable();
              });
            }
          });
          if (isAllPrevious) {
            this.loading = false;
            this.wmBonusError = wmBonusError;
          }
        } else {
          this.loading = false;
          this.wmBonusError = wmBonusError;
        }
      }
    });
  }

  changeDisable() {
    const summary = deepCopy(this.summary);
    summary.firstContributionMonth = fromISOMonthYear(this.summary.firstContributionMonth);
    summary.nextCommYearStart = fromISOMonthYear(this.summary.nextCommYearStart);
    this.fcMonthChange = summary.firstContributionMonth !== this.summaryForm.firstContributionMonth;
    if (
      this.monthYearReg.test(this.summaryForm.firstContributionMonth) &&
      this.monthYearReg.test(this.summaryForm.nextCommYearStart) &&
      !this.nextCommYearStartError &&
      !this.fcMonthError &&
      !this.frozenError &&
      !this.wmBonusError &&
      JSON.stringify(summary) != JSON.stringify(this.summaryForm)
    ) {
      this.saveDisabled = false;
    } else {
      this.saveDisabled = true;
    }
  }

  cancelEdit() {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        message: texts.cancelMsg,
        cancel: texts.cancel,
        continue: texts.continue,
        type: 'confirm',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree) {
        this.summaryForm = deepCopy(this.summary);
        this.summaryForm.firstContributionMonth = fromISOMonthYear(this.summaryForm.firstContributionMonth);
        this.editStatus = false;
        this.disableInput = false;
        this.fcMonthError = '';
      }
    });
  }

  reset() {
    this.summaryForm = deepCopy(this.summary);
    this.summaryForm.firstContributionMonth = fromISOMonthYear(this.summaryForm.firstContributionMonth);
    this.summaryForm.nextCommYearStart = fromISOMonthYear(this.summaryForm.nextCommYearStart);
    this.fcMonthError = '';
    this.frozenError = '';
    this.wmBonusError = '';
    this.change();
  }

  save() {
    if (this.saveDisabled) {
      return;
    }
    this.summaryForm.firstContributionMonth = toISODate(getMonthFirstByMonthYear(this.summaryForm.firstContributionMonth));
    this.summaryForm.nextCommYearStart = toISODate(getMonthFirstByMonthYear(this.summaryForm.nextCommYearStart));
    this.isConfirm = true;
  }

  backToEdit() {
    this.summaryForm.firstContributionMonth = fromISOMonthYear(this.summaryForm.firstContributionMonth);
    this.summaryForm.nextCommYearStart = fromISOMonthYear(this.summaryForm.nextCommYearStart);
    this.editStatus = true;
    this.isConfirm = false;
    this.change();
  }

  confirm() {
    this.loading = true;
    const params: any = {
      frozen: this.summaryForm.frozen,
      waiveMonthlyBonus: this.summaryForm.waiveMonthlyBonus,
    };
    if (this.summaryForm.firstContributionMonth != this.summary.firstContributionMonth) {
      params.firstContributionMonth = this.summaryForm.firstContributionMonth;
    }
    if (this.summaryForm.nextCommYearStart != this.summary.nextCommYearStart) {
      params.nextCommYearStart = this.summaryForm.nextCommYearStart;
    }
    this.poolService.saveOrUpdate(this.summaryForm.id, params).subscribe(resp => {
      this.loading = false;
      let popData, result: number;
      if (resp && resp.result == 0) {
        popData = {
          message: texts.alertSuccessMsg,
          continue: texts.alertSuccessContinue,
          type: 'alert',
        };
        result = resp.result;
      } else {
        popData = {
          message: texts.alertFailedMsg,
          continue: texts.alertFailedContinue,
          type: 'alert',
          params: { respMsg: resp.message },
        };
        result = resp.result;
      }
      const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, { data: popData });
      popupRef.afterClosed().subscribe(confirm => {
        if (confirm && confirm.agree) {
          if (result == 0) {
            this.editStatus = false;
            this.getPoolInfo();
          }
        }
      });
    });
  }

  tabClick(i: any) {
    this.tab.forEach(j => {
      const item: any = j;
      if (item.value == i.value) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }

  backLanding() {
    this.router.navigate(['/commission/pool-list']);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
  }
}
