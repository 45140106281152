import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRoutingModule } from './payment.routing';
import { DetailsModule } from './details/details.module';
import { FinancialJournalModule } from './financial-journal/financial-journal.module';

@NgModule({
  imports: [CommonModule, PaymentRoutingModule, DetailsModule, FinancialJournalModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaymentModule {}
