import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { finalize } from 'rxjs';
import { BTN_CONFIG_TERTIARY } from 'src/app/config/btn.config';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { RelatedPolicy, employeeStatusMapObj } from '../employee';
import { EmployeeService } from '../employee.service';

@Component({
  selector: 'app-related-policy',
  templateUrl: './related-policy.component.html',
  styleUrls: ['./related-policy.component.scss'],
})
export class RelatedPolicyComponent implements OnInit {
  @Input() id = '';
  @Input() md5 = '';

  dataSource: RelatedPolicy[] = [];

  moreBtnCfg: CdsButtonConfig = BTN_CONFIG_TERTIARY;

  isLoading = false;

  employeeStatusMapObj = employeeStatusMapObj;

  constructor(public service: EmployeeService, private toastAlert: ToastAlertService, private router: Router) {}

  ngOnInit(): void {
    this.loadTable();
  }

  loadTable() {
    this.isLoading = true;
    if (!this.md5) {
      this.md5 = '';
    }
    if (!this.id) {
      this.id = '';
    }
    this.service
      .getRelatedPolicies(this.id, this.md5)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this.isLoading = false;
          this.dataSource = data.data || [];
        } else {
          this.toastError(data.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  goToDetail(id = '') {
    this.router.navigate(['/employee/related-policy-detail'], { queryParams: { id } });
  }
}
