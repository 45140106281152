/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, QueryList } from '@angular/core';
import { Component, OnInit, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsFooterConfig, CdsMenuItemComponent } from '@cds/ng-web-components/navigation';
import { Subject } from 'rxjs';
import { Menu } from 'src/app/core/models/menu';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { environment } from 'src/environments/environment';
import { LoginType } from '../../user/login/login.component';
import { NotifyService } from 'src/app/core/services/notify.service';
import { loadGoHomeFunc } from 'src/app/utils/utils';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit, AfterViewInit {
  @ViewChildren('menuItem') menuItemList!: QueryList<CdsMenuItemComponent>;
  menus: Menu[] = [];
  currentUser: any;
  copyright = `© Manulife (International) Limited (Incorporated in Bermuda with limited liability) ${environment.organizationDay}-${new Date().getFullYear()}`;
  version = `V ${environment.appVersion}`;
  langList: CdsOption[] = environment.languageList;
  selectedLang = 'en';
  isHideNavigationLang = false;
  private sharData: Subject<string> = new Subject();

  btnCfg: CdsButtonConfig = {
    style: 'tertiary',
    size: 'sm',
  };

  go(link?: string) {
    if (link) {
      if (link.indexOf('?') !== -1) {
        const linkP = link.split('?');
        const url = linkP[0];
        const parmasList = linkP[1].split('&');
        const parmas: any = [];
        parmasList.forEach(x => {
          const temp = x.split('=');
          parmas[temp[0]] = temp[1];
        });
        this.router.navigateByUrl('/error', { skipLocationChange: true }).then(() => this.router.navigate([url], { queryParams: { ...parmas } }));
        return;
      }
      this.router.navigate([link]);
    }
  }

  footerConfig: CdsFooterConfig = {
    menu: {
      items: [
        {
          header: 'Profile enquiry',
          list: ['Client static data', 'Daily transaction history', 'Account balance', 'Bonus rebate data', 'Investment portfolio'],
        },
        {
          header: 'Bonus rebate',
          list: ['Calculation frequency & method', 'BU calculation automation', 'Data interface to eMPF'],
        },
        {
          header: 'Agent maintenance',
          list: ['Agent Profile', 'Commision split', 'Online agent / commision scale update', 'Alert setting'],
        },
        {
          header: 'Commision',
          list: ['Commision calculation', 'Campaign results calculation', 'Data interface to DCS', 'Account Entries'],
        },
        {
          header: 'Customer',
          list: ['Member authorization', 'Daily activities tracker'],
        },
      ],
    },
    connect: {
      items: ['Privacy', 'Legal', 'Terms and Conditions'],
      socialMedia: {
        facebook: true,
        //Fixme: add youtube icon here
        // 'social_media:youtube':true,
        linkedin: true,
        weChat: true,
      },
    },
    legal: {
      country: 'Philippines',
      logo: 'logo:Manulife_rgb_w',
      htmlContent: this.copyright + '<br/>' + this.version,
    },
  };

  constructor(
    private authenticationService: AuthenticationService,
    private permissionService: PermissionService,
    private languageChangeService: LanguageChangeService,
    private router: Router,
    private notifyService: NotifyService
  ) {}

  ngOnInit(): void {
    this.permissionService.initMenu().then(menus => {
      this.menus = menus;
    });

    this.notifyService.getHideNavigationLang().subscribe(boo => {
      setTimeout(() => {
        this.isHideNavigationLang = boo;
        const mobileNavigation = document.querySelector('div.cds-mobile-menu-util.order-1 cds-navigation-dropdown-utility');
        if (mobileNavigation && mobileNavigation.parentElement) {
          (mobileNavigation.parentElement as HTMLElement).style.display = boo ? 'none' : 'unset';
        }
      }, 100);
    });

    this.languageChangeService.initLanNotice.subscribe(lang => {
      this.selectedLang = lang;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      for (const menu of this.menuItemList) {
        menu.subMenuOpened.subscribe(() => {
          setTimeout(() => {
            const subMenu = document.querySelector('.cds-sub-menu');
            (subMenu as HTMLElement).click();
          }, 0);
        });
      }
    }, 100);

    loadGoHomeFunc(() => {
      this.router.navigate(['/home']);
    });
  }

  logout() {
    let loginType = localStorage.getItem('loginType') as LoginType;
    if (!loginType) {
      loginType = 'sso';
    }
    this.authenticationService.logout(loginType, this.router);
  }

  changeLang(lang: string) {
    this.selectedLang = lang;
    this.languageChangeService.setLang(lang);
    this.sendData(lang);
  }

  sendData(value: string) {
    this.sharData.next(value);
  }

  getLanguage() {
    return this.sharData;
  }
}
