<div class="l-d-f cds-grey light auth-layout">
  <div class="content-box">
    <div class="cds-white header l-pt-4 l-pb-4 l-d-f l-ai-cen l-jc-sb">
      <div class="l-d-f l-ai-cen">
        <div class="logo">
          <cds-icon style="width: 24px; height: 24px" color="#FFFFFF" icon="logo:Manulife_symbol"></cds-icon>
        </div>
        <div>
          <span class="l-mr-2 cds-body-hero cds-bold">Manulife</span>
          <span class="cds-body-hero" style="font-weight: 500">宏利</span>
        </div>
      </div>
      <div class="pc">
        <div [matMenuTriggerFor]="menu" (click)="isOpen = true" class="l-d-f l-ai-cen lang-box">
          <cds-icon color="black" icon="general:globe"></cds-icon>
          <cds-icon color="black" icon="action:button_up" *ngIf="isOpen"></cds-icon>
          <cds-icon color="black" icon="action:button_down" *ngIf="!isOpen"></cds-icon>
        </div>
      </div>
      <div class="mobile">
        <div [matMenuTriggerFor]="menu" (click)="isOpen = true" class="l-d-f l-ai-cen lang-box">
          <cds-icon color="white" icon="general:globe"></cds-icon>
          <cds-icon color="white" icon="action:button_down" *ngIf="!isOpen"></cds-icon>
          <cds-icon color="white" icon="action:button_up" *ngIf="isOpen"></cds-icon>
        </div>
      </div>
    </div>
    <div class="div-border-top content">
      <cds-content>
        <router-outlet></router-outlet>
      </cds-content>
    </div>
    <div class="cds-white div-border-top footer cds-body2">
      <div class="l-pl-6 l-pr-6 l-pt-3 l-pb-3">
        <div class="l-d-f">
          <div class="l-pa-2">
            {{ 'login.footer.privacy' | lang }}
          </div>
          <div class="l-pa-2">
            {{ 'login.footer.leagal' | lang }}
          </div>
          <div class="l-pa-2">
            {{ 'login.footer.terms' | lang }}
          </div>
          <div class="l-pa-2">
            {{ 'login.footer.internet' | lang }}
          </div>
        </div>
        <div class="l-pa-2">{{ copyright }}&nbsp;{{ 'login.footer.company' | lang }}</div>
      </div>
    </div>
  </div>
  <div class="img-box"></div>
</div>

<mat-menu #menu="matMenu" (closed)="isOpen = false" class="options-list">
  <button
    mat-menu-item
    class="tabindex-focus"
    [class.selected]="item.value === selectedValue"
    *ngFor="let item of languages"
    (click)="changeLang(item.value)"
    tabindex="-1">
    <span class="cds-detail1">{{ item.label | lang }}</span>
  </button>
</mat-menu>
