import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { NotifyService } from 'src/app/core/services/notify.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TransferStatusService } from 'src/app/core/services/transfer-status/transfer-status.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { cdsPopupBackdropOntouchmovePreventDefault, cdsPopupBackdropUnOntouchmovePreventDefault, numRanking, pageToTop } from 'src/app/utils/utils';
import { AddMdReferenceNumberPopupComponent } from '../add-md-reference-number-popup/add-md-reference-number-popup.component';
import { AddMpfReferenceNumberPopupComponent } from '../add-mpf-reference-number-popup/add-mpf-reference-number-popup.component';
import { ER_DECLINED_REASON_OPTIONS } from '../case-summary/case-summary.config';
import { EmpfAppStatusPopupComponent } from '../empf-app-status-popup/empf-app-status-popup.component';
import { IffStatusFlowPopupComponent } from '../iff-status-flow-popup/iff-status-flow-popup.component';
import { RefNoType } from '../md-iff-summary/md-iff-summary.model';
import { ResendEiffComponent } from '../resend-eiff/resend-eiff.component';
import { ResendEiffResendType } from '../resend-eiff/resend-eiff.model';
import { ERCaseDetailsResponse } from './er-empf-case-details.model';

type RolePriority = 'SuperAdmin' | 'SalesSupport' | 'SalesJourneyAgent';

@Component({
  selector: 'app-er-empf-case-details',
  templateUrl: './er-empf-case-details.component.html',
  styleUrls: ['./er-empf-case-details.component.scss'],
})
export class ErEmpfCaseDetailsComponent implements OnInit {
  numRanking = numRanking;
  backErIcon = ActionIcons.button_left_filled;
  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  agentMDFormCompletedCheck = new FormControl(true);
  clientMDFormCompletedCheck = new FormControl(true);
  eMPFCompletedCheck = new FormControl(false);
  agentIFFCompletedCheck = new FormControl(false);
  clientIFFCompletedCheck = new FormControl(false);
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;
  MDIFFStatus = 0;
  empfStatus = 0;
  intragroupTransferCheck = new FormControl(true);
  isOpenDbs = false;
  hasSalesSupportRole = false;
  hasSalesJourneyAgentRole = false;
  hasSalesJourneySuperAdmin = false;
  rolePriority?: RolePriority;

  erCaseDetailsResponse?: ERCaseDetailsResponse;
  // = {
  //   status: {
  //     agentIFFCompleted: true,
  //     splitAgentCompleted: false,
  //     customerIFFCompleted: false,
  //     empfMatched: false,
  //   },
  //   mdCaseDetail: null,
  //   // {
  //   //   resendAble: true,
  //   //   mdNo: 'MD-1',
  //   //   mdStatus: IffStatusValue.Completed,
  //   //   submissionDate: '25/09/2024',
  //   //   resendDate: '25/09/2024',
  //   //   commChannel: 'SMS',
  //   //   phoneCountry: '34',
  //   //   phone: '1234',
  //   //   email: 'asdfsdf@gmail.com',
  //   //   titleType: 'BR_NO',
  //   //   employerName: 'ycy',
  //   //   brNo: 'b-1',
  //   // },
  //   empfCaseDetail: null,
  //   // {
  //   //   empfNo: 'empf-3',
  //   //   empfStatus: EMPF_App_Status_Value.Not_Applicable,
  //   //   email: 'asdfsdf@gmail.com',
  //   //   phone: '18989898989',
  //   //   submissionDate: '15/10/2024',
  //   //   employerName: 'test',
  //   //   brNo: 'br1',
  //   //   apPerson: [ {name:"hans", email: 'asdf@gmail.com', phone: '18989898989',phoneCountry:'+86'}, {name: "nevin", email: 'asdf@gmail.com', phone: '18989898989',phoneCountry:'+86'}],
  //   //   transferDetails: [],
  //   // },
  //   serviceAgent: {
  //     agentCode: '683395',
  //     agentName: '?2',
  //     isRgltdActAck: false,
  //     shareHKPC: 0.5,
  //     shareCommission: 0.5,
  //   },
  //     "splitAgent": [{
  //     "agentCode": "683395",
  //     "agentName": "?2",
  //     "isRgltdActAck": false,
  //     "shareHKPC": 0.5,
  //     "shareCommission": 0.5
  //   },
  //   {
  //     "agentCode": "683395",
  //     "agentName": "?2",
  //     "isRgltdActAck": false,
  //     "shareHKPC": 0.4,
  //     "shareCommission": 0.6
  //   }
  //   ],
  //    "transfer": [{
  //     "productType": "PA",
  //     status: TransferStatusValue.TRANSFER_FOLLOW_UP,
  //     "trusteeName": {
  //       "originalScheme": "xxx",
  //       "MILScheme": "xxxx"
  //     },
  //     "schemeName": {
  //       "originalScheme": "xxx",
  //       "MILScheme": "xxxx"
  //     },
  //     "schemeMemberAccount": {
  //       "originalScheme": "xxx",
  //       "MILScheme": "xxxx"
  //     },
  //     "accountType": {
  //       "originalScheme": "xxx",
  //       "MILScheme": "xxxx"
  //     },
  //     "rejectReason": [1, 2, 3, 4, 5]
  //   }
  //   ]
  // };

  no?: string;
  refNoType?: RefNoType;

  constructor(
    private router: Router,
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    public empfAppStatusService: EmpfAppStatusService,
    private permissionService: PermissionService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private notifyService: NotifyService,
    private route: ActivatedRoute,
    public transferStatusService: TransferStatusService
  ) {}

  ngOnInit(): void {
    this.no = this.route.snapshot.paramMap.get('no') || undefined;
    this.refNoType = (this.route.snapshot.paramMap.get('refNoType') as RefNoType) || undefined;
    this.initRole().then(() => {
      this.getCaseDetail().then(() => {
        this.initRejectReasons();
      });
    });
  }

  getCaseDetail() {
    return new Promise<void>((resolve, reject) => {
      let urlRolePart = '';
      switch (this.rolePriority) {
        case 'SalesJourneyAgent':
          urlRolePart = 'agent';
          break;
        case 'SalesSupport':
          urlRolePart = 'support';
          break;
        case 'SuperAdmin':
          urlRolePart = 'admin';
          break;
        default:
          break;
      }
      let urlRefNoTypePart = '';
      let urlParamPart;
      switch (this.refNoType) {
        case RefNoType.MD:
          urlRefNoTypePart = 'caseDetailByMd';
          urlParamPart = { mdNo: this.no };
          break;
        case RefNoType.EMPF:
          urlRefNoTypePart = 'caseDetailByEMPF';
          urlParamPart = { empfNo: this.no };
          break;

        default:
          break;
      }

      if (!urlRolePart || !urlRefNoTypePart || !urlParamPart) {
        return;
      }

      this.notifyService.screenLoadingSubject.next(true);
      this.cdHttpServeService.get<ERCaseDetailsResponse>(`/ext/eb-ssms-sales-journey-service/sj/${urlRefNoTypePart}/${urlRolePart}`, urlParamPart).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
            reject();
          } else {
            this.erCaseDetailsResponse = res.data;
            resolve();
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          reject();
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
    });
  }

  initRejectReasons() {
    this.erCaseDetailsResponse?.transfer?.forEach(t => {
      t.rejectReasonCombination = t.rejectReason.map(item => {
        const option = ER_DECLINED_REASON_OPTIONS.find(o => o.value === item + '');
        if (option) {
          return {
            title: option.label,
            content:
              'Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis.',
            isExpand: false,
          };
        } else {
          return {
            title: 'N/A',
            content:
              'Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis.',
            isExpand: false,
          };
        }
      });
    });
  }

  private async initRole() {
    this.hasSalesJourneySuperAdmin = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SUPER_ADMIN);
    this.hasSalesSupportRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SALES_SUPPORT);
    this.hasSalesJourneyAgentRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_AGENT);
    if (this.hasSalesJourneySuperAdmin) {
      this.rolePriority = 'SuperAdmin';
    } else if (this.hasSalesSupportRole) {
      this.rolePriority = 'SalesSupport';
    } else if (this.hasSalesJourneyAgentRole) {
      this.rolePriority = 'SalesJourneyAgent';
    }
  }

  onBack() {
    this.router.navigate(['/salesjourney/md-iff-summary']);
  }

  toTop() {
    pageToTop();
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(IffStatusFlowPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseEmpf() {
    this.cdsPopup.open(EmpfAppStatusPopupComponent, {
      size: 'sm',
    });
  }

  onOpenAddEmpfRefNoPopup(mdNo: string) {
    this.cdsPopup.open(AddMpfReferenceNumberPopupComponent, {
      size: 'sm',
      data: {
        mdNo: mdNo,
        prodType: 'ER',
      },
    });
  }

  onOpenAddMDRefNoPopup(empfNo: string) {
    this.cdsPopup.open(AddMdReferenceNumberPopupComponent, {
      size: 'sm',
      data: {
        empfNo: empfNo,
        prodType: 'ER',
      },
    });
  }

  openResendEIFF() {
    const ref = this.cdsPopup.open(ResendEiffComponent, {
      size: 'md',
      data: {
        mdNo: this.erCaseDetailsResponse?.mdCaseDetail?.mdNo,
        resendType: ResendEiffResendType.ER,
        mdStatus: this.erCaseDetailsResponse?.mdCaseDetail?.mdStatus,
        empfStatus: this.erCaseDetailsResponse?.empfCaseDetail?.empfStatus,
      },
    });
    ref.afterClosed().subscribe(({ isRefresh }) => {
      if (isRefresh) {
        location.reload();
      }
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });

    ref.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  onMDIFFStatusShowToggle(event: MouseEvent) {
    if (event.target) {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  onToggleDbs() {
    this.isOpenDbs = !this.isOpenDbs;
  }

  onGoEditAgentDetails() {
    this.router.navigate(['/salesjourney/edit-agent-details', this.erCaseDetailsResponse?.mdCaseDetail?.mdNo, { type: 'ER' }]);
  }
}
