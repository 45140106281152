/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { PopupRequest } from '@azure/msal-browser';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { MatDialog } from '@angular/material/dialog';
import { BasicResponse } from 'src/app/core/models/response/response';
import { getSessionByKey, deleteSessionByKey } from 'src/app/utils/indexdb';
import { UserAgreementPopupComponent } from '../../views/commission/user-agreement-popup.component';
import { CdsLangService } from '@cds/ng-core/lang';

import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginType } from '../../views/user/login/login.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public worker: Worker;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private router: Router,
    private http: HttpClient,
    private cdsPopup: CdsPopupService,
    public dialog: MatDialog,
    private langService: CdsLangService
  ) {
    this.worker = new Worker(new URL('src/app/utils/refresh-token.worker', import.meta.url));
  }

  public workerRefresh() {
    this.worker.onmessage = ({ data }) => {
      if (data) {
        const response = JSON.parse(data);
        if (response && response.status == 401) {
          this.sessionTimeOut();
        } else if (response && response.alert) {
          const popupRef = this.cdsPopup.open(UserAgreementPopupComponent, {
            data: {
              message: 'login.logout.alert',
              continue: 'common.gotItWithExclamation',
              type: 'alert',
              params: { minutes: response.minutes },
            },
          });
          popupRef.afterClosed().subscribe(confirm => {
            if (confirm) {
              this.worker.postMessage(null);
            }
          });
        }
      }
    };
    this.worker.postMessage(null);
  }

  public currentUserValue() {
    return getSessionByKey();
  }

  setRouterUrl(url: string) {
    if (!['/user/session-time-out', '/user/login', '/', '/permission-error', '/response-error'].includes(url) && url.indexOf('/user/login?') == -1) {
      sessionStorage.setItem('targetUrl', url);
    }
    if (window.location.href.includes('/salesjourney/split-agent-notification?id=')) {
      const s = window.location.href.indexOf('/salesjourney/split-agent-notification');
      const path = window.location.href.substring(s);
      sessionStorage.setItem('targetUrl', path);

      const summaryIdStartIndex = window.location.href.indexOf('?id=') + 4;
      const summaryIdEndIndex = window.location.href.indexOf('&iffId');
      let id = window.location.href.substring(summaryIdStartIndex, summaryIdEndIndex);

      const iffIdStartIndex = window.location.href.indexOf('&iffId=') + 7;
      const iffIdEndIndex = window.location.href.indexOf('&iffVersionNo');
      let iffId = window.location.href.substring(iffIdStartIndex, iffIdEndIndex);

      const iffVersionNoStartIndex = window.location.href.indexOf('&iffVersionNo=') + 14;
      const iffVersionNoEndIndex = window.location.href.indexOf('&language');
      let iffVersionNo = window.location.href.substring(iffVersionNoStartIndex, iffVersionNoEndIndex);

      if (id.includes('%') && id.lastIndexOf('%') !== id.indexOf('%') && sessionStorage.getItem('num')) {
        id = sessionStorage.getItem('summaryId') || '';
        iffId = sessionStorage.getItem('iffId') || '';
        iffVersionNo = sessionStorage.getItem('iffVersionNo') || '';
      }

      sessionStorage.setItem('num', '1');
      sessionStorage.setItem('summaryId', id);
      sessionStorage.setItem('iffId', iffId);
      sessionStorage.setItem('iffVersionNo', iffVersionNo);
    }
  }

  authenticateToken(apiSuffix: string, accessToken: string, mcfRefreshToken?: string): Observable<any> {
    const headers: any = { Authorization: accessToken };
    if (mcfRefreshToken) {
      headers['Mcf-Refresh-Token'] = mcfRefreshToken;
    }
    return this.http.post<any>(environment.apiPrefix.concat(environment.identityServiceExt).concat(apiSuffix), null, {
      headers: new HttpHeaders(headers),
    });
  }

  loginWithSSO() {
    if (this.msalGuardConfig.authRequest) {
      return this.authService.loginPopup({
        ...this.msalGuardConfig.authRequest,
      } as PopupRequest);
    }

    return this.authService.loginPopup();
  }

  logout(loginType: LoginType, router: any) {
    switch (loginType) {
      case 'sso':
        this.SSOlogoutLogging();
        this.authService.logoutPopup().subscribe(async () => {
          this.redirect(router);
        });
        break;
      case 'mcf':
        this.clearSsoPopup(router);
        break;
      default:
        this.redirect(router);
    }
  }

  SSOlogoutLogging() {
    this.currentUserValue().then(res => {
      const logRes = this.http.post<any>(`${environment.apiPrefix}${environment.identityServiceExt}/auth/ad/logout`, { microsoftId: res.microsoftId });
      logRes.subscribe(() => {});
    });
  }

  getLoadingDialogRef(): MatDialogRef<UserAgreementPopupComponent> {
    return this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        type: 'loading',
      },
    });
  }

  logoutMcfPost(mcfId: string): Observable<BasicResponse<any>> {
    return this.http.post<any>(`${environment.apiPrefix}${environment.identityServiceExt}/auth/mcf/logout`, { mcfId: mcfId }).pipe(
      map(res => {
        return res;
      })
    );
  }

  logoutMcf(router: any, b?: boolean) {
    getSessionByKey().then(session => {
      const loading = this.getLoadingDialogRef();
      this.logoutMcfPost(session.mcfId).subscribe(res => {
        loading.close();
        if (res && res.result == 0) {
          this.redirect(router, b);
        } else {
          this.catchMcfError(router, res.message);
        }
      });
    });
  }

  validateMcfToken(mcfId: string): Observable<BasicResponse<any>> {
    const params = { mcfId: mcfId };
    return this.http.get<any>(`${environment.apiPrefix}${environment.identityServiceExt}/auth/mcf/valid-tokens`, { params: params }).pipe(
      map(res => {
        return res;
      })
    );
  }

  en =
    '<div class="l-pl-4 l-pr-4"><span class="cds-h4-light l-pt-2">System detected that you are accessing other agency tools with same user account, do you want to cancel the single sign-on function in this browser?</span><br><br>' +
    '<div class="l-d-f l-pt-1"><div class="l-pl-2 l-pr-2">•</div>If "Yes", it means that you will have to re-enter your username and password in this browser for accessing to the agency tools. </div>' +
    '<div class="l-d-f l-pt-1"><div class="l-pl-2 l-pr-2">•</div><span>If "No", to avoid others using your username and password for login, please ensure you have logout to <span class="font-bold">all your agency tools on all browser(s)/device(s)</span> before you close this browser (especially on public area / using shared PC). </span></div><br></div>';

  zh =
    '<div class="l-pl-4 l-pr-4"><span class="cds-h4-light l-pt-2">系統顯示您正在使用同一用戶名稱登入了其他經銷工具，您想取消在此瀏覽器上的單一登入功能嗎？</span><br><br>' +
    '<div class="l-d-f l-pt-1"><div class="l-pl-2 l-pr-2">•</div>如「是」，即代表您在下一次使用此瀏覽器登入經銷工具時需要重新輸入您的用戶名稱及密碼作登入。</div>' +
    '<div class="l-d-f l-pt-1"><div class="l-pl-2 l-pr-2">•</div><span>如「否」，請確保在離開此瀏覽器前已登出<span class="font-bold">所有瀏覽器/裝置上的所有經銷工具</span>（特別在公共地方 / 共用的電子裝置上），以避免其他人可以你的用戶名稱及密碼作登入。</span></div><br></div>';

  async clearSsoPopup(router: any) {
    getSessionByKey().then(session => {
      const popupRef = this.getLoadingDialogRef();
      this.validateMcfToken(session.mcfId).subscribe(res => {
        if (res && res.result == 0) {
          const arr: string[] = res.data;
          if (arr && arr.length > 1 && arr.includes(`${environment.mcfClientId}`)) {
            this.langService.addLangEntries({
              logoutMsg: {
                en: this.en,
                zh: this.zh,
              },
            });
            popupRef.close();
            const popupRef2: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
              size: 'lg',
              data: {
                message: 'logoutMsg',
                cancel: 'common.no',
                continue: 'common.yes',
                type: 'confirm',
              },
            });
            popupRef2.afterClosed().subscribe(confirm => {
              if (confirm) {
                if (confirm.agree) {
                  this.logoutMcf(router, true);
                } else {
                  this.logoutMcf(router);
                }
              }
            });
          } else if (arr && arr.length == 1 && arr.includes(`${environment.mcfClientId}`)) {
            this.logoutMcf(router, true);
          } else {
            this.redirect(router);
          }
        } else {
          this.catchMcfError(router, res.message);
        }
      });
    });
  }

  catchMcfError(router: any, message: string) {
    const msg = message ? message : 'Unknown Error';
    const popupRef2: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      size: 'md',
      data: {
        message: '<span class="cds-h4-light l-pt-2">' + msg + '</span>',
        continue: 'Got it',
        type: 'alert',
      },
    });
    popupRef2.afterClosed().subscribe(confirm => {
      if (confirm && confirm.agree) {
        this.redirect(router);
      }
    });
  }

  redirect(router: any, redirect?: boolean) {
    this.dialog.closeAll();
    this.deleteSession(true).then(res => {
      if (res) {
        if (redirect) {
          window.location.href = `${environment.ssoLogoutUrl}` + window.location.origin;
        } else {
          router.navigate(['/user/login']);
        }
      }
    });
  }

  sessionTimeOut() {
    this.dialog.closeAll();
    this.setRouterUrl(this.router.url);
    if (localStorage.getItem('loginType') == 'mcf') {
      window.location.href = `${environment.ssoLogoutUrl}` + window.location.origin + `/user/session-time-out`;
    } else {
      this.router.navigate(['/user/session-time-out']);
    }
  }

  deleteSession(b: boolean) {
    return new Promise(resolve => {
      deleteSessionByKey().then(() => {
        this.dialog.closeAll();
        if (b) {
          localStorage.removeItem('loginType');
          sessionStorage.removeItem('targetUrl');
          sessionStorage.removeItem('summaryId');
          sessionStorage.removeItem('iffId');
          localStorage.removeItem('case-summary-search-params');
          localStorage.removeItem('case-summary-selected-data');
          localStorage.removeItem('store');
        }
        resolve(true);
      });
    });
  }
}
