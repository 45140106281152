<app-cd-page-title title="{{ 'setting.auditHistory.title' | lang }}"> </app-cd-page-title>
<div class="row">
  <div class="col-xs-12 l-pt-4 l-mb-7">
    <form [formGroup]="formGroup" (ngSubmit)="search()">
      <div class="cds-h2 cds-light l-mb-18">
        {{ 'commission.pool.common.searchRecord' | lang }}
      </div>
      <div class="row l-plr-0">
        <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-mb-18 l-d-f l-pr-4">
          <cds-form-field class="l-fg-1" *ngIf="isShowSearchDatePicker">
            <cds-textfield
              [label]="'setting.auditHistory.dateFrom' | lang"
              placeholder="DD/MM/YYYY"
              [cdsDatepicker]="OccurDateFromPicker"
              [formControl]="startDate">
            </cds-textfield>
            <cds-datepicker #OccurDateFromPicker="cdsDatepicker"></cds-datepicker>
            <cds-assistive-text *ngIf="startDate.errors && startDate.dirty">{{ startDate.errors['error'] | lang }}</cds-assistive-text>
            <cds-assistive-text *ngIf="formGroup.errors && formGroup.dirty">{{ formGroup.errors['error'] | lang }}</cds-assistive-text>
          </cds-form-field>
          <div class="cds-body1 l-pl-4 l-pt-5 line-box desktop-md-hide">-</div>
        </div>
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18" *ngIf="isShowSearchDatePicker">
          <cds-textfield [label]="'setting.auditHistory.dateTo' | lang" placeholder="DD/MM/YYYY" [cdsDatepicker]="OccurDateToPicker" [formControl]="endDate">
          </cds-textfield>
          <cds-datepicker #OccurDateToPicker="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="endDate.errors && endDate.dirty">{{ endDate.errors['error'] | lang }}</cds-assistive-text>
        </cds-form-field>
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18">
          <app-cd-autocomplete
            [placeholder]="'setting.auditHistory.service.placeholder'"
            [label]="'setting.auditHistory.service'"
            [control]="service"
            [options]="serviceOptions"></app-cd-autocomplete>
        </cds-form-field>
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18">
          <app-cd-autocomplete
            [placeholder]="'setting.auditHistory.subService.placeholder'"
            [label]="'setting.auditHistory.subService'"
            [control]="serviceSection"
            [options]="subServiceOptions"></app-cd-autocomplete>
        </cds-form-field>
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18">
          <app-cd-autocomplete
            [placeholder]="'setting.auditHistory.accountType.placeholder'"
            [label]="'setting.auditHistory.accountType'"
            [control]="accountType"
            [options]="accountTypesOptions"></app-cd-autocomplete>
        </cds-form-field>
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18">
          <cds-textfield
            [label]="'setting.auditHistory.accountCode' | lang"
            [placeholder]="'setting.auditHistory.accountCode.placeholder' | lang"
            [formControl]="accountCode">
          </cds-textfield>
          <cds-assistive-text *ngIf="accountCode.errors && accountCode.dirty">{{ accountCode.errors['error'] | lang }}</cds-assistive-text>
        </cds-form-field>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20">
          <div class="search-area-btn">
            <cds-button type="submit" [disabled]="searchDisabled" [style]="'primary'" size="md">
              {{ 'common.search' | lang }}
            </cds-button>
            <cds-button (click)="reset()" [style]="'secondary'" size="md">
              {{ 'common.reset' | lang }}
            </cds-button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-xs-12 l-pt-4">
    <app-cd-table-title class="l-mb-5" [title]="'common.recordList' | lang" [total]="getTotalCount">
      <ng-template #tableAction>
        <cds-button (click)="download()" [disabled]="downloadDisabled" [style]="'secondary'" size="sm">
          <span>{{ 'common.download' | lang }}</span>
        </cds-button>
      </ng-template>
    </app-cd-table-title>
    <div>
      <app-data-table
        [frontPagination]="false"
        [fixed]="false"
        [isLoading]="isDataLoading"
        (reloadTable)="pageChange($event)"
        [pageConfig]="pageConfig"
        [dataSource]="dataDisplayed"
        [columnsConfig]="columnsConfig">
        <ng-template appDtBodyCellDef="updatedAt" let-element>
          <div>
            {{ element.updatedAt | ssmsDate : 'DD/MM/YYYY HH:mm:ss' }}
          </div>
        </ng-template>
        <ng-template appDtBodyCellDef="fields" let-element>
          <div *ngFor="let field of element.fields" style="height: 24px">
            <span>{{ field }}</span>
          </div>
        </ng-template>
        <ng-template appDtBodyCellDef="originalValue" let-element>
          <div *ngFor="let originalV of element.originalValue" style="height: 24px">
            <span>{{ originalV || ' ' }}</span>
          </div>
        </ng-template>
        <ng-template appDtBodyCellDef="changedValue" let-element>
          <div *ngFor="let changedV of element.changedValue" style="height: 24px">
            <span>{{ changedV || ' ' }}</span>
          </div>
        </ng-template>
      </app-data-table>
    </div>
  </div>
</div>
<app-cd-download-progress [show]="isDownLoading"></app-cd-download-progress>
