export class AgentProductSummary {
  pendingToMap?: number;
  pendingcustomer?: number;
  pendingAgent?: number;
  allPendingCount?: number;

  constructor(pendingToMap: number, pendingcustomer: number, pendingAgent: number, allPendingCount: number) {
    this.pendingToMap = pendingToMap;
    this.pendingcustomer = pendingcustomer;
    this.pendingAgent = pendingAgent;
    this.allPendingCount = allPendingCount;
  }
}
