<div class="row l-plr-0">
  <div class="col-xs-12 l-plr-0 l-pt-5">
    <span class="cds-h2-light">{{ 'list.search-record' | lang }}</span>
  </div>
</div>

<div class="row l-plr-0">
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown [(ngModel)]="searchDTO.year" (ngModelChange)="selectedChange($event, 'year')" [config]="yearCodeConfig"></cds-dropdown>
  </div>
</div>

<div class="row l-pt-7 l-plr-0">
  <div class="col-xs-12 l-pl-0 record-area">
    <div class="l-mr-6 l-mb-2">
      <div class="col-xs-12 l-pb-2 l-pl-0">
        <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
      </div>
      <div class="col-xs-12 l-pl-0">
        <span class="cds-body1">{{ 'page.total' | lang }} {{ pageConfig.totalCount }}{{ 'page.records' | lang }}</span>
      </div>
    </div>
    <div>
      <cds-button [config]="addButtonConfig" label="{{ 'dividend-rate.addLable' | lang }}" (click)="add()"></cds-button>
    </div>
  </div>
</div>

<div>
  <app-data-table
    [frontPagination]="false"
    [fixed]="false"
    [isLoading]="_loading"
    (reloadTable)="pageChange($event)"
    [pageConfig]="pageConfig"
    [dataSource]="dataList"
    [columnsConfig]="columnConfig">
    <ng-template appDtBodyCellDef="date" let-element>
      <div>
        <span>{{ element.month }}/{{ element.year }}</span>
      </div>
    </ng-template>
    <ng-template appDtBodyCellDef="dividendRate" let-element>
      <span>{{ element.dividendRate | number : '1.3-3' }}</span>
    </ng-template>
    <ng-template appDtBodyCellDef="recordDate" let-element>
      <span>{{ element.recordDate | ssmsDate }}</span>
    </ng-template>

    <ng-template appDtBodyCellDef="status" let-element>
      <div>
        <ng-container *ngIf="element.status === 'Pending'">
          <cds-button [config]="approveConfig" size="sm" label="{{ 'common.approve' | lang }}" (click)="approveDividendRate(element)"></cds-button>
        </ng-container>
        <ng-container *ngIf="element.status !== 'Pending'">
          {{ element.status }}
        </ng-container>
      </div>
    </ng-template>
  </app-data-table>
</div>
<div class="l-mt-9"></div>
