/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CdsLangService } from '@cds/ng-core/lang';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CDS_POPUP_DATA, CdsPopupService } from '@cds/ng-web-components/popup';
import { NotifyService } from 'src/app/core/services/notify.service';
import { AlertPopupComponent } from 'src/app/shared/alert-popup/alert-popup.component';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { EEEmpfDetailSimple, EREmpfDetailSimple } from './add-md-reference-number-popup.model';
import { AddMdService } from 'src/app/core/services/add-md/add-md.service';
import { catchError, map, Observable, of } from 'rxjs';

@Component({
  selector: 'app-add-md-reference-number-popup',
  templateUrl: './add-md-reference-number-popup.component.html',
  styleUrls: ['./add-md-reference-number-popup.component.scss'],
})
export class AddMdReferenceNumberPopupComponent implements OnInit {
  step = 1;
  mdIffControl: FormControl = new FormControl('', {
    initialValueIsDefault: true,
  });
  eeEmpfDetailSimple?: EEEmpfDetailSimple;
  erEmpfDetailSimple?: EREmpfDetailSimple;
  mdIffDetail?: any;

  get isNoChange() {
    return this.mdIffControl.value === '';
  }

  get eMPFPhoneNumber() {
    if (this.data.prodType === 'EE') {
      if (!this.eeEmpfDetailSimple) {
        return 'N/A';
      }
      return `(+${this.eeEmpfDetailSimple?.phoneCountry}) ${this.eeEmpfDetailSimple?.phone}`;
    } else {
      if (!this.erEmpfDetailSimple) {
        return 'N/A';
      }
      return `(+${this.erEmpfDetailSimple?.phoneCountry}) ${this.erEmpfDetailSimple?.phone}`;
    }
  }

  constructor(
    private dialogRef: MatDialogRef<AddMdReferenceNumberPopupComponent>,
    private cdsPopup: CdsPopupService,
    private langService: CdsLangService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      empfNo: string;
      prodType: 'EE' | 'ER';
    },
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private notifyService: NotifyService,
    private addMdService: AddMdService
  ) {}

  ngOnInit(): void {
    this.cdHttpServeService
      .get<EEEmpfDetailSimple | EREmpfDetailSimple>('/ext/eb-ssms-sales-journey-service/empf/detailSimpleByEmpfNo', { empfNo: this.data.empfNo })
      .subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
          } else {
            if (this.data.prodType === 'EE') {
              this.eeEmpfDetailSimple = res.data as EEEmpfDetailSimple;
            } else if (this.data.prodType === 'ER') {
              this.erEmpfDetailSimple = res.data as EREmpfDetailSimple;
            }
          }
        },
        error: err => {
          this.alert.error('Error!', err);
        },
      });
  }

  closeCurrentPopup() {
    this.dialogRef.close();
  }

  onReset() {
    this.step = 1;
    this.mdIffControl.reset();
  }

  onSubmit() {
    this.notifyService.screenLoadingSubject.next(true);
    this.cdHttpServeService
      .post<{ rejectReasonCode: number }>('/ext/eb-ssms-sales-journey-service/empf/addMdNo', {
        mdNo: this.mdIffControl.value,
        empfNo: this.data.empfNo,
      })
      .subscribe({
        next: res => {
          if (res.result === 0) {
            this.dialogRef.close();
            this.cdsPopup.open(AlertPopupComponent, {
              size: 'sm',
              data: {
                message: this.langService.translate('common.action.change.success.italic'),
                buttonName: this.langService.translate('common.gotItWithExclamation'),
              },
            });
          } else if (res.result === 4) {
            let msg = this.langService.translate('common.error');
            if (res.data.rejectReasonCode === -1) {
              msg = this.langService.translate('empf-ref-no-to-md-case-error-1');
            } else if (res.data.rejectReasonCode > -5 && res.data.rejectReasonCode < -1) {
              msg = this.langService.translate('empf-ref-no-to-md-case-error-2');
            } else if (res.data.rejectReasonCode === -5) {
              msg = this.langService.translate('empf-ref-no-to-md-case-error-1');
            }
            this.dialogRef.close();
            this.cdsPopup.open(AlertPopupComponent, {
              size: 'sm',
              data: {
                message: msg,
                buttonName: this.langService.translate('common.gotItWithExclamation'),
              },
            });
          } else {
            this.alert.warning('Warning!', `${res.message}`);
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
  }

  search = (query: string, page: number, size: number): Observable<{ label: string; value: string }[]> => {
    return this.addMdService.mdNoLike(query, page, size).pipe(
      map(res => {
        if (res.result !== 0 || !res.data) {
          return [];
        } else {
          return res.data.map(item => ({
            value: item.mdNo,
            label: `${item.mdNo};${item.code};${item.productType}`,
          }));
        }
      }),
      catchError(() => {
        return of([]);
      })
    );
  };

  onResultSelected(mdNo: string) {
    this.cdHttpServeService.get('/ext/eb-ssms-sales-journey-service/md/detailByMdNo', { mdNo: mdNo }).subscribe({
      next: res => {
        if (res.result !== 0) {
          this.alert.warning('Warning!', `${res.message}`);
        } else {
          this.mdIffDetail = res.data;
        }
      },
      error: err => {
        this.alert.error('Error!', err);
      },
    });
  }

  get isDisabledAdd() {
    return this.mdIffControl.value === '';
  }

  onBackToEdit() {
    this.step = 1;
  }

  onAdd() {
    if (this.isDisabledAdd) {
      return;
    }

    this.step = 2;
  }
}
