export enum TransferStatusValue {
  TRANSFER_PROCESSING = 0,
  TRANSFER_SUBMITTED = 0,
  TRANSFER_ACCEPTED = 0,
  TRANSFER_RECEIVED = 0,
  TRANSFER_PENDING = 0,
  TRANSFER_PEND_PAYMT_SETTLE = 0,
  TRANSFER_NIGHT_BATCH_PEND = 0,
  TRANSFER_ORDER_IN_PROGRESS = 0,
  TRANSFER_FOLLOW_UP = 10,
  TRANSFER_COMPLETED = 20,
  TRANSFER_CANCELLED = 30,
  TRANSFER_REJECTED = 40,
}

export const TRANSFER_STATUS = [
  {
    text: 'Transfer Processing',
    value: TransferStatusValue.TRANSFER_PROCESSING,
  },
  {
    text: 'Transfer Submitted',
    value: TransferStatusValue.TRANSFER_SUBMITTED,
  },
  {
    text: 'Transfer Accepted',
    value: TransferStatusValue.TRANSFER_ACCEPTED,
  },
  {
    text: 'Transfer Received',
    value: TransferStatusValue.TRANSFER_RECEIVED,
  },
  {
    text: 'Transfer Pending',
    value: TransferStatusValue.TRANSFER_PENDING,
  },
  {
    text: 'Transfer Pend Paymt Settle',
    value: TransferStatusValue.TRANSFER_PEND_PAYMT_SETTLE,
  },
  {
    text: 'Transfer Night Batch Pend',
    value: TransferStatusValue.TRANSFER_NIGHT_BATCH_PEND,
  },
  {
    text: 'Transfer Order In Progress',
    value: TransferStatusValue.TRANSFER_ORDER_IN_PROGRESS,
  },
  {
    text: 'Transfer Follow up',
    value: TransferStatusValue.TRANSFER_FOLLOW_UP,
  },
  {
    text: 'Transfer Completed',
    value: TransferStatusValue.TRANSFER_COMPLETED,
  },
  {
    text: 'Transfer Cancelled',
    value: TransferStatusValue.TRANSFER_CANCELLED,
  },
  {
    text: 'Transfer Rejected',
    value: TransferStatusValue.TRANSFER_REJECTED,
  },
];
