import { Component, OnInit } from '@angular/core';

import { CALENDAR_TAB } from './shared/calendar.config';
import { CalendarService } from './shared/calendar.service';
import { Tab } from 'src/app/core/models/enum';

@Component({
  selector: 'app-day-card',
  templateUrl: './day-card.component.html',
  styleUrls: ['./day-card.component.scss'],
})
export class DayCardComponent implements OnInit {
  tab: Array<Tab> = CALENDAR_TAB;

  constructor(public calendarService: CalendarService) {}

  ngOnInit(): void {}

  tabClick(i: Tab) {
    this.tab.forEach((j: Tab) => {
      const item: Tab = j;
      if (item.value == i.value) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }
}
