<app-cd-popup>
  <cds-popup-content>
    <div class="content l-pl-2 l-pr-2">
      <div class="cds-h2-light l-mb-0 l-pb-1">
        {{ 'calendar.event.approval' | lang }}
      </div>
      <div class="row l-plr-0 l-mt-4 l-mb-2">
        <div class="col-xs-12 col-md-11 l-d-f alert l-pl-5 l-pr-5 l-pt-4 l-pb-4">
          <cds-icon class="cds-demibold l-pr-3" size="sm" icon="action:megaphone"></cds-icon>
          <div>
            <div class="cds-demibold l-pb-1">
              {{ 'common.alert' | lang }}
            </div>
            <div>
              {{ 'calendar.approve.alert' | lang }}
            </div>
          </div>
        </div>
      </div>
      <div class="l-pt-4">
        <div class="col-xs-12 col-md-8 l-plr-0 cds-demibold cds-dropdown-label ng-star-inserted label-color l-pb-3">
          {{ 'calendar.event.name' | lang }}
        </div>
        <div class="cds-h3-light l-mb-0">{{ eventType | lang }}</div>
      </div>
      <div class="l-d-f l-ai-cen l-pt-5">
        <span class="cds-body1 l-mb-0">{{ 'calendar.event.year' | lang }}</span>
        <span class="cds-h3 l-pl-3 l-mb-0 cds-demibold">{{ nextYear }}</span>
      </div>
      <div class="row l-plr-0 l-pt-4">
        <div class="col-xs-12 col-md-12 col-lg-11 l-plr-0 l-pb-4">
          <div class="row l-plr-0 cds-white div-border-top div-border-bottom">
            <div class="col-xs-2 col-md-2 l-pb-2 l-pt-2 l-pl-4 l-pr-4"></div>
            <div class="col-xs-5 col-md-5 l-pb-2 l-pt-2 l-pl-4 l-pr-4">{{ 'calendar.event.date' | lang }}<span class="cds-coral-text dark-1">*</span></div>
            <div class="col-xs-5 col-md-5 l-pb-2 l-pt-2 l-pl-4 l-pr-4">{{ 'calendar.event.date' | lang }}<span class="cds-coral-text dark-1">*</span></div>
          </div>
          <div>
            <ng-container>
              <ng-container *ngFor="let myForm of commissionEventInitValue; let index = index; odd as odd">
                <div class="row l-plr-0 div-border-bottom" [class.cds-white]="odd">
                  <div class="col-xs-2 col-md-2 l-pt-4 l-pb-4 l-pl-4 l-pr-4 div-border-right l-d-f l-ai-cen">
                    {{ monthConfig[index].label | lang }}
                  </div>
                  <div class="col-xs-5 col-md-5 l-pt-3 l-pl-4 l-pr-8 div-border-right">
                    {{ myForm[0] }}
                  </div>
                  <div class="col-xs-5 col-md-5 l-pt-3 l-pl-4 l-pr-8 div-border-right">
                    {{ myForm[1] }}
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <app-permission [role]="permissionAccess.W" [permission]="permissionItem.SYS_CALENDAR_APPROVE">
      <cds-button [style]="'secondary'" size="sm" (click)="decline()">{{ 'common.decline' | lang }}</cds-button>
      <cds-button size="sm" (click)="onSubmit()">{{ 'common.approve' | lang }}</cds-button>
    </app-permission>
  </cds-popup-actions>
</app-cd-popup>
