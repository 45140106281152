import { ColumnConfig } from 'src/app/shared/data-table';

export const PAGE_SIZE = 10;
export const FILE_NAME = 'Finanical Journal';
export const EXCEL_COLUMNS = [
  'Employer Account Code',
  'Payroll Group ID',
  'Run Date',
  'Member Account Code',
  'Txn Date',
  'Txn Code',
  'Transaction Name',
  'Amount Debited',
  'Account Number',
  'Fund Code',
  'Amount Credited',
  'Scheme type',
  'Currency',
  'Report No.',
  'Work Area',
  'Process',
  'Admin Unit',
  'Financial Journal Last Run Date',
];

export const I18N_KEY = {
  DATE_From_NULL: 'commission.journal.dateFromNull',
  DATE_TO_NULL: 'commission.journal.dateToNull',
  INVALID_RUN_DATE: 'commission.common.runDate.invalid',
  DATE_RANGE_INCOMPLETE: 'commission.common.dateRangeError',
  INVALID_DATE_RANGE: 'commission.common.dateRangeInvalid',
  ACCOUNT_NO_INVALID: 'commission.common.accountNo.invalid',
  MEMBER_NO_INVALID: 'commission.common.memberNo.invalid',
  DOWN_LOAD_MAX_LIMMIT: 'commission.journal.download.limmit',
  DOWN_LOAD_MAX_FAIL: 'commission.journal.download.fail',
  COMMON_GOT: 'common.gotItWithExclamation',
};

export const FINANICAL_JOURNAL_COLUMNS: Array<ColumnConfig> = [
  { key: 'employerAccountCode', sticky: true, title: 'common.business.employerAcconutCode' },
  { key: 'payrollGroupId', sticky: true, title: 'common.business.payrollGroupID' },
  { key: 'runDate', stickyEnd: true, title: 'common.business.runDate' },
  { key: 'memberAccountCode', title: 'common.business.memberAcconutCode' },
  { key: 'txnDate', title: 'common.business.txnDate' },
  { key: 'txnCode', title: 'common.business.txnCode' },
  { key: 'transactionName', title: 'common.business.transactionName' },
  { key: 'amountDebited', title: 'common.business.amountDebited' },
  { key: 'accountNumber', title: 'common.business.accountNumber' },
  { key: 'fundCode', title: 'common.business.fundCode' },
  { key: 'amountCredited', title: 'common.business.amountCredited' },
  { key: 'schemeType', title: 'common.business.schemeType' },
  { key: 'currency', title: 'common.business.currency' },
  { key: 'reportNo', title: 'common.business.reportNo' },
  { key: 'workArea', title: 'common.business.workArea' },
  { key: 'process', title: 'common.business.process' },
  { key: 'adminUnit', title: 'common.business.adminUnit' },
  { key: 'lastRunDate', title: 'common.business.lastRunDate' },
];
