<div class="card-header-tab card-header">
  <div class="card-header-title font-size-lg text-capitalize fw-normal ps-4">
    <span style="font-weight: 600; font-size: 20px"> Chatbot - Reports</span>
  </div>
</div>

<div class="card-min-height p-3">
  <div class="card-header-title font-size-lg text-capitalize fw-normal ps-3">
    <span style="font-weight: 600; font-size: 20px"> Filter</span>
  </div>
  <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
    <div class="form-body">
      <div class="row" style="align-items: end">
        <div class="col-lg-3 col-md-3 col-sm-3 col-7 pb-1 ps-1">
          <div class="form-group">
            <label class="required">Start Date:</label>
            <input type="date" class="form-control" formControlName="startDate" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-7 pb-1 ps-1">
          <div class="form-group">
            <label class="required">End Date:</label>
            <input type="date" class="form-control" formControlName="endDate" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-7 pb-1 ps-1">
          <div class="form-group">
            <label>CSO List:</label>
            <ng-select
              class="font-small-1"
              [multiple]="true"
              [items]="agentDDL"
              bindLabel="name"
              formControlName="agent"
              bindValue="id"
              placeholder="Choose One Option"
              [clearable]="true">
            </ng-select>
          </div>
        </div>
      </div>

      <div class="ps-2" style="align-items: end; display: flex">
        <div class="ps-1">
          <cds-button [disabled]="loading" [size]="'sm'" [style]="'secondary'" class="ms-2" (click)="form.reset()">Reset</cds-button>
        </div>
        <div class="ps-1">
          <cds-button
            [disabled]="loading"
            [size]="'sm'"
            [style]="'primary'"
            [type]="'submit'"
            class="ms-2"
            [ngClass]="{ 'm-loader m-loader--right m-loader--info': loading }"
            [disabled]="loading || !form.valid"
            (click)="onSubmit()"
            >Download</cds-button
          >
        </div>
      </div>
    </div>
  </form>
</div>
