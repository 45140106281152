import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatHistoryComponent } from '../main/chat-history/chat-history.component';
import { ReportsComponent } from '../main/reports/reports.component';
import { ChatListComponent } from './chat-list/chat-list.component';
import { SupervisorDashboardComponent } from './supervisor-dashboard/supervisor-dashboard.component';
import { SupervisorComponent } from './supervisor.component';

const routes: Routes = [
  {
    path: '',
    component: SupervisorComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: SupervisorDashboardComponent,
      },
      {
        path: 'history',
        component: ChatHistoryComponent,
      },
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'chats',
        component: ChatListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SupervisorRoutingModule {}
