import { deepCopy } from 'src/app/utils/copy';
import { CurrencyE } from './../employer';
import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { aggregateInvestedAmountCurrencyDropdownOptions, ErRecord, ErSearchRequest, ProfileAgentInfo } from '../employer';
import { EmployerService } from '../employer.service';
import { CdsCheckboxConfig } from '@cds/ng-core/checkbox';
import { Router } from '@angular/router';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ColumnItem, Sort } from 'src/app/components/table/table-expand';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { descriptionValidator, numberStringValidator, rangeValidator } from 'src/app/shared/validators/validators';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

//test
@Component({
  selector: 'app-employer-list',
  templateUrl: './employer-list.component.html',
  styleUrls: ['./employer-list.component.scss'],
})
export class EmployerListComponent implements OnInit {
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;
  public Sort = Sort;
  form: FormGroup = new FormGroup({});
  formReady = false;
  showTable = true;
  _noResutlMsg?: string;

  employerNameValidTip = 'Please input English, Chinese, numbers, or special characters';

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  resetButtonDisabled = true;

  get employerNo() {
    return this.form?.get('employerNo');
  }

  get origErAcctCd() {
    return this.form?.get('origErAcctCd');
  }

  get eMpfId() {
    return this.form?.get('eMpfId');
  }

  get employerName() {
    return this.form?.get('employerName');
  }

  get intermediatoryCode() {
    return this.form?.get('intermediatoryCode');
  }

  get aggregateInvestedAmountCurrency() {
    return this.form?.get('aggregateInvestedAmountCurrency');
  }

  get aggregateInvestedAmountFrom() {
    return this.form?.get('aggregateInvestedAmountFrom');
  }

  get aggregateInvestedAmountTo() {
    return this.form?.get('aggregateInvestedAmountTo');
  }

  tempSeardhParams: ErSearchRequest = {
    page: 1,
    size: 10,
  };

  constructor(private service: EmployerService, private router: Router, private toastAlert: ToastAlertService, private permissionService: PermissionService) {}

  lineOfBusinessConfig: CdsDropdownConfig = {
    label: 'Line of Business',
    placeholder: 'Select Line of Business',
    options: [
      { label: 'MPF', value: 'MPF' },
      { label: 'ORSO', value: 'ORSO' },
      { label: 'MACAUCPF', value: 'MACAUCPF' },
      { label: 'MACAUPPS', value: 'MACAUPPS' },
    ],
  };
  isStaff = false;

  totalLivesConfig: CdsDropdownConfig = {
    label: 'Total Lives',
    placeholder: 'Select Number of Total Lives',
    options: [
      { label: '0-50 Lives', value: [0, 50] },
      { label: '51-100 Lives', value: [51, 100] },
      { label: '101-200 Lives', value: [101, 200] },
      { label: '>200 Lives', value: [201] },
    ],
  };

  _totalLiveModel?: number[];

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  aggregateInvestedAmountCurrencyConfig: CdsDropdownConfig = {
    label: '',
    placeholder: '',
    options: aggregateInvestedAmountCurrencyDropdownOptions,
  };

  _sortChange(columnItem: ColumnItem) {
    const temp = columnItem.prop?.replace('employerNo', 'erAcctCd').replace('employerName', 'companyNm');
    if (columnItem.sort !== Sort.DEFAULT) {
      this.tempSeardhParams.sort = `${temp},${columnItem.sort}`;
    } else {
      delete this.tempSeardhParams.sort;
    }
    this.getPageList();
  }

  permissionFilter() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYER).then(res => {
      this.isStaff = res;
    });
  }

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  isCheckPass = true;

  _pageCount: IPaginationCount = { current: 1, count: 1 };
  _totalCount = 0;

  _erRecordList?: ErRecord[];
  _progress = false;
  isLoading = false;

  noCheckConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: false,
  };

  checkedConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: true,
  };

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;
    this.isLoading = true;
    this.permissionFilter();
    this.initForm();
    this.getPageList();
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup(
      {
        employerNo: new FormControl('', [Validators.maxLength(15), numberStringValidator()]),
        origErAcctCd: new FormControl('', [Validators.maxLength(100), numberStringValidator()]),
        eMpfId: new FormControl('', [Validators.maxLength(20), numberStringValidator()]),
        employerName: new FormControl('', [Validators.maxLength(200), descriptionValidator(this.employerNameValidTip, true)]),
        lineOfBusiness: new FormControl(''),
        _totalLiveModel: new FormControl(),
        intermediatoryCode: new FormControl('', [Validators.maxLength(20), numberStringValidator()]),
        aggregateInvestedAmountCurrency: new FormControl(CurrencyE.HKD),
        aggregateInvestedAmountFrom: new FormControl(''),
        aggregateInvestedAmountTo: new FormControl(''),
      },
      { validators: [rangeValidator('list.invalid-range', 'aggregateInvestedAmountFrom', 'aggregateInvestedAmountTo'), this.amountCUrrency()] }
    );
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  search() {
    if (this.searchButtonDisabled) return;
    this.resetButtonDisabled = false;
    this._pageCount = { current: 1, count: 1 };
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.getPageList();
  }

  goToDetail(item: ErRecord) {
    this.router.navigate(['/employer/profile-detail'], {
      queryParams: {
        id: item.id,
        schema: item.systemId,
      },
    });
  }

  expandRow(index: number) {
    if (this._erRecordList && this._erRecordList[index]) {
      this.getListExpansion(this._erRecordList[index].id, index).then(() => {});
    }
  }

  async getListExpansion(id: string, index: number) {
    this._progress = true;
    this.service
      .getListExpansion(id)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && this._erRecordList) {
          this._erRecordList[index].expandData = data.data;
        } else {
          this.toastError(data.message);
        }
      });
  }

  profileAgentInfo(val: ProfileAgentInfo) {
    if (!val) {
      return '';
    }
    return val.agentCode + ' ' + val.agentName;
  }
  defaultPaymentMethodStr(val: string[]) {
    if (!val) {
      return '';
    }
    let str = '';
    val.forEach((i, index) => {
      str = str + i + (index + 1 != val.length ? ' | ' : '');
    });
    return str;
  }
  reset() {
    if (this.resetButtonDisabled) return;
    this.resetButtonDisabled = true;
    this.initForm();
    // reloadTable to reset sort
    this.showTable = false;
    setTimeout(() => {
      this.showTable = true;
      this._pageCount = { current: 1, count: 1 };
      this.tempSeardhParams = { page: 1, size: 10 };
      this.getPageList();
    });
  }

  currencyChange() {
    this.aggregateInvestedAmountCurrency?.markAsPristine();
    this.aggregateInvestedAmountCurrency?.markAsUntouched();
  }

  noChange(val: string, type: 'aggregateInvestedAmountFrom' | 'aggregateInvestedAmountTo') {
    let result = 0;
    const num = Number(val);
    if (!isNaN(num)) {
      result = Math.abs(num);
    }
    this.form.get(type)?.setValue(result);
  }

  getPageList() {
    this._progress = true;

    const params: ErSearchRequest = this.getRequestParams();

    this.service
      .getPageList(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        this._noResutlMsg = undefined;
        if (data.result === 4 && data.message == 'Restricted Manulife Staff Policy - Enquiry Prohibited') {
          this._erRecordList = [];
          this._pageCount.count = 0;
          this._totalCount = 0;
          this._noResutlMsg = data.message;
          console.log(data.message);
          return;
        }
        if (data.result === 0 && data.data) {
          this._erRecordList = data.data.empty ? [] : data.data.content;
          this._pageCount.count = data.data.totalPages;
          this._totalCount = data.data.totalElements;
        } else {
          this.toastError(data.message);
        }
      });
  }

  getTempSearchParams(sort = '') {
    const params: ErSearchRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this._pageCount.current as number) - 1,
      size: 10,
    };
    if (params._totalLiveModel) {
      [params.startTotalLives, params.endTotalLives] = params._totalLiveModel;
    }

    if (!params.aggregateInvestedAmountFrom && !params.aggregateInvestedAmountTo) {
      delete params.aggregateInvestedAmountCurrency;
      delete params.aggregateInvestedAmountFrom;
      delete params.aggregateInvestedAmountTo;
    }

    if (sort) {
      params.sort = sort;
    }

    delete params._totalLiveModel;
    return params;
  }
  getRequestParams() {
    const params: ErSearchRequest = {
      ...this.tempSeardhParams,
      page: (this._pageCount.current as number) - 1,
      size: 10,
    };

    if (params.aggregateInvestedAmountFrom === '') {
      params.aggregateInvestedAmountFrom = 0;
    }
    if (params.aggregateInvestedAmountTo === '') {
      params.aggregateInvestedAmountTo = 10000000000000000;
    }
    return params;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  amountCUrrency(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currencyControl = control.get('aggregateInvestedAmountCurrency');
      const amountFromControl = control.get('aggregateInvestedAmountFrom');
      const amountToControl = control.get('aggregateInvestedAmountTo');
      const currency = currencyControl?.value;
      const amountFrom = amountFromControl?.value;
      const amountTo = amountToControl?.value;

      const fromErrors = amountFromControl?.errors ? deepCopy(amountFromControl?.errors) : {};
      const toErrors = amountToControl?.errors ? deepCopy(amountToControl?.errors) : {};

      if (currency !== CurrencyE.HKD && amountFrom === '' && amountTo === '') {
        fromErrors['required'] = true;
        toErrors['required'] = true;

        amountFromControl?.markAsTouched();
        amountToControl?.markAsTouched();

        amountFromControl?.setErrors(fromErrors);
        amountToControl?.setErrors(toErrors);

        return null;
      }

      delete fromErrors['required'];
      delete toErrors['required'];
      amountFromControl?.setErrors(Object.keys(fromErrors).length === 0 ? null : fromErrors);
      amountToControl?.setErrors(Object.keys(toErrors).length === 0 ? null : toErrors);

      return null;
    };
  }

  channelStrGen(data?: string[]) {
    if (!data) return '';
    return data.join(' | ');
  }
}
