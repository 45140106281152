/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicResponse, PageResponse } from 'src/app/core/models/response/response';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  AccountBalance,
  AccountInfoAmountRequest,
  Activity,
  ActivitySearchRequest,
  AgentInfo,
  AgentSep,
  AgentSepFileCheckRes,
  BankInformation,
  BillingClass,
  BillReference,
  ChargeInfoClass,
  ChargeInfoRequest,
  CommentHistory,
  ContactPersion,
  ContributionAmount,
  ContributionDetails,
  ContributionSortByE,
  EmployerProfile,
  EmployerProfileDetail,
  ErBaseRequest,
  ErRecord,
  ErRecordDetail,
  ErSearchRequest,
  FormulaClass,
  MemberAmount,
  PaymentContribution,
  PaymentDetail,
  PaymentMemberLevel,
  PaymentMemberLevelRequest,
  PaymentNoticeRequest,
  PaymentNoticeRes,
  PaymentRecord,
  PaymentRecordsDetailPaymentInfo,
  PaymentRecordSearchRequest,
  PaymentSubmission,
  RelationshipTotalCount,
  SchemeInfo,
  SpecialManagement,
  SpecialQuote,
  SpecialQuoteRequest,
  SuspenseRecordSearchRequest,
  WorkflowDTO,
} from './employer';
import { filterParams, IPageableRequest } from 'src/app/core/models/request';
import moment from 'moment';
import { CdsOption } from '@cds/ng-core/configuration';
import { DateTime } from 'luxon';
import { ContactStatusE, ContactStatusMapObj } from './employer-profile-company-list/employer-profile-company-list';
import { Sort } from 'src/app/components/table/table-expand';
import { paramsObj2paramsUrlStr } from 'src/app/utils/utils';
import { EmpfWorkflowRequest } from '../employee/employee';

@Injectable({
  providedIn: 'root',
})
export class EmployerService {
  private customerServiceUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/`;
  private base_url = `${environment.apiPrefix}/ext/eb-ssms/customer-service/employer/`;
  private base_ee_url = `${environment.apiPrefix}/ext/eb-ssms/customer-service/EE-Profile/`;
  private overview = this.base_url + 'overview';
  private contactPersion = this.base_url + 'contactPersonList';
  private billingClassList = this.base_url + 'billingClassList';
  private profileDetails = this.base_url + 'details';
  private totalScoreUrl = this.base_url + 'totalRelationshipScore';
  private schemeInfo = this.base_url + 'payrollGroup/schemeInfo';
  private getPaymentRecordsListUrl = this.base_url + 'payrollGroup/paymentRecords';
  private getPaymentRecordsDetailUrl = this.base_url + 'payrollGroup/paymentRecordsDetail/baseInfo';
  private getPaymentRecordsDetailSearchListUrl = this.base_url + 'payrollGroup/paymentRecordsDetail/paymentInfoSearchList';
  private getPaymentRecordsDetailPaymentInformationUrl = this.base_url + 'payrollGroup/paymentRecordsDetail/paymentInformation';
  private getPaymentRecordsDetailPaymentContributionUrl = this.base_url + 'payrollGroup/paymentRecordsDetail/contribution';
  private getPaymentRecordsDetailMemberLevelListUrl = this.base_url + 'payrollGroup/paymentRecordsDetail/memberLevel';
  private updateCommentsUrl = this.base_url + 'updateComments';
  private getCommentsHistoryUrl = this.base_url + 'commentsHistory';
  private getPageListUrl = this.base_url + 'list';
  private getWorkflowUrl = this.base_url + 'workflowList';
  private getListExpansionUrl = this.base_url + 'listExpansion';
  private getAgentInfoUrl = this.base_url + 'agentInfo';
  private getPaymentNoticUrl = this.base_url + 'paymentNotice';
  private getContributionDetailsUrl = this.base_url + 'payrollGroup/accountInfo/contributionDetails';
  private special_quote_base_url = `${environment.apiPrefix}/ext/eb-ssms/customer-service/employers/`;
  private specialQuoteSubmissionYearsUrl = `${environment.apiPrefix}/ext/eb-ssms/customer-service/employers/`;
  private specialQuotesPath = '/special-quotes';
  private sqLogMasterListUrl = this.customerServiceUrl + 'special-quotes/list';
  private sqLogMasterExportUrl = this.customerServiceUrl + 'special-quotes/export';
  private yearsPath = '/years';
  private getActivityListUrl = this.base_url + 'activity/list';
  private getCompletionActivityListUrl = this.base_url + 'activity/completionList';
  private editActivityUrl = this.base_url + 'activity/edit';
  private editCompletionActivityUrl = this.base_url + 'activity/editCompletion';
  private deleteActivityUrl = this.base_url + 'activity/delete';
  private addActivityUrl = this.base_url + 'activity/add';
  private addCompletionActivityUrl = this.base_url + 'activity/addCompletion';
  private getErActivityScoreUrl = this.base_url + 'activity/employerScore';
  private getCompletionActivityScoreUrl = this.base_url + 'activity/completionScore';
  private specialOfferUrl = this.base_url + 'special-offer';
  private checkActivityUrl = this.base_url + 'activity/sameCompletion';
  private getAccountInfoAmountListUrl = this.base_url + 'payrollGroup/accountInfo/amountPopup';
  private getAccountInfoBillReferenceUrl = this.base_url + 'payrollGroup/accountInfo/billReference';
  private getAccountInfoMemberLevelListUrl = this.base_url + 'payrollGroup/accountInfo/memberLevel';
  private getAccountBalanceUrl = this.base_url + 'payrollGroup/accountBalance';
  private getAgentSepUrl = this.customerServiceUrl + 'agent-sep-enrolment/recordList';
  private getAgentSepEditUrl = this.customerServiceUrl + 'agent-sep-enrolment/editRecord';
  private getAgentSepAddUrl = this.customerServiceUrl + 'agent-sep-enrolment/addRecords';
  private deleteAgentSepurl = this.customerServiceUrl + 'agent-sep-enrolment/deleteRecord';
  private uploadAgentSepListUrl = this.customerServiceUrl + 'agent-sep-enrolment/uploadCsvFile';
  private getVestingRulesUrl = this.base_url + 'getVestingRulesByAcctNo';
  private getVoluntaryRulesUrl = this.base_url + 'getVoluntaryRulesByAcctNo';
  private getFormulaClassUrl = this.base_url + 'formulaClass';
  private getChargeInfoDateUrl = this.base_url + 'chargeInformationEffectiveDate';
  private getChargeInfoClassUrl = this.base_url + 'chargeInformation';
  private getBillingClassInfoClassUrl = this.base_url + 'payrollGroup/billingInfo';

  constructor(private http: HttpClient) {}

  getById(id: string): Observable<BasicResponse<EmployerProfile>> {
    return this.http.get<BasicResponse<EmployerProfile>>(this.overview, {
      params: { id: id },
    });
  }

  getProfileDetail(id: string): Observable<BasicResponse<EmployerProfileDetail>> {
    return this.http.get<BasicResponse<EmployerProfileDetail>>(this.profileDetails, {
      params: { id: id },
    });
  }

  getTotalScore(id: string): Observable<BasicResponse<RelationshipTotalCount>> {
    return this.http.get<BasicResponse<RelationshipTotalCount>>(this.totalScoreUrl, {
      params: { id: id },
    });
  }

  getErActivityScore(id: string): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<number>>(this.getErActivityScoreUrl, {
      params: { employerId: id },
    });
  }

  getCompletionActivityScore(params: ActivitySearchRequest): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<number>>(this.getCompletionActivityScoreUrl, {
      params: filterParams(params),
    });
  }

  getContactPersionList(param: ErBaseRequest): Observable<BasicResponse<ContactPersion[]>> {
    return this.http.get<BasicResponse<ContactPersion[]>>(this.contactPersion, {
      params: { ...param },
    });
  }

  getBillingClassList(id: string): Observable<BasicResponse<BillingClass[]>> {
    return this.http.get<BasicResponse<BillingClass[]>>(this.billingClassList, {
      params: { id: id },
    });
  }

  getSchemeInfo(param: ErBaseRequest): Observable<BasicResponse<SchemeInfo>> {
    return this.http.get<BasicResponse<SchemeInfo>>(this.schemeInfo, {
      params: { ...param },
    });
  }

  updateComments(commentHistory: CommentHistory) {
    return this.http.post<BasicResponse<boolean>>(this.updateCommentsUrl, commentHistory);
  }

  getCommentsHistory(customerId: string): Observable<BasicResponse<CommentHistory[]>> {
    return this.http.get<BasicResponse<CommentHistory[]>>(this.getCommentsHistoryUrl, {
      params: { customerId: customerId },
    });
  }

  getPageList(params: ErSearchRequest): Observable<PageResponse<ErRecord>> {
    return this.http.get<PageResponse<ErRecord>>(this.getPageListUrl, {
      params: filterParams(params),
    });
  }

  getListExpansion(id: string): Observable<BasicResponse<ErRecordDetail>> {
    return this.http.get<BasicResponse<ErRecordDetail>>(this.getListExpansionUrl, {
      params: { id: id },
    });
  }

  getAgentInfo(id: string): Observable<BasicResponse<AgentInfo>> {
    return this.http.get<BasicResponse<AgentInfo>>(this.getAgentInfoUrl, {
      params: { id: id },
    });
  }

  getPaymentNotice(params: PaymentNoticeRequest): Observable<BasicResponse<PaymentNoticeRes>> {
    return this.http.get<BasicResponse<PaymentNoticeRes>>(this.getPaymentNoticUrl, {
      params: filterParams(params),
    });
  }

  getContributionDetails(
    id = '',
    filterType: ContributionSortByE,
    payrollGroupId?: string,
    startDate?: string
  ): Observable<BasicResponse<ContributionDetails>> {
    return this.http.get<BasicResponse<ContributionDetails>>(this.getContributionDetailsUrl, {
      params: filterParams({
        id,
        payrollGroupId,
        filterType,
        // startDate: '2023-6-24',
        startDate: startDate ? moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined,
      }),
    });
  }

  contributionAmountTableDataTrans(data: ContributionDetails) {
    const tableData: ContributionAmount[] = [
      {
        category: 'memberContributionAmountForTheBill',
        ...data.billContributionAmount,
      },
      {
        category: 'reportedMemberContributionAmount',
        ...data.reportedMemberContributionAmount,
      },
      {
        category: 'paidContributionAmount',
        ...data.paidAmount,
      },
      {
        category: 'outstandingAmountOfThePayment',
        ...data.outstandingPaymentAmount,
      },
    ];

    return tableData;
  }

  getSpecialQuoteUrl(customerId: string) {
    return `${this.special_quote_base_url}${customerId}${this.specialQuotesPath}`;
  }

  getSpecialQuoteSubmissionYearsUrl(customerId: string) {
    return `${this.specialQuoteSubmissionYearsUrl}${customerId}${this.specialQuotesPath}${this.yearsPath}`;
  }

  getSpecialQuoteList(customerId: string, params: IPageableRequest): Observable<PageResponse<SpecialQuote>> {
    Object.keys(params).forEach(
      key =>
        (params[key as keyof IPageableRequest] === undefined || params[key as keyof IPageableRequest] === '') && delete params[key as keyof IPageableRequest]
    );

    let paramsStr = '';

    Object.keys(params).forEach((key, index) => {
      paramsStr = `${paramsStr}${index === 0 ? '?' : '&'}${key}=${params[key as keyof IPageableRequest]}`;
    });
    return this.http.get<PageResponse<SpecialQuote>>(this.getSpecialQuoteUrl(customerId) + paramsStr + '&sort=sqSubmissionDate,desc&sort=updatedAt,desc');
  }

  getSpecialQuoteSubmissionYears(customerId: string): Observable<BasicResponse<number[]>> {
    return this.http.get<BasicResponse<number[]>>(this.getSpecialQuoteSubmissionYearsUrl(customerId));
  }

  deleteSpecialQuote(customerId: string, id: string): Observable<BasicResponse<SpecialQuote>> {
    return this.http.delete<BasicResponse<SpecialQuote>>(`${this.getSpecialQuoteUrl(customerId)}/${id}`);
  }

  saveSpecialQuote(customerId: string, data: SpecialQuote) {
    return this.http.put<BasicResponse<SpecialQuote>>(this.getSpecialQuoteUrl(customerId), data);
  }

  addSpecialQuote(customerId: string, data: SpecialQuote) {
    return this.http.post<BasicResponse<SpecialQuote>>(`${this.getSpecialQuoteUrl(customerId)}`, data);
  }

  getSqLogMasterList(params: SpecialQuoteRequest): Observable<PageResponse<SpecialQuote>> {
    return this.sqLogMasterGetRequest(params, this.sqLogMasterListUrl);
  }

  sqLogMasterGetRequest(params: SpecialQuoteRequest, url: string): Observable<PageResponse<SpecialQuote>> {
    const sortOrderList = [
      {
        key: 'sqReferenceNo',
        sort: Sort.DOWN,
      },
      {
        key: 'sqSubmissionDate',
        sort: Sort.DOWN,
      },
      {
        key: 'erAcctCd',
        sort: Sort.UP,
      },
      {
        key: 'companyNm',
        sort: Sort.UP,
      },
    ];
    let paramsUrl = '';
    let resetSortParamsUrl = '';
    if (params.sort) {
      const key = params.sort.split(',')[0];
      const restSortOrderList = sortOrderList.filter(item => item.key !== key);
      resetSortParamsUrl = restSortOrderList.map(item => `sort=${item.key},${item.sort}`).join('&');
    } else {
      resetSortParamsUrl = sortOrderList.map(item => `sort=${item.key},${item.sort}`).join('&');
    }
    paramsUrl = Object.keys(params)
      .filter(key => (params as any)[key] !== '')
      .map(key => `${key}=${(params as any)[key]}`)
      .join('&');

    return this.http.get<PageResponse<SpecialQuote>>(`${url}?${paramsUrl}&${resetSortParamsUrl}`);
  }

  sqLogMasterExport(params: SpecialQuoteRequest): Observable<PageResponse<SpecialQuote>> {
    return this.sqLogMasterGetRequest(params, this.sqLogMasterExportUrl);
  }

  optionLabelRender(value?: string, options?: CdsOption[]) {
    if (!value || !options) {
      return '';
    }
    const option = options.find(item => item.value === value);
    if (option) {
      return option.label;
    }
    return '';
  }

  getAccountBalance(params: SuspenseRecordSearchRequest): Observable<BasicResponse<AccountBalance>> {
    return this.http.get<BasicResponse<AccountBalance>>(this.getAccountBalanceUrl, {
      params: filterParams(params),
    });
  }

  getPaymentRcordList(params: PaymentRecordSearchRequest): Observable<PageResponse<PaymentRecord>> {
    return this.http.get<PageResponse<PaymentRecord>>(this.getPaymentRecordsListUrl, {
      params: filterParams(params),
    });
  }

  getActivityList(params: ActivitySearchRequest): Observable<PageResponse<Activity>> {
    params = {
      ...params,
      createStartDate: params.createStartDate ? encodeURIComponent(params.createStartDate) : '',
      createEndDate: params.createEndDate ? encodeURIComponent(params.createEndDate) : '',
    };
    return this.http.get<PageResponse<Activity>>(this.getActivityListUrl, {
      params: filterParams(params),
    });
  }

  editActivity(params: Activity) {
    return this.http.post<BasicResponse<Activity>>(this.editActivityUrl, params);
  }

  checkActivity(params: Activity) {
    return this.http.get<BasicResponse<boolean>>(this.checkActivityUrl, {
      params: filterParams(params),
    });
  }

  editCompletionActivity(params: Activity) {
    return this.http.post<BasicResponse<Activity>>(this.editCompletionActivityUrl, params);
  }

  deleteActivity(id: string): Observable<BasicResponse<Activity>> {
    return this.http.post<BasicResponse<Activity>>(this.deleteActivityUrl, {
      id,
    });
  }

  addActivity(params: Activity) {
    return this.http.post<BasicResponse<Activity>>(this.addActivityUrl, params);
  }

  addCompletionActivity(params: Activity) {
    return this.http.post<BasicResponse<Activity>>(this.addCompletionActivityUrl, params);
  }

  getActivityCompletionList(params: ActivitySearchRequest): Observable<PageResponse<Activity>> {
    return this.http.get<PageResponse<Activity>>(this.getCompletionActivityListUrl, {
      params: filterParams(params),
    });
  }

  getSpecialOffer(employerId: string): Observable<PageResponse<SpecialManagement>> {
    return this.http.get<PageResponse<SpecialManagement>>(this.specialOfferUrl, {
      params: {
        employerId,
      },
    });
  }

  editSpecialOffer(params: SpecialManagement) {
    return this.http.post<BasicResponse<SpecialManagement>>(this.specialOfferUrl, params);
  }

  formateDate(date?: string) {
    if (!date) return '';
    const tempDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
    return DateTime.fromFormat(tempDate, 'yyyy-MM-dd H:m:s').toISO();
  }

  getStatusColorStyle(status: ContactStatusE | undefined) {
    if (!status) {
      return '';
    }
    return `background-color: ${ContactStatusMapObj[status].color};`;
  }

  getStatus(status: ContactStatusE | undefined) {
    if (!status) {
      return '';
    }
    return ContactStatusMapObj[status].text;
  }

  getAccountInfoAmountList(params: AccountInfoAmountRequest): Observable<PageResponse<MemberAmount>> {
    return this.http.get<PageResponse<MemberAmount>>(this.getAccountInfoAmountListUrl, {
      params: filterParams(params),
    });
  }

  getAccountInfoBillReferenceList(params: AccountInfoAmountRequest): Observable<BasicResponse<BillReference[]>> {
    return this.http.get<BasicResponse<BillReference[]>>(this.getAccountInfoBillReferenceUrl, {
      params: filterParams(params),
    });
  }

  getAccountInfoMemberLevelList(params: AccountInfoAmountRequest): Observable<PageResponse<MemberAmount>> {
    return this.http.get<PageResponse<MemberAmount>>(this.getAccountInfoMemberLevelListUrl, {
      params: filterParams(params),
    });
  }

  getAgentSepList(params: IPageableRequest): Observable<PageResponse<AgentSep>> {
    let paramsStr = paramsObj2paramsUrlStr(params);
    const sortList: string[] | undefined = params.sort?.split(',');
    if (sortList) {
      const sortKey = sortList[0];
      const sortMode = sortList[1];
      if (sortKey === 'hkid') {
        paramsStr += `&sort=passport,${sortMode}&sort=sepCommDate,desc&sort=mbAcctCd,asc`;
      } else if (sortKey === 'sepCommDate') {
        paramsStr += `&sort=mbAcctCd,asc`;
      } else if (sortKey === 'mbAcctCd') {
        paramsStr += `&sort=sepCommDate,desc`;
      } else {
        paramsStr += `&sort=sepCommDate,desc&sort=mbAcctCd,asc`;
      }
    }
    return this.http.get<PageResponse<AgentSep>>(this.getAgentSepUrl + paramsStr);
  }

  uploadAgentSepList(file: File, isCheck = false) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<BasicResponse<AgentSepFileCheckRes>>(this.uploadAgentSepListUrl, formData, { params: { check: isCheck } });
  }

  agentSepEdit(params: AgentSep) {
    return this.http.post<BasicResponse<any>>(this.getAgentSepEditUrl, params);
  }

  agentSepAdd(list: AgentSep[], check = false) {
    return this.http.post<BasicResponse<any>>(this.getAgentSepAddUrl, {
      list,
      check,
    });
  }

  agentSepDelete(id: string): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(this.deleteAgentSepurl, {
      id,
    });
  }

  getPaymentRecordsDetailBaseInfo(subRefNo: string, id: string, payrollGroupId: string): Observable<BasicResponse<PaymentSubmission[]>> {
    return this.http.get<BasicResponse<PaymentSubmission[]>>(this.getPaymentRecordsDetailUrl, {
      params: {
        subRefNo,
        id,
        payrollGroupId,
      },
    });
  }

  getPaymentRecordsDetailSearchList(subRefNo: string, id: string, payrollGroupId: string): Observable<BasicResponse<PaymentRecordsDetailPaymentInfo[]>> {
    return this.http.get<BasicResponse<PaymentRecordsDetailPaymentInfo[]>>(this.getPaymentRecordsDetailSearchListUrl, {
      params: {
        subRefNo,
        id,
        payrollGroupId,
      },
    });
  }

  getPaymentRecordsDetailPaymentInformation(
    subRefNo: string,
    id: string,
    payrollGroupId: string,
    receiptReferenceNo: string
  ): Observable<BasicResponse<BankInformation>> {
    return this.http.get<BasicResponse<BankInformation>>(this.getPaymentRecordsDetailPaymentInformationUrl, {
      params: {
        subRefNo,
        id,
        payrollGroupId,
        receiptReferenceNo,
      },
    });
  }

  getPaymentRecordsDetailContribution(
    subRefNo: string,
    id: string,
    payrollGroupId: string,
    receiptReferenceNo: string
  ): Observable<BasicResponse<PaymentContribution[]>> {
    return this.http.get<BasicResponse<PaymentContribution[]>>(this.getPaymentRecordsDetailPaymentContributionUrl, {
      params: {
        subRefNo,
        id,
        payrollGroupId,
        receiptReferenceNo,
      },
    });
  }

  getPaymentRecordsDetailMemberLevelList(params: PaymentMemberLevelRequest): Observable<PageResponse<PaymentMemberLevel>> {
    return this.http.get<PageResponse<PaymentMemberLevel>>(this.getPaymentRecordsDetailMemberLevelListUrl, {
      params: filterParams(params),
    });
  }

  getVestingRules(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(this.getVestingRulesUrl, {
      params,
    });
  }

  getVoluntaryRules(params: any): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(this.getVoluntaryRulesUrl, {
      params,
    });
  }

  getEmpfWorkflowList = (params: EmpfWorkflowRequest): Observable<PageResponse<WorkflowDTO>> => {
    return this.http.get<PageResponse<WorkflowDTO>>(this.getWorkflowUrl, {
      params: filterParams(params),
    });
  };

  getFurmulaClassList = (params: ErBaseRequest): Observable<BasicResponse<FormulaClass[]>> => {
    return this.http.get<BasicResponse<FormulaClass[]>>(this.getFormulaClassUrl, {
      params: filterParams(params),
    });
  };

  getChargeInformationEffectiveDate = (params: ChargeInfoRequest): Observable<BasicResponse<string[]>> => {
    return this.http.get<BasicResponse<string[]>>(this.getChargeInfoDateUrl, {
      params: filterParams(params),
    });
  };

  getChargeInformation = (params: ChargeInfoRequest): Observable<BasicResponse<ChargeInfoClass[]>> => {
    return this.http.get<BasicResponse<ChargeInfoClass[]>>(this.getChargeInfoClassUrl, {
      params: filterParams(params),
    });
  };

  getBillingClassInfo(param: ErBaseRequest): Observable<BasicResponse<PaymentDetail>> {
    return this.http.get<BasicResponse<PaymentDetail>>(this.getBillingClassInfoClassUrl, {
      params: { ...param },
    });
  }
}
