<div class="row">
  <!-- <div class="col-xs-12 l-mt-7 l-mb-2">
    <span class="cds-body1"
      >&nbsp;
    </span>
  </div> -->

  <div [formGroup]="form" *ngIf="formReady">
    <div class="col-xs-12 l-pt-7">
      <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
    </div>
    <div class="row l-plr-0">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="trusteeEmployerAccountNumber" label="Employer Account Code" placeholder="Input Employer Account Code"></cds-textfield>

        <div
          *ngIf="trusteeEmployerAccountNumberControl?.invalid && (trusteeEmployerAccountNumberControl?.dirty || trusteeEmployerAccountNumberControl?.touched)"
          class="l-pt-2">
          <cds-assistive-text *ngIf="trusteeEmployerAccountNumberControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false"
            >Max length is 15</cds-assistive-text
          >

          <cds-assistive-text
            *ngIf="trusteeEmployerAccountNumberControl?.errors?.['invalidNumberString']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ trusteeEmployerAccountNumberControl?.errors?.['invalidNumberString'] }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="companyName" label="Employer Name" placeholder="Input Employer Name"></cds-textfield>

        <div *ngIf="companyNameControl?.invalid && (companyNameControl?.dirty || companyNameControl?.touched)" class="l-pt-2">
          <cds-assistive-text *ngIf="companyNameControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 200</cds-assistive-text>

          <cds-assistive-text
            *ngIf="companyNameControl?.errors?.['descriptionInvalid']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ companyNameControl?.errors?.['descriptionInvalid'] }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="row col-xs-12 col-sm-12 col-md-8 col-lg-6 l-pt-4 l-pb-4 l-plr-0 date-picker-area relative">
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="terminationDateStart"
            placeholder="DD/MM/YYYY"
            label="Termination Date From"
            [max]="getMaxFromDate | fn : terminationDateEndControl?.value"
            [cdsDatepicker]="startPicker">
          </cds-textfield>
          <cds-datepicker #startPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="terminationDateStartControl?.invalid && (terminationDateStartControl?.dirty || terminationDateStartControl?.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="terminationDateStartControl?.errors?.['dateInvalid'] || terminationDateStartControl?.errors?.['startLaterThanEnd']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ 'create.dateError' | lang }}</cds-assistive-text
            >
          </div>
        </div>
        <div class="connector">-</div>
        <div class="col-xs-6 date-picker">
          <cds-textfield
            formControlName="terminationDateEnd"
            placeholder="DD/MM/YYYY"
            label="To"
            [min]="getMinToDate | fn : terminationDateStartControl?.value"
            [cdsDatepicker]="endPicker">
          </cds-textfield>
          <cds-datepicker #endPicker="cdsDatepicker"></cds-datepicker>

          <div *ngIf="terminationDateEndControl?.invalid && (terminationDateEndControl?.dirty || terminationDateEndControl?.touched)" class="l-pt-2">
            <cds-assistive-text
              *ngIf="terminationDateEndControl?.errors?.['dateInvalid']  || terminationDateEndControl?.errors?.['startLaterThanEnd']"
              class="l-pt-2"
              [showIcon]="false"
              >{{ 'create.dateError' | lang }}</cds-assistive-text
            >
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4">
        <cds-textfield formControlName="agentCode" label="Agent Code" placeholder="Input agent code"></cds-textfield>

        <div *ngIf="agentCodeControl?.invalid && (agentCodeControl?.dirty || agentCodeControl?.touched)" class="l-pt-2">
          <cds-assistive-text *ngIf="agentCodeControl?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 20</cds-assistive-text>

          <cds-assistive-text
            *ngIf="agentCodeControl?.errors?.['invalidNumberString']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ agentCodeControl?.errors?.['invalidNumberString'] }}</cds-assistive-text
          >
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 my-content">
        <cds-dropdown formControlName="agentFollowUpStatus" [config]="agentFollowUpStatusConfig"></cds-dropdown>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 l-pt-4 l-pb-4 my-content">
        <cds-dropdown formControlName="accountStatus" [config]="accountStatusConfig"></cds-dropdown>
      </div>

      <div class="row col-xs-12 col-sm-12 col-md-6 col-lg-3 bottom-xs l-pb-4 l-plr-0">
        <cds-button
          class="l-mr-4 botton"
          [disabled]="searchButtonDisabled"
          [config]="searchButtonConfig"
          label="{{ 'list.search' | lang }}"
          (click)="search()"></cds-button>
        <cds-button
          class="botton"
          [disabled]="resetButtonDisabled"
          [config]="resetButtonConfig"
          label="{{ 'list.reset' | lang }}"
          (click)="reset()"></cds-button>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-md-12 l-pb-3 l-d-f l-jc-sb l-mt-7 relative">
    <div>
      <div class="cds-h2-light l-ma-0">{{ 'common.recordList' | lang }}</div>
      <div class="cds-detail1-demibold">Total {{ _totalCount | number }} records</div>
    </div>
  </div>

  <div class="col-xs-12 table-container" *ngIf="!isMobile">
    <app-data-table
      [frontPagination]="false"
      [isLoading]="_progress"
      [pageConfig]="pageConfig"
      [fixed]="false"
      [overlay]="true"
      [dataSource]="_recordList"
      [columnsConfig]="columnsConfig"
      (reloadTable)="reloadTable($event)">
      <ng-template appDtBodyCellDef="terminationDate" let-element>
        {{ element['terminationDate'] | ssmsDate }}
      </ng-template>
      <ng-template appDtBodyCellDef="createdAt" let-element>
        {{ element['createdAt'] | ssmsDate }}
      </ng-template>
      <ng-template appDtBodyCellDef="trusteeEmployerAccountNumber" let-element>
        <div class="min-width-300 code-and-name">
          <div class="code">{{ element.trusteeEmployerAccountNumber }}</div>
          <div class="name">
            {{ element.companyName }}
            <span *ngIf="element.chineseCompanyName">&nbsp;{{ element.chineseCompanyName }}</span>
          </div>
        </div>
      </ng-template>
      <ng-template appDtBodyCellDef="empfMemberAccountNumber" let-element>
        <div class="min-width-300 code-and-name">
          <div class="code">{{ element['empfMemberAccountNumber'] }}</div>
          <div class="name">
            <div>
              <span>{{ element['firstName'] }}</span
              >&nbsp;<span class="l-pr-1">&nbsp;{{ element['lastName'] }}</span
              >&nbsp;
              <span class="name" *ngIf="element['chineseLastName'] || element['chineseFirstName']">
                <span>{{ element['chineseLastName'] }}</span
                ><span>{{ element['chineseFirstName'] }}</span>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template appDtBodyCellDef="preassignedAgentCode" let-element>
        <div class="min-width-200 code-and-name">
          <div class="code">{{ element.preassignedAgentCode }}</div>
          <div class="name">
            {{ element.preassignedAgentName }}<span *ngIf="element.preassignedAgentChineseName">&nbsp;{{ element.preassignedAgentChineseName }}</span>
          </div>
        </div>
      </ng-template>

      <ng-template appDtBodyCellDef="agentFollowUpStatus" let-element>
        <div class="min-width-200">
          <div>{{ employerService.optionLabelRender | fn : element.agentFollowUpStatus : agentFollowUpStatusDropdownOptions }}</div>
        </div>
      </ng-template>
      <ng-template appDtBodyCellDef="accountStatus" let-element>
        <div class="min-width-200">
          <div>{{ employerService.optionLabelRender | fn : element.accountStatus : accountStatusDropdownOptions }}</div>
        </div>
      </ng-template>
    </app-data-table>
  </div>

  <div class="row l-mt-4" *ngIf="isMobile">
    <div *ngFor="let item of _recordList" class="col-xs-12 no-padding">
      <app-expand-item-mobile [data]="item"></app-expand-item-mobile>
    </div>
  </div>
</div>
