<app-cd-popup>
  <cds-popup-content>
    <div class="content l-pl-6 l-pr-6">
      <div class="cds-h2-light l-mb-0 l-pb-1">
        {{ 'calendar.action.add' | lang }}
      </div>
      <span class="cds-body2-light cds-coral-text dark-1">{{ 'common.fillIn' | lang }}</span>
      <div class="row l-plr-0 l-pt-5" *ngIf="dropdownConfig">
        <div class="col-xs-12 col-md-8 l-plr-0 cds-demibold cds-dropdown-label ng-star-inserted label-color l-pb-3">
          {{ 'calendar.action.chooseName' | lang }}<span class="cds-coral-text dark-1">*</span>
        </div>
        <cds-dropdown [config]="dropdownConfig" class="col-xs-12 col-md-8 l-plr-0" [formControl]="eventTypeSelected"></cds-dropdown>
      </div>
      <div *ngIf="eventTypeSelected.value === eventTypeKey.COMMISSION">
        <div *ngIf="hasNextYearCommissionEvent" class="cds-body2-light cds-coral-text dark-1 l-mt-3">{{ 'calendar.addCommissionDuplited' | lang }}</div>
        <div *ngIf="!isLastTwoMonths" class="cds-body2-light cds-coral-text dark-1 l-mt-3">{{ 'calendar.addCommissionLimit' | lang }}</div>
        <div class="l-d-f l-ai-cen l-pt-5" *ngIf="!hasNextYearCommissionEvent && isLastTwoMonths">
          <span class="cds-body1 l-mb-0">{{ 'calendar.event.year' | lang }}</span>
          <span class="cds-h3 l-pl-3 l-mb-0 cds-demibold">{{ calendarService.nextYear }}</span>
        </div>
        <div class="row l-plr-0 l-pt-4 l-pb-4" *ngIf="!hasNextYearCommissionEvent && isLastTwoMonths">
          <div>
            <app-update-commission-event (valueChange)="commissionChange($event)" class="col-xs-12 col-md-12 col-lg-11 l-plr-0"></app-update-commission-event>
          </div>
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [style]="'secondary'" size="sm" (click)="cancle()">{{ 'common.cancel' | lang }}</cds-button>
    <cds-button size="sm" [disabled]="disabled" (click)="onSubmit()">{{ 'common.confirm' | lang }}</cds-button>
  </cds-popup-actions>
</app-cd-popup>
