import { Component, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfigActions } from '../../ThemeOptions/store/config.actions';
import { ThemeOptions } from '../../theme-options';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { NotifyService } from 'src/app/core/services/notify.service';
import { CdsOption } from '@cds/ng-core/configuration';
import { environment } from 'src/environments/environment';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { CannedService } from '../../_services/canned.service';
import { loadGoHomeFunc } from 'src/app/utils/utils';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  animations: [
    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column',
          }),
        ]),
        query(':enter', [animate('100ms ease', style({ opacity: 1, transform: 'translateY(0)' }))], { optional: true }),

        query(':leave', [animate('100ms ease', style({ opacity: 0, transform: 'translateY(-20px)' }))], { optional: true }),
      ]),
    ]),
  ],
  styleUrls: ['../../styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseLayoutComponent {
  langList: CdsOption[] = environment.languageList;
  selectedLang = 'en';
  isHideNavigationLang = false;
  private sharData: Subject<string> = new Subject();
  constructor(
    public globals: ThemeOptions,
    public configActions: ConfigActions,
    private languageChangeService: LanguageChangeService,
    private authenticationService: AuthenticationService,
    private cannedService: CannedService,

    public router: Router,
    private notifyService: NotifyService
  ) {}

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  logout() {
    let loginType = localStorage.getItem('loginType');
    if (!loginType) {
      loginType = 'sso';
    }
    // this.authenticationService.logout(loginType, this.router)
  }

  ngAfterViewInit(): void {
    loadGoHomeFunc(() => {
      this.router.navigate(['/home']);
    });
  }

  ngOnInit(): void {
    const styleTags = document.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
      const styleTag = styleTags[i] as any;
      if (styleTag.textContent.includes('chatbot-admin-style-marker-deletion-prohibited')) {
        if (styleTag.hasAttribute('media')) {
          styleTag.removeAttribute('media');
        }
      }

      this.notifyService.getHideNavigationLang().subscribe(boo => {
        setTimeout(() => {
          this.isHideNavigationLang = boo;
          const mobileNavigation = document.querySelector('div.cds-mobile-menu-util.order-1 cds-navigation-dropdown-utility');
          if (mobileNavigation && mobileNavigation.parentElement) {
            (mobileNavigation.parentElement as HTMLElement).style.display = boo ? 'none' : 'unset';
          }
        }, 100);
      });
    }
  }

  changeLang(lang: string) {
    this.selectedLang = lang;
    this.languageChangeService.setLang(lang);
    this.sendData(lang);
  }

  sendData(value: string) {
    this.sharData.next(value);
  }

  TerminateChat() {
    this.cannedService.terminateChat().subscribe((resp: any) => {});
  }

  ngOnDestroy(): void {
    const styleTags = document.getElementsByTagName('style');
    for (let i = 0; i < styleTags.length; i++) {
      const styleTag = styleTags[i] as any;
      if (styleTag.textContent.includes('chatbot-admin-style-marker-deletion-prohibited')) {
        styleTag.setAttribute('media', 'max-width: 1px');
      }
    }
  }
}
