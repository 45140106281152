import { CalendarEvent } from 'angular-calendar';

export enum EventTypeKey {
  A_C_MONTH_END = 'A_C_MONTH_END',
  PROC_DATE = 'PROC_DATE',
  COMMISSION = 'COMMISSION',
  HOLIDAY = 'HOLIDAY',
}

export enum EventStatus {
  REJECTED = 'REJECTED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  PENDING_APPROVE = 'PENDING_APPROVE',
  APPROVED = 'APPROVED',
  REVOKED = 'REVOKED',
  REJECTED_WITHDRAW = 'REJECTED_WITHDRAW',
}

export interface Category {
  title: string;
  color: string;
  children: Array<EventTypeCheck>;
}

export interface EventType {
  key: string;
  color: string;
  title: string;
  editable: boolean;
  addable: boolean;
  fileName?: string;
  sequence?: number;
}

export interface EventTypeCheck extends EventType {
  checked: boolean;
}

export interface CalendarEventNew extends CalendarEvent {
  eventId: string;
  eventType: string;
  editable: boolean;
  sequence: number;
  eventStr: string;
}

export interface DayCardEvent {
  id: string;
  type: string;
  from: string;
  to?: string;
  reportNo?: string;
  actionDisplay: boolean;
}

export interface QueryEventParams {
  eventId?: string;
  eventType?: string;
  reportNo?: string;
  eventStartDate?: string;
  eventEndDate?: string;
}

export interface AddEventItem {
  eventType?: string;
  eventDate?: string;
  eventStartDate?: string;
  eventId?: string;
  eventEndDate: string;
}

export interface EventItem extends AddEventItem {
  eventId: string;
  reportNo?: number;
}

export interface EventTableItem extends EventItem {
  errorMsg?: string;
  actionDisplay?: boolean;
  eventDateStr: string;
  isChange?: boolean;
  editable: boolean;
  isShowDatePicker?: boolean;
}

export interface ReviewEventParam {
  commitId: string;
  isApprove: boolean;
}

export interface GetReviewEventParam {
  year: string;
  isApprove?: boolean;
}
