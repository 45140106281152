<div class="row">
  <div class="col-xs-12 l-pt-7">
    <div class="l-pb-2 l-mb-5">
      <span class="cds-h4 cds-demibold cds-green-text">{{ 'common.filterSearch' | lang }}</span>
    </div>

    <div class="col-xs-12 l-pt-4 l-mb-7">
      <form [formGroup]="reportForm" (ngSubmit)="search()">
        <div class="row l-plr-0">
          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-mb-18 l-d-f l-pr-4">
            <cds-form-field class="l-fg-1" *ngIf="isShowSearchDatePicker">
              <cds-textfield
                [label]="'commission.reportCenter.occurDateFrom' | lang"
                placeholder="DD/MM/YYYY"
                [cdsDatepicker]="OccurDateFromPicker"
                [formControl]="occurDateFrom">
              </cds-textfield>
              <cds-datepicker #OccurDateFromPicker="cdsDatepicker"></cds-datepicker>
              <cds-assistive-text *ngIf="occurDateFrom.errors && occurDateFrom.dirty">{{ occurDateFrom.errors['error'] | lang }}</cds-assistive-text>
              <cds-assistive-text *ngIf="reportForm.errors && reportForm.dirty">{{ reportForm.errors['error'] | lang }}</cds-assistive-text>
            </cds-form-field>
            <div class="cds-body1 l-pl-4 l-pt-5 line-box desktop-md-hide">-</div>
          </div>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18" *ngIf="isShowSearchDatePicker">
            <cds-textfield
              [label]="'commission.reportCenter.occurDateTo' | lang"
              placeholder="DD/MM/YYYY"
              [cdsDatepicker]="OccurDateToPicker"
              [formControl]="occurDateTo">
            </cds-textfield>
            <cds-datepicker #OccurDateToPicker="cdsDatepicker"></cds-datepicker>
            <cds-assistive-text *ngIf="occurDateTo.errors && occurDateTo.dirty">{{ occurDateTo.errors['error'] | lang }}</cds-assistive-text>
          </cds-form-field>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18">
            <app-cd-multidropdown
              formControlName="subCategory"
              [label]="'commission.reportCenter.subCategory.label' | lang"
              [placeholder]="'commission.reportCenter.subCategory.placeholder' | lang"
              [style.width]="'100%'"
              [toppingList]="subCategoryOptions"></app-cd-multidropdown>
          </cds-form-field>

          <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18">
            <cds-textfield
              [placeholder]="'commission.reportCenter.reportName.placeholder'"
              [label]="'commission.reportCenter.reportName.label'"
              formControlName="reportName">
            </cds-textfield>
          </cds-form-field>

          <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20">
            <div class="search-area-btn">
              <cds-button type="submit" [disabled]="searchDisabled" [style]="'primary'" size="md">
                {{ 'common.search' | lang }}
              </cds-button>
              <cds-button type="submit" (click)="reset()" [style]="'secondary'" size="md">
                {{ 'common.reset' | lang }}
              </cds-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-xs-12 l-pt-4">
      <app-cd-table-title class="l-mb-5" [title]="'common.recordList' | lang" [total]="getTotalCount"></app-cd-table-title>
      <div>
        <!-- <app-data-table
          [frontPagination]="false"
          [fixed]="false"
          [isLoading]="isDataLoading"
          (reloadTable)="pageChange($event)"
          [pageConfig]="pageConfig"
          [dataSource]="dataDisplayed"
          [columnsConfig]="columnsConfig">
        </app-data-table> -->
        <app-data-table [dataSource]="dataDisplayed" [columnsConfig]="columnsConfig"> </app-data-table>
      </div>
    </div>
  </div>
</div>
