/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';

import { CdPopupService, CdPopupSize, MatDialogRef } from 'src/app/shared/cd-popup';
import { EventTypeKey } from 'src/app/views/system/calendar/shared/calendar.model';
import { CalendarService } from 'src/app/views/system/calendar/shared/calendar.service';
import { ReportNoEventComponent } from '../shared/report-no-event/report-no-event.component';
import { ApproveCommissionEventComponent } from '../shared/approve-commission-event/approve-commission-event.component';

@Component({
  selector: 'app-approve-event',
  templateUrl: './approve-event.component.html',
  styleUrls: ['./approve-event.component.scss'],
})
export class ApproveEventComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ApproveEventComponent>, private cdsPopup: CdPopupService, public calendarService: CalendarService) {}

  ngOnInit(): void {}

  approveEvent(event: any) {
    if (event.eventType === EventTypeKey.COMMISSION) {
      this.approveCommissionEvent(event);
    }
    if (event.eventType === EventTypeKey.PROC_DATE) {
      this.approveReportNo(event);
    }
  }

  approveCommissionEvent(event: any) {
    const popupRef: MatDialogRef<ApproveCommissionEventComponent> = this.cdsPopup.open(ApproveCommissionEventComponent, {
      size: CdPopupSize.LARGE,
      data: {
        data: event,
        reviewId: event.reviewId,
      },
    });
    popupRef.afterClosed().subscribe(data => {
      if (data && data.isChange) {
        this.calendarService.approvalEvents = this.calendarService.approvalEvents.filter(item => item.eventType !== data.event.eventType);
      }
      this.isClose();
    });
  }

  approveReportNo(event: any) {
    const popupRef: MatDialogRef<ReportNoEventComponent> = this.cdsPopup.open(ReportNoEventComponent, {
      size: CdPopupSize.LARGE,
      panelClass: ['calendar-popup-bg'],
      data: {
        event,
        isReview: false,
      },
    });
    popupRef.afterClosed().subscribe(data => {
      if (data && data.isChange) {
        this.calendarService.approvalEvents = this.calendarService.approvalEvents.filter(item => item.eventType !== EventTypeKey.PROC_DATE);
      }
      this.isClose();
    });
  }

  isClose() {
    if (!this.calendarService.approvalEvents.length) {
      this.dialogRef.close();
    }
  }
}
