import { Component, Input, OnInit } from '@angular/core';
import { copyText } from 'src/app/utils/utils';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent implements OnInit {
  @Input() value = '';
  isShowCopied = false;

  constructor() {}

  ngOnInit(): void {}

  copy() {
    if (this.isShowCopied) {
      return;
    }
    copyText(this.value);
    this.isShowCopied = true;
    setTimeout(() => {
      this.isShowCopied = false;
    }, 2000);
  }
}
