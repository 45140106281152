<app-cd-page-title title="{{ 'commission.errorLog.title' | lang }}"> </app-cd-page-title>
<div class="row">
  <p class="col-xs-12 cds-body1 l-mt-7 l-mb-7">{{ 'commission.errorLog.introduction' | lang }}</p>
  <div class="col-xs-12 l-pt-4 l-mb-7">
    <form [formGroup]="formGroup" (ngSubmit)="search()">
      <div class="cds-h2 cds-light l-mb-18">
        {{ 'commission.pool.common.searchRecord' | lang }}
      </div>
      <div class="row l-plr-0">
        <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-mb-18 l-d-f l-pr-4">
          <cds-form-field class="l-fg-1" *ngIf="isShowSearchDatePicker">
            <cds-textfield
              [label]="'commission.errorLog.occurDateFrom' | lang"
              placeholder="DD/MM/YYYY"
              [cdsDatepicker]="OccurDateFromPicker"
              [formControl]="occurDateFrom">
            </cds-textfield>
            <cds-datepicker #OccurDateFromPicker="cdsDatepicker"></cds-datepicker>
            <cds-assistive-text *ngIf="occurDateFrom.errors && occurDateFrom.dirty">{{ occurDateFrom.errors['error'] | lang }}</cds-assistive-text>
            <cds-assistive-text *ngIf="formGroup.errors && formGroup.dirty">{{ formGroup.errors['error'] | lang }}</cds-assistive-text>
          </cds-form-field>
          <div class="cds-body1 l-pl-4 l-pt-5 line-box desktop-md-hide">-</div>
        </div>
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18" *ngIf="isShowSearchDatePicker">
          <cds-textfield
            [label]="'commission.errorLog.occurDateTo' | lang"
            placeholder="DD/MM/YYYY"
            [cdsDatepicker]="OccurDateToPicker"
            [formControl]="occurDateTo">
          </cds-textfield>
          <cds-datepicker #OccurDateToPicker="cdsDatepicker"></cds-datepicker>
          <cds-assistive-text *ngIf="occurDateTo.errors && occurDateTo.dirty">{{ occurDateTo.errors['error'] | lang }}</cds-assistive-text>
        </cds-form-field>
      </div>
      <div class="row l-plr-0">
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18">
          <cds-textfield
            [label]="'commission.common.accountNo' | lang"
            [placeholder]="'commission.common.accountNo.placeholder' | lang"
            [formControl]="employerAccountCode">
          </cds-textfield>
          <cds-assistive-text *ngIf="employerAccountCode.errors && employerAccountCode.dirty">{{
            employerAccountCode.errors['error'] | lang
          }}</cds-assistive-text>
        </cds-form-field>
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20 l-mb-18">
          <cds-textfield
            [label]="'commission.common.memberNo' | lang"
            [placeholder]="'commission.common.placeholder.memberNo' | lang"
            [formControl]="memberAccountCode">
          </cds-textfield>
          <cds-assistive-text *ngIf="memberAccountCode.errors && memberAccountCode.dirty">{{ memberAccountCode.errors['error'] | lang }}</cds-assistive-text>
        </cds-form-field>
        <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20">
          <cds-textfield
            [label]="'commission.errorLog.referenceNo' | lang"
            class="l-pb-3"
            [placeholder]="'commission.common.referenceNo.placeholder' | lang"
            [formControl]="referenceNo">
          </cds-textfield>
          <cds-assistive-text *ngIf="referenceNo.errors && referenceNo.dirty">{{ referenceNo.errors['error'] | lang }}</cds-assistive-text>
        </cds-form-field>
        <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20">
          <div class="search-area-btn">
            <cds-button type="submit" [disabled]="searchDisabled" [style]="'primary'" size="md">
              {{ 'common.search' | lang }}
            </cds-button>
            <cds-button type="submit" (click)="reset()" [style]="'secondary'" size="md">
              {{ 'common.reset' | lang }}
            </cds-button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-xs-12 l-pt-4">
    <app-cd-table-title class="l-mb-5" [title]="'common.recordList' | lang" [total]="getTotalCount"></app-cd-table-title>
    <div>
      <app-data-table
        [frontPagination]="false"
        [fixed]="false"
        [isLoading]="isDataLoading"
        (reloadTable)="pageChange($event)"
        [pageConfig]="pageConfig"
        [dataSource]="dataDisplayed"
        [columnsConfig]="columnsConfig">
        <ng-template appDtBodyCellDef="commissionPoolId" let-element>
          <span>{{ element.commissionPoolId | slice : 0 : 8 }}</span>
        </ng-template>
      </app-data-table>
    </div>
  </div>
</div>
