import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { MdStatusValue } from 'src/app/config/iff-status.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { NotifyService } from 'src/app/core/services/notify.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TransferStatusService } from 'src/app/core/services/transfer-status/transfer-status.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { cdsPopupBackdropOntouchmovePreventDefault, cdsPopupBackdropUnOntouchmovePreventDefault, pageToTop } from 'src/app/utils/utils';
import { AddMdReferenceNumberPopupComponent } from '../add-md-reference-number-popup/add-md-reference-number-popup.component';
import { AddMpfReferenceNumberPopupComponent } from '../add-mpf-reference-number-popup/add-mpf-reference-number-popup.component';
import { EE_DECLINED_REASON_OPTIONS } from '../case-summary/case-summary.config';
import { EmpfAppStatusPopupComponent } from '../empf-app-status-popup/empf-app-status-popup.component';
import { IffStatusFlowPopupComponent } from '../iff-status-flow-popup/iff-status-flow-popup.component';
import { RefNoType } from '../md-iff-summary/md-iff-summary.model';
import { ResendEiffComponent } from '../resend-eiff/resend-eiff.component';
import { ResendEiffResendType } from '../resend-eiff/resend-eiff.model';
import { EECaseDetailsResponse } from './empf-case-details.model';

type RolePriority = 'SuperAdmin' | 'SalesSupport' | 'SalesJourneyAgent';

@Component({
  selector: 'app-empf-case-details',
  templateUrl: './empf-case-details.component.html',
  styleUrls: ['./empf-case-details.component.scss'],
})
export class EmpfCaseDetailsComponent implements OnInit {
  IffStatusValue = MdStatusValue;
  backErIcon = ActionIcons.button_left_filled;
  backErIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  iconConfig: CdsIconConfig = {
    size: 'sm',
    color: 'default',
  };
  infoIcon = ActionIcons.info_1;
  isOpenDbs = false;
  hasSalesSupportRole = false;
  hasSalesJourneyAgentRole = false;
  hasSalesJourneySuperAdmin = false;
  rolePriority?: RolePriority;
  caseDetailsResponse?: EECaseDetailsResponse;
  //  = {
  //   "status": {
  //     "agentIFFCompleted": true,
  //     "splitAgentCompleted": true,
  //     "customerIFFCompleted": true,
  //     "empfMatched": false
  //   },
  //   "mdCaseDetail":
  //   {
  //     "resendAble": true,
  //     "mdNo": "MD-2-2",
  //     "mdStatus": IffStatusValue.Completed,
  //     "submissionDate": "25/09/2024",
  //     "resendDate": "25/09/2024",
  //     "commChannel": "SMS",
  //     "phoneCountry": "+86",
  //     "phone": '18888888888',
  //     "email": "xxdfd@xxx.com",
  //     "clientName": "xiao2 hans2"
  //   },
  //   "empfCaseDetail":
  //   {
  //     "empfNo": "empf-1",
  //     "empfStatus": EMPF_App_Status_Value.Not_Applicable,
  //     "email": "xxdfd@xxx.com",
  //     "phone": '18888888888',
  //     "submissionDate": "25/09/2024",
  //     "clientName": "l1",
  //     "dob": "15/10/2024"
  //   },
  //   "serviceAgent": {
  //     "agentCode": "334065",
  //     "agentName": "?1",
  //     "isRgltdActAck": true,
  //     "shareHKPC": 0.5,
  //     "shareCommission": 0.5
  //   },
  //   "splitAgent": [{
  //     "agentCode": "683395",
  //     "agentName": "?2",
  //     "isRgltdActAck": false,
  //     "shareHKPC": 0.5,
  //     "shareCommission": 0.5
  //   },
  //   {
  //     "agentCode": "683395",
  //     "agentName": "?2",
  //     "isRgltdActAck": false,
  //     "shareHKPC": 0.4,
  //     "shareCommission": 0.6
  //   }
  //   ],
  //   "transfer": [{
  //     "productType": "PA",
  //     status: TransferStatusValue.TRANSFER_FOLLOW_UP,
  //     "trusteeName": {
  //       "originalScheme": "xxx",
  //       "MILScheme": "xxxx"
  //     },
  //     "schemeName": {
  //       "originalScheme": "xxx",
  //       "MILScheme": "xxxx"
  //     },
  //     "schemeMemberAccount": {
  //       "originalScheme": "xxx",
  //       "MILScheme": "xxxx"
  //     },
  //     "accountType": {
  //       "originalScheme": "xxx",
  //       "MILScheme": "xxxx"
  //     },
  //     "rejectReason": [1, 2, 3, 4, 5]
  //   }
  //   ]
  // }
  no?: string;
  refNoType?: RefNoType;

  constructor(
    private router: Router,
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    public empfAppStatusService: EmpfAppStatusService,
    private permissionService: PermissionService,
    private cdHttpServeService: CdHttpServeService,
    public transferStatusService: TransferStatusService,
    private alert: CdsAlertService,
    private notifyService: NotifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.no = this.route.snapshot.paramMap.get('no') || undefined;
    this.refNoType = (this.route.snapshot.paramMap.get('refNoType') as RefNoType) || undefined;
    this.initRole().then(() => {
      this.getCaseDetail().then(() => {
        this.initRejectReasons();
      });
    });
  }

  getCaseDetail() {
    return new Promise<void>((resolve, reject) => {
      let urlRolePart = '';
      switch (this.rolePriority) {
        case 'SalesJourneyAgent':
          urlRolePart = 'agent';
          break;
        case 'SalesSupport':
          urlRolePart = 'support';
          break;
        case 'SuperAdmin':
          urlRolePart = 'admin';
          break;
        default:
          break;
      }
      let urlRefNoTypePart = '';
      let urlParamPart;
      switch (this.refNoType) {
        case RefNoType.MD:
          urlRefNoTypePart = 'caseDetailByMd';
          urlParamPart = { mdNo: this.no };
          break;
        case RefNoType.EMPF:
          urlRefNoTypePart = 'caseDetailByEMPF';
          urlParamPart = { empfNo: this.no };
          break;

        default:
          break;
      }

      if (!urlRolePart || !urlRefNoTypePart || !urlParamPart) {
        return;
      }

      this.notifyService.screenLoadingSubject.next(true);
      this.cdHttpServeService.get<EECaseDetailsResponse>(`/ext/eb-ssms-sales-journey-service/sj/${urlRefNoTypePart}/${urlRolePart}`, urlParamPart).subscribe({
        next: res => {
          if (res.result !== 0) {
            this.alert.warning('Warning!', `${res.message}`);
            reject();
          } else {
            this.caseDetailsResponse = res.data;
            resolve();
          }
          this.notifyService.screenLoadingSubject.next(false);
        },
        error: err => {
          reject();
          this.alert.error('Error!', err);
          this.notifyService.screenLoadingSubject.next(false);
        },
      });
    });
  }

  initRejectReasons() {
    this.caseDetailsResponse?.transfer?.forEach(t => {
      t.rejectReasonCombination = t.rejectReason.map(item => {
        const option = EE_DECLINED_REASON_OPTIONS.find(o => o.value === item + '');
        if (option) {
          return {
            title: option.label,
            content:
              'Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis.',
            isExpand: false,
          };
        } else {
          return {
            title: 'N/A',
            content:
              'Lorem ipsum dolor sit amet. Qui autem impedit est tenetur reiciendis et sunt rerum qui quisquam animi et repudiandae rerum ab cumque dolore ut accusamus ipsum. Doloribus aliquid quo delectus atque aut corporis eius eum maiores optio in autem consequatur quo quidem officiis.',
            isExpand: false,
          };
        }
      });
    });
  }

  private async initRole() {
    this.hasSalesJourneySuperAdmin = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SUPER_ADMIN);
    this.hasSalesSupportRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SALES_SUPPORT);
    this.hasSalesJourneyAgentRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_AGENT);
    if (this.hasSalesJourneySuperAdmin) {
      this.rolePriority = 'SuperAdmin';
    } else if (this.hasSalesSupportRole) {
      this.rolePriority = 'SalesSupport';
    } else if (this.hasSalesJourneyAgentRole) {
      this.rolePriority = 'SalesJourneyAgent';
    }
  }

  getMdDetailByMdNo() {
    this.cdHttpServeService.post;
  }

  onBack() {
    this.router.navigate(['/salesjourney/md-iff-summary']);
  }

  toTop() {
    pageToTop();
  }

  onDisplayAdviseIFF() {
    this.cdsPopup.open(IffStatusFlowPopupComponent, {
      size: 'sm',
    });
  }

  onDisplayAdviseEmpf() {
    this.cdsPopup.open(EmpfAppStatusPopupComponent, {
      size: 'sm',
    });
  }

  onOpenAddEmpfRefNoPopup(mdNo: string) {
    this.cdsPopup.open(AddMpfReferenceNumberPopupComponent, {
      size: 'sm',
      data: {
        mdNo: mdNo,
        prodType: 'EE',
      },
    });
  }

  onOpenAddMDRefNoPopup(empfNo: string) {
    this.cdsPopup.open(AddMdReferenceNumberPopupComponent, {
      size: 'sm',
      data: {
        empfNo: empfNo,
        prodType: 'EE',
      },
    });
  }

  openResendEIFF() {
    const ref = this.cdsPopup.open(ResendEiffComponent, {
      size: 'md',
      data: {
        mdNo: this.caseDetailsResponse?.mdCaseDetail?.mdNo,
        resendType: ResendEiffResendType.EE,
        mdStatus: this.caseDetailsResponse?.mdCaseDetail?.mdStatus,
        empfStatus: this.caseDetailsResponse?.empfCaseDetail?.empfStatus,
      },
    });
    ref.afterClosed().subscribe(({ isRefresh }) => {
      if (isRefresh) {
        location.reload();
      }
      cdsPopupBackdropUnOntouchmovePreventDefault();
    });

    ref.afterOpened().subscribe(() => {
      cdsPopupBackdropOntouchmovePreventDefault();
    });
  }

  onMDIFFStatusShowToggle(event: MouseEvent) {
    if (event.target) {
      event.target.dispatchEvent(new Event('click'));
    }
  }

  onToggleDbs() {
    this.isOpenDbs = !this.isOpenDbs;
  }

  onGoEditAgentDetails() {
    this.router.navigate(['/salesjourney/edit-agent-details', this.caseDetailsResponse?.mdCaseDetail?.mdNo, { type: 'EE' }]);
  }
}
