/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, HostListener, ChangeDetectorRef, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { finalize, Subject } from 'rxjs';
import { ChatService } from '../_service/chat.service';
import { MessageService } from '../_service/message.service';
import { Helper } from '../_common/helper';
import { APIPaths } from '../_common/constant';
import { Alerts } from '../../chatbot-admin/_common/alerts';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { CloseChatComponent } from '../angent/close-chat/close-chat.component';
import { IdleService } from '../../chatbot-admin/_services/idle.service';
import { CannedService } from '../../chatbot-admin/_services/canned.service';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { CloseChatConfirmComponent } from '../close-chat-confirm/close-chat-confirm.component';

export interface Imsg {
  id: any;
  msg: string;
  msg_Code: any;
  type: string;
}
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  @ViewChild('messageContainer') messageContainer: any;
  @ViewChild('cbChatbot', { static: false }) cbChatbot!: ElementRef;

  chats: any[] = [
    // {
    //   "msg": "Hi, good day. I will try my best to answer your questions about MPF business. Please select below topics of enquiry.",
    //   "enabled": false,
    //   "chineseMsg": "您好，我會盡我所能為您解答強積金業務方面的疑問。請選擇以下詢問主題。"
    // },
  ];

  wecloemsg = {
    en: {
      head: 'Welcome',
      msg: 'This Chatbot and Live Chat function assist you in finding answers to support your MPF business and provides the latest MPF information.',
    },
    zh: {
      head: '歡迎',
      msg: '此聊天機械人及實時真人對話功能可協助您尋找支援您的強積金業務的答案，並提供最新的強積金資訊。',
    },
  };
  isShowChatBot = false;
  showBotOnMobile = true;
  showBack = false;
  isShowChatBotIcon = true;
  isShowChatBotCloseIcon = false;
  chatJson: any = [];
  canedList: any = [];
  oldcanedList: any = [];
  roomId: any = null;
  encodRoom: any = null;
  agrement = false;
  message: any;
  liveChat = false;
  index = 0;
  groupNumber = false;
  introJson: any[] = [];
  introServiceson: any[] = [];
  groupNo: any = '';
  certNo: any = '';
  token: any = '';
  username = '';
  noCsoAvailable: boolean = false;
  allCsobusy: boolean = false;
  userInfo: any;
  botUser: any;
  roomInfo: any;
  chatlogs: any;
  lang: any;
  showNext: boolean = false;
  showLiveButton: boolean = false;
  screenWidth: number = 0;
  private reloadSubject = new Subject<{ roomId: string; verify: boolean } | undefined>();
  constructor(
    public chatService: ChatService,
    public messageService: MessageService,
    public idleService: IdleService,
    public route: ActivatedRoute,
    public modalService: NgbModal,
    public router: Router,
    private cdsPopup: CdsPopupService,
    public cannedServices: CannedService,
    public languageService: LanguageChangeService,
    public permissionService: PermissionService,
    public cdk: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    this.languageService.initLanNotice.subscribe(res => {
      this.lang = res;
      // Alerts.showErrorMessage('You are idle for 5 minutes, Please click on the screen to continue');
    });
    this.renderer.listen('window', 'click', (event: Event) => {
      this.closePopUpIfClickedOutside(event);
    });
    this.roomId = localStorage.getItem('roomId');
    let verify = localStorage.getItem('verify');

    if (this.roomId && verify == 'true') {
      this.agrement = true;
      this.groupNumber = true;
      this.isShowChatBot = true;
      this.liveChat = true;
      this.gerRoomInfo();
      this.chatService.getUserChatHistory(this.roomId).subscribe(resp => {
        if (resp) {
          let check = resp.find((item: any) => item.userType == 'AGENT' && item.sender != 'BOT');
          if (!check) {
            this.getIdleUser();
          }
          this.chatJson = resp || [];
          this.messageService.initializeWebSocketConnection(this.roomId, 'User!').then(() => {
            this.scrollToBottom();
          });
        }
      });
    } else if (this.roomId && verify == 'false') {
      this.agrement = true;
      this.groupNumber = false;
      this.isShowChatBot = true;
      this.chatService.getUserChatHistory(this.roomId).subscribe(resp => {
        if (resp) {
          this.chatJson = resp || [];
          this.messageService.initializeWebSocketConnection(this.roomId, 'User!').then(() => {
            this.chatService.getParent().subscribe(resp => {
              if (resp) {
                this.chats = resp;
                this.canedList = resp;
                this.scrollToBottom();
              }
            });
          });
        }
      });
    }
    this.getBotUser();
    this.permissionService.getUserInfo().then(info => {
      this.token = info.token;
      this.username = info.displayName;
      this.userInfo = info;
    });
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkChatbotWidth();
  }
  ngAfterViewInit() {
    this.checkChatbotWidth();
  }

  private checkChatbotWidth() {
    this.screenWidth = this.cbChatbot?.nativeElement?.offsetWidth;
    console.log('cb-chatbot width:', this.screenWidth);
    // Assuming mobile width to be less than 768px
    if (window.innerWidth < 426) {
      if (this.isShowChatBot) {
        this.showBotOnMobile = false;
      }

      console.log('Mobile screen detected');
      // Perform any additional actions if needed
    } else {
      this.showBotOnMobile = true;
    }
  }

  ngOnInit(): void {
    this.idleService.onUserIdle.subscribe(() => {
      this.noCsoAvailable = true;
      // Alerts.showErrorMessage('You are idle for 5 minutes, Please click on the screen to continue');
    });

    this.message = this.messageService.msg;
    this.messageService.receiveMessages().subscribe((messages: any) => {
      console.log('Received messages:', messages);
      if (messages.type != 'LEAVE') {
        this.chatJson.push(messages);
      } else {
        this.gerRoomInfo();
        localStorage.removeItem('roomId');
        localStorage.removeItem('verify');
      }
      this.scrollToBottom();
      if (messages.userType === 'AGENT' && messages.sender != 'BOT') {
        this.idleService.clearTimer();
      }
      this.cdk.detectChanges();
    });
    this.getServicesAgree();
  }

  getBotUser() {
    this.chatService.getBotUser().subscribe(resp => {
      this.botUser = resp;
    });
  }

  liveChatEnable() {
    this.liveChat = true;
    this.chats = [];
    // this.chatJson.push();
    const modal = {
      type: 'CHAT',
      content: this.lang == 'en' ? 'Please wait when we are connecting you to our Customer Service Officer.' : '請稍候，我們正在為您連接到我們的客戶服務主任。',
      userId: this.botUser.identityId,
      userType: 'AGENT',
      sender: 'BOT',
    };
    this.messageService.sendMessage(modal, this.roomId);
    this.getIdleUser();
    this.gerRoomInfo();

    // this.chatJson=this.messageService.receiveMessages()
    // element.value = '';
  }

  closePopUpIfClickedOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    const chatPopup = document.querySelector('.cb-userchatbot');

    // If the click target is outside the chat popup, close it
    if (this.showPopUp && chatPopup && !chatPopup.contains(targetElement)) {
      this.showPopUp = false;
    }
  }

  checkGroupNo() {
    this.liveChat = true;
    this.groupNumber = false;
    this.chats = [];
    const model = {
      type: 'CHAT',
      content:
        this.lang == 'en'
          ? `<p>If the enquiry is policy-related, please provide the customer's policy number in the fields below. If it is a general enquiry, please leave the fields blank. Then please press the <img src="./assets/icons/play.png " width="31" height="20" alt="Send"/> button on the right to connect to CSO live chat.</p>`
          : '如果是與保單相關查詢，請於下方欄位提供客戶的保單號碼。如果是一般查詢，請將欄位留空。之後請按右側的 <img src="./assets/icons/play.png" width="31" height="20" alt="Send"/> 按鈕連接到 CSO 即時聊天',
      userId: '',
      userType: 'AGENT',
      sender: 'BOT',
    };
    this.chatJson.push(model);
    // this.messageService.sendMessage(mode, this.roomId);
    // this.chatJson=this.messageService.receiveMessages()
  }

  sendGroupNo() {
    if (this.groupNo || (!this.certNo && !this.groupNo)) {
      this.chatService
        .checkGroupNo(this.certNo || null, this.groupNo || null, this.userInfo.id, this.roomId)
        .pipe(finalize(() => {}))
        .subscribe((data: any) => {
          if (data.result === -99) {
            Alerts.showErrorMessage(data.message);
          } else {
            this.checkAvailableCSO();
          }
        });
    } else {
      Alerts.showErrorMessage('You must input both Group No. and Cert No');
    }
  }

  checkAvailableCSO() {
    this.chatService.checkAvailableCSO().subscribe((resp: any) => {
      if (resp?.status) {
        this.groupNumber = true;
        localStorage.setItem('verify', 'true');
        this.liveChatEnable();
      } else {
        this.allCsobusy = true;
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.groupNumber && this.liveChat) {
        this.sendMessage();
      }
    }
  }

  backToMessage() {
    this.chats = this.canedList;
    this.showBack = false;
    this.oldcanedList = [];
    this.showLiveButton = false;
  }

  backToPrevousMessage() {
    this.showBack = this.oldcanedList.length > 0 ? true : false;
    this.showLiveButton = false;
    let item = this.oldcanedList.pop();
    if (item.parentId) {
      this.chatService.getParentById(item.parentId).subscribe(resp => {
        if (resp) {
          this.chats = resp;
        }
      });
    } else {
      this.chats = [item];
    }
  }

  async sendMessage() {
    const element = document.getElementById('chatMessage') as HTMLInputElement;
    if (element.value) {
      const val = Helper.maskPII(element.value);
      const mode = {
        type: 'CHAT',
        content: val,
        userId: this.userInfo.id,
        userType: 'USER',
        sender: this.username,
      };
      this.messageService.sendMessage(mode, this.roomId);
      this.scrollToBottom();
      // this.chatJson=this.messageService.receiveMessages()
      element.value = '';
    }
  }
  sendNumber() {}

  inrecmentIndex() {
    if (this.index + 1 < this.introJson.length) {
      this.index = this.index + 1;
    } else {
      this.index = 0;
    }
    this.introJson.forEach((item: any, i: any) => {
      if (this.index == i) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    this.cdk.detectChanges();
  }

  previousIndex() {
    if (this.index - 1 >= 0) {
      this.index = this.index - 1;
    } else {
      this.index = this.introJson.length - 1;
    }
    this.introJson.forEach((item: any, i: any) => {
      if (this.index == i) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    this.cdk.detectChanges();
  }

  clickinrecmentIndex(ind: any) {
    this.introJson.forEach((item: any, i: any) => {
      if (ind == i) {
        this.index = ind;
        item.active = true;
      } else {
        item.active = false;
      }
    });
  }
  // /topic/public / { room }
  //   / chat.addUser / { room }
  // {
  //   "type": "",
  //     "content" : "",
  //       "sender" : ""
  // }

  getCanedResponse() {
    this.chatService.getParent().subscribe(resp => {
      if (resp) {
        if (!this.roomId) {
          this.chatService.getNewChatRoom(this.userInfo?.id).subscribe(val => {
            this.roomId = val.roomId;
            localStorage.setItem('roomId', this.roomId);
            localStorage.setItem('verify', 'false');
            this.messageService.initializeWebSocketConnection(this.roomId, 'User!').then(() => {
              this.chats = resp;
              this.canedList = resp;
              this.encodRoom = btoa(this.roomId);
              this.reloadSubject.next({ roomId: this.encodRoom, verify: false });
              this.cdk.detectChanges();
            });
          });
        } else {
          this.chats = resp;
          this.canedList = resp;
          this.cdk.detectChanges();
        }
      }
    });
  }

  onShowChatbot() {
    this.isShowChatBot = true;
    if (this.screenWidth < 425) {
      this.showBotOnMobile = false;
    }
    this.isShowChatBotIcon = false;
    this.isShowChatBotCloseIcon = true;
    this.cdk.detectChanges();
  }

  onHideChatbot() {
    this.isShowChatBot = false;
    if (this.screenWidth < 425) {
      this.showBotOnMobile = true;
    }
    this.isShowChatBotIcon = true;
    this.isShowChatBotCloseIcon = false;
  }

  onFileSelected(event: any) {
    console.log(event);
  }

  onFileChange(event: any) {
    this.uploadFile(event.target.files[0]);
    event.target.value = '';
  }

  uploadFile(file: any) {
    this.showPopUp = false;
    /* eslint-disable @typescript-eslint/no-this-alias */
    const that = this;
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    formData.append('file', file);
    this.fileUploaded(xhr, that, (data: any) => {
      data;
    });

    if (xhr.upload) {
      this.updateProgress(xhr, (data: any) => {
        // this.statusBar = data;
        data;
      });
    }
    xhr.open('POST', APIPaths.upload, true);
    xhr.setRequestHeader('Authorization', `${this.token}`);
    xhr.setRequestHeader('DisplayName', encodeURIComponent(this.userInfo?.displayName));
    xhr.setRequestHeader('Email', this.userInfo.email);
    xhr.setRequestHeader('Id', this.userInfo.id);
    xhr.setRequestHeader('CurrentLastUpdate', this.userInfo.currentLastUpdate.toString());
    xhr.setRequestHeader('Permissions', JSON.stringify(this.userInfo.permissions));
    xhr.send(formData);
  }

  fileUploaded(xhr: any, that: any, callback: any) {
    callback;
    xhr.onreadystatechange = function (e: any) {
      e;
      if (xhr.readyState === 4) {
        //Done
        if (xhr.status === 200) {
          //Loading/Done

          const response = JSON.parse(xhr.response);
          const mode = {
            type: 'FILE',
            content: xhr.response,
            userId: that.userInfo?.id,
            userType: 'USER',
            sender: that.username,
          };
          that.messageService.sendMessage(mode, that.roomId);
        }
      } else if (xhr.readyState === 1) {
        //loading
      }
    };
  }

  updateProgress(xhr: any, callback: any) {
    xhr.upload.onprogress = function (e: any) {
      if (e.lengthComputable) {
        this.statusBar = (e.loaded / e.total) * 100;
        callback((e.loaded / e.total) * 100);
      }
    };
    xhr.upload.onloadstart = function (e: any) {
      e;
      callback(0);
    };
    xhr.upload.onloadend = function (e: any) {
      e;
      callback(100);
    };
  }

  getServicesAgree() {
    this.chatService
      .getServicesAgree()
      .pipe(finalize(() => {}))
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.introJson = data;
          this.introJson[0].active = true;
        }
      });
  }

  getresponce(item: any) {
    this.showBack = false;
    this.showLiveButton = false;
    this.oldcanedList.push(item);
    this.chats = [];

    const model = {
      type: 'CHAT',
      content: this.lang == 'en' ? item.msg : item.chineseMsg,
      userId: this.userInfo.id,
      username: this.username,
      sender: this.username,
      userType: 'USER',
    };
    this.messageService.sendMessage(model, this.roomId);
    // this.chatJson.push(model);

    setTimeout(() => {
      let ag = {
        type: 'CHAT',
        content: this.lang == 'en' ? item.ans : item.chineseAns,
        userId: this.botUser.identityId,
        userType: 'AGENT',
        sender: 'BOT',
      };

      this.messageService.sendMessage(ag, this.roomId);
    }, 300);
    setTimeout(() => {
      this.chatService.getParentById(item.id).subscribe(resp => {
        if (resp) {
          this.chats = resp;
          this.showLiveButton = item.enabled;

          this.showBack = true;
        }
      });
    }, 1000);
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        if (this.messageContainer?.nativeElement) this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight + 350;
      }, 300);
    } catch (err) {}
  }
  gerRoomInfo() {
    this.chatService.getRoomInfo(this.roomId).subscribe(resp => {
      console.log(resp);
      this.roomInfo = resp.room;
      this.chatlogs = resp.log;
      this.cdk.detectChanges();
    });
  }

  showPopUp = false;
  showModal(event: Event) {
    event.stopPropagation();
    this.showPopUp = !this.showPopUp;
  }
  modalOptions: NgbModalOptions = {};
  modalRef: any;

  onCloseChatModal(model?: any, content?: any) {
    const popupRef = this.cdsPopup.open(CloseChatConfirmComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(data => {
      console.log(data); // { agree: true/false }
      if (data) {
        let modal = {
          policyNumber: this.roomInfo?.group_no,
          certificateNumber: this.roomInfo?.cert_no,
          type: '',
          subType: '',
          enquiryType: '',
          contactName: '',
          status: '',
          description: '',
        };
        this.exitChat();
        // this.cannedServices.closeChat(modal, { roomId: this.roomId }, 1).subscribe((resp: any) => {

        // });
      }
    });
  }

  getPath(content: any) {
    let dat = JSON.parse(content);
    return dat.filePath;
  }

  exitChat() {
    this.reloadSubject.next(undefined);
    let modal = {
      policyNumber: this.roomInfo?.group_no,
      certificateNumber: this.roomInfo?.cert_no,
      type: '',
      subType: '',
      enquiryType: '',
      contactName: '',
      status: '',
      description: '',
    };
    this.cannedServices.closeChat(modal, { roomId: this.roomId }, 1).subscribe((resp: any) => {
      this.messageService.closeWebSocketConnection();
    });
    this.noCsoAvailable = false;
    this.allCsobusy = false;
    localStorage.removeItem('roomId');
    localStorage.removeItem('verify');
    this.chatlogs = null;
    this.liveChat = false;
    this.agrement = false;
    this.showNext = false;
    this.showPopUp = false;
    this.certNo = '';
    this.groupNo = '';
    this.chatJson = [];
    this.roomInfo = null;
    this.roomId = null;
    this.showBack = false;
    this.showLiveButton = false;
    this.cdk.detectChanges();
  }

  idleTimer: any;
  getIdleUser() {
    this.chatService.getAlert().subscribe((resp: any) => {
      let obj = resp.find((item: any) => item?.autoMsgType == 'NO_CSO');
      if (obj) {
        let sec = obj.minutes * 60 * 1000;
        this.idleService.startUserListening(sec);
      }
    });
  }
}
