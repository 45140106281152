import { CdsOption } from '@cds/ng-core/configuration';
import { deepCopy } from 'src/app/utils/copy';
import { Component, OnInit } from '@angular/core';
import { CdsButtonConfig } from '@cds/ng-core/button';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { EmployeeService } from '../employee.service';
import { CdsCheckboxConfig } from '@cds/ng-core/checkbox';
import { Router } from '@angular/router';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { ColumnItem, Sort } from 'src/app/components/table/table-expand';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { dateValidator, descriptionValidator, numberStringValidator, rangeValidator, startAndEndDateValidator } from 'src/app/shared/validators/validators';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';
import { aggregateInvestedAmountCurrencyDropdownOptions, CurrencyE, ProfileAgentInfo } from '../../employer/employer';
import {
  ContryCode,
  EeSearchRequest,
  Employee,
  lineOfBusinessOptions,
  memberAccountTypeOptions,
  policyStatusOptions,
  RelatedPolicy,
  servicingChannelOptions,
} from '../employee';
import moment from 'moment';
import { DateTime } from 'luxon';
import { ColumnConfig } from 'src/app/shared/data-table';
import { empfCountryOption } from '../../salesjourney/case-summary/declaration-iff-er/empf-declaration-form.config';
import { LayoutHeaderService } from 'src/app/core/services/layout-header.service';
@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
})
export class EmployeeListComponent implements OnInit {
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;
  public Sort = Sort;
  form: FormGroup = new FormGroup({});
  formReady = false;
  showTable = true;

  showDatePicker = true;

  memberNameValidTip = 'Please input English, Chinese, numbers, or special characters';

  hkidPassportValidTip = 'Please input English, numbers, or special characters';

  nowTimeInstr = DateTime.now().toFormat('dd/MM/yyyy');

  maxTime = this.getNowTime();

  maxTimeInStr = DateTime.fromJSDate(this.maxTime).toFormat('dd/MM/yyyy');

  memberAccountTypeOptions = memberAccountTypeOptions;

  policyStatusOptions = policyStatusOptions;

  servicingChannelOptions = servicingChannelOptions;

  get searchButtonDisabled() {
    return !this.form?.valid || !this.form.dirty;
  }

  resetButtonDisabled = true;

  get memberAccountCode() {
    return this.form?.get('memberAccountCode');
  }

  get originalMemberAccountCode() {
    return this.form?.get('originalMemberAccountCode');
  }

  get empfId() {
    return this.form?.get('empfId');
  }

  get memberName() {
    return this.form?.get('memberName');
  }

  get intermediatoryCode() {
    return this.form?.get('intermediatoryCode');
  }

  get aggregateInvestedAmountCurrency() {
    return this.form?.get('aggregateInvestedAmountCurrency');
  }

  get aggregateInvestedAmountFrom() {
    return this.form?.get('aggregateInvestedAmountFrom');
  }

  get aggregateInvestedAmountTo() {
    return this.form?.get('aggregateInvestedAmountTo');
  }

  get phoneNumber() {
    return this.form?.get('phoneNumber');
  }

  get countryCode() {
    return this.form?.get('phoneNumberExt');
  }

  get hkidOrPassport() {
    return this.form?.get('hkidOrPassport');
  }

  get enrolmentCompletionDateFromControl() {
    return this.form?.get('enrolmentCompletionDateFrom');
  }

  get enrolmentCompletionDateToControl() {
    return this.form?.get('enrolmentCompletionDateTo');
  }

  get enrolmentCompletionDateFromValue() {
    return this.enrolmentCompletionDateFromControl?.value;
  }

  get enrolmentCompletionDateToValue() {
    return this.enrolmentCompletionDateToControl?.value;
  }

  get companyName() {
    return this.form?.get('companyName');
  }

  get employerAccountCode() {
    return this.form?.get('employerAccountCode');
  }

  get servicingAgentCode() {
    return this.form?.get('servicingAgentCode');
  }

  get ageFrom() {
    return this.form?.get('ageFrom');
  }

  get ageTo() {
    return this.form?.get('ageTo');
  }

  get assetType() {
    return this.form?.get('assetType');
  }

  get assetSizeFrom() {
    return this.form?.get('assetSizeFrom');
  }

  get assetSizeTo() {
    return this.form?.get('assetSizeTo');
  }

  get lastEcaMonthYearOrYear() {
    return this.form?.get('lastEcaMonthYearOrYear');
  }

  tempSeardhParams: EeSearchRequest = {
    page: 1,
    size: 10,
  };

  addressRequestUrl = 'customer-service/employee/address';

  addressSearchParams = {
    residentAddressDistrict: '',
  };

  constructor(
    private service: EmployeeService,
    private router: Router,
    private toastAlert: ToastAlertService,
    private permissionService: PermissionService,
    private layoutHeaderService: LayoutHeaderService
  ) {}

  lineOfBusinessConfig: CdsDropdownConfig = {
    label: 'Line of Business',
    placeholder: 'Select Line of Business',
    options: lineOfBusinessOptions,
  };

  countryCodeConfig: CdsDropdownConfig = {
    label: '',
    placeholder: '+852(HK)',
    options: this.getContryCodeOptions(),
  };

  birthdayMonthConfig: CdsDropdownConfig = {
    label: 'Birthday Month',
    placeholder: 'Select Birthday Month',
    options: [
      { label: 'common.month.january', value: 1 },
      { label: 'common.month.february', value: 2 },
      { label: 'common.month.march', value: 3 },
      { label: 'common.month.april', value: 4 },
      { label: 'common.month.may', value: 5 },
      { label: 'common.month.june', value: 6 },
      { label: 'common.month.july', value: 7 },
      { label: 'common.month.august', value: 8 },
      { label: 'common.month.september', value: 9 },
      { label: 'common.month.october', value: 10 },
      { label: 'common.month.november', value: 11 },
      { label: 'common.month.december', value: 12 },
    ],
  };

  isStaff = false;

  residentAddressDistrictConfig: CdsDropdownConfig = {
    label: 'Resident Address District',
    placeholder: 'Select Resident Address District',
    options: [],
  };

  _totalLiveModel?: number[];

  searchButtonConfig: CdsButtonConfig = {
    size: 'sm',
  };

  assetTypeConfig: CdsDropdownConfig = {
    label: '',
    placeholder: 'HKD',
    options: aggregateInvestedAmountCurrencyDropdownOptions,
  };

  _sortChange(columnItem: ColumnItem) {
    const temp = columnItem.prop?.replace('empfID', 'empf_id').replace('employeeName', 'last_nm');
    if (columnItem.sort !== Sort.DEFAULT) {
      this.tempSeardhParams.sort = `${temp},${columnItem.sort}`;
    } else {
      delete this.tempSeardhParams.sort;
    }
    this.getPageList();
  }

  permissionFilter() {
    this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.CUSTOMER_EMPLOYEE).then(res => {
      this.isStaff = res;
    });
  }

  resetButtonConfig: CdsButtonConfig = {
    size: 'sm',
    style: 'secondary',
  };

  isCheckPass = true;

  _pageCount: IPaginationCount = { current: 1, count: 1 };
  _totalCount = 0;

  _eeRecordList?: Employee[] = [];
  _progress = false;

  noCheckConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: false,
  };

  checkedConfig: CdsCheckboxConfig = {
    disabled: true,
    checked: true,
  };

  columnsConfig: ColumnConfig[] = [
    {
      key: 'lineOfBusiness',
      title: 'Line of Busines',
    },
    {
      key: 'memberAccountType',
      title: 'Member Account Type',
    },
    {
      key: 'memberAccountCode',
      title: 'Member Account Code',
    },
    {
      key: 'servicingChannel',
      title: 'Servicing Channel',
    },
    {
      key: 'memberAuthorization',
      title: 'Member Authorization',
    },
  ];

  columnsConfigAgent: ColumnConfig[] = [
    {
      key: 'lineOfBusiness',
      title: 'Line of Busines',
    },
    {
      key: 'memberAccountType',
      title: 'Member Account Type',
    },
    {
      key: 'memberAccountCode',
      title: 'Member Account Code',
    },
    {
      key: 'memberAuthorization',
      title: 'Member Authorization',
    },
    {
      key: 'lastEcaMonthYearOrYear',
      title: 'Last ECA Transfer-In Date (DD/MM/YYYY)',
    },
  ];

  isAdvanceSearch = false;

  resultMsg = 'No Result Found';

  ngOnInit(): void {
    this.layoutHeaderService.doHidenBackButton();
    document.documentElement.scrollTop = 0;
    this.permissionFilter();
    this.initForm();
    this.search(true);
  }

  initForm() {
    this.form?.markAsUntouched();
    this.form?.markAsPristine();

    this.form = new FormGroup(
      {
        empfId: new FormControl('', [Validators.maxLength(20), numberStringValidator()]),
        memberName: new FormControl('', [Validators.maxLength(100), descriptionValidator(this.memberNameValidTip, true)]),
        originalMemberAccountCode: new FormControl('', [Validators.maxLength(100)]),
        memberAccountCode: new FormControl('', [Validators.maxLength(15), numberStringValidator()]),
        memberAccountType: new FormControl([]),
        policyStatus: new FormControl([]),
        lineOfBusiness: new FormControl(''),
        phoneNumberExt: new FormControl(''),
        phoneNumber: new FormControl('', [Validators.maxLength(20), numberStringValidator()]),
        hkidOrPassport: new FormControl('', [Validators.maxLength(80), descriptionValidator(this.hkidPassportValidTip)]),
        enrolmentCompletionDateFrom: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
        enrolmentCompletionDateTo: new FormControl('', [dateValidator('create.dateError', '', this.nowTimeInstr)]),
        companyName: new FormControl('', [Validators.maxLength(200), descriptionValidator(this.memberNameValidTip, true)]),
        employerAccountCode: new FormControl('', [Validators.maxLength(15), numberStringValidator()]),
        servicingChannel: new FormControl([]),
        servicingAgentCode: new FormControl('', [Validators.maxLength(20), numberStringValidator()]),
        birthDayMonth: new FormControl(''),
        ageFrom: new FormControl('', [numberStringValidator()]),
        ageTo: new FormControl('', [numberStringValidator()]),
        assetType: new FormControl(''),
        assetSizeFrom: new FormControl('', [numberStringValidator()]),
        assetSizeTo: new FormControl('', [numberStringValidator()]),
        residentAddressDistrict: new FormControl(''),
        lastEcaMonthYearOrYear: new FormControl('', [this.monthYearValidator]),
      },
      {
        validators: [
          rangeValidator('list.range-invalid', 'ageFrom', 'ageTo', true),
          rangeValidator('list.range-invalid', 'assetSizeFrom', 'assetSizeTo', true),
          startAndEndDateValidator('common.date-range-error', 'enrolmentCompletionDateFrom', 'enrolmentCompletionDateTo'),
          // this.amountCurrencyValidator(),
        ],
        updateOn: 'change',
      }
    );
    this.formReady = false;
    setTimeout(() => {
      this.formReady = true;
    });
  }

  onCountryCodeChange(val: string, phoneNumber: string) {
    this.countryCode?.setValue(val);
    if (!val) return;

    this.phoneNumber?.setValidators([Validators.required, Validators.maxLength(20), numberStringValidator()]);

    if (phoneNumber === '') {
      this.phoneNumber?.markAsDirty();
      this.phoneNumber?.setValue('');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPhoneNumberChange(val: any) {
    if (val) {
      this.countryCode?.setValidators([Validators.required]);
      if (!this.countryCode?.value) {
        this.countryCode?.markAsDirty();
        this.countryCode?.setValue('');
      }
    } else {
      this.countryCode?.clearValidators();
      this.countryCode?.markAsPristine();
      this.countryCode?.markAsUntouched();
      this.countryCode?.updateValueAndValidity();
    }
  }

  getContryCodeOptions() {
    return empfCountryOption.map(item => {
      if (!item.value) {
        return { ...item, value: 'none' };
      }
      return item;
    });
  }

  search(skipCheck = false) {
    if (this.searchButtonDisabled && !skipCheck) return;
    if (!skipCheck) {
      this.resetButtonDisabled = false;
    }
    this._pageCount = { current: 1, count: 1 };
    this.tempSeardhParams = deepCopy(this.getTempSearchParams());
    this.getPageList();
  }

  goToDetail(item: Employee) {
    if (!this.isStaff) return;
    this.router.navigate(['/employee/profile'], {
      queryParams: {
        md5: item.md5,
      },
    });
  }

  goToPolicyDetail(item: RelatedPolicy) {
    this.router.navigate(['/employee/related-policy-detail'], {
      queryParams: {
        id: item.id,
      },
    });
  }

  expandRow(index: number) {
    if (this._eeRecordList && this._eeRecordList[index]) {
      this.getListExpansion(this._eeRecordList[index].md5 as string, index).then(() => {});
    }
  }

  async getListExpansion(md5: string, index: number) {
    const params = deepCopy(this.tempSeardhParams);
    params.md5 = md5;
    delete params.page;
    delete params.size;
    this._progress = true;
    this.service
      .getListExpansion(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && this._eeRecordList) {
          this._eeRecordList[index].expandData = data.data;
        } else {
          this.toastError(data.message);
        }
      });
  }

  profileAgentInfo(val: ProfileAgentInfo) {
    if (!val) {
      return '';
    }
    return val.agentCode + ' ' + val.agentName;
  }
  defaultPaymentMethodStr(val: string[]) {
    if (!val) {
      return '';
    }
    let str = '';
    val.forEach((i, index) => {
      str = str + i + (index + 1 != val.length ? ' | ' : '');
    });
    return str;
  }
  reset() {
    if (this.resetButtonDisabled) return;
    this.resetButtonDisabled = true;
    this.initForm();
    // reloadTable to reset sort
    this.showTable = false;
    setTimeout(() => {
      this.showTable = true;
      this._pageCount = { current: 1, count: 1 };
      this.tempSeardhParams = { page: 1, size: 10 };
      this.search(true);
    });
  }

  assetTypeChange() {
    // this.assetType?.markAsPristine();
    // this.assetType?.markAsUntouched();
  }

  // ageRangeChange(val: string, type: 'ageFrom' | 'ageTo') {
  //   let result = 0;
  //   const num = Number(val);
  //   if (!isNaN(num)) {
  //     result = Math.abs(num);
  //   }
  //   this.form.get(type)?.setValue(result);
  // }

  // assetSizeChange(val: string, type: 'assetSizeFrom' | 'assetSizeTo') {
  //   let result = 0;
  //   const num = Number(val);
  //   if (!isNaN(num)) {
  //     result = Math.abs(num);
  //   }
  //   this.form.get(type)?.setValue(result);
  // }

  // getCountryCodes() {
  //   this.service
  //     .getCountryCodes()
  //     .pipe(finalize(() => {}))
  //     .subscribe(data => {
  //       if (data.result === 0 && data.data) {
  //         this.countryCodeConfig.options = this.transToOpionts(data.data);
  //         this.countryCodeConfig = { ...this.countryCodeConfig };
  //       } else {
  //         this.toastError(data.message);
  //       }
  //     });
  // }

  transToOpionts(list: ContryCode[]): CdsOption[] {
    return list.map(item => {
      return {
        label: `${item.countryCallingCode || ''}(${item.crsCountryCode})`,
        value: item.countryCallingCode,
      };
    });
  }

  getPageList() {
    this._progress = true;

    const params: EeSearchRequest = this.getRequestParams();

    this.service
      .getPageList(params)
      .pipe(
        finalize(() => {
          this._progress = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0 && data.data) {
          this._eeRecordList = data.data.empty ? [] : data.data.content;
          this._pageCount.count = data.data.totalPages;
          this._totalCount = data.data.totalElements;
          this._pageCount = { ...this._pageCount };
          this.resultMsg = 'No Result Found';
        } else if (data.result === 3) {
          console.log(data);
          this._eeRecordList = [];
          this._pageCount.count = 0;
          this._totalCount = 0;
          this.resultMsg = 'Restricted Manulife Staff Policy - Enquiry Prohibited';
        } else {
          this.toastError(data.message);
        }
      });
  }

  getTempSearchParams(sort = '') {
    const params: EeSearchRequest = {
      ...this.tempSeardhParams,
      ...(this.form.valid ? this.form.value : {}),
      page: (this._pageCount.current as number) - 1,
      size: 10,
    };

    if (sort) {
      params.sort = sort;
    }

    if (params.enrolmentCompletionDateFrom) {
      params.enrolmentCompletionDateFrom = this.dateTrans(params.enrolmentCompletionDateFrom);
    }

    if (params.enrolmentCompletionDateTo) {
      params.enrolmentCompletionDateTo = this.dateTrans(params.enrolmentCompletionDateTo);
    }

    if (params.memberAccountType) {
      params.memberAccountType = this.getRequestValueFromOptions(params.memberAccountType as CdsOption[]);
    }

    if (params.policyStatus) {
      params.policyStatus = this.getRequestValueFromOptions(params.policyStatus as CdsOption[]);
    }

    if (params.servicingChannel) {
      params.servicingChannel = this.getRequestValueFromOptions(params.servicingChannel as CdsOption[]);
    }

    if (params.phoneNumberExt === 'none') {
      delete params.phoneNumberExt;
    }

    return params;
  }

  getRequestValueFromOptions(options: CdsOption[]) {
    return options.map(item => item.value).join(',');
  }

  getRequestParams() {
    const params: EeSearchRequest = {
      ...this.tempSeardhParams,
      page: (this._pageCount.current as number) - 1,
      size: 10,
    };

    return params;
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const countryCodeControl = control.get('countryCode');
      const numberControl = control.get('phoneNumber');
      if (countryCodeControl?.value || numberControl?.value) {
        numberControl?.setValidators([Validators.required, Validators.maxLength(20), numberStringValidator()]);
        countryCodeControl?.setValidators([Validators.required]);
        countryCodeControl?.markAllAsTouched();
        numberControl?.markAllAsTouched();
        countryCodeControl?.updateValueAndValidity();
        numberControl?.updateValueAndValidity();
      } else {
        numberControl?.clearValidators();
        countryCodeControl?.setValidators([Validators.maxLength(20), numberStringValidator()]);
      }
      return null;
    };
  }

  amountCurrencyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currencyControl = control.get('assetType');
      const amountFromControl = control.get('assetSizeFrom');
      const amountToControl = control.get('assetSizeTo');
      const currency = currencyControl?.value;
      const amountFrom = amountFromControl?.value;
      const amountTo = amountToControl?.value;

      const fromErrors = amountFromControl?.errors ? deepCopy(amountFromControl?.errors) : {};
      const toErrors = amountToControl?.errors ? deepCopy(amountToControl?.errors) : {};

      if (currency !== CurrencyE.HKD && amountFrom === '' && amountTo === '') {
        fromErrors['required'] = true;
        toErrors['required'] = true;

        amountFromControl?.markAsTouched();
        amountToControl?.markAsTouched();

        amountFromControl?.setErrors(fromErrors);
        amountToControl?.setErrors(toErrors);

        return null;
      }

      // if (!currency && (amountFrom !== '' || amountTo !== '')) {
      //   currencyControl?.setErrors({ required: true });
      //   currencyControl?.markAllAsTouched();
      // } else {
      //   currencyControl?.setErrors({});
      // }

      delete fromErrors['required'];
      delete toErrors['required'];
      amountFromControl?.setErrors(Object.keys(fromErrors).length === 0 ? null : fromErrors);
      amountToControl?.setErrors(Object.keys(toErrors).length === 0 ? null : toErrors);

      return null;
    };
  }

  channelStrGen(data?: string[]) {
    if (!data) return '';
    return data.join(' | ');
  }

  dateTrans(date: string) {
    if (date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return date;
  }

  getNowTime() {
    return moment().toDate();
  }

  getMaxFromDate = (toDateStr?: string) => {
    if (!toDateStr) {
      return this.getNowTime();
    }
    return moment(toDateStr, 'DD/MM/YYYY').toDate();
  };

  getMinToDate = (fromDateStr = '01/01/0000') => {
    return moment(fromDateStr, 'DD/MM/YYYY').toDate();
  };

  advanceSearchToggle() {
    this.isAdvanceSearch = !this.isAdvanceSearch;
  }

  monthYearValidator(control: AbstractControl): ValidationErrors | null {
    const dateString = control.value;
    const regexYYYY = /^\d{4}$/;
    const regexMMYYYY = /^\d{2}-\d{4}$/;

    if (!dateString) {
      return null;
    }

    if (regexYYYY.test(dateString) || regexMMYYYY.test(dateString)) {
      return null;
    }

    return { dateInvalid: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDataSource(data: any) {
    if (!data || !Array.isArray(data)) return [];
    return data;
  }

  assetSizeChange() {
    if (this.assetSizeFrom?.value || this.assetSizeTo?.value) {
      this.assetType?.setValidators([Validators.required]);
      this.assetType?.markAsDirty();
    } else {
      this.assetType?.clearValidators();
    }
    this.assetType?.updateValueAndValidity();
  }
}
