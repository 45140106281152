import { Category, EventTypeKey } from 'src/app/views/system/calendar/shared/calendar.model';
import { EventColor } from 'calendar-utils';
import { Tab } from 'src/app/core/models/enum';

export const COLORS: Record<string, EventColor> = {
  green: {
    primary: '#06874e',
    secondary: '#06874e',
  },
  blue: {
    primary: '#1e1ee5',
    secondary: '#1e1ee5',
  },
  greenLight: {
    primary: '#00a758',
    secondary: '#00A758',
  },
  red: {
    primary: '#EC6453',
    secondary: '#EC6453',
  },
  turquoise: {
    primary: '#06c7ba',
    secondary: '#06c7ba',
  },
  gold: {
    primary: '#f49600',
    secondary: '#f49600',
  },
};

export const CALENDAR_TAB: Array<Tab> = [
  {
    label: 'calendar.tab.title',
    value: 'calendar',
    selected: true,
  },
  {
    label: 'eventList.tab.title',
    value: 'event',
    selected: false,
  },
];

export const CATEGORY: Array<Category> = [
  {
    title: 'Accounting',
    color: 'cds-green',
    children: [
      {
        key: EventTypeKey.A_C_MONTH_END,
        color: 'greenLight',
        title: 'A/C Month end',
        editable: false,
        addable: false,
        checked: true,
        sequence: 1,
        fileName: 'Calendar_ACMonthEnd_report',
      },
    ],
  },
  {
    title: 'Commission',
    color: 'cds-turquoise',
    children: [
      {
        key: EventTypeKey.COMMISSION,
        color: 'turquoise',
        title: 'Commission End',
        editable: false,
        addable: true,
        checked: true,
        sequence: 3,
        fileName: 'Calendar_CommissionEnd_report',
      },
    ],
  },
  {
    title: 'Holiday',
    color: 'cds-coral',
    children: [
      {
        key: EventTypeKey.HOLIDAY,
        color: 'red',
        title: 'Holiday',
        editable: false,
        addable: false,
        checked: true,
        sequence: 5,
        fileName: 'Calendar_Holiday_report',
      },
    ],
  },
  {
    title: 'Proc date',
    color: 'cds-blue light-1',
    children: [
      {
        key: EventTypeKey.PROC_DATE,
        color: 'blue',
        title: 'Proc date',
        editable: false,
        addable: false,
        checked: true,
        sequence: 2,
        fileName: 'Calendar_ProcDate_report',
      },
    ],
  },
  {
    title: 'Dividend (Income Fund SHK150)',
    color: 'cds-coral',
    children: [
      {
        key: EventTypeKey.DIVIDEND_RECORD_DATE,
        color: 'red',
        title: 'Dividend Record Date',
        editable: true,
        addable: true,
        checked: true,
        sequence: 6,
        fileName: 'DIVIDEND_RECORD_DATE',
      },
    ],
  },
];

export const I18N_KEY = {
  INVALID_DATE_FORMAT: 'calendar.date.Format',
  DATE_REQUIRED: 'calendar.date.required',
  INVALID_DATE_FROM_FORMAT: 'calendar.dateFrom.dateFormat',
  INVALID_DATE_TO_FORMAT: 'calendar.dateTo.dateFormat',
  INVALID_REPORT_NO: 'calendar.report.no',
  INVALID_DATE_FORM: 'calendar.dateFrom.dateRange',
  INVALID_COMMISSION_DATE_FORMAT: 'calendar.commission.dateFormat',
  INVALID_COMMISSION_DATE_ERROR: 'calendar.commission.dateError',
  INVALID_COMMISSION_DATE_EQUEAL: 'calendar.commission.dateEqueal',
  COMMON_ACTION_SUCCESS: 'common.action.success',
  COMMON_ACTION_CANCLE: 'common.action.cancel',
  COMMON_GOT: 'common.gotIt',
  COMMON_CANCEL: 'common.cancel',
  COMMON_DELETE: 'common.delete',
  COMMON_CONTINUE: 'common.continue',
  EVENT_DELETE_CONFIRM: 'calendar.action.deleteConfirm',
  SUCCESS: 'common.action.success',
  INVALID_FORMAT: 'calendar.action.invaidFormat',
  INVALID_DATE_RANGE: 'calendar.action.invaidDateRange',
  ALREADY_EXISTS: 'calendar.action.exists',
  EVENT_CHOOSE_NAME: 'calendar.action.chooseName',
  CALENDAR_DECLINE_MSG: 'calendar.decline.message',
  CALENDAR_DECLINE: 'calendar.action.declineConfirm',
  EVENT_RETURN_CONFIRM: 'calendar.returnConfirm',
  CALENDAR_CONFIRM: 'common.confirm',
  NORMAL_WORKING_DAY: 'calendar.event.normalWorkDay',
};

export const I18N_KEY_FOR_API_RES: Record<string, string> = {
  SUCCESS: 'common.action.success',
  INVALID_FORMAT: 'calendar.action.invaidFormat',
  INVALID_DATE_RANGE: 'calendar.action.invaidDateRange',
  ALREADY_EXISTS: 'calendar.action.exists',
};

export const EVENT_TABLE_COLUMNS_NORMAL = [{ key: 'eventDateStr', title: 'EventDate' }];

export const EVENT_TABLE_COLUMNS_PROC_DATE = [
  { key: 'eventDateStr', title: 'Process Date' },
  { key: 'reportNo', title: 'Report No.' },
];

export const EVENT_TABLE_COLUMNS_COMMISSION = [
  { key: 'eventStartDate', title: 'From' },
  { key: 'eventEndDate', title: 'To' },
];

export const EVENT_TABLE_DIVIDEND_RATE = [
  { key: 'date', title: 'Month / Year' },
  { key: 'recordDate', title: 'Record Date' },
  { key: 'status', title: 'Status' },
];
