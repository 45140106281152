<div class="row dropdown" [formGroup]="form" *ngIf="formReady">
  <div class="col-xs-12 l-pt-7">
    <span class="cds-h2-light">{{ 'list.serach-record' | lang }}</span>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="employerNo"
      label="{{ 'er.profile.employer-account-code' | lang }}"
      placeholder="{{ 'er.profile.input-employer-account-code' | lang }}"></cds-textfield>

    <div *ngIf="employerNo?.invalid && (employerNo?.dirty || employerNo?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="employerNo?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 15</cds-assistive-text>

      <cds-assistive-text
        *ngIf="employerNo?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ employerNo?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="origErAcctCd"
      label="{{ 'er.profile.origErAcctCd' | lang }}"
      placeholder="{{ 'er.profile.origErAcctCd-input' | lang }}"></cds-textfield>

    <div *ngIf="origErAcctCd?.invalid && (origErAcctCd?.dirty || origErAcctCd?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="origErAcctCd?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 100</cds-assistive-text>

      <cds-assistive-text
        *ngIf="origErAcctCd?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ origErAcctCd?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield formControlName="eMpfId" label="{{ 'er.profile.empf-id' | lang }}" placeholder="{{ 'er.profile.input-empf-id' | lang }}"></cds-textfield>

    <div *ngIf="eMpfId?.invalid && (eMpfId?.dirty || eMpfId?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="eMpfId?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 20</cds-assistive-text>

      <cds-assistive-text
        *ngIf="eMpfId?.errors?.['invalidNumberString']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ eMpfId?.errors?.['invalidNumberString'] }}</cds-assistive-text
      >
    </div>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-textfield
      formControlName="employerName"
      label="{{ 'list.employer-name' | lang }}"
      placeholder="{{ 'list.input-employer-name' | lang }}"></cds-textfield>

    <div *ngIf="employerName?.invalid && (employerName?.dirty || employerName?.touched)" class="l-pt-2">
      <cds-assistive-text *ngIf="employerName?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 200</cds-assistive-text>

      <cds-assistive-text
        *ngIf="employerName?.errors?.['descriptionInvalid']"
        class="l-pt-2"
        [showIcon]="false"
        >{{ employerName?.errors?.['descriptionInvalid'] }}</cds-assistive-text
      >
    </div>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="lineOfBusiness" [config]="lineOfBusinessConfig"> </cds-dropdown>
  </div>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
    <cds-dropdown formControlName="_totalLiveModel" [config]="totalLivesConfig"></cds-dropdown>
  </div>

  <ng-container *ngIf="isStaff">
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4">
      <cds-textfield
        formControlName="intermediatoryCode"
        label="{{ 'list.servicing-agent-code' | lang }}"
        placeholder="{{ 'list.input-servicing-agent-code' | lang }}"></cds-textfield>

      <div *ngIf="intermediatoryCode?.invalid && (intermediatoryCode?.dirty || intermediatoryCode?.touched)" class="l-pt-2">
        <cds-assistive-text *ngIf="intermediatoryCode?.errors?.['maxlength']" class="l-pt-2" [showIcon]="false">Max length is 20</cds-assistive-text>

        <cds-assistive-text
          *ngIf="intermediatoryCode?.errors?.['invalidNumberString']"
          class="l-pt-2"
          [showIcon]="false"
          >{{ intermediatoryCode?.errors?.['invalidNumberString'] }}</cds-assistive-text
        >
      </div>
    </div>
  </ng-container>
  <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-4 l-pb-4 aggregate-invested-amount">
    <div class="aggregate-invested-amount-label cds-detail1">Aggregate Invested Amount</div>
    <div class="aggregate-invested-amount-content">
      <div class="currency-wrapper">
        <cds-dropdown
          formControlName="aggregateInvestedAmountCurrency"
          [config]="aggregateInvestedAmountCurrencyConfig"
          (cdsChange)="currencyChange()"></cds-dropdown>
      </div>
      <div class="amount-wrapper">
        <cds-textfield
          formControlName="aggregateInvestedAmountFrom"
          label=""
          type="number"
          placeholder="0"
          (cdsChange)="noChange($event, 'aggregateInvestedAmountFrom')"></cds-textfield>

        <div
          *ngIf="aggregateInvestedAmountFrom?.invalid && (aggregateInvestedAmountFrom?.dirty || aggregateInvestedAmountFrom?.touched)"
          class="l-pt-2 absolute">
          <cds-assistive-text
            *ngIf="aggregateInvestedAmountFrom?.errors?.['invalidRange']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ aggregateInvestedAmountFrom?.errors?.['invalidRange'] | lang }}</cds-assistive-text
          >
          <cds-assistive-text *ngIf="aggregateInvestedAmountFrom?.errors?.['required']" class="l-pt-2" [showIcon]="false"
            >Please input amount from</cds-assistive-text
          >
        </div>
      </div>
      <div class="line"></div>
      <div class="amount-wrapper">
        <cds-textfield
          formControlName="aggregateInvestedAmountTo"
          label=""
          type="number"
          placeholder="0"
          (cdsChange)="noChange(aggregateInvestedAmountTo?.value, 'aggregateInvestedAmountTo')"></cds-textfield>

        <div *ngIf="aggregateInvestedAmountTo?.invalid && (aggregateInvestedAmountTo?.dirty || aggregateInvestedAmountTo?.touched)" class="l-pt-2 absolute">
          <cds-assistive-text
            *ngIf="aggregateInvestedAmountTo?.errors?.['invalidRange']"
            class="l-pt-2"
            [showIcon]="false"
            >{{ aggregateInvestedAmountTo?.errors?.['invalidRange'] | lang }}</cds-assistive-text
          >
          <cds-assistive-text *ngIf="aggregateInvestedAmountTo?.errors?.['required']" class="l-pt-2" [showIcon]="false"
            >Please input amount to</cds-assistive-text
          >
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 l-mt-2 l-pb-2 l-pt-2 btns">
    <cds-button
      class="l-mr-4 botton"
      [disabled]="searchButtonDisabled"
      [config]="searchButtonConfig"
      label="{{ 'list.search' | lang }}"
      (click)="search()"></cds-button>
    <cds-button class="botton" [disabled]="resetButtonDisabled" [config]="resetButtonConfig" label="{{ 'list.reset' | lang }}" (click)="reset()"></cds-button>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 l-pt-7 l-pb-2">
    <span class="cds-h2-light">{{ 'list.record-list' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <span class="cds-body1">Total {{ _totalCount | number }} records</span>
  </div>
</div>

<ng-template #checkBox let-element>
  <cds-checkbox *ngIf="element['parentCompany'] === 'N'" [config]="noCheckConfig"></cds-checkbox>
  <cds-checkbox *ngIf="element['parentCompany'] === 'Y'" [config]="checkedConfig"></cds-checkbox>
</ng-template>
<ng-template #greenLine let-element>
  <div (click)="goToDetail(element)" class="col-xs middle-xs l-plr-0">
    <div>
      <span class="green-line pointer">
        <span class="cds-body1-demibold">{{ element['employerName'] }}</span>
      </span>
    </div>
    <div>
      <span class="green-line pointer">
        <span class="cds-body1-demibold">{{ element['employerChineseName'] }}</span>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #greenLine2 let-element>
  <div (click)="goToDetail(element)" class="col-xs middle-xs l-plr-0">
    <span class="green-line pointer">
      <span class="cds-body1-demibold">{{ element['employerName'] }}</span>
    </span>
    <span class="l-pr-2"></span>
    <span class="green-line pointer">
      <span class="cds-body1-demibold">{{ element['employerChineseName'] }}</span>
    </span>
  </div>
</ng-template>

<ng-template #template let-element>
  <div class="row col-xs-12 l-plr-0">
    <app-row-item
      label="list.commencment-date"
      data="{{ element['commencementDate'] | ssmsDate }}"
      class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-4 l-pb-4"></app-row-item>
    <app-row-item
      label="list.rs-ubmission-channel"
      [data]="channelStrGen | fn : element['rsSubmissionChannel']"
      class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-4 l-pb-4"></app-row-item>
    <app-row-item
      label="list.default-payment-method"
      data="{{ defaultPaymentMethodStr(element['paymentMethod']) }}"
      class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-4 l-pb-4"></app-row-item>
    <ng-container *ngIf="isStaff">
      <app-row-item
        label="list.servicing-agent"
        data="{{ profileAgentInfo(element['servicingAgent']) }}"
        class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-4 l-pb-4"></app-row-item>
      <app-row-item
        label="list.commission-agent"
        data="{{ profileAgentInfo(element['commissionAgent']) }}"
        class="col-xs-6 col-sm-6 col-md-4 col-lg-4 l-pt-4 l-pb-4"></app-row-item>
    </ng-container>
    <div *ngIf="element['relatedCompanies'] && element['relatedCompanies'].length > 0" class="col-xs-12">
      <span class="cds-body2">{{ 'list.related-companies' | lang }}</span>
    </div>
    <app-table-expand *ngIf="element['relatedCompanies'] && element['relatedCompanies'].length > 0" [data]="element['relatedCompanies']">
      <app-table-expand-col prop="employerNo" label="{{ 'list.trustee-employer-no' | lang }}"></app-table-expand-col>
      <app-table-expand-col [template]="greenLine2" prop="employerName" width="400px" label="{{ 'list.employer-name' | lang }}"></app-table-expand-col>
      <app-table-expand-col prop="totalLives" label="{{ 'er.profile.total-lives' | lang }}"></app-table-expand-col>
      <app-table-expand-col [template]="checkBox" prop="parentCompany" label="{{ 'list.parent-company' | lang }}"></app-table-expand-col>
    </app-table-expand>
  </div>
</ng-template>
<div class="row">
  <div class="col-xs-12 min-height" *ngIf="showTable">
    <app-table-expand
      (sortChange)="_sortChange($event)"
      [progress]="_progress"
      (expandChange)="expandRow($event)"
      [expandTemplate]="template"
      [data]="_erRecordList"
      [noResultMsg]="_noResutlMsg">
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="employerNo" label="Employer Account Code"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="empfId" label="eMPF ID"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" [template]="greenLine" prop="employerName" label="Employer Name" width="400px"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="lineOfBusiness" label="Line of Business"></app-table-expand-col>
      <app-table-expand-col [sort]="Sort.DEFAULT" prop="totalLives" label="Total Lives"></app-table-expand-col>
      <ng-container *ngIf="isStaff">
        <app-table-expand-col prop="servicingAgentCode" label="Servicing Agent Code"></app-table-expand-col>
        <app-table-expand-col prop="commissionAgentCode" label="Commission Agent Code"></app-table-expand-col>
      </ng-container>
      <app-table-expand-col [template]="checkBox" prop="parentCompany" label="Parent Company"></app-table-expand-col>
    </app-table-expand>
  </div>
</div>

<div *ngIf="_erRecordList" class="row l-pt-4 middle-xs">
  <app-pagination [pageCount]="_pageCount" [isLoading]="_progress" [textfieldAlwaysAvailable]="true" [useOf]="true" (pageChange)="getPageList()">
  </app-pagination>
</div>
