<div class="cd-autocomplete">
  <mat-form-field class="example-full-width" appearance="fill">
    <input
      type="text"
      matInput
      placeholder="{{ placeholder | lang }}"
      class="text-field"
      [ngClass]="['icon-location-right']"
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      #trigger="matAutocompleteTrigger"
      appCdAutocompletePosition="trigger" />
    <mat-autocomplete #auto="matAutocomplete" (opened)="onOpen()" (closed)="onClose()" (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let result of results" [value]="result.label">
        {{ result.label }}
      </mat-option>
      <mat-option *ngIf="loading">{{ 'common.loading' | lang }}</mat-option>
      <mat-option *ngIf="!haveMore">{{ 'common.noMore' | lang }}</mat-option>
    </mat-autocomplete>
    <div class="icon-location-right-x-icon" #iconLocationRightXIcon>
      <cds-icon [config]="{ color: '#8E90A2', size: 'xs' }" icon="action:button_x_filled" (click)="clearInput()"></cds-icon>
    </div>
  </mat-form-field>
</div>
