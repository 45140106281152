export enum ApeType {
  MPF_ER_AC = 'MPF_ER_AC',
  MPF_ER_TA = 'MPF_ER_TA',
  MPF_ER_IT_MPF_PAP = 'MPF_ER_IT_MPF_PAP',
  MPF_TVC = 'MPF_TVC',
  MPF_SEP = 'MPF_SEP',
  MPF_FRC = 'MPF_FRC',
  OR_MA_PPS_AC = 'OR_MA_PPS_AC',
  OR_MA_PPS_TA = 'OR_MA_PPS_TA',
  MA_CPF_AC = 'MA_CPF_AC',
  MA_CPF_TA = 'MA_CPF_TA',
  MA_CPF_IT = 'MA_CPF_IT',
  MA_PAP = 'MA_PAP',
  MA_IVC = 'MA_IVC',
}

export const ApeTypeLabel = new Map([
  [ApeType.MPF_ER_AC, 'MPF-ER AC'],
  [ApeType.MPF_ER_TA, 'MPF-ER TA'],
  [ApeType.MPF_ER_IT_MPF_PAP, 'MPF_ER_IT & MPF_PAP '],
  [ApeType.MPF_TVC, 'MPF-TVC'],
  [ApeType.MPF_SEP, 'MPF-SEP'],
  [ApeType.MPF_FRC, 'MPF-FRC'],
  [ApeType.OR_MA_PPS_AC, 'OR MA(PPS) AC'],
  [ApeType.OR_MA_PPS_TA, 'OR MA(PPS) TA'],
  [ApeType.MA_CPF_AC, 'MA(CPF) AC'],
  [ApeType.MA_CPF_TA, 'MA(CPF) TA'],
  [ApeType.MA_CPF_IT, 'MA(CPF) IT'],
  [ApeType.MA_PAP, 'MA-PAP'],
  [ApeType.MA_IVC, 'MA-IVC'],
]);
