import { environment } from 'src/environments/environment';

const baseUrl = environment.apiPrefix + '/ext/eb-ssms/chatbot-service/api/v1/';

export const APIPaths = {
  subresponse: baseUrl + 'subresponse',
  autoResponse: baseUrl + 'auto',
  agreements: baseUrl + 'agreements',
  alerts: baseUrl + 'alerts',
  users: baseUrl + 'users',
  closeChat: baseUrl + 'agent/log/',
  getCanedResponse: baseUrl + 'canned',
  getsubCanedResponse: baseUrl + 'canned',
  getAgentcanned: baseUrl + 'agentcanned',
  getAgetnList: baseUrl + 'user/get-all-agents',
  getAllAgetnList: baseUrl + 'user/get-all-agents-without-user-id',
  transferChat: baseUrl + 'room/transfer',
  getActiveCloseChatList: baseUrl + 'rooms-with-status',
  getAutoResponse: baseUrl + 'auto',
  feedBack: baseUrl + 'feedback',
  getNewChatRoom: baseUrl + 'room',
  getUsreList: baseUrl + 'rooms',
  upload: environment.apiPrefix + '/ext/eb-ssms/chatbot-service/api/files/upload',
  download: environment.apiPrefix + '/ext/eb-ssms/chatbot-service/api/files/download/',
  websocket: environment.chatbotWebsocket + '/ext/eb-ssms/chatbot-service/ws',
  //websocket:  'ws://localhost:8080/ws',
  //websocket: environment.apiPrefix + '/ws',
  changeRoomStatus: baseUrl + 'room/',
  changeUsertatus: baseUrl + 'user/status/',
  gethistory: baseUrl + 'history',
  getServicesAgree: baseUrl + 'agreements',
  getAllRoomForHistory: baseUrl + 'rooms',
  getReports: baseUrl + 'admin/download-excel',

  staticData: baseUrl + 'staticdata',
  supervisor: baseUrl + 'user/get-supervisor-report',
  terminateChat: baseUrl + 'agent/log/terminateAllBotChats',
};

export const languagesDDL = [
  {
    id: 'zh',
    text: 'Chinese',
  },
  {
    id: 'en',
    text: 'English',
  },
];

export const msgTypeDDL = [
  {
    id: 1,
    text: 'IDLE',
  },
  {
    id: 2,
    text: 'SESSION_OUT',
  },
  {
    id: 3,
    text: 'NO_CSO',
  },
  {
    id: 4,
    text: 'AGENT_OFFLINE',
  },
  {
    id: 5,
    text: 'CSO_OFFLINE',
  },
];
