import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChargeInfoClass, ChargeInfoRequest, ChargeInfoSelected } from '../../employer';
import { EmployerService } from '../../employer.service';
import { finalize } from 'rxjs';
import * as moment from 'moment';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

@Component({
  selector: 'app-charge-info',
  templateUrl: './charge-info.component.html',
  styleUrls: ['./charge-info.component.scss'],
})
export class ChargeInfoComponent implements OnInit, OnChanges {
  constructor(private service: EmployerService, private toastAlert: ToastAlertService) {}

  @Input() payrollGroupId?: string;
  @Input() employerId?: string;

  _typeList: ChargeInfoSelected[] = [
    {
      label: 'Future Service Charge',
      value: '0',
      dropName: 'Future Service Charge Effective Date (MMM YYY)',
      codeName: 'Future Service Charge Code',
      debuctTypeMap: new Map([
        [0, 'n/a'],
        [1, 'paid separately'],
        [2, 'deducted from each of ER required, EE required and EE optional premium'],
        [3, 'deducted from ER required and EE required premium only'],
        [4, 'deducted from ER required and EE optional premium only'],
        [5, 'deducted from ER required premium only'],
      ]),
    },
    {
      label: 'Past Service Charge',
      value: '1',
      dropName: 'Past Service Charge Effective Date (MMM YYY)',
      codeName: 'Past Service Charge Code',
      debuctTypeMap: new Map([
        [0, 'n/a'],
        [1, 'paid separately'],
        [3, 'deducted from EE required and ER required (PS)'],
        [5, 'deducted from ER required (PS) contributions only'],
      ]),
    },
    {
      label: 'Anniversary Charge',
      value: '2',
      dropName: 'Anniversary Charge Effective Date (MMM YYY)',
      codeName: 'Anniversary Charge Code',
      debuctTypeMap: new Map([
        [0, 'n/a'],
        [1, 'paid separately'],
        [2, 'deducted from each of ER required, EE required and EE(FS) optional contribution'],
        [3, 'deducted from ER required and EE required (FS) contributions only'],
        [4, 'deducted from ER(FS) required and EE optional contributions only'],
        [5, 'educted from ER (FS) required contributions only'],
      ]),
    },
  ];
  _typeSelected = 0;

  _deteList: string[] = [];
  _dateSelected = 0;
  _detailsShow = false;

  dataList: ChargeInfoClass[] = [];

  _loading = false;

  _chargeAppTypeMap = new Map([
    [1, 'APPLIED TO CASH PAYMENTS ONLY'],
    [2, 'APPLIED TO BILLED CONTS'],
  ]);

  _brakPointTypeMap = new Map([
    [0, 'NA'],
    [1, 'CURRENT POLICY YEAR CONT'],
    [2, 'ACCUM CONT'],
  ]);

  _policyFreeTypeMap = new Map([
    [' ', ' '],
    ['A', 'ANNUAL FEE'],
  ]);

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('>>>>>>>>>>>>> charg info');
    if (this.employerId && changes['employerId'].currentValue) {
      this.getDateList();
    }
  }

  _policyFreeType(key?: string) {
    if (key == undefined) {
      return 'FEE TYPE NA';
    }
    return this._policyFreeTypeMap.get(key) || 'FEE TYPE NA';
  }

  _brakPointType(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._brakPointTypeMap.get(key) || '';
  }

  _debuctType(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._typeList[this._typeSelected].debuctTypeMap.get(key) || '';
  }

  formatType(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._chargeAppTypeMap.get(key) || '';
  }

  formatDate(val?: string) {
    if (!val) {
      return '';
    }
    let _temp = val.substring(1);
    if (val.startsWith('1')) {
      _temp = '19' + _temp;
    }
    if (val.startsWith('2')) {
      _temp = '20' + _temp;
      9;
    }
    return moment(_temp).format('DD/MM/YYYY');
  }

  getDateList() {
    const _param: ChargeInfoRequest = {
      // payrollGroupId: '615001-1',
      id: this.employerId,
      chargeApplicationType: this._typeList[this._typeSelected].value,
    };
    this._loading = true;
    this.service
      .getChargeInformationEffectiveDate(_param)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this._deteList = data.data ? data.data : [];
          this.getData();
        } else {
          this.toastError(data.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  getData() {
    this.dataList = [];
    if (this._deteList.length == 0) {
      return;
    }
    const _param: ChargeInfoRequest = {
      // payrollGroupId: '615001-1',
      id: this.employerId,
      chargeApplicationType: this._typeList[this._typeSelected].value,
      effectiveDate: this._deteList[this._dateSelected],
    };
    this._loading = true;
    this.service
      .getChargeInformation(_param)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.dataList = data.data ? data.data : [];
        }
      });
  }

  _detailTrigger() {
    this._detailsShow = !this._detailsShow;
  }

  select(index: number) {
    this._dateSelected = index;
    this._detailTrigger();
    this.getDateList();
  }

  typeSelecte(index: number) {
    this._typeSelected = index;
    this.getDateList();
  }
}
