import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ErBaseRequest, FormulaClass } from '../../employer';
import { EmployerService } from '../../employer.service';
import { finalize } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

@Component({
  selector: 'app-formula-class',
  templateUrl: './formula-class.component.html',
  styleUrls: ['./formula-class.component.scss'],
})
export class FormulaClassComponent implements OnInit, OnChanges {
  constructor(private service: EmployerService, private toastAlert: ToastAlertService) {}

  @Input() payrollGroupId?: string;
  @Input() employerId?: string;

  dataList: FormulaClass[] = [];
  _loading = false;

  _contTableColumns = ['service', 'val', 'flatAmount'];

  _rounSignalMap = new Map([
    [0, 'n/a i.e. rounded to the nearest cent'],
    [1, 'rounded to the nearest dollar'],
    [2, 'rounded to the next highest dollar'],
    [3, 'rounded to the next lowest dollar'],
  ]);
  _noPayLeaveMap = new Map([
    [0, 'NA'],
    [1, 'no pay leave months are included'],
    [2, 'no pay leave months are excluded'],
  ]);

  _erContTypeMap = new Map([
    ['S', 'ER monthly contribution same as EE'],
    ['D', 'ER monthly contribution different from EE'],
    ['N', 'no ER contribution'],
  ]);

  _contCalcMap = new Map([
    [0, 'n/a'],
    [1, 'premium calculated by the system'],
    [2, 'premium specified - fixed'],
    [9, 'premium specified - variable'],
  ]);

  _contTableMap = new Map([
    [0, 'NA'],
    [1, 'Salary'],
    [4, 'Job Category'],
    [10, 'Service'],
    [11, 'Participation'],
    [12, 'Age'],
    [13, 'Sex'],
  ]);

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('>>>>>>>>>>>>> formula');
    if (this.employerId && changes['employerId'].currentValue) {
      this.getData();
    }
  }

  formatRoundSignal(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._rounSignalMap.get(key) || '';
  }

  formatNopayLeave(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._noPayLeaveMap.get(key) || '';
  }

  formatType(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._contCalcMap.get(key) || '';
  }

  formatTypeER(key?: string) {
    if (key == undefined) {
      return '';
    }
    return this._erContTypeMap.get(key) || '';
  }

  formatContTableMap(key?: number) {
    if (key == undefined) {
      return '';
    }
    return this._contTableMap.get(key) || '';
  }

  getData() {
    const _param: ErBaseRequest = {
      // payrollGroupId: '615001-1',
      id: this.employerId,
    };
    this._loading = true;
    this.service
      .getFurmulaClassList(_param)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.dataList = data.data ? data.data : [];
        } else {
          this.toastError(data.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }
}
