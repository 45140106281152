/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdsPopupService } from '@cds/ng-web-components/popup';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { EmpfAppStatusService } from 'src/app/core/services/empf-app-status/empf-app-status.service';
import { IffStatusService } from 'src/app/core/services/iff-status/iff-status.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { SalesJourneyProdType } from '../case-summary/case-summary.model';
import { IffCompletionStepsPopupComponent } from '../iff-completion-steps-popup/iff-completion-steps-popup.component';

type RolePriority = 'SalesSupport' | 'SalesJourneyAgent';

export enum QuickSearchIffStatus {
  'All',
  'Completed',
  'Declined',
  'NONE',
}

enum TabIndex {
  'empf',
  'mdiff',
}

export enum IndividualType {
  ALL = 'all',
  PA = 'PA',
  TVC = 'TVC',
  SVC = 'SVC',
}

@Component({
  selector: 'app-md-iff-summary',
  templateUrl: './md-iff-summary.component.html',
  styleUrls: ['./md-iff-summary.component.scss'],
})
export class MdIffSummaryComponent implements OnInit {
  SalesJourneyProdType = SalesJourneyProdType;
  TabIndex = TabIndex;
  @ViewChild('tableBox', { static: false })
  tableBox?: ElementRef<HTMLDivElement>;
  IndividualType = IndividualType;
  IffStatus = QuickSearchIffStatus;
  individualTypeSelected = IndividualType.ALL;
  iffStatusSelected = QuickSearchIffStatus.All;
  prodTypeFormControl: FormControl = new FormControl(SalesJourneyProdType.Individual);
  productTypeOptions = [
    {
      label: 'Individual(PA,TVC,SVC)',
      value: SalesJourneyProdType.Individual,
    },
    { label: 'Corperate(ER)', value: SalesJourneyProdType.Corperate },
    { label: 'SEP', value: SalesJourneyProdType.SEP },
  ];
  hasSalesSupportRole = false;
  hasSalesJourneyAgentRole = false;
  hasSalesJourneySuperAdminRole = false;
  rolePriority?: RolePriority;
  tabIndex: TabIndex = TabIndex.empf;

  constructor(
    private cdsPopup: CdsPopupService,
    public iffStatusService: IffStatusService,
    private permissionService: PermissionService,
    public empfAppStatusService: EmpfAppStatusService
  ) {}

  ngOnInit(): void {
    this.initRole();
  }

  private async initRole() {
    this.hasSalesSupportRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SALES_SUPPORT);
    this.hasSalesJourneyAgentRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_AGENT);
    this.hasSalesJourneySuperAdminRole = await this.permissionService.hasPermission(PermissionAccess.R, PermissionItem.SALES_JOURNEY_SUPER_ADMIN);
    if (this.hasSalesSupportRole) {
      this.rolePriority = 'SalesSupport';
    } else if (this.hasSalesJourneyAgentRole || this.hasSalesJourneySuperAdminRole) {
      this.rolePriority = 'SalesJourneyAgent';
    }
  }

  quickGuide() {
    this.cdsPopup.open(IffCompletionStepsPopupComponent, {
      size: 'sm',
    });
  }

  prodTypeChange(value: string) {
    this.prodTypeFormControl.setValue(value);
  }

  fakeTabIndexChange(index: number) {
    this.tabIndex = index;
  }
}
