<app-cd-page-title title="{{ 'paymentJournal.paymentDetailsEnquiry' | lang }}"> </app-cd-page-title>
<div class="row">
  <form class="col-xs-12 l-mb-8" [formGroup]="formGroup" (ngSubmit)="search()">
    <div class="cds-h2-light l-pt-7 l-mb-7">
      {{ 'common.searchRecord' | lang }}
    </div>
    <div class="row l-plr-0">
      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <cds-dropdown [config]="schemeCdConfig" [formControl]="schemeCd"> </cds-dropdown>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'common.business.employerAcconutCode' | lang }}</label>
        <cds-textfield [placeholder]="'common.business.employerAcconutCode.placeholder' | lang" [formControl]="employerAccountCode"> </cds-textfield>
        <cds-assistive-text *ngIf="employerAccountCode.errors && employerAccountCode.dirty">
          {{ employerAccountCode.errors['error'] | lang }}</cds-assistive-text
        >
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'common.business.payrollGroupID' | lang }}</label>
        <cds-textfield [placeholder]="'common.business.payrollGroupID.placeholder' | lang" [formControl]="payrollGroupId"> </cds-textfield>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'common.business.memberAcconutCode' | lang }}</label>
        <cds-textfield [placeholder]="'common.business.memberAcconutCode.placeholder' | lang" [formControl]="memberAccountCode"> </cds-textfield>
        <cds-assistive-text *ngIf="memberAccountCode.errors && memberAccountCode.dirty"> {{ memberAccountCode.errors['error'] | lang }}</cds-assistive-text>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'common.business.receiptReferenceNumber' | lang }}</label>
        <cds-textfield [placeholder]="'common.business.receiptReferenceNumber.placeholder' | lang" [formControl]="rcvRefNo"> </cds-textfield>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <cds-dropdown [config]="statusCodeConfig" [formControl]="statusCode"> </cds-dropdown>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'common.business.paymentSubmissionReferenceNumber' | lang }}</label>
        <cds-textfield [placeholder]="'common.business.paymentSubmissionReferenceNumber.placeholder' | lang" [formControl]="paySubmitRefNo"> </cds-textfield>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <cds-dropdown [config]="payMthdConfig" [formControl]="payMthd"> </cds-dropdown>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'common.business.receivedAmount' | lang }}</label>
        <cds-textfield [placeholder]="'common.business.receivedAmount.placeholder' | lang" [formControl]="rcvdAmt"> </cds-textfield>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <div *ngIf="isShowSearchDatePicker">
          <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'common.business.clearedDate' | lang }}</label>
          <cds-textfield [placeholder]="'common.business.dateFormat.placeholder' | lang" [formControl]="clearDate" [cdsDatepicker]="clearDatePicker">
          </cds-textfield>
          <cds-datepicker #clearDatePicker="cdsDatepicker"></cds-datepicker>
        </div>
        <cds-assistive-text *ngIf="clearDate.errors && clearDate.dirty">{{ clearDate.errors['error'] | lang }}</cds-assistive-text>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'common.business.trusteeBankAccountNumber' | lang }}</label>
        <cds-textfield [placeholder]="'common.business.trusteeBankAccountNumber.placeholder' | lang" [formControl]="trBnkAcctNo"> </cds-textfield>
      </cds-form-field>

      <cds-form-field class="col-xs-12 col-md-6 col-lg-3 l-pb-5 l-pl-0 l-pr-20">
        <label class="cds-body2 font-color-light3 l-d-ib l-mb-3">{{ 'common.business.reportNo' | lang }}</label>
        <cds-textfield [placeholder]="'common.business.reportNo.placeholder' | lang" [formControl]="reportNo"> </cds-textfield>
      </cds-form-field>

      <div class="col-xs-12 col-md-6 col-lg-3 l-pl-0 l-pr-20">
        <div class="search-area-btn">
          <cds-button type="submit" [disabled]="searchDisabled" [style]="'primary'" size="md">
            {{ 'common.search' | lang }}
          </cds-button>
          <cds-button (click)="reset()" [style]="'secondary'" size="md">
            {{ 'common.reset' | lang }}
          </cds-button>
        </div>
      </div>
    </div>
  </form>
  <div class="col-xs-12">
    <app-cd-table-title class="l-mb-5" [title]="'common.recordList' | lang" [total]="getTotalCount"></app-cd-table-title>
    <div>
      <app-data-table
        [frontPagination]="false"
        [fixed]="false"
        [isLoading]="isDataLoading"
        (reloadTable)="pageChange($event)"
        [pageConfig]="pageConfig"
        [dataSource]="dataDisplayed"
        [columnsConfig]="columnsConfig">
        <ng-template appDtHeaderCellDef="schemeCode" let-element>
          <div class="l-d-f l-ai-cen">
            <cds-checkbox (change)="handleAllChange($event)" [indeterminate]="isIndeterminate" [checked]="isAllItemSelected"> </cds-checkbox>
            <span class="l-ml-5">{{ element['title'] | lang }}</span>
          </div>
        </ng-template>
        <ng-template appDtBodyCellDef="schemeCode" let-element>
          <div class="l-d-f l-ai-cen">
            <cds-checkbox (change)="updateChecked(element, $event)" [checked]="element.checked"> </cds-checkbox>
            <span class="l-ml-5">{{ element['schemeCode'] | lang }}</span>
          </div>
        </ng-template>
      </app-data-table>
    </div>
  </div>
</div>
<div class="export-bar l-d-f l-jc-sb l-ai-cen">
  <div class="tip">{{ 'common.selected.tips' | lang }}</div>
  <div>
    <span class="selected">{{ 'common.selected.item' | lang : { p1: dataSelected.length + '' } }}</span>
    <cds-button class="l-ml-7" type="submit" (click)="exportSelectedData()" [disabled]="exportSeltDisable" [style]="'secondary'" size="md">
      {{ 'common.export.selected' | lang }}
    </cds-button>
    <cds-button class="l-ml-4" type="submit" (click)="exportAllData()" [disabled]="isDataDownLoading" [style]="'primary'" size="md">
      {{ 'common.export.all' | lang }}
    </cds-button>
  </div>
</div>
<app-cd-download-progress [show]="isDataDownLoading"></app-cd-download-progress>
