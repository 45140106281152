/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { configPrimary, configSecondary, DividendRateSearch, initPage, InterestRateSearch, InterestRateStatus } from '../../employer';
import { CdsPopupService, MatDialogRef } from '@cds/ng-web-components/popup';
import { finalize } from 'rxjs';
import { InterestRateService } from '../../interest-rate.service';
import { DividendRateAddPopupComponent } from '../dividend-rate-add-popup/dividend-rate-add-popup.component';
import { DividendRate } from 'src/app/views/system/calendar/shared/calendar.model';
import { CdsOption } from '@cds/ng-core/configuration';
import { ToastAlertService } from 'src/app/shared/toast-alert.service';

@Component({
  selector: 'app-dividend-rate',
  templateUrl: './dividend-rate.component.html',
  styleUrls: ['./dividend-rate.component.scss'],
})
export class DividendRateComponent implements OnInit {
  constructor(private service: InterestRateService, private cdsPopup: CdsPopupService, private toastAlert: ToastAlertService) {}

  @Input() load = false;

  form = new FormGroup({
    year: new FormControl(),
  });

  searchDTO: DividendRateSearch = {
    ...initPage(),
    year: String(new Date().getFullYear()),
  };

  yearCodeConfig: CdsDropdownConfig = {
    label: 'Calendar Year',
    placeholder: 'Select Calendar Year',
    options: [],
  };

  searchButtonConfig = configPrimary();
  _searchDisable = false;

  resetButtonConfig = configSecondary();
  _resetDisable = false;

  addButtonConfig = configPrimary();

  approveConfig = configSecondary();

  _valid = false;

  _loading = false;

  pageConfig: PageConfig = {
    current: 1,
    totalCount: 0,
    pageSize: 10,
  };

  columnConfig: ColumnConfig[] = [
    { title: 'Month / Year', key: 'date' },
    { title: 'Dividend/ Unit', key: 'dividendRate' },
    { title: 'Record Date', key: 'recordDate' },
    { title: 'Approval', key: 'status' },
  ];

  dataList: DividendRate[] = [];

  ngOnInit(): void {
    this.yearsInit();
    // this.loadTable();
  }

  yearsInit() {
    this.service.getDividendYearList().subscribe(res => {
      if (res.data) {
        const _list: CdsOption[] = [];
        res.data.forEach(x => {
          _list.push({
            label: x,
            value: x,
          });
        });
        this.yearCodeConfig.options = _list;
        this.yearCodeConfig = { ...this.yearCodeConfig };
      }
    });
  }

  pageChange(ev: any) {
    if (this.pageConfig.current !== ev.current) {
      this.pageConfig.current = ev.current;
      this.loadTable();
    }
  }

  approveDividendRate(item: DividendRate) {
    this._loading = true;
    const param: string[] = [];
    if (item.id) {
      param.push(item.id);
    }
    this.service
      .diviendRateDoApprove(param)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(res => {
        if (res.result === 0) {
          this.loadTable();
        } else {
          this.toastError(res.message);
        }
      });
  }

  toastError(errorMessage: string) {
    this.toastAlert.show('error', 'common.error', errorMessage, 5000);
  }

  statusFormat(status: InterestRateStatus) {
    let re = '';
    switch (status) {
      case InterestRateStatus.PENDING:
        re = 'Pending approval';
        break;
      case InterestRateStatus.APPROVED:
        re = 'Approved';
        break;
      default:
    }
    return re;
  }

  add() {
    const popupRef: MatDialogRef<DividendRateAddPopupComponent> = this.cdsPopup.open(DividendRateAddPopupComponent, {
      size: 'lg',
      data: {},
    });
    popupRef.afterClosed().subscribe(data => {
      if (data) {
        this.loadTable();
      }
    });
  }

  getRequestParams() {
    const params: InterestRateSearch = { ...this.searchDTO };
    params.page = (this.pageConfig.current as number) - 1;
    return params;
  }

  loadTable() {
    const params: InterestRateSearch = this.getRequestParams();
    this._loading = true;
    this.service
      .getDividendRateEvents(params)
      .pipe(
        finalize(() => {
          this._loading = false;
        })
      )
      .subscribe(data => {
        if (data.result === 0) {
          this.dataList = data.data ? data.data.content : [];
          this.pageConfig = {
            ...this.pageConfig,
            totalCount: data.data ? data.data.totalElements : 0,
          };
        }
      });
  }

  selectedChange(val: string, type: string) {
    switch (type) {
      case 'year':
        this.searchDTO.year = val;
        break;
      default:
    }
    this.loadTable();
  }
}
