/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';

import { EventItem, EventType, QueryEventParams, EventTableItem, EventTypeKey } from 'src/app/views/system/calendar/shared/calendar.model';
import moment from 'moment';
import { CalendarService } from 'src/app/views/system/calendar/shared/calendar.service';
import {
  CATEGORY,
  I18N_KEY,
  EVENT_TABLE_COLUMNS_NORMAL,
  EVENT_TABLE_COLUMNS_PROC_DATE,
  EVENT_TABLE_COLUMNS_COMMISSION,
  EVENT_TABLE_DIVIDEND_RATE,
} from '../shared/calendar.config';
import { BasicResponse } from 'src/app/core/models/response/response';
import { map } from 'rxjs';
import { ReportNoValidator, dateFormRangeValidator } from '../shared/calendar.validators';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { PermissionService } from 'src/app/core/services/permission.service';
import { EventAddComponent } from '../event-add/event-add.component';
import { ColumnConfig, PageConfig } from 'src/app/shared/data-table';
import { FileType, aoaToSheet } from 'src/app/utils/xlsx';
import { CdPopupService, CdPopupSize, MatDialogRef } from 'src/app/shared/cd-popup';
import { dateValidator } from 'src/app/core/validators';
import { CdsOption } from '@cds/ng-core/configuration';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';

@Component({
  selector: 'app-event-edit-list',
  templateUrl: './event-edit-list.component.html',
  styleUrls: ['./event-edit-list.component.scss'],
})
export class EventEditListComponent implements OnInit {
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;

  eventTypes!: Array<EventType>;
  eventTypeSelect!: EventType;
  eventTypeKey = EventTypeKey;

  searchForm = new FormGroup(
    {
      fromDate: new FormControl('', [dateValidator({ error: I18N_KEY.INVALID_DATE_FROM_FORMAT })]),
      toDate: new FormControl('', [dateValidator({ error: I18N_KEY.INVALID_DATE_TO_FORMAT })]),
      reportNo: new FormControl('', [ReportNoValidator(I18N_KEY.INVALID_REPORT_NO)]),
    },
    { validators: dateFormRangeValidator(I18N_KEY.INVALID_DATE_FORM) }
  );
  isShowSearchDatePicker = true;

  yearCodeConfig: CdsDropdownConfig = {
    label: 'Calendar Year',
    placeholder: 'Select Calendar Year',
    options: [],
  };

  _year = '';

  get fromDate() {
    return this.searchForm.get('fromDate') as AbstractControl;
  }

  get toDate() {
    return this.searchForm.get('toDate') as AbstractControl;
  }

  get reportNo() {
    return this.searchForm.get('reportNo') as AbstractControl;
  }

  get isSearchDisabled() {
    return this.searchForm.invalid || (!this.fromDate.value && !this.toDate.value && !this.reportNo.value);
  }

  loading = false;
  pageConfig: PageConfig = {
    pageSize: 20,
  };
  dataDisplayed: any[] = [];
  columnsConfig: Array<ColumnConfig> = [{ key: 'date', title: 'EventDate' }];

  get isResetDisable() {
    return this.fromDate.value || this.toDate.value || (this.eventTypeSelect.key === EventTypeKey.PROC_DATE && this.reportNo.value);
  }

  approveDeatil: any;

  get downloadDisabled() {
    return this.dataDisplayed.length <= 0 || this.isDownLoading;
  }

  isDownLoading = false;

  constructor(private cdsPopup: CdPopupService, private calendarService: CalendarService, private permissionService: PermissionService) {}

  ngOnInit(): void {
    this.eventTypes = this.calendarService.createEventTypes(CATEGORY);
    this.eventTypeChange(this.eventTypes[0]);
  }

  getEvents(): void {
    this.loading = true;
    const params = this.createParams();
    this.calendarService
      .getEvents(params)
      .pipe(
        map((data: Array<BasicResponse<Array<EventItem> | any>>) => {
          return data
            .map((result: BasicResponse<Array<EventItem> | any>) => {
              const res = { ...result };
              if (this.eventTypeSelect.key === EventTypeKey.COMMISSION) {
                this.approveDeatil = res.data;
                res.data = res.data.events || [];
              }
              return res;
            })
            .filter((result: BasicResponse<Array<EventItem> | any>) => {
              return result.data && result.data.length;
            })
            .reduce((pre: Array<EventItem>, cur: BasicResponse<Array<EventItem>>) => {
              return pre.concat(cur.data as Array<EventItem>);
            }, []);
        })
      )
      .subscribe((data: Array<EventItem>) => {
        const tempData = this.createEvents(data);
        this.dataDisplayed = tempData;
        this.loading = false;
      });
  }

  createParams(): QueryEventParams {
    const obj: QueryEventParams = {};
    obj.eventType = this.eventTypeSelect.key;
    if (this.fromDate.value) {
      obj.eventStartDate = moment(this.fromDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (this.toDate.value) {
      obj.eventEndDate = moment(this.toDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (this.reportNo.value) {
      obj.reportNo = this.reportNo.value;
    }
    return obj;
  }

  createEvents(data: Array<EventItem>): Array<EventTableItem> {
    const tempData: Array<EventTableItem> = [];
    if (data && data.length) {
      data.forEach(item => {
        const { eventEndDate, eventDate } = item;
        const dateStr = eventDate ? eventDate : eventEndDate;
        const editable = this.eventTypeSelect.editable;
        tempData.push({
          eventDateStr: moment(new Date(dateStr), 'DD/MM/YYYY').format('DD/MM/YYYY'),
          actionDisplay: false,
          isChange: false,
          isShowDatePicker: true,
          editable,
          ...item,
        });
      });
    }
    return tempData;
  }

  sort(data: Array<EventTableItem>) {
    const temp = data.sort((a, b) => moment(b.eventEndDate, 'YYYY/MM/DD').diff(moment(a.eventEndDate, 'YYYY/MM/DD'), 'days'));
    return [...temp];
  }

  addNewEvent(): void {
    const popupRef: MatDialogRef<EventAddComponent> = this.cdsPopup.open(EventAddComponent, { size: CdPopupSize['LARGE'] });
    popupRef.afterClosed().subscribe(() => {});
  }

  eventTypeChange(eventType: EventType): void {
    if (this.eventTypeSelect === eventType) {
      return;
    }
    this.eventTypeSelect = eventType;
    this.searchForm.reset();

    let columnsConfig: Array<ColumnConfig> = EVENT_TABLE_COLUMNS_NORMAL;
    if (eventType.key == EventTypeKey.PROC_DATE) {
      columnsConfig = EVENT_TABLE_COLUMNS_PROC_DATE;
    }
    if (eventType.key == EventTypeKey.COMMISSION) {
      columnsConfig = EVENT_TABLE_COLUMNS_COMMISSION;
    }
    if (eventType.key == EventTypeKey.DIVIDEND_RECORD_DATE) {
      columnsConfig = EVENT_TABLE_DIVIDEND_RATE;
      this.yearsInit();
    }
    this.columnsConfig = columnsConfig;
    this.getEvents();
  }

  yearsInit() {
    this.calendarService.getDividendYearList().subscribe(res => {
      if (res.data) {
        const _list: CdsOption[] = [];
        res.data.forEach(x => {
          _list.push({
            label: x,
            value: x,
          });
        });
        this.yearCodeConfig.options = _list;
        this.yearCodeConfig = { ...this.yearCodeConfig };

        const nowY = new Date().getFullYear();
        this._year = nowY + '';
      }
    });
  }

  search() {
    if (this.isSearchDisabled) {
      return;
    }
    this.getEvents();
  }

  reset() {
    this.isShowSearchDatePicker = false;
    setTimeout(() => {
      this.isShowSearchDatePicker = true; // Fix DatePicker Bug
    });
    this.searchForm.reset();
    this.getEvents();
  }

  pageChange() {
    document.getElementsByClassName('cds-navigation-content')[0].scrollTo({
      top: 240,
      behavior: 'smooth',
    });
  }

  download() {
    if (this.downloadDisabled) {
      return;
    }

    this.isDownLoading = true;
    const fileName = this.eventTypeSelect.fileName;

    const excelData = this.createExcelData(this.dataDisplayed);
    this.isDownLoading = false;

    aoaToSheet(
      {
        fileName: `${fileName} ${moment().format('YYYYMMDD_HHmmss')}`,
        fileType: FileType.CSV,
      },
      excelData
    );
  }

  createExcelData(data: any[]) {
    const excelData: any[] = [];
    const fileHeader = 'Report Date:';
    let excelColumns = ['Event Date'];
    if (this.eventTypeSelect.key == EventTypeKey.PROC_DATE) {
      excelColumns = ['Proc Date', 'Report No.'];
    } else if (this.eventTypeSelect.key == EventTypeKey.COMMISSION) {
      excelColumns = ['From', 'To'];
    } else if (this.eventTypeSelect.key == EventTypeKey.HOLIDAY) {
      excelColumns = ['Holiday Date'];
    } else if (this.eventTypeSelect.key == EventTypeKey.A_C_MONTH_END) {
      excelColumns = ['A/C Month End Date'];
    }

    excelData.push([`${fileHeader}${moment().format('YYYY-MM-DD HH:MM:SS')}`]);
    excelData.push(excelColumns);
    data.forEach(item => {
      const temp = [];
      if (this.eventTypeSelect.key == EventTypeKey.PROC_DATE) {
        temp.push(item.eventEndDate);
        temp.push(item.reportNo);
      } else if (this.eventTypeSelect.key == EventTypeKey.COMMISSION) {
        temp.push(item.eventStartDate);
        temp.push(item.eventEndDate);
      } else if (this.eventTypeSelect.key == EventTypeKey.HOLIDAY || this.eventTypeSelect.key == EventTypeKey.A_C_MONTH_END) {
        temp.push(item.eventEndDate);
      }

      excelData.push(temp);
    });
    return excelData;
  }
}
