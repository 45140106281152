<app-cd-popup>
  <cds-popup-content>
    <div class="content">
      <div class="cds-h2-light" *ngIf="isReview">
        {{ 'calendar.event.review' | lang }}
      </div>
      <div class="cds-h2-light" *ngIf="!isReview">
        {{ 'calendar.event.approval' | lang }}
      </div>
      <div class="l-mb-5">
        <div class="cds-detail1 l-mb-3">Item(s) summary:</div>
        <div class="row l-plr-0">
          <div class="col-md-2 l-mr-1 l-pa-3 cds-white">
            <span class="cds-h2 cds-demibold cds-green-text">{{ event.firstDateNo }}</span>
            <div class="cds-dark-navy-text cds-demibold l-mt-3">1st date of report no.</div>
          </div>
          <div class="col-md-2 l-mr-1 l-pa-3 cds-white">
            <span class="cds-h2 cds-demibold cds-green-text">{{ event.lastDateNo }}</span>
            <div class="cds-dark-navy-text cds-demibold l-mt-3">Last date of report no.</div>
          </div>
          <div class="col-md-2 l-mr-1 l-pa-3 cds-white">
            <span class="cds-h2 cds-demibold cds-green-text">{{ event.publicHolidayCnt }}</span>
            <div class="cds-dark-navy-text cds-demibold l-mt-3">Number of public holiday</div>
          </div>
          <div class="col-md-2 l-mr-1 l-pa-3 cds-white">
            <span class="cds-h2 cds-demibold cds-green-text">{{ event.nonWorkingDayCnt }}</span>
            <div class="cds-dark-navy-text cds-demibold l-mt-3">Number of non working day</div>
          </div>
          <div class="col-md-2 l-mr-1 l-pa-3 cds-white">
            <span class="cds-h2 cds-demibold cds-green-text">{{ event.workingDayCnt }}</span>
            <div class="cds-dark-navy-text cds-demibold l-mt-3">Total number of working day</div>
          </div>
        </div>
      </div>
      <div class="row l-plr-0">
        <div class="col-xs-12 col-md-11 l-d-f alert l-pl-5 l-pr-5 l-pt-4 l-pb-4">
          <cds-icon class="cds-demibold l-pr-3" size="sm" icon="action:megaphone"></cds-icon>
          <div>
            <div class="cds-demibold l-pb-1">
              {{ 'common.alert' | lang }}
            </div>
            <div>
              {{ 'calendar.approve.alert' | lang }}
            </div>
          </div>
        </div>
      </div>
      <div class="l-mb-5 l-mt-5">
        <div class="cds-body2 cds-demibold l-mb-0">Event Name</div>
        <div class="cds-h4 l-mb-0">Report No.</div>
      </div>
      <div class="row l-mt-5 l-plr-0">
        <div class="col-xs-12 col-md-9">
          <app-data-table
            class="col-xs-12 col-md-10 col-lg-6 l-pt-5 l-plr-0"
            [frontPagination]="true"
            [isLoading]="loading"
            [overlay]="true"
            [fixed]="true"
            [pageConfig]="pageConfig"
            [dataSource]="dataDisplayed"
            [columnsConfig]="columnsConfig">
          </app-data-table>
        </div>
      </div>
    </div>
  </cds-popup-content>
  <cds-popup-actions>
    <cds-button [style]="'secondary'" size="sm" (click)="cancle()">{{ 'common.cancel' | lang }}</cds-button>
    <app-permission [role]="permissionAccess.W" [permission]="permissionItem.SYS_CALENDAR_REVIEWER" class="l-d-f l-ai-cen l-jc-cen">
      <cds-button size="sm" *ngIf="isReview" (click)="review()">{{ 'common.review' | lang }}</cds-button>
    </app-permission>
    <app-permission [role]="permissionAccess.W" [permission]="permissionItem.SYS_CALENDAR_APPROVE" class="l-d-f l-ai-cen l-jc-cen">
      <cds-button size="sm" *ngIf="!isReview" (click)="approve()">{{ 'common.approve' | lang }}</cds-button>
    </app-permission>
  </cds-popup-actions>
</app-cd-popup>
