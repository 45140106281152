<div class="row">
  <div class="col-xs-12 l-mt-7 cds-color-green anchor-parent">
    <div class="anchor-element" id="formulaClass"></div>
    <span class="cds-h4-demibold">{{ 'er.formula-class' | lang }}</span>
  </div>
  <div class="col-xs-12">
    <div class="p-line"></div>
  </div>
</div>

<app-no-result-found *ngIf="!dataList.length && !_loading"></app-no-result-found>

<div *ngIf="_loading" class="col-xs-12 l-pa-7 center-xs">
  <app-loading></app-loading>
</div>

<app-cd-accordion *ngIf="dataList.length">
  <app-cd-accordion-panel
    *ngFor="let item of dataList; index as itemIndex"
    [title]="'Formula Class No. ' + item.formulaClassNo"
    titleClass="accordion-title"
    [miniTitle]="item.formulaClassName"
    miniTitleClass="accordion-mini-title">
    <div class="row col-xs-12 l-plr-0">
      <app-row-item label="er.round-signal" [data]="formatRoundSignal(item.roundSignal)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="er.no-pay-leave" [data]="formatNopayLeave(item.noPayLeave)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="er.er-count-type" [data]="formatTypeER(item.erCountType)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="er.ee-cont-calc" [data]="formatType(item.eeContCalc)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="er.er-cont-calc" [data]="formatType(item.erContCalc)" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="er.ee-mac-mth-cont" [data]="item.eeMaxMthCont | number" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
      <app-row-item label="er.er-max-mth-cont" [data]="item.erMaxMthCont | number" class="col-xs-6 col-sm-3 col-md-3 col-lg-3"> </app-row-item>
    </div>

    <div class="col-xs-10 row l-plr-0 l-pt-6">
      <div class="col-xs-12 col-sm-12 col-md-6 l-pb-4">
        <div class="cds-xs-12 cds-color-green cds-body2 font-weight-6 font-style-italic l-pt-2">
          <span>EE Cont Table</span>
        </div>
        <ng-container>
          <cds-table [dataSource]="item.employeeBreakpointList || []" [class.full]="true">
            <ng-container cdsColumnDef="service">
              <cds-header-cell *cdsHeaderCellDef="let element">{{ formatContTableMap(item.employeeBreakpointType) }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.breakpoint | number : '1.2-2' }}</cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="val">
              <cds-header-cell *cdsHeaderCellDef>%</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.percentage | percent : '1.3-3' }}</cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="flatAmount">
              <cds-header-cell *cdsHeaderCellDef>Flat Amount</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.flatAmount | number : '1.2-2' }}</cds-cell>
            </ng-container>

            <cds-header-row *cdsHeaderRowDef="_contTableColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: _contTableColumns"></cds-row>
          </cds-table>
          <div *ngIf="!item.employeeBreakpointList" class="l-pt-7 l-pb-7">
            <app-no-result-found></app-no-result-found>
          </div>
        </ng-container>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 l-pb-4">
        <div class="cds-xs-12 cds-color-green cds-body2 font-weight-6 font-style-italic l-pt-2">ER Cont Table</div>
        <ng-container>
          <cds-table [dataSource]="item.employerBreakpointList || []" [class.full]="true">
            <ng-container cdsColumnDef="service">
              <cds-header-cell *cdsHeaderCellDef="let element">{{ formatContTableMap(item.employerBreakpointType) }}</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.breakpoint | number : '1.2-2' }}</cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="val">
              <cds-header-cell *cdsHeaderCellDef>%</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.percentage | percent : '1.3-3' }}</cds-cell>
            </ng-container>

            <ng-container cdsColumnDef="flatAmount">
              <cds-header-cell *cdsHeaderCellDef>Flat Amount</cds-header-cell>
              <cds-cell *cdsCellDef="let element">{{ element.flatAmount | number : '1.2-2' }}</cds-cell>
            </ng-container>

            <cds-header-row *cdsHeaderRowDef="_contTableColumns"></cds-header-row>
            <cds-row class="cds-body1" *cdsRowDef="let row; columns: _contTableColumns"></cds-row>
          </cds-table>
          <div *ngIf="!item.employerBreakpointList" class="l-pt-7 l-pb-7">
            <app-no-result-found></app-no-result-found>
          </div>
        </ng-container>
      </div>
    </div>
  </app-cd-accordion-panel>
</app-cd-accordion>
