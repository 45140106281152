/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';

import moment from 'moment';

import { PermissionService } from 'src/app/core/services/permission.service';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';

import { I18N_KEY } from '../calendar.config';
import { CdPopupService, MatDialogRef, CDS_POPUP_DATA, CdPopupType } from 'src/app/shared/cd-popup';
import { requiredValidator, dateValidator } from 'src/app/core/validators';
@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;
  hasPermission = false;
  isEditAble = true;
  isShowDatePicker = true;
  formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cdsPopup: CdPopupService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      event: any;
    },
    private permissionService: PermissionService
  ) {
    const { end, editable } = data.event;
    this.isEditAble = editable;
    this.formGroup = this.fb.group({
      eventDate: new FormControl(
        {
          value: moment(end, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          disabled: !this.isEditAble,
        },
        {
          initialValueIsDefault: true,
          validators: [requiredValidator({ error: I18N_KEY.DATE_REQUIRED }), dateValidator({ error: I18N_KEY.INVALID_DATE_FORMAT })],
        }
      ),
    });
  }

  ngOnInit(): void {
    this.permissionService.hasPermission(PermissionAccess.W, PermissionItem.SYS_CALENDAR).then(hasPermission => {
      this.hasPermission = hasPermission;
    });
  }

  get eventDate() {
    return this.formGroup.get('eventDate') as AbstractControl;
  }

  get saveDisabled() {
    return !this.isEditAble || this.eventDate.invalid || this.eventDate.value === moment(this.data.event.end, 'DD/MM/YYYY').format('DD/MM/YYYY');
  }

  get resetDisabled() {
    return this.eventDate.value === moment(this.data.event.end, 'DD/MM/YYYY').format('DD/MM/YYYY');
  }

  reset() {
    if (this.resetDisabled) {
      return;
    }
    this.isShowDatePicker = false;
    setTimeout(() => {
      this.isShowDatePicker = true; // fix datePicker bug
    });
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: I18N_KEY.COMMON_ACTION_CANCLE,
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.continue) {
        this.formGroup.reset();
      }
    });
  }

  onSubmit() {
    if (this.saveDisabled) {
      return;
    }
  }
}
