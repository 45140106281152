import { ColumnConfig } from 'src/app/shared/data-table';

export const PAGE_SIZE = 10;

export const I18N_KEY = {
  COMMON_GOT: 'common.gotItWithExclamation',
  INVALID_DATE_FORMAT: 'commission.errorLog.occurDateInvalid',
  INVALID_DATE_REANGE: 'commission.common.dateRangeInvalid',
  ERROR_DATE_REANGE: 'commission.common.dateRangeError',
  ACCOUNT_NO_INVALID: 'commission.common.accountNo.invalid',
  MEMBER_NO_INVALID: 'commission.common.memberNo.invalid',
};

export const ERROR_LOG_COLUMNS: Array<ColumnConfig> = [
  { key: 'occurDate', sticky: true, title: 'commission.errorLog.occurDate' },
  { key: 'errSign', stickyEnd: true, title: 'commission.errorLog.errSignal' },
  { key: 'errMsg', title: 'commission.errorLog.message' },
  { key: 'commissionPoolId', title: 'commission.common.commPoolNo' },
  { key: 'payReferenceNo', title: 'commission.common.payReferenceNo' },
  { key: 'referenceNo', title: 'commission.common.transactionReferenceNo' },
  { key: 'employerAccountCode', title: 'commission.common.accountNo' },
  { key: 'memberAccountCode', title: 'commission.common.memberNo' },
  { key: 'accountType', title: 'commission.common.accountType' },
  { key: 'payRollGroupNo', title: 'commission.common.payroolGroupId' },
  { key: 'contStartDate', title: 'commission.common.contStart' },
  { key: 'vipCode', title: 'commission.common.vipCode' },
  { key: 'txnCode', title: 'commission.common.txn' },
];
