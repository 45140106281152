<app-cd-page-title title="{{ 'Batch Upload For Commission Adjustment' | lang }}"> </app-cd-page-title>
<div class="row">
  <div class="col-xs-12 l-pt-4">
    <app-cd-table-title class="l-pt-3 l-pb-3" [title]="'common.recordList' | lang" [total]="totalElements">
      <ng-template #tableAction>
        <app-permission [role]="permissionAccess.W" [permission]="permissionItem.COMM_ADJ_BATCH_UPLOAD">
          <cds-button (click)="upload()" [style]="'secondary'" size="sm">
            <span>File Upload</span>
          </cds-button>
        </app-permission>
      </ng-template>
    </app-cd-table-title>
    <app-data-table
      [frontPagination]="false"
      [fixed]="false"
      [isLoading]="loading"
      (reloadTable)="pageChange($event)"
      [pageConfig]="pageConfig"
      [dataSource]="dataDisplayed"
      [columnsConfig]="columnsConfig">
      <ng-template appDtBodyCellDef="fileName" let-element>
        <span class="cds-body1 cds-demibold text-underline" (click)="fileDetail(element)">{{ element['fileName'] }}</span>
      </ng-template>
      <ng-template appDtBodyCellDef="action" let-element>
        <div class="l-d-f l-ai-cen">
          <app-permission class="l-mr-4" [role]="permissionAccess.E" [permission]="permissionItem.COMM_ADJ_BATCH_UPLOAD">
            <cds-icon icon="form:delete" (click)="delete(element['id'])" class="cursor-pointer l-mr-1" *ngIf="element['isShowDelete']"></cds-icon>
          </app-permission>
          <cds-button (click)="validate(element['id'], element['fileName'])" [style]="'secondary'" size="sm" *ngIf="element['isShowValidate']">
            <span>Validate</span>
          </cds-button>
        </div>
        <!-- <cds-icon style="vertical-align: sub" icon="form:delete" (click)="upload()"> </cds-icon> -->
      </ng-template>
    </app-data-table>
  </div>
</div>
