/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
// import { CdsOption } from '@cds/ng-core/configuration';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { ResponseResult } from 'src/app/core/models/response/response-result';
import { CommissionDefaultScaleService } from '../commission-default-scale.service';
import { IPaginationCount } from 'src/app/shared/pagination/pagination.dto';
import { UserAgreementPopupComponent } from 'src/app/views/commission/user-agreement-popup.component';
import { I18N_KEY, MacauChannelTypes, MacauScaleTypeMap, MacauCommissionRates } from '../commission-default-scale.config';
import { AutoChangeListComponent } from './auto-change-list/auto-change-list.component';
import { DefaultScaleType } from 'src/app/core/models/enum/default-scale-type.enum';
import { ScaleType } from 'src/app/core/models/enum/commission-scale.enum';
import { requiredValidator, ScaleCodeAsyncValidator } from 'src/app/core/validators';
import { AccountType, MacauAccountTypes, PermissionAccess, PermissionItem } from 'src/app/core/models/enum';
import { CdPopupService, CdPopupSize, MatDialogRef } from 'src/app/shared/cd-popup';

@Component({
  selector: 'app-new-business-macau',
  templateUrl: './new-business-macau.component.html',
  styleUrls: ['./new-business-macau.component.scss'],
})
export class NewBusinessMacauComponent implements OnInit, OnDestroy {
  permissionItem = PermissionItem.SYS_COMMISSION_DEFAULT_SCALE;
  permissionAccess = PermissionAccess.W;
  channelType = MacauChannelTypes;
  channelTypeSelected: any;
  commissionRate = MacauCommissionRates;
  commissionRateSelected: any;
  accountType = MacauAccountTypes;
  accountTypeMacauKey = AccountType.MACAU_PPS_ER;
  accountTypeSelected: any;
  searchParam$ = new Subject<any>();

  // options: CdsOption[] = MacauScenarios;
  // scenario: any;
  // quickSearchFormcontrol: FormControl = new FormControl();
  disPlayData: any[] = [];
  progress = false;
  totalElements = 0;
  pageCount: IPaginationCount = { current: 1, count: 1 };
  formGroup = this.fb.group({
    details: this.fb.array([]),
  });

  get details() {
    return this.formGroup.controls['details'] as FormArray;
  }

  constructor(
    private scaleCodeAsyncValidator: ScaleCodeAsyncValidator,
    private cdsPopup: CdPopupService,
    private fb: FormBuilder,
    private defaultScaleService: CommissionDefaultScaleService
  ) {
    this.searchParam$
      .pipe(
        debounceTime(500),
        // distinctUntilChanged(),
        switchMap(param => {
          return this.defaultScaleService.getList(param);
        })
      )
      .subscribe(res => {
        this.initData(res.data);
      });
  }

  ngOnInit() {
    this.search();
    // if (this.scenario) {
    //   this.quickSearchFormcontrol.setValue(this.scenario);
    // }
  }

  ngOnDestroy(): void {
    this.searchParam$.unsubscribe();
  }

  resetFilter() {
    this.channelTypeSelected = null;
    this.accountTypeSelected = null;
    this.search();
  }

  channelFilterClick(item: any) {
    this.channelTypeSelected = this.channelTypeSelected === item ? null : item;
    this.search();
  }

  accountFilterClick(item: any) {
    this.accountTypeSelected = this.accountTypeSelected === item ? null : item;
    this.search();
  }

  // quickSearch(opt: any) {
  //   this.scenario = opt;
  //   this.search();
  // }

  pageChange(currentPage: number) {
    this.pageCount.current = currentPage;
    this.getData();
  }

  search() {
    this.pageCount = { current: 1, count: 1 };
    this.getData();
  }

  getData() {
    const param = this.createParam();
    this.progress = true;
    this.searchParam$.next(param);
  }

  createParam() {
    const param: any = {
      defaultScaleType: DefaultScaleType.IFAST_NB,
    };
    param.page = (this.pageCount.current as number) - 1;
    param.size = 10;
    if (this.channelTypeSelected) {
      param.agentChannel = this.channelTypeSelected.value;
    }
    if (this.accountTypeSelected) {
      param.accountType = this.accountTypeSelected.value;
    }
    // if (this.commissionRateSelected) {
    //   param.compensationRate = this.commissionRateSelected.value;
    // }
    // if (this.scenario) {
    //   param.scenario = this.scenario.value;
    // }
    return param;
  }

  initData(data: any) {
    this.progress = false;
    this.totalElements = data.totalElements;
    this.pageCount = {
      ...this.pageCount,
      count: data.totalPages || 0,
    };
    this.details.clear();
    const content = data.content || [];
    this.disPlayData = content.map((item: any, index: number) => {
      const obj = this.createItemFormGroup(item);
      item.detail.index = index;
      item.detail.isEditAble = true;
      item.detail.isEdit = false;
      const accountType = this.accountType.find((e: any) => e.value === item.accountType);
      item.accountTypeLabel = accountType ? accountType.label : '';
      const channel = this.channelType.find((e: any) => e.value === item.agentChannel);
      item.agentChannelLabel = channel ? channel.label : '';
      const compensationRate = this.commissionRate.find((e: any) => e.value === item.compensationRate);
      item.compensationRateLabel = compensationRate ? compensationRate.label : '';
      if (item.accountType === AccountType.MACAU_PAP && !item.agentChannel && !item.compensationRate && item.subscriptionFee === undefined) {
        item.detail.isEditAble = false;
      }
      this.details.push(this.fb.group(obj));
      return item;
    });
  }

  createItemFormGroup(scenario: any) {
    const fp: any = {};
    const accountType = scenario.accountType;
    scenario.detail.list = [];
    MacauScaleTypeMap.forEach((label: string, key: string) => {
      fp[key] = new FormControl(scenario.detail[key], {
        initialValueIsDefault: true,
        updateOn: 'blur',
        validators: this.isScaleCodeRequired(key, accountType)
          ? [
              requiredValidator({
                error: I18N_KEY.COMMISSION_SCALE_CODE_INPUT,
              }),
            ]
          : [],
        asyncValidators: [this.scaleCodeAsyncValidator.validate.bind(this.scaleCodeAsyncValidator)],
      });
      scenario.detail.list.push({
        label,
        value: scenario.detail[key],
        key,
      });
    });
    return fp;
  }

  isScaleCodeRequired(scaleType: string, accountType: string) {
    if (accountType === AccountType.MACAU_PPS_ER || accountType === AccountType.MACAU_CPF_ER) {
      return (
        scaleType == ScaleType.FS_PC ||
        scaleType == ScaleType.FY ||
        scaleType == ScaleType.FS_SF ||
        scaleType == ScaleType.TRF_SP_PC ||
        scaleType == ScaleType.TRF_SP ||
        scaleType == ScaleType.PS_PC ||
        scaleType == ScaleType.PS
      );
    }
    return (
      scaleType == ScaleType.FS_PC ||
      scaleType == ScaleType.FY ||
      scaleType == ScaleType.FS_SF ||
      scaleType == ScaleType.TRF_SP_PC ||
      scaleType == ScaleType.TRF_SP
    );
  }

  editScaleCode(detail: any) {
    if (!detail.isEditAble) {
      return;
    }
    detail.isEdit = !detail.isEdit;
    if (!detail.isEdit) {
      this.details.controls[detail.index].reset();
    }
  }

  scaleCodeChange(detail: any, key: string, event: any) {
    let value = event.target.value;
    value = value.toUpperCase().replace(/[^\a-\z\A-\Z0-9\ ]/g, ''); // eslint-disable-line
    value = value.substring(0, 6);
    const pos = event.target.selectionEnd;
    event.target.setSelectionRange(pos, pos);
    this.details.controls[detail.index].get(key)?.setValue(value);
  }

  save(editItem: any) {
    const editIndex = editItem.detail.index;
    if (!this.details.controls[editIndex].dirty || this.details.controls[editIndex].invalid) {
      return;
    }

    const param: any = [];
    const detailsValue = this.details.controls[editIndex].value;
    editItem.detail.list.forEach((item: any) => {
      param.push({
        defaultScaleType: DefaultScaleType.IFAST_NB,
        subscriptionFee: editItem.subscriptionFee,
        accountType: editItem.accountType,
        agentChannel: editItem.agentChannel,
        compensationRate: editItem.compensationRate,
        commissionType: item.key,
        scaleCode: detailsValue[item.key]?.trim(),
      });
    });

    this.defaultScaleService.update(param).subscribe(resp => {
      if (resp && resp.result == ResponseResult.SUCCESS) {
        editItem.detail.isEdit = false;
        editItem.detail = { ...editItem.detail, ...detailsValue };
        const obj = this.createItemFormGroup(editItem);
        this.details.setControl(editIndex, this.fb.group(obj));
      }
    });
  }

  reset(detail: any) {
    const popupRef: MatDialogRef<UserAgreementPopupComponent> = this.cdsPopup.open(UserAgreementPopupComponent, {
      data: {
        message: I18N_KEY.COMMON_ACTION_CANCLE,
        cancel: I18N_KEY.COMMON_CANCEL,
        continue: I18N_KEY.COMMON_CONTINUE,
        type: 'confirm',
      },
    });
    popupRef.afterClosed().subscribe((confirm: any) => {
      if (confirm.agree) {
        this.details.controls[detail.index].reset();
      }
    });
  }

  onChangeList(editItem: any) {
    const popupRef: MatDialogRef<AutoChangeListComponent> = this.cdsPopup.open(AutoChangeListComponent, {
      data: editItem,
      size: CdPopupSize.LARGE,
    });
    popupRef.afterClosed().subscribe(() => {});
  }
}
