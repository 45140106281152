/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdsDropdownConfig } from '@cds/ng-core/dropdown';
import { CdsIconConfig } from '@cds/ng-core/icon';
import { CdsLangService } from '@cds/ng-core/lang';
import { ActionIcons } from '@cds/ng-themes';
import { CdsAlertService } from '@cds/ng-web-components/alert';
import { MatDialogRef } from '@cds/ng-web-components/popup';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/services/notify.service';
import { CdHttpServeService } from 'src/app/shared/cd-http-serve/cd-http-serve.service';
import { CdPopupService, CdPopupType } from 'src/app/shared/cd-popup';
import { CdRadioComponent, CdRadioConfig } from 'src/app/shared/cd-radio/cd-radio.component';
import { LanguageChangeService } from 'src/app/shared/language-change.service';
import { PreferredLanguageType } from '../../case-summary/case-summary.model';
import { empfCountryOption } from '../../case-summary/declaration-iff-er/empf-declaration-form.config';
import { ResetWarningComponent } from '../../iff-paper-form/reset-warning/reset-warning.component';
import { ProductType } from '../../md-iff-summary/md-iff-summary.model';
import { HasBeenSentCustomerPopupComponent } from '../has-been-sent-customer-popup/has-been-sent-customer-popup.component';
import { PendingSplitAgentConfirPopupComponent } from '../pending-split-agent-confir-popup/pending-split-agent-confir-popup.component';

enum Show {
  selectProductType = 'selectProductType',
  individuals = 'individuals',
  intermediary = 'intermediary',
}

type Domain = 'IND' | 'ER';

interface AgentInfo {
  agentId: string;
  agentCode: string;
  agentName: string;
  agentNameTC: string;
  mobileNumber: string;
  emailAddress: string;
  branchCode: string;
  agentCategory: string;
  licenseNumber: string;
  licenseCode: string;
  licenseStatus: string;
  status: string;
  shortEmail?: string;
  shortPhone?: string;
}

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss'],
})
export class IndividualComponent implements OnInit, OnDestroy {
  @ViewChild('displayContent') displayContent!: ElementRef<HTMLDivElement>;
  @ViewChild('cdRadioPreferredLanguage') cdRadioPreferredLanguage!: CdRadioComponent;
  @ViewChild('dbsReferredLanguage') dbsReferredLanguage!: CdRadioComponent;
  @ViewChild('radioRelevantRegulatedActivities') radioRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioSecondAgentRelevantRegulatedActivities') radioSecondAgentRelevantRegulatedActivities!: CdRadioComponent;
  @ViewChild('radioSecondAgentClientContact') radioSecondAgentClientContact?: CdRadioComponent;
  @ViewChild('radioSecondAgentLanguage') radioSecondAgentLanguage?: CdRadioComponent;
  domain?: Domain;
  PreferredLanguageType = PreferredLanguageType;
  Show = Show;
  show = Show.selectProductType;
  currentStep = 1;
  isConfirming = false;
  isScrollBottom = false;
  onScoll = () => {
    const scrollHeight = this.displayContent.nativeElement.scrollHeight;
    const clientHeight = this.displayContent.nativeElement.clientHeight;

    if (!this.isScrollBottom && this.displayContent.nativeElement.scrollTop > scrollHeight - clientHeight - 10) {
      this.isScrollBottom = true;
    }
  };
  backIconConfig: CdsIconConfig = {
    color: 'cta',
    size: 'sm',
  };
  backIcon = ActionIcons.button_left_filled;
  individualsForm!: FormGroup;
  secondAgentCode = '';
  isTryConfirm = false;
  language = 'EN';
  prodTypeDropdownConfig: CdsDropdownConfig = {
    options: [
      {
        label: ProductType.PA,
        value: ProductType.PA,
      },
      {
        label: ProductType.TVC,
        value: ProductType.TVC,
      },
      {
        label: ProductType.SVC,
        value: ProductType.SVC,
      },
      {
        label: ProductType.SEP,
        value: ProductType.SEP,
      },
    ],
    placeholder: 'Select Product Type',
    color: 'ml',
  };
  lanChangeSubscription?: Subscription;
  clientContactConfig: CdRadioConfig = {
    options: [
      {
        label: 'Email Address',
        value: 'E',
        disable: false,
      },
      {
        label: 'Phone Number',
        value: 'M',
        disable: false,
      },
    ],
  };
  isDBschannel = '';
  dbsContactConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };
  clientContact = '';
  countrycodeConfig: CdsDropdownConfig = {
    options: empfCountryOption,
    placeholder: '+852 (HK)',
    color: 'ml',
  };
  private readonly CN_PHONE = /^1[345678]\d{9}$/;

  preferredLanguageConfig = {
    options: [
      {
        label: 'common.chinese',
        value: PreferredLanguageType.Chinese,
        disable: true,
      },
      {
        label: 'common.english',
        value: PreferredLanguageType.English,
        disable: true,
      },
    ],
  };

  idTypeConfig: CdsDropdownConfig = {
    options: [
      { label: this.cdsLangService.translate('iff.hkid'), value: '0' },
      { label: this.cdsLangService.translate('iff.passport'), value: '1' },
    ],
    placeholder: this.cdsLangService.translate('iff.pleaseSelectIDType'),
  };

  relevantRegulatedActivitiesValue = '';
  relevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'n',
        disable: false,
      },
    ],
  };
  resetDatePicker = false;
  secondAgent?: AgentInfo;
  secondAgentRelevantRegulatedActivities = '';
  secondAgentRelevantRegulatedActivitiesConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.yes'),
        value: 'Y',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.no'),
        value: 'N',
        disable: false,
      },
    ],
  };

  secondAgentClientContact = '';
  secondAgentClientContactConfig?: CdRadioConfig;

  secondAgentLanguage = '';
  secondAgentLanguageConfig: CdRadioConfig = {
    options: [
      {
        label: this.cdsLangService.translate('common.chinese'),
        value: 'ZH',
        disable: false,
      },
      {
        label: this.cdsLangService.translate('common.english'),
        value: 'EN',
        disable: false,
      },
    ],
  };

  get preferredLanguage() {
    return this.individualsForm.get('preferredLanguage') as FormControl;
  }

  get customerSurname() {
    return this.individualsForm.get('customerSurname') as FormControl;
  }

  get customerGivenName() {
    return this.individualsForm.get('customerGivenName') as FormControl;
  }

  get isCustomerError() {
    if (!this.isTryConfirm) {
      return false;
    }
    if (!this.customerSurname && !this.customerGivenName) {
      return true;
    } else if ((this.customerSurname && !this.customerGivenName) || (!this.customerSurname && this.customerGivenName)) {
      return true;
    }
    return false;
  }

  get hkidOrPassportNo() {
    return this.individualsForm.get('hkidOrPassportNo') as FormControl;
  }

  get idType() {
    return this.individualsForm.get('idType') as FormControl;
  }

  get rmReferralCode() {
    return this.individualsForm.get('rmReferralCode') as FormControl;
  }
  get inputCustomerId() {
    return this.individualsForm.get('inputCustomerId') as FormControl;
  }
  get sourceCode() {
    return this.individualsForm.get('sourceCode') as FormControl;
  }
  get campaignCode() {
    return this.individualsForm.get('campaignCode') as FormControl;
  }

  get getSecondAgentName() {
    if (!this.secondAgent) {
      return 'N/A';
    }
    if (this.cdsLangService.currentLang === 'en') {
      return this.secondAgent.agentName || this.secondAgent.agentNameTC;
    } else {
      return this.secondAgent.agentNameTC || this.secondAgent.agentName;
    }
  }

  // get servicingAgentCode() {
  //   return this.individualsForm.get('servicingAgentCode') as FormControl;
  // }

  // get splitAgentCode() {
  //   return this.individualsForm.get('splitAgentCode') as FormControl;
  // }

  // get isSplitAgentCodePerformed() {
  //   return this.individualsForm.get('isSplitAgentCodePerformed') as FormControl;
  // }

  get clientPhoneNumber() {
    return this.individualsForm.get('clientPhoneNumber') as FormControl;
  }

  get clientCountryCode() {
    return this.individualsForm.get('clientCountryCode') as FormControl;
  }

  get clientEmailAddress() {
    return this.individualsForm.get('clientEmailAddress') as FormControl;
  }

  get productType() {
    return this.individualsForm.get('productType') as FormControl;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public cdsLangService: CdsLangService,
    private cdHttpServeService: CdHttpServeService,
    private alert: CdsAlertService,
    private notifyService: NotifyService,
    private languageChangeService: LanguageChangeService,
    private cdsPopup: CdPopupService
  ) {}

  hKIDPassportNoChange() {
    this.hkidOrPassportNo.updateValueAndValidity();
  }

  hkidOrPassportNoChange(event: string) {
    const value = this.hkidOrPassportNo.value?.replace(/[\u4e00-\u9fa5]/gi, '');
    if (event !== value) {
      this.hkidOrPassportNo.setValue(value);
    }

    if (/^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(value)) {
      if (this.idType.value === '0') {
        this.cdHttpServeService.post(`/ext/eb-ssms-sales-journey-service/hkidCheckDigit`, { hkId: value.trim() }).subscribe({
          next: res => {
            if (!(res.result === 0 && res.data)) {
              this.hkidOrPassportNo.setErrors({ formatError: true });
            }
          },
          error: err => {
            this.hkidOrPassportNo.setErrors({ formatError: true });
            this.alert.error('Error!', err);
          },
        });
      }
    }
  }

  clientCountryCodeChange() {
    this.clientPhoneNumber.updateValueAndValidity();
  }

  clientContactChange() {
    this.preferredLanguage.setValue(undefined);
    this.preferredLanguageConfig = {
      options: [
        {
          label: 'common.chinese',
          value: PreferredLanguageType.Chinese,
          disable: false,
        },
        {
          label: 'common.english',
          value: PreferredLanguageType.English,
          disable: false,
        },
      ],
    };
    this.cdRadioPreferredLanguage?.refreshConfig();
    if (this.clientContact === 'E') {
      this.clientPhoneNumber.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.displayContent?.nativeElement.removeEventListener('scroll', this.onScoll);
  }

  dbsContractChange(val: string) {
    this.isDBschannel = val;
    if (val === 'N') {
      this.individualsForm.get('rmReferralCode')?.reset();
      this.individualsForm.get('inputCustomerId')?.reset();
      this.individualsForm.get('campaignCode')?.reset();
      this.individualsForm.get('sourceCode')?.reset();
    }
  }

  lanSubjectChange = (lan: string) => {
    this.language = lan === 'en' ? 'EN' : 'TC';

    this.clientContactConfig = {
      options: [
        {
          label: this.cdsLangService.translate('generate.md.emailAddress'),
          value: 'E',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('generate.md.phoneNumber'),
          value: 'M',
          disable: false,
        },
      ],
    };

    this.idTypeConfig = {
      options: [
        { label: this.cdsLangService.translate('iff.hkid'), value: '0' },
        { label: this.cdsLangService.translate('iff.passport'), value: '1' },
      ],
      placeholder: this.cdsLangService.translate('iff.pleaseSelectIDType'),
    };

    this.refreshSecondAgentClientContactConfig();
    this.refreshRelevantRegulatedActivitiesConfig();
    this.refreshDbsContactConfig();
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
    this.refreshSecondAgentLanguageConfig();
  };

  private refreshSecondAgentLanguageConfig() {
    this.secondAgentLanguageConfig.options[0].label = this.cdsLangService.translate('common.chinese');
    this.secondAgentLanguageConfig.options[1].label = this.cdsLangService.translate('common.english');
    this.radioSecondAgentLanguage?.refreshConfig();
  }

  private refreshSecondAgentRelevantRegulatedActivitiesConfig() {
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioSecondAgentRelevantRegulatedActivities?.refreshConfig();
  }

  ngOnInit(): void {
    this.domain = (this.route.snapshot.paramMap.get('domain') as Domain) || undefined;

    this.lanChangeSubscription = this.languageChangeService.lanSubject.subscribe(this.lanSubjectChange);

    this.individualsForm = new FormGroup({
      customerSurname: new FormControl('', [Validators.required]),
      customerGivenName: new FormControl('', [Validators.required]),
      productType: new FormControl(null, [Validators.required]),
      // eMPFReferenceNo: new FormControl('', [Validators.required]),
      // paperIFFAgentSubmissionDateFrom: new FormControl('', [Validators.required, this.indFormPaperIFFAgentSubmissionDateFromVali]),
      // conductedRegulatorActivityFlag: new FormControl(null),
      idType: new FormControl('0'),
      hkidOrPassportNo: new FormControl('', [Validators.required, this.indFormHkidOrPassportNoVali]),
      clientEmailAddress: new FormControl(null, [this.clientEmailVali]),
      clientCountryCode: new FormControl('852', [Validators.required]),
      clientPhoneNumber: new FormControl('', [this.idFormClientPhoneNumberVali]),
      // servicingAgentCode: new FormControl(null, [Validators.required]),
      // splitAgentCode: new FormControl(null),
      // isServicingAgentCodePerformed: new FormControl(false),
      // isSplitAgentCodePerformed: new FormControl(false),
      preferredLanguage: new FormControl(null, [this.idFormPreferredLanguageVali]),
      rmReferralCode: new FormControl('', [this.rmReferralCodeVali]),
      inputCustomerId: new FormControl('', [this.inputCustomerIdVali]),
      sourceCode: new FormControl('', [this.sourceCodeVali]),
      campaignCode: new FormControl('', [this.campaignCodeVali]),
    });

    // this.servicingAgentCode.valueChanges.subscribe(this.servicingAgentCodeValueChanges);
  }

  rmReferralCodeVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.isDBschannel === 'Y') {
      let input = control.value || '';
      input = input.trim();
      const isValid = input.length >= 6 && input.length <= 8;
      return isValid ? null : { lengthError: true };
    }
    return null;
  };
  inputCustomerIdVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = ((input.length >= 6 && input.length <= 8) || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  sourceCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };
  campaignCodeVali = (control: AbstractControl): ValidationErrors | null => {
    let input = control.value || '';
    input = input.trim();
    const isValid = (input.length === 10 || input.length === 0) && /^[A-Za-z0-9-]*$/.exec(input);
    return isValid ? null : { lengthError: true };
  };

  idFormPreferredLanguageVali = (control: AbstractControl): ValidationErrors | null => {
    if ((this.clientContact === 'M' && control.value) || this.clientContact === 'E' || !this.clientContact) {
      return null;
    }
    return { formatError: true };
  };

  clientEmailVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.clientContact === 'E') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        if (!option.value.includes('@')) {
          return { email: true };
        }
      }
    }
    return null;
  };

  idFormClientPhoneNumberVali = (option: AbstractControl): ValidationErrors | null => {
    if (this.clientContact === 'M') {
      if (!option.value) {
        return { required: true };
      }

      if (typeof option.value === 'string') {
        if (['852', '853'].includes(this.clientCountryCode.value)) {
          if (option.value.length === 8 && ['4', '5', '6', '7', '8', '9'].includes(option.value.substring(0, 1))) {
            return null;
          }
        }

        if (['86'].includes(this.clientCountryCode.value)) {
          if (option.value.length === 11) {
            const e = this.CN_PHONE.test(option.value);
            if (e) {
              return null;
            }
          }
        }

        if (!['852', '853', '86'].includes(this.clientCountryCode.value)) {
          return null;
        }
      }

      return { errorPhoneNumber: true };
    }

    return null;
  };

  // servicingAgentCodeValueChanges = (res: string) => {
  //   if (res === '') {
  //     this.splitAgentCode.setValue('');
  //     this.isSplitAgentCodePerformed.setValue(false);
  //   }
  // };

  indFormHkidOrPassportNoVali = (control: AbstractControl): ValidationErrors | null => {
    if (this.individualsForm?.get('idType')?.value === '1') {
      return null;
    }
    if (control.value === '' || control.value == undefined) {
      return null;
    }
    let input: string = control.value;
    input = input.trim();
    const isValid: RegExpExecArray | null | boolean = /^[a-zA-Z]{1,2}\d{6}\([aA\d]\)$/.exec(input);
    return isValid ? null : { formatError: true };
  };

  showDeclaration() {
    this.currentStep = 2;
    setTimeout(() => {
      this.displayContent?.nativeElement.addEventListener('scroll', this.onScoll);
    }, 0);
  }

  toIndividualsEdit() {
    if (!this.isScrollBottom) {
      return;
    }

    this.show = Show.individuals;
  }

  onBack() {
    if (this.isConfirming) {
      return;
    }
    this.displayContent?.nativeElement.removeEventListener('scroll', this.onScoll);
    this.router.navigate(['/salesjourney/md-iff-summary']);
  }

  reset() {
    const popupRef: MatDialogRef<ResetWarningComponent> = this.cdsPopup.open(ResetWarningComponent, {
      size: 'sm',
    });
    popupRef.afterClosed().subscribe(this.resetWarningComponentPopupCloseCall);
  }

  resetWarningComponentPopupCloseCall = (confirm: { agree: boolean }) => {
    if (confirm.agree) {
      this.individualsForm.reset();
      this.clientCountryCode.setValue('852');
      this.idType.setValue('0');
      this.isTryConfirm = false;
      this.relevantRegulatedActivitiesValue = '';
      this.resetDatePicker = true;
      this.clientContact = '';
      this.isDBschannel = '';
      this.secondAgentCode = '';
      this.secondAgent = undefined;
      setTimeout(() => {
        this.resetDatePicker = false;
        // setTimeout(() => {
        //   this.setPaperAgentSubmissionDateReadonly();
        // }, 0);
      }, 0);
      this.preferredLanguage.reset();
      this.preferredLanguageConfig = {
        options: [
          {
            label: 'common.chinese',
            value: PreferredLanguageType.Chinese,
            disable: true,
          },
          {
            label: 'common.english',
            value: PreferredLanguageType.English,
            disable: true,
          },
        ],
      };
      this.cdRadioPreferredLanguage?.refreshConfig();
    }
  };

  backToEdit() {
    const popupRef: MatDialogRef<any> = this.cdsPopup.openCommon({
      type: CdPopupType.CONTINUE,
      data: {
        message: 'generate.md.leave-page',
        continue: 'common.confirm-continue',
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.continue) {
        this.isConfirming = false;
        this.dbsContactConfig.options.forEach((e: { disable: boolean }) => {
          e.disable = false;
        });
        this.dbsReferredLanguage?.refreshConfig();
        this.setRelevantRegulatedActivitiesEnable();
        this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = false;
        this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = false;
        this.secondAgentRelevantRegulatedActivities = '';
        this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
        this.secondAgentClientContact = '';
        this.secondAgentLanguage = '';
      }
    });
  }

  private refreshDbsContactConfig() {
    this.dbsContactConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.dbsContactConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.dbsReferredLanguage?.refreshConfig();
  }

  next() {
    if (this.isDisableNext) {
      return;
    }
    this.customerSurname.markAsDirty();
    this.customerGivenName.markAsDirty();
    this.hkidOrPassportNo.markAsDirty();
    this.isTryConfirm = true;
    this.clientEmailAddress.markAsDirty();
    this.clientPhoneNumber.markAsDirty();
    this.preferredLanguage.markAsTouched();

    // const boo = this.isAllowPast();

    if (this.individualsForm.valid) {
      this.show = Show.individuals;
      this.isConfirming = true;
      this.setRelevantRegulatedActivitiesDisable();
      if (this.secondAgentCode) {
        this.show = Show.intermediary;
        this.getAgentInfoByAgentCode(this.secondAgentCode).then(agentInfo => {
          this.secondAgent = agentInfo;
          this.refreshSecondAgentClientContactConfig();
        });
      }
    }
  }

  private refreshSecondAgentClientContactConfig() {
    if (!this.secondAgent) {
      return;
    }
    this.secondAgent.shortEmail = '';
    if (this.secondAgent.emailAddress) {
      const ea = this.secondAgent.emailAddress || '';
      const endIndex = ea.indexOf('@');
      this.secondAgent.shortEmail = ea.substring(0, 4) + '*****' + ea.substring(endIndex);
    }
    this.secondAgent.shortPhone = '';
    if (this.secondAgent.mobileNumber) {
      const mn = this.secondAgent.mobileNumber || '';
      let phoneArr = new Array((mn + '').length - 6);
      phoneArr = phoneArr.fill('*');
      this.secondAgent.shortPhone = mn.substring(0, 6) + phoneArr.join('');
    }

    this.secondAgentClientContactConfig = {
      options: [
        {
          label: `${this.cdsLangService.translate('generate.md.registered-email-address')}(${this.secondAgent.shortEmail})`,
          value: 'E',
          disable: false,
        },
        {
          label: `${this.cdsLangService.translate('generate.md.registered-phone-number')} ${this.secondAgent.shortPhone}`,
          value: 'M',
          disable: false,
        },
      ],
    };
    this.radioSecondAgentClientContact?.refreshConfig();
  }

  private getAgentInfoByAgentCode(agentCode: string) {
    return new Promise<AgentInfo>((resolve, reject) => {
      this.cdHttpServeService.post<AgentInfo[]>(`/ext/eb-ssms/agent-service/agent/infos/agentCd`, [agentCode]).subscribe({
        next: res => {
          if (res.result === 0) {
            if (res.data && res.data.length) {
              resolve(res.data[0]);
            }
          } else {
            this.alert.error('Error!', res.message);
          }
          reject();
        },
        error: err => {
          this.alert.error('Error!', err);
          reject();
        },
      });
    });
  }

  private refreshRelevantRegulatedActivitiesConfig() {
    this.relevantRegulatedActivitiesConfig.options[0].label = this.cdsLangService.translate('common.yes');
    this.relevantRegulatedActivitiesConfig.options[1].label = this.cdsLangService.translate('common.no');
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setRelevantRegulatedActivitiesDisable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: true,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: true,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  private setRelevantRegulatedActivitiesEnable() {
    this.relevantRegulatedActivitiesConfig = {
      options: [
        {
          label: this.cdsLangService.translate('common.yes'),
          value: 'y',
          disable: false,
        },
        {
          label: this.cdsLangService.translate('common.no'),
          value: 'n',
          disable: false,
        },
      ],
    };
    this.radioRelevantRegulatedActivities?.refreshConfig();
  }

  isAllowPast() {
    if (this.relevantRegulatedActivitiesValue === '' || this.isDBschannel === '' || !this.customerSurname || !this.customerGivenName) {
      return false;
    }
    if (this.isDBschannel === 'Y' && (!this.rmReferralCode.valid || !this.campaignCode.valid || !this.inputCustomerId.valid || !this.sourceCode.valid)) {
      return false;
    }

    if (this.idType.value === '0' && !this.hkidOrPassportNo.valid) {
      return false;
    }

    if (this.clientContact === '') {
      return false;
    }
    if (this.clientContact === 'E' && !this.clientEmailAddress.valid) {
      return false;
    } else if (this.clientContact === 'M' && !this.clientPhoneNumber.valid) {
      return false;
    }

    return true;
  }

  get isDisableNext() {
    return this.individualsForm.invalid || this.clientContact === '' || this.isDBschannel === '' || this.relevantRegulatedActivitiesValue === '';
  }

  get isDisableIntermediaryNext() {
    if (this.secondAgentRelevantRegulatedActivities === '') {
      return true;
    }
    if (this.secondAgentRelevantRegulatedActivities === 'Y') {
      if (this.secondAgentClientContact === '') {
        return true;
      }
      if (this.secondAgentClientContact === 'M' && this.secondAgentLanguage === '') {
        return true;
      }
    }
    return false;
  }

  secondAgentClientContactChange() {
    this.secondAgentLanguage = '';
  }

  intermediaryNext() {
    if (this.isDisableIntermediaryNext) {
      return;
    }
    this.isConfirming = true;
    this.show = Show.individuals;
    this.setRelevantRegulatedActivitiesDisable();
    this.secondAgentRelevantRegulatedActivitiesConfig.options[0].disable = true;
    this.secondAgentRelevantRegulatedActivitiesConfig.options[1].disable = true;
    this.refreshSecondAgentRelevantRegulatedActivitiesConfig();
  }

  intermediaryReset() {
    this.secondAgentRelevantRegulatedActivities = '';
    this.secondAgentLanguage = '';
    this.secondAgentClientContact = '';
  }

  get getSecondAgentLanguage() {
    if (this.secondAgentLanguage === 'ZH') {
      return this.secondAgentLanguageConfig.options[0].label;
    } else if (this.secondAgentLanguage === 'EN') {
      return this.secondAgentLanguageConfig.options[1].label;
    }
    return undefined;
  }

  submit() {
    if (this.relevantRegulatedActivitiesValue === 'n') {
      this.cdsPopup.open(HasBeenSentCustomerPopupComponent, {
        size: 'md',
        data: {
          mdNo: 'MDasdlfsjldf4343',
        },
      });
    } else {
      this.cdsPopup.open(PendingSplitAgentConfirPopupComponent, {
        size: 'md',
        data: {
          mdNo: 'MDasdlfsjldf4343',
        },
      });
    }
  }
}
