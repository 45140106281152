// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageInfo from '../../package.json';

export const environment = {
  production: false,
  profile: 'dev',
  currentTimeout: 5,
  alertTimeout: 15,
  httpTimeout: 1000 * 60,
  mCFMode: true,
  pwdLogin: true,
  menuExclusion: [],
  internalUrl: '',
  externalUrl: '',
  ssoLogoutUrl: 'https://sea-emt-dev-api.ap.manulife.com/ext/nest-connect-gateway/ssologout?redirectURL=',
  mcfUrl:
    'https://sea-emt-dev-api.ap.manulife.com/ext/nest-connect-gateway/auth?client_id=13ef1fcb-5625-41b6-b364-6747f350405b&response_type=id_token%20token&redirect_uri=https%3A%2F%2Fmfcblobebsmssdeveas01.z7.web.core.windows.net%2Fuser%2Flogin&scope=openid%20profile%20mcf_token&nonce=some-nonce&state=some-state',
  languageList: [
    { value: 'en', label: 'English' },
    { value: 'zh', label: '繁體中文' },
  ],
  //apiPrefix: 'http://',
  apiPrefix: 'https://eas-hk-dev-api.ap.manulife.com',
  identityServiceExt: '/ext/eb-ssms/identity-service',
  //commissionServiceExt: 'localhost:8085',
  commissionServiceExt: '/ext/eb-ssms/commission-service-1',
  reportingServiceExt: '/ext/eb-ssms/reporting-service',
  accountingServiceExt: '/ext/eb-ssms/accounting-service',
  manutouchServiceInt: '/ext/eb-ssms/id-manutouch-service',
  notificationServiceExt: '/ext/eb-ssms/notification-service',
  paymentServiceExt: '/ext/eb-ssms/payment-service',
  chatbotWebsocket: 'wss://eas-hk-dev-api.ap.manulife.com',
  appVersion: packageInfo.version,
  organizationDay: '2002',
  clientId: '3ab9aa6e-d55b-4835-9a7b-42f4b709039b',
  mcfClientId: '13ef1fcb-5625-41b6-b364-6747f350405b',
  // clientId: 'bfca76e1-d711-4637-8e9e-a578547882f3',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
