<div *ngIf="show === Show.selectProductType" class="select-product-type">
  <div class="top">
    <span class="text">Generate MD Reference Number</span>
  </div>

  <div *ngIf="currentStep === 1" class="content">
    <div>
      <span class="cds-h3-light"> Select Product Type </span>
    </div>

    <div class="select-list l-mt-7">
      <div class="select-box" (click)="showDeclaration()">
        <cds-icon style="width: 80px; height: 80px; --cds-icon-color: var(--cds-bg-ml)" icon="people:login_1"></cds-icon>
        <div class="l-mt-5">
          <span class="cds-h2-light">{{ 'generate.md.individuals' | lang }}</span>
        </div>
      </div>
      <div class="select-box l-ml-6" (click)="showDeclaration()">
        <cds-icon style="width: 80px; height: 80px; --cds-icon-color: var(--cds-bg-ml)" icon="environment:office_building"></cds-icon>
        <div class="l-mt-5">
          <span class="cds-h2-light">Employer</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentStep === 2" class="l-mt-7 declaration-box">
    <div class="l-pl-7">
      <span class="cds-h4-demibold">{{ 'Declaration.header' | lang }}</span>
    </div>
    <div #displayContent class="declaration-content">
      <div class="l-mt-5 l-pl-7">
        <span class="cds-body1">
          {{ 'Declaration.headersub1' | lang }}
        </span>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.headersub3' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodydis' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodycommon' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodysuba' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodysubb' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubc' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubd' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodyadmin' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodycommon' | lang }}
          </span>
        </div>

        <div class="  ">
          <span class="cds-body1">
            {{ 'Declaration.bodysube' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubf' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodyother' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodycommon2' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubg' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodysubh' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodysubi' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodyimportant' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodynotice' | lang }}
          </span>
        </div>

        <div class="l-mt-5 l-mb-5">
          <span class="cds-body1-demibold">
            {{ 'Declaration.bodynoticesub1' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodynoticesuba' | lang }}
          </span>
        </div>

        <div class="">
          <span class="cds-body1">
            {{ 'Declaration.bodynoticesubb' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodynoticesubc' | lang }}
          </span>
        </div>

        <div class=" ">
          <span class="cds-body1">
            {{ 'Declaration.bodynoticesubd' | lang }}
          </span>
        </div>
      </div>
    </div>
    <div class="l-pl-7 l-mb-2 l-pt-5 declaration-agreement-btn">
      <cds-button
        label="{{ 'Declaration.noticeread' | lang }}"
        (click)="toIndividualsEdit()"
        [disabled]="!isScrollBottom"
        [size]="'md'"
        [style]="'primary'"></cds-button>
    </div>
  </div>
</div>

<div *ngIf="show === Show.individuals" class="individuals">
  <div class="head-box">
    <div class="back-box">
      <cds-icon [ngClass]="[isConfirming ? 'disabled' : '']" (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
      <div class="back-title" [ngClass]="[isConfirming ? 'disabled' : '']">
        <span (click)="onBack()" class="cds-body2-demibold">Exit and back to MD_IFF Summary Page</span>
      </div>
    </div>
    <span class="head">Generate MD Reference Number</span>
  </div>

  <form [formGroup]="individualsForm">
    <div class="content-box">
      <div class="l-d-f l-ai-cen">
        <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="people:login_1"></cds-icon>
        <div class="l-ml-4">
          <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">{{ 'generate.md.individuals' | lang }}</span>
        </div>
      </div>
      <div class="l-mt-7">
        <span class="cds-h2-demibold">強積金中介人聲明</span>
      </div>
      <div class="l-mt-4">
        <span class="cds-detail2">請填寫以下資料以完成聲明</span>
      </div>

      <div *ngIf="isConfirming" class="l-mt-6">
        <app-matters-needing-attention [color]="'red'" [title]="'generate.md.important-notes' | lang">
          <ul class="l-mt-0 l-mb-0">
            <li>
              <span class="cds-detail1">{{ 'generate.md.I-declare-that' | lang }}</span>
            </li>
            <li>
              <span class="cds-detail1 red-text">{{ 'generate.md.agents-should-no1' | lang }}</span>
              <span class="cds-detail1">{{ 'generate.md.agents-should-no2' | lang }}</span>
            </li>
          </ul>
        </app-matters-needing-attention>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">{{ 'generate.md.clientDetails' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">Product type</span><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming" style="max-width: 265px; min-width: 265px; line-height: 33px">
                <span class="cds-body1">{{ individualsForm.get('productType')?.value }}</span>
              </div>
              <div *ngIf="!isConfirming" style="max-width: 265px; min-width: 265px">
                <app-cd-dropdown
                  [placeholder]="'Select Product Type'"
                  [group]="individualsForm"
                  controlName="productType"
                  [toppingList]="prodTypeDropdownConfig.options"></app-cd-dropdown>
                <cds-assistive-text *ngIf="individualsForm.get('productType')?.dirty && individualsForm.get('productType')?.hasError('required')" class="l-mt-1"
                  >Please select the product type</cds-assistive-text
                >
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientName' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ customerSurname.value }} {{ customerGivenName.value }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets" style="flex: 1">
                <div class="l-d-f">
                  <div style="max-width: 180px; min-width: 180px">
                    <app-cd-input
                      [group]="individualsForm"
                      [controlName]="'customerSurname'"
                      [regExpLimit]="'^[a-zA-Z]*$'"
                      placeholder="{{ 'generate.md.surname' | lang }}"></app-cd-input>
                  </div>
                  <div class="l-ml-6" style="max-width: 400px; min-width: 400px">
                    <app-cd-input
                      [group]="individualsForm"
                      [controlName]="'customerGivenName'"
                      [regExpLimit]="'^[a-zA-Z]*$'"
                      placeholder="{{ 'generate.md.givenName' | lang }}"></app-cd-input>
                  </div>
                </div>
                <div
                  *ngIf="
                    (customerSurname?.dirty && customerSurname?.hasError('required')) || (customerGivenName?.dirty && customerGivenName?.hasError('required'))
                  "
                  class="l-mt-1">
                  <cds-assistive-text>{{ 'generate.md.fullNameError' | lang }}</cds-assistive-text>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientHKIDorPass' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming" class="l-d-f" style="flex: 1">
                <span class="cds-body1 l-mb-0"
                  >({{ individualsForm.get('idType')?.value === '0' ? 'HKID' : 'Passport No.' }}) {{ individualsForm.get('hkidOrPassportNo')?.value }}</span
                >
              </div>
              <div *ngIf="!isConfirming" class="l-d-f" style="flex: 1">
                <div class="offsets" style="max-width: 180px; min-width: 180px">
                  <app-cd-dropdown
                    [placeholder]="'iff.pleaseSelectIDType'"
                    [group]="individualsForm"
                    [controlName]="'idType'"
                    (selectedChange)="hKIDPassportNoChange()"
                    [toppingList]="idTypeConfig.options"></app-cd-dropdown>
                </div>
                <div class="l-ml-6 offsets" style="max-width: 400px; min-width: 400px">
                  <app-cd-input
                    [group]="individualsForm"
                    controlName="hkidOrPassportNo"
                    [placeholder]="'iff.pleaseInputHKIDPassportNo' | lang"
                    (valueChange)="hkidOrPassportNoChange($event)"></app-cd-input>
                  <div class="l-mt-3" *ngIf="hkidOrPassportNo?.dirty && hkidOrPassportNo?.hasError('required')">
                    <cds-assistive-text type="error">
                      <span>
                        {{ 'generate.md.pleaseInputHkid' | lang }}
                      </span>
                    </cds-assistive-text>
                  </div>
                  <div class="l-mt-3" *ngIf="hkidOrPassportNo?.dirty && hkidOrPassportNo?.hasError('formatError')">
                    <cds-assistive-text type="error">
                      <span>
                        {{ 'generate.md.hkidInvalid' | lang }}
                      </span>
                    </cds-assistive-text>
                  </div>
                </div>
              </div>
            </div>

            <div class="item-box">
              <div style="width: 270px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientEmailorPhone' | lang }}</span
                ><span class="cds-body2-demibold" style="color: var(--cds-color-dark-1-coral)">*</span><span class="cds-body2-demibold">:</span>
              </div>
              <div *ngIf="isConfirming">
                <span *ngIf="clientContact === 'E'" class="cds-body1">{{ individualsForm.get('clientEmailAddress')?.value }}</span>
                <span *ngIf="clientContact === 'M'" class="cds-body1"
                  >(+{{ individualsForm.get('clientCountryCode')?.value }}) {{ individualsForm.get('clientPhoneNumber')?.value }}</span
                >
              </div>
              <div *ngIf="!isConfirming">
                <div>
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    [(value)]="clientContact"
                    [config]="clientContactConfig"
                    (valueChange)="clientContactChange()"></app-cd-radio>
                  <div>
                    <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !clientContact">{{
                      'generate.md.pleaseSelCustomerContactType' | lang
                    }}</cds-assistive-text>
                  </div>
                </div>
                <div>
                  <div *ngIf="clientContact === 'E'" class="l-mt-5 l-mb-3">
                    <cds-textfield ngDefaultControl [placeholder]="'common.email-address' | lang" formControlName="clientEmailAddress"></cds-textfield>
                    <cds-assistive-text class="l-mt-4" *ngIf="clientEmailAddress?.dirty && clientEmailAddress?.hasError('email')">{{
                      'generate.md.pleaseInputValidCustomerEmail' | lang
                    }}</cds-assistive-text>
                    <cds-assistive-text class="l-mt-4" *ngIf="clientEmailAddress?.dirty && clientEmailAddress?.hasError('required')">{{
                      'generate.md.pleaseInputCustomerEmail' | lang
                    }}</cds-assistive-text>
                  </div>
                  <div *ngIf="clientContact === 'M'" class="l-d-f l-mt-5">
                    <cds-dropdown
                      formControlName="clientCountryCode"
                      [config]="countrycodeConfig"
                      (ngModelChange)="clientCountryCodeChange()"
                      ngDefaultControl></cds-dropdown>
                    <div>
                      <div class="l-ml-4" style="position: relative; top: -1px; width: 230px">
                        <app-cd-input
                          [type]="'number'"
                          ngDefaultControl
                          placeholder="{{ 'generate.md.pleaseInputCustomerMobileNumber' | lang }}"
                          [group]="individualsForm"
                          controlName="clientPhoneNumber"></app-cd-input>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text *ngIf="clientPhoneNumber?.dirty && clientPhoneNumber.hasError('errorPhoneNumber')">
                          <span>
                            {{ 'generate.md.pleaseInputValidMobileNumber' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                      <div class="l-ml-4 l-mt-1">
                        <cds-assistive-text *ngIf="clientPhoneNumber?.dirty && clientPhoneNumber?.hasError('required')">
                          <span>
                            {{ 'generate.md.pleaseInputCustomerMobileNumber' | lang }}
                          </span>
                        </cds-assistive-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-box" *ngIf="!(isConfirming && !individualsForm.get('preferredLanguage')?.value)">
              <div *ngIf="clientContact === 'M'" style="width: 270px">
                <span class="cds-body2-demibold">{{ 'generate.md.selectLanguage' | lang }}:</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ (preferredLanguage.value === PreferredLanguageType.English ? 'common.english' : 'common.chinese') | lang }}</span>
              </div>
              <div *ngIf="!isConfirming && clientContact === 'M'" style="flex: 1">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #cdRadioPreferredLanguage
                  (valueChange)="individualsForm.get('preferredLanguage')?.setValue($event)"
                  [value]="individualsForm.get('preferredLanguage')?.value"
                  [config]="preferredLanguageConfig"></app-cd-radio>
                <div
                  *ngIf="individualsForm.get('preferredLanguage')?.invalid && individualsForm.get('preferredLanguage')?.touched"
                  style="margin-top: var(--cds-spacing-01)">
                  <cds-assistive-text type="error">{{ 'generate.md.please-select-language' | lang }} </cds-assistive-text>
                </div>
              </div>
            </div>
            <div *ngIf="!isConfirming" class="item-box">
              <div>
                <span class="cds-detail2" style="color: var(--cds-color-dark-1-coral)">{{ 'generate.md.pleaseEnsure' | lang }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <span class="cds-body2-demibold" style="margin-bottom: 0">{{ 'generate.md.MPFIntermediary' | lang }}</span>
            </div>
            <div class="item-box">
              <app-cd-radio
                #radioRelevantRegulatedActivities
                class="cd-radio-margin-bottom-clear"
                [(value)]="relevantRegulatedActivitiesValue"
                [config]="relevantRegulatedActivitiesConfig"></app-cd-radio>
            </div>
            <div class="l-mt-1">
              <cds-assistive-text *ngIf="isTryConfirm && !relevantRegulatedActivitiesValue">{{
                'generate.md.pleaseSelectRelevantRegulatedActivities' | lang
              }}</cds-assistive-text>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isConfirming" class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">{{ 'generate.md.2ndMPFIntermediary' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body1-demibold">DBS</span>
                <span class="cds-body1-demibold" style="color: var(--cds-color-dark-1-coral)">*</span>
                <span class="cds-body1-demibold">:</span>
              </div>
              <div>
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #dbsReferredLanguage
                  [(value)]="isDBschannel"
                  [config]="dbsContactConfig"
                  (valueChange)="dbsContractChange($event)">
                </app-cd-radio>
                <div>
                  <cds-assistive-text class="l-mt-4" *ngIf="isTryConfirm && !isDBschannel">Please select DBS</cds-assistive-text>
                </div>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold" style="margin-bottom: 0">{{ 'generate.md.addMPFIntermediary' | lang }}：</span>
              </div>
              <div *ngIf="isConfirming" style="flex: 1">
                <span class="cds-body1">{{ secondAgentCode }}</span>
              </div>
              <div *ngIf="!isConfirming" class="offsets" style="flex: 1">
                <div class="l-d-f">
                  <div style="max-width: 560px; min-width: 560px">
                    <app-cd-input [(value)]="secondAgentCode" placeholder="輸入強積金中介人編號（選填）"></app-cd-input>
                  </div>
                </div>
                <div *ngIf="isCustomerError" class="l-mt-1">
                  <cds-assistive-text>Please input the customer’s full name</cds-assistive-text>
                </div>
              </div>
            </div>
          </div>

          <div class="information-box" *ngIf="isDBschannel === 'Y'">
            <div class="cds-body-hero-light">dbs</div>
            <div class="item-list-box" style="border: 0px">
              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">RM Referral Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ individualsForm.get('rmReferralCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [type]="'number'"
                    [group]="individualsForm"
                    controlName="rmReferralCode"
                    [maxlength]="8"
                    ngDefaultControl
                    [placeholder]="'Input RM Referral Code'"></app-cd-input>

                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="individualsForm.get('rmReferralCode')?.dirty && individualsForm.get('rmReferralCode')?.hasError('required'); else second"
                      type="error"
                      >Please input valid RM Referral Code
                    </cds-assistive-text>
                    <ng-template #second>
                      <cds-assistive-text
                        *ngIf="individualsForm.get('rmReferralCode')?.dirty && individualsForm.get('rmReferralCode')?.hasError('lengthError')"
                        type="error"
                        >Please input valid RM Referral Code
                      </cds-assistive-text>
                    </ng-template>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Customer ID:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ individualsForm.get('inputCustomerId')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="individualsForm"
                    controlName="inputCustomerId"
                    [maxlength]="8"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Customer ID (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="individualsForm.get('inputCustomerId')?.dirty && individualsForm.get('inputCustomerId')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Customer ID
                    </cds-assistive-text>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Campaign Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ individualsForm.get('campaignCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="individualsForm"
                    controlName="campaignCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Campaign Code (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="individualsForm.get('campaignCode')?.dirty && individualsForm.get('campaignCode')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Campaign Code
                    </cds-assistive-text>
                  </div>
                </div>
              </div>

              <div class="item-box l-mt-0">
                <div style="width: 270px" class="l-mt-5">
                  <span class="cds-body1-demibold">Source Code:</span>
                </div>
                <div *ngIf="isConfirming" class="l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <span class="cds-body1">{{ individualsForm.get('sourceCode')?.value }} </span>
                </div>
                <div *ngIf="!isConfirming" class="offsets l-mt-5" style="max-width: 560px; min-width: 265px; width: 560px">
                  <app-cd-input
                    [group]="individualsForm"
                    controlName="sourceCode"
                    [maxlength]="10"
                    [regExpLimit]="'^[A-Za-z0-9-]*$'"
                    ngDefaultControl
                    [placeholder]="'Input Source Code (Optional)'"></app-cd-input>
                  <div class="l-mt-1">
                    <cds-assistive-text
                      *ngIf="individualsForm.get('sourceCode')?.dirty && individualsForm.get('sourceCode')?.hasError('lengthError')"
                      type="error"
                      >Please input valid Source Code
                    </cds-assistive-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isConfirming && secondAgent" class="information-list-box">
        <div class="information-box">
          <div>
            <span class="cds-h4">{{ 'generate.md.2nd-MPF-intermediary-details' | lang }}</span>
          </div>
          <div class="item-list-box">
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.agent-code' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ secondAgent.agentCode }}</span>
              </div>
            </div>
            <div class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.agent-name' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ getSecondAgentName }}</span>
              </div>
            </div>
            <div *ngIf="secondAgentRelevantRegulatedActivities === 'Y'" class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.clientEmailorPhone' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ secondAgentClientContact === 'E' ? secondAgent.shortEmail : secondAgent.shortPhone }}</span>
              </div>
            </div>
            <div *ngIf="secondAgentRelevantRegulatedActivities === 'Y' && getSecondAgentLanguage" class="item-box">
              <div style="width: 270px; line-height: 33px">
                <span class="cds-body2-demibold">{{ 'generate.md.selectLanguage' | lang }}</span
                ><span class="cds-body2-demibold">:</span>
              </div>
              <div style="flex: 1">
                <span class="cds-body2-demibold">{{ getSecondAgentLanguage }}</span>
              </div>
            </div>
            <div class="second-agent-relevant-regulated-activities-box l-mt-5">
              <div>
                <span class="cds-detail2">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
              </div>
              <div class="l-mt-4">{{ 'generate.md.mpf-intermediary' | lang }}</div>
              <div class="l-mt-5">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #radioSecondAgentRelevantRegulatedActivities
                  [(value)]="secondAgentRelevantRegulatedActivities"
                  [config]="secondAgentRelevantRegulatedActivitiesConfig"></app-cd-radio>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button (click)="reset()" [style]="'secondary'">{{ 'common.reset1' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button [disabled]="isDisableNext" (click)="next()">{{ 'common.next' | lang }}</cds-button>
        </div>
      </div>

      <div *ngIf="isConfirming" class="l-mt-6 l-d-f">
        <div>
          <cds-button (click)="backToEdit()" [style]="'secondary'">{{ 'common.backToEdit' | lang }}</cds-button>
        </div>
        <div class="l-ml-4">
          <cds-button (click)="submit()">{{ 'common.confirmAndSubmit' | lang }}</cds-button>
          <div class="l-mt-3">
            <cds-assistive-text>{{ 'generate.md.no-changes-submitted' | lang }}</cds-assistive-text>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="show === Show.intermediary" class="individuals">
  <div class="head-box">
    <div class="back-box">
      <cds-icon [ngClass]="[isConfirming ? 'disabled' : '']" (click)="onBack()" [config]="backIconConfig" [icon]="backIcon"> </cds-icon>
      <div class="back-title" [ngClass]="[isConfirming ? 'disabled' : '']">
        <span (click)="onBack()" class="cds-body2-demibold">Exit and back to MD_IFF Summary Page</span>
      </div>
    </div>
    <span class="head">Generate MD Reference Number</span>
  </div>

  <div class="content-box">
    <div class="l-d-f l-ai-cen">
      <cds-icon style="width: 40px; height: 40px; --cds-icon-color: var(--cds-bg-ml)" icon="people:login_1"></cds-icon>
      <div class="l-ml-4">
        <span class="cds-h2-demibold" style="color: var(--cds-bg-ml)">{{ 'generate.md.individuals' | lang }}</span>
      </div>
    </div>
    <div class="l-mt-7">
      <span class="cds-h2-demibold">強積金中介人聲明（第二強積金中介人）</span>
    </div>
    <div class="l-mt-4">
      <span class="cds-detail2">{{ 'generate.md.addMPFIntermediary' | lang }}</span>
    </div>
    <div class="information-list-box">
      <div class="information-box">
        <div>
          <span class="cds-h4">{{ 'generate.md.2nd-MPF-intermediary-details' | lang }}</span>
        </div>
        <div class="item-list-box">
          <div class="item-box">
            <div style="width: 270px; line-height: 33px">
              <span class="cds-body2-demibold">{{ 'generate.md.agent-code' | lang }}</span
              ><span class="cds-body2-demibold">:</span>
            </div>
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ secondAgent?.agentCode }}</span>
            </div>
          </div>
          <div class="item-box">
            <div style="width: 270px; line-height: 33px">
              <span class="cds-body2-demibold">{{ 'generate.md.agent-name' | lang }}</span
              ><span class="cds-body2-demibold">:</span>
            </div>
            <div style="flex: 1">
              <span class="cds-body2-demibold">{{ getSecondAgentName }}</span>
            </div>
          </div>
          <div class="second-agent-relevant-regulated-activities-box l-mt-5">
            <div>
              <span class="cds-detail2">{{ 'generate.md.relevantRegulatedActivities' | lang }}</span>
            </div>
            <div class="l-mt-4">{{ 'generate.md.mpf-intermediary' | lang }}</div>
            <div class="l-mt-5">
              <app-cd-radio
                class="cd-radio-margin-bottom-clear"
                #radioSecondAgentRelevantRegulatedActivities
                [(value)]="secondAgentRelevantRegulatedActivities"
                [config]="secondAgentRelevantRegulatedActivitiesConfig"></app-cd-radio>
            </div>
            <ng-container *ngIf="secondAgentRelevantRegulatedActivities === 'Y'">
              <div class="l-mt-7">
                <app-matters-needing-attention [title]="'generate.md.important-notes' | lang">
                  <div class="cds-detail2 l-d-f l-fd-col">
                    <span class="cds-detail2 l-mt-1">
                      {{ 'generate.md.mpf-declaration-will' | lang }}
                    </span>
                  </div>
                </app-matters-needing-attention>
              </div>
              <div class="l-mt-7">
                <span class="cds-body2-demibold" style="color: #000000">{{ 'generate.md.choose-the-following-registered' | lang }}</span>
              </div>
              <div *ngIf="secondAgentClientContactConfig" class="l-mt-4">
                <app-cd-radio
                  class="cd-radio-margin-bottom-clear"
                  #radioSecondAgentClientContact
                  [(value)]="secondAgentClientContact"
                  (valueChange)="secondAgentClientContactChange()"
                  [config]="secondAgentClientContactConfig"></app-cd-radio>
              </div>
              <div *ngIf="secondAgentClientContact === 'M'" class="l-mt-5 l-d-f">
                <div>
                  <span>{{ 'generate.md.selectLanguage' | lang }}</span>
                </div>
                <div style="margin-left: 100px">
                  <app-cd-radio
                    class="cd-radio-margin-bottom-clear"
                    #radioSecondAgentLanguage
                    [(value)]="secondAgentLanguage"
                    [config]="secondAgentLanguageConfig"></app-cd-radio>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="l-mt-6 l-d-f">
      <div>
        <cds-button (click)="intermediaryReset()" [style]="'secondary'">{{ 'common.reset1' | lang }}</cds-button>
      </div>
      <div class="l-ml-4">
        <cds-button [disabled]="isDisableIntermediaryNext" (click)="intermediaryNext()">{{ 'common.next' | lang }}</cds-button>
      </div>
    </div>
  </div>
</div>
