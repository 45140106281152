export enum PermissionAccess {
  R = 'R',
  W = 'W',
  E = 'E',
}

export enum PermissionItem {
  SYS_ROLE_MANAGE = 'SYS_ROLE_MANAGE',
  SYS_IDENTITY_AGENT_MANAGE = 'SYS_IDENTITY_AGENT_MANAGE',
  SYS_IDENTITY_STAFF_MANAGE = 'SYS_IDENTITY_STAFF_MANAGE',
  SYS_CALENDAR = 'SYS_CALENDAR',
  SYS_CALENDAR_REVIEWER = 'SYS_CALENDAR_REVIEWER',
  SYS_CALENDAR_APPROVE = 'SYS_CALENDAR_APPROVE',
  SYS_COMMISSION_DEFAULT_SCALE = 'SYS_COMMISSION_DEFAULT_SCALE',
  SYS_AUDIT_LOG = 'SYS_AUDIT_LOG',
  CUSTOMER_EMPLOYER = 'CUSTOMER_EMPLOYER',
  CUSTOMER_EMPLOYER_SQ = 'CUSTOMER_EMPLOYER_SQ',
  CUSTOMER_EMPLOYER_SUBSIDIARY = 'CUSTOMER_EMPLOYER_SUBSIDIARY',
  CUSTOMER_MEMBER = 'CUSTOMER_MEMBER',
  CUSTOMER_MOBILE_SUBSCRIPTION = 'CUSTOMER_MOBILE_SUBSCRIPTION',
  CUSTOMER_SERVICING_AGENT = 'CUSTOMER_SERVICING_AGENT',
  CUSTOMER_EMPLOYER_INTERMEDIARY = 'CUSTOMER_EMPLOYER_INTERMEDIARY',
  COMMISSION_SCHEME_MASTER_RECORD = 'COMMISSION_SCHEME_MASTER_RECORD',
  COMMISSION_SCALE_MANAGEMENT = 'COMMISSION_SCALE_MANAGEMENT',
  COMM_INFO_MAIN_COMM_SUMMARY = 'COMM_INFO_MAIN_COMM_SUMMARY',
  COMM_INFO_MAIN_PARTIES_CONT_ADJ = 'COMM_INFO_MAIN_PARTIES_CONT_ADJ',
  COMM_INFO_MAIN_PARTIES_OTHERS = 'COMM_INFO_MAIN_PARTIES_OTHERS',
  COMM_INFO_SCALE_RECORD = 'COMM_INFO_SCALE_RECORD',
  COMM_INFO_AGENT_COMP = 'COMM_INFO_AGENT_COMP',
  COMM_INFO_AGENT_CHANGE = 'COMM_INFO_AGENT_CHANGE',
  COMM_INFO_INDIVIDUAL_TRF = 'COMM_INFO_INDIVIDUAL_TRF',
  COMMISSION_JOURNAL = 'COMMISSION_JOURNAL',
  COMM_HOLD_ON_COMPENSATION_REPORT = 'COMM_HOLD_ON_COMPENSATION_REPORT',
  COMMISSION_ERROR_LOG = 'COMMISSION_ERROR_LOG',

  CUSTOMER_EMPLOYEE = 'CUSTOMER_EMPLOYEE',
  CUSTOMER_EMPLOYEE_INTERMEDIARY = 'CUSTOMER_EMPLOYEE_INTERMEDIARY',

  SHK_AGENT_ASSIGNMENT_AGENT = 'SHK_AGENT_ASSIGNMENT_AGENT',
  CUSTOMER_EMPLOYER_ADDITIONAL_INFORMATION = 'CUSTOMER_EMPLOYER_ADDITIONAL_INFORMATION',
  CUSTOMER_EMPLOYER_ACTIVITY_CODE = 'CUSTOMER_EMPLOYER_ACTIVITY_CODE',

  SALES_JOURNEY_SALES_SUPPORT = 'SALES_JOURNEY_SALES_SUPPORT',
  SALES_JOURNEY_AGENT = 'SALES_JOURNEY_AGENT',
  SALES_JOURNEY_SUPER_USER = 'SALES_JOURNEY_SUPER_USER',
  SALES_JOURNEY_SUPER_ADMIN = 'SALES_JOURNEY_SUPER_ADMIN',
  SALES_JOURNEY_BRANCH_LEVEL = 'SALES_JOURNEY_BRANCH_LEVEL',

  SHK_AGENT_ASSIGNMENT_MAPPING = 'SHK_AGENT_ASSIGNMENT_MAPPING',
  SHK_AGENT_ASSIGNMENT_REPORT = 'SHK_AGENT_ASSIGNMENT_REPORT',
  SHK_AGENT_ASSIGNMENT_REPORT_AGENT_HEAD = 'SHK_AGENT_ASSIGNMENT_REPORT_AGENT_HEAD',
  SHK_AUTO_ROLLOVER_OPS_STAFF = 'SHK_AUTO_ROLLOVER_OPS_STAFF',
  SHK_AGENT_ASSIGNMENT_REASSIGN = 'SHK_AGENT_ASSIGNMENT_REASSIGN',
  SHK_PAYMENT_ISSUANCE_PAYMENT_ADMIN = 'SHK_PAYMENT_ISSUANCE_PAYMENT_ADMIN',
  SHK_PAYMENT_ISSUANCE_PAYMENT_STAFF = 'SHK_PAYMENT_ISSUANCE_PAYMENT_STAFF',
  SHK_PAYMENT_ISSUANCE_PAYMENT_MANAGER = 'SHK_PAYMENT_ISSUANCE_PAYMENT_MANAGER',

  COMM_ADJ_NORMAL = 'COMM_ADJ_NORMAL',
  COMM_ADJ_INDIVIDUAL = 'COMM_ADJ_INDIVIDUAL',
  COMM_ADJ_APPEAL = 'COMM_ADJ_APPEAL',

  CHATBOT_CSO = 'CHATBOT_CSO',
  CHATBOT_ADMIN = 'CHATBOT_ADMIN',
  CHATBOT_SUPERVISOR = 'CHATBOT_SUPERVISOR',
  CHATBOT_AGENT = 'CHATBOT_AGENT',

  PAYMENT_DETIAL_ENQUIRY_EXPORT = 'PAYMENT_DETIAL_ENQUIRY_EXPORT',
}
export enum ReportingPermissionItem {
  MPF_GENERAL_REPORTS = 'MPF_GENERAL_REPORTS',
  MPF_STAFF_REPORTS = 'MPF_STAFF_REPORTS',
  MPF_MIS_REPORTS = 'MPF_MIS_REPORTS',
  MPF_MLFUB_REPORTS = 'MPF_MLFUB_REPORTS',

  ORSO_GENERAL_REPORTS = 'ORSO_GENERAL_REPORTS',
  ORSO_STAFF_REPORTS = 'ORSO_STAFF_REPORTS',
  ORSO_MIS_REPORTS = 'ORSO_MIS_REPORTS',
  ORSO_MLFUB_REPORTS = 'ORSO_MLFUB_REPORTS',

  MACAU_GENERAL_REPORTS = 'MACAU_GENERAL_REPORTS',
  MACAU_STAFF_REPORTS = 'MACAU_STAFF_REPORTS',
  MACAU_MIS_REPORTS = 'MACAU_MIS_REPORTS',
  MACAU_MLFUB_REPORTS = 'MACAU_MLFUB_REPORTS',

  SHK_GENERAL_REPORTS = 'SHK_GENERAL_REPORTS',
  SHK_STAFF_REPORTS = 'SHK_STAFF_REPORTS',
  SHK_MIS_REPORTS = 'SHK_MIS_REPORTS',
  SHK_MLFUB_REPORTS = 'SHK_MLFUB_REPORTS',
}
