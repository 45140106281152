import { Injectable } from '@angular/core';
import { MD_STATUS } from 'src/app/config/iff-status.config';

@Injectable({
  providedIn: 'root',
})
export class IffStatusService {
  constructor() {}

  getIFFStatusInfo(val: number) {
    return MD_STATUS.find(item => item.value === val);
  }
}
