<div class="row">
  <div class="col-xs-12 l-pt-6 l-pb-6 cds-color-green anchor-parent">
    <div class="anchor-element" id="employerPayrollGroup"></div>
    <span class="cds-h4-demibold">{{ 'er.employer-payroll-group' | lang }}</span>
  </div>
</div>

<div *ngIf="_loadding" class="col-xs-12 l-pa-7 center-xs">
  <app-loading></app-loading>
</div>

<app-cd-accordion *ngIf="!_loadding">
  <app-cd-accordion-panel
    *ngFor="let item of data"
    [title]="item.payrollGroupName"
    titleClass="cds-h5-bold"
    titleTip="Billing Class Name"
    [subTitle]="formatNum(item.payrollGroupId)"
    subTitleTip="Billing Class No.">
    <div class="col-xs-12 l-plr-0">
      <div class="row">
        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
          <div class="cds-color-navy-light3">
            <span class="cds-body2">{{ 'er.profile.01.lives' | lang }}</span>
          </div>
          <div class="l-pt-1">
            <span class="cds-body1">{{ item.lives }}</span>
          </div>
        </div>
        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
          <div class="cds-color-navy-light3">
            <span class="cds-body2">{{ 'payment.contribution-frequency' | lang }}</span>
          </div>
          <div class="l-pt-1">
            <span class="cds-body1">{{ contributionFrequencyRender | fn : item.contributionFrequency }}</span>
          </div>
        </div>
        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
          <div class="cds-color-navy-light3">
            <span class="cds-body2">{{ 'er.profile.effective-date' | lang }}</span>
          </div>
          <div class="l-pt-1">
            <span class="cds-body1">{{ item.effDate | ssmsDate }}<span *ngIf="item.effDate && item.termDate">-</span>{{ item.termDate | ssmsDate }}</span>
          </div>
        </div>
        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2" *ngIf="isStaff">
          <div class="cds-color-navy-light3">
            <span class="cds-body2">{{ 'er.profile.01.mcn' | lang }}</span>
          </div>
          <div class="l-pt-1">
            <span class="cds-body1">{{ item.mcn }}</span>
          </div>
        </div>
        <!-- <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
          <div class="cds-color-navy-light3">
            <span class="cds-body2">{{ 'er.profile.01.stmt-hit' | lang }}</span>
          </div>
          <div class="l-pt-1">
            <span class="cds-body1">{{ item.stmtHit | ssmsDate }}</span>
          </div>
        </div>
        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
          <div class="cds-color-navy-light3">
            <span class="cds-body2">{{ 'er.profile.01.bill-hit' | lang }}</span>
          </div>
          <div class="l-pt-1">
            <span class="cds-body1">{{ item.billHit | ssmsDate }}</span>
          </div>
        </div>
        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 l-pt-2 l-pb-2">
          <div class="cds-color-navy-light3">
            <span class="cds-body2">{{ 'er.profile.01.cont-sig' | lang }}</span>
          </div>
          <div class="l-pt-1">
            <span class="cds-body1">{{ item.contSig === 'BILLED' ? 'Billed' : 'Specified' }}</span>
          </div>
        </div> -->
        <div (click)="toDetail(item)" class="row col-xs-12 middle-xs l-pt-4 l-pb-4 pointer">
          <div class="l-mr-2">
            <cds-icon class="button-x-filled" color="#EC6453" icon="action:button_x_filled"></cds-icon>
          </div>
          <div>
            <span class="cds-body2-demibold">{{ 'er.profile.view-more' | lang }}</span>
          </div>
        </div>
      </div>
    </div>
  </app-cd-accordion-panel>
</app-cd-accordion>
