/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormArray } from '@angular/forms';

import { commissionDateValiate, commissionDateGroupValiate } from '../calendar.validators';
import { MONTH_OPTS } from 'src/app/config/month.config';

@Component({
  selector: 'app-update-commission-event',
  templateUrl: './update-commission-event.component.html',
  styleUrls: ['./update-commission-event.component.scss'],
})
export class UpdateCommissionEventComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  // @Input() hasNextYearCommissionEvent: boolean = true;
  @Input()
  set commissionEventInitValue(value: number[][]) {
    this._commissionEventInitValue = value;
    this.initFormArray();
  }
  get commissionEventInitValue() {
    return this._commissionEventInitValue;
  }

  private _commissionEventInitValue: number[][] = [];
  monthConfig = MONTH_OPTS;

  formGroup = this.fb.group({
    formArray: this.fb.array([]),
  });

  constructor(private fb: FormBuilder) {
    this.monthConfig.forEach(item => {
      this.formArray.push(
        this.fb.group(
          {
            eventDateOne: new FormControl('', commissionDateValiate(item.days)),
            eventDateTwo: new FormControl('', commissionDateValiate(item.days)),
          },
          {
            validators: commissionDateGroupValiate,
          }
        )
      );
    });
  }

  ngOnInit() {
    this.formArray.valueChanges.subscribe(value => {
      this.valueChange.emit({
        value: value.reduce((prev: string[], item: number[], index: number) => {
          const month = this.monthConfig[index].month;
          const valid = this.formArray.controls[index].valid;
          const temp: string[] = [];
          if (valid) {
            Object.values(item).forEach(str => {
              temp.push(`${month}-${this.transformDay(str.toString())}`);
            });
          }
          return temp.concat(prev);
        }, []),
        invalid: this.formArray.invalid,
      });
    });
  }

  initFormArray() {
    this._commissionEventInitValue.forEach((item, index) => {
      if (item && Array.isArray(item)) {
        const temp = item.sort((a, b) => a - b);
        const control = this.formArray.get(index.toString());
        if (control) {
          control.setValue({
            eventDateOne: temp[0] ? temp[0].toString() : '',
            eventDateTwo: temp[1] ? temp[1].toString() : '',
          });
        }
      }
    });
  }

  transformDay(str: string) {
    if (str.length > 1) {
      return str;
    }
    return `0${str}`;
  }

  get formArray() {
    return this.formGroup.controls['formArray'] as FormArray;
  }
}
