export enum EMPF_App_Status_Value {
  eMPF_Processing = 10,
  eMPF_Follow_up = 20,
  eMPF_Application_cancelled = 50,
  eMPF_Completed = 30,
  eMPF_Rejected = 40,
  Pending_eMPF_Data = 0,
  Partially_Completed = 31,
  Not_Applicable = 99,
}

export const EMPF_APP_STATUS = [
  {
    text: 'eMPF Processing',
    info: 'The record is currently under progress in eMPF',
    color: 'var(--cds-color-turquoise)',
    value: EMPF_App_Status_Value.eMPF_Processing,
  },
  {
    text: 'eMPF Follow-up',
    info: 'The record is currently under follow-up in eMPF',
    color: 'var(--cds-color-jh-blue)',
    value: EMPF_App_Status_Value.eMPF_Follow_up,
  },
  {
    text: 'eMPF Application cancelled',
    info: 'The record is canclled in eMPF',
    color: 'var(--cds-color-dark-2-coral)',
    value: EMPF_App_Status_Value.eMPF_Application_cancelled,
  },
  {
    text: 'eMPF Completed',
    info: 'The record and onboarding are completed in eMPF',
    color: 'var(--cds-color-dark-1-green)',
    value: EMPF_App_Status_Value.eMPF_Completed,
  },
  {
    text: 'eMPF Rejected',
    info: 'The record is being rejected in eMPF',
    color: 'var(--cds-color-light-4-dark-navy)',
    value: EMPF_App_Status_Value.eMPF_Rejected,
  },
  {
    text: 'Pending eMPF data',
    info: 'The record is pending for eMPF data feed',
    color: 'var(--cds-color-gold)',
    value: EMPF_App_Status_Value.Pending_eMPF_Data,
  },
  {
    text: 'Partially Completed',
    info: 'The record and onboarding are partially completed in eMPF',
    color: 'var(--cds-color-coral)',
    value: EMPF_App_Status_Value.Partially_Completed,
  },
  {
    text: 'Not Applicable',
    info: undefined,
    color: 'var(--cds-color-violet)',
    value: EMPF_App_Status_Value.Not_Applicable,
  },
];
