import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommBatchRunningComponent } from './comm-batch-running.component';
import { CdsLanguageModule } from '@cds/ng-core/lang';
import { CdsIconModule } from '@cds/ng-web-components/icon';

@NgModule({
  imports: [CommonModule, CdsLanguageModule, CdsIconModule],
  declarations: [CommBatchRunningComponent],
  exports: [CommBatchRunningComponent],
})
export class CommBatchRunningModule {}
