import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';
import { I18N_KEY } from './calendar.config';

// Report No. must be number
export function ReportNoValidator(key: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const reg = /(^-?[1-9]([0-9]*)$|^-?[0-9]$)/;
    if (!control.value || reg.test(control.value)) {
      return null;
    }
    return { error: key };
  };
}

// from date must < to date
export function dateFormRangeValidator(key: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const fromDate = control.get('fromDate');
    const toDate = control.get('toDate');
    if (fromDate && toDate && fromDate.value && toDate.value) {
      if (moment(fromDate.value, 'DD/MM/YYYY') > moment(toDate.value, 'DD/MM/YYYY')) {
        return { error: key };
      }
    }
    return null;
  };
}

//commission event
export function commissionDateValiate(days: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === '') {
      return { error: I18N_KEY.DATE_REQUIRED };
    }
    const reg = /(^-?[1-9]([0-9]*)$|^-?[0-9]$)/;
    if (!reg.test(value)) {
      return { error: I18N_KEY.INVALID_COMMISSION_DATE_FORMAT };
    }
    if (value < 1 || value > days) {
      return { error: I18N_KEY.INVALID_COMMISSION_DATE_ERROR };
    }
    return null;
  };
}

//commission event of every month
export function commissionDateGroupValiate(control: AbstractControl): ValidationErrors | null {
  const eventDateOne = control.get('eventDateOne')?.value;
  const eventDateTwo = control.get('eventDateTwo')?.value;
  if (eventDateOne && eventDateTwo && eventDateOne === eventDateTwo) {
    return { error: I18N_KEY.INVALID_COMMISSION_DATE_EQUEAL };
  }
  return null;
}
