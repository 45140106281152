/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';

import { CdPopupService, CdPopupSize, MatDialogRef, CDS_POPUP_DATA, CdPopupType } from 'src/app/shared/cd-popup';

import { CalendarService } from 'src/app/views/system/calendar/shared/calendar.service';

import { I18N_KEY, I18N_KEY_FOR_API_RES } from '../calendar.config';
import { PermissionAccess, PermissionItem } from 'src/app/core/models/enum/permission.enum';
import { MONTH_OPTS } from 'src/app/config/month.config';
import { ResponseResult } from 'src/app/core/models/response/response-result';

@Component({
  selector: 'app-approve-commission-event',
  templateUrl: './approve-commission-event.component.html',
  styleUrls: ['./approve-commission-event.component.scss'],
})
export class ApproveCommissionEventComponent implements OnInit {
  permissionAccess = PermissionAccess;
  permissionItem = PermissionItem;
  nextYear = new Date().getFullYear() + 1;
  commissionEventInitValue: number[][] = [];
  monthConfig = MONTH_OPTS;
  commitId = '';

  eventType: string;

  constructor(
    private ref: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ApproveCommissionEventComponent>,
    private cdPopup: CdPopupService,
    private calendarService: CalendarService,
    @Inject(CDS_POPUP_DATA)
    public data: {
      data: any;
    }
  ) {
    const dataTemp = this.data.data;
    this.eventType = dataTemp.title;
    this.commissionEventInitValue = [];
    dataTemp.events.map((item: any) => {
      const data = new Date(item.eventDate);
      const eventMonth = data.getMonth();
      const eventDay = data.getDate();
      if (!this.commissionEventInitValue[eventMonth]) {
        this.commissionEventInitValue[eventMonth] = [];
      }
      this.commitId = item.commitId;
      this.commissionEventInitValue[eventMonth].push(eventDay);
      this.commissionEventInitValue[eventMonth].sort((a, b) => a - b);
    });
  }

  ngOnInit() {}

  onSubmit() {
    this.approveRequest(true);
  }

  decline() {
    const popupRef: MatDialogRef<any> = this.cdPopup.openCommon({
      size: CdPopupSize.MEDIUM,
      type: CdPopupType.CONTINUE,
      isShowClose: false,
      data: {
        message: I18N_KEY.CALENDAR_DECLINE_MSG,
        continue: I18N_KEY.CALENDAR_DECLINE,
      },
    });
    popupRef.afterClosed().subscribe(confirm => {
      if (confirm.continue) {
        this.approveRequest(false);
      }
    });
  }

  approveRequest(isApprove: boolean) {
    this.calendarService
      .reviewCommissionEvents({
        commitId: this.commitId,
        isApprove,
      })
      .subscribe(res => {
        if (res.result == ResponseResult.SUCCESS) {
          const popupRef: MatDialogRef<any> = this.cdPopup.openCommon();
          popupRef.afterClosed().subscribe(() => {
            this.dialogRef.close({
              isChange: true,
              event: this.data.data,
            });
          });
        } else {
          this.cdPopup.openCommon({
            data: {
              message: I18N_KEY_FOR_API_RES[res.message] || res.message,
            },
          });
        }
      });
  }
}
